import { APPEAL_LOADING, APPEAL_LOAD_ERROR, APPEAL_LOAD_SUCCESS } from 'contexts/actionTypes';
import { ResponseError, Action, AllAppealStateType, AppealData } from 'contexts/intialstates/Types';

export const appeal = (
	state: AllAppealStateType,
	{ payload, type }: Action<AppealData>,
): AllAppealStateType => {
	switch (type) {
		case APPEAL_LOADING: {
			return {
				...state,
				error: null,
				loading: true,
			};
		}

		case APPEAL_LOAD_SUCCESS: {
			return {
				...state,
				loading: false,
				error: null,
				data: payload as AppealData,
			};
		}
		case APPEAL_LOAD_ERROR: {
			return {
				...state,
				loading: false,
				error: payload as ResponseError,
			};
		}
		default:
			return state;
	}
};
