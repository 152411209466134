import React, { FC, createContext, useReducer } from 'react'
import { 
    templateDeposits,
    deleteTemplateDeposit, 
    updateTemplateDeposit,
    templateDeposit, 
} from './reducers/templateDeposits'
import { 
    templateDepositsInitialState, 
    templateDepositDeleteInitialState,
    templateDepositUpdateInitialState, 
    templateDepositByIdInitialState,
} from 'contexts/intialstates/templateDeposits'
import {
    TemplateDepositType,
    TemplateDepositsData, 
    Action, 
    UpdateDeletePayload, 
    TemplateDepositDeleteType,
    TemplateDepositUpdateType,
    TemplateDepositByIdType,
    TemplateDeposits,
} from 'contexts/intialstates/Types'

export interface TemplateDepositContextType {
    templateDepositsState: TemplateDepositType
    templateDepositDispatch?: React.Dispatch<Action<TemplateDepositsData>>
    deleteTemplateDepositState: TemplateDepositDeleteType
    deleteTemplateDepositDispatch?: React.Dispatch<Action<UpdateDeletePayload>>
    updateTemplateDepositState: TemplateDepositUpdateType
    updateTemplateDepositDispatch?: React.Dispatch<Action<UpdateDeletePayload>>
    templateDepositState: TemplateDepositByIdType
    templateDepositByIdDispatch?: React.Dispatch<Action<TemplateDeposits>>
    
}

export const TemplateDepositContext = createContext<TemplateDepositContextType>({
    templateDepositsState: templateDepositsInitialState,
    deleteTemplateDepositState: templateDepositDeleteInitialState,
    updateTemplateDepositState: templateDepositUpdateInitialState,
    templateDepositState: templateDepositByIdInitialState,
})

export const TemplateDepositContextProvider: FC = ({ children }) => {
    const [templateDepositsState, templateDepositDispatch] = 
       useReducer(templateDeposits, templateDepositsInitialState)
    const [deleteTemplateDepositState, deleteTemplateDepositDispatch] = 
       useReducer(deleteTemplateDeposit, templateDepositDeleteInitialState)
    const [updateTemplateDepositState, updateTemplateDepositDispatch] = 
       useReducer(updateTemplateDeposit, templateDepositDeleteInitialState)
    const [templateDepositState, templateDepositByIdDispatch] = 
       useReducer(templateDeposit, templateDepositByIdInitialState)

    const contextValue = {
        templateDepositsState,
        templateDepositDispatch,
        deleteTemplateDepositState,
        deleteTemplateDepositDispatch,
        updateTemplateDepositState,
        updateTemplateDepositDispatch,
        templateDepositState,
        templateDepositByIdDispatch
    }
    
    return (
        <TemplateDepositContext.Provider value={contextValue}>
            {children}
        </TemplateDepositContext.Provider>
    )
}