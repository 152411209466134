import { TableCellProps } from '@material-ui/core';

export interface ColumnType {
	id: string;
	label: string;
	minWidth: number;
	align: TableCellProps['align'];
	filterType?: string;
	list?: Array<any>;
	isChecked?: boolean;
	data?: any;
}

export const DepositsTableHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'donor',
		label: 'Donor',
		minWidth: 220,
		align: 'center',
		filterType: 'search',
	},

	{
		id: 'referencenumber',
		label: 'Donor Ref No',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'creditAmount',
		label: 'Amount',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'paymentMethod',
		label: 'Payment Method',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [
			{ name: 'credit card' },
			{ name: 'cash' },
			{ name: 'cheque' },
			{ name: 'money order' },
			{ name: 'post date' },
			{ name: 'direct debit' },
			{ name: 'credit memo' },
			{ name: 'canada helps' },
		],
	},
	{
		id: 'itemStatus',
		label: 'Item Status',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'completed' }, { name: 'incomplete' }, { name: 'posted' }],
	},
	{
		id: 'isWalkathon',
		label: 'Walkathon',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'true' }, { name: 'false' }],
	},
	{
		id: 'creditDate',
		label: 'Credit Date',
		minWidth: 220,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'postDate',
		label: 'Post Date',
		minWidth: 220,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'batchNumber',
		label: 'Batch No',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{ id: 'chequeNumber', label: 'Cheque Number', minWidth: 220, align: 'center', filterType: 'text' },
	{ id: 'accountNumber', label: 'Donor Bank Account', minWidth: 250, align: 'center', filterType: 'text' },
	{
		id: 'societies',
		label: 'Societies',
		minWidth: 250,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'sp' }, { name: 'hc' }, { name: 'pf' }],
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	// {
	// 	id: 'depositAccount',
	// 	label: 'Society Bank Account',
	// 	minWidth: 250,
	// 	align: 'center',
	// 	filterType: 'value',
	// },
	// {
	// 	id: 'sequenceNumber',
	// 	label: 'Sequence Number',
	// 	minWidth: 290,
	// 	align: 'center',
	// },
	// {
	// 	id: 'rt',
	// 	label: 'RT',
	// 	minWidth: 290,
	// 	align: 'center',
	// },
	// {
	// 	id: 'receivedDate',
	// 	label: 'Received Date',
	// 	minWidth: 290,
	// 	align: 'center',
	// },
];
export const hacDonationTableHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'donor',
		label: 'Donor',
		minWidth: 220,
		align: 'center',
		filterType: 'search',
	},
	{
		id: 'acknowledged',
		label: 'Acknowledged',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'true' }, { name: 'false' }],
	},

	{
		id: 'referencenumber',
		label: 'Donor Ref No',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'creditAmount',
		label: 'Amount',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'paymentMethod',
		label: 'Payment Method',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [
			{ name: 'credit card' },
			{ name: 'cash' },
			{ name: 'cheque' },
			{ name: 'money order' },
			{ name: 'post date' },
			{ name: 'direct debit' },
			{ name: 'credit memo' },
			{ name: 'canada helps' },
		],
	},
	{
		id: 'itemStatus',
		label: 'Item Status',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'completed' }, { name: 'incomplete' }, { name: 'posted' }],
	},
	{
		id: 'isWalkathon',
		label: 'Walkathon',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'true' }, { name: 'false' }],
	},
	{
		id: 'creditDate',
		label: 'Credit Date',
		minWidth: 220,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'postDate',
		label: 'Post Date',
		minWidth: 220,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'batchNumber',
		label: 'Batch No',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{ id: 'chequeNumber', label: 'Cheque Number', minWidth: 220, align: 'center', filterType: 'text' },
	{ id: 'accountNumber', label: 'Donor Bank Account', minWidth: 250, align: 'center', filterType: 'text' },
	{ id: 'societies', label: 'Societies', minWidth: 250, align: 'center', filterType: 'text' },
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'depositCreated',
		label: 'Deposit Created Date',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'depositUpdated',
		label: 'Deposit Updated Date',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	// {
	// 	id: 'depositAccount',
	// 	label: 'Society Bank Account',
	// 	minWidth: 250,
	// 	align: 'center',
	// 	filterType: 'value',
	// },
	// {
	// 	id: 'sequenceNumber',
	// 	label: 'Sequence Number',
	// 	minWidth: 290,
	// 	align: 'center',
	// },
	// {
	// 	id: 'rt',
	// 	label: 'RT',
	// 	minWidth: 290,
	// 	align: 'center',
	// },
	// {
	// 	id: 'receivedDate',
	// 	label: 'Received Date',
	// 	minWidth: 290,
	// 	align: 'center',
	// },
];

export const BatchDonationsTableHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'donor',
		label: 'Donor',
		minWidth: 220,
		align: 'center',
		filterType: 'search',
	},

	{
		id: 'referencenumber',
		label: 'Donor Ref No',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'creditAmount',
		label: 'Amount',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'paymentMethod',
		label: 'Payment Method',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [
			{ name: 'credit card' },
			{ name: 'cash' },
			{ name: 'cheque' },
			{ name: 'money order' },
			{ name: 'post date' },
			{ name: 'direct debit' },
			{ name: 'credit memo' },
			{ name: 'canada helps' },
		],
	},
	{
		id: 'societies',
		label: 'Societies',
		minWidth: 250,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'sp' }, { name: 'hc' }, { name: 'pf' }],
	},
	{
		id: 'itemStatus',
		label: 'Item Status',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'completed' }, { name: 'incomplete' }, { name: 'posted' }],
	},
	{
		id: 'isWalkathon',
		label: 'Walkathon',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'true' }, { name: 'false' }],
	},
	{
		id: 'creditDate',
		label: 'Credit Date',
		minWidth: 220,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'postDate',
		label: 'Post Date',
		minWidth: 220,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'batchNumber',
		label: 'Batch No',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{ id: 'chequeNumber', label: 'cheque Number', minWidth: 220, align: 'center', filterType: 'text' },
	{ id: 'accountNumber', label: 'Donor Bank Account', minWidth: 250, align: 'center', filterType: 'text' },
	{ id: 'blank', label: 'Blank', minWidth: 250, align: 'center' },
];

export const donationsTableHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},

	{
		id: 'receiptNum',
		label: 'Receipt Number',
		minWidth: 170,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'contribution',
		label: 'Contribution Amount',
		minWidth: 170,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'issueDate',
		label: 'Date Issued',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},

	{
		id: 'society',
		label: 'Society',
		minWidth: 170,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'sp' }, { name: 'hc' }, { name: 'pf' }],
	},
	{
		id: 'creditDate',
		label: 'Date Credited',
		minWidth: 290,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 290,
		align: 'center',
		filterType: 'date',
	},

	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 290,
		filterType: 'date',
		align: 'center',
	},

	{
		id: 'remark',
		label: 'Remark',
		minWidth: 170,
		align: 'center',
	},
];

export const donorsTableHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},

	{
		id: 'receiptNum',
		label: 'Receipt Number',
		minWidth: 170,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'contribution',
		label: 'Contribution Amount',
		minWidth: 170,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'issueDate',
		label: 'Date Issued',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},

	{
		id: 'society',
		label: 'Society',
		minWidth: 170,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'sp' }, { name: 'hc' }, { name: 'pf' }],
	},
	{
		id: 'organizationName',
		label: 'Organization',
		minWidth: 250,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'creditDate',
		label: 'Date Credited',
		minWidth: 290,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 290,
		align: 'center',
		filterType: 'date',
	},

	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 290,
		filterType: 'date',
		align: 'center',
	},
	{
		id: 'remark',
		label: 'Remark',
		minWidth: 170,
		align: 'center',
	},
];

export const batchesTableHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'batchNumber',
		label: 'Batch Number',
		minWidth: 290,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'batchStatus',
		label: 'Item Status',
		minWidth: 290,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'completed' }, { name: 'incomplete' }, { name: 'posted' }],
	},
	{
		id: 'deposits',
		label: 'Number of Deposits',
		minWidth: 290,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 290,
		align: 'center',
		filterType: 'date',
	},

	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 290,
		filterType: 'date',
		align: 'center',
	},
];

export const receiptTemplateHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'name',
		label: 'Template Name',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'donationType',
		label: 'Donation Type',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'society',
		label: 'Society',
		minWidth: 290,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'sp' }, { name: 'hc' }, { name: 'pf' }],
	},
	{
		id: 'appeal',
		label: 'Appeal',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},

	{
		id: 'month',
		label: 'Month',
		minWidth: 290,
		align: 'center',
		filterType: 'list',
		list: [
			{
				name: '1',
				label: 'January',
			},
			{
				name: '2',
				label: 'February',
			},
			{
				name: '3',
				label: 'March',
			},
			{
				name: '4',
				label: 'April',
			},
			{
				name: '5',
				label: 'May',
			},
			{
				name: '6',
				label: 'June',
			},
			{
				name: '7',
				label: 'July',
			},
			{
				name: '8',
				label: 'August',
			},
			{
				name: '9',
				label: 'September',
			},
			{
				name: '10',
				label: 'October',
			},
			{
				name: '11',
				label: 'November',
			},
			{
				name: '12',
				label: 'December',
			},
		],
	},

	{
		id: 'year',
		label: 'Year',
		minWidth: 290,
		align: 'center',
		filterType: 'value',
	},

	{
		id: 'created',
		label: 'Date Created',
		minWidth: 290,
		align: 'center',
		filterType: 'date',
	},

	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 290,
		filterType: 'date',
		align: 'center',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
];

export const appealHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'code',
		label: 'Code',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'description',
		label: 'Description',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	// {
	// 	id: 'progress',
	// 	label: 'Progress',
	// 	minWidth: 170,
	// 	align: 'center',
	// 	filterType: 'list',
	// 	list: [{ name: 'Completed' }, { name: 'Incomplete' }],
	// },
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
];

export const parishHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'parishName',
		label: 'Parish Name',
		minWidth: 250,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'parishCode',
		label: 'Parish Code',
		minWidth: 170,
		align: 'center',
		filterType: 'value',
	},

	{
		id: 'archived',
		label: 'Archive Status',
		minWidth: 290,
		align: 'center',
	},
	{
		id: 'pastor',
		label: 'Priest',
		minWidth: 250,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'phone',
		label: 'Phone',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'fax',
		label: 'Fax',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'email',
		label: 'Email',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'streetline1',
		label: 'Streetline 1',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'streetline2',
		label: 'Streetline 2',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'city',
		label: 'City',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'postalCode',
		label: 'Postal Code',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'country',
		label: 'Country',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
];
export const hcaOrdersHead: ColumnType[] = [
	{
		id: 'id',
		label: 'Order ID',
		minWidth: 100,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'organizationType',
		label: 'Organization Type',
		minWidth: 170,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'Parish' }, { name: 'School' }, { name: 'Board' }, { name: 'Diocese' }],
	},
	{
		id: 'organizationName',
		label: 'Organization Name',
		minWidth: 250,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'address',
		label: 'Address',
		minWidth: 250,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'receivedDate',
		label: 'Received Date',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'status',
		label: 'Status',
		minWidth: 170,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'Pending' }, { name: 'Printed' }, { name: 'Confirmed' }],
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
];
export const postDatedChequeHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'cheque',
		label: 'Cheque',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'donor',
		label: 'Donor',
		minWidth: 220,
		align: 'center',
		filterType: 'search',
	},
	{
		id: 'amount',
		label: 'Total Amount',
		minWidth: 250,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'receivedDate',
		label: 'Received Date',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'dueDate',
		label: 'Due Date',
		minWidth: 250,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'status',
		label: 'Status',
		minWidth: 170,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'Pending' }, { name: 'Deposited' }, { name: 'Post-dated' }],
	},
	{
		id: 'remark',
		label: 'Remark',
		minWidth: 250,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
];
export const campaignHead: ColumnType[] = [
	{
		id: 'id',
		label: 'Campaign ID',
		minWidth: 170,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'name',
		label: 'Name',
		minWidth: 220,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'minAmount',
		label: 'Amount From',
		minWidth: 250,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'maxAmount',
		label: 'Amount To',
		minWidth: 250,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'startDate',
		label: 'Date From',
		minWidth: 250,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'endDate',
		label: 'Date To',
		minWidth: 250,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'donorCount',
		label: 'Donor Count',
		minWidth: 250,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
];
export const campaignOutputHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'Sno',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'donor',
		label: 'Donor',
		minWidth: 220,
		align: 'center',
	},
	{
		id: 'organization',
		label: 'Organization',
		minWidth: 220,
		align: 'center',
	},
	{
		id: 'donorType',
		label: 'Donor Type',
		minWidth: 250,
		align: 'center',
	},
	{
		id: 'amount',
		label: 'Amount ',
		minWidth: 250,
		align: 'center',
	},
	{
		id: 'blank',
		label: 'Blank ',
		minWidth: 200,
		align: 'center',
	},
];

export const DonationTypeHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'type',
		label: 'Type',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'description',
		label: 'Description',
		minWidth: 250,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'receipting',
		label: 'Receipting',
		minWidth: 170,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'true' }, { name: 'false' }],
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},

	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
];
export const userHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'Sno',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'firstName',
		label: 'Name',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'groupName',
		label: 'Role',
		minWidth: 250,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'email',
		label: 'Email',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
];
export const schoolHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'name',
		label: 'Name',
		minWidth: 250,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'code',
		label: 'Code',
		minWidth: 170,
		align: 'center',
		filterType: 'value',
	},

	{
		id: 'archived',
		label: 'Archive Status',
		minWidth: 290,
		align: 'center',
	},
	{
		id: 'principal',
		label: 'Principal',
		minWidth: 250,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'phone',
		label: 'Phone',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'fax',
		label: 'Fax',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'email',
		label: 'Email',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'streetline1',
		label: 'Streetline 1',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'streetline2',
		label: 'Streetline 2',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'city',
		label: 'City',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'postalCode',
		label: 'Postal Code',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'country',
		label: 'Country',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'diocese',
		label: 'Diocese',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'districtCode',
		label: 'District Code',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'schoolStatus',
		label: 'Status',
		minWidth: 170,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'Active' }, { name: 'On Hold' }],
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
];
export const schoolBoardHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'name',
		label: 'Name',
		minWidth: 250,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'code',
		label: 'Code',
		minWidth: 170,
		align: 'center',
		filterType: 'value',
	},

	{
		id: 'archived',
		label: 'Archive Status',
		minWidth: 290,
		align: 'center',
	},
	{
		id: 'director',
		label: 'Director',
		minWidth: 250,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'phone',
		label: 'Phone',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'fax',
		label: 'Fax',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'email',
		label: 'Email',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'streetline1',
		label: 'Streetline 1',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'streetline2',
		label: 'Streetline 2',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'city',
		label: 'City',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'postalCode',
		label: 'Postal Code',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'country',
		label: 'Country',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'diocese',
		label: 'Diocese',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'country',
		label: 'Country',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updated',
		label: 'Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
];
export const hcaMaterialsHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'itemCode',
		label: 'Item Code',
		minWidth: 250,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'description',
		label: 'Description',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'active',
		label: 'Active',
		minWidth: 250,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'true' }, { name: 'false' }],
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 250,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 250,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 250,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 250,
		align: 'center',
		filterType: 'date',
	},

	{
		id: 'remark',
		label: 'Remark',
		minWidth: 250,
		align: 'center',
	},
];

export const dioceseHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'code',
		label: 'Code',
		minWidth: 170,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'dioceseName',
		label: 'Diocese',
		minWidth: 250,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'archived',
		label: 'Archive Status',
		minWidth: 290,
		align: 'center',
	},
	{
		id: 'chancellor',
		label: 'Chancellor',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'bishop',
		label: 'Bishop',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'phone',
		label: 'Phone',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'fax',
		label: 'Fax',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'email',
		label: 'Email',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'streetline1',
		label: 'Streetline 1',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'streetline2',
		label: 'Streetline 2',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'city',
		label: 'City',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'postalCode',
		label: 'Postal Code',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'country',
		label: 'Country',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updated',
		label: 'Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
];

export const donorHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'referencenumber',
		label: 'Donor Ref No',
		minWidth: 200,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'salutation',
		label: 'Salutation',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'firstName',
		label: 'First Name',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'lastName',
		label: 'Last Name',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'streetline1',
		label: 'Streetline 1',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'streetline2',
		label: 'Streetline 2',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'province',
		label: 'Province',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'city',
		label: 'City',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'postalcode',
		label: 'Postal Code',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'country',
		label: 'Country',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'archived',
		label: 'Archive Status',
		minWidth: 290,
		align: 'center',
	},

	{
		id: 'orgname',
		label: 'Organization',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'parish',
		label: 'Parish Name',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'phone',
		label: 'Phone',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'email',
		label: 'Email',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'mobile',
		label: 'Mobile',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'careof',
		label: 'Care Of',
		minWidth: 290,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'deceased',
		label: 'Deceased',
		minWidth: 290,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'true' }, { name: 'false' }],
	},

	{
		id: 'isAnnuitant',
		label: 'Annuitant',
		minWidth: 290,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'true' }, { name: 'false' }],
	},
	{
		id: 'dateOfDeath',
		label: 'Date Of Death',
		minWidth: 290,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'deathReported',
		label: 'Death Reported',
		minWidth: 290,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
];

export const eoyReceiptHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'actions',
		label: 'Actions',
		minWidth: 100,
		align: 'center',
	},
	{
		id: 'amount',
		label: 'Amount',
		minWidth: 100,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'receiptNumber',
		label: 'Receipt Number',
		minWidth: 200,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'society',
		label: 'Society',
		minWidth: 100,
		align: 'center',
		filterType: 'list',
	},
	{
		id: 'recordCount',
		label: 'Number of Donations',
		minWidth: 200,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'issueDate',
		label: 'Date Issued',
		minWidth: 200,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'receiptYear',
		label: 'Receipt Year',
		minWidth: 100,
		align: 'center',
		filterType: 'date',
	},
];

export const walkathonHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'schoolBoard',
		label: 'School Board',
		minWidth: 200,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'amount',
		label: 'Amount Received',
		minWidth: 200,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'receivedDate',
		label: 'Recieved Date',
		minWidth: 200,
		align: 'center',
		filterType: 'list',
	},
	{
		id: 'status',
		label: 'Status',
		minWidth: 100,
		align: 'center',
		filterType: 'list',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
	},
];

export const failedTransactionsHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'Sno',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'donor',
		label: 'Donor',
		minWidth: 290,
		align: 'center',
		filterType: 'search',
	},
	{
		id: 'postAmount',
		label: 'Total Amount',
		minWidth: 250,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'transactionDate',
		label: 'Transaction Date',
		minWidth: 290,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'paymentMethod',
		label: 'Payment Method',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [
			{ name: 'credit card' },
			{ name: 'cash' },
			{ name: 'cheque' },
			{ name: 'money order' },
			{ name: 'post date' },
			{ name: 'direct debit' },
			{ name: 'credit memo' },
			{ name: 'canada helps' },
		],
	},
	{
		id: 'blank',
		label: 'Blank',
		minWidth: 300,
		align: 'center',
	},
];

export const postDatedNotificationsHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'Sno',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'donor',
		label: 'Donor',
		minWidth: 290,
		align: 'center',
		filterType: 'search',
	},
	{
		id: 'amount',
		label: 'Total Amount',
		minWidth: 250,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'dueDate',
		label: 'Due Date',
		minWidth: 290,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'cheque',
		label: 'Cheque',
		minWidth: 220,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'blank',
		label: 'Blank',
		minWidth: 300,
		align: 'center',
	},
];

export const expiringCCHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'Sno',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'donor',
		label: 'Donor',
		minWidth: 290,
		align: 'center',
		filterType: 'search',
	},
	{
		id: 'cardNumber',
		label: 'Card Number',
		minWidth: 250,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'expiryDate',
		label: 'Expiry Date',
		minWidth: 250,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'blank',
		label: 'Blank',
		minWidth: 300,
		align: 'center',
	},
];
export const batchNotificationsHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'Sno',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'batchNumber',
		label: 'Batch Number',
		minWidth: 170,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'numberOfDeposit',
		label: 'Number of Deposit',
		minWidth: 170,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'lastUpdated',
		label: 'Last Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'status',
		label: 'Status',
		minWidth: 170,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'Completed' }, { name: 'Incomplete' }],
	},
	{
		id: 'blank',
		label: 'Blank',
		minWidth: 300,
		align: 'center',
	},
];
