import React, { FC } from 'react';
import { TableRow, TableCell, Skeleton } from '@mui/material';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';

interface P {
	columnCount: number;
	columnMinWidth: number;
	isSN?: boolean;
	action?: boolean;
	columnData?: ColumnType[];
}

interface TableAccordian {
	columnData?: any;
}

const TableSkeletonContent: FC<P> = ({ action, columnData }) => {
	return (
		<>
			{[...Array.from({ length: 5 })].map((i, index) => {
				return (
					<TableRow key={index}>
						<tr>
							{columnData?.map((c, index) =>
								action ? (
									<TableCell key={index} style={{ minWidth: c.minWidth }} align={'center'}>
										<Skeleton height={30} />
									</TableCell>
								) : (
									<TableCell key={index} style={{ minWidth: c.minWidth }} align={'center'}>
										<Skeleton height={30} />
									</TableCell>
								),
							)}
						</tr>
					</TableRow>
				);
			})}
		</>
	);
};

export const TableAccordianSkeletonContent: FC<TableAccordian> = ({ columnData }) => {
	return (
		<>
			{[...Array.from({ length: 5 })].map((i, index) => {
				return (
					<TableRow key={index}>
						{columnData?.map((c: any, index: number) => (
							<TableCell key={index} align={'center'}>
								<Skeleton height={30} />
							</TableCell>
						))}
					</TableRow>
				);
			})}
		</>
	);
};

export default TableSkeletonContent;
