import React, { useState } from 'react';
import { IconButton, Paper, Stack, TablePagination } from '@mui/material';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import FormatCurrency from 'helpers/FormatCurrency';
import { StateType } from 'contexts/intialstates/Types';
import { CampaignOutputDonor, ICampaignOutput } from 'hooks/useCampaignActions';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { pagination } from 'Components/Common/Tables/Tables';
import { GlobalContexts } from 'contexts/GlobalContext';

interface P {
	trimList: ColumnType[];
	setTrimList: React.Dispatch<React.SetStateAction<ColumnType[]>>;
	output: StateType<ICampaignOutput>;
	handleOpenEdit: (data: CampaignOutputDonor) => void;
}

const OutputList = ({ trimList, setTrimList, output, handleOpenEdit }: P) => {
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);
	const donorName = (donor: CampaignOutputDonor) => {
		if (donor?.firstName && donor?.lastName) return `${donor?.firstName} ${donor?.lastName}`;
		if (donor?.firstName) return `${donor?.firstName}`;
		if (donor?.lastName) return ` ${donor?.lastName}`;
		if (donor?.orgName) return donor?.orgName;
		return 'Anonymous';
	};

	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleEdit = (row: any) => {
		if (output?.data?.campaignDonors?.some((item: CampaignOutputDonor) => !item.id)) {
			setSimpleErrorSnack({
				severity: 'error',
				message: 'Please save campaign to edit donors',
				show: true,
			});
			return;
		}
		const data = output?.data?.campaignDonors?.find(
			(item: CampaignOutputDonor) => item.id == row?.id?.toString(),
		);
		data && handleOpenEdit?.(data);
	};
	const getTableData = () => {
		const outputArr = output?.data?.campaignDonors?.slice(
			page * pageSize,
			page * pageSize + pageSize,
		) as CampaignOutputDonor[];

		return outputArr?.length
			? outputArr?.map((row: CampaignOutputDonor, index: number) => {
					return {
						id: `${row.id}`,
						's/n': page * pageSize + index + 1,
						donor: donorName(row),
						organization: row?.orgName || '-',
						donorType: row?.donorType || '-',
						amount: row?.amount ? <FormatCurrency value={row?.amount} /> : 'N/A',
						blank: (
							<Stack direction="row" spacing={2}>
								<IconButton
									sx={{
										borderRadius: '6px',
										width: 40,
										height: 30,
										border: '1px solid var(--mainColor)',
										color: 'var(--mainColor)',
									}}
								>
									<EditOutlined sx={{ fontSize: 16, color: 'var(--mainColor)' }} />
								</IconButton>

								<IconButton
									sx={{
										background: 'var(--mainColor)',
										borderRadius: '6px',
										width: 40,
										height: 30,
										border: '1px solid var(--mainColor)',
										color: '#fff',
										'&:hover': { border: '1px solid var(--mainColor)', background: 'var(--mainColor)' },
									}}
								>
									<DeleteOutline
										sx={{
											fontSize: 16,
											color: 'white',
											'&:hover': { background: 'var(--mainColor)' },
										}}
									/>
								</IconButton>
							</Stack>
						),
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType) => {
			return {
				...column,
				data: <>{column.label}</>,
			};
		});
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		reOrderedData && setTrimList?.(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		resizedData && setTrimList?.(resizedData);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<CustomTable
				loading={output?.loading}
				columnData={getColumnData()}
				tableData={getTableData()}
				handleDrag={handleDrag}
				handleResize={handleResize}
				emptyMessage={'No Orders available'}
				handleRowClick={(row) => handleEdit(row)}
			/>
			{(output?.data?.campaignDonors?.length as number) > 0 && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
					component="div"
					count={output?.data?.campaignDonors?.length as number}
					rowsPerPage={pageSize}
					page={page}
					sx={pagination}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</Paper>
	);
};

export default OutputList;
