import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { DepositFormPayload } from '../CreateDeposit/Types';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Content from './Content';
import { TemplateDepositContext } from 'contexts/TemplateDepositContext';
import useSocietyActions from 'hooks/useSocietyAction';
import usePaymentmethodsActions from 'hooks/usePaymentMethodAction';
import useVoidTypeActions from 'hooks/useVoidTypeActions';
import useAppealActions from 'hooks/useAppealActions';
import { GlobalContexts } from 'contexts/GlobalContext';
import useDepositActions from 'hooks/useDepositActions';
import useTemplateDepositActions from 'hooks/useTemplateDepositActions';
import {
	ApiResponse,
	Appeal,
	Deposits,
	PaymentMethod,
	Society,
	StateType,
} from 'contexts/intialstates/Types';
import useHCADonations from 'hooks/useHCADonationActions';

interface RouteParams {
	id?: string; //deposit - if this exists then this is an edit action then a deposit should be gotten from the backend (GET deposit and populated in the form)
	donorId?: string; // if this exists,
	batchId?: string;
	tab?: string;
}

const CreateEditDepositsWithDonations = () => {
	const [allSociety, setAllSoceity] = React.useState<StateType<Society[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [allPM, setPM] = React.useState<StateType<PaymentMethod[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [allAppeals, setAllAppeals] = useState<StateType<Appeal[]>>({
		data: null,
		loading: false,
		error: null,
	});

	const routeParams: RouteParams = useParams();
	const location = useLocation();
	const isHCADonations = location.pathname.includes('hca-donations');
	const querySearch = new URLSearchParams(location.search);
	const type = querySearch.get('tab');
	const navigate = useNavigate();
	const isTemplateDeposit = location.pathname.includes('template');
	const [depositId, setDepositId] = useState<number>();
	const [donorId, setDonorId] = useState<number>();
	const [batchId, setBatchId] = useState<number>();
	const { getSocieties } = useSocietyActions();
	const { getPaymentMethods } = usePaymentmethodsActions();
	const { getAllAppeals } = useAppealActions();
	const {
		deleteTemplateDeposit,
		getTemplateDepositByID,
		updateTemplateDeposit,
		createSingletTemplateDeposit,
	} = useTemplateDepositActions();
	const { createSingleDeposit, getDeposit, updateSingleDeposit, deleteSingleDeposit } = useDepositActions();
	const { getHCADonations } = useHCADonations();
	const { setErrorSnack } = useContext(GlobalContexts);

	const {
		templateDepositByIdDispatch,
		templateDepositState,
		updateTemplateDepositDispatch,
		deleteTemplateDepositDispatch,
	} = useContext(TemplateDepositContext);
	const [reload, setReload] = useState(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [initialize, setInitialize] = useState<boolean>(false);
	const [deposit, setDeposit] = React.useState<StateType<any>>({
		data: null,
		error: null,
		loading: false,
	});
	const [updateDeposit, setUpdateDeposit] = React.useState<StateType<ApiResponse>>({
		data: null,
		error: null,
		loading: false,
	});
	const [createDeposit, setCreateDeposit] = React.useState<StateType<Deposits>>({
		data: null,
		error: null,
		loading: false,
	});
	const [deleteDeposit, setDeleteDeposit] = React.useState<StateType<ApiResponse>>({
		data: null,
		error: null,
		loading: false,
	});

	useEffect(() => {
		if (!location) return;
		if (routeParams) {
			routeParams.id && setDepositId(parseInt(routeParams.id));
			routeParams.batchId && setBatchId(parseInt(routeParams.batchId));
			routeParams.donorId && setDonorId(parseInt(routeParams.donorId));
		}

		if (routeParams.id) {
			if (isHCADonations) {
				getHCADonations({ depositId: parseInt(routeParams.id), deposit, setDeposit, setErrorSnack });
			} else if (isTemplateDeposit) {
				if (templateDepositByIdDispatch)
					getTemplateDepositByID(parseInt(routeParams.id))(templateDepositByIdDispatch);
			} else {
				getDeposit({ depositId: parseInt(routeParams.id), deposit, setDeposit, setErrorSnack });
			}
		}

		setInitialize(true);
	}, [routeParams, location, isTemplateDeposit, reload]);

	useEffect(() => {
		getSocieties({ allSociety, setAllSoceity });
		getAllAppeals({ setAllAppeals, allAppeals });
		getPaymentMethods({ allPM, setPM });
	}, []);

	useEffect(() => {
		const isLoading = allPM?.loading || deposit?.loading;
		if (!isLoading && initialize) {
			setLoading(false);
		}
	}, [allPM?.loading, initialize, deposit?.loading]);

	const handleUpdateDeposit = async (payload: DepositFormPayload) => {
		if (depositId) {
			if (isHCADonations) {
				await updateSingleDeposit({
					payload,
					depositId: parseInt(deposit.data?.deposit?.id),
					updateDeposit,
					setUpdateDeposit,
					setErrorSnack,
					posted: deposit.data?.deposit?.itemStatus?.status === 'Posted',
				});
			} else if (!isTemplateDeposit) {
				await updateSingleDeposit({
					payload,
					depositId,
					updateDeposit,
					setUpdateDeposit,
					setErrorSnack,
					posted: deposit.data?.itemStatus?.status === 'Posted',
				});
			} else {
				type &&
					updateTemplateDepositDispatch &&
					(await updateTemplateDeposit(
						depositId,
						payload,
						type,
						deposit.data?.itemStatus?.status === 'Posted',
					)(updateTemplateDepositDispatch));
			}
		} else {
			!isTemplateDeposit
				? await createSingleDeposit({
						payload,
						createDeposit,
						setCreateDeposit,
						setErrorSnack,
				  })
				: await createSingletTemplateDeposit({
						payload,
						createDeposit,
						setCreateDeposit,
						setErrorSnack,
				  });
		}
		if (batchId) {
			navigate(`/bookkeeping/batch/${batchId}`);
		}

		if (donorId) {
			navigate(`/donors/${donorId}`);
		}
		if (isHCADonations) {
			navigate(`/hca/hca-donations/`);
		}
		if (!batchId && !donorId && !isHCADonations) {
			!isTemplateDeposit
				? navigate(`/bookkeeping/deposits`)
				: // : navigate(`/bookkeeping/template/deposits/?tab=${type}`);
				  setReload(!reload);
		}
	};

	const handleDelete = async () => {
		if (depositId) {
			if (isTemplateDeposit) {
				if (deleteTemplateDepositDispatch) {
					await deleteTemplateDeposit(depositId)(deleteTemplateDepositDispatch);
				}
			} else if (isHCADonations) {
				await deleteSingleDeposit({
					depositId: parseInt(deposit.data?.deposit?.id),
					deleteDeposit,
					setDeleteDeposit,
					setErrorSnack,
				});
			} else {
				await deleteSingleDeposit({
					depositId,
					deleteDeposit,
					setDeleteDeposit,
					setErrorSnack,
				});
			}
		}
		if (batchId) {
			navigate(`/bookkeeping/batch/${batchId}`);
		}

		if (donorId) {
			navigate(`/bookkeeping/donors/${donorId}`);
		}
		if (isHCADonations) {
			navigate(`/hca/hca-donations/`);
		}
		if (!batchId && !donorId && !isHCADonations) {
			!isTemplateDeposit
				? navigate(`/bookkeeping/deposits`)
				: navigate(`/bookkeeping/template/deposits/?tab=${type}`);
		}
	};

	return (
		<Content
			batchId={batchId}
			depositId={depositId}
			donorId={donorId}
			depositData={
				isHCADonations
					? deposit?.data?.deposit
					: isTemplateDeposit
					? templateDepositState?.data
					: deposit?.data
			}
			loading={loading}
			allPaymentMethods={allPM?.data}
			handleUpdateDeposit={handleUpdateDeposit}
			handleDelete={handleDelete}
			isTemplateDeposit={isTemplateDeposit}
			handleReload={() => setReload(!reload)}
			type={type || ''}
			setReloader={setReload}
			reloader={reload}
			acknowledged={deposit?.data?.acknowledged}
			showBatchNumber={!batchId && !isTemplateDeposit && !isHCADonations ? true : false}
		/>
	);
};

export default CreateEditDepositsWithDonations;
