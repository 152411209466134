import React, { useEffect } from 'react';
import { TablePagination, Paper, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import { pagination } from 'Components/Common/Tables/Tables';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { convertDate } from 'helpers/dayAndTime';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import { sortTable } from 'Components/Common/Tables/sortTable';
import FormatCurrency from 'helpers/FormatCurrency';
import { OrderType, StateType } from 'contexts/intialstates/Types';
import useCampaignActions, { Campaign, CampaignData } from 'hooks/useCampaignActions';

interface P {
	reload: boolean;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	trimList: ColumnType[];
	setTrimList: React.Dispatch<React.SetStateAction<ColumnType[]>>;
	filterPayload: any;
	setFilterPayload: any;
}

const CampaignList = ({
	trimList,
	reload,
	setReload,
	filterPayload,
	setFilterPayload,
	setTrimList,
	page,
	setPage,
}: P) => {
	const navigate = useNavigate();

	const { getCampaigns } = useCampaignActions();
	const [searchDonor] = React.useState<string>('');
	const [pageSize, setPageSize] = React.useState(10);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');

	const [campaign, setCampaign] = React.useState<StateType<Campaign>>({
		data: null,
		error: null,
		loading: false,
	});

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleEdit = (row: CampaignData) => {
		navigate(`${row?.id}`);
	};
	useEffect(() => {
		getCampaigns({
			setCampaign,
			campaign,
			pageSize,
			page,
			orderBy,
			filterPayload,
		});
	}, [page, pageSize, reload, orderBy, searchDonor]);

	const getTableData = () => {
		return campaign?.data?.body?.length
			? campaign?.data?.body.map((row: CampaignData) => {
					return {
						id: row?.id || 'N/A',
						name: row?.name || 'N/A',
						minAmount: row?.minAmount ? <FormatCurrency value={row?.minAmount} /> : 'N/A',
						maxAmount: row?.maxAmount ? <FormatCurrency value={row?.maxAmount} /> : 'N/A',
						startDate: row?.startDate ? convertDate(row?.startDate) : 'N/A',
						endDate: row?.endDate ? convertDate(row?.endDate) : 'N/A',
						donorCount: row?.donorCount || 'N/A',
						created: `${row?.created ? convertDate(row?.created) : 'N/A'}`,
						createdBy: row?.createdBy?.name || 'N/A',
						updated: `${row?.updated ? convertDate(row?.updated) : 'N/A'}`,
						updatedBy: row?.updatedBy?.name || 'N/A',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
							<span
								style={{ cursor: 'pointer' }}
								onClick={() =>
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									})
								}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<>
							<TableFiltering
								field={column.id}
								filterType={column.filterType}
								list={column.list}
								setPage={setPage}
								page={page}
								reload={reload}
								setIsReload={setReload}
								setFilterPayload={setFilterPayload}
								filterPayload={filterPayload}
							/>
						</>
					</>
				),
			};
		});
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		reOrderedData && setTrimList?.(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		resizedData && setTrimList?.(resizedData);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<CustomTable
				loading={campaign?.loading}
				columnData={getColumnData()}
				tableData={getTableData()}
				handleDrag={handleDrag}
				handleResize={handleResize}
				emptyMessage={'No Orders available'}
				handleRowClick={(row) => handleEdit(row as CampaignData)}
			/>
			{(campaign?.data?.body?.length as number) > 0 && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
					component="div"
					count={campaign?.data?.totalRecords as number}
					rowsPerPage={pageSize}
					page={page}
					sx={pagination}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</Paper>
	);
};

export default CampaignList;
