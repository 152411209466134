import React, { useState, useEffect } from 'react';
import {
	Stack,
	Button,
	Typography,
	Paper,
	TextField,
	Autocomplete,
	Box,
	Tooltip,
	Grid,
	InputAdornment,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	ArrowBackIosNew,
	AddTask,
	Add,
	CorporateFare,
	DateRange,
	Search,
	Delete,
	Receipt,
} from '@mui/icons-material';
import { useStyles } from '../Walkathon.style';
import { useGlobalStyles } from 'Components/Common/global/global';
import WalkathonAccordian from './WalkathonAccordian';
import { serachSchoolBoardField } from '../../Reusable/Modals/AdvancedSearch/Mock';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import WalkathonAdvancedSearch, {
	Field,
} from 'Components/Reusable/Modals/AdvancedSearch/AdvancedSearchModal';
import { StateType, SchoolBoardData, OrderType, SchoolBoard } from 'contexts/intialstates/Types';
import { ColumnType, schoolBoardHead } from 'Components/Common/Tables/TableHeaders';
import SchoolBoardTable from 'Components/AdminPanel/SchoolBoard/SchoolBoardTable';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import useSchoolBoardActions from 'hooks/useSchoolBoardActions';
import useWalkathonActions, {
	DonorContributionInput,
	SchoolContributionInput,
	WalkathonById,
} from 'hooks/useWalkathonAction';
import { useNavigate, useParams } from 'react-router-dom';
import useDebounce from 'Components/Reusable/hooks/debounce';
import { ContentSkeleton } from 'Components/Deposits/CreateEditDepositWithDonations/Content';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface DonorType {
	key: string;
	contributionId?: number;
	donorId?: number;
	donorName: string;
	donorAmount: string;
	donorRecieptNumber?: number;
	depositId?: number;
}

export interface SchoolDataType {
	key: string;
	contributionId?: number;
	schoolId?: number;
	schoolName: string;
	schoolAmount: string;
	isEdit?: boolean;
	expand?: boolean;
	donor: DonorType[];
}

interface WalkathonFormData {
	status: string;
	schoolBoardId?: number;
	schoolBoardName: string;
	totalAmount?: number;
	receiveDate: string;
	school: SchoolDataType[];
}

const CreateWalkathon = () => {
	const [formErrors, setFormErrors] = useState<string[]>([]);
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);
	const { id } = useParams();
	const [formData, setFormData] = useState<WalkathonFormData>({
		status: 'Pending',
		schoolBoardName: '',
		receiveDate: '',
		school: [],
	});
	const classes = useStyles();
	const global = useGlobalStyles();
	const { getSchoolBoards, getSchoolBoardSearch } = useSchoolBoardActions();
	const [visibleSearch, setVisibleSearch] = useState({
		state: false,
		data: {
			organizationName: '',
			organizationId: 0,
		},
	});
	const [schoolBoards, setSchoolBoards] = React.useState<StateType<SchoolBoardData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [schoolBoardSearch, setSchoolBoardSearch] = React.useState<StateType<SchoolBoard[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [searchTerm, setSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce(searchTerm, 1000);
	const [walkathon, setWalkathon] = React.useState<StateType<WalkathonById>>({
		data: null,
		error: null,
		loading: false,
	});
	const [searchFields, setSearchFields] = useState<Field[]>(serachSchoolBoardField);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [filterPayload, setFilterPayload] = useState<any>([]);
	const [orderType, setOrderType] = useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const [saveLoading, setSaveLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [postLoading, setPostLoading] = useState(false);
	const [openConfirmation, setOpenConfirmation] = useState(false);
	const [receiptLoading, setReceiptLoading] = useState(false);
	const [reload, setReload] = useState(false);
	const [sendEmail, setSendEmail] = useState(false);
	const [schoolTotal, setSchoolTotal] = useState(0);

	const {
		createWalkathon,
		getWalkathonById,
		updateWalkathon,
		deleteWalkathon,
		postWalkathon,
		generateReceipt,
	} = useWalkathonActions();
	const navigate = useNavigate();

	useEffect(() => {
		if (!debouncedSearchTerm) return;
		getSchoolBoardSearch({ setSchoolBoardSearch, schoolBoardSearch, searchTerm });
	}, [debouncedSearchTerm]);

	useEffect(() => {
		if (!id) return;
		getWalkathonById({ walkathon, setWalkathon, id: parseInt(id) });
	}, [id, reload]);

	useEffect(() => {
		const walkathonData = walkathon.data;
		if (!walkathonData) return;
		const data = {
			status: walkathonData.status,
			schoolBoardId: walkathonData.schoolBoard.id,
			schoolBoardName: walkathonData.schoolBoard.name,
			totalAmount: walkathonData.amount,
			receiveDate: walkathonData.receivedDate,
			school: walkathonData.schoolContributions.map(({ id, amount, school, donorContributions }) => {
				return {
					key: id.toString(),
					contributionId: id,
					schoolId: school.id,
					schoolName: school.name,
					schoolAmount: amount.toString(),
					donor: donorContributions.map(({ id, amount, donor, receiptNum, depositId }) => {
						return {
							key: id.toString(),
							contributionId: id,
							donorId: parseInt(donor.id),
							donorName: `${donor.firstName} ${donor.lastName}`,
							donorAmount: amount.toString(),
							donorRecieptNumber: receiptNum,
							depositId,
						};
					}),
				};
			}),
		};
		setFormData(data);
	}, [walkathon?.data]);

	useEffect(() => {
		if (!visibleSearch.state) return;
		getSchoolBoards({
			schoolBoards,
			setSchoolBoards,
			page,
			pageSize,
			orderBy,
			filterPayload,
			archive: '&Filters=field=archived;value=false',
		});
	}, [visibleSearch.state, page, pageSize, orderBy, filterPayload.length]);

	useEffect(() => {
		if (!Object.keys(visibleSearch.data).length) return;
		setFormData((prevState) => {
			return {
				...prevState,
				schoolBoardName: visibleSearch.data.organizationName,
				schoolBoardId: visibleSearch.data.organizationId,
			};
		});
	}, [visibleSearch.data]);
	useEffect(() => {
		if (!formData.school.length) {
			setSchoolTotal(0);
		} else {
			const totalVal = formData.school
				.map((e) => (e.schoolAmount ? parseFloat(e.schoolAmount) : 0))
				.reduce((total, curr) => total + curr, 0);
			setSchoolTotal(totalVal);
		}
	}, [formData.school]);

	const handleVisibleConfirmation = () => {
		setOpenConfirmation(!openConfirmation);
	};

	const handleVisibleSearch = () => {
		setVisibleSearch((prevState) => {
			return {
				...prevState,
				state: !visibleSearch.state,
			};
		});
	};

	const handleAddSchool = () => {
		const school = {
			key: (Math.random() + 1).toString(36).substring(7),
			schoolName: '',
			schoolAmount: '',
			isEdit: true,
			donor: [],
		};
		setFormData((prevState) => {
			return {
				...prevState,
				school: [school, ...prevState.school],
			};
		});
	};

	const handleSaveSchool = (data: SchoolDataType) => {
		const schoolIndex = formData.school.findIndex((school) => school.key === data.key);
		const schoolFormData = [...formData.school];
		schoolFormData[schoolIndex] = {
			...data,
			isEdit: false,
		};
		setFormData((prevState) => {
			return {
				...prevState,
				school: schoolFormData,
			};
		});
	};

	const handleToggleExpand = (key: string) => {
		const schoolIndex = formData.school.findIndex((school) => school.key === key);
		const schoolFormData = [...formData.school];
		schoolFormData[schoolIndex] = {
			...schoolFormData[schoolIndex],
			expand: !schoolFormData[schoolIndex].expand,
		};
		setFormData((prevState) => {
			return {
				...prevState,
				school: schoolFormData,
			};
		});
	};

	const handleSetSchoolEdit = (key: string) => {
		const schoolIndex = formData.school.findIndex((school) => school.key === key);
		const schoolFormData = [...formData.school];
		schoolFormData[schoolIndex] = {
			...schoolFormData[schoolIndex],
			isEdit: true,
		};
		setFormData((prevState) => {
			return {
				...prevState,
				school: schoolFormData,
			};
		});
	};

	const handleRemoveSchool = (key: string) => {
		const schoolFormData = [...formData.school];
		setFormData((prevState) => {
			return {
				...prevState,
				school: schoolFormData.filter((school) => school.key !== key),
			};
		});
	};

	const handleDonorDelete = (schoolKey: string, donorKey: string) => {
		const schoolIndex = formData.school.findIndex((school) => school.key === schoolKey);
		const schoolFormData = [...formData.school];
		schoolFormData[schoolIndex].donor = schoolFormData[schoolIndex].donor.filter(
			(item) => item.key !== donorKey,
		);
		setFormData((prevState) => {
			return {
				...prevState,
				school: schoolFormData,
			};
		});
	};

	const handleAddDonor = (key: string) => {
		const donor = {
			key: (Math.random() + 1).toString(36).substring(7),
			donorName: '',
			donorAmount: '',
			donorRecieptNumber: 0,
		};
		const schoolIndex = formData.school.findIndex((school) => school.key === key);
		const schoolFormData = [...formData.school];
		schoolFormData[schoolIndex] = {
			...schoolFormData[schoolIndex],
			expand: true,
			donor: [donor, ...schoolFormData[schoolIndex].donor],
		};
		setFormData((prevState) => {
			return {
				...prevState,
				school: schoolFormData,
			};
		});
	};

	const handleChangeDonor = (
		schoolKey: string,
		donorKey: string,
		control: string,
		value: number,
		name?: string,
	) => {
		const schoolIndex = formData.school.findIndex((school) => school.key === schoolKey);
		const schoolFormData = [...formData.school];
		schoolFormData[schoolIndex].donor = schoolFormData[schoolIndex].donor.map((item) => {
			if (item.key === donorKey) {
				return Object.assign(
					{
						...item,
						[control]: value,
					},
					name && { donorName: name },
				);
			}
			return item;
		});
		setFormData((prevState) => {
			return {
				...prevState,
				school: schoolFormData,
			};
		});
	};

	const handleSchoolBoardChange = (control: string, value: string | number, name?: string) => {
		if (formErrors.includes(control)) {
			setFormErrors(formErrors.filter((item) => item !== control));
		}
		setFormData((prevState) => {
			return Object.assign(
				{
					...prevState,
					[control]: value,
				},
				name && { schoolBoardName: name },
			);
		});
	};

	const handleSearch = () => {
		const payload: any = searchFields.map(({ key, value }) => {
			if (value) {
				return {
					field: key,
					query: key !== 'phone' ? value : null,
					value: key === 'phone' ? [value] : null,
					from: null,
					to: null,
				};
			}
		});
		setFilterPayload(payload.filter(Boolean));
	};

	const handleSave = async () => {
		const errors = [];
		if (!formData.schoolBoardId) {
			errors.push('schoolBoardId');
		}
		if (!formData.totalAmount) {
			errors.push('totalAmount');
		}
		if (Number.isNaN(parseFloat(formData?.totalAmount?.toString() as string))) {
			errors.push('totalAmountNaN');
		}
		if (!formData.receiveDate) {
			errors.push('receiveDate');
		}

		setFormErrors(errors);
		if (errors.length) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'Please fill all the required fields.',
			});
			return;
		}

		if (!formData.schoolBoardId || !formData.totalAmount) return;

		if (!formData?.school?.length) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'Please add a school.',
			});
			return;
		}

		let err = {
			status: false,
			message: '',
		};
		const schoolTotalVal = formData.school
			.map(({ schoolAmount }) => (schoolAmount ? parseFloat(schoolAmount) : 0))
			.reduce((total, curr) => total + curr, 0);

		formData?.school?.map(({ schoolId, schoolAmount, donor, contributionId, schoolName }) => {
			if (!schoolId || !schoolAmount) {
				err = {
					status: true,
					message: `School name field and amount cannot be empty  ${schoolName ? `for ${schoolName}` : ''}`,
				};
				return;
			}

			if (schoolTotalVal > parseFloat(formData.totalAmount?.toString() as string)) {
				err = {
					status: true,
					message: `Sum of total amount cannot be greater than amount donated by school board  ${
						schoolName ? `for ${schoolName}` : ''
					}`,
				};

				return;
			}

			const donorTotalVal = donor
				.map((e) => (e.donorAmount ? parseFloat(e.donorAmount) : 0))
				.reduce((total, curr) => total + curr, 0);
			if (donorTotalVal > parseFloat(schoolAmount)) {
				err = {
					status: true,
					message: `Sum of donors amount cannot be greater than amount donated by school    ${
						schoolName ? `for ${schoolName}` : ''
					}`,
				};

				return;
			}
			return {
				id: contributionId,
				schoolId,
				amount: parseFloat(schoolAmount),
				donorContributions: donor
					.map(({ donorId, donorAmount, contributionId }) => {
						if (!donorId || !donorAmount) {
							err = {
								status: true,
								message: `Donor name field and amount cannot be empty  ${
									schoolName ? `for ${schoolName}` : ''
								}`,
							};
							return;
						}
						return {
							id: contributionId,
							donorId,
							amount: parseFloat(donorAmount),
						};
					})
					.filter(Boolean) as DonorContributionInput[],
			};
		});

		if (err.status) {
			setSimpleErrorSnack({
				message: `${err.message}`,
				severity: 'error',
				show: true,
			});
			return;
		}
		const payload = {
			schoolBoardId: formData.schoolBoardId,
			amount: parseFloat(formData?.totalAmount?.toString()),
			receivedDate: formData.receiveDate,
			schoolContributions: formData.school
				.map(({ schoolId, schoolAmount, donor, contributionId }) => {
					return {
						id: contributionId,
						schoolId,
						amount: parseFloat(schoolAmount),
						donorContributions: donor
							.map(({ donorId, donorAmount, contributionId }) => {
								if (!donorId || !donorAmount) return;
								return {
									id: contributionId,
									donorId,
									amount: parseFloat(donorAmount),
								};
							})
							.filter(Boolean) as DonorContributionInput[],
					};
				})
				.filter(Boolean) as SchoolContributionInput[],
		};
		setSaveLoading(true);
		let isSucess;
		if (id) {
			isSucess = await updateWalkathon(payload, parseInt(id));
			if (isSucess) {
				setReload(!reload);
			}
		} else {
			const id = await createWalkathon(payload);
			navigate(`/hca/walkathon/${id}`);
		}
		setSaveLoading(false);
	};

	const handleDelete = async () => {
		if (!id) return;
		setDeleteLoading(true);
		const isSucess = await deleteWalkathon(parseInt(id));
		if (isSucess) {
			navigate('/hca/walkathon');
		}
		setDeleteLoading(false);
	};

	const handlePost = async () => {
		if (!id) return;
		setPostLoading(true);
		const isSucess = await postWalkathon(parseInt(id));
		if (isSucess) {
			setReload(!reload);
		}
		setPostLoading(false);
	};

	const handleGenerate = async () => {
		setReceiptLoading(true);
		id && (await generateReceipt(parseInt(id), sendEmail));
		setReceiptLoading(false);
		handleVisibleConfirmation();
	};
	return (
		<>
			{walkathon?.loading ? (
				<ContentSkeleton />
			) : (
				<Stack spacing={3}>
					<Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap={'wrap'}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={4}
							marginBottom={2}
						>
							<Stack className={classes.backIconWrapper}>
								<ArrowBackIosNew onClick={() => navigate('/hca/walkathon')} />
							</Stack>
							<Stack alignItems={'flex-start'}>
								<span className={classes.title}>Walkathon</span>
								{id && <span className={classes.subTitle}>{formData.status}</span>}
							</Stack>
						</Stack>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems={'center'}
							spacing={2}
							marginBottom={2}
						>
							{id && (
								<>
									<Button disabled={walkathon.data?.status === 'Pending'} onClick={handleVisibleConfirmation}>
										<Receipt />
									</Button>
									<Tooltip
										title={walkathon.data?.status === 'Posted' ? 'Posted Walkathons cannot be deleted' : ''}
									>
										<span>
											<LoadingButton
												loadingPosition="start"
												loading={deleteLoading}
												startIcon={<Delete />}
												size={'small'}
												variant="contained"
												disabled={walkathon.data?.status === 'Posted'}
												onClick={handleDelete}
											>
												Delete
											</LoadingButton>
										</span>
									</Tooltip>
									<LoadingButton
										loadingPosition="start"
										loading={postLoading}
										startIcon={<AddTask />}
										size={'small'}
										variant="outlined"
										disabled={walkathon.data?.status === 'Posted'}
										className={walkathon.data?.status !== 'Posted' ? classes.whiteBtn : ''}
										onClick={handlePost}
									>
										Post
									</LoadingButton>
								</>
							)}
							<LoadingButton
								loadingPosition="start"
								loading={saveLoading}
								startIcon={<Add />}
								size={'small'}
								variant="contained"
								onClick={handleSave}
								disabled={walkathon.data?.status === 'Posted'}
							>
								Save
							</LoadingButton>
						</Stack>
					</Stack>
					<Paper className={classes.paperWrap}>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2} mb={2}>
							<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
								<Stack direction="row" mb={1}>
									<CorporateFare className={global.formLabelIcon} />
									<Typography variant="body2" className={global.formLabelText}>
										School Board
									</Typography>
								</Stack>
								<Stack direction="row" alignItems="center" justifyContent="space-between">
									<Autocomplete
										freeSolo
										disabled={walkathon.data?.status === 'Posted'}
										value={formData.schoolBoardName}
										fullWidth
										onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
											setFormData((prevState) => {
												if (formErrors.includes('schoolBoardId')) {
													setFormErrors(formErrors.filter((item) => item !== 'schoolBoardId'));
												}
												return {
													...prevState,
													schoolBoardName: val?.label,
													schoolBoardId: val?.id,
												};
											});
										}}
										options={(schoolBoardSearch?.data || []).map((item) => ({
											id: item.id,
											label: item.name,
										}))}
										renderOption={(props, option) => (
											<Box component="li" {...props} key={option.id}>
												{option.label}
											</Box>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label=""
												InputProps={{
													...params.InputProps,
												}}
												onChange={(event: any) => {
													setSearchTerm(event.target.value);
												}}
												size={'small'}
												placeholder={'Search school board'}
												className={walkathon.data?.status === 'Posted' ? classes.disabledInput : ''}
											/>
										)}
									/>
									<Stack>
										<Button
											onClick={handleVisibleSearch}
											startIcon={<Search />}
											size={'small'}
											variant="outlined"
											sx={{ margin: '5px' }}
											disabled={walkathon.data?.status === 'Posted'}
										>
											Search
										</Button>
									</Stack>
								</Stack>
								{formErrors.includes('schoolBoardId') && (
									<span className={global.errorText}>School board is required</span>
								)}
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
								<Stack direction="row" mb={1}>
									<CorporateFare className={global.formLabelIcon} />
									<Typography variant="body2" className={global.formLabelText}>
										Total Received Amount
									</Typography>
								</Stack>
								<TextField
									InputProps={{
										startAdornment: <InputAdornment position="start">$</InputAdornment>,
									}}
									label=""
									variant="outlined"
									size="small"
									fullWidth
									value={formData?.totalAmount}
									placeholder={'Enter Total amount'}
									className={walkathon.data?.status === 'Posted' ? classes.disabledInput : ''}
									disabled={walkathon.data?.status === 'Posted'}
									onChange={(e) => handleSchoolBoardChange('totalAmount', e.target.value)}
								/>
								{formErrors.includes('totalAmount') ? (
									<span className={global.errorText}>Total Received Amount is required </span>
								) : formErrors.includes('totalAmountNaN') ? (
									<span className={global.errorText}>Invalid amount</span>
								) : (
									''
								)}
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
								<Stack direction="row" mb={1}>
									<DateRange className={global.formLabelIcon} />
									<Typography variant="body2" className={global.formLabelText}>
										Receive Date
									</Typography>
								</Stack>
								<BasicDatePicker
									size="small"
									value={formData?.receiveDate}
									disabled={walkathon.data?.status === 'Posted'}
									setValue={(e) => handleSchoolBoardChange('receiveDate', e.target.value)}
								/>
								{formErrors.includes('receiveDate') && (
									<span className={global.errorText}>Receive Date is required</span>
								)}
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
								<Stack direction="row" mb={1}>
									<CorporateFare className={global.formLabelIcon} />
									<Typography variant="body2" className={global.formLabelText}>
										Allocated Amount
									</Typography>
								</Stack>
								<TextField
									InputProps={{
										startAdornment: <InputAdornment position="start">$</InputAdornment>,
									}}
									label=""
									variant="outlined"
									size="small"
									fullWidth
									value={schoolTotal?.toLocaleString(undefined, {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									})}
									placeholder={'Enter Total amount'}
									className={walkathon.data?.status === 'Posted' ? classes.disabledInput : ''}
									disabled
								/>
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
								<Stack direction="row" mb={1}>
									<CorporateFare className={global.formLabelIcon} />
									<Typography variant="body2" className={global.formLabelText}>
										Amount Donated by School Board
									</Typography>
								</Stack>
								<TextField
									InputProps={{
										startAdornment: <InputAdornment position="start">$</InputAdornment>,
									}}
									label=""
									variant="outlined"
									size="small"
									fullWidth
									value={
										formData?.totalAmount
											? (formData?.totalAmount - schoolTotal)?.toLocaleString(undefined, {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
											  })
											: 0
									}
									placeholder={'Enter Total amount'}
									className={walkathon.data?.status === 'Posted' ? classes.disabledInput : ''}
									disabled
								/>
							</Grid>
						</Grid>
						<Stack mb={1} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
							<span>School Details</span>
							<Button
								size={'small'}
								variant="outlined"
								onClick={handleAddSchool}
								disabled={walkathon.data?.status === 'Posted'}
							>
								Add School
							</Button>
						</Stack>
						<hr />
						{formData.school.map((item) => (
							<div key={item.key} style={{ marginTop: '10px' }}>
								<WalkathonAccordian
									accordianData={item}
									handleAddSchool={handleSaveSchool}
									handleSetSchoolEdit={handleSetSchoolEdit}
									handleRemoveSchool={handleRemoveSchool}
									handleAddDonor={handleAddDonor}
									handleChangeDonor={handleChangeDonor}
									handleDonorDelete={handleDonorDelete}
									handleToggleExpand={handleToggleExpand}
									isDisabled={walkathon.data?.status === 'Posted'}
								/>
							</div>
						))}
					</Paper>
				</Stack>
			)}
			{visibleSearch.state && (
				<WalkathonAdvancedSearch
					visible={visibleSearch.state}
					handleClose={handleVisibleSearch}
					field={searchFields}
					setField={setSearchFields}
					title={'Search School Board'}
					handleSearch={handleSearch}
				>
					<>
						<Stack alignItems={'flex-end'}>
							<TrimTables
								name={'schoolBoardHead'}
								list={schoolBoardHead}
								trimList={trimList}
								setTrimList={setTrimList}
							/>
						</Stack>
						<SchoolBoardTable
							trimList={trimList}
							setTrimList={setTrimList}
							page={page}
							pageSize={pageSize}
							setPage={setPage}
							setPageSize={setPageSize}
							label={label}
							orderType={orderType}
							setLabel={setLabel}
							setOderBy={setOderBy}
							setOrderType={setOrderType}
							schoolBoards={schoolBoards}
							hcaOrgList
							open={visibleSearch}
							setOpen={setVisibleSearch}
						/>
					</>
				</WalkathonAdvancedSearch>
			)}
			{openConfirmation && (
				<ConfirmationModal
					handleCancel={handleVisibleConfirmation}
					handleConfirmation={handleGenerate}
					open={openConfirmation}
					handleClose={handleVisibleConfirmation}
					message="Do you want to generate a receipt for this walkathon ?"
					loading={receiptLoading}
					sendEmail={sendEmail}
					setSendEmail={setSendEmail}
				/>
			)}
		</>
	);
};

export default CreateWalkathon;
