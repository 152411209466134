import React, { useState } from 'react';
import { Stack, Button, Typography, Box } from '@mui/material';
import { Add } from '@mui/icons-material';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import LifeExpectancyTable, { lifeExpectancyHead } from './LifeExpectancyTable';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import CreateEditLifeExpectancy from 'Components/Annuities/LifeExpectancy/CreateEditLifeExpectancy';
import useLifeExpectancyAdjustment, { LifeExpectancyData } from 'hooks/useLifeExpectancyAdjustment';
import useUserActions from 'hooks/useUserActions';

const LifeExpectancy = () => {
	const { handleRoles } = useUserActions();
	const { deleteLifeExpectancyAdjustment } = useLifeExpectancyAdjustment();
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [filterPayload, setFilterPayload] = useState<Array<any>>([]);
	const [visible, setVisible] = useState(false);
	const [selectedData, setSelectedData] = useState<LifeExpectancyData>();
	const [isDelete, setIsDelete] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [reload, setReload] = useState(false);
	const [page, setPage] = useState(0);

	const handleCloseEdit = () => {
		setVisible(false);
		setSelectedData(undefined);
	};

	const handleOpenEdit = (data: LifeExpectancyData) => {
		setVisible(true);
		setSelectedData(data);
	};

	const handleOpenDelete = (data: LifeExpectancyData) => {
		setIsDelete(true);
		setSelectedData(data);
	};

	const handleCloseDelete = () => {
		setVisible(false);
		setSelectedData(undefined);
	};

	const handleCloseConfirmation = () => {
		setIsDelete(false);
	};

	const handleDelete = async () => {
		if (selectedData) {
			setDeleteLoading(true);
			const isSucess = await deleteLifeExpectancyAdjustment(selectedData.id);
			setDeleteLoading(false);
			handleCloseDelete();
			handleCloseConfirmation();
			if (isSucess) {
				setReload(!reload);
			}
		}
	};

	return (
		<Stack>
			<Stack
				alignItems="center"
				direction={{ xs: 'column', sm: 'row' }}
				justifyContent={'space-between'}
				spacing={1}
				mb={5}
			>
				<Stack direction="column">
					<Stack direction="row" spacing={4} alignItems="center">
						<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
							Life Expectancy Adjustment
						</Typography>
					</Stack>
					<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
						Create and manage life expectancy adjustment here.
					</Typography>
				</Stack>
				<Stack sx={{ mb: 3 }} direction="row" alignItems={'center'} justifyContent="flex-end">
					<TrimTables
						name="lifeExpectancyHead"
						list={lifeExpectancyHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
					{handleRoles('annuities-actions') && (
						<Button
							onClick={() => setVisible(true)}
							sx={{ textTransform: 'none', mr: 2 }}
							startIcon={<Add />}
							variant="contained"
						>
							Add Life Expectancy Adjustment
						</Button>
					)}
					<FilterListButton
						reload={reload}
						setIsReload={setReload}
						setPage={setPage}
						page={page}
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
					/>
				</Stack>
			</Stack>
			<LifeExpectancyTable
				trimList={trimList}
				setTrimList={setTrimList}
				filterPayload={filterPayload}
				setFilterPayload={setFilterPayload}
				handleOpenEdit={handleOpenEdit}
				reload={reload}
				setReload={setReload}
				setPage={setPage}
				page={page}
			/>

			{visible && (
				<BasicModal
					onClose={handleCloseEdit}
					visible={visible}
					title={selectedData ? 'Edit life expectancy adjustment' : 'Add a life expectancy adjustment'}
					data={selectedData || undefined}
					handleOpenDelete={handleOpenDelete}
					isClose={false}
					disabledDelete={selectedData?.isCurrent}
					noDelete={handleRoles('annuities-actions')}
				>
					<CreateEditLifeExpectancy
						selectedEditData={selectedData}
						handleClose={handleCloseEdit}
						reload={reload}
						setReload={setReload}
					/>
				</BasicModal>
			)}
			{isDelete && (
				<ConfirmationModal
					handleClose={handleCloseConfirmation}
					message={'Are you sure you want to delete this life expectancy?'}
					open={isDelete}
					handleConfirmation={handleDelete}
					handleCancel={handleCloseConfirmation}
					loading={deleteLoading}
				/>
			)}
		</Stack>
	);
};

export default LifeExpectancy;
