import { GetAllDepositProps, GetDepositProps } from '../Components/Deposits/Types';
import { ResponseError } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import React from 'react';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';

const useHCADonations = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const getAllDeposit = async ({
		pageNumber,
		sizeOfPage,
		donorQuery,
		batchID,
		allDeposit,
		setAllDeposit,
		setErrorSnack,
		orderBy,
		filterPayload,
	}: GetAllDepositProps) => {
		try {
			setAllDeposit({
				...allDeposit,
				loading: true,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (pageNumber) {
				response = await api.get(
					`hcaAcknowledgement/list?PageNumber=${pageNumber + 1}&PageSize=${sizeOfPage}${
						donorQuery ? '&donorQuery=' + donorQuery : ''
					}${batchID ? '&batchId=' + batchID : ''}${filter}${orderBy || '&SortBy=updated%20desc'}`,
				);
			} else {
				response = await api.get(
					`hcaAcknowledgement/list?&PageSize=${sizeOfPage}${donorQuery ? '&donorQuery=' + donorQuery : ''}${
						batchID ? '&batchId=' + batchID : ''
					}${filter}${orderBy || '&SortBy=updated%20desc'}`,
				);
			}

			setAllDeposit({
				...allDeposit,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || allDeposit.data?.totalRecords,
				},
				error: null,
			});
			setErrorSnack(false);
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAllDeposit({
					...allDeposit,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
			setErrorSnack(true);
		}
	};

	const getHCADonations = async ({ depositId, deposit, setDeposit, setErrorSnack }: GetDepositProps) => {
		try {
			setDeposit({
				...deposit,
				loading: true,
			});
			const response = await api.get(`hcaAcknowledgement/${depositId}`);
			setDeposit({
				...deposit,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
			setErrorSnack(false);
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDeposit({
					...deposit,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
			setErrorSnack(true);
		}
	};

	const createSingleDeposit = async () => {
		try {
			const response = await api.post(`hcaAcknowledgement/createNewAcknowledgements`, {});
			if (response.data?.statusCode === 0) {
				setSimpleErrorSnack({
					severity: 'success',
					show: true,
					message: 'Created Succesfully',
				});
				return true;
			}
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSimpleErrorSnack({
					message: 'Failed to create donation',
					severity: 'error',
					show: true,
				});
				return false;
			}
		}
	};

	return {
		getAllDeposit,
		getHCADonations,
		createSingleDeposit,
	};
};

export default useHCADonations;
