import React, { useState, useEffect, useContext } from 'react';
import useTemplateDepositActions from 'hooks/useTemplateDepositActions';
import { DonationsData, StateType, OrderType } from 'contexts/intialstates/Types';
import { GlobalContexts } from 'contexts/GlobalContext';
import { Button, Stack, TablePagination, Tooltip, Paper } from '@mui/material';
import { pagination } from 'Components/Common/Tables/Tables';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { Edit } from '@material-ui/icons';
import { ColumnType, donorsTableHead } from 'Components/Common/Tables/TableHeaders';
import { convertDate } from 'helpers/dayAndTime';
import { useNavigate } from 'react-router-dom';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import { sortTable } from 'Components/Common/Tables/sortTable';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import useUserActions from 'hooks/useUserActions';

interface P {
	id: number;
}

const DonorTemplateDeposit = ({ id }: P) => {
	const { handleRoles } = useUserActions();
	const { getDonorsTemplateDeposit } = useTemplateDepositActions();
	const [donorsTemplateDeposit, setDonorsTemplateDeposit] = useState<StateType<DonationsData>>({
		loading: false,
		error: null,
		data: null,
	});
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [isReload, setIsReload] = React.useState(false);
	const [filterPayload, setFilterPayload] = React.useState<Array<any>>([]);
	const [orderBy, setOderBy] = React.useState<string>('');
	const [label, setLabel] = React.useState<string>('');
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const { setErrorSnack } = useContext(GlobalContexts);
	const donations = donorsTemplateDeposit?.data?.body;
	const navigate = useNavigate();

	useEffect(() => {
		getDonorsTemplateDeposit({
			donorsTemplateDeposit,
			setDonorsTemplateDeposit,
			setErrorSnack,
			page,
			pageSize,
			donorId: id,
			orderBy,
			filterPayload,
		});
	}, [page, pageSize, orderBy, isReload, isReload]);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleEditDonation = (depositId: number) => {
		navigate(`/bookkeeping/template/deposits/${depositId}/donor/${id}`);
	};

	const getTableData = () => {
		return donations?.length
			? donations.map((row: any, index: number) => {
					return {
						's/n': index + 1,
						depositId: row.depositId,
						receiptNum: row.receiptNum || 'N/A',
						contribution: row.contribution || 'N/A',
						issueDate: row?.issueDate ? `${convertDate(row.issueDate)}` : 'N/A',
						society: row?.society?.code ? row.society.code : 'N/A',
						creditDate: row?.deposit?.creditDate ? `${convertDate(row?.deposit?.creditDate)}` : 'N/A',
						created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
						updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
						remark: row.remark || 'N/A',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType, index) => {
			return {
				...column,
				data: (
					<>
						<Tooltip
							key={column.id}
							title={column.id == 'actions' || column.id == 'remark' ? '' : `Sort by ${column.label}`}
						>
							<span
								style={{ cursor: 'pointer' }}
								onClick={() =>
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									})
								}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							reload={isReload}
							setIsReload={setIsReload}
							setPage={setPage}
							page={page}
						/>
					</>
				),
			};
		});
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	return (
		<>
			<Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }}>
				<TrimTables
					name="donorsTableHead"
					list={donorsTableHead}
					trimList={trimList}
					setTrimList={setTrimList}
				/>
				<FilterListButton
					setFilterPayload={setFilterPayload}
					filterPayload={filterPayload}
					reload={isReload}
					setIsReload={setIsReload}
					setPage={setPage}
					page={page}
				/>
			</Stack>

			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<CustomTable
					loading={donorsTemplateDeposit?.loading}
					columnData={getColumnData()}
					tableData={getTableData()}
					handleDrag={handleDrag}
					handleResize={handleResize}
					emptyMessage={'No donations available'}
					handleRowClick={(row) => handleEditDonation(row.depositId)}
				/>
				{(donations?.length as number) > 0 && (
					<TablePagination
						rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
						count={donorsTemplateDeposit?.data?.totalRecords as number}
						rowsPerPage={pageSize}
						page={page}
						sx={pagination}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
			</Paper>
		</>
	);
};

export default DonorTemplateDeposit;
