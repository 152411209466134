import { ResponseError, Diocese, DioceseData, StateType, FilterPayload } from 'contexts/intialstates/Types';
import useApi from './useApi';
import Axios from 'axios';
import { errorCodes } from 'errorCodes';
import { DioceseValues } from 'Components/AdminPanel/Diocese/CreateEditDiocese/CreateEditDioceseForm';
import moment from 'moment';
export interface GetAllDioceseProps {
	setAllDioceses: React.Dispatch<React.SetStateAction<StateType<DioceseData>>>;
	allDioceses: StateType<DioceseData>;
}

export interface GetDioceseProps {
	setDiocese: React.Dispatch<React.SetStateAction<StateType<Diocese>>>;
	diocese: StateType<Diocese>;
	id: number;
}
interface GetDioceseSearch {
	searchDiocese: StateType<Diocese[]>;
	setSearchDiocese: React.Dispatch<React.SetStateAction<StateType<Diocese[]>>>;
	searchTerm: string;
}

export interface GetDiocesesExportType {
	setDioceseExport: React.Dispatch<React.SetStateAction<StateType<DioceseData>>>;
	dioceseExport: StateType<DioceseData>;
	page: number;
	pageSize: number;
	filterPayload?: FilterPayload[];
	orderBy?: string;
	archive?: string;
}

export interface GetDiocesesType {
	setDioceses: React.Dispatch<React.SetStateAction<StateType<DioceseData>>>;
	dioceses: StateType<DioceseData>;
	page: number;
	pageSize: number;
	filterPayload?: FilterPayload[];
	orderBy?: string;
	archive?: string;
}

const useDioceseActions = () => {
	const { api } = useApi();

	const getAllDiocese = async ({ setAllDioceses, allDioceses }: GetAllDioceseProps) => {
		try {
			setAllDioceses({
				...allDioceses,
				loading: true,
			});
			const getAppeal = await api.get(`/diocese/list?&Filters=field=archived;value=false`);
			setAllDioceses({
				...allDioceses,
				loading: false,
				data: getAppeal?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAllDioceses({
					...allDioceses,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const getDioceseByID = async ({ setDiocese, diocese, id }: GetDioceseProps) => {
		try {
			setDiocese({
				...diocese,
				loading: true,
			});
			const getDiocese = await api.get(`diocese/${id}`);
			setDiocese({
				...diocese,
				loading: false,
				data: getDiocese?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDiocese({
					...diocese,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const getDioceses = async ({
		setDioceses,
		dioceses,
		page,
		pageSize,
		filterPayload,
		orderBy,
		archive,
	}: GetDiocesesType) => {
		try {
			setDioceses({
				...dioceses,
				loading: true,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;

			if (page) {
				response = await api.get(
					`/diocese/list?PageNumber=${page + 1}&PageSize=${pageSize}${archive ? archive : ''}${filter || ''}${orderBy || '&SortBy=updated%20desc'
					}`,
				);
			} else {
				response = await api.get(`/diocese/list?${orderBy || 'SortBy=updated%20desc'
					}&PageSize=${pageSize}${archive ? archive : ''}${filter || ''}`);
			}

			setDioceses({
				...dioceses,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || dioceses.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDioceses({
					...dioceses,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const exportDiocesesCSV = async ({
		setDioceseExport,
		dioceseExport,
		page,
		pageSize,
		filterPayload,
		orderBy,
		archive,
	}: GetDiocesesExportType) => {
		try {
			setDioceseExport({
				...dioceseExport,
				loading: true,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			const response = await api.get(
				`/diocese/export-csv?PageNumber=${page + 1}&PageSize=${pageSize}${archive ? archive : ''}${filter || ''
				}${orderBy || ''}`,
			);
			const url = window.URL.createObjectURL(new Blob([response?.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Diocese_Export_${moment().format('yyMM')}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			setDioceseExport({
				...dioceseExport,
				loading: false,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDioceseExport({
					...dioceseExport,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const createDiocese = async (payload: DioceseValues): Promise<boolean> => {
		try {
			const createDioceseData = await api.post(`diocese/create`, {
				...payload,
			});
			if (createDioceseData?.data?.result) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	const updateDiocese = async (payload: DioceseValues, dioceseId: number): Promise<boolean> => {
		try {
			const updateDioceseData = await api.put(`diocese/${dioceseId}/update`, {
				...payload,
				id: dioceseId,
			});
			if (updateDioceseData?.data?.statusCode === 0) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	const deleteDiocese = async (dioceseId: number): Promise<boolean> => {
		try {
			const deleteDiocese = await api.remove(`diocese/${dioceseId}/archive`);
			if (deleteDiocese?.data?.statusCode === 0) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};
	const restoreDiocese = async (dioceseId: number): Promise<boolean> => {
		try {
			const deleteDiocese = await api.post(`diocese/${dioceseId}/unarchive`);
			if (deleteDiocese?.data?.statusCode === 0) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};
	const getDioceseBySearch = async ({ searchDiocese, setSearchDiocese, searchTerm }: GetDioceseSearch) => {
		try {
			setSearchDiocese({
				...searchDiocese,
				loading: true,
			});
			const response = await api.get(
				`diocese/search?dioceseQuery=${searchTerm}&Filters=field=archived;value=false`,
			);

			setSearchDiocese({
				...searchDiocese,
				loading: false,
				data: response?.data?.result,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSearchDiocese({
					...searchDiocese,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	return {
		getAllDiocese,
		getDioceseByID,
		getDioceses,
		createDiocese,
		updateDiocese,
		deleteDiocese,
		getDioceseBySearch,
		restoreDiocese,
		exportDiocesesCSV,
	};
};

export default useDioceseActions;
