import React from 'react';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { Paper, Checkbox, Stack } from '@mui/material';
import { StateType } from 'contexts/intialstates/Types';
import { EFTPaymentData } from 'hooks/useAnnuitiesActions';
import { convertDate } from 'helpers/dayAndTime';

export const eftGenerateAnnuities: ColumnType[] = [
	{
		id: 's/n',
		label: 'Select',
		minWidth: 90,
		align: 'center',
	},
	{
		id: 'fullName',
		label: 'Full Name',
		minWidth: 220,
		align: 'center',
	},
	{
		id: 'dueDate',
		label: 'Due Date',
		minWidth: 220,
		align: 'center',
	},
	{
		id: 'account',
		label: 'Account',
		minWidth: 220,
		align: 'center',
	},
	{
		id: 'transit',
		label: 'Transit',
		minWidth: 220,
		align: 'center',
	},
	{
		id: 'annuity',
		label: 'Annuity',
		minWidth: 220,
		align: 'center',
	},
	{
		id: 'sin',
		label: 'SIN',
		minWidth: 220,
		align: 'center',
	},
];

interface P {
	agreementIds: number[];
	handleCheck: (checked: boolean, id: number) => void;
	eftPayment: StateType<EFTPaymentData>;
	setEftPayment: React.Dispatch<React.SetStateAction<StateType<EFTPaymentData>>>;
	trimList: ColumnType[];
	setTrimList: React.Dispatch<React.SetStateAction<ColumnType[]>>;
}

const EFTTable = ({ agreementIds, handleCheck, eftPayment, setEftPayment, trimList, setTrimList }: P) => {
	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};
	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	const getColumnData = () => {
		return trimList;
	};

	const getTableData = () => {
		return eftPayment?.data?.payments.map((row: any, index: number) => {
			return {
				's/n': (
					<Checkbox
						checked={agreementIds.includes(row.id)}
						onChange={(e) => handleCheck(e.target.checked, row.id)}
					/>
				),
				id: row.id,
				fullName: row.fullName,
				dueDate: row.dueDate ? convertDate(row.dueDate) : 'N/A',
				account: row.account,
				transit: row.transit,
				annuity: row.annuity,
				sin: row.sin,
			};
		});
	};

	const handleRowClick = (row: any) => {
		if (agreementIds.includes(row.id)) {
			handleCheck(false, row.id);
		} else {
			handleCheck(true, row.id);
		}
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<CustomTable
				loading={eftPayment?.loading}
				columnData={getColumnData()}
				tableData={getTableData()}
				handleDrag={handleDrag}
				handleResize={handleResize}
				emptyMessage={'No data available'}
				handleRowClick={handleRowClick}
			/>
		</Paper>
	);
};

export default EFTTable;
