import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { TaskAlt } from '@mui/icons-material';
import { Stack, Box } from '@mui/material';
import useReportActions from 'hooks/useReportAction';
import { LoadingButton } from '@mui/lab';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import { MiniCard } from '../Card';
import moment from 'moment';

const SplitDonations = () => {
	const [date, setDate] = useState('');
	const [formError, setFormError] = useState(false);

	const { getSplitDonationsCSV } = useReportActions();

	const [loading, setLoading] = useState(false);

	const handleExportCSV = async () => {
		if (!date) {
			setFormError(true);
			return;
		}
		setLoading(true);
		await getSplitDonationsCSV(moment(date).format('YYYY-MM-DD'));
		setLoading(false);
	};
	return (
		<Box mb={2}>
			<Stack mb={2} direction={'column'}>
				<Typography variant="h5" sx={{ color: '#1976D2' }}>
					Split Donation Reports
				</Typography>
			</Stack>
			<MiniCard title="Configure">
				<>
					<Stack sx={{ mb: 3 }}>
						<Stack direction="row" spacing={5} alignItems="center">
							<Stack direction="column" spacing={2}>
								<Typography sx={{ color: 'var(--formLabelColor)', fontSize: 14, fontWeight: 600 }}>
									Date
								</Typography>

								<BasicDatePicker
									label="Select Date"
									value={date}
									setValue={(e) => {
										setFormError(false);
										setDate(e.target.value);
									}}
								/>
							</Stack>
							<Stack direction="row" sx={{ transform: 'translateY(20px)' }} spacing={3}>
								<LoadingButton
									onClick={handleExportCSV}
									sx={{ textTransform: 'none', height: 40 }}
									startIcon={<TaskAlt />}
									variant="contained"
									loadingPosition="start"
									loading={loading}
								>
									Generate
								</LoadingButton>
							</Stack>
						</Stack>
						{formError && <span style={{ color: 'red', fontSize: 13 }}>Date is required</span>}
					</Stack>
				</>
			</MiniCard>
		</Box>
	);
};

export default SplitDonations;
