import React, { useRef } from 'react';
import { Stack, Table, Box, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import FormatCurrency, { formatNumberFunc } from 'helpers/FormatCurrency';
import { PaymentListReport } from 'hooks/useReportAction';
import { StateType } from 'contexts/intialstates/Types';
import Logo from 'assets/images/logo.png';
import { useReactToPrint } from 'react-to-print';
import { useStyles } from '../style';
import Report from 'Components/Reports/Report';

interface Props {
	paymentList: StateType<PaymentListReport[]>;
	setPaymentList?: React.Dispatch<React.SetStateAction<StateType<PaymentListReport[]>>>;
	year: number;
	month: number;
	handleGetReportPDF: () => void;
}

const ReportView = ({ paymentList, month, year, handleGetReportPDF }: Props) => {
	const classes = useStyles();
	const componentRef = useRef(null);
	let agreementTotal = 0;
	let same = 0;
	let annuityTotal = 0;
	let taxableTotal = 0;
	let newAnnuityTotal: any;
	let newTaxableTotal: any;

	const splitter = () => {
		if (!paymentList.data?.length) return;
		const newPaymentList: any = [];
		const data = [];
		paymentList.data?.map((row, index, arr) => {
			if (arr[index + 1]?.annuitantDonorId === arr[index]?.annuitantDonorId) {
				same = 0;
				agreementTotal += 1;

				newTaxableTotal = 0;
				taxableTotal = taxableTotal + arr[index]?.taxableAmount;

				newAnnuityTotal = 0;
				annuityTotal = annuityTotal + arr[index]?.annuityAmount;
			} else {
				same = agreementTotal + 1;
				agreementTotal = 0;

				newAnnuityTotal = annuityTotal + arr[index]?.annuityAmount;
				annuityTotal = 0;

				newTaxableTotal = taxableTotal + arr[index]?.taxableAmount;
				taxableTotal = 0;
			}

			newPaymentList.push({
				agreementNumber: (
					<span>
						{row?.agreementNumber || '-'}
						<br />
						{row?.agreementStatus || '-'}
						<br />
						{row?.agreementType || '-'}
					</span>
				),
				anId: (
					<span>
						{row?.annuitantDonorId || '-'}
						<br />
						{row?.annuitantSIN || '-'}
						<br />
						{row?.annuitantName || '-'}
					</span>
				),
				anDod: row.annuitantDateOfDeath || '-',

				coAnId: (
					<span>
						{row?.coAnnuitantDonorId || '-'}
						<br />
						{row?.coAnnuitantSIN || '-'}
						<br />
						{row?.coAnnuitantName || '-'}
					</span>
				),
				coAnDod: row.coAnnuitantDateOfDeath || '-',
				paymentDate: <span>{row?.dateOfPayment || '-'}</span>,
				bankName: (
					<span>
						{row?.bankName || '-'}
						<br />
						{row?.bankBranch || '-'}
						<br />
						{row?.accountNumber || '-'}
					</span>
				),
				transit: <span>{row.transit || '-'}</span>,
				annuityAmount: <span>{row.annuityAmount ? <FormatCurrency value={row.annuityAmount} /> : '-'},</span>,
				taxableAmount: row.taxableAmount ? <FormatCurrency value={row.taxableAmount} /> : '-',
				id: row.annuitantDonorId || '-',
				same: same,
				showBorder: arr[index + 1]?.annuitantDonorId !== arr[index]?.annuitantDonorId,
				newAnnuityTotal: newAnnuityTotal.toFixed(2),
				newTaxableTotal: newTaxableTotal.toFixed(2),
			});
		});
		for (let i = 0; i < newPaymentList.length; i += 4) {
			data.push(newPaymentList.slice(i, i + 4));
		}
		return data;
	};
	const renderData = () => {
		if (!paymentList.data?.length) return;
		const newPaymentList: any = [];
		paymentList.data?.map((row, index, arr) => {
			if (arr[index + 1]?.annuitantDonorId === arr[index]?.annuitantDonorId) {
				same = 0;
				agreementTotal += 1;

				newTaxableTotal = 0;
				taxableTotal = taxableTotal + arr[index]?.taxableAmount;

				newAnnuityTotal = 0;
				annuityTotal = annuityTotal + arr[index]?.annuityAmount;
			} else {
				same = agreementTotal + 1;
				agreementTotal = 0;

				newAnnuityTotal = annuityTotal + arr[index]?.annuityAmount;
				annuityTotal = 0;

				newTaxableTotal = taxableTotal + arr[index]?.taxableAmount;
				taxableTotal = 0;
			}

			newPaymentList.push({
				agreementNumber: (
					<span>
						{row?.agreementNumber || '-'}
						<br />
						{row?.agreementStatus || '-'}
						<br />
						{row?.agreementType || '-'}
					</span>
				),
				anId: (
					<span>
						{row?.annuitantDonorId || '-'}
						<br />
						{row?.annuitantSIN || '-'}
						<br />
						{row?.annuitantName || '-'}
					</span>
				),
				anDod: row.annuitantDateOfDeath || '-',

				coAnId: (
					<span>
						{row?.coAnnuitantDonorId || '-'}
						<br />
						{row?.coAnnuitantSIN || '-'}
						<br />
						{row?.coAnnuitantName || '-'}
					</span>
				),
				coAnDod: row.coAnnuitantDateOfDeath || '-',
				paymentDate: <span>{row?.dateOfPayment || '-'}</span>,
				bankName: (
					<span>
						{row?.bankName || '-'}
						<br />
						{row?.bankBranch || '-'}
						<br />
						{row?.accountNumber || '-'}
					</span>
				),
				transit: <span>{row.transit || '-'}</span>,
				annuityAmount: <span>{row.annuityAmount ? <FormatCurrency value={row.annuityAmount} /> : '-'},</span>,
				taxableAmount: row.taxableAmount ? <FormatCurrency value={row.taxableAmount} /> : '-',
				id: row.annuitantDonorId || '-',
				same: same,
				showBorder: arr[index + 1]?.annuitantDonorId !== arr[index]?.annuitantDonorId,
				newAnnuityTotal: newAnnuityTotal.toFixed(2),
				newTaxableTotal: newTaxableTotal.toFixed(2),
			});
		});
		return newPaymentList;
	};

	const tableData = renderData();

	const printData = () => {
		const splitData = splitter();
		return (
			<TableContainer>
				{splitData?.map((data, index) => {
					return (
						<div className={classes.pageBreak} key={index}>
							<Table sx={{ minWidth: 700 }} key={index} aria-label="spanning table">
								<TableHead className={classes.tableHead}>
									<TableRow>
										<TableCell align="center">
											Agreement # <br />
											Status
											<br />
											Form
										</TableCell>
										<TableCell align="center">
											ID
											<br />
											S.I.N
											<br />
											Name
										</TableCell>
										<TableCell align="center">DOD</TableCell>
										<TableCell align="center">
											<span style={{ borderBottom: '1px solid black', padding: 0, margin: 0 }}>Co</span>{' '}
											<br />
											ID
											<br />
											S.I.N
											<br />
											Name{' '}
										</TableCell>
										<TableCell align="center">
											<span style={{ borderBottom: '1px solid black', padding: 0, margin: 0 }}>Co</span>{' '}
											<br />
											DOD
										</TableCell>
										<TableCell align="center">Date Of Payment</TableCell>
										<TableCell align="center">
											Bank Name
											<br />
											Branch
											<br />
											Account #
										</TableCell>
										<TableCell align="center">Transit #</TableCell>
										<TableCell align="center">
											Annuity
											<br />
											Amount
										</TableCell>
										<TableCell align="center">
											Taxable
											<br />
											Amount
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map((row: any, index: number) => {
										return (
											<>
												{
													<>
														<TableRow key={index} className={classes.tableRowWrap}>
															<TableCell align="center">{row?.agreementNumber || '-'}</TableCell>
															<TableCell align="center">{row?.anId || '-'}</TableCell>
															<TableCell align="center">{row?.anDod || '-'}</TableCell>
															<TableCell align="center">{row?.coAnId || '-'}</TableCell>
															<TableCell align="center">{row?.coAnDod || '-'}</TableCell>
															<TableCell align="center">{row?.paymentDate || '-'}</TableCell>
															<TableCell align="center">{row?.bankName || '-'}</TableCell>
															<TableCell align="center">{row?.transit || '-'}</TableCell>
															<TableCell align="center">{row?.annuityAmount || '-'}</TableCell>
															<TableCell align="center">{row?.taxableAmount || '-'}</TableCell>
														</TableRow>
														{row.showBorder && (
															<TableCell sx={{ p: 0, m: 0, borderBottom: 'none' }} colSpan={10}>
																<Stack
																	alignItems="center"
																	direction="row"
																	sx={{ width: '100%' }}
																	justifyContent="space-between"
																>
																	<Box sx={{ borderTop: '1px solid black', width: '50%' }}></Box>
																	<Stack
																		direction="row"
																		sx={{ width: '50%', pr: 3 }}
																		spacing={5}
																		justifyContent="flex-end"
																	>
																		<Box>Agreements {row.same}</Box>
																		<Box>{row.newAnnuityTotal}</Box>
																		<Box>{row.newTaxableTotal}</Box>
																	</Stack>{' '}
																</Stack>{' '}
															</TableCell>
														)}
													</>
												}
											</>
										);
									})}
									{index === splitData.length - 1 && (
										<TableRow className={classes.tableTotal2}>
											<TableCell colSpan={10}>
												<Stack sx={{ fontSize: 14, fontWeight: 700 }} direction="row" width="100%">
													<Stack width="40%"></Stack>
													<Stack width="60%">
														<Stack
															width="100%"
															direction="row"
															mb={0.5}
															py={1}
															sx={{
																px: 2,
															}}
														>
															<Stack direction="row" width={'100%'}>
																{paymentList.data?.length ? (
																	<Stack width={'100%'} direction="row" justifyContent="flex-end" spacing={4}>
																		<span>
																			{formatNumberFunc(
																				paymentList.data?.reduce((total, curr, index, arr) => arr.length, 0),
																			)}{' '}
																			Agreements
																		</span>{' '}
																		<Box sx={{ borderBottom: '2px solid black', pb: 0.25 }}>
																			<Box sx={{ borderBottom: '2px solid black' }}>
																				<FormatCurrency
																					value={paymentList.data?.reduce(
																						(total, curr) => total + curr.annuityAmount,
																						0,
																					)}
																				/>
																			</Box>
																		</Box>
																		<Box sx={{ borderBottom: '2px solid black', pb: 0.25 }}>
																			<Box sx={{ borderBottom: '2px solid black' }}>
																				<FormatCurrency
																					value={paymentList.data?.reduce(
																						(total, curr) => total + curr.taxableAmount,
																						0,
																					)}
																				/>
																			</Box>
																		</Box>
																	</Stack>
																) : (
																	'-'
																)}
															</Stack>
														</Stack>
													</Stack>
												</Stack>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</div>
					);
				})}
			</TableContainer>
		);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const getReport = async () => {
		if (!paymentList.data?.length) return;
		handlePrint();
	};

	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	return (
		<div>
			<Report
				title="Payment List Report"
				subTitle={`List of Annuity payments due for the month of ${months[month - 1]} ${year}`}
				handlePrint={handleGetReportPDF}
			>
				<TableContainer>
					<Table sx={{ minWidth: 700 }} aria-label="spanning table">
						<TableHead className={classes.tableHeadViewx}>
							<TableRow>
								<TableCell align="center">
									Agreement # <br />
									Status
									<br />
									Form
								</TableCell>
								<TableCell align="center">
									ID
									<br />
									S.I.N
									<br />
									Name
								</TableCell>
								<TableCell align="center">DOD</TableCell>
								<TableCell align="center">
									<span style={{ borderBottom: '1px solid black', padding: 0, margin: 0 }}>Co</span> <br />
									ID
									<br />
									S.I.N
									<br />
									Name{' '}
								</TableCell>
								<TableCell align="center">
									<span style={{ borderBottom: '1px solid black', padding: 0, margin: 0 }}>Co</span> <br />
									DOD
								</TableCell>
								<TableCell align="center">Date Of Payment</TableCell>
								<TableCell align="center">
									Bank Name
									<br />
									Branch
									<br />
									Account #
								</TableCell>
								<TableCell align="center">Transit #</TableCell>
								<TableCell align="center">
									Annuity
									<br />
									Amount
								</TableCell>
								<TableCell align="center">
									Taxable
									<br />
									Amount
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{tableData?.map((row: any, index: number) => {
								return (
									<>
										{
											<>
												<TableRow key={index} className={classes.tableRowWrap}>
													<TableCell align="center">{row?.agreementNumber || '-'}</TableCell>
													<TableCell align="center">{row?.anId || '-'}</TableCell>
													<TableCell align="center">{row?.anDod || '-'}</TableCell>
													<TableCell align="center">{row?.coAnId || '-'}</TableCell>
													<TableCell align="center">{row?.coAnDod || '-'}</TableCell>
													<TableCell align="center">{row?.paymentDate || '-'}</TableCell>
													<TableCell align="center">{row?.bankName || '-'}</TableCell>
													<TableCell align="center">{row?.transit || '-'}</TableCell>
													<TableCell align="center">{row?.annuityAmount || '-'}</TableCell>
													<TableCell align="center">{row?.taxableAmount || '-'}</TableCell>
												</TableRow>
												{row.showBorder && (
													<TableCell sx={{ p: 0, m: 0, borderBottom: 'none' }} colSpan={10}>
														<Stack
															alignItems="center"
															direction="row"
															sx={{ width: '100%' }}
															justifyContent="space-between"
														>
															<Box sx={{ borderTop: '1px solid black', width: '50%' }}></Box>
															<Stack
																direction="row"
																sx={{ width: '50%', pr: 3 }}
																spacing={5}
																justifyContent="flex-end"
															>
																<Box>Agreements {row.same}</Box>
																<Box>{row.newAnnuityTotal}</Box>
																<Box>{row.newTaxableTotal}</Box>
															</Stack>{' '}
														</Stack>{' '}
													</TableCell>
												)}
											</>
										}
									</>
								);
							})}

							<TableRow className={classes.tableTotal2}>
								<TableCell colSpan={10}>
									<Stack sx={{ fontSize: 14, fontWeight: 700 }} direction="row" width="100%">
										<Stack width="40%"></Stack>
										<Stack width="60%">
											<Stack
												width="100%"
												direction="row"
												mb={0.5}
												py={1}
												sx={{
													px: 2,
												}}
											>
												<Stack direction="row" width={'100%'}>
													{paymentList.data?.length ? (
														<Stack width={'100%'} direction="row" justifyContent="flex-end" spacing={4}>
															<span>
																{formatNumberFunc(
																	paymentList.data?.reduce((total, curr, index, arr) => arr.length, 0),
																)}{' '}
																Agreements
															</span>{' '}
															<Box sx={{ borderBottom: '2px solid black', pb: 0.25 }}>
																<Box sx={{ borderBottom: '2px solid black' }}>
																	<FormatCurrency
																		value={paymentList.data?.reduce(
																			(total, curr) => total + curr.annuityAmount,
																			0,
																		)}
																	/>
																</Box>
															</Box>
															<Box sx={{ borderBottom: '2px solid black', pb: 0.25 }}>
																<Box sx={{ borderBottom: '2px solid black' }}>
																	<FormatCurrency
																		value={paymentList.data?.reduce(
																			(total, curr) => total + curr.taxableAmount,
																			0,
																		)}
																	/>
																</Box>
															</Box>
														</Stack>
													) : (
														'-'
													)}
												</Stack>
											</Stack>
										</Stack>
									</Stack>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Report>
			<div style={{ display: 'none' }}>
				<div style={{ padding: 16 }} ref={componentRef}>
					<Stack sx={{ mt: 2, px: 1 }}>
						<>
							<Stack width="100%" direction="row">
								<Stack flex={0.5} alignItems="flex-start" direction="column">
									<img src={Logo} alt="logo" width={40} height={50} />
								</Stack>
							</Stack>
							<Stack
								direction="row"
								justifyContent="center"
								sx={{ fontWeight: 600, fontSize: 17, textAlign: 'center' }}
							>
								List of Annuity payments due for the month of {months[month - 1]} {year}
							</Stack>
						</>

						<Box width="100%">{printData()}</Box>
					</Stack>
				</div>
			</div>
		</div>
	);
};

export default ReportView;
