import React, { useState, useEffect } from 'react';
import { Stack, Button, Tooltip } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ArrowBackIosNew, AddTask, Add, Delete, Print } from '@mui/icons-material';
import { useStyles } from '../DepositSlip.style';
import { useNavigate, useParams } from 'react-router-dom';
import DepositSlipHeader from './DepositSlipHeader';
import DepositSlipForm from './DepositSlipForm';
import useDepositSlipActions, { DepositSlip } from 'hooks/useDepositSlipAction';
import { StateType } from 'contexts/intialstates/Types';
import { ContentSkeleton } from 'Components/Deposits/CreateEditDepositWithDonations/Content';
import ActionModal from 'Components/Reusable/Modals/ActionModal/ActionModal';
import useUserActions from 'hooks/useUserActions';

export interface TotalCount {
	total: number;
	totalAmount: number;
	deposited: number;
	depositedAmount: number;
	cash: number;
	cashAmount: number;
	cheque: number;
	chequeAmount: number;
}

const CreateDepositSlip = () => {
	const { handleRoles } = useUserActions();
	const classes = useStyles();
	const navigate = useNavigate();
	const { id } = useParams();
	const {
		createDepositSlip,
		getDepositSlipById,
		updateDepositSlip,
		deleteDepositSlip,
		postDepositSlip,
		printDepositSlip,
	} = useDepositSlipActions();
	const [saveLoading, setSaveLoading] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [postLoading, setPostLoading] = useState(false);
	const [printLoading, setPrintLoading] = useState(false);
	const [reload, setReload] = useState(false);
	const [depositSlip, setDepositSlip] = useState<StateType<DepositSlip>>({
		data: null,
		loading: false,
		error: null,
	});
	const [formData, setFormData] = useState<DepositSlipForm>({
		contributions: [],
		status: 'Pending',
		error: [],
	});
	const [totalData, setTotalData] = useState<TotalCount>({
		total: 0,
		totalAmount: 0,
		deposited: 0,
		depositedAmount: 0,
		cash: 0,
		cashAmount: 0,
		cheque: 0,
		chequeAmount: 0,
	});
	const [openModal, setOpenModal] = useState(false);
	const [openPostModal, setOpenPostModal] = useState(false);
	const [openStatusValidation, setOpenStatusValidation] = useState(false);

	useEffect(() => {
		if (!id) return;
		getDepositSlipById({ depositSlip, setDepositSlip, id: parseInt(id) });
	}, [id, reload]);

	useEffect(() => {
		const data = depositSlip?.data;
		if (data) {
			setFormData({
				societyId: data?.society.id,
				status: data?.status,
				bankDepositDate: data.bankDepositDate,
				error: [],
				contributions: data.contributions.map(
					({
						id,
						donor,
						paymentMethod,
						amount,
						chequeNumber,
						donationType,
						appeal,
						isWalkathon,
						organizationId,
						organizationName,
						organizationType,
						status,
						remark,
					}) => {
						return {
							id,
							donorId: donor && parseInt(donor.id),
							donorName: donor ? `${donor.firstName} ${donor.lastName}` : '',
							paymentMethodId: paymentMethod.id,
							paymentMethodName: paymentMethod.name,
							amount,
							chequeNumber,
							donationTypeId: donationType?.id,
							donationTypeName: donationType?.description,
							appealId: appeal?.id,
							isWalkathon,
							organizationType,
							organizationId,
							organizationName,
							statusId: status?.id,
							statusName: status?.status,
							remark,
						};
					},
				),
			});
		}
	}, [depositSlip?.data]);

	useEffect(() => {
		handleCheckTotal();
	}, [formData.contributions]);

	const handleCheckTotal = () => {
		let totalAmount = 0;
		let deposited = 0;
		let depositedAmount = 0;
		let cash = 0;
		let cashAmount = 0;
		let cheque = 0;
		let chequeAmount = 0;
		formData.contributions.map(({ amount, statusName, paymentMethodName }) => {
			totalAmount = totalAmount + amount;
			if (statusName === 'Pass') {
				deposited = deposited + 1;
				depositedAmount = depositedAmount + amount;
			}
			if (paymentMethodName === 'Cash') {
				cash = cash + 1;
				cashAmount = cashAmount + amount;
			}
			if (paymentMethodName === 'Cheque') {
				cheque = cheque + 1;
				chequeAmount = chequeAmount + amount;
			}
		});

		setTotalData({
			total: formData.contributions.length,
			totalAmount,
			deposited,
			depositedAmount,
			cash,
			cashAmount,
			cheque,
			chequeAmount,
		});
	};

	const handleSave = async () => {
		const formValues: DepositSlipForm = {
			...formData,
			contributions: formData.contributions.map((item) => {
				const error = [];
				if (!item.donationTypeId) error.push('donationTypeId');
				if (!item.paymentMethodId) error.push('paymentMethodId');
				if (!item.amount) error.push('amount');
				return {
					...item,
					error,
				};
			}),
		};

		if (!formValues.contributions?.length) {
			setOpenModal(true);
		}
		let isError = false;
		if (!formValues.societyId) {
			isError = true;
			formValues.error.push('societyId');
		} else {
			for (const contribution of formValues.contributions) {
				if (contribution?.error?.length) {
					isError = true;
					break;
				}
			}
		}

		if (isError || !formValues.contributions?.length) {
			setFormData(formValues);
		} else {
			const payload = {
				societyId: formData.societyId,
				bankDepositDate: formData.bankDepositDate,
				contributions: formData.contributions.map(
					({
						donorId,
						paymentMethodId,
						amount,
						chequeNumber,
						donationTypeId,
						appealId,
						isWalkathon,
						organizationType,
						organizationId,
						statusId,
						remark,
					}) => {
						return {
							donorId,
							paymentMethodId,
							amount,
							chequeNumber,
							donationTypeId,
							appealId,
							isWalkathon,
							organizationType,
							organizationId,
							statusId,
							remark,
						};
					},
				),
			};

			setSaveLoading(true);
			let isSucess;
			if (id) {
				isSucess = await updateDepositSlip({ ...payload, id: parseInt(id) }, parseInt(id));
			} else {
				isSucess = await createDepositSlip(payload);
			}
			setSaveLoading(false);
			if (isSucess) {
				navigate('/bookkeeping/deposit-slip');
			}
		}
	};

	const handleDelete = async () => {
		if (!id) return;
		setDeleteLoading(true);
		const isSucess = await deleteDepositSlip(parseInt(id));
		if (isSucess) {
			navigate('/bookkeeping/deposit-slip');
		}
		setDeleteLoading(false);
	};

	const handlePost = async () => {
		let isError = false;
		const formValues: DepositSlipForm = {
			...formData,
			contributions: formData.contributions.map((item) => {
				const error = [];
				if (!item.statusId) {
					error.push('status');
					isError = true;
				}
				return {
					...item,
					error,
				};
			}),
		};

		if (!formData.bankDepositDate) {
			isError = true;
			formValues.error.push('bankDepositDate');
		}

		if (isError) {
			setFormData(formValues);
			setOpenStatusValidation(true);
		} else {
			setOpenPostModal(true);
		}
	};

	const handleConfirmPost = async () => {
		if (!id) return;
		setPostLoading(true);
		const isSucess = await postDepositSlip(parseInt(id));
		if (isSucess) {
			navigate('/bookkeeping/deposit-slip');
		}
		setPostLoading(false);
		setOpenPostModal(false);
	};

	const handlePrint = async () => {
		if (!id) return;
		setPrintLoading(true);
		await printDepositSlip(parseInt(id));
		setPrintLoading(false);
		setReload(!reload);
	};

	return (
		<>
			{depositSlip?.loading ? (
				<ContentSkeleton />
			) : (
				<Stack spacing={3}>
					<Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap={'wrap'}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={4}
							marginBottom={2}
						>
							<Stack className={classes.backIconWrapper}>
								<ArrowBackIosNew onClick={() => navigate(-1)} />
							</Stack>
							<Stack alignItems={'flex-start'}>
								<span className={classes.title}>Deposit Slip</span>
								<span className={classes.subTitle}>{formData.status}</span>
							</Stack>
						</Stack>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems={'center'}
							spacing={2}
							marginBottom={2}
						>
							{id && handleRoles('bookkeeping-actions') && (
								<>
									<LoadingButton
										loadingPosition="start"
										loading={deleteLoading}
										startIcon={<Delete />}
										size={'small'}
										variant="contained"
										className={formData.status === 'Pending' ? classes.whiteBtn : ''}
										onClick={handleDelete}
										disabled={formData.status !== 'Pending'}
									>
										Delete
									</LoadingButton>
									<Tooltip
										title={
											depositSlip.data?.contributions.some((ele) => !ele?.status?.status)
												? 'Please assign a status to all deposits then save to be able to post this deposit slip'
												: formData.status === 'Posted'
												? 'This Deposit slip has been posted'
												: ''
										}
									>
										<span>
											{' '}
											<Button
												startIcon={<AddTask />}
												size={'small'}
												variant="contained"
												disabled={
													formData.status === 'Posted' ||
													depositSlip.data?.contributions.some((ele) => !ele?.status?.status)
												}
												onClick={handlePost}
											>
												Post
											</Button>
										</span>
									</Tooltip>

									<LoadingButton
										loadingPosition="start"
										loading={printLoading}
										startIcon={<Print />}
										size={'small'}
										variant="contained"
										className={classes.whiteBtn}
										onClick={handlePrint}
									>
										Print
									</LoadingButton>
								</>
							)}
							{handleRoles('bookkeeping-actions') && (
								<LoadingButton
									loadingPosition="start"
									loading={saveLoading}
									startIcon={<Add />}
									size={'small'}
									variant="contained"
									onClick={handleSave}
									disabled={formData.status === 'Posted'}
								>
									Save
								</LoadingButton>
							)}
						</Stack>
					</Stack>
					<DepositSlipHeader totalData={totalData} />
					<DepositSlipForm formData={formData} setFormData={setFormData} />
				</Stack>
			)}
			{openModal && (
				<ActionModal
					open={openModal}
					title={'Required field'}
					subTitle={'Please add atleast one deposit to save deposit slip'}
					closeText={'Close'}
					handleClose={() => setOpenModal(false)}
				/>
			)}
			{openPostModal && (
				<ActionModal
					open={openPostModal}
					title={'Post Deposit Slip'}
					subTitle={'Are you sure you want to Post this Deposit Slip?'}
					description={`Note: Once a Deposit slip is Posted you will not be able to make any changes and 
					all the Deposits with Status marked as Pass will be converted into a Batch.`}
					submitText={'Post'}
					handleSubmit={handleConfirmPost}
					closeText={'Cancel'}
					handleClose={() => setOpenPostModal(false)}
					loading={postLoading}
				/>
			)}
			{openStatusValidation && (
				<ActionModal
					open={openStatusValidation}
					title={'Please save required field to Post'}
					closeText={'Cancel'}
					handleClose={() => setOpenStatusValidation(false)}
				/>
			)}
		</>
	);
};

export default CreateDepositSlip;
