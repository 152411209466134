import React, { FC, useEffect, useState } from 'react';
import { TextFieldProps } from '@material-ui/core/TextField';
import { fieldToTextField } from 'formik-material-ui';
import { Autocomplete, TextField } from '@mui/material';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filter = createFilterOptions();

const FormAutocomplete: FC<any> = ({
	textFieldProps,
	onType,
	includeInputInList = true,
	filterSelectedOptions = true,
	...props
}) => {
	const {
		form: { setTouched, setFieldValue },
		onChange,
	} = props;
	const { error, helperText, ...field } = fieldToTextField(props);
	const { name, value }: any = field;
	const [open, setOpen] = useState(false);

	const [inputValue, setInputValue] = useState(props.value);

	useEffect(() => {
		if (value) setInputValue(props?.getOptionLabel ? props?.getOptionLabel(value) : value?.label || value);
	}, [value]);

	return (
		<Autocomplete
			{...props}
			{...field}
			onChange={(_: any, value: any) => {
				if (onChange) onChange(value);
				setFieldValue(name, value);
			}}
			onBlur={() => setTouched({ [name]: true })}
			getOptionSelected={(item: { value: any }, current: { value: any }) => item?.value === current?.value}
			onInputChange={(_, value) => {
				if (onType) onType(value);
				setInputValue(value);
			}}
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			includeInputInList={includeInputInList}
			filterSelectedOptions={filterSelectedOptions}
			size="small"
			renderInput={(props: JSX.IntrinsicAttributes & TextFieldProps) => (
				<TextField
					{...props}
					{...textFieldProps}
					helperText={helperText}
					error={error}
					inputProps={{
						...props.inputProps,
						autoComplete: 'off',
						value: inputValue,
					}}
					size="small"
				/>
			)}
		/>
	);
};

export const FormFreeSoloAutocomplete: FC<any> = ({ textFieldProps, onType, ...props }) => {
	const {
		form: { setTouched, setFieldValue },
		onChange,
	} = props;
	const { error, helperText, ...field } = fieldToTextField(props);
	const { name }: any = field;
	const [open, setOpen] = useState(false);

	const [inputValue, setInputValue] = useState(props.value);

	return (
		<Autocomplete
			{...props}
			{...field}
			freeSolo
			clearOnBlur
			open={open}
			selectOnFocus
			handleHomeEndKeys
			includeInputInList
			filterSelectedOptions
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			onBlur={() => setTouched({ [name]: true })}
			onChange={(_: any, selected: any) => {
				if (onChange) onChange(selected);
				if (selected && selected.inputValue) {
					setFieldValue(name, {
						label: selected.inputValue,
						value: selected.inputValue.toUpperCase(),
					});
				} else {
					setFieldValue(name, selected);
				}
			}}
			getOptionSelected={(item: { value: any }, current: { value: any }) => item.value === current.value}
			onInputChange={(_, value) => {
				if (onType) onType(value);
				setInputValue(value);
			}}
			filterOptions={(options, params: any) => {
				const filtered = filter(options, params);

				if (params.inputValue !== '') {
					filtered.push({
						inputValue: params.inputValue,
						label: `Add "${params.inputValue}"`,
					});
				}

				return filtered;
			}}
			getOptionLabel={(option: any) => {
				if (typeof option === 'string') return option;
				return option.label;
			}}
			renderInput={(props: JSX.IntrinsicAttributes & TextFieldProps) => (
				<TextField
					{...props}
					error={error}
					{...textFieldProps}
					helperText={helperText}
					inputProps={{
						...props.inputProps,
						autoComplete: 'off',
						value: inputValue,
					}}
					size="small"
				/>
			)}
		/>
	);
};

export default FormAutocomplete;
