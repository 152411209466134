import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Button, Typography } from '@mui/material';
import { Add, Download } from '@mui/icons-material';
import SchoolTable from './SchoolTable';
import { schoolHead, ColumnType } from 'Components/Common/Tables/TableHeaders';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { StateType, SchoolData, OrderType, FilterPayload } from 'contexts/intialstates/Types';
import useSchoolActions from 'hooks/useSchoolActions';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import { LoadingButton } from '@mui/lab';
import Archive, { IArchiveStatus } from 'Components/Reusable/Archive';
import { styles } from 'Components/Donors/donors.style';

const Schools = () => {
	const navigate = useNavigate();
	const { getSchools, exportSchoolCSV } = useSchoolActions();
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);
	const [reload, setReload] = React.useState(false);

	const [schools, setSchools] = React.useState<StateType<SchoolData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [schoolExport, setSchoolExport] = useState<StateType<SchoolData>>({
		data: null,
		error: null,
		loading: false,
	});

	const [archive, setArchive] = useState<IArchiveStatus>({
		label: 'All',
		filter: '',
	});
	const handleExport = () => {
		exportSchoolCSV({
			schoolExport,
			setSchoolExport,
			pageSize,
			page,
			orderBy,
			filterPayload,
			archive: archive.filter,
		});
	};

	React.useEffect(() => {
		getSchools({
			schools,
			archive: archive?.filter,
			setSchools,
			page,
			pageSize,
			orderBy,
			filterPayload,
		});
	}, [page, pageSize, orderBy, reload, archive]);

	return (
		<div>
			<Stack>
				<Stack direction={'row'} justifyContent={'space-between'} mb={3}>
					<Stack direction={'column'} spacing={3}>
						<Stack direction={'column'}>
							<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
								School
							</Typography>
							<Stack>Add and manage Schools here.</Stack>
						</Stack>
						<Stack direction="row" spacing={2}>
							<Archive archive={archive} setArchive={setArchive} />
							<LoadingButton
								loading={schoolExport.loading}
								loadingPosition="start"
								onClick={handleExport}
								startIcon={<Download />}
								variant="outlined"
								sx={styles.csvBtn}
							>
								Download CSV
							</LoadingButton>
						</Stack>
					</Stack>
					<Stack direction={'row'} alignItems={'center'}>
						<TrimTables name="schoolHead" list={schoolHead} trimList={trimList} setTrimList={setTrimList} />
						<Button
							onClick={() => navigate(`/hca/create-edit-schools`)}
							sx={{ textTransform: 'none', mr: 2 }}
							startIcon={<Add />}
							variant="contained"
						>
							Add New School
						</Button>
						<FilterListButton
							setPage={setPage}
							page={page}
							reload={reload}
							setIsReload={setReload}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
						/>
					</Stack>
				</Stack>
				<SchoolTable
					trimList={trimList}
					setTrimList={setTrimList}
					setPage={setPage}
					page={page}
					reload={reload}
					setReload={setReload}
					pageSize={pageSize}
					setPageSize={setPageSize}
					label={label}
					orderType={orderType}
					setLabel={setLabel}
					setOderBy={setOderBy}
					setOrderType={setOrderType}
					schools={schools}
					setFilterPayload={setFilterPayload}
					filterPayload={filterPayload}
				/>
			</Stack>
		</div>
	);
};

export default Schools;
