import { Receipt } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { Deposits, Donations, ResponseError } from 'contexts/intialstates/Types';
import React, { useContext, useState } from 'react';
import ConfirmationModal from '../Modals/ConfirmationModal';
import useApi from 'hooks/useApi';
import moment from 'moment';
import { GlobalContexts } from 'contexts/GlobalContext';

interface GenerateReceiptProps {
	deposits: Deposits[];
	validDeposits?: number;
}
export interface ConfirmationModalState {
	open: boolean;
	id: null | number | string;
}

interface GenerateReceipt {
	loading: boolean;
	data: string | null;
	error: ResponseError | null;
}
interface GenerateSingleReceiptProps {
	id: number | string;
	onEditDeposit?: boolean;
	isDisabled?: boolean;
	tooltipMessage?: string;
	handleReload?: () => void;
	deposit?: Deposits;
	isHCA?: boolean;
	donation?: Donations;
}

interface GenerateEOYReceiptProps {
	id: number;
	isYearEnd?: boolean;
}

interface GenerateIssuedEOYReceiptProps {
	year: number | null;
}

export const GenerateSingleReceipt = ({
	id,
	onEditDeposit,
	handleReload,
	deposit,
	donation,
	isDisabled,
	isHCA,
}: GenerateSingleReceiptProps) => {
	const { api } = useApi();
	const [receipt, setReceipt] = React.useState<GenerateReceipt>({
		loading: false,
		data: null,
		error: null,
	});
	const [isValid, setIsValid] = React.useState({
		invalid: true,
		message: '',
	});
	const [isOpen, setIsopen] = React.useState<ConfirmationModalState>({
		open: false,
		id: null,
	});

	const handleGenerateReceipt = () => {
		setIsopen({
			open: true,
			id,
		});
	};
	const cancelConfirmation = () => {
		setIsopen({
			open: false,
			id: null,
		});
	};
	const [sendEmail, setSendEmail] = useState(false);
	React.useEffect(() => {
		deposit && validateDepositReceipting(deposit);
		donation && validateDonationReceipting(donation);
	}, []);
	const donationType = (donationType: string) => {
		if (donationType === 'Daily Donation') return 'daily donation';
		if (donationType === 'DIRECT MAIL APPEAL DONATION') return 'direct mail appeal donation';
		if (donationType === 'REFURBISH CHALICE DONATION') return 'refurbish chalice donation';
		if (donationType === 'ESTATES') return 'estates';
		if (donationType === 'GENERAL DONATION - FROM MAGAZINE') return 'general donation - from magazine';
		if (donationType === 'APPEAL FOLLOW UP') return 'appeal follow up';
		if (donationType === 'INVESTMENT INCOME') return 'investment income';
		if (donationType === 'Walk-A-Thon/Special appeal/project') return 'walk-a-thon/special appeal/project';
		if (donationType === 'MAGAZINE SUBSCRIPTION') return 'magazine subscription';
	};
	const validateDepositReceipting = (deposit: Deposits) => {
		if (isHCA) {
			if (deposit?.paymentMethod?.name === 'Canada Helps') {
				setIsValid({ invalid: true, message: 'Cannot generate acknowledgment for Canada Helps' });
				return;
			}
			if (!deposit?.donor && !deposit.diocese && !deposit.school && !deposit.schoolBoard && !deposit.parish) {
				setIsValid({ invalid: true, message: 'Cannot generate acknowledgment for Anonymous donors' });
				return;
			}
			if (deposit?.itemStatus?.status === 'Incomplete') {
				setIsValid({ invalid: true, message: 'Cannot generate acknowledgment for incomplete donations' });
				return;
			}
			setIsValid({ invalid: false, message: 'Generate acknowledgment' });
			return;
		}

		if (deposit?.paymentMethod?.name === 'Canada Helps') {
			setIsValid({ invalid: true, message: 'Cannot generate receipt for Canada Helps' });
			return;
		}
		if (!deposit?.donor && !deposit.diocese && !deposit.school && !deposit.schoolBoard && !deposit.parish) {
			setIsValid({ invalid: true, message: 'Cannot generate receipt for Anonymous donors' });
			return;
		}

		if (deposit?.itemStatus?.status === 'Incomplete') {
			setIsValid({ invalid: true, message: 'Cannot generate receipt for incomplete donations' });
			return;
		}
		setIsValid({ invalid: false, message: 'Generate receipt' });
	};
	const validateDonationReceipting = (donation: Donations) => {
		if (!donation?.donationType?.receipting) {
			setIsValid({
				invalid: true,
				message: `Cannot generate receipt for ${donationType(
					donation?.donationType?.description,
				)} donation type`,
			});
			return;
		}
		setIsValid({ invalid: false, message: 'Generate receipt' });
	};
	const generateReceipt = async () => {
		setReceipt({
			...receipt,
			loading: true,
			error: null,
		});
		try {
			const response = await api.get(
				`receipt/${isHCA ? 'hcaAcknowledgement' : 'deposit'}/${isOpen?.id}${
					sendEmail ? '?sendEmail=true' : ''
				}`,
				{
					responseType: 'blob',
				},
			);
			setReceipt({
				...receipt,
				loading: false,
				error: null,
			});
			handleReload?.();
			const url = window.URL.createObjectURL(new Blob([response?.data], { type: 'application/zip' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${isHCA ? 'acknowledgment' : 'receipt'}.zip`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			setIsopen({
				open: false,
				id: null,
			});
		} catch (err) {
			setReceipt({
				...receipt,
				loading: false,
				error: err as ResponseError,
			});
			alert('Unable to download file');
			setIsopen({
				open: false,
				id: null,
			});
		}
	};
	return (
		<>
			<ConfirmationModal
				handleCancel={cancelConfirmation}
				handleConfirmation={generateReceipt}
				open={isOpen?.open}
				handleClose={cancelConfirmation}
				message={`Do you want to generate a ${isHCA ? 'acknowledgement' : 'receipt'} for this donation?`}
				loading={receipt?.loading}
				sendEmail={sendEmail}
				setSendEmail={setSendEmail}
			/>
			<Tooltip title={isValid.message}>
				{onEditDeposit ? (
					<span>
						<Button
							startIcon={<Receipt />}
							color="primary"
							variant="contained"
							sx={{ textTransform: 'none' }}
							onClick={handleGenerateReceipt}
							disabled={isValid.invalid}
						>
							{isHCA ? 'Generate Acknowledgment' : 'Generate Receipt'}
						</Button>
					</span>
				) : (
					<span>
						<Button
							variant="text"
							disabled={isValid.invalid || isDisabled}
							size="medium"
							onClick={handleGenerateReceipt}
						>
							<Receipt sx={{ color: isValid.invalid ? 'grey!important' : 'inherit' }} />
						</Button>
					</span>
				)}
			</Tooltip>
		</>
	);
};

export const GenerateEOYReceipt = ({ id }: GenerateEOYReceiptProps) => {
	const { api } = useApi();
	const [receipt, setReceipt] = React.useState<GenerateReceipt>({
		loading: false,
		data: null,
		error: null,
	});
	const [isOpen, setIsopen] = React.useState<ConfirmationModalState>({
		open: false,
		id: null,
	});

	const handleGenerateReceipt = () => {
		setIsopen({
			open: true,
			id,
		});
	};
	const cancelConfirmation = () => {
		setIsopen({
			open: false,
			id: null,
		});
	};
	const [sendEmail, setSendEmail] = useState(false);
	const generateReceipt = async () => {
		setReceipt({
			...receipt,
			loading: true,
			error: null,
		});
		try {
			const response = await api.get(
				`receipt/download/${isOpen?.id}/yearend${sendEmail ? '?sendEmail=true' : ''}`,
				{
					responseType: 'blob',
				},
			);
			setReceipt({
				...receipt,
				loading: false,
				error: null,
			});
			const url = window.URL.createObjectURL(new Blob([response?.data], { type: 'application/pdf' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'receipt.pdf');
			document.body.appendChild(link);
			link.click();
			link.remove();
			setIsopen({
				open: false,
				id: null,
			});
		} catch (err) {
			setReceipt({
				...receipt,
				loading: false,
				error: err as ResponseError,
			});
			alert('Unable to download file');
			setIsopen({
				open: false,
				id: null,
			});
		}
	};
	return (
		<>
			<ConfirmationModal
				handleCancel={cancelConfirmation}
				handleConfirmation={generateReceipt}
				open={isOpen?.open}
				handleClose={cancelConfirmation}
				message="Do you want to generate a receipt for this deposit?"
				loading={receipt?.loading}
				sendEmail={sendEmail}
				setSendEmail={setSendEmail}
			/>

			<Tooltip title="Generate Receipt ">
				<Button variant="text" size="medium" onClick={handleGenerateReceipt}>
					<Receipt />
				</Button>
			</Tooltip>
		</>
	);
};
export const GenerateIssuedEOYReceipt = ({ year }: GenerateIssuedEOYReceiptProps) => {
	const { api } = useApi();
	const { setPendingFiles, setSimpleErrorSnack } = useContext(GlobalContexts);
	const [receipt, setReceipt] = React.useState<GenerateReceipt>({
		loading: false,
		data: null,
		error: null,
	});
	const [isOpen, setIsopen] = React.useState<boolean>(false);
	const [value, setValue] = React.useState(true);
	const [sendEmail, setSendEmail] = useState(false);

	const handleGenerateReceipt = () => {
		setIsopen(true);
	};
	const cancelConfirmation = () => {
		setIsopen(false);
	};
	const generateReceipt = async () => {
		setReceipt({
			...receipt,
			loading: true,
			error: null,
		});
		try {
			const response = await api.get(
				`/receipt/download/yearend?targetYear=${year}&orderBySociety=${value}${
					sendEmail ? '&sendEmail=true' : ''
				}`,
			);
			if (response?.data?.statusCode === 0 && response?.data?.result?.id) {
				setIsopen(false);
				setPendingFiles((prev) => [...prev, response?.data?.result?.id]);
				setSimpleErrorSnack({
					message: `Download is in progress, we will inform you when it is ready`,
					severity: 'info',
					show: true,
				});
				return true;
			}
		} catch (err) {
			setIsopen(false);
			setSimpleErrorSnack({
				message: `Unable to generate receipt`,
				severity: 'error',
				show: true,
			});
		}
	};
	return (
		<div>
			<ConfirmationModal
				handleCancel={cancelConfirmation}
				handleConfirmation={generateReceipt}
				open={isOpen}
				handleClose={cancelConfirmation}
				message={`Do you want to generate receipt for ${year}`}
				loading={receipt?.loading}
				value={value}
				setValue={setValue}
				bySociety
				sendEmail={sendEmail}
				setSendEmail={setSendEmail}
			/>
			<Tooltip title="Generate Receipt ">
				<span>
					<Button
						sx={{ textTransform: 'none' }}
						variant="contained"
						size="small"
						onClick={handleGenerateReceipt}
						disabled={year ? false : true}
					>
						Download
					</Button>
				</span>
			</Tooltip>
		</div>
	);
};

const GenerateReceipt = ({ deposits, validDeposits }: GenerateReceiptProps) => {
	const { api } = useApi();
	const [isOpen, setIsopen] = React.useState(false);
	const [receipt, setReceipt] = React.useState<GenerateReceipt>({
		loading: false,
		data: null,
		error: null,
	});
	const [validateDeposits, setValidateDeposits] = React.useState({
		invalid: false,
		message: '',
	});
	const [value, setValue] = React.useState(true);
	const [sendEmail, setSendEmail] = useState(false);

	const list = deposits?.map((deposit: Deposits) => deposit.id);

	const handleClick = () => {
		setIsopen(true);
	};

	const cancelConfirmation = () => {
		setIsopen(false);
	};
	const onValidateDeposit = (validDeposits: number) => {
		if (validDeposits && validDeposits >= 0) {
			setValidateDeposits({ invalid: true, message: 'Cannot generate receipt for incomplete donations' });
			return;
		}
		setValidateDeposits({ invalid: false, message: '' });
	};
	React.useEffect(() => {
		validDeposits && onValidateDeposit(validDeposits);
	}, []);

	const generateReceipt = async () => {
		if (deposits.length !== 0) {
			setReceipt({
				...receipt,
				loading: true,
				data: null,
				error: null,
			});
			try {
				const response = await api.get(
					`receipt/receipts?depositids=${list.join()}&orderBySociety=${value}${
						sendEmail ? '&sendEmail=true' : ''
					}`,
					{
						responseType: 'blob',
					},
				);

				setReceipt({
					...receipt,
					loading: false,
					data: response?.data?.result,
					error: null,
				});
				setIsopen(false);
				const url = window.URL.createObjectURL(new Blob([response?.data], { type: 'application/zip' }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'receipt.zip');
				document.body.appendChild(link);
				link.click();
				link.remove();
			} catch (err) {
				setReceipt({
					...receipt,
					loading: false,
					data: null,
					error: err as ResponseError,
				});
				alert('Unable to download file');
				setIsopen(false);
			}
		}
	};
	return (
		<div>
			<ConfirmationModal
				handleCancel={cancelConfirmation}
				handleConfirmation={generateReceipt}
				open={isOpen}
				handleClose={cancelConfirmation}
				message="Do you want to generate a receipt for these donations?"
				loading={receipt?.loading}
				value={value}
				bySociety
				setValue={setValue}
				sendEmail={sendEmail}
				setSendEmail={setSendEmail}
			/>
			<Tooltip title={validateDeposits.message}>
				<span>
					<Button
						startIcon={<Receipt />}
						color="primary"
						variant="contained"
						sx={{ textTransform: 'none' }}
						onClick={handleClick}
						disabled={validateDeposits.invalid}
					>
						Generate Receipt
					</Button>
				</span>
			</Tooltip>
		</div>
	);
};

export const GenerateAcknowledgment = ({ deposits, validDeposits }: GenerateReceiptProps) => {
	const { api } = useApi();
	const [isOpen, setIsopen] = React.useState(false);
	const [sendEmail, setSendEmail] = useState(false);
	const [receipt, setReceipt] = React.useState<GenerateReceipt>({
		loading: false,
		data: null,
		error: null,
	});
	const list = deposits?.map((deposit: Deposits) => deposit.id);

	const handleClick = () => {
		setIsopen(true);
	};

	const cancelConfirmation = () => {
		setIsopen(false);
	};
	let validateDeposit = {
		invalid: true,
		message: `Cannot generate acknowledgment for incomplete donations`,
	};
	if (validDeposits === -1) {
		validateDeposit = {
			invalid: false,
			message: ``,
		};
	}
	const generateReceipt = async () => {
		if (deposits.length !== 0) {
			setReceipt({
				...receipt,
				loading: true,
				data: null,
				error: null,
			});
			try {
				const response = await api.get(
					`receipt/hcaAcknowledgements?hcaAcknowledgementIds=${list.join()}${
						sendEmail ? '&sendEmail=true' : ''
					}`,
					{
						responseType: 'blob',
					},
				);
				setReceipt({
					...receipt,
					loading: false,
					data: response?.data?.result,
					error: null,
				});
				setIsopen(false);
				const url = window.URL.createObjectURL(new Blob([response?.data], { type: 'application/zip' }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `acknowledgments_${moment()}.zip`);
				document.body.appendChild(link);
				link.click();
				link.remove();
			} catch (err) {
				setReceipt({
					...receipt,
					loading: false,
					data: null,
					error: err as ResponseError,
				});
				alert('Unable to download file');
				setIsopen(false);
			}
		}
	};
	return (
		<div>
			<ConfirmationModal
				handleCancel={cancelConfirmation}
				handleConfirmation={generateReceipt}
				open={isOpen}
				handleClose={cancelConfirmation}
				message={`Do you want to generate acknowledgment for this donations ?`}
				loading={receipt?.loading}
				sendEmail={sendEmail}
				setSendEmail={setSendEmail}
			/>
			<Tooltip title={validateDeposit.message}>
				<span>
					<Button
						startIcon={<Receipt />}
						color="primary"
						variant="contained"
						sx={{ textTransform: 'none' }}
						onClick={handleClick}
						disabled={validateDeposit.invalid}
					>
						Generate Acknowledgments
					</Button>
				</span>
			</Tooltip>
		</div>
	);
};

export const GenerateNoncasReceipt = ({
	id,
	onEditDeposit,
	handleReload,
	deposit,
	donation,
	isDisabled,
	isHCA,
}: GenerateSingleReceiptProps) => {
	const { api } = useApi();
	const [receipt, setReceipt] = React.useState<GenerateReceipt>({
		loading: false,
		data: null,
		error: null,
	});
	const [isValid, setIsValid] = React.useState({
		invalid: false,
		message: '',
	});
	const [isOpen, setIsopen] = React.useState<ConfirmationModalState>({
		open: false,
		id: null,
	});

	const handleGenerateReceipt = () => {
		setIsopen({
			open: true,
			id,
		});
	};
	const cancelConfirmation = () => {
		setIsopen({
			open: false,
			id: null,
		});
	};
	const [sendEmail, setSendEmail] = useState(false);
	React.useEffect(() => {
		deposit && validateDepositReceipting(deposit);
		donation && validateDonationReceipting(donation);
	}, []);
	const donationType = (donationType: string) => {
		if (donationType === 'Daily Donation') return 'daily donation';
		if (donationType === 'DIRECT MAIL APPEAL DONATION') return 'direct mail appeal donation';
		if (donationType === 'REFURBISH CHALICE DONATION') return 'refurbish chalice donation';
		if (donationType === 'ESTATES') return 'estates';
		if (donationType === 'GENERAL DONATION - FROM MAGAZINE') return 'general donation - from magazine';
		if (donationType === 'APPEAL FOLLOW UP') return 'appeal follow up';
		if (donationType === 'INVESTMENT INCOME') return 'investment income';
		if (donationType === 'Walk-A-Thon/Special appeal/project') return 'walk-a-thon/special appeal/project';
		if (donationType === 'MAGAZINE SUBSCRIPTION') return 'magazine subscription';
	};
	const validateDepositReceipting = (deposit: Deposits) => {
		if (isHCA) {
			if (deposit?.paymentMethod?.name === 'Canada Helps') {
				setIsValid({ invalid: true, message: 'Cannot generate acknowledgment for Canada Helps' });
				return;
			}
			if (!deposit?.donor && !deposit.diocese && !deposit.school && !deposit.schoolBoard && !deposit.parish) {
				setIsValid({ invalid: true, message: 'Cannot generate acknowledgment for Anonymous donors' });
				return;
			}
			if (deposit?.itemStatus?.status === 'Incomplete') {
				setIsValid({ invalid: true, message: 'Cannot generate acknowledgment for incomplete donations' });
				return;
			}
			setIsValid({ invalid: false, message: 'Generate acknowledgment' });
			return;
		}

		if (deposit?.paymentMethod?.name === 'Canada Helps') {
			setIsValid({ invalid: true, message: 'Cannot generate receipt for Canada Helps' });
			return;
		}
		if (!deposit?.donor && !deposit.diocese && !deposit.school && !deposit.schoolBoard && !deposit.parish) {
			setIsValid({ invalid: true, message: 'Cannot generate receipt for Anonymous donors' });
			return;
		}

		if (deposit?.itemStatus?.status === 'Incomplete') {
			setIsValid({ invalid: true, message: 'Cannot generate receipt for incomplete donations' });
			return;
		}
		setIsValid({ invalid: false, message: 'Generate receipt' });
	};
	const validateDonationReceipting = (donation: Donations) => {
		if (!donation?.donationType?.receipting) {
			setIsValid({
				invalid: true,
				message: `Cannot generate receipt for ${donationType(
					donation?.donationType?.description,
				)} donation type`,
			});
			return;
		}
		setIsValid({ invalid: false, message: 'Generate receipt' });
	};
	const generateReceipt = async () => {
		setReceipt({
			...receipt,
			loading: true,
			error: null,
		});
		try {
			const response = await api.get(
				`receipt/${isHCA ? 'hcaAcknowledgement' : 'deposit'}/${isOpen?.id}${
					sendEmail ? '?sendEmail=true' : ''
				}`,
				{
					responseType: 'blob',
				},
			);
			setReceipt({
				...receipt,
				loading: false,
				error: null,
			});
			handleReload?.();
			const url = window.URL.createObjectURL(new Blob([response?.data], { type: 'application/zip' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${isHCA ? 'acknowledgment' : 'receipt'}.zip`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			setIsopen({
				open: false,
				id: null,
			});
		} catch (err) {
			setReceipt({
				...receipt,
				loading: false,
				error: err as ResponseError,
			});
			alert('Unable to download file');
			setIsopen({
				open: false,
				id: null,
			});
		}
	};
	return (
		<>
			<ConfirmationModal
				handleCancel={cancelConfirmation}
				handleConfirmation={generateReceipt}
				open={isOpen?.open}
				handleClose={cancelConfirmation}
				message={`Do you want to generate a ${isHCA ? 'acknowledgement' : 'receipt'} for this donation?`}
				loading={receipt?.loading}
				sendEmail={sendEmail}
				setSendEmail={setSendEmail}
			/>
			<Tooltip title={`${isDisabled ? 'Cannot generate receipt for non posted donations' : ''}`}>
				{onEditDeposit ? (
					<span>
						<Button
							startIcon={<Receipt />}
							color="primary"
							variant="contained"
							sx={{ textTransform: 'none' }}
							onClick={handleGenerateReceipt}
							disabled={isValid.invalid}
						>
							{isHCA ? 'Generate Acknowledgment' : 'Generate Receipt'}
						</Button>
					</span>
				) : (
					<span>
						<Button
							variant="text"
							disabled={isValid.invalid || isDisabled}
							size="medium"
							onClick={handleGenerateReceipt}
						>
							<Receipt sx={{ color: isValid.invalid ? 'grey!important' : 'inherit' }} />
						</Button>
					</span>
				)}
			</Tooltip>
		</>
	);
};

export default GenerateReceipt;
