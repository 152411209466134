import React, { useState, useEffect } from 'react';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { Paper, TablePagination, Tooltip } from '@mui/material';
import { pagination } from 'Components/Common/Tables/Tables';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import useAnnuitiesActions, { AnnuitiesPosting, AnnuitiesPostingData } from 'hooks/useAnnuitiesActions';
import { StateType, OrderType, FilterPayload } from 'contexts/intialstates/Types';
import { convertDate } from 'helpers/dayAndTime';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import { sortTable } from 'Components/Common/Tables/sortTable';
import { useNavigate } from 'react-router-dom';

interface P {
	trimList: ColumnType[];
	setTrimList: React.Dispatch<React.SetStateAction<ColumnType[]>>;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	reload: boolean;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	page: number;
	filterPayload: any[];
	setFilterPayload: React.Dispatch<React.SetStateAction<any[]>>;
}

const List = ({
	page,
	setPage,
	trimList,
	setTrimList,
	reload,
	setReload,
	filterPayload,
	setFilterPayload,
}: P) => {
	const navigate = useNavigate();
	const { getAnnuitiesPosting } = useAnnuitiesActions();
	const [annuitiesPosting, setAnnuitiesPosting] = useState<StateType<AnnuitiesPosting>>({
		loading: false,
		error: null,
		data: null,
	});
	const [pageSize, setPageSize] = useState(10);
	const [orderBy, setOrderBy] = useState<string>('');
	const [orderType, setOrderType] = useState<OrderType>('asc');
	const [label, setLabel] = useState<string>('');

	const handleClick = (row: AnnuitiesPostingData) => {
		navigate(`${row?.id}`);
	};

	useEffect(() => {
		getAnnuitiesPosting({
			annuitiesPosting,
			setAnnuitiesPosting,
			page,
			pageSize,
			orderBy,
			filterPayload,
			type: 'monthly',
		});
	}, [orderBy, page, pageSize, reload]);

	const getTableData = () => {
		return annuitiesPosting?.data?.body.map((row: AnnuitiesPostingData, index: number) => {
			return {
				's/n': page * pageSize + index + 1,
				id: row.id,
				postingNumber: row.postingNumber,
				postingPeriod: row?.postingPeriod ? `${convertDate(row?.postingPeriod)}` : 'N/A',
				postingDate: row?.postingDate ? `${convertDate(row?.postingDate)}` : 'N/A',
				createdBy: row?.createdBy?.created || 'N/A',
			};
		});
	};
	const getColumnData = () => {
		return trimList.map((column) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
							<span
								style={{
									cursor: 'pointer',
									margin: '10px',
								}}
								onClick={() => {
									sortTable({
										column: column.id,
										setLabel,
										setOderBy: setOrderBy,
										setOrderType,
										orderType,
										label,
									});
								}}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							reload={reload}
							setIsReload={setReload}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							setPage={setPage}
							page={page}
						/>
					</>
				),
			};
		});
	};
	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};
	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};
	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<CustomTable
				loading={annuitiesPosting?.loading}
				columnData={getColumnData()}
				tableData={getTableData()}
				handleDrag={handleDrag}
				handleResize={handleResize}
				emptyMessage={'No data available'}
				handleRowClick={(row) => handleClick(row)}
			/>
			<TablePagination
				rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
				component="div"
				count={annuitiesPosting?.data?.totalRecords || 0}
				rowsPerPage={pageSize}
				page={page}
				sx={pagination}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
};
export default List;
