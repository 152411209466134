import React, { useState, useEffect } from 'react';
import { TablePagination, Paper, Tooltip } from '@mui/material';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import { pagination } from 'Components/Common/Tables/Tables';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { sortTable } from 'Components/Common/Tables/sortTable';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import { convertDate } from 'helpers/dayAndTime';
import useGiftTypeActions, { GiftType, GiftTypeData } from 'hooks/useGiftTypeActions';
import { StateType, OrderType } from 'contexts/intialstates/Types';

export const giftTypeHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'type',
		label: 'Gift Type',
		minWidth: 220,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'description',
		label: 'Description',
		minWidth: 220,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
];

interface P {
	trimList: ColumnType[];
	setTrimList: React.Dispatch<React.SetStateAction<ColumnType[]>>;
	filterPayload: any[];
	setFilterPayload: React.Dispatch<React.SetStateAction<any[]>>;
	handleOpenEdit: (data: GiftTypeData) => void;
	reload: boolean;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	page: number;
}

const GiftTypeTable = ({
	trimList,
	setTrimList,
	filterPayload,
	setFilterPayload,
	handleOpenEdit,
	reload,
	setReload,
	page,
	setPage,
}: P) => {
	const { getGiftTypes } = useGiftTypeActions();
	const [giftTypes, setGiftTypes] = useState<StateType<GiftType>>({
		data: null,
		loading: false,
		error: null,
	});
	const [pageSize, setPageSize] = useState(10);
	const [orderType, setOrderType] = useState<OrderType>('asc');
	const [label, setLabel] = useState<string>('');
	const [orderBy, setOderBy] = useState<string>('');

	useEffect(() => {
		getGiftTypes({
			giftTypes,
			setGiftTypes,
			page,
			pageSize,
			orderBy,
			filterPayload,
		});
	}, [page, pageSize, orderBy, reload]);

	const getTableData = () => {
		return giftTypes?.data?.body.length
			? giftTypes.data.body.map((row: any, index: number) => {
					return {
						's/n': index + 1,
						id: row.id,
						type: row.type || '',
						description: row.description || '',
						createdBy: row?.createdBy?.name || 'N/A',
						updatedBy: row?.updatedBy?.name || 'N/A',
						created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
						updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
							<span
								style={{
									cursor: 'pointer',
									margin: '10px',
								}}
								onClick={() => {
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									});
								}}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							setPage={setPage}
							page={page}
							reload={reload}
							setIsReload={setReload}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
						/>
					</>
				),
			};
		});
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	const handleRowClick = (row: any) => {
		handleOpenEdit(row);
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<CustomTable
				loading={giftTypes?.loading}
				columnData={getColumnData()}
				tableData={getTableData()}
				handleDrag={handleDrag}
				handleResize={handleResize}
				emptyMessage={'No gift types available'}
				handleRowClick={handleRowClick}
			/>
			{(giftTypes?.data?.body?.length as number) > 0 && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
					component="div"
					count={giftTypes?.data?.totalRecords as number}
					rowsPerPage={pageSize}
					page={page}
					sx={pagination}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</Paper>
	);
};

export default GiftTypeTable;
