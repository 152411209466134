import React, {FC} from 'react'
import {TableRow, TableCell, Box, Typography} from '@mui/material'
import { Block } from '@mui/icons-material';
import { makeStyles } from '@material-ui/core/styles';
interface P{
    emptyMessage: string
    columnCount: number
    isSN?: boolean
}

const TableEmptyLayout:FC<P> = ({emptyMessage, columnCount, isSN}) => {

    return (
        <TableRow>
            <TableCell 
               colSpan={isSN ? columnCount + 1 : columnCount}
               sx={{textAlign:'center', padding:'40px'}}
            >
                <Box 
                    sx={{display: 'flex',
                    alignItems:'center',
                    justifyContent: 'center',
                    flexDirection: 'column'}}
                >
                    <Block 
                        sx={{ fontSize: '90px' }}
                        htmlColor='var(--lightGray)'
                        className={'empty-block'}
                    />
                    <Typography sx={{ color: 'var(--lightGray)' }}>{emptyMessage}</Typography>
                </Box>
            </TableCell>
        </TableRow>
    )
}

export default TableEmptyLayout