import React, { useState } from 'react';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { Stack, TablePagination, Chip, Tooltip } from '@mui/material';
import { pagination } from 'Components/Common/Tables/Tables';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import { BurseChalice as BurseChaliceType } from 'hooks/useBurseChaliceActions';
import FormatCurrency from 'helpers/FormatCurrency';
import { convertDate } from 'helpers/dayAndTime';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import { sortTable } from 'Components/Common/Tables/sortTable';
import { OrderType } from 'contexts/intialstates/Types';

export const burseChaliceHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'contributionType',
		label: 'Contribution Type',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'Chalice' }, { name: 'Burse' }],
	},
	{
		id: 'status',
		label: 'Status',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'Completed' }, { name: 'Active' }, { name: 'Inactive' }, { name: 'Stopped' }],
	},
	{
		id: 'targetAmount',
		label: 'Target',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'contributionAmount',
		label: 'Contribution',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'donationType',
		label: 'Donation Type',
		minWidth: 220,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'startDate',
		label: 'Start Date',
		minWidth: 220,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'endDate',
		label: 'End Date',
		minWidth: 220,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
];

interface P {
	trimList: ColumnType[];
	setTrimList: React.Dispatch<React.SetStateAction<ColumnType[]>>;
	handleRowClick: (row: any) => void;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	page: number;
	pageSize: number;
	setPageSize: React.Dispatch<React.SetStateAction<number>>;
	burseChalice?: BurseChaliceType | null;
	loading?: boolean;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	reload: boolean;
	setOrderBy: React.Dispatch<React.SetStateAction<string>>;
	setFilterPayload: React.Dispatch<React.SetStateAction<any[]>>;
	filterPayload: any[];
}

const BurseChaliceTable = ({
	trimList,
	setTrimList,
	handleRowClick,
	setPage,
	setPageSize,
	page,
	pageSize,
	burseChalice,
	loading,
	reload,
	setReload,
	setOrderBy,
	setFilterPayload,
	filterPayload,
}: P) => {
	const [orderType, setOrderType] = useState<OrderType>('asc');
	const [label, setLabel] = useState<string>('');
	const getTableData = () => {
		return burseChalice?.body?.map((row: any, index: number) => {
			return {
				's/n': index + 1,
				id: row.id,
				contributionType: row.contributionType,
				status: row.status ? (
					<Chip
						label={row.status}
						color={
							row.status === 'Completed'
								? 'success'
								: row.status === 'Active'
								? 'primary'
								: row.status === 'Inactive'
								? 'default'
								: 'error'
						}
						variant="outlined"
						size="small"
					/>
				) : (
					'N/A'
				),
				targetAmount: row?.targetAmount ? <FormatCurrency value={row.targetAmount} /> : 'N/A',
				contributionAmount: row?.contributionAmount ? (
					<FormatCurrency value={row.contributionAmount} />
				) : (
					'N/A'
				),
				donationType: row?.donationType?.description || 'N/A',
				startDate: row?.startDate ? convertDate(row.startDate) : 'N/A',
				endDate: row?.endDate ? convertDate(row.endDate) : 'N/A',
				created: `${row?.created ? convertDate(row.created) : 'N/A'}`,
				createdBy: row?.createdBy?.name || 'N/A',
				updated: `${row?.updated ? convertDate(row.updated) : 'N/A'}`,
				updatedBy: row?.updatedBy?.name || 'N/A',
			};
		});
	};

	const getColumnData = () => {
		return trimList.map((column) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
							<span
								style={{
									cursor: 'pointer',
									margin: '10px',
								}}
								onClick={() => {
									sortTable({
										column: column.id,
										setLabel,
										setOderBy: setOrderBy,
										setOrderType,
										orderType,
										label,
									});
								}}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							reload={reload}
							setIsReload={setReload}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							setPage={setPage}
							page={page}
						/>
					</>
				),
			};
		});
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	return (
		<Stack mt={2}>
			<CustomTable
				loading={loading}
				columnData={getColumnData()}
				tableData={getTableData()}
				handleDrag={handleDrag}
				handleResize={handleResize}
				emptyMessage={'No data available'}
				handleRowClick={handleRowClick}
			/>
			<TablePagination
				rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
				component="div"
				count={burseChalice?.totalRecords || 0}
				rowsPerPage={pageSize}
				page={page}
				sx={pagination}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Stack>
	);
};

export default BurseChaliceTable;
