import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    tableHead: {
        '& .MuiTableRow-head': {
            '& .MuiTableCell-head': {
                fontWeight: 400,
                fontSize: '18px',
                lineHeight: '26px',
                borderBottom: '1px solid #ABAEB0',
                padding: '12px 0'
            },
        },
    },
    tableRowWrap: {
        '& .MuiTableCell-root': {
            borderBottom: '1px solid #DDE5EA',
            fontSize: '16px',
            lineHeight: '26px',
            padding: '10px',
        },
    },
    tableHeadContent: {
        display: 'flex',
        justifyContent: 'center',
        borderRight: '1px solid #E3E9EC',
    },
});
