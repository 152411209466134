import React from 'react';
import { ResponseError, SchoolStatus, StateType } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface GetSchoolBoardsTypes {
	setAllSchoolStatus: React.Dispatch<React.SetStateAction<StateType<SchoolStatus[]>>>;
	allSchoolStatus: StateType<SchoolStatus[]>;
}

export interface GetSchoolStatusTypes {
	setSchoolStatus: React.Dispatch<React.SetStateAction<StateType<SchoolStatus>>>;
	schoolStatus: StateType<SchoolStatus>;
	id: number;
}

const useSchoolStatusActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const getSchoolStatusByID = async ({ setSchoolStatus, schoolStatus, id }: GetSchoolStatusTypes) => {
		try {
			setSchoolStatus({
				...schoolStatus,
				loading: true,
				error: null,
			});
			const response = await api.get(`schoolStatus/${id}`);
			setSchoolStatus({
				...schoolStatus,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSchoolStatus({
					...schoolStatus,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const getSchoolStatus = async ({ setAllSchoolStatus, allSchoolStatus }: GetSchoolBoardsTypes) => {
		try {
			setAllSchoolStatus({
				...allSchoolStatus,
				loading: true,
				error: null,
			});
			const response = await api.get(`schoolStatus/list`);
			setAllSchoolStatus({
				...allSchoolStatus,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAllSchoolStatus({
					...allSchoolStatus,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	return {
		getSchoolStatusByID,
		getSchoolStatus,
	};
};

export default useSchoolStatusActions;
