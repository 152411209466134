import React from 'react';
import {
	Close,
	LockOutlined,
	MailOutline,
	PersonAddAlt,
	SellOutlined,
	TaskAlt,
	Visibility,
	VisibilityOff,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	Button,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	MenuItem,
	Select,
	Stack,
	Typography,
} from '@mui/material';
import { useGlobalStyles } from 'Components/Common/global/global';
import { FormTextField } from 'Components/Common/TextField';
import { Group, StateType, User } from 'contexts/intialstates/Types';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import passwordGenerator from 'generate-password';
import useGroupActions from 'hooks/useGroupActions';

export interface UserValues {
	firstName: string;
	lastName: string;
	groupId: string;
	groupName?: string;
	email: string;
	password?: string;
}

interface CreateEditAppealProp {
	handleSubmit: (values: UserValues) => void;
	selectedEditData?: User;
	saveLoading?: boolean;
	setVisible: any;
	setSelectedData: any;
}

const CreateEditUser = ({
	handleSubmit,
	selectedEditData,
	saveLoading,
	setVisible,
	setSelectedData,
}: CreateEditAppealProp) => {
	const global = useGlobalStyles();
	const [initial, setInitial] = useState<UserValues>({
		email: '',
		firstName: '',
		lastName: '',
		groupId: '',
	});
	const { getGroup } = useGroupActions();
	const [showPassword, setShowPassword] = React.useState(false);
	const [groups, setGroups] = React.useState<StateType<Group[]>>({
		data: null,
		loading: false,
		error: null,
	});

	useEffect(() => {
		if (!selectedEditData) {
			setInitial({
				email: '',
				firstName: '',
				lastName: '',
				groupId: '',
				password: undefined,
			});
		} else {
			const { groupId, email, firstName, lastName } = selectedEditData;
			setInitial({
				email,
				firstName,
				lastName,
				groupId,
			});
		}
	}, [selectedEditData]);
	useEffect(() => {
		getGroup({ groups, setGroups });
	}, []);

	const validation = yup.object().shape({
		firstName: yup.string().typeError('Invalid first name').required('First name is required'),
		lastName: yup.string().typeError('Invalid last name').required('Last name is required'),
		email: yup.string().email('Invalid Email').required('Email is required'),
		groupId: yup.string().required('Role is required'),
		password: selectedEditData
			? yup.string()
			: yup.string().min(6, 'Password is too short').required('Password is required'),
	});
	const handleCloseEdit = () => {
		setVisible(false);
		setSelectedData(undefined);
	};
	const generatePassword = (setFieldValue: any) => {
		const newPassword = passwordGenerator.generate({
			length: 10,
			numbers: true,
		});
		setFieldValue('password', newPassword);
	};
	return (
		<>
			<Formik
				enableReinitialize
				onSubmit={handleSubmit}
				validationSchema={validation}
				initialValues={initial}
			>
				{({ setFieldValue, touched, values, errors }) => (
					<Form noValidate autoComplete="off">
						<Stack>
							<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
								<Grid item xs={12} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
									<Grid container>
										<Grid item>
											<PersonAddAlt className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												First Name
											</Typography>
										</Grid>
									</Grid>
									<Field
										fullWidth
										name="firstName"
										size="small"
										variant="outlined"
										placeholder="First Name"
										component={FormTextField}
									/>
								</Grid>
								<Grid item xs={12} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
									<Grid container>
										<Grid item>
											<PersonAddAlt className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												Last Name
											</Typography>
										</Grid>
									</Grid>
									<Field
										fullWidth
										name="lastName"
										size="small"
										variant="outlined"
										placeholder="Last Name"
										component={FormTextField}
									/>
								</Grid>
								<Grid item xs={12} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
									<Grid container>
										<Grid item>
											<SellOutlined className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												Role
											</Typography>
										</Grid>
									</Grid>
									<Field
										component={() => (
											<Select
												fullWidth
												size="small"
												onChange={(e) => {
													setFieldValue('groupId', e.target.value);
												}}
												value={values.groupId}
												error={Boolean(touched?.groupId && errors?.groupId)}
											>
												{groups.data?.map(({ displayName, id }) => (
													<MenuItem key={id} value={id}>
														{displayName}
													</MenuItem>
												))}
											</Select>
										)}
										name="groupId"
										as={'select'}
										label="Role"
										variant="outlined"
									/>
									{touched?.groupId && errors?.groupId && (
										<FormHelperText error>{errors?.groupId}</FormHelperText>
									)}
								</Grid>
								<Grid item xs={12} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
									<Grid container>
										<Grid item>
											<MailOutline className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												Email
											</Typography>
										</Grid>
									</Grid>
									<Field
										fullWidth
										name="email"
										size="small"
										variant="outlined"
										placeholder="Email"
										disabled={selectedEditData}
										className={selectedEditData ? global.disabledInput : ''}
										component={FormTextField}
									/>
								</Grid>
								{!selectedEditData && (
									<>
										<Grid item xs={12} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack sx={{ mb: 2 }}>
												<Grid container>
													<Grid item>
														<LockOutlined className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography variant="body2" className={global.formLabelText}>
															Password
														</Typography>
													</Grid>
												</Grid>
												<Field
													name="password"
													size="small"
													variant="outlined"
													placeholder="Password"
													type={showPassword ? 'text' : 'password'}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	aria-label="toggle password visibility"
																	onClick={() => setShowPassword(!showPassword)}
																	edge="end"
																>
																	{showPassword ? <VisibilityOff /> : <Visibility />}
																</IconButton>
															</InputAdornment>
														),
													}}
													component={FormTextField}
												/>
											</Stack>
										</Grid>
										<Stack justifyContent="center">
											<Button
												sx={{ textTransform: 'capitalize' }}
												variant="contained"
												onClick={() => generatePassword(setFieldValue)}
											>
												Autogenerate
											</Button>
										</Stack>
									</>
								)}
							</Grid>

							<Stack direction="row" my={3} justifyContent={'center'} spacing={2} alignItems="center">
								<LoadingButton
									type="submit"
									color="primary"
									variant="contained"
									sx={{ width: 'fit-content', textTransform: 'capitalize' }}
									loading={saveLoading}
									startIcon={<TaskAlt />}
									loadingPosition="start"
								>
									Save
								</LoadingButton>
								<Button
									variant="outlined"
									sx={{ width: 'fit-content', textTransform: 'capitalize' }}
									onClick={handleCloseEdit}
									startIcon={<Close />}
								>
									Cancel
								</Button>
							</Stack>
						</Stack>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default CreateEditUser;
