import React, { useState, useEffect } from 'react';
import { Formik, FormikProps, Form, Field } from 'formik';
import { Grid, Stack, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormTextField } from 'Components/Common/TextField';
import { useGlobalStyles } from 'Components/Common/global/global';
import { HCAMaterials } from 'contexts/intialstates/Types';
import { ConfirmationNumber, Notes, NotesOutlined, ToggleOn } from '@mui/icons-material';
import * as yup from 'yup';

export interface HCAMaterialValues {
	itemCode: string;
	description: string;
	remark: string;
	active: boolean;
}

interface CreateEditHCAMaterialsProp {
	handleSubmit: (values: HCAMaterialValues) => void;
	selectedEditData?: HCAMaterials;
	saveLoading?: boolean;
}

const CreditEditHCAMaterials = ({
	handleSubmit,
	selectedEditData,
	saveLoading,
}: CreateEditHCAMaterialsProp) => {
	const global = useGlobalStyles();
	const [initial, setInitial] = useState<HCAMaterialValues>({
		itemCode: '',
		description: '',
		remark: '',
		active: false,
	});

	useEffect(() => {
		if (!selectedEditData) {
			setInitial({
				itemCode: '',
				description: '',
				remark: '',
				active: false,
			});
		} else {
			const { itemCode, description, remark, active } = selectedEditData;
			setInitial({
				itemCode,
				description,
				remark,
				active,
			});
		}
	}, [selectedEditData]);

	const validationHCAMaterials = yup.object().shape({
		itemCode: yup.string().required('Item code is required'),
		description: yup.string().required('Description is required'),
	});

	return (
		<Formik
			enableReinitialize
			onSubmit={handleSubmit}
			validationSchema={validationHCAMaterials}
			initialValues={initial}
		>
			{({ setFieldValue, values }: FormikProps<HCAMaterialValues>) => (
				<Form noValidate autoComplete="off">
					<Stack direction="column" my={2} py={2}>
						<Stack direction="row" justifyContent="space-between">
							<Stack sx={{ mb: 2 }}>
								<Grid container>
									<Grid item>
										<ConfirmationNumber className={global.formLabelIcon} />
									</Grid>
									<Grid item>
										<Typography variant="body2" className={global.formLabelText}>
											Item Code
										</Typography>
									</Grid>
								</Grid>
								<Field
									fullWidth
									name="itemCode"
									size="small"
									variant="outlined"
									placeholder="Item Code"
									component={FormTextField}
								/>
							</Stack>
							<Stack sx={{ mb: 2 }}>
								<Grid container>
									<Grid item>
										<NotesOutlined className={global.formLabelIcon} />
									</Grid>
									<Grid item>
										<Typography variant="body2" className={global.formLabelText}>
											Description
										</Typography>
									</Grid>
								</Grid>
								<Field
									fullWidth
									name="description"
									size="small"
									variant="outlined"
									placeholder="Description"
									component={FormTextField}
								/>
							</Stack>
						</Stack>
						<Stack direction="row" justifyContent="space-between">
							<Stack sx={{ width: '50%', mb: 2, mx: 2 }}>
								<Grid container>
									<Grid item>
										<ToggleOn className={global.formLabelIcon} />
									</Grid>
									<Grid item>
										<Typography variant="body2" className={global.formLabelText}>
											Is Active
										</Typography>
									</Grid>
								</Grid>
								<Field
									fullWidth
									name="active"
									size="small"
									variant="outlined"
									component={() => (
										<RadioGroup
											value={values.active}
											name="active"
											onChange={(e) => setFieldValue('active', e.target.value === 'true')}
										>
											<FormControlLabel value={true} control={<Radio />} label="Yes" />
											<FormControlLabel value={false} control={<Radio />} label="No" />
										</RadioGroup>
									)}
								/>
							</Stack>
						</Stack>
					</Stack>

					<Stack direction="row" justifyContent="space-between">
						<Stack width="100%" sx={{ mb: 2 }}>
							<Grid container>
								<Grid item>
									<Notes className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Remark
									</Typography>
								</Grid>
							</Grid>
							<Field
								fullWidth
								name="remark"
								size="small"
								variant="outlined"
								placeholder="Remark"
								component={FormTextField}
								multiline
								rows={4}
							/>
						</Stack>
					</Stack>
					<Stack>
						<LoadingButton
							fullWidth
							type="submit"
							color="primary"
							variant="contained"
							sx={{ marginTop: '30px' }}
							loading={saveLoading}
							loadingPosition="start"
						>
							{selectedEditData ? 'Edit Material' : 'Create Material'}
						</LoadingButton>
					</Stack>
				</Form>
			)}
		</Formik>
	);
};

export default CreditEditHCAMaterials;
