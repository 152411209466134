import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useStyles } from './style';
import {
	Stack,
	Button,
	Grid,
	Typography,
	Select,
	MenuItem,
	TextField,
	IconButton,
	Skeleton,
} from '@mui/material';
import { AddTask, Cancel, PersonSearch } from '@mui/icons-material';
import { useGlobalStyles } from 'Components/Common/global/global';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import useRecieptScheduleAction, { ReceiptSchedule } from 'hooks/useReceiptScheduleActions';
import { StateType, DonorData, DonationTypes, Society, PaymentMethod } from 'contexts/intialstates/Types';
import { GlobalContexts } from 'contexts/GlobalContext';
import useSocietyActions from 'hooks/useSocietyAction';
import usePaymentmethodsActions from 'hooks/usePaymentMethodAction';
import useDonationTypesActions from 'hooks/useDonationTypesActions';
import LoadingButton from '@mui/lab/LoadingButton';
import DonorSearch from '../../Reports/DonorReports/DonorSearch';
import { Box } from '@mui/system';
import { adhocDonor, CampaignData } from 'hooks/useCampaignActions';
import { useParams } from 'react-router-dom';
import useDonorActions from 'hooks/useDonorActions';
import moment from 'moment';

export interface FormValues {
	start: string;
	end: string;
	minAmount?: string;
	maxAmount?: string;
	donors: adhocDonor[];
	donationType: string[];
	receiptFrequency: string[];
	society: string[];
	preferredSociety: string[];
	paymentMethod: string[];
	communication: string[];
	name: string;
	noMail?: boolean;
	excludeFromParishList?: boolean;
	returnMail?: boolean;
	deceased?: boolean;
	isCommunicationNone?: boolean;
	magazine?: boolean;
	faithPropagation?: boolean;
	stPeter?: boolean;
	holyChildhood?: boolean;
	noFollowAppeal?: boolean;
}
interface P {
	handleGetDonors: (values: FormValues, isGenerate?: boolean) => void;
	handleGenerateCampaign: (values: FormValues, isGenerate?: boolean) => void;
	loading: boolean;
	onCancel: () => void;
	isEdit: boolean;
	isShowFilter: boolean;
	campaignId: StateType<CampaignData>;
	setCampaignFormData: Dispatch<SetStateAction<FormValues>>;
	formErrors: string[];
	setFormErrors: Dispatch<SetStateAction<string[]>>;
}

const CampaignForm = ({
	handleGetDonors,
	handleGenerateCampaign,
	loading,
	onCancel,
	isShowFilter,
	campaignId,
	setCampaignFormData,
	formErrors,
	setFormErrors,
}: P) => {
	const { setSimpleErrorSnack, setCheckedDonorx, checkedDonorx } = React.useContext(GlobalContexts);
	const { id } = useParams();
	const classes = useStyles();
	const global = useGlobalStyles();
	const { getReceiptSchedule } = useRecieptScheduleAction();
	const { getSocieties } = useSocietyActions();
	const { getPaymentMethods } = usePaymentmethodsActions();
	const { getAllDonationTypes } = useDonationTypesActions();
	const { getDonors } = useDonorActions();
	const [visibleSearch, setVisibleSearch] = useState(false);
	const [formData, setFormData] = useState<FormValues>({
		start: '',
		end: '',
		donationType: [],
		receiptFrequency: [],
		society: [],
		preferredSociety: [],
		paymentMethod: [],
		communication: [],
		name: '',
		donors: [],
	});
	const [allSociety, setAllSoceity] = React.useState<StateType<Society[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [allPM, setPM] = React.useState<StateType<PaymentMethod[]>>({
		data: null,
		error: null,
		loading: false,
	});

	const allSocieties = allSociety?.data;
	const allPaymentMethods = allPM?.data;
	const [getAllDonors, setGetAllDonors] = React.useState<StateType<DonorData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [isDonorSet, setIsDonorset] = useState(false);
	const [receiptSchedule, setReceiptSchedule] = useState<StateType<ReceiptSchedule[]>>({
		data: null,
		error: null,
		loading: false,
	});

	const [donationTypes, setDonationTypes] = React.useState<StateType<DonationTypes[]>>({
		data: null,
		loading: false,
		error: null,
	});

	useEffect(() => {
		getAllDonationTypes({ donationTypes, setDonationTypes });
		getReceiptSchedule({ receiptSchedule, setReceiptSchedule });
		getSocieties({ allSociety, setAllSoceity });
		getPaymentMethods({ allPM, setPM });
	}, []);

	useEffect(() => {
		if (!visibleSearch && checkedDonorx != formData.donors) {
			setCheckedDonorx(formData.donors);
		}
	}, [visibleSearch]);

	const handleVisibleSearch = () => {
		setVisibleSearch(!visibleSearch);
	};

	const handleAddDonor = (donors: adhocDonor[]) => {
		setFormData({
			...formData,
			donors,
		});
		handleVisibleSearch();
	};

	const handleChange = (field: string, value: string) => {
		if (formErrors.includes(field)) {
			setFormErrors(formErrors.filter((item) => item !== field));
		}
		setFormData({
			...formData,
			[field]: value,
		});
	};

	const handleSelectChange = (field: string, value: string | string[] | boolean) => {
		setFormData({
			...formData,
			[field]: typeof value === 'string' ? value.split(',') : value,
		});
	};
	const validation = (): boolean => {
		const errors = [];
		if (!formData.name) {
			errors.push('name');
		}
		if (!formData.end) {
			errors.push('end');
		}
		if (!formData.start) {
			errors.push('start');
		}
		setFormErrors(errors);
		if (errors.length) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'Please fill all the required fields.',
			});
			return false;
		}
		return true;
	};
	const handleGenerate = async (isGenerate?: boolean) => {
		if (!validation()) return;

		await handleGetDonors(formData, isGenerate);
	};
	const handleGenerateWithId = async (isGenerate?: boolean) => {
		if (!validation()) return;

		await handleGenerateCampaign(formData, isGenerate);
	};

	const handleCancel = () => {
		setFormData({
			start: '',
			end: '',
			minAmount: '',
			maxAmount: '',
			donors: [],
			donationType: [],
			receiptFrequency: [],
			society: [],
			preferredSociety: [],
			paymentMethod: [],
			communication: [],
			magazine: null as any,
			noFollowAppeal: null as any,
			name: formData.name,
		});
		setCheckedDonorx([]);
		onCancel();
	};

	const communicationArr = ({
		noMail,
		excludeFromParishList,
		returnMail,
		deceased,
		isCommunicationNone,
	}: {
		noMail: boolean;
		excludeFromParishList: boolean;
		returnMail: boolean;
		deceased: boolean;
		isCommunicationNone: boolean;
	}) => {
		const arr = [];

		if (noMail) arr.push('noMail');
		if (excludeFromParishList) arr.push('excludeFromParishList');
		if (returnMail) arr.push('returnMail');
		if (deceased) arr.push('deceased');
		if (isCommunicationNone) arr.push('isCommunicationNone');

		return arr;
	};

	const prefferedSocietyArr = ({
		faithPropagation,
		stPeter,
		holyChildhood,
	}: {
		faithPropagation: boolean;
		stPeter: boolean;
		holyChildhood: boolean;
	}) => {
		const arr = [];

		if (faithPropagation) arr.push('faithPropagation');
		if (stPeter) arr.push('stPeter');
		if (holyChildhood) arr.push('holyChildhood');

		return arr;
	};
	useEffect(() => {
		if (!id || !campaignId?.data || getAllDonors?.loading) return;
		const {
			name,
			minAmount,
			maxAmount,
			startDate,
			endDate,
			isCommunicationNone,
			noMail,
			excludeFromParishList,
			returnMail,
			deceased,
			receiptFrequencyIds,
			donationTypeIds,
			societyIds,
			paymentMethodIds,
			donors,
			magazine,
			faithPropagation,
			stPeter,
			holyChildhood,
			noFollowAppeal,
		} = campaignId?.data;
		setFormData({
			name,
			minAmount: minAmount?.toString() || '',
			maxAmount: maxAmount?.toString() || '',
			start: startDate || '',
			end: endDate || '',
			receiptFrequency: receiptFrequencyIds?.map((ele) => ele?.toString()) || [],
			donationType: donationTypeIds?.map((ele) => ele?.toString()) || [],
			society: societyIds?.map((ele) => ele?.toString()) || [],
			paymentMethod: paymentMethodIds?.map((ele) => ele?.toString()) || [],
			donors: donors?.map((curr) => curr) || [],
			communication:
				communicationArr({ noMail, excludeFromParishList, returnMail, deceased, isCommunicationNone }) || [],
			preferredSociety:
				prefferedSocietyArr({
					faithPropagation,
					stPeter,
					holyChildhood,
				}) || [],
			magazine: magazine,
			noFollowAppeal: noFollowAppeal,
		});
	}, [campaignId?.data, id, getAllDonors.data?.body]);

	useEffect(() => {
		getDonors({
			setDonors: setGetAllDonors,
			donors: getAllDonors,
		});
	}, []);

	useEffect(() => {
		setCampaignFormData(formData);
	}, [formData]);
	useEffect(() => {
		setCheckedDonorx([]);
		if (!campaignId?.data?.donors?.length || isDonorSet) return;
		setCheckedDonorx(campaignId?.data?.donors as adhocDonor[]);
		setIsDonorset(true);
	}, [campaignId?.data]);
	return (
		<Stack>
			<Box>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
					<Grid item xs={12} md={4} sm={6} mb={3}>
						<Typography variant="body2" className={global.formLabelText} mb={1}>
							Campaign Name
						</Typography>
						<Stack direction="row" alignItems="center" pr={{ lg: 5, md: 0 }}>
							<TextField
								size={'small'}
								fullWidth
								placeholder={'Campaign Name'}
								value={formData.name}
								onChange={(e) => handleChange('name', e.target.value)}
							/>
						</Stack>
						{formErrors.includes('name') && (
							<span className={global.errorText}>Campaign Name is required</span>
						)}
					</Grid>
				</Grid>

				{isShowFilter && (
					<>
						<Box className={classes.pageDivider}>Criteria</Box>
						{loading ? (
							<>
								<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
									{[...Array.from({ length: 11 })].map((i, index) => (
										<Grid key={index} item xs={12} md={4} sm={6} mb={3}>
											<Skeleton variant="text" width={130} height={30} />
											<Skeleton variant="rectangular" width={250} height={40} />
										</Grid>
									))}
								</Grid>
							</>
						) : (
							<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
								<Grid item xs={12} md={4} sm={6} mb={3}>
									<Typography variant="body2" className={global.formLabelText} mb={1}>
										Donation Received Date Range
									</Typography>
									<Stack direction="row" pr={{ lg: 5, md: 0 }}>
										<BasicDatePicker
											size="small"
											value={formData.start}
											setValue={(e) => handleChange('start', e.target.value)}
										/>
										<Stack m={1}>To</Stack>
										<BasicDatePicker
											size="small"
											value={formData.end}
											setValue={(e) => handleChange('end', e.target.value)}
											maxDate={moment()}
										/>
									</Stack>
									{formErrors.includes('start') || formErrors.includes('end') ? (
										<span className={global.errorText}>Donation Received Date Range is required</span>
									) : null}
								</Grid>
								<Grid item xs={12} md={4} sm={6} mb={3}>
									<Typography variant="body2" className={global.formLabelText} mb={1}>
										Receipt Frequency
									</Typography>
									<Stack pr={{ lg: 5, md: 0 }}>
										<Select
											size="small"
											value={formData.receiptFrequency}
											name="receiptFrequency"
											multiple
											onChange={(event) => {
												handleSelectChange('receiptFrequency', event.target.value);
											}}
										>
											{receiptSchedule.data?.map(({ id, schedule }) => (
												<MenuItem key={id} value={`${id}`}>
													{schedule === 'Yearly' ? 'Year-End' : schedule}
												</MenuItem>
											))}
										</Select>
									</Stack>
								</Grid>
								<Grid item xs={12} md={4} sm={6} mb={3}>
									<Typography variant="body2" className={global.formLabelText} mb={1}>
										Donation Type
									</Typography>
									<Stack pr={{ lg: 5, md: 0 }}>
										<Select
											size="small"
											value={formData.donationType}
											name="donationType"
											multiple
											onChange={(event) => {
												handleSelectChange('donationType', event.target.value);
											}}
										>
											{(donationTypes?.data || [])?.map(
												(x: { id: number; description: string; type: string }) => (
													<MenuItem key={x.id} value={`${x.id}`}>
														{`${x.type} - ${x.description}`}
													</MenuItem>
												),
											)}
										</Select>
									</Stack>
								</Grid>
								<Grid item xs={12} md={4} sm={6} mb={3}>
									<Typography variant="body2" className={global.formLabelText} mb={1}>
										Society
									</Typography>
									<Stack pr={{ lg: 5, md: 0 }}>
										<Select
											size="small"
											value={formData.society}
											name="society"
											multiple
											onChange={(event) => {
												handleSelectChange('society', event.target.value);
											}}
										>
											{(allSocieties || [])
												?.filter((curr) => curr.code !== 'AN')
												.map((x: { id: number; code: string }) => (
													<MenuItem key={x.id} value={`${x.id}`}>
														{x.code}
													</MenuItem>
												))}
										</Select>
									</Stack>
								</Grid>
								<Grid item xs={12} md={4} sm={6} mb={3}>
									<Typography variant="body2" className={global.formLabelText} mb={1}>
										Preferred Society
									</Typography>
									<Stack pr={{ lg: 5, md: 0 }}>
										<Select
											size="small"
											value={formData.preferredSociety}
											name="preferredSociety"
											multiple
											onChange={(event) => {
												handleSelectChange('preferredSociety', event.target.value.toString());
											}}
										>
											<MenuItem value={'faithPropagation'}>Propagation of Faith</MenuItem>
											<MenuItem value={'holyChildhood'}>Holy Childhood</MenuItem>
											<MenuItem value={'stPeter'}>Saint Peter</MenuItem>
										</Select>
									</Stack>
								</Grid>
								<Grid item xs={12} md={4} sm={6} mb={3}>
									<Typography variant="body2" className={global.formLabelText} mb={1}>
										Magazine
									</Typography>
									<Stack pr={{ lg: 5, md: 0 }}>
										<Select
											size="small"
											value={formData?.magazine}
											name="magazine"
											onChange={(event) => {
												handleSelectChange('magazine', event.target.value);
											}}
										>
											<MenuItem value={null as any}>Clear</MenuItem>
											<MenuItem value={true as any}>True</MenuItem>
											<MenuItem value={false as any}>False</MenuItem>
										</Select>
									</Stack>
								</Grid>
								<Grid item xs={12} md={4} sm={6} mb={3}>
									<Typography variant="body2" className={global.formLabelText} mb={1}>
										No Follow up Appeal
									</Typography>
									<Stack pr={{ lg: 5, md: 0 }}>
										<Select
											size="small"
											value={formData.noFollowAppeal}
											name="noFollowAppeal"
											onChange={(event) => {
												handleSelectChange('noFollowAppeal', event.target.value);
											}}
										>
											<MenuItem value={null as any}>Clear</MenuItem>
											<MenuItem value={true as any}>True</MenuItem>
											<MenuItem value={false as any}>False</MenuItem>
										</Select>
									</Stack>
								</Grid>
								<Grid item xs={12} md={4} sm={6} mb={3}>
									<Typography variant="body2" className={global.formLabelText} mb={1}>
										Payment Method
									</Typography>
									<Stack pr={{ lg: 5, md: 0 }}>
										<Select
											size="small"
											value={formData.paymentMethod}
											name="paymentMethod"
											multiple
											onChange={(event) => {
												handleSelectChange('paymentMethod', event.target.value);
											}}
										>
											{(allPaymentMethods || []).map((x: { id: number; code: string; name: string }) => (
												<MenuItem key={x.id} value={`${x.id}`}>
													{x.name}
												</MenuItem>
											))}
										</Select>
									</Stack>
								</Grid>
								<Grid item xs={12} md={4} sm={6} mb={3}>
									<Typography variant="body2" className={global.formLabelText} mb={1}>
										Donation Amount Range
									</Typography>
									<Stack direction="row" alignItems="center" pr={{ lg: 5, md: 0 }}>
										<TextField
											size={'small'}
											fullWidth
											placeholder={'Min'}
											value={formData.minAmount}
											onChange={(e) => handleChange('minAmount', e.target.value)}
										/>
										<Stack sx={{ margin: 1 }}>-</Stack>
										<TextField
											size={'small'}
											fullWidth
											placeholder={'Max'}
											value={formData.maxAmount}
											onChange={(e) => handleChange('maxAmount', e.target.value)}
										/>
									</Stack>
									{formErrors.includes('minAmount') || formErrors.includes('maxAmount') ? (
										<span className={global.errorText}>Donation Amount Range is required</span>
									) : null}
								</Grid>
								<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
									<Grid container>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												Communication (Is not equal to)
											</Typography>
										</Grid>
									</Grid>
									<Select
										fullWidth
										size="small"
										value={formData?.communication || []}
										name="communication"
										multiple
										onChange={(event) => {
											handleSelectChange('communication', event.target.value);
										}}
									>
										<MenuItem value={'isCommunicationNone'}>Select All</MenuItem>
										<MenuItem value={'noMail'}>Do not send email</MenuItem>
										<MenuItem value={'excludeFromParishList'}>Exclude from Paris List</MenuItem>
										<MenuItem value={'returnMail'}>Returned Mail</MenuItem>
										<MenuItem value={'deceased'}>Deceased</MenuItem>
									</Select>
								</Grid>
								<Grid item xs={12} md={4} sm={6} mb={3}>
									<Typography variant="body2" className={global.formLabelText} mb={1}>
										Donor
									</Typography>
									<Stack direction="row" alignItems={'center'} pr={{ lg: 5, md: 0 }}>
										<TextField
											size={'small'}
											value={formData?.donors
												?.map((item: any) => {
													return `${item?.name}`;
												})
												.join(', ')}
											placeholder={'Search donors'}
											disabled
											fullWidth
										/>

										<IconButton
											onClick={() => handleVisibleSearch()}
											className={global.whiteBtn}
											size="large"
										>
											<PersonSearch />
										</IconButton>

										<IconButton
											onClick={() => {
												setFormData({ ...formData, donors: [] });
												setCheckedDonorx([]);
											}}
											size="small"
											className={global.whiteBtn}
										>
											<Cancel />
										</IconButton>
									</Stack>
								</Grid>
							</Grid>
						)}
					</>
				)}
			</Box>
			{isShowFilter && (
				<Stack m={3} direction="row" spacing={2}>
					<Button variant="outlined" startIcon={<Cancel />} onClick={handleCancel}>
						Clear Criteria
					</Button>
					<LoadingButton
						startIcon={<AddTask />}
						variant="contained"
						onClick={() => {
							if (id) {
								handleGenerateWithId(true);
							} else {
								handleGenerate(true);
							}
						}}
						loading={loading}
						loadingPosition="start"
						size="small"
					>
						Generate
					</LoadingButton>
				</Stack>
			)}
			{visibleSearch && (
				<DonorSearch
					handleVisibleSearch={handleVisibleSearch}
					visibleSearch={visibleSearch}
					handleAddAdHoc={handleAddDonor}
					isMultiSelect
				/>
			)}
		</Stack>
	);
};

export default CampaignForm;
