import React, { useState, useEffect } from 'react';
import { Formik, FormikProps, Form, Field } from 'formik';
import { Grid, Stack, Typography, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormTextField } from 'Components/Common/TextField';
import { useGlobalStyles } from 'Components/Common/global/global';
import { Appeal } from 'contexts/intialstates/Types';
import { ConfirmationNumber, Notes } from '@mui/icons-material';
import * as yup from 'yup';
import useUserActions from 'hooks/useUserActions';

export interface AppealValues {
	code: string;
	description: string;
}

interface CreateEditAppealProp {
	handleSubmit: (values: AppealValues) => void;
	selectedEditData?: Appeal;
	saveLoading?: boolean;
}

const CreditEditAppeal = ({ handleSubmit, selectedEditData, saveLoading }: CreateEditAppealProp) => {
	const { handleRoles } = useUserActions();
	const global = useGlobalStyles();
	const [initial, setInitial] = useState<AppealValues>({
		code: '',
		description: '',
	});

	useEffect(() => {
		if (!selectedEditData) {
			setInitial({
				code: '',
				description: '',
			});
		} else {
			const { code, description } = selectedEditData;
			setInitial({
				code,
				description,
			});
		}
	}, [selectedEditData]);

	const validationAPpeal = yup.object().shape({
		code: yup.string().required('Code is required'),
	});

	return (
		<Formik
			enableReinitialize
			onSubmit={handleSubmit}
			validationSchema={validationAPpeal}
			initialValues={initial}
		>
			{() => (
				<Form noValidate autoComplete="off">
					<Stack>
						<Stack sx={{ mb: 2 }}>
							<Grid container>
								<Grid item>
									<ConfirmationNumber className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Code
									</Typography>
								</Grid>
							</Grid>
							<Field
								fullWidth
								name="code"
								size="small"
								variant="outlined"
								placeholder="Code"
								component={FormTextField}
							/>
						</Stack>
						<Stack sx={{ mb: 2 }}>
							<Grid container>
								<Grid item>
									<Notes className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Description
									</Typography>
								</Grid>
							</Grid>
							<Field
								fullWidth
								name="description"
								size="small"
								variant="outlined"
								placeholder="Description"
								component={FormTextField}
								multiline
								rows={4}
							/>
						</Stack>
						<Stack>
							{handleRoles('admin-actions') && (
								<LoadingButton
									fullWidth
									type="submit"
									color="primary"
									variant="contained"
									sx={{ marginTop: '30px' }}
									loading={saveLoading}
									loadingPosition="start"
								>
									{selectedEditData ? 'Save' : 'Create Appeal'}
								</LoadingButton>
							)}
						</Stack>
					</Stack>
				</Form>
			)}
		</Formik>
	);
};

export default CreditEditAppeal;
