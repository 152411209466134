import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import useDownloads, { DownloadData, IDownload } from 'hooks/useDownloads';
import { StateType } from 'contexts/intialstates/Types';
import moment from 'moment';
import { Stack } from '@mui/system';
import { IconButton, Skeleton } from '@mui/material';
import { Clear, Download } from '@mui/icons-material';
import { GlobalContexts } from 'contexts/GlobalContext';

interface Column {
	id: 'sno' | 'name' | 'requested' | 'completed' | 'action';
	label: string;
	minWidth?: number;
	align?: 'left';
	format?: (value: number) => string;
}

const columns: readonly Column[] = [
	{ id: 'sno', label: 'Sno.', minWidth: 50 },
	{ id: 'name', label: 'Name', minWidth: 250 },
	{ id: 'requested', label: 'Requested', minWidth: 150 },
	{ id: 'completed', label: 'Completed', minWidth: 150 },
	{ id: 'action', label: '', minWidth: 150 },
];

const DownloadsTable = () => {
	const { getFiles, deleteFile, downloadFile } = useDownloads();
	const { setPendingFiles } = React.useContext(GlobalContexts);

	const [downloads, setDownloads] = React.useState<StateType<DownloadData>>({
		data: null,
		loading: false,
		error: null,
	});
	const [pageNumber, setPageNumber] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [reload, setReload] = React.useState(false);
	const [clearLoading, setClearLoading] = React.useState(false);
	const [downloading, setDownloading] = React.useState(false);

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPageNumber(0);
	};

	React.useEffect(() => {
		getFiles({ downloads, setDownloads, pageNumber, pageSize });
	}, [reload, pageNumber, pageSize]);

	const handleClear = async (file: IDownload) => {
		if (!file?.id) return;
		setClearLoading(true);
		await deleteFile(file?.id);
		setPageNumber(0);
		setPageSize(10);
		setReload((prev) => !prev);
		setClearLoading(false);
	};

	const handleDownload = async (file: IDownload) => {
		if (!file?.id) return;
		setPendingFiles((prev) => prev.filter((item) => item !== file?.id));
		setDownloading(true);
		await downloadFile(file?.id, file?.name);
		setDownloading(false);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPageNumber(newPage);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<TableContainer sx={{ maxHeight: '70vh' }}>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{downloads.loading
							? [...Array.from({ length: 10 })].map((i, index) => {
									return (
										<TableRow key={index}>
											{columns.map((c, index) => (
												<TableCell key={index} style={{ minWidth: c?.minWidth }} align={'left'}>
													<Skeleton height={30} />
												</TableCell>
											))}
										</TableRow>
									);
							  })
							: downloads.data?.body?.map((row, index) => {
									return (
										<TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
											<TableCell align="left">{pageNumber * pageSize + index + 1}</TableCell>
											<TableCell align="left">{row?.name?.toLowerCase() || '-'}</TableCell>
											<TableCell align="left">
												{row?.dateRequested
													? moment(row?.dateRequested + 'Z').format('MMMM Do YYYY, h:mm:ss')
													: '-'}
											</TableCell>
											<TableCell align="left">
												{row?.dateCompleted
													? moment(row?.dateCompleted + 'Z').format('MMMM Do YYYY, h:mm:ss')
													: '-'}
											</TableCell>
											<TableCell align="left">
												<Stack direction="row" spacing={2} alignItems="center">
													<Stack>
														<IconButton
															onClick={() => handleDownload(row)}
															disabled={row?.dateCompleted && !downloading ? false : true}
															sx={{
																background: 'white',
																borderRadius: '2px',
																p: 0,
																border: '1px solid var(--mainColor)',
															}}
														>
															<Download sx={{ color: 'var(--mainColor)' }} />
														</IconButton>
													</Stack>

													<IconButton
														disabled={row?.dateCompleted && !clearLoading ? false : true}
														onClick={() => handleClear(row)}
														sx={{
															background: 'white',
															borderRadius: '2px',
															p: 0,
															border: '1px solid var(--mainColor)',
														}}
													>
														<Clear sx={{ color: 'var(--mainColor)' }} />
													</IconButton>
												</Stack>
											</TableCell>
										</TableRow>
									);
							  })}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				component="div"
				rowsPerPageOptions={[10, 25, 50, 100]}
				count={downloads.data?.totalRecords || 0}
				rowsPerPage={pageSize}
				page={pageNumber}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
};

export default DownloadsTable;
