import React from 'react';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ColumnType, batchNotificationsHead } from 'Components/Common/Tables/TableHeaders';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { BatchNotificationData, FilterPayload, StateType } from 'contexts/intialstates/Types';
import { convertDate, fullMonthYear } from 'helpers/dayAndTime';
import useNotificationActions from 'hooks/useNotificationActions';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { Batch } from 'Components/Batch/Batch';
import useUserActions from 'hooks/useUserActions';
import { ContentSkeleton } from 'Components/Deposits/CreateEditDepositWithDonations/Content';

const BatchNotifications = () => {
	const navigate = useNavigate();

	const [batch, setBatch] = React.useState<StateType<BatchNotificationData[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const { handleRoles } = useUserActions();

	const [tableArr, setTableArr] = React.useState<{ tableData: any; curr: BatchNotificationData }[]>();
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);
	const [reload, setIsReload] = React.useState(false);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const { getBatch } = useNotificationActions();

	React.useEffect(() => {
		getBatch({
			batch,
			setBatch,
		});
	}, []);

	const editReceiptTemplate = (row: Batch) => {
		if (row.type == 'PAPBANK') {
			navigate(`/bookkeeping/batch/${row?.id}?tab=papbank`);

			return;
		}
		if (row.type === 'PAPCARD') {
			navigate(`/bookkeeping/batch/${row?.id}?tab=papcard`);
			return;
		}
		if (row.type == 'CHEQUE') {
			navigate(`/bookkeeping/batch/${row?.id}?tab=cheque`);
			return;
		}
	};

	React.useEffect(() => {
		if (batch.data?.length !== 0) {
			setTableArr(
				batch.data?.map((curr) => ({
					tableData: () => {
						return curr.batches.length
							? curr.batches.map((row: Batch, index: number) => {
									return {
										id: row?.id,
										type: row?.type,

										's/n': index + 1,
										batchNumber: row?.batchNumber || 'N/A',
										numberOfDeposit: row?.depositCount || 'N/A',
										lastUpdated: curr.lastUpdated ? `${convertDate(curr.lastUpdated)}` : 'N/A',
										status: row?.batchStatus || 'N/A',
										blank: handleRoles('bookkeeping-actions') && (
											<Stack direction="row" spacing={2}>
												<Button variant="outlined" sx={{ textTransform: 'capitalize', width: 120 }}>
													Archive
												</Button>
												<Button variant="contained" sx={{ textTransform: 'capitalize', width: 120 }}>
													Post
												</Button>
											</Stack>
										),
									};
							  })
							: [];
					},
					curr,
				})),
			);
		}
	}, [batch.data, batch.data?.length]);

	const getColumnData = () => {
		return trimList.map((column: ColumnType) => {
			return {
				...column,
			};
		});
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	return (
		<>
			<Stack
				alignItems="center"
				direction={{ xs: 'column', sm: 'row' }}
				justifyContent={'space-between'}
				spacing={1}
				mb={5}
			>
				<Stack direction="column">
					<Stack direction="row" spacing={4} alignItems="center">
						<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
							Batch
						</Typography>
					</Stack>
					<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
						View and manage all non-posted batches here.
					</Typography>
				</Stack>
				<Stack sx={{ mb: 3 }} direction="row" alignItems={'center'} justifyContent="flex-end">
					<TrimTables
						name={'batchNotificationsHead'}
						list={batchNotificationsHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
					<FilterListButton
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
						reload={reload}
						setIsReload={setIsReload}
					/>
				</Stack>
			</Stack>

			<Stack justifyContent="center" alignItems="center">
				{batch.loading && !tableArr?.length && <ContentSkeleton />}
				{tableArr?.length !== 0 &&
					tableArr?.map((curr, index) => (
						<Paper key={index} sx={{ width: '100%', overflow: 'hidden', mb: 3 }}>
							<CustomTable
								loading={batch?.loading}
								columnData={getColumnData()}
								tableData={curr.tableData()}
								handleDrag={handleDrag}
								handleResize={handleResize}
								emptyMessage={'No Batch Available'}
								unPaginated
								handleRowClick={(row) => editReceiptTemplate(row as Batch)}
								unPaginatedTitle={fullMonthYear(curr.curr.lastUpdated as string)}
								headerBg="white"
							/>
						</Paper>
					))}
			</Stack>
		</>
	);
};

export default BatchNotifications;
