import React from 'react';
import {
	Close,
	TaskAlt,
	PersonAddAlt,
	LocationCity,
	Phone,
	PhoneIphone,
	Email,
	PersonOutline,
	LocationOn,
	MyLocation,
	Terrain,
	Markunread,
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { useGlobalStyles } from 'Components/Common/global/global';
import { FormTextField } from 'Components/Common/TextField';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

export interface Values {
	salutation: string;
	firstName: string;
	lastName: string;
	streetline1: string;
	streetline2: string;
	city: string;
	postalCode: string;
	country: string;
	phone: string;
	mobile: string;
	email: string;
	fax: string;
	id?: string;
}

interface CreateEditOutputProp {
	handleSubmit: (values: Values) => void;
	selectedEditData: Values;
	saveLoading?: boolean;
	setVisible: any;
	setSelectedData: any;
}

const OutputForm = ({
	handleSubmit,
	selectedEditData,
	saveLoading,
	setVisible,
	setSelectedData,
}: CreateEditOutputProp) => {
	const global = useGlobalStyles();

	const [initial, setInitial] = useState<Values>({
		salutation: '',
		firstName: '',
		lastName: '',
		streetline1: '',
		streetline2: '',
		city: '',
		postalCode: '',
		country: '',
		phone: '',
		mobile: '',
		email: '',
		fax: '',
	});

	useEffect(() => {
		if (!selectedEditData) return;
		const {
			id,
			salutation,
			email,
			firstName,
			lastName,
			fax,
			mobile,
			city,
			postalCode,
			streetline1,
			streetline2,
			country,
			phone,
		} = selectedEditData as any;
		setInitial({
			salutation,
			email,
			firstName,
			lastName,
			fax,
			mobile,
			city,
			postalCode,
			streetline1,
			streetline2,
			country,
			phone,
			id,
		});
	}, [selectedEditData]);

	const validation = yup.object().shape({
		firstName: yup.string().typeError('Invalid first name').required('First name is required'),
		lastName: yup.string().typeError('Invalid last name').required('Last name is required'),
	});
	const handleCloseEdit = () => {
		setVisible(false);
		setSelectedData(undefined);
	};

	return (
		<>
			<Formik
				enableReinitialize
				onSubmit={handleSubmit}
				validationSchema={validation}
				initialValues={initial}
			>
				{() => (
					<Form noValidate autoComplete="off">
						<Stack>
							<Typography sx={{ fontSize: 14, fontWeight: 600, mt: 2 }}>Personal Details</Typography>
							<Divider />
							<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
								<Grid item xs={12} sm={6} mb={3} pr={{ lg: 0, md: 0 }}>
									<Grid container>
										<Grid item>
											<PersonAddAlt className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												Salutation
											</Typography>
										</Grid>
									</Grid>
									<Field
										fullWidth
										name="salutation"
										size="small"
										variant="outlined"
										placeholder="Salutation"
										component={FormTextField}
									/>
								</Grid>
								<Grid item xs={12} sm={6} mb={3} pr={{ lg: 0, md: 0 }}>
									<Grid container>
										<Grid item>
											<PersonAddAlt className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												First Name
											</Typography>
										</Grid>
									</Grid>
									<Field
										fullWidth
										name="firstName"
										size="small"
										variant="outlined"
										placeholder="First Name"
										component={FormTextField}
									/>
								</Grid>
								<Grid item xs={12} sm={6} mb={3} pr={{ lg: 0, md: 0 }}>
									<Grid container>
										<Grid item>
											<PersonAddAlt className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												Last Name
											</Typography>
										</Grid>
									</Grid>
									<Field
										fullWidth
										name="lastName"
										size="small"
										variant="outlined"
										placeholder="Last Name"
										component={FormTextField}
									/>
								</Grid>
							</Grid>
							<Typography sx={{ fontSize: 14, fontWeight: 600, mt: 2 }}>Contact Details</Typography>
							<Divider />
							<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2}>
								<Grid item xs={12} sm={6} mb={3} pr={{ lg: 0, md: 0 }}>
									<Grid container>
										<Grid item>
											<Phone className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												Phone
											</Typography>
										</Grid>
									</Grid>
									<Field
										name="phone"
										size="small"
										variant="outlined"
										placeholder="Phone"
										component={FormTextField}
									/>
								</Grid>
								<Grid item xs={12} sm={6} mb={3} pr={{ lg: 0, md: 0 }}>
									<Grid container>
										<Grid item>
											<PhoneIphone className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												Mobile
											</Typography>
										</Grid>
									</Grid>
									<Field
										name="mobile"
										size="small"
										variant="outlined"
										placeholder="Mobile"
										component={FormTextField}
									/>
								</Grid>
								<Grid item xs={12} sm={6} mb={3} pr={{ lg: 0, md: 0 }}>
									<Grid container>
										<Grid item>
											<Email className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												Email
											</Typography>
										</Grid>
									</Grid>
									<Field
										name="email"
										size="small"
										variant="outlined"
										placeholder="Email"
										component={FormTextField}
									/>
								</Grid>
								<Grid item xs={12} sm={6} mb={3} pr={{ lg: 0, md: 0 }}>
									<Grid container>
										<Grid item>
											<PersonOutline className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												Fax
											</Typography>
										</Grid>
									</Grid>
									<Field
										name="fax"
										size="small"
										variant="outlined"
										placeholder="Fax"
										component={FormTextField}
									/>
								</Grid>
							</Grid>
							<Typography sx={{ fontSize: 14, fontWeight: 600, mt: 2 }}>Address Details</Typography>
							<Divider />
							<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2}>
								<Grid item xs={12} sm={6} mb={3} pr={{ lg: 0, md: 0 }}>
									<Grid container>
										<Grid item>
											<LocationOn className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												Streetline 1
											</Typography>
										</Grid>
									</Grid>
									<Field
										name="streetline1"
										size="small"
										variant="outlined"
										placeholder="Streetline 1"
										component={FormTextField}
									/>
								</Grid>
								<Grid item xs={12} sm={6} mb={3} pr={{ lg: 0, md: 0 }}>
									<Grid container>
										<Grid item>
											<MyLocation className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												Streetline 2
											</Typography>
										</Grid>
									</Grid>
									<Field
										name="streetline2"
										size="small"
										variant="outlined"
										placeholder="Streetline 2"
										component={FormTextField}
									/>
								</Grid>
								<Grid item xs={12} sm={6} mb={3} pr={{ lg: 0, md: 0 }}>
									<Grid container>
										<Grid item>
											<LocationCity className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												City
											</Typography>
										</Grid>
									</Grid>
									<Field
										name="city"
										size="small"
										variant="outlined"
										placeholder="city"
										component={FormTextField}
									/>
								</Grid>
								<Grid item xs={12} sm={6} mb={3} pr={{ lg: 0, md: 0 }}>
									<Grid container>
										<Grid item>
											<Markunread className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												Postal Code
											</Typography>
										</Grid>
									</Grid>
									<Field
										name="postalCode"
										size="small"
										variant="outlined"
										placeholder="postalCode"
										component={FormTextField}
									/>
								</Grid>
								<Grid item xs={12} sm={6} mb={3} pr={{ lg: 0, md: 0 }}>
									<Grid container>
										<Grid item>
											<Terrain className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												Country
											</Typography>
										</Grid>
									</Grid>
									<Field
										name="country"
										size="small"
										variant="outlined"
										placeholder="country"
										component={FormTextField}
									/>
								</Grid>
							</Grid>

							<Stack direction="row" my={3} justifyContent={'center'} spacing={2} alignItems="center">
								<LoadingButton
									type="submit"
									color="primary"
									variant="contained"
									sx={{ width: 'fit-content', textTransform: 'capitalize' }}
									loading={saveLoading}
									startIcon={<TaskAlt />}
									loadingPosition="start"
								>
									Save
								</LoadingButton>
								<Button
									variant="outlined"
									sx={{ width: 'fit-content', textTransform: 'capitalize' }}
									onClick={handleCloseEdit}
									startIcon={<Close />}
								>
									Cancel
								</Button>
							</Stack>
						</Stack>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default OutputForm;
