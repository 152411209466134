import { styled, Box } from '@mui/system';
import { Typography, Button } from '@mui/material';

export const Title = styled(Typography)`
	font-size: 18px;
	font-weight: 600;
`;
export const InputSection = styled('form')`
	padding: 0 20px;
`;
export const InputContainer = styled(Box)`
	display: flex;
	justify-content: center;
	width: 100%;
`;

export const SubmitBtn = styled(Button)`
	font-size: 13px;
	text-transform: capitalize !important;
	padding: 3px 13px !important;
`;
