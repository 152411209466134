import React, { useEffect } from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import TemplateDeposit from './TemplateDeposit';
import { useNavigate, useLocation } from 'react-router-dom';

const Index = () => {
	const location = useLocation();
	const querySearch = new URLSearchParams(location.search);
	const type = querySearch.get('tab');
	const navigate = useNavigate();
	const [tabValue, setTabValue] = React.useState('');

	useEffect(() => {
		if (type) {
			setTabValue(type);
			navigate(`/bookkeeping/template/deposits?tab=${type}`);
			return;
		}
		setTabValue('creditcard');
		navigate(`/bookkeeping/template/deposits?tab=creditcard`);
	}, [type]);

	const handleChange = (event: any, value: string) => {
		setTabValue(value);
		navigate(`/bookkeeping/template/deposits?tab=${value}`);
	};

	return (
		<Box sx={{ width: '100%', typography: 'body1' }}>
			<TabContext value={tabValue}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleChange}>
						<Tab label="Credit Card" value="creditcard" />
						<Tab label="PAP Bank" value="bank" />
					</TabList>
				</Box>
				<TabPanel value="creditcard">
					<TemplateDeposit type={tabValue} />
				</TabPanel>
				<TabPanel value="bank">
					<TemplateDeposit type={tabValue} />
				</TabPanel>
			</TabContext>
		</Box>
	);
};

export default Index;
