import { useContext } from 'react';
import {
	ResponseError,
	StateType,
	TimeStamp,
	TimeStampInfo,
	FilterPayload,
	Donor,
	Society,
	DonationTypes,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';
import { GiftTypeData } from 'hooks/useGiftTypeActions';

export interface NonCashDonation {
	pageNumber: number;
	pageSize: number;
	totalPages: number;
	totalRecords: number;
	body: NonCashDonationData[];
}

export interface NonCashDonationProps {
	nonCashDonations: StateType<NonCashDonation>;
	setNonCashDonations: React.Dispatch<React.SetStateAction<StateType<NonCashDonation>>>;
	page: number;
	pageSize: number;
	orderBy?: string;
	filterPayload?: FilterPayload[];
	donorQuery?: string;
}

export interface NonCashDonationData extends TimeStamp, TimeStampInfo {
	id: number;
	status: number;
	amount: number;
	receivedDate: string;
	archived: boolean;
	depositId: number;
	donorId: number;
	donor: Donor;
	societyId: number;
	society: Society;
	donationTypeId: number;
	donationType: DonationTypes;
	giftTypeId: number;
	giftType: GiftTypeData;
}

interface nonCashDonationPayload {
	donorId?: number;
	amount?: number;
	societyId?: number;
	donationTypeId?: number;
	giftTypeId?: number;
	receivedDate?: string;
}

interface GetNonCashDonationByIdProps {
	nonCashDonation: StateType<NonCashDonationData>;
	setNonCashDonation: React.Dispatch<React.SetStateAction<StateType<NonCashDonationData>>>;
	id: number;
}

const useNonCashDonationActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = useContext(GlobalContexts);

	const getNonCashDonations = async ({
		nonCashDonations,
		setNonCashDonations,
		page,
		pageSize,
		orderBy,
		filterPayload,
		donorQuery,
	}: NonCashDonationProps) => {
		try {
			setNonCashDonations({
				...nonCashDonations,
				loading: true,
			});
			let response;
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			if (page) {
				response = await api.get(
					`noncashdonation/list?PageNumber=${page + 1}&PageSize=${pageSize}${filter}${
						orderBy || '&SortBy=receivedDate%20desc'
					}${donorQuery ? '&donorQuery=' + donorQuery : ''}`,
				);
			} else {
				response = await api.get(
					`noncashdonation/list?&PageSize=${pageSize}${filter}${orderBy || '&SortBy=receivedDate%20desc'}${
						donorQuery ? '&donorQuery=' + donorQuery : ''
					}`,
				);
			}

			setNonCashDonations({
				...nonCashDonations,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || nonCashDonations.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setNonCashDonations({
					...nonCashDonations,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
		}
	};

	const getNonCashDonationById = async ({
		nonCashDonation,
		setNonCashDonation,
		id,
	}: GetNonCashDonationByIdProps) => {
		try {
			setNonCashDonation({
				...nonCashDonation,
				loading: true,
			});
			const response = await api.get(`noncashdonation/${id}`);

			setNonCashDonation({
				...nonCashDonation,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setNonCashDonation({
					...nonCashDonation,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			alert('An error occurred');
		}
	};

	const createNonCashDonation = async (payload: nonCashDonationPayload): Promise<boolean | number> => {
		try {
			const createdData = await api.post(`noncashdonation/create`, {
				...payload,
			});
			if (createdData?.data?.result) {
				setSimpleErrorSnack({
					message: 'Non cash donation created successfully',
					severity: 'success',
					show: true,
				});
				return createdData?.data?.result?.id;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const updateNonCashDonation = async (payload: nonCashDonationPayload, id: number): Promise<boolean> => {
		try {
			const updatedData = await api.put(`noncashdonation/${id}/update`, {
				...payload,
				id,
			});
			if (updatedData?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Non cash donation updated successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deleteNonCashDonation = async (id: number): Promise<boolean> => {
		try {
			const deletedData = await api.remove(`noncashdonation/${id}/archive`);
			if (deletedData?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Non cash donation deleted successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const postNonCashDonation = async (id: number): Promise<boolean> => {
		try {
			const postedData = await api.post(`/noncashdonation/${id}/post`);
			if (postedData?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Non cash donation posted succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	return {
		getNonCashDonations,
		createNonCashDonation,
		updateNonCashDonation,
		deleteNonCashDonation,
		getNonCashDonationById,
		postNonCashDonation,
	};
};

export default useNonCashDonationActions;
