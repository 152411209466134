import React from 'react';
import { Box } from '@mui/system';
import { Stack, Typography, Paper } from '@mui/material';
import ReportCard from './Card';

const lists = [
	{ label: 'Donation Summary Reports', link: 'donations-summary' },
	{ label: 'Split Donation Reports', link: 'split-donations-reports' },
	{ label: 'Donors Summary Reports', link: 'donor-reports' },
	{ label: 'Burse/Chalice Reports', link: 'burse-chalice-reports' },
	{ label: 'Non Cash Donations Reports', link: 'non-cash-donations' },
	{ label: 'Mission Sunday Reports', link: 'missionsunday-reports' },
	{ label: 'Converge Reports', link: 'converge-reports' },
];
const lists2 = [
	{ label: 'Walkathon Report', link: 'walkathon-report' },
	{ label: 'HCA Order Activity Report', link: 'hca-order-activity' },
];
const lists3 = [
	{ label: 'Agreements Report', link: 'annuities-reports' },
	{ label: 'Annuitant/Co-Annuitant', link: 'annuity-miscellaneous-reports' },
	{ label: 'Residual Posting', link: 'residual-reports' },
	{ label: 'Payment List', link: 'payment-list-reports' },
];

const Reports = () => {
	return (
		<>
			<Box>
				<Stack
					alignItems="center"
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent={'space-between'}
					spacing={1}
					mb={4}
				>
					<Stack direction="column">
						<Stack direction="row" spacing={4} alignItems="center">
							<Typography variant="h6" sx={{ color: 'var(--mainColor)' }}>
								Reports
							</Typography>
						</Stack>
						<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
							Generate and Export Reports report.
						</Typography>
					</Stack>
				</Stack>
				<Paper sx={{ minHeight: '70vh', px: 6, py: 4 }}>
					<Stack direction={{ md: 'row', xs: 'column' }} justifyContent="space-between" spacing={5}>
						<ReportCard title="General Reports" lists={lists} />
						<ReportCard title="HCA Reports" lists={lists2} />
						<ReportCard title="Annuity Reports" lists={lists3} />
					</Stack>
				</Paper>
			</Box>
		</>
	);
};

export default Reports;
