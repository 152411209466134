import React from 'react';
import {
	ResponseError,
	HCAMaterials,
	HCAMaterialsData,
	StateType,
	FilterPayload,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { HCAMaterialValues } from 'Components/AdminPanel/HCAMaterials/CreateEditHCAMaterials';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface GetHCAMaterialsTypes {
	setHcaMaterials: React.Dispatch<React.SetStateAction<StateType<HCAMaterialsData>>>;
	hcaMaterials: StateType<HCAMaterialsData>;
	page: number;
	pageSize: number;
	orderBy?: string;
	filterPayload?: FilterPayload[];
}
export interface GetAllHCAMaterialsTypes {
	setHcaMaterials: React.Dispatch<React.SetStateAction<StateType<HCAMaterials[]>>>;
	hcaMaterials: StateType<HCAMaterials[]>;
}
export interface GetHCAMaterialTypes {
	setHcaMaterial: React.Dispatch<React.SetStateAction<StateType<HCAMaterials>>>;
	hcaMaterial: StateType<HCAMaterials>;
	id: number;
}

const useHCAMaterialActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const getHCAMaterialsByID = async ({ setHcaMaterial, hcaMaterial, id }: GetHCAMaterialTypes) => {
		try {
			setHcaMaterial({
				...hcaMaterial,
				loading: true,
				error: null,
			});
			const response = await api.get(`hcaMaterial/${id}`);
			setHcaMaterial({
				...hcaMaterial,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setHcaMaterial({
					...hcaMaterial,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const getHCAMaterials = async ({
		setHcaMaterials,
		hcaMaterials,
		page,
		pageSize,
		orderBy,
		filterPayload,
	}: GetHCAMaterialsTypes) => {
		try {
			setHcaMaterials({
				...hcaMaterials,
				loading: true,
				error: null,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`/hcaMaterial/list?PageNumber=${page + 1}&PageSize=${pageSize}${filter}${
						orderBy || '&SortBy=updated%20desc'
					}`,
				);
			} else {
				response = await api.get(
					`/hcaMaterial/list?&PageSize=${pageSize}${filter}${orderBy || '&SortBy=updated%20desc'}`,
				);
			}

			setHcaMaterials({
				...hcaMaterials,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || hcaMaterials.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setHcaMaterials({
					...hcaMaterials,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const getAllHCAMaterials = async ({ setHcaMaterials, hcaMaterials }: GetAllHCAMaterialsTypes) => {
		try {
			setHcaMaterials({
				...hcaMaterials,
				loading: true,
				error: null,
			});
			const response = await api.get(`/hcaMaterial/lookup-list`, {});
			setHcaMaterials({
				...hcaMaterials,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setHcaMaterials({
					...hcaMaterials,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const createHCAMaterial = async (payload: HCAMaterialValues): Promise<boolean> => {
		try {
			const response = await api.post(`hcaMaterial/create`, {
				...payload,
			});
			if (response?.data?.result) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	const updateHCAMaterial = async (payload: HCAMaterialValues, HCAMaterialID: number): Promise<boolean> => {
		try {
			const response = await api.put(`hcaMaterial/${HCAMaterialID}/update`, {
				...payload,
				id: HCAMaterialID,
			});
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Item updated sucesssfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deleteHCAMaterial = async (HCAMaterialID: number): Promise<boolean> => {
		try {
			const response = await api.remove(`hcaMaterial/${HCAMaterialID}/arhcive`);
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Item deleted succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	return {
		getHCAMaterialsByID,
		getHCAMaterials,
		createHCAMaterial,
		updateHCAMaterial,
		deleteHCAMaterial,
		getAllHCAMaterials,
	};
};

export default useHCAMaterialActions;
