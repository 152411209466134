import React, { useState, useEffect } from 'react';
import { TablePagination, Paper, Tooltip } from '@mui/material';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import { pagination } from 'Components/Common/Tables/Tables';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { sortTable } from 'Components/Common/Tables/sortTable';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import { convertDate } from 'helpers/dayAndTime';
import { StateType, OrderType } from 'contexts/intialstates/Types';
import useLifeExpectancy, { LifeExpectancy, LifeExpectancyData } from 'hooks/useLifeExpectancyAdjustment';

export const lifeExpectancyHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'dateFrom',
		label: 'From Date',
		minWidth: 220,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'dateTo',
		label: 'Date To',
		minWidth: 220,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'adjRate',
		label: 'Adjustment Rate',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'adjMale',
		label: 'Male Adjustment Rate',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'adjFemale',
		label: 'Female Adjustment Rate',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'unisex',
		label: 'Unisex',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'true' }, { name: 'false' }],
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
];

interface P {
	trimList: ColumnType[];
	setTrimList: React.Dispatch<React.SetStateAction<ColumnType[]>>;
	filterPayload: any[];
	setFilterPayload: React.Dispatch<React.SetStateAction<any[]>>;
	handleOpenEdit: (data: LifeExpectancyData) => void;
	reload: boolean;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	page: number;
}

const LifeExpectancyAdjustmentTable = ({
	trimList,
	setTrimList,
	filterPayload,
	setFilterPayload,
	handleOpenEdit,
	reload,
	setReload,
	page,
	setPage,
}: P) => {
	const { getLifeExpectancyAdjustment } = useLifeExpectancy();
	const [lifeExpectancy, setLifeExpectancy] = useState<StateType<LifeExpectancy>>({
		data: null,
		loading: false,
		error: null,
	});
	const [pageSize, setPageSize] = useState(10);
	const [orderType, setOrderType] = useState<OrderType>('asc');
	const [label, setLabel] = useState<string>('');
	const [orderBy, setOderBy] = useState<string>('');

	useEffect(() => {
		getLifeExpectancyAdjustment({
			lifeExpectancy,
			setLifeExpectancy,
			page,
			pageSize,
			orderBy,
			filterPayload,
		});
	}, [page, pageSize, orderBy, reload]);

	const getTableData = () => {
		return lifeExpectancy?.data?.body.length
			? lifeExpectancy.data.body.map((row: LifeExpectancyData, index: number) => {
					return {
						's/n': page * pageSize + index + 1,
						id: row.id,
						dateFrom: row.dateFrom ? convertDate(row?.dateFrom) : 'N/A',
						dateTo: row.dateFrom ? convertDate(row?.dateTo) : 'N/A',
						adjRate: row.adjRate || 'N/A',
						adjMale: row.adjMale || 'N/A',
						adjFemale: row.adjFemale || 'N/A',
						unisex: row.unisex ? 'Yes' : 'No',
						createdBy: row?.createdBy?.name || 'N/A',
						updatedBy: row?.updatedBy?.name || 'N/A',
						created: row?.created ? convertDate(row?.created) : 'N/A',
						updated: row?.updated ? convertDate(row?.updated) : 'N/A',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={`Sort by ${column.label}`}>
							<span
								style={{
									cursor: 'pointer',
									margin: '10px',
								}}
								onClick={() => {
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									});
								}}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							reload={reload}
							setIsReload={setReload}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							setPage={setPage}
							page={page}
						/>
					</>
				),
			};
		});
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	const handleRowClick = (row: any) => {
		const data = lifeExpectancy?.data?.body?.find((item) => item.id === row.id);
		if (!data) return;
		handleOpenEdit(data);
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<CustomTable
				loading={lifeExpectancy?.loading}
				columnData={getColumnData()}
				tableData={getTableData()}
				handleDrag={handleDrag}
				handleResize={handleResize}
				emptyMessage={'No life expectancy adjustment available'}
				handleRowClick={handleRowClick}
			/>
			{(lifeExpectancy?.data?.body?.length as number) > 0 && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
					component="div"
					count={lifeExpectancy?.data?.totalRecords as number}
					rowsPerPage={pageSize}
					page={page}
					sx={pagination}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</Paper>
	);
};

export default LifeExpectancyAdjustmentTable;
