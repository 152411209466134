import React, { useRef } from 'react';
import {
	Stack,
	Table,
	Box,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import Report from '../Report';
import { convertDate } from 'helpers/dayAndTime';
import { useStyles } from 'Components/Reports/MissionSunday/MissionSunday.style';
import { useReactToPrint } from 'react-to-print';
import Logo from 'assets/images/logo.png';
import { currentDate } from 'helpers/dayAndTime';
import { MissionOrderById } from 'hooks/useMissionSundayOrder';

interface P {
	missionSundayReportData: MissionOrderById[];
	start: string;
	end: string;
	handleGetReportPdf: () => void;
}

const MissionSundayReportView = ({ missionSundayReportData, start, end, handleGetReportPdf }: P) => {
	const classes = useStyles();
	const componentRef = useRef(null);

	const handlePrint = useReactToPrint({
		content: () => componentRef?.current,
	});

	const splitter = () => {
		if (!missionSundayReportData?.length) return;
		const missionSundayData: any = [];
		const data = [];
		missionSundayReportData?.map((row) => {
			missionSundayData?.push({
				id: row?.id,
				receivedDate: convertDate(row?.receivedDate),
				code: row?.parish?.parishCode,
				name: row?.parish?.parishName,
				materialCode: row?.materials[0]?.material?.itemCode,
				frQty: row?.materials[0]?.frQuantity,
				enQty: row?.materials[0]?.enQuantity,
			});
			if (row?.materials?.length > 1) {
				row?.materials?.slice(1, row?.materials?.length)?.map((item) => {
					missionSundayData?.push({
						id: '',
						receivedDate: '',
						code: '',
						name: '',
						materialCode: item?.material?.itemCode,
						frQty: item?.frQuantity,
						enQty: item?.enQuantity,
					});
				});
			}
		});
		for (let i = 0; i < missionSundayData?.length; i += 15) {
			data?.push(missionSundayData?.slice(i, i + 15));
		}
		return data;
	};

	const printData = () => {
		const splitData = splitter();
		return (
			<TableContainer>
				{splitData?.map((data, index) => {
					return (
						<div className={classes?.pageBreak} key={index}>
							<Table sx={{ minWidth: 700 }} key={index} aria-label="spanning table">
								<TableHead className={classes?.tableHead}>
									<TableRow>
										<TableCell align="center">Order ID</TableCell>
										<TableCell align="center">Order Date</TableCell>
										<TableCell align="center">Parish Code</TableCell>
										<TableCell align="center">Parish Name</TableCell>
										<TableCell align="center">Material</TableCell>
										<TableCell align="center">English Quantity</TableCell>
										<TableCell align="center">French Quantity</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data?.map((row: any, index: number) => (
										<TableRow key={index} className={classes?.tableRowWrap}>
											<TableCell align="center">{row?.id || ''}</TableCell>
											<TableCell align="center">
												{row?.receivedDate ? convertDate(row?.receivedDate) : ''}
											</TableCell>
											<TableCell align="center">{row?.code || ''}</TableCell>
											<TableCell align="center">{row?.name || ''}</TableCell>
											<TableCell align="center">{row?.materialCode || ''}</TableCell>
											<TableCell align="center">{row?.enQty}</TableCell>
											<TableCell align="center">{row?.frQty}</TableCell>
										</TableRow>
									))}
									{index === splitData?.length - 1 && (
										<TableRow className={classes?.tableTotal}>
											<TableCell align="center" colSpan={3}>
												<Stack
													py={1}
													sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
												>
													<Stack
														direction="row"
														justifyContent={'center'}
														sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
													>
														Total Number of Orders
													</Stack>
												</Stack>
											</TableCell>
											<TableCell align="center">
												<Stack
													py={1}
													sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
												>
													<Stack
														direction="row"
														justifyContent={'center'}
														sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
													>
														{missionSundayReportData?.length}
													</Stack>
												</Stack>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</div>
					);
				})}
			</TableContainer>
		);
	};

	return (
		<div>
			<Report
				title="Mission Sunday Report"
				subTitle={`Order Received from ${convertDate(start)} To ${convertDate(end)}`}
				handlePrint={handleGetReportPdf}
			>
				<TableContainer>
					<Table sx={{ minWidth: 700 }} aria-label="spanning table">
						<TableHead className={classes?.tableHead}>
							<TableRow>
								<TableCell align="center">Order ID</TableCell>
								<TableCell align="center">Order Date</TableCell>
								<TableCell align="center">Parish Code</TableCell>
								<TableCell align="center">Parish Name</TableCell>
								<TableCell align="center">Material</TableCell>
								<TableCell align="center">English Quantity</TableCell>
								<TableCell align="center">French Quantity</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{missionSundayReportData?.map((row, index) => (
								<>
									<TableRow key={index} className={classes?.tableRowWrap}>
										<TableCell align="center">{row?.id || 'N/A'}</TableCell>
										<TableCell align="center">{convertDate(row?.receivedDate)}</TableCell>
										<TableCell align="center">{row?.parish?.parishCode || 'N/A'}</TableCell>
										<TableCell align="center">{row?.parish?.parishName || 'N/A'}</TableCell>
										<TableCell align="center">{row?.materials[0]?.material?.itemCode}</TableCell>
										<TableCell align="center">{row?.materials[0]?.enQuantity}</TableCell>
										<TableCell align="center">{row?.materials[0]?.frQuantity}</TableCell>
									</TableRow>
									{row?.materials?.length > 1 &&
										row?.materials?.slice(1, row?.materials?.length)?.map((item) => (
											<TableRow key={index} className={classes?.tableRowWrap}>
												<TableCell align="center"></TableCell>
												<TableCell align="center"></TableCell>
												<TableCell align="center"></TableCell>
												<TableCell align="center"></TableCell>
												<TableCell align="center">{item?.material?.itemCode || 'N/A'}</TableCell>
												<TableCell align="center">{item?.enQuantity}</TableCell>
												<TableCell align="center">{item?.frQuantity}</TableCell>
											</TableRow>
										))}
								</>
							))}
							<TableRow className={classes?.tableTotal}>
								<TableCell colSpan={2} align="center">
									<Stack py={1} sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}>
										<Stack
											direction="row"
											justifyContent={'center'}
											sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
										>
											Total Number of Orders
										</Stack>
									</Stack>
								</TableCell>
								<TableCell align="center">
									<Stack py={1} sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}>
										<Stack
											direction="row"
											justifyContent={'center'}
											sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
										>
											{missionSundayReportData?.length}
										</Stack>
									</Stack>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Report>
			<div style={{ display: 'none' }}>
				<div style={{ padding: 16 }} ref={componentRef}>
					<Stack sx={{ my: 2, px: 1 }}>
						<>
							<Stack width="100%" direction="row">
								<Stack flex={0.5} alignItems="flex-start" direction="column">
									<img src={Logo} alt="logo" width={40} height={50} />
								</Stack>

								<Stack sx={{ mb: 2, textAlign: 'center' }} alignItems="center" direction="column">
									<Typography sx={{ color: 'var(--mainColor)', fontSize: 20, fontWeight: 700 }}>
										Mission Sunday Report
									</Typography>
									<Typography sx={{ color: 'var(--fontColorHeaderSubTitle)', fontSize: 18, fontWeight: 400 }}>
										{`Order Received from ${convertDate(start)} To ${convertDate(end)}`}
									</Typography>
								</Stack>
							</Stack>
							<Stack
								direction={'row'}
								justifyContent="flex-end"
								mb={3}
								sx={{ color: 'var(--fontColorHeaderSubTitle)', fontSize: 16, fontWeight: 400 }}
							>
								{currentDate()}
							</Stack>
						</>

						<Box width="100%">{printData()}</Box>
					</Stack>
				</div>
			</div>
		</div>
	);
};

export default MissionSundayReportView;
