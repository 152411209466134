import React, { FC } from 'react';
import { Paper, Stack, Typography } from '@mui/material';
import { convertDate } from 'helpers/dayAndTime';
import { Batch, SocietyContributions } from './Batch';
import { Deposits } from 'contexts/intialstates/Types';
import { ResponsiveStyleValue } from '@mui/system';

interface BatchHeaderProp {
	data: Batch;
	deposits?: Deposits[];
}

const spacing: ResponsiveStyleValue<any> = {
	md: 5,
	lg: 10,
};

const BatchHeader: FC<BatchHeaderProp> = ({ data, deposits }) => {
	return (
		<>
			{
				<Paper sx={{ background: 'var(--highlightColor)', mb: 4 }}>
					<Stack
						direction={{ xs: 'column', sm: 'column', md: 'row' }}
						spacing={{ xs: 2 }}
						justifyContent="space-between"
						py={2}
						px={2}
					>
						<Stack justifyContent="space-between" spacing={spacing} direction="row">
							<Stack direction="column">
								<Typography sx={{ fontSize: 14, fontWeight: 600, color: '#fff' }}>Batch Number </Typography>
								<Typography sx={{ fontSize: 12, color: 'white' }}>{data && data?.batchNumber}</Typography>
							</Stack>
							<Stack direction="column">
								<Typography sx={{ fontSize: 14, fontWeight: 600, color: '#fff' }}>Status </Typography>
								<Typography sx={{ fontSize: 12, color: 'white' }}>{data && data?.batchStatus}</Typography>
							</Stack>
						</Stack>

						<Stack justifyContent="space-between" spacing={spacing} direction="row">
							<Stack direction="column">
								<Typography sx={{ fontSize: 14, fontWeight: 600, color: '#fff' }}>Total Amount </Typography>
								<Typography sx={{ fontSize: 12, color: 'white' }}>{data && data?.totalAmount}</Typography>
							</Stack>
							<Stack direction="column">
								<Typography sx={{ fontSize: 14, fontWeight: 600, color: '#fff' }}>Type </Typography>
								<Typography sx={{ fontSize: 12, color: 'white' }}>{data && data?.type}</Typography>
							</Stack>
						</Stack>

						<Stack justifyContent="space-between" spacing={spacing} direction="row">
							<Stack direction="column">
								<Typography sx={{ fontSize: 14, fontWeight: 600, color: '#fff' }}>Updated </Typography>
								<Typography sx={{ fontSize: 12, color: 'white' }}>
									{convertDate(data ? data?.created : '')}
								</Typography>
							</Stack>
							<Stack direction="column">
								<Typography sx={{ fontSize: 14, fontWeight: 600, color: '#fff' }}>Update By </Typography>
								<Typography sx={{ fontSize: 12, color: 'white' }}>{data && data?.createdBy?.name}</Typography>
							</Stack>
						</Stack>

						<Stack justifyContent="space-between" spacing={spacing} direction="row">
							{data &&
								data?.societyContributions.map((ele: SocietyContributions) => {
									return (
										<Stack direction="column" key={ele?.societyId}>
											<Typography sx={{ fontSize: 14, fontWeight: 600, color: '#fff' }}>
												{ele?.code}{' '}
											</Typography>
											<Typography sx={{ fontSize: 12, color: 'white' }}>{ele?.contributionTotal}</Typography>
										</Stack>
									);
								})}
						</Stack>
					</Stack>
				</Paper>
			}
		</>
	);
};

export default BatchHeader;
