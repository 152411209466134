import {
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
	Autocomplete,
	TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import React, { useState } from 'react';
import { MiniCard } from '../Card';
import { nonCashdoantionGroups } from '../mock';
import { useStyles } from '../report.style';
import useHCAMaterialsActions from 'hooks/useHCAMaterialsActions';
import { GlobalContexts } from 'contexts/GlobalContext';
import {
	INonCashDonationsReport,
	StateType,
	DonationTypes,
	HCAMaterials,
	Society,
} from 'contexts/intialstates/Types';
import { Close, TaskAlt } from '@mui/icons-material';
import useReportActions from 'hooks/useReportAction';
import SummarizedReport from './SummarizedReport';
import DetailedReport from './DetailedReport';
import { FilterProps } from '../Configure';
import { ContentSkeleton } from '../DonationsSummary';
import useDonationTypesActions from 'hooks/useDonationTypesActions';
import useSocietyActions from 'hooks/useSocietyAction';
import useGiftTypeActions, { GiftType } from 'hooks/useGiftTypeActions';
import useDebounce from 'Components/Reusable/hooks/debounce';

export interface NonCashDonationsReportPayload {
	start: any;
	end: any;
	groupby: string;
	category: string;
	isdetailed: boolean;
	gifttypeids?: string;
	societyids?: string;
	donationtypeids?: string;
}
export interface FormData {
	start: any;
	end: any;
	category: string;
	groupby: string;
	isdetailed: boolean;
	gifttypeids?: string[];
	societyids?: string[];
	donationType?: {
		id: number;
		label: string;
	}[];
}
interface Props {
	nonCashDonationsReport: StateType<INonCashDonationsReport[]>;
	setNonCashDonationReport: React.Dispatch<React.SetStateAction<StateType<INonCashDonationsReport[]>>>;
	filterContols: FilterProps;
	setFilterControls: React.Dispatch<React.SetStateAction<FilterProps>>;
}
const NonCashDonationsReport = ({
	nonCashDonationsReport,
	setNonCashDonationReport,
	filterContols,
	setFilterControls,
}: Props) => {
	const classes = useStyles();
	const { setSimpleErrorSnack, simpleErrorSnack } = React.useContext(GlobalContexts);

	const { getGiftTypes } = useGiftTypeActions();
	const { getDonationTypeBySearch } = useDonationTypesActions();
	const { getNonCashDonationsReport, getNonCashDonationsPdf } = useReportActions();
	const { getAllHCAMaterials } = useHCAMaterialsActions();
	const { getSocieties } = useSocietyActions();

	const [hcaMaterials, setHcaMaterials] = React.useState<StateType<HCAMaterials[]>>({
		data: null,
		loading: false,
		error: null,
	});

	const [output, setOutput] = React.useState<any>();
	const [loading, setLoading] = React.useState(true);
	const [giftTypes, setGiftTypes] = useState<StateType<GiftType>>({
		data: null,
		loading: false,
		error: null,
	});
	const [formData, setFormData] = React.useState<FormData>({
		start: '',
		end: '',
		groupby: '',
		category: '',
		isdetailed: false,
		gifttypeids: [],
		societyids: [],
		donationType: [],
	});
	const [allSociety, setAllSoceity] = React.useState<StateType<Society[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [donationTypeSearchTerm, setDonationTypeSearchTerm] = useState('');
	const debouncedDonationSearchTerm = useDebounce(donationTypeSearchTerm, 1000);
	const [donationType, setDonationType] = useState<StateType<DonationTypes[]>>({
		data: null,
		loading: false,
		error: null,
	});

	React.useEffect(() => {
		if (!debouncedDonationSearchTerm) return;
		getDonationTypeBySearch({ donationType, setDonationType, searchTerm: debouncedDonationSearchTerm });
	}, [debouncedDonationSearchTerm]);

	React.useEffect(() => {
		getAllHCAMaterials({ hcaMaterials, setHcaMaterials });
		getSocieties({ allSociety, setAllSoceity });
		getGiftTypes({ giftTypes, setGiftTypes, page: 0, pageSize: 10000 });
	}, []);

	React.useEffect(() => {
		if (allSociety?.loading) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [allSociety?.loading]);

	const handleSubmit = () => {
		if (!formData) return;

		if (!formData.groupby) {
			setSimpleErrorSnack({
				message: 'Group report by is required',
				show: true,
				severity: 'error',
			});
			return;
		}
		if (!formData.start) {
			setSimpleErrorSnack({
				message: 'Start date is required',
				show: true,
				severity: 'error',
			});
			return;
		}
		if (!formData.end) {
			setSimpleErrorSnack({
				message: 'End date is required',
				show: true,
				severity: 'error',
			});
			return;
		}
		getNonCashDonationsReport({
			nonCashDonationsReport,
			setNonCashDonationReport,
			payload: {
				...formData,
				start: `${formData.start.getFullYear()}-${formData.start.getMonth() + 1}-${formData.start.getDate()}`,
				end: `${formData.end.getFullYear()}-${formData.end.getMonth() + 1}-${formData.end.getDate()}`,
				gifttypeids: formData.gifttypeids?.toString(),
				donationtypeids: formData?.donationType?.map((item) => item.id).join(','),
				societyids: formData.societyids?.toString(),
			},
		});
	};

	const handlePdfDownload = async () => {
		const payload = {
			...formData,
			start: `${formData.start.getFullYear()}-${formData.start.getMonth() + 1}-${formData.start.getDate()}`,
			end: `${formData.end.getFullYear()}-${formData.end.getMonth() + 1}-${formData.end.getDate()}`,
			gifttypeids: formData.gifttypeids?.toString(),
			donationtypeids: formData?.donationType?.map((item) => item.id).join(','),
			societyids: formData.societyids?.toString(),
		};

		await getNonCashDonationsPdf(payload);
	};

	React.useEffect(() => {
		const reportOutput = () => {
			if (nonCashDonationsReport.loading) {
				setSimpleErrorSnack({
					message: 'processing...',
					severity: 'info',
					show: true,
				});
				return;
			}
			if (nonCashDonationsReport.data?.length === 0) {
				setSimpleErrorSnack({
					message: 'No non-cash donation found',
					severity: 'info',
					show: true,
				});
				return;
			}

			if (!formData.isdetailed && nonCashDonationsReport.data) {
				setOutput(
					<SummarizedReport
						handlePdfDownload={handlePdfDownload}
						data={nonCashDonationsReport.data}
						formData={formData}
					/>,
				);
				setSimpleErrorSnack({
					...simpleErrorSnack,
					show: false,
				});
				return;
			}
			if (formData.isdetailed && nonCashDonationsReport.data) {
				setOutput(
					<DetailedReport
						handlePdfDownload={handlePdfDownload}
						data={nonCashDonationsReport.data}
						formData={formData}
					/>,
				);
				setSimpleErrorSnack({
					...simpleErrorSnack,
					show: false,
				});
				return;
			}
		};
		reportOutput();
	}, [nonCashDonationsReport]);

	const handleChange = (event: any, current: any) => {
		const {
			target: { value },
		} = event;
		setFormData({ ...formData, [current]: typeof value === 'string' ? value.split(',') : value });
	};

	const grouping = (value: string) => {
		return value.split(',');
	};

	const handleDoanationSelectChange = (val: any) => {
		setFormData({
			...formData,
			donationType: val,
		});
	};

	return (
		<>
			{loading ? (
				<>
					{filterContols.configure && (
						<Box mb={2}>
							<MiniCard title="Configure">
								<ContentSkeleton length={3} />
							</MiniCard>
						</Box>
					)}
					{filterContols.filter && (
						<Box mb={2}>
							<MiniCard title="Filters">
								<ContentSkeleton length={3} />
							</MiniCard>
						</Box>
					)}
				</>
			) : (
				<>
					<>
						{filterContols.configure && (
							<Box mb={2}>
								<MiniCard title="Configure">
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack direction="column" spacing={2}>
												<Box className={classes.formLabelText}>Group Report by *</Box>
												<Select
													fullWidth
													size={'small'}
													value={`${formData?.groupby},${formData?.category}`}
													onChange={(e) =>
														setFormData({
															...formData,
															groupby: grouping(e.target.value)[0],
															category: grouping(e.target.value)[1],
														})
													}
												>
													{nonCashdoantionGroups.map((x) => (
														<MenuItem key={x.id} value={x.id}>
															{x.label}
														</MenuItem>
													))}
												</Select>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack direction="column" spacing={2}>
												<Box className={classes.formLabelText}>Start Date *</Box>
												<Box>
													<BasicDatePicker
														size="small"
														value={formData?.start || null}
														setValue={(e) =>
															setFormData({
																...formData,
																start: e.target.value,
															})
														}
													/>
												</Box>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack direction="column" spacing={2}>
												<Box className={classes.formLabelText}>End Date *</Box>
												<Box>
													<BasicDatePicker
														size="small"
														value={formData?.end || null}
														setValue={(e) =>
															setFormData({
																...formData,
																end: e.target.value,
															})
														}
													/>
												</Box>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<FormControl sx={{ '& .css-ahj2mt-MuiTypography-root': { fontSize: 14 } }}>
												<RadioGroup
													aria-labelledby="demo-controlled-radio-buttons-group"
													name="controlled-radio-buttons-group"
													value={formData?.isdetailed}
													onChange={(e) => {
														setFormData({ ...formData, isdetailed: e.target.value === 'true' });
													}}
												>
													<FormControlLabel value={false} control={<Radio />} label="Summary Report" />
													<FormControlLabel value={true} control={<Radio />} label="Detailed Report" />
												</RadioGroup>
											</FormControl>
										</Grid>
										{filterContols.editConfigure && (
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Stack direction="row" mt={3} spacing={1} alignItems="center">
													<Button
														onClick={handleSubmit}
														sx={{ textTransform: 'none', height: 40 }}
														startIcon={<TaskAlt />}
														variant="contained"
													>
														Apply
													</Button>
													<Button
														onClick={() => setFilterControls({ ...filterContols, configure: false })}
														sx={{ textTransform: 'none', height: 40 }}
														variant="outlined"
														startIcon={<Close />}
													>
														Cancel
													</Button>
												</Stack>
											</Grid>
										)}
									</Grid>
								</MiniCard>
							</Box>
						)}

						{filterContols.filter && (
							<Box mb={2}>
								<MiniCard title="Filters">
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack className={classes.fieldGridItem} direction="column" spacing={2}>
												<Box className={classes.formLabelText}>Gift Type</Box>
												<FormControl>
													<Select
														fullWidth
														size="small"
														multiple
														value={formData?.gifttypeids}
														onChange={(e) => handleChange(e, 'gifttypeids')}
													>
														{giftTypes.data?.body.map((type) => (
															<MenuItem key={type.id} value={type.id}>
																{type.type}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack className={classes.fieldGridItem} direction="column" spacing={2}>
												<Box className={classes.formLabelText}>Society</Box>
												<FormControl>
													<Select
														fullWidth
														size="small"
														multiple
														value={formData?.societyids}
														onChange={(e) => handleChange(e, 'societyids')}
													>
														{allSociety?.data
															?.filter((curr) => curr.code !== 'AN')
															?.map((name: any) => (
																<MenuItem key={name.id} value={name.id}>
																	{name.code}
																</MenuItem>
															))}
													</Select>
												</FormControl>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack className={classes.fieldGridItem} direction="column" spacing={2}>
												<Box className={classes.formLabelText}>Donation Types</Box>
												<Autocomplete
													freeSolo
													value={formData.donationType}
													loading={donationType.loading}
													loadingText="loading..."
													onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
														handleDoanationSelectChange(val);
													}}
													fullWidth
													multiple
													options={(donationType?.data || []).map((donation: DonationTypes) => ({
														id: donation.id,
														label: `${donation.type} - ${donation.description}`,
													}))}
													renderOption={(props, option) => (
														<Box component="li" {...props} key={option.id}>
															{option.label}
														</Box>
													)}
													renderInput={(params) => (
														<TextField
															{...params}
															variant="outlined"
															label=""
															InputProps={{
																...params.InputProps,
															}}
															onChange={(event: any) => {
																setDonationTypeSearchTerm(event.target.value);
															}}
															size={'small'}
															placeholder={'Search donation type'}
														/>
													)}
												/>
											</Stack>
										</Grid>
										{filterContols.editFilter && (
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Stack direction="row" alignItems="center" mt={3} spacing={1}>
													<Button
														onClick={handleSubmit}
														sx={{ textTransform: 'none', height: 40 }}
														startIcon={<TaskAlt />}
														variant="contained"
													>
														Apply
													</Button>
													<Button
														onClick={() => setFilterControls({ ...filterContols, filter: false })}
														sx={{ textTransform: 'none', height: 40 }}
														startIcon={<Close />}
														variant="outlined"
													>
														Cancel
													</Button>
												</Stack>
											</Grid>
										)}
									</Grid>
								</MiniCard>
							</Box>
						)}

						{!nonCashDonationsReport.data && (
							<Stack direction="row" ml={5} mt={2}>
								<Button
									onClick={handleSubmit}
									sx={{ textTransform: 'none', height: 40 }}
									startIcon={<TaskAlt />}
									variant="contained"
								>
									Generate
								</Button>
							</Stack>
						)}
					</>

					{output}
				</>
			)}
		</>
	);
};

export default NonCashDonationsReport;
