import React from 'react';
import { Modal, Paper, Stack, Typography, Box, TextField, Grid, Button } from '@mui/material';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useGlobalStyles } from 'Components/Common/global/global';
import { useStyles } from '../../../Walkthon/Walkathon.style';
import { Search } from '@mui/icons-material';

export interface Field {
	key: string;
	control: string;
	label: string;
	icon: React.ReactElement<SvgIconProps>;
	value: string;
}

interface P {
	field: Field[];
	setField: React.Dispatch<React.SetStateAction<Field[]>>;
	title: string;
	visible: boolean;
	handleClose: () => void;
	children: any;
	handleSearch: () => void;
}

const style = {
	position: 'absolute',
	top: '1%',
	left: '10%',
	width: '80vw',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	borderRadius: 2,
	maxHeight: '95vh',
	overflow: 'auto',
};

const AdvancedSearchModal = ({
	field,
	setField,
	title,
	visible,
	handleClose,
	children,
	handleSearch,
}: P) => {
	const global = useGlobalStyles();
	const classes = useStyles();

	const handleOnChange = (key: string, value: string) => {
		const fieldIndex = field.findIndex((data) => data.key === key);
		const fieldData = [...field];
		fieldData[fieldIndex] = {
			...fieldData[fieldIndex],
			value,
		};
		setField(fieldData);
	};

	return (
		<Modal open={visible} onClose={handleClose} disablePortal>
			<Paper sx={style}>
				<Stack direction="row" justifyContent="space-between">
					<Typography sx={{ fontWeight: 700, fontSize: 23, color: '#1976D2' }}>{title}</Typography>
				</Stack>
				<Box sx={{ mt: 1.5 }}>
					<Grid container columnSpacing={10} alignItems="center">
						{field.map((data) => (
							<Grid item mb={2} key={data.key}>
								<Stack direction="row" mb={1}>
									<div className={classes.searchFieldIcon}>{data.icon}</div>
									<Typography variant="body2" className={global.formLabelText}>
										{data.label}
									</Typography>
								</Stack>
								{data.control === 'text' && (
									<TextField
										sx={{ minWidth: 250 }}
										label=""
										variant="outlined"
										size="small"
										// value={formData?.name}
										onChange={(e) => handleOnChange(data.key, e.target.value)}
									/>
								)}
							</Grid>
						))}
						<Grid item mb={2}>
							<Button startIcon={<Search />} size={'small'} variant="outlined" onClick={handleSearch}>
								Search
							</Button>
						</Grid>
					</Grid>
				</Box>
				{children}
			</Paper>
		</Modal>
	);
};

export default AdvancedSearchModal;
