import React, { useEffect, useRef, useState } from 'react';
import { Receipt } from '@mui/icons-material';
import { Stack, Table, Box, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import FormatCurrency, { formatNumberFunc } from 'helpers/FormatCurrency';
import useReportActions, { DeathBenefitReport } from 'hooks/useReportAction';
import { StateType } from 'contexts/intialstates/Types';
import Logo from './../../../assets/images/logo.png';
import { useReactToPrint } from 'react-to-print';
import { useStyles } from './style';
import { LoadingButton } from '@mui/lab';
import { convertDate } from 'helpers/dayAndTime';

interface Props {
	deathBenefit: StateType<DeathBenefitReport>;
	setDeathBenefit: React.Dispatch<React.SetStateAction<StateType<DeathBenefitReport>>>;
	id: number;
	disabled: boolean;
}

const DeathBenefit = ({ deathBenefit, setDeathBenefit, id, disabled }: Props) => {
	const { getDeathBenefitReport } = useReportActions();
	const [reprint, setReprint] = useState(false);
	const classes = useStyles();
	const componentRef = useRef(null);

	const splitter = () => {
		if (!deathBenefit.data?.aggreements.length) return;
		const newDeathBenefit: any = [];
		const data = [];
		deathBenefit.data?.aggreements?.map((row) => {
			newDeathBenefit.push({
				agreementNumber: row.agreementNumber || '-',
				types: (
					<span>
						Annuitant
						<br />
						Co-Annuitant
					</span>
				),
				donorId: row.donorId || '-',
				sin: (
					<span>
						{row?.annuitantSIN || '-'}
						<br />
						{row?.coAnnuitantSIN || '-'}
					</span>
				),
				name: (
					<span>
						{row?.annuitantName || '-'}
						<br />
						{row?.coAnnuitantName || '-'}
					</span>
				),
				dod: (
					<span>
						{row?.annuitantDateOfDeath ? convertDate(row?.annuitantDateOfDeath) : '-'}
						<br />
						{row?.coAnnuitantDateOfDeath ? convertDate(row?.coAnnuitantDateOfDeath) : '-'}
					</span>
				),
				oldStatus: row.oldStatus || '-',
				newStatus: row.newStatus || '-',
				dbAmount: row.dbAmount ? <FormatCurrency value={row.dbAmount} /> : '-',
			});
		});
		for (let i = 0; i < newDeathBenefit.length; i += 5) {
			data.push(newDeathBenefit.slice(i, i + 5));
		}
		return data;
	};

	const printData = () => {
		const splitData = splitter();
		return (
			<TableContainer>
				{splitData?.map((data, index) => {
					return (
						<div className={classes.pageBreak} key={index}>
							<Table sx={{ minWidth: 700 }} key={index} aria-label="spanning table">
								<TableHead className={classes.tableHead}>
									<TableRow>
										<TableCell align="center">Agreement #</TableCell>
										<TableCell align="center"></TableCell>
										<TableCell align="center">Donor Id</TableCell>
										<TableCell align="center">SIN </TableCell>
										<TableCell align="center">Name</TableCell>
										<TableCell align="center">DOD</TableCell>
										<TableCell align="center">Old Status</TableCell>
										<TableCell align="center">New Status</TableCell>
										<TableCell align="center">DB Amount</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map((row: any, index: number) => (
										<>
											{
												<TableRow key={index} className={classes.tableRowWrap}>
													<TableCell align="center">{row?.agreementNumber || '-'}</TableCell>
													<TableCell align="center">{row?.types || '-'}</TableCell>
													<TableCell align="center">{row?.donorId || '-'}</TableCell>
													<TableCell align="center">{row?.sin || '-'}</TableCell>
													<TableCell align="center">{row?.name || '-'}</TableCell>
													<TableCell align="center">{row?.dod || '-'}</TableCell>
													<TableCell align="center">{row?.oldStatus || '-'}</TableCell>
													<TableCell align="center">{row?.newStatus || '-'}</TableCell>
													<TableCell align="center">{row?.dbAmount || '-'}</TableCell>
												</TableRow>
											}
										</>
									))}
									{index === splitData.length - 1 && (
										<TableRow className={classes.tableTotal}>
											<TableCell colSpan={10}>
												<Stack sx={{ fontSize: 19, fontWeight: 700 }} direction="row" width="100%">
													<Stack width="40%">
														Total Count:{' '}
														{deathBenefit.data?.aggreements.length
															? formatNumberFunc(deathBenefit.data?.aggreements.length)
															: '-'}
													</Stack>
													<Stack width="60%">
														<Stack
															width="100%"
															direction="row"
															mb={0.5}
															py={1}
															sx={{
																px: 2,
															}}
														>
															<Stack direction="row" width={'100%'}>
																{deathBenefit.data?.aggreements.length ? (
																	<Stack width={'100%'} direction="row" justifyContent="flex-end" spacing={4}>
																		<span>Grand Total</span>{' '}
																		<span>
																			<FormatCurrency
																				value={deathBenefit.data?.aggreements.reduce(
																					(total, curr) => total + curr.dbAmount,
																					0,
																				)}
																			/>
																		</span>
																	</Stack>
																) : (
																	'-'
																)}
															</Stack>
														</Stack>
													</Stack>
												</Stack>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</div>
					);
				})}
			</TableContainer>
		);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	useEffect(() => {
		if (!deathBenefit.data?.aggreements.length) return;
		handlePrint();
	}, [deathBenefit.data?.aggreements.length, reprint]);

	const getReport = async () => {
		if (!id) return;
		if (!deathBenefit.data?.aggreements.length) {
			await getDeathBenefitReport({ id, deathBenefit, setDeathBenefit });
		}
		setReprint(!reprint);
	};

	return (
		<>
			<LoadingButton
				loading={deathBenefit.loading}
				loadingPosition="start"
				variant="outlined"
				size="small"
				startIcon={<Receipt />}
				disabled={disabled}
				onClick={getReport}
			>
				Get Death Benefit Report
			</LoadingButton>
			<div style={{ display: 'none' }}>
				<div style={{ padding: 16 }} ref={componentRef}>
					<Stack sx={{ mt: 2, px: 1 }}>
						<>
							<Stack width="100%" direction="row">
								<Stack flex={0.5} alignItems="flex-start" direction="column">
									<img src={Logo} alt="logo" width={40} height={50} />
								</Stack>
							</Stack>
							<Stack sx={{ fontWeight: 600, fontSize: 25, mt: 3, textTransform: 'uppercase' }}>
								Propagation of the Faith
							</Stack>
							<Stack direction="row" justifyContent="center" sx={{ fontWeight: 500, fontSize: 23, mt: 2 }}>
								Death Benefit Report
							</Stack>
							<Stack sx={{ fontWeight: 600, fontSize: 20 }}>
								Annuitant: {deathBenefit.data?.annuitant || '-'}{' '}
							</Stack>
							<Stack sx={{ fontWeight: 600, fontSize: 20 }}>
								DOD: {deathBenefit.data?.dateOfDeath ? convertDate(deathBenefit.data?.dateOfDeath) : '-'}
							</Stack>
						</>

						<Box width="100%">{printData()}</Box>
					</Stack>
				</div>
			</div>
		</>
	);
};

export default DeathBenefit;
