import React, { useState } from 'react';
import { Stack, Button, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import AnnuitiesTable from './AnnuitiesTable';
import useUserActions from 'hooks/useUserActions';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';

export const annuitiesHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'agreementNumber',
		label: 'Agreement ID',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'annuitantId',
		label: 'Donor ID',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'annuitant',
		label: 'Annuitant',
		minWidth: 220,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'receivedAmount',
		label: 'Received Amount',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'agreementType',
		label: 'Agreement Type',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'Joint' }, { name: 'Single' }],
	},
	{
		id: 'status',
		label: 'Status',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'Annuitant' }, { name: 'Co-Annuitant' }, { name: 'Expired' }, { name: 'Revoked' }],
	},
	{
		id: 'state',
		label: 'State',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'Draft' }, { name: 'Posted' }],
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
];

const Annuities = () => {
	const navigate = useNavigate();
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const { handleRoles } = useUserActions();
	const [reload, setReload] = useState(false);
	const [filterPayload, setFilterPayload] = React.useState<Array<any>>([]);
	const [page, setPage] = useState(0);

	return (
		<Stack>
			<Stack direction={'row'} justifyContent={'space-between'} mb={3}>
				<Stack direction={'column'}>
					<Typography variant="h5" sx={{ color: '#1976D2' }}>
						Annuities
					</Typography>
					<Stack>Create and manage Annuities here</Stack>
				</Stack>
				<Stack direction={'row'} alignItems={'center'}>
					<TrimTables
						name={'annuitiesHead'}
						list={annuitiesHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
					{handleRoles('annuities-actions') && (
						<Button onClick={() => navigate('create')} size={'small'} startIcon={<Add />} variant="contained">
							Add Agreement
						</Button>
					)}
					<FilterListButton
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
						reload={reload}
						setIsReload={setReload}
						setPage={setPage}
						page={page}
					/>
				</Stack>
			</Stack>
			<AnnuitiesTable
				trimList={trimList}
				setTrimList={setTrimList}
				reload={reload}
				setReload={setReload}
				setPage={setPage}
				page={page}
				setFilterPayload={setFilterPayload}
				filterPayload={filterPayload}
			/>
		</Stack>
	);
};

export default Annuities;
