/* eslint-disable eol-last */
export const REGISTER_LOADING = 'REGISTER_LOADING';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';

// Donors
// create donor
export const CREATE_DONOR_LOAD = 'CREATE_DONOR_LOAD';
export const CREATE_DONOR_SUCCESS = 'CREATE_DONOR_SUCCESS';
export const CREATE_DONOR_ERROR = 'CREATE_DONOR_ERROR';

export const SEARCH_DONORS = 'SEARCH_DONORS';

// delete donor
export const DELETE_DONOR_LOADING = 'DELETE_DONOR_LOADING';
export const DELETE_DONOR_SUCCESS = 'DELETE_DONOR_SUCCESS';
export const DELETE_DONOR_ERROR = 'DELETE_DONOR_ERROR';

// update donor
export const UPDATE_DONOR_LOADING = 'UPDATE_DONOR_LOADING';
export const UPDATE_DONOR_SUCCESS = 'UPDATE_DONOR_SUCCESS';
export const UPDATE_DONOR_ERROR = 'UPDATE_DONOR_ERROR';

// donors loading
export const DONORS_LOADING = 'DONORS_LOADING';
export const DONORS_LOAD_SUCCESS = 'DONORS_LOAD_SUCCESS';
export const DONORS_LOAD_ERROR = 'DONORS_LOAD_ERROR';
export const DONOR_LOADING = 'DONOR_LOADING';
export const DONOR_LOAD_SUCCESS = 'DONOR_LOAD_SUCCESS';
export const DONOR_LOAD_ERROR = 'DONOR_LOAD_ERROR';

// create deposit
export const CREATE_DEPOSIT_LOAD = 'CREATE_DEPOSIT_LOAD';
export const CREATE_DEPOSIT_SUCCESS = 'CREATE_DEPOSIT_SUCCESS';
export const CREATE_DEPOSIT_ERROR = 'CREATE_DEPOSIT_ERROR';

export const SEARCH_DEPOSITS = 'SEARCH_DEPOSITS';

// delete deposit
export const DELETE_DEPOSIT_LOADING = 'DELETE_DEPOSIT_LOADING';
export const DELETE_DEPOSIT_SUCCESS = 'DELETE_DEPOSIT_SUCCESS';
export const DELETE_DEPOSIT_ERROR = 'DELETE_DEPOSIT_ERROR';

// update deposit
export const UPDATE_DEPOSIT_LOADING = 'UPDATE_DEPOSIT_LOADING';
export const UPDATE_DEPOSIT_SUCCESS = 'UPDATE_DEPOSIT_SUCCESS';
export const UPDATE_DEPOSIT_ERROR = 'UPDATE_DEPOSIT_ERROR';

// deposit loading
export const DEPOSITS_LOADING = 'DEPOSITS_LOADING';
export const DEPOSITS_LOAD_SUCCESS = 'DEPOSITS_LOAD_SUCCESS';
export const DEPOSITS_LOAD_ERROR = 'DEPOSITS_LOAD_ERROR';
export const DEPOSIT_LOADING = 'DEPOSIT_LOADING';
export const DEPOSIT_LOAD_SUCCESS = 'DEPOSIT_LOAD_SUCCESS';
export const DEPOSIT_LOAD_ERROR = 'DEPOSIT_LOAD_ERROR';

// template deposits
export const TEMPLATE_DEPOSITS_LOADING = 'TEMPLATE_DEPOSITS_LOADING';
export const TEMPLATE_DEPOSITS_LOAD_SUCCESS = 'TEMPLATE_DEPOSITS_LOAD_SUCCESS';
export const TEMPLATE_DEPOSITS_LOAD_ERROR = 'TEMPLATE_DEPOSITS_LOAD_ERROR';

// template deposit
export const TEMPLATE_DEPOSIT_LOADING = 'TEMPLATE_DEPOSIT_LOADING';
export const TEMPLATE_DEPOSIT_LOAD_SUCCESS = 'TEMPLATE_DEPOSIT_LOAD_SUCCESS';
export const TEMPLATE_DEPOSIT_LOAD_ERROR = 'TEMPLATE_DEPOSIT_LOAD_ERROR';

// delete template deposit
export const DELETE_TEMPLATE_DEPOSIT_LOADING = 'DELETE_TEMPLATE_DEPOSIT_LOADING';
export const DELETE_TEMPLATE_DEPOSITS_LOAD_SUCCESS = 'DELETE_TEMPLATE_DEPOSITS_LOAD_SUCCESS';
export const DELETE_TEMPLATE_DEPOSITS_LOAD_ERROR = 'DELETE_TEMPLATE_DEPOSITS_LOAD_ERROR';

// update template deposit
export const UPDATE_TEMPLATE_DEPOSIT_LOADING = 'UPDATE_TEMPLATE_DEPOSIT_LOADING';
export const UPDATE_TEMPLATE_DEPOSITS_LOAD_SUCCESS = 'UPDATE_TEMPLATE_DEPOSITS_LOAD_SUCCESS';
export const UPDATE_TEMPLATE_DEPOSITS_LOAD_ERROR = 'UPDATE_TEMPLATE_DEPOSITS_LOAD_ERROR';

// create donation
export const CREATE_DONATION_LOAD = 'CREATE_DONATION_LOAD';
export const CREATE_DONATION_SUCCESS = 'CREATE_DONATION_SUCCESS';
export const CREATE_DONATION_ERROR = 'CREATE_DONATION_ERROR';

export const SEARCH_DONATIONS = 'SEARCH_DONATIONS';

// delete donation
export const DELETE_DONATION_LOADING = 'DELETE_DONATION_LOADING';
export const DELETE_DONATION_SUCCESS = 'DELETE_DONATION_SUCCESS';
export const DELETE_DONATION_ERROR = 'DELETE_DONATION_ERROR';

// update donation
export const UPDATE_DONATION_LOADING = 'UPDATE_DONATION_LOADING';
export const UPDATE_DONATION_SUCCESS = 'UPDATE_DONATION_SUCCESS';
export const UPDATE_DONATION_ERROR = 'UPDATE_DONATION_ERROR';

// donation loading
export const DONATIONS_LOADING = 'DONATIONS_LOADING';
export const DONATIONS_LOAD_SUCCESS = 'DONATIONS_LOAD_SUCCESS';
export const DONATIONS_LOAD_ERROR = 'DONATIONS_LOAD_ERROR';
export const DONATION_LOADING = 'DONATION_LOADING';
export const DONATION_LOAD_SUCCESS = 'DONATION_LOAD_SUCCESS';
export const DONATION_LOAD_ERROR = 'DONATION_LOAD_ERROR';

// donations
// List donations of a donor
export const GET_DONATION_BY_DONOR_LOADING = 'GET_DONATION_BY_DONOR_LOADING';
export const GET_DONATION_BY_DONOR_LOAD_SUCCESS = 'GET_DONATION_BY_DONOR_LOAD_SUCCESS';
export const GET_DONATION_BY_DONOR_ERROR = 'GET_DONATION_BY_DONOR_ERROR';

// donations
// List donations of a donor
export const GET_ALL_BATCH_LOADING = 'GET_ALL_BATCH_LOADING';
export const GET_ALL_BATCH_LOAD_SUCCESS = 'GET_ALL_BATCH_LOAD_SUCCESS';
export const GET_ALL_BATCH_ERROR = 'GET_ALL_BATCH_ERROR';
export const GET_SINGLE_BATCH_LOADING = 'GET_SINGLE_BATCH_LOADING';
export const GET_SINGLE_BATCH_LOAD_SUCCESS = 'GET_SINGLE_BATCH_LOAD_SUCCESS';
export const GET_SINGLE_BATCH_ERROR = 'GET_SINGLE_BATCH_ERROR';

// Donation types

export const GET_ALL_DONATION_TYPES_LOADING = 'GET_ALL_DONATION_TYPES_LOADING';
export const GET_ALL_DONATION_TYPES_LOAD_SUCCESS = 'GET_ALL_DONATION_TYPES_LOAD_SUCCESS';
export const GET_ALL_DONATION_TYPES_ERROR = 'GET_ALL_DONATION_TYPES_ERROR';
export const GET_SINGLE_DONATION_TYPES_LOADING = 'GET_SINGLE_DONATION_TYPES_LOADING';
export const GET_SINGLE_DONATION_TYPES_LOAD_SUCCESS = 'GET_SINGLE_DONATION_TYPES_LOAD_SUCCESS';
export const GET_SINGLE_DONATION_TYPES_ERROR = 'GET_SINGLE_DONATION_TYPES_ERROR';

// Batch
export const BATCHES_LOADING = 'BATCHES_LOADING';
export const BATCHES_LOAD_SUCCESS = 'BATCHES_LOAD_SUCCESS';
export const BATCHES_LOAD_ERROR = 'BATCHES_LOAD_ERROR';

//delete batch
export const DELETE_BATCH_LOADING = 'DELETE_BATCH_LOADING';
export const DELETE_BATCH_SUCCESS = 'DELETE_BATCH_SUCCESS';
export const DELETE_BATCH_ERROR = 'DELETE_BATCH_ERROR';

//upload Batch
export const BATCH_UPLOADING_LOADING = 'BATCH_UPLOADING_LOADING';
export const BATCH_UPLOADING_LOAD_SUCCESS = 'BATCH_UPLOADING_LOAD_SUCCESS';
export const BATCH_UPLOADING_LOAD_ERROR = 'BATCH_UPLOADING_LOAD_ERROR';

// Payment
export const PAYMENTMETHODS_LOADING = 'PAYMENTMETHODS_LOADING';
export const PAYMENTMETHODS_LOAD_SUCCESS = 'PAYMENTMETHODS_LOAD_SUCCESS';
export const PAYMENTMETHOD_LOADING = 'PAYMENTMETHOD_LOADING';
export const PAYMENTMETHOD_LOAD_SUCCESS = 'PAYMENTMETHOD_LOAD_SUCCESS';
export const PAYMENTMETHOD_LOAD_ERROR = 'PAYMENTMETHOD_LOAD_ERROR';
export const PAYMENTMETHODS_LOAD_ERROR = 'PAYMENTMETHODS_LOAD_ERROR';

// Parish
export const PARISHES_LOADING = 'PARISHES_LOADING';
export const PARISHES_LOAD_SUCCESS = 'PARISHES_LOAD_SUCCESS';
export const PARISH_LOADING = 'PARISH_LOADING';
export const PARISH_LOAD_SUCCESS = 'PARISH_LOAD_SUCCESS';
export const PARISH_LOAD_ERROR = 'PARISH_LOAD_ERROR';
export const PARISHES_LOAD_ERROR = 'PARISHES_LOAD_ERROR';

// Society
export const SOCIETIES_LOADING = 'SOCIETIES_LOADING';
export const SOCIETIES_LOAD_SUCCESS = 'SOCIETIES_LOAD_SUCCESS';
export const SOCIETIES_LOAD_ERROR = 'SOCIETIES_LOAD_ERROR';
export const DELETE_SOCIETY_LOADING = 'DELETE_SOCIETY_LOADING,';
export const DELETE_SOCIETY_SUCCESS = 'DELETE_SOCIETY_SUCCESS';
export const SOCIETY_LOAD_SUCCESS = 'SOCIETY_LOAD_SUCCESS';
export const SOCIETY_LOAD_ERROR = 'SOCIETY_LOAD_ERROR';
export const CREATE_SOCIETY_ERROR = 'CREATE_SOCIETY_ERROR';
export const CREATE_SOCIETY_LOAD = 'CREATE_SOCIETY_LOAD';
export const CREATE_SOCIETY_SUCCESS = 'CREATE_SOCIETY_SUCCESS';
export const UPDATE_SOCIETY_ERROR = 'UPDATE_SOCIETY_ERROR';
export const UPDATE_SOCIETY_SUCCESS = 'UPDATE_SOCIETY_SUCCESS';
export const SOCIETY_LOADING = 'SOCIETY_LOADING';

// perform actions
export const SET_ACTION = 'SET_ACTION';

// void type
export const VOID_TYPE_LOADING = 'VOID_TYPE_LOADING';
export const VOID_TYPE_LOAD_SUCCESS = 'VOID_TYPE_LOAD_SUCCESS';
export const VOID_TYPE_LOAD_ERROR = 'VOID_TYPE_LOAD_ERROR';
export const DELETE_VOID_TYPE_LOADING = 'DELETE_VOID_TYPE_LOADING,';

// appeal types
export const APPEAL_LOADING = 'APPEAL_LOADING';
export const APPEAL_LOAD_SUCCESS = 'APPEAL_LOAD_SUCCESS';
export const APPEAL_LOAD_ERROR = 'APPEAL_LOAD_ERROR';
export const DELETE_APPEAL_LOADING = 'DELETE_APPEAL_LOADING,';

// item status
export const ITEM_STATUS_LOADING = 'ITEM_STATUS_LOADING';
export const ITEM_STATUS_LOAD_SUCCESS = 'ITEM_STATUS_LOAD_SUCCESS';
export const ITEM_STATUS_LOAD_ERROR = 'ITEM_STATUS_LOAD_ERROR';
