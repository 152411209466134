import { TDonorEdge } from './DonorInfo/DonorCheckBoxes';
import { Donor } from '../../contexts/intialstates/Types';
import { DonorFormValues } from './CreateEditDonorModal';

type TDonor = Donor | DonorFormValues | undefined

const getDonorEdges = (donorInformation: TDonor)  => {

	const appealsEdges: TDonorEdge[] = [
		{
			label: 'Propagation of faith',
			key: 'faithPropagation',
			value: donorInformation?.faithPropagation,
		},
		{
			label: 'Holy childhood',
			key: 'holyChildhood',
			value: donorInformation?.holyChildhood,
		},
		{
			label: 'Saint Peter',
			key: 'stPeter',
			value: donorInformation?.stPeter,
		},
	];
	const communicationsEdges = [
		{
			label: "Don't send emails",
			key: 'noMail',
			value: donorInformation?.noMail,
		},
		{
			label: 'Exclude from parish list',
			key: 'excludeFromParishList',
			value: donorInformation?.excludeFromParishList,
		},
		{
			label: 'Returned mail',
			key: 'returnMail',
			value: donorInformation?.returnMail,
		},
		{
			label: 'Deceased',
			key: 'deceased',
			value: donorInformation?.deceased,
		},
	];
	const familyEdges = [
		{
			label: 'Principal',
			key: 'principal',
			value: donorInformation?.principal,
		},
		{
			label: 'Member',
			key: 'member',
			value: donorInformation?.member,
		},
	];
	const specialEdges = [
		{
			label: 'PAP',
			key: 'pap',
			value: donorInformation?.pap,
		},
		{
			label: 'Annuity',
			key: 'annuity',
			value: donorInformation?.annuity,
		},
		{
			label: 'Estate',
			key: 'estate',
			value: donorInformation?.estate,
		},
	];

	return {
		appealsEdges,
		communicationsEdges,
		familyEdges,
		specialEdges,
	};
};

export { getDonorEdges };
