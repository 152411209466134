import React from 'react';
import { Stack } from '@mui/material';
import { useStyles } from '../../Annuities.style';
import { StateType } from 'contexts/intialstates/Types';
import { Agreement } from 'hooks/useAnnuitiesActions';
import { convertDate } from 'helpers/dayAndTime';

interface P {
	agreement: StateType<Agreement>;
}

const AnnuitiesHeader = ({ agreement }: P) => {
	const classes = useStyles();
	const data = agreement.data;

	const viewData = [
		{
			title: 'Frequency',
			subTitle: data?.paymentFrequency?.frequency ?? '-',
		},
		{
			title: 'First Payment Date',
			subTitle: data?.firstPaymentDate ? convertDate(data?.firstPaymentDate) : '-',
		},
		{
			title: 'Annuitant Date of Death',
			subTitle: data?.annuitant?.dateOfDeath ? convertDate(data?.annuitant?.dateOfDeath) : '-',
		},
		{
			title: 'Co-Annuitant Date of Death',
			subTitle: data?.coAnnuitant?.dateOfDeath ? convertDate(data?.coAnnuitant?.dateOfDeath) : '-',
		},
		{
			title: 'Last Payment Date',
			subTitle: data?.lastPaymentDate ? convertDate(data?.lastPaymentDate) : '-',
		},
		{
			title: 'Overpayment Amount',
			subTitle: data?.overPaymentAmount ? data.overPaymentAmount : '-',
		},
	];

	return (
		<Stack flexDirection="row" justifyContent={'space-between'} width="100%" className={classes.headerWrap}>
			{viewData.map((item, index: number) => (
				<Stack
					alignItems="center"
					className={index !== viewData.length - 1 ? classes.headerContent : ''}
					width="100%"
					key={index}
					px={2}
				>
					<Stack className={classes.titleHeader}>{item.title}</Stack>
					<Stack className={classes.subTitleHeader}>{item.subTitle}</Stack>
				</Stack>
			))}
		</Stack>
	);
};

export default AnnuitiesHeader;
