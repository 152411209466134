import React, { useEffect } from 'react';
import { Box } from '@mui/system';
import { Button, Paper, Stack, Grid, Typography, Divider, TextField } from '@mui/material';
import { Numbers, Search, Home } from '@mui/icons-material';
import { leftFieldStack } from 'Components/AdminPanel/Diocese/CreateEditDiocese/CreateEditDioceseForm';
import { useGlobalStyles } from 'Components/Common/global/global';
import useDioceseActions from 'hooks/useDioceseActions';
import {
	StateType,
	SchoolData,
	SchoolBoardData,
	DioceseData,
	ParishData,
	OrderType,
} from 'contexts/intialstates/Types';
import Modal from '@mui/material/Modal';
import useSchoolActions from 'hooks/useSchoolActions';
import useSchoolBoardActions from 'hooks/useSchoolBoardActions';
import useParishesActions from 'hooks/useParishesActions';
import SchoolTable from 'Components/AdminPanel/School/SchoolTable';
import SchoolBoardTable from 'Components/AdminPanel/SchoolBoard/SchoolBoardTable';
import DioceseTable from 'Components/AdminPanel/Diocese/DioceseTable';
import ParishesTable from 'Components/AdminPanel/Parishes/ParishesTable';
import {
	ColumnType,
	dioceseHead,
	parishHead,
	schoolBoardHead,
	schoolHead,
} from 'Components/Common/Tables/TableHeaders';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';

export interface DioceseValues {
	dioceseName: string;
	chancellor: string;
	streetLine1: string;
	streetline2: string;
	city: string;
	province: string;
	postalCode: string;
	contactName: string;
	phone: string;
	fax: string;
	email: string;
	language: string;
	useEmail: boolean;
	useEFile: boolean;
	magazine: boolean;
	remark: string;
}

const style = {
	position: 'absolute',
	top: '1%',
	left: '10%',
	width: '80vw',
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	borderRadius: 2,
	maxHeight: '95vh',
	overflow: 'auto',
};

const OrdersModal = ({ open, setOpen }: any) => {
	const handleClose = () => {
		setFilterPayload([]);
		setOpen({ ...open, state: false, type: '' });
	};

	const global = useGlobalStyles();
	const { getDioceses } = useDioceseActions();
	const { getSchools } = useSchoolActions();
	const { getSchoolBoards } = useSchoolBoardActions();
	const { getParishes } = useParishesActions();

	const [schools, setSchools] = React.useState<StateType<SchoolData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [schoolBoards, setSchoolBoards] = React.useState<StateType<SchoolBoardData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [dioceses, setDioceses, ,] = React.useState<StateType<DioceseData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [parishes, setParishes] = React.useState<StateType<ParishData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [filterPayload, setFilterPayload] = React.useState<any>([]);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [reload, setReload] = React.useState(false);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);

	useEffect(() => {
		if (open.type === 'School') {
			getSchools({
				archive: '&Filters=field=archived;value=false',
				schools,
				setSchools,
				page,
				pageSize,
				orderBy,
				filterPayload,
			});
			return;
		}
		if (open.type === 'Board') {
			getSchoolBoards({
				archive: '&Filters=field=archived;value=false',
				schoolBoards,
				setSchoolBoards,
				page,
				pageSize,
				orderBy,
				filterPayload,
			});
			return;
		}
		if (open.type === 'Diocese') {
			getDioceses({
				setDioceses,
				dioceses,
				pageSize,
				page,
				orderBy,
				filterPayload,
				archive: '&Filters=field=archived;value=false',
			});
			return;
		}
		if (open.type === 'Parish') {
			getParishes({
				archive: '&Filters=field=archived;value=false',
				parishes,
				setParishes,
				page,
				pageSize,
				orderBy,
				filterPayload,
			});
			return;
		}
	}, [open.type, page, pageSize, orderBy, reload, filterPayload.length]);

	const table = (tableType: string) => {
		if (tableType === 'School') {
			return (
				<SchoolTable
					trimList={trimList}
					setTrimList={setTrimList}
					page={page}
					pageSize={pageSize}
					setPage={setPage}
					setPageSize={setPageSize}
					label={label}
					orderType={orderType}
					setLabel={setLabel}
					setOderBy={setOderBy}
					setOrderType={setOrderType}
					schools={schools}
					hcaOrgList
					open={open}
					setOpen={setOpen}
				/>
			);
		}
		if (tableType === 'Board') {
			return (
				<SchoolBoardTable
					trimList={trimList}
					setTrimList={setTrimList}
					page={page}
					pageSize={pageSize}
					setPage={setPage}
					setPageSize={setPageSize}
					label={label}
					orderType={orderType}
					setLabel={setLabel}
					setOderBy={setOderBy}
					setOrderType={setOrderType}
					schoolBoards={schoolBoards}
					hcaOrgList
					open={open}
					setOpen={setOpen}
				/>
			);
		}
		if (tableType === 'Parish') {
			return (
				<ParishesTable
					trimList={trimList}
					setTrimList={setTrimList}
					page={page}
					pageSize={pageSize}
					setPage={setPage}
					setPageSize={setPageSize}
					label={label}
					orderType={orderType}
					setLabel={setLabel}
					setOderBy={setOderBy}
					setOrderType={setOrderType}
					parishes={parishes}
					hcaOrgList
					open={open}
					setOpen={setOpen}
				/>
			);
		}
		if (tableType === 'Diocese') {
			return (
				<DioceseTable
					trimList={trimList}
					setTrimList={setTrimList}
					page={page}
					pageSize={pageSize}
					setPage={setPage}
					setPageSize={setPageSize}
					label={label}
					orderType={orderType}
					setLabel={setLabel}
					setOderBy={setOderBy}
					setOrderType={setOrderType}
					dioceses={dioceses}
					hcaOrgList
					open={open}
					setOpen={setOpen}
				/>
			);
		}
	};

	const handleFilter = (key: any) => (e: any) => {
		const newPayload = filterPayload;

		const arrIndex = newPayload.findIndex((ele: any) => {
			return key === ele.field;
		});

		if (arrIndex > -1) {
			if (key === 'phone' || key === 'code') {
				if (e.target.value === '') {
					newPayload[arrIndex] = { ...newPayload[arrIndex], value: null };
					return;
				}
				newPayload[arrIndex] = { ...newPayload[arrIndex], value: [e.target.value] };
				return;
			}
			if (e.target.value === '') {
				newPayload[arrIndex] = { ...newPayload[arrIndex], query: null };
			}
			newPayload[arrIndex] = { ...newPayload[arrIndex], query: e.target.value };
		} else {
			if (key === 'phone' || key === 'code') {
				newPayload.push({
					field: key,
					query: null,
					value: [e.target.value],
					from: null,
					to: null,
				});
				return;
			}
			newPayload.push({
				field: key,
				query: e.target.value,
				value: null,
				from: null,
				to: null,
			});
		}

		setFilterPayload(newPayload);
	};

	const filterName = (type: string) => {
		if (type === 'Parish') return 'parishName';
		if (type === 'Diocese') return 'dioceseName';
		return 'name';
	};
	const filterChief = (type: string) => {
		if (type === 'Parish') return 'pastor';
		if (type === 'Diocese') return 'chancellor';
		if (type === 'School') return 'principal';
		if (type === 'Board') return 'director';
	};
	const filterHead = (type: string) => {
		if (type === 'Parish') return 'parishHead';
		if (type === 'Diocese') return 'dioceseHead';
		if (type === 'School') return 'schoolHead';
		if (type === 'Board') return 'schoolBoardHead';
	};
	const filterComponent = (type: string) => {
		if (type === 'Parish') return parishHead;
		if (type === 'Diocese') return dioceseHead;
		if (type === 'School') return schoolHead;
		if (type === 'Board') return schoolBoardHead;
	};

	return (
		<div>
			<Modal
				open={open.state}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				disablePortal
			>
				<Paper sx={style}>
					<>
						<Stack direction="row" justifyContent="space-between">
							<Typography sx={{ fontWeight: 700, fontSize: 23, color: '#1976D2' }}>
								Select an Organization
							</Typography>
							<TrimTables
								name={filterHead(open.type) as string}
								list={filterComponent(open.type) as any}
								trimList={trimList}
								setTrimList={setTrimList}
							/>
						</Stack>

						<Box>
							<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2}>
								<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
									<Stack>
										<Grid container>
											<Grid item>
												<Home className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography
													variant="body2"
													sx={{ fontWeight: 600, fontSize: 15, color: 'var(--formLabelColor)' }}
												>
													Organization Name
												</Typography>
											</Grid>
										</Grid>

										<TextField
											label=""
											size="small"
											variant="outlined"
											onChange={handleFilter(filterName(open.type))}
											placeholder="Organization Name"
										/>
									</Stack>
								</Grid>
								<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
									<Stack>
										<Grid container>
											<Grid item>
												<Home className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography
													variant="body2"
													sx={{ fontWeight: 600, fontSize: 15, color: 'var(--formLabelColor)' }}
												>
													City
												</Typography>
											</Grid>
										</Grid>

										<TextField
											size="small"
											variant="outlined"
											onChange={handleFilter('city')}
											placeholder="City"
										/>
									</Stack>
								</Grid>
								<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
									<Stack>
										<Grid container>
											<Grid item>
												<Numbers className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography
													variant="body2"
													sx={{ fontWeight: 600, fontSize: 15, color: 'var(--formLabelColor)' }}
												>
													Postal Code
												</Typography>
											</Grid>
										</Grid>

										<TextField
											size="small"
											variant="outlined"
											onChange={handleFilter('postalCode')}
											placeholder="Postal Code"
										/>
									</Stack>
								</Grid>
								<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
									<Stack>
										<Grid container>
											<Grid item>
												<Numbers className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography
													variant="body2"
													sx={{ fontWeight: 600, fontSize: 15, color: 'var(--formLabelColor)' }}
												>
													Phone
												</Typography>
											</Grid>
										</Grid>
										<TextField
											size="small"
											variant="outlined"
											onChange={handleFilter('phone')}
											placeholder="Phone"
										/>
									</Stack>
								</Grid>
								<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
									<Stack>
										<Grid container>
											<Grid item>
												<Numbers className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography
													variant="body2"
													sx={{
														fontWeight: 600,
														fontSize: 15,
														color: 'var(--formLabelColor)',
														textTransform: 'capitalize',
													}}
												>
													{filterChief(open.type)}
												</Typography>
											</Grid>
										</Grid>

										<TextField
											size="small"
											variant="outlined"
											onChange={handleFilter(filterChief(open.type))}
											placeholder={filterChief(open.type)}
										/>
									</Stack>
								</Grid>
								{open.type === 'Diocese' && (
									<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
										<Stack>
											<Grid container>
												<Grid item>
													<Numbers className={global.formLabelIcon} />
												</Grid>
												<Grid item>
													<Typography
														variant="body2"
														sx={{
															fontWeight: 600,
															fontSize: 15,
															color: 'var(--formLabelColor)',
															textTransform: 'capitalize',
														}}
													>
														Code
													</Typography>
												</Grid>
											</Grid>

											<TextField
												size="small"
												type="number"
												variant="outlined"
												onChange={handleFilter('code')}
												placeholder={'Code'}
											/>
										</Stack>
									</Grid>
								)}
								<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
									<Stack direction="column" height="100%" justifyContent="center" alignItems="flex-start">
										<Button
											sx={{
												textTransform: 'capitalize',
												background: '#fff',
												color: '#1976D2',
												py: 0.5,
											}}
											variant="outlined"
											startIcon={<Search />}
											onClick={() => setReload(!reload)}
										>
											Search
										</Button>
									</Stack>
								</Grid>
							</Grid>
						</Box>

						<Box>
							<Typography sx={{ fontSize: { xs: 13, sm: 14 }, fontWeight: 600, mt: 2 }}>
								Order Materials
							</Typography>
							<Divider />
							{table(open.type)}
						</Box>
					</>
				</Paper>
			</Modal>
		</div>
	);
};
export default OrdersModal;
