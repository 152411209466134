import React, { useEffect, useState } from 'react';
import { Stack, Button, Typography, Paper, Grid, Box, Tooltip } from '@mui/material';
import { Add } from '@mui/icons-material';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import AnnuitiesTable from './List';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import useAnnuitiesActions, { IPostingPeriods } from 'hooks/useAnnuitiesActions';
import { FilterPayload, StateType } from 'contexts/intialstates/Types';
import { convertDate } from 'helpers/dayAndTime';
import useUserActions from 'hooks/useUserActions';
import { useNavigate } from 'react-router-dom';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import { PaymentListReport } from 'hooks/useReportAction';
export const annuitiesHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'postingNumber',
		label: 'Posting',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'postingPeriod',
		label: 'Posting Period',
		minWidth: 220,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'postingDate',
		label: 'Posting Date',
		minWidth: 220,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
];

const MonthlyPosting = () => {
	const [page, setPage] = useState(0);

	const navigate = useNavigate();
	const { handleRoles } = useUserActions();
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [annuitiesPostingPeriods, setAnnuitiesPostingPeriods] = useState<StateType<IPostingPeriods>>({
		loading: false,
		error: null,
		data: null,
	});
	const [paymentList, setPaymentList] = useState<StateType<PaymentListReport[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const { getPostingPeriods, annuitiesPosting } = useAnnuitiesActions();
	const [reload, setReload] = useState(false);
	useEffect(() => {
		getPostingPeriods({ annuitiesPostingPeriods, setAnnuitiesPostingPeriods, type: 'monthly' });
	}, [reload]);

	const handlePosting = async () => {
		setLoading(true);
		const response = await annuitiesPosting('monthly');
		if (response?.postingLog.id) {
			navigate(`${response?.postingLog.id}`);
		}
		setLoading(false);
		setOpen(false);
	};
	return (
		<Stack>
			<Stack direction={'row'} justifyContent={'space-between'} mb={3}>
				<Stack direction={'column'}>
					<Typography variant="h5" sx={{ color: '#1976D2' }}>
						Monthly Posting
					</Typography>
					<Stack>Create and view monthly posting here</Stack>
				</Stack>
				<Stack direction={'row'} alignItems={'center'}>
					<TrimTables
						name={'annuitiesMonthlyHead'}
						list={annuitiesHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
					{handleRoles('annuities-actions') && (
						<Stack direction="row" spacing={2}>
							<Tooltip title={''}>
								<span>
									<Button
										onClick={() => setOpen(true)}
										size={'small'}
										startIcon={<Add />}
										variant="contained"
									>
										Add Posting
									</Button>
								</span>
							</Tooltip>
							{/* <PaymentList paymentList={paymentList} setPaymentList={setPaymentList} /> */}
						</Stack>
					)}
					<FilterListButton
						reload={reload}
						setIsReload={setReload}
						setPage={setPage}
						page={page}
						filterPayload={filterPayload}
						setFilterPayload={setFilterPayload}
					/>
				</Stack>
			</Stack>
			<Paper sx={{ px: 3, mb: 3 }}>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
					<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
						<Stack direction="column" spacing={2}>
							<Box>Last Posting Date</Box>
							<Box>
								<BasicDatePicker size="small" value={annuitiesPostingPeriods.data?.last || null} disabled />
							</Box>
						</Stack>
					</Grid>
					<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
						<Stack direction="column" spacing={2}>
							<Box>Current Posting Date</Box>
							<Box>
								<BasicDatePicker
									size="small"
									value={annuitiesPostingPeriods.data?.current || null}
									disabled
								/>
							</Box>
						</Stack>
					</Grid>
				</Grid>
			</Paper>
			<AnnuitiesTable
				filterPayload={filterPayload}
				setFilterPayload={setFilterPayload}
				trimList={trimList}
				setTrimList={setTrimList}
				reload={reload}
				setReload={setReload}
				setPage={setPage}
				page={page}
			/>
			<ConfirmationModal
				handleClose={() => setOpen(false)}
				message={`Are you sure you want to create a new posting for ${convertDate(
					annuitiesPostingPeriods.data?.current,
				)}?`}
				open={open}
				handleConfirmation={handlePosting}
				handleCancel={() => setOpen(false)}
				loading={loading}
				title="Add New Posting"
			/>
		</Stack>
	);
};

export default MonthlyPosting;
