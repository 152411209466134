import React, { useEffect, useState } from 'react';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import {
	Stack,
	Button,
	Chip,
	Grid,
	Typography,
	TextField,
	Paper,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Table,
	Skeleton,
} from '@mui/material';
import { Archive, ChangeHistory } from '@mui/icons-material';
import { useStyles } from './BurseChalice.style';
import { useGlobalStyles } from 'Components/Common/global/global';
import FormatCurrency from 'helpers/FormatCurrency';
import useBurseChaliceActions, { BurseChaliceById } from 'hooks/useBurseChaliceActions';
import { StateType } from 'contexts/intialstates/Types';
import { convertDate } from 'helpers/dayAndTime';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import ActionModal from 'Components/Reusable/Modals/ActionModal/ActionModal';
import useUserActions from 'hooks/useUserActions';

interface P {
	visible: boolean;
	handleClose: () => void;
	id: number;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	reload: boolean;
}

const EditBurseChalice = ({ visible, handleClose, id, setReload, reload }: P) => {
	const { handleRoles } = useUserActions();
	const classes = useStyles();
	const global = useGlobalStyles();
	const navigate = useNavigate();
	const { getBurseChaliceById, deleteBurseChalice, updateBurseChaliceState } = useBurseChaliceActions();
	const [burseChalice, setBurseChalice] = useState<StateType<BurseChaliceById>>({
		data: null,
		loading: false,
		error: null,
	});
	const [isDelete, setIsDelete] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [stopLoading, setStopLoading] = useState(false);
	const [isConfirmVisible, setConfirmVisible] = useState(false);

	useEffect(() => {
		if (!id) return;
		getBurseChaliceById({ burseChalice, setBurseChalice, id });
	}, [id]);

	const getTargetAmount = (): number => {
		let value = 0;
		if (burseChalice?.data) {
			const { targetAmount, contributionAmount } = burseChalice?.data;
			if (targetAmount) value = targetAmount - contributionAmount;
			else if (contributionAmount) value = contributionAmount;
		}
		return value;
	};

	const getContributionAmount = (): number => {
		let value = 0;
		if (burseChalice?.data) {
			const { contributionAmount } = burseChalice?.data;
			if (contributionAmount) value = contributionAmount;
		}
		return value;
	};

	const handleToggleDelete = () => {
		setIsDelete(!isDelete);
	};

	const handleDelete = async () => {
		setDeleteLoading(true);
		const isSucess = await deleteBurseChalice(id);
		setDeleteLoading(false);
		handleToggleDelete();
		if (isSucess) {
			setReload(!reload);
			handleClose();
		}
	};

	const handleStopRestart = async () => {
		setStopLoading(true);
		const isSucess = await updateBurseChaliceState(id, burseChalice?.data?.status !== 'Stopped');
		setStopLoading(false);
		if (isSucess) {
			setReload(!reload);
			handleClose();
		}
	};

	const handleClickStopRestart = () => {
		if (getTargetAmount() === 0 && burseChalice?.data?.status === 'Completed') {
			setConfirmVisible(true);
		} else {
			handleStopRestart();
		}
	};

	const handleRowClick = (depositId: number) => {
		navigate(`/bookkeeping/deposits/${depositId}/donor/${id}`);
	};

	const handleConfirm = async () => {
		await handleStopRestart();
		setConfirmVisible(false);
	};

	return (
		<BasicModal onClose={handleClose} visible={visible} title={''} maxWidth={'md'}>
			<Stack direction="row" justifyContent="space-between">
				<Stack direction="row" spacing={1} alignItems="center">
					<span className={classes.title}>Bruse/Chalice Details</span>
					<Chip
						label={`Contribution: $${getContributionAmount()}`}
						color="primary"
						variant="outlined"
						size="small"
					/>
					<Chip label={`Balance: $${getTargetAmount()}`} color="primary" variant="outlined" size="small" />
				</Stack>
				{!burseChalice?.loading && handleRoles('donor-bookkeepings') && (
					<Stack direction="row" spacing={1}>
						<Button
							startIcon={<Archive />}
							disabled={getContributionAmount() !== 0}
							variant="contained"
							size="small"
							onClick={() => handleToggleDelete()}
						>
							Archive
						</Button>
						<LoadingButton
							startIcon={<ChangeHistory />}
							loading={stopLoading}
							loadingPosition="start"
							variant="outlined"
							size="small"
							onClick={handleClickStopRestart}
							disabled={burseChalice?.data?.stopped}
						>
							{burseChalice?.data?.stopped
								? 'Restart'
								: burseChalice?.data?.status !== 'Stopped'
								? 'Stop'
								: 'Restart'}
						</LoadingButton>
					</Stack>
				)}
			</Stack>
			<Stack p={2}>
				<Stack>
					{!burseChalice?.loading ? (
						<Grid container rowSpacing={1} mt={4} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
							<Grid item xs={12} md={4} sm={6} mb={2}>
								<Stack>
									<Typography variant="body2" className={global.formLabelText}>
										Contribution Type
									</Typography>
									<TextField
										value={burseChalice?.data?.contributionType}
										disabled
										size="small"
										className={classes.disabledInput}
									/>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4} mb={2} sm={6}>
								<Stack>
									<Typography variant="body2" className={global.formLabelText}>
										Target Amount
									</Typography>
									<TextField
										value={burseChalice?.data?.targetAmount}
										disabled
										size="small"
										className={classes.disabledInput}
									/>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4} mb={2} sm={6}>
								<Stack>
									<Typography variant="body2" className={global.formLabelText}>
										Donation Type
									</Typography>
									<TextField
										value={burseChalice?.data?.donationType?.description}
										disabled
										size="small"
										className={classes.disabledInput}
									/>
								</Stack>
							</Grid>
						</Grid>
					) : (
						<Grid container rowSpacing={1} mt={4} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
							{[...Array.from({ length: 3 })].map((i, index) => (
								<Grid item xs={12} md={4} sm={6} mb={2} key={index}>
									<Skeleton height={70} />
								</Grid>
							))}
						</Grid>
					)}
				</Stack>
				<Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '20px' }}>
					<TableContainer sx={{ padding: '10px' }}>
						<Table sx={{ minWidth: 700 }} aria-label="spanning table">
							<TableHead className={classes.tableHead}>
								<TableRow>
									<TableCell align="center">S/N</TableCell>
									<TableCell align="center">Receipt Number</TableCell>
									<TableCell align="center">Amount</TableCell>
									<TableCell align="center">Society</TableCell>
									<TableCell align="center">Date Issued</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{burseChalice?.loading ? (
									<>
										{[...Array.from({ length: 3 })].map((i, index) => (
											<TableRow key={index} className={classes.tableRowWrap}>
												{[...Array.from({ length: 5 })].map((i, index) => (
													<TableCell align="center" key={index}>
														<Skeleton height={50} />
													</TableCell>
												))}
											</TableRow>
										))}
									</>
								) : (
									burseChalice?.data?.burseChaliceDonationMappings?.map((row, index) => (
										<TableRow
											key={index}
											className={classes.tableRowWrap}
											onClick={() => handleRowClick(row.donation.depositId)}
										>
											<TableCell align="center">{index + 1}</TableCell>
											<TableCell align="center">{row.donation?.receiptNum || 'N/A'}</TableCell>
											<TableCell align="center">
												<FormatCurrency value={row.amount || 'N/A'} />
											</TableCell>
											<TableCell align="center">{row.donation?.society?.code || 'N/A'}</TableCell>
											<TableCell align="center">
												{row.donation?.issueDate ? convertDate(row.donation.issueDate) : 'N/A'}
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Stack>
			{isDelete && (
				<ConfirmationModal
					handleClose={handleToggleDelete}
					message={'Are you sure you want to delete Burse/Chalice ?'}
					open={isDelete}
					handleConfirmation={handleDelete}
					handleCancel={handleToggleDelete}
					loading={deleteLoading}
				/>
			)}
			{isConfirmVisible && (
				<ActionModal
					open={isConfirmVisible}
					title={'Are you sure you want to stop is Burse/Chalice goal?'}
					subTitle={
						'Please note, once you stop a completed Burse/chalice goal, you will not get a option to restart it for duplication.'
					}
					submitText={'Confirm'}
					handleSubmit={handleConfirm}
					closeText={'Cancel'}
					handleClose={() => setConfirmVisible(false)}
					loading={stopLoading}
				/>
			)}
		</BasicModal>
	);
};

export default EditBurseChalice;
