import { alpha } from "@material-ui/core/styles";

export const PADDING = 64;

export const DARK_COLOR = alpha("#000000", 0.8);
export const PRIMARY_COLOR = "#499BFB";
export const LIGHT_GREY_COLOR = "#eaeaea";
export const LIGHTER_GREY_COLOR = "#fcfcfe";
export const LIGHTER_PRIMARY_COLOR = "#f5f7fb";
export const DARK_PRIMARY_COLOR = "#8C97AF";
export const SECONDARY_COLOR = "#22396b";
export const SUCCESS_COLOR = "#009006F8";
export const DANGER_COLOR = "#FF7E7E";

export const lg = "(min-width: 992px)";
export const md = "(min-width: 768px) AND (max-width: 992px)";
export const sm = "(min-width: 576px) AND (max-width: 768px)";
export const xs = "(max-width: 576px)";
