import React from 'react';
import {
	FilterPayload,
	ResponseError,
	StateType,
	TimeStamp,
	TimeStampInfoType,
	Parish,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';
import { MissionMaterial } from 'hooks/useMissionSundayMaterial';
import moment from 'moment';

export interface MissionOrderData {
	pageNumber: number;
	pageSize: number;
	totalPages: number;
	totalRecords: number;
	body: MissionOrder[];
}

export interface MissionOrder extends TimeStamp, TimeStampInfoType {
	id: number;
	year: number;
	receivedDate: string;
	parishId: number;
	parish: Parish;
}

export interface MissionOrderById extends TimeStamp, TimeStampInfoType {
	id: number;
	year: number;
	receivedDate: string;
	parishId: number;
	parish: Parish;
	materials: {
		missionSundayMaterialId: number;
		enQuantity: number;
		frQuantity: number;
		material: MissionMaterial;
	}[];
}

export interface GetMissionOrderTypes {
	setMissionOrder: React.Dispatch<React.SetStateAction<StateType<MissionOrderData>>>;
	missionOrder: StateType<MissionOrderData>;
	page: number;
	pageSize: number;
	filterPayload?: FilterPayload[];
	orderBy?: string;
}

export interface GetMissionOrderByIdTypes {
	setMissionOrderById: React.Dispatch<React.SetStateAction<StateType<MissionOrderById>>>;
	missionOrderById: StateType<MissionOrderById>;
	id: number;
}

export interface MaterialPayload {
	materialId: number;
	enQuantity: number;
	frQuantity: number;
}

export interface MissionOrderPayload {
	year: number;
	parishId: number;
	streetline1: string;
	streetline2: string;
	country: string;
	city: string;
	provinceId?: number;
	postalCode: string;
	receivedDate: string;
	materials: MaterialPayload[];
}

const useMissionSundayOrderActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const getMissionOrder = async ({
		setMissionOrder,
		missionOrder,
		page,
		pageSize,
		filterPayload,
		orderBy,
	}: GetMissionOrderTypes) => {
		try {
			setMissionOrder({
				...missionOrder,
				loading: true,
				error: null,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`missionsunday-order/list?&PageNumber=${page + 1}&PageSize=${pageSize}${filter}${
						orderBy || '&SortBy=receivedDate%20desc'
					}`,
				);
			} else {
				response = await api.get(
					`missionsunday-order/list?&PageSize=${pageSize}${filter}${
						orderBy || '&SortBy=receivedDate%20desc'
					}`,
				);
			}

			setMissionOrder({
				...missionOrder,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || missionOrder.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setMissionOrder({
					...missionOrder,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const getMissionOrderById = async ({
		setMissionOrderById,
		missionOrderById,
		id,
	}: GetMissionOrderByIdTypes) => {
		try {
			setMissionOrderById({
				...missionOrderById,
				loading: true,
				error: null,
			});
			const response = await api.get(`missionsunday-order/${id}`);
			setMissionOrderById({
				...missionOrderById,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setMissionOrderById({
					...missionOrderById,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const createMissionOrder = async (payload: MissionOrderPayload): Promise<boolean | number> => {
		try {
			const response = await api.post(`missionsunday-order/create`, {
				...payload,
			});
			if (response?.data?.result) {
				setSimpleErrorSnack({
					message: 'Mission sunday order created succesfully',
					severity: 'success',
					show: true,
				});
				return response?.data?.result.id;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const updateMissionOrder = async (
		payload: MissionOrderPayload,
		missionSundayOrderId: number,
	): Promise<boolean> => {
		try {
			const response = await api.put(`missionsunday-order/${missionSundayOrderId}/update`, {
				...payload,
			});
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Mission sunday order updated sucesssfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deleteMissionOrder = async (missionSundayOrderId: number): Promise<boolean> => {
		try {
			const response = await api.remove(`missionsunday-order/${missionSundayOrderId}/archive`);
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Mission sunday order deleted succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const exportLabels = async () => {
		try {
			const response = await api.get(`/missionsunday-order/export-labels`);
			const url = window.URL.createObjectURL(new Blob([response?.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Export_Labels_${moment().format('yyMM')}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSimpleErrorSnack({
					message: 'An error occurred',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	return {
		getMissionOrder,
		createMissionOrder,
		updateMissionOrder,
		deleteMissionOrder,
		getMissionOrderById,
		exportLabels,
	};
};

export default useMissionSundayOrderActions;
