import React, { useState, useEffect } from 'react';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import { Grid, Stack, Typography, Button, TextField } from '@mui/material';
import ProvinceAutocomplete from 'Components/Province';
import { useGlobalStyles } from 'Components/Common/global/global';
import { TaskAlt, Close } from '@mui/icons-material';

interface P {
	visible: boolean;
	handleClose: () => void;
	selectedData?: any;
	handleSubmit: (address: AddressValues) => void;
}

export interface AddressValues {
	streetline1: string;
	streetline2: string;
	country: string;
	city: string;
	province: string;
	provinceId?: string;
	postalCode: string;
}

const Address = ({ visible, handleClose, selectedData, handleSubmit }: P) => {
	const global = useGlobalStyles();
	const [address, setAddress] = useState<AddressValues>({
		streetline1: '',
		streetline2: '',
		country: '',
		city: '',
		province: '',
		postalCode: '',
	});

	useEffect(() => {
		if (selectedData) {
			const { streetline1, streetline2, country, city, province, provinceId, postalCode } = selectedData;
			setAddress({
				streetline1,
				streetline2,
				country,
				city,
				province,
				postalCode,
				provinceId,
			});
		}
	}, [selectedData]);

	const handleChange = (name: string, value: string, provinceName?: string) => {
		if (name === 'provinceId' && provinceName) {
			setAddress({
				...address,
				[name]: value,
				province: provinceName,
			});
		} else {
			setAddress({
				...address,
				[name]: value,
			});
		}
	};

	return (
		<BasicModal
			onClose={handleClose}
			visible={visible}
			title={'Edit Address'}
			data={selectedData || undefined}
		>
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
				<Grid item xs={12} md={6} mb={2}>
					<Grid container>
						<Typography variant="body2" className={global.formLabelText}>
							Street Line 1
						</Typography>
					</Grid>
					<TextField
						fullWidth
						size="small"
						variant="outlined"
						placeholder="Street Line 1"
						onChange={(e) => handleChange('streetline1', e.target.value)}
						value={address.streetline1}
					/>
				</Grid>
				<Grid item xs={12} md={6} mb={2}>
					<Grid container>
						<Typography variant="body2" className={global.formLabelText}>
							Street Line 2
						</Typography>
					</Grid>
					<TextField
						fullWidth
						size="small"
						variant="outlined"
						placeholder="Street Line 2"
						onChange={(e) => handleChange('streetline2', e.target.value)}
						value={address.streetline2}
					/>
				</Grid>
				<Grid item xs={12} md={6} mb={2}>
					<Grid container>
						<Typography variant="body2" className={global.formLabelText}>
							City
						</Typography>
					</Grid>
					<TextField
						fullWidth
						size="small"
						variant="outlined"
						placeholder="City"
						onChange={(e) => handleChange('city', e.target.value)}
						value={address.city}
					/>
				</Grid>
				<Grid item xs={12} md={6} mb={2}>
					<Grid container>
						<Typography variant="body2" className={global.formLabelText}>
							Province
						</Typography>
					</Grid>
					<ProvinceAutocomplete
						value={address.province}
						handleChange={(val) => handleChange('provinceId', val.id, val.label)}
					/>
				</Grid>
				<Grid item xs={12} md={6} mb={2}>
					<Grid container>
						<Typography variant="body2" className={global.formLabelText}>
							Postal Code
						</Typography>
					</Grid>
					<TextField
						fullWidth
						size="small"
						variant="outlined"
						placeholder="Postal Code"
						onChange={(e) => handleChange('postalCode', e.target.value)}
						value={address.postalCode}
					/>
				</Grid>
				<Grid item xs={12} md={6} mb={2}>
					<Grid container>
						<Typography variant="body2" className={global.formLabelText}>
							Country
						</Typography>
					</Grid>
					<TextField
						fullWidth
						size="small"
						variant="outlined"
						placeholder="Country"
						onChange={(e) => handleChange('country', e.target.value)}
						value={address.country}
					/>
				</Grid>
			</Grid>
			<Stack direction="row" my={3} justifyContent={'center'} spacing={2} alignItems="center">
				<Button
					color="primary"
					variant="contained"
					sx={{ width: 'fit-content', textTransform: 'capitalize' }}
					startIcon={<TaskAlt />}
					onClick={() => handleSubmit(address)}
				>
					Save
				</Button>
				<Button
					variant="outlined"
					sx={{ width: 'fit-content', textTransform: 'capitalize' }}
					onClick={handleClose}
					startIcon={<Close />}
				>
					Cancel
				</Button>
			</Stack>
		</BasicModal>
	);
};

export default Address;
