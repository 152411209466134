import React, { useContext, useState } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	Divider,
	Grid,
	Stack,
	TextField,
	Autocomplete,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Title } from '../CreateDeposit/CreateDeposit.style';
import { CreditCardDepositPayload } from '../CreateDeposit/Types';
import { useGlobalStyles } from 'Components/Common/global/global';
import { AccountBalance, ConfirmationNumber, PausePresentation } from '@mui/icons-material';
import { Donor, StateType } from 'contexts/intialstates/Types';
import useDepositActions from 'hooks/useDepositActions';
import useDebounce from 'Components/Reusable/hooks/debounce';
import useDonorAction from 'hooks/useDonorActions';
import { LoadingButton } from '@mui/lab';
import { GlobalContexts } from 'contexts/GlobalContext';

interface FormData {
	amount?: string;
	donorId?: string;
	note?: string;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			<Title>{children}</Title>
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

const SingleCreditCardDeposit = () => {
	const { setSimpleErrorSnack } = useContext(GlobalContexts);
	const global = useGlobalStyles();
	const [formData, setFormData] = useState<FormData>();
	const [open, setOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const [searchDonors, setSearchDonors] = useState<StateType<Donor[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [searchTerm, setSearchTerm] = useState('');
	const { creditCardDeposit } = useDepositActions();
	const gotDonor = searchDonors?.data?.find((donor: Donor) => donor.id === formData?.donorId?.toString());
	const { getSearchDonors } = useDonorAction();
	const [formErrors, setFormErrors] = useState<string[]>([]);

	const debouncedSearchTerm = useDebounce(searchTerm, 1000);

	React.useEffect(() => {
		getSearchDonors({ searchDonors, setSearchDonors, searchTerm });
	}, [debouncedSearchTerm]);

	const updateFormValue = (key: string, donorId?: any) => (e: any) => {
		setFormErrors(formErrors.filter((item) => item !== key));
		let temp;
		if (key === 'donorId') {
			temp = { ...formData, donorId };
			setFormData(temp);
			return;
		}
		temp = { ...formData, [key]: e.target.value };
		setFormData(temp);
	};

	const startPayment = async () => {
		if (!formData) {
			return;
		}
		const errors = [];

		if (!formData.donorId) {
			errors.push('donorId');
		}

		if (!formData.amount) {
			errors.push('amount');
		}
		if (
			!Number.isInteger(parseFloat(formData.amount as string)) ||
			parseFloat(formData.amount as string) < 1
		) {
			errors.push('invalidAmount');
		}

		setFormErrors(errors);
		if (errors.length) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'Please fill all the required fields.',
			});
			return;
		}
		setLoading(true);

		const payload: CreditCardDepositPayload = {
			amount: parseFloat(formData.amount as string),
			donorId: formData.donorId ? parseInt(formData.donorId) : undefined,
			remark: formData.note,
			isRecurring: true,
		};

		const response = await creditCardDeposit({ payload });
		if (response === false) {
			setLoading(false);
		}
	};

	return (
		<>
			<Button
				color="primary"
				variant="contained"
				sx={{ textTransform: 'none' }}
				onClick={() => setOpen(true)}
			>
				Recurring Donation
			</Button>
			<BootstrapDialog
				onClose={() => setOpen(false)}
				aria-labelledby="customized-dialog-title"
				fullWidth
				open={open}
			>
				<BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
					Recurring Donation
				</BootstrapDialogTitle>

				<DialogContent sx={{ px: 8 }}>
					<Divider />
					<Stack direction="row" justifyContent="space-between" py={2}>
						<Stack>
							<Grid container>
								<Grid item>
									<AccountBalance className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Amount
									</Typography>
								</Grid>
							</Grid>
							<TextField
								sx={{ minWidth: 250 }}
								value={formData?.amount}
								onChange={updateFormValue('amount')}
							/>
							{formErrors.includes('amount') ? (
								<span className={global.errorText}>Amount is required</span>
							) : formErrors.includes('invalidAmount') ? (
								<span className={global.errorText}>Invalid Amount</span>
							) : (
								''
							)}
						</Stack>
						<Stack>
							<Grid container>
								<Grid item>
									<ConfirmationNumber className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Donor
									</Typography>
								</Grid>
							</Grid>
							{
								<>
									<Autocomplete
										freeSolo
										value={gotDonor ? `${gotDonor.firstName}  ${gotDonor.lastName}` : ''}
										loadingText="loading..."
										loading={searchDonors.loading}
										sx={{
											width: 250,
										}}
										id="free-solo-2-demo"
										onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
											updateFormValue('donorId', val?.id)(event);
										}}
										options={(searchDonors?.data || []).map((donor: Donor) => ({
											id: donor.id,
											label: `${donor.firstName || ''} ${donor.lastName || ''}`,
										}))}
										renderInput={(params) => (
											<Stack direction="row" spacing={1}>
												<TextField
													{...params}
													variant="outlined"
													label=""
													InputProps={{
														...params.InputProps,
													}}
													onChange={(event: any) => {
														setSearchTerm(event.target.value);
													}}
												/>
											</Stack>
										)}
									/>
									{formErrors.includes('donorId') && (
										<span className={global.errorText}>Donor is required</span>
									)}
								</>
							}
						</Stack>
					</Stack>
					<Typography sx={{ fontSize: 14, fontWeight: 600, mt: 2 }}>Others</Typography>
					<Divider />
					<Stack direction="column" my={2} py={2} justifyContent="space-between" width="100%">
						<Stack>
							<Grid container>
								<Grid item>
									<PausePresentation className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Note
									</Typography>
								</Grid>
							</Grid>
							<TextField value={formData?.note || ''} multiline rows={4} onChange={updateFormValue('note')} />
						</Stack>
					</Stack>

					<LoadingButton
						disabled={!formData}
						loading={loading}
						loadingPosition="start"
						fullWidth
						type="submit"
						color="primary"
						variant="contained"
						onClick={() => {
							startPayment();
						}}
					>
						Donate
					</LoadingButton>
				</DialogContent>
			</BootstrapDialog>
		</>
	);
};
export default SingleCreditCardDeposit;
