import { VOID_TYPE_LOADING, VOID_TYPE_LOAD_ERROR, VOID_TYPE_LOAD_SUCCESS } from 'contexts/actionTypes';
import { ResponseError, Action, AllVoidTypeStateType, VoidTypeData } from 'contexts/intialstates/Types';

export const allVoidTypes = (
	state: AllVoidTypeStateType,
	{ payload, type }: Action<VoidTypeData>,
): AllVoidTypeStateType => {
	switch (type) {
		case VOID_TYPE_LOADING: {
			return {
				...state,
				error: null,
				loading: true,
			};
		}

		case VOID_TYPE_LOAD_SUCCESS: {
			return {
				...state,
				loading: false,
				error: null,
				data: payload as VoidTypeData,
			};
		}
		case VOID_TYPE_LOAD_ERROR: {
			return {
				...state,
				loading: false,
				error: payload as ResponseError,
			};
		}
		default:
			return state;
	}
};
