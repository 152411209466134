import React from 'react';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { ColumnType, expiringCCHead } from 'Components/Common/Tables/TableHeaders';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import {
	ExpiringCreditCardsInfo,
	Donor,
	expiringCCNotificationsData,
	StateType,
	FilterPayload,
} from 'contexts/intialstates/Types';
import { convertDate, fullMonthYear } from 'helpers/dayAndTime';
import useNotificationActions from 'hooks/useNotificationActions';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import useUserActions from 'hooks/useUserActions';

const ExpiringCCNotifications = () => {
	const { handleRoles } = useUserActions();
	const [expiringCC, setExpiringCC] = React.useState<StateType<expiringCCNotificationsData>>({
		data: null,
		error: null,
		loading: false,
	});

	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);
	const [reload, setIsReload] = React.useState(false);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const { getExpiringCC } = useNotificationActions();

	React.useEffect(() => {
		getExpiringCC({
			expiringCC,
			setExpiringCC,
		});
	}, []);

	const donorName = (donor: Donor) => {
		if (donor?.firstName && donor?.lastName) return `${donor?.firstName} ${donor?.lastName}`;
		if (donor?.firstName) return `${donor?.firstName}`;
		if (donor?.lastName) return ` ${donor?.lastName}`;
		if (donor?.orgName) return donor?.orgName;
		return 'Anonymous';
	};

	const getTableData = () => {
		return expiringCC?.data?.expiredCards.length
			? expiringCC?.data?.expiredCards.map((row: ExpiringCreditCardsInfo, index: number) => {
					return {
						id: row?.id,
						's/n': index + 1,
						donor: donorName(row?.donor),
						cardNumber: row?.cardNumber || 'N/A',
						expiryDate: row?.cardExpireDate ? `${convertDate(row?.cardExpireDate)}` : 'N/A',
						blank: handleRoles('bookkeeping-actions') && (
							<Stack direction="row" spacing={2}>
								<Button variant="contained" sx={{ textTransform: 'capitalize', width: 120 }}>
									Updated
								</Button>
							</Stack>
						),
					};
			  })
			: [];
	};
	const getTableDataMonth1 = () => {
		return expiringCC.data?.expiringCards[0].cards.length
			? expiringCC?.data?.expiringCards[0].cards.map((row: ExpiringCreditCardsInfo, index: number) => {
					return {
						id: row?.id,
						's/n': index + 1,
						donor: donorName(row?.donor),
						cardNumber: row?.cardNumber || 'N/A',
						expiryDate: row?.cardExpireDate ? `${convertDate(row?.cardExpireDate)}` : 'N/A',
						blank: handleRoles('bookkeeping-actions') && (
							<Stack direction="row" spacing={2}>
								<Button variant="outlined" sx={{ textTransform: 'capitalize', width: 120 }}>
									Stop Payments
								</Button>
								<Button variant="contained" sx={{ textTransform: 'capitalize', width: 120 }}>
									Updated
								</Button>
							</Stack>
						),
					};
			  })
			: [];
	};
	const getTableDataMonth2 = () => {
		return expiringCC.data?.expiringCards[1].cards.length
			? expiringCC?.data?.expiringCards[1].cards.map((row: ExpiringCreditCardsInfo, index: number) => {
					return {
						id: row?.id,
						's/n': index + 1,
						donor: donorName(row?.donor),
						cardNumber: row?.cardNumber || 'N/A',
						expiryDate: row?.cardExpireDate ? `${convertDate(row?.cardExpireDate)}` : 'N/A',
						blank: handleRoles('bookkeeping-actions') && (
							<Stack direction="row" spacing={2}>
								<Button variant="outlined" sx={{ textTransform: 'capitalize', width: 120 }}>
									Stop Payments
								</Button>
								<Button variant="contained" sx={{ textTransform: 'capitalize', width: 120 }}>
									Updated
								</Button>
							</Stack>
						),
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType) => {
			return {
				...column,
			};
		});
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	return (
		<>
			<Stack
				alignItems="center"
				direction={{ xs: 'column', sm: 'row' }}
				justifyContent={'space-between'}
				spacing={1}
				mb={5}
			>
				<Stack direction="column">
					<Stack direction="row" spacing={4} alignItems="center">
						<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
							Expiring Credit Cards
						</Typography>
					</Stack>
					<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
						View and manage all expiring credit cards here.
					</Typography>
				</Stack>
				<Stack sx={{ mb: 3 }} direction="row" alignItems={'center'} justifyContent="flex-end">
					<TrimTables
						name={'expiringCCHead'}
						list={expiringCCHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
					<FilterListButton
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
						reload={reload}
						setIsReload={setIsReload}
					/>
				</Stack>
			</Stack>

			<Stack justifyContent="center" alignItems="center">
				<Paper sx={{ width: '100%', overflow: 'hidden', mb: 3 }}>
					<CustomTable
						loading={expiringCC?.loading}
						columnData={getColumnData()}
						tableData={getTableData()}
						handleDrag={handleDrag}
						handleResize={handleResize}
						emptyMessage={'No receipt template available'}
						unPaginated
						unPaginatedTitle="Expired Cards"
					/>
				</Paper>
				<Paper sx={{ width: '100%', overflow: 'hidden', mb: 3 }}>
					<CustomTable
						loading={expiringCC?.loading}
						columnData={getColumnData()}
						tableData={getTableDataMonth1()}
						handleDrag={handleDrag}
						handleResize={handleResize}
						emptyMessage={'No receipt template available'}
						unPaginated
						unPaginatedTitle={fullMonthYear(expiringCC.data?.expiringCards[0].expiryDate as string)}
					/>
				</Paper>
				<Paper sx={{ width: '100%', overflow: 'hidden', mb: 3 }}>
					<CustomTable
						loading={expiringCC?.loading}
						columnData={getColumnData()}
						tableData={getTableDataMonth2()}
						handleDrag={handleDrag}
						handleResize={handleResize}
						emptyMessage={'No receipt template available'}
						unPaginated
						unPaginatedTitle={fullMonthYear(expiringCC.data?.expiringCards[1].expiryDate as string)}
					/>
				</Paper>
			</Stack>
		</>
	);
};

export default ExpiringCCNotifications;
