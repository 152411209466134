import React from 'react';
import { ResponseError, StateType, FilterPayload, User } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { UserValues } from 'Components/AdminPanel/User/CreateEdit';
import { GlobalContexts } from 'contexts/GlobalContext';

type ActiveRoute =
	| 'donor-list'
	| 'annuities-list'
	| 'receipt-letter'
	| 'appeal'
	| 'donation-type'
	| 'diocese'
	| 'parish'
	| 'gift-type'
	| 'annuities-list'
	| 'annuities-actions'
	| 'user-actions'
	| 'user-list'
	| 'donor-anuities'
	| 'donor-bookkeepings'
	| 'donor-actions'
	| 'bookkeeping-list'
	| 'bookkeeping-actions'
	| 'notes'
	| 'campaign'
	| 'operations'
	| 'hca'
	| 'mission-sunday'
	| 'admin-actions';

export interface GetUsers {
	setUsers: React.Dispatch<React.SetStateAction<StateType<User[]>>>;
	users: StateType<User[]>;
	page?: number;
	pageSize?: number;
	orderBy?: string;
	filterPayload?: FilterPayload[];
}

export interface GetUser {
	setCurrentUser: React.Dispatch<React.SetStateAction<StateType<User>>>;
	currentUser: StateType<User>;
}

const useUserActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack, currentUser } = React.useContext(GlobalContexts);

	const getUsers = async ({ setUsers, users }: GetUsers) => {
		try {
			setUsers({
				...users,
				loading: true,
				error: null,
			});
			const response = await api.get(`/user/list`);

			setUsers({
				...users,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setUsers({
					...users,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};
	const getCurrentUser = async ({ currentUser, setCurrentUser }: GetUser) => {
		try {
			setCurrentUser({
				...currentUser,
				loading: true,
				error: null,
			});
			const response = await api.get(`/user/user-info`);

			setCurrentUser({
				...currentUser,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setCurrentUser({
					...currentUser,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const createUser = async (payload: UserValues): Promise<User | boolean> => {
		try {
			const response = await api.post(`user/create`, {
				...payload,
			});
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'User created succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const updateUser = async (payload: UserValues, id: string): Promise<boolean> => {
		try {
			const response = await api.post(`user/${id}/update`, {
				...payload,
				id,
			});
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'User updated succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deleteUser = async (id: string): Promise<boolean> => {
		try {
			const response = await api.remove(`user/${id}/archive`);
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'User deleted successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};
	const handleRoles = (activeRoute: ActiveRoute) => {
		const auditor = process.env.REACT_APP_ROLE_AUDITOR;
		const accountant = process.env.REACT_APP_ROLE_ACCOUNTANT;
		const clerk = process.env.REACT_APP_ROLE_CLERK;
		const admin = process.env.REACT_APP_ROLE_ADMIN;
		const bookeeper = process.env.REACT_APP_ROLE_BOOKKEEPER;
		const operations = process.env.REACT_APP_ROLE_OPERATIONS;

		const user = currentUser.data?.groupId;

		const general = [
			'donor-list',
			'annuities-list',
			'admin-actions',
			'annuities-list',
			'notes',
			'bookkeeping-list',
		];

		if (user === admin) return true;

		if (user === operations) {
			if (
				activeRoute === 'annuities-actions' ||
				activeRoute === 'user-actions' ||
				activeRoute === 'bookkeeping-actions' ||
				activeRoute === 'donor-bookkeepings'
			)
				return false;
			return true;
		}

		if (user === accountant) {
			const permitedArr = [
				...general,
				'donor-anuities',
				'bookkeeping-actions',
				'annuities-actions',
				'donor-bookkeepings',
			];
			return permitedArr.includes(activeRoute);
		}

		if (user === auditor) {
			const permitedArr = ['donor-list', 'annuities-list'];
			return permitedArr.includes(activeRoute);
		}
		if (user === bookeeper) {
			const permitedArr = [...general, 'bookkeeping-actions', 'donor-bookkeepings'];
			return permitedArr.includes(activeRoute);
		}
		if (user === clerk) {
			const permitedArr = [...general, 'campaign', 'mission-sunday', 'operations'];
			return permitedArr.includes(activeRoute);
		}
		return false;
	};

	return {
		getUsers,
		createUser,
		updateUser,
		deleteUser,
		getCurrentUser,
		handleRoles,
	};
};

export default useUserActions;
