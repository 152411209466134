import * as React from 'react';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from 'react-router-dom';
import { convertDate } from 'helpers/dayAndTime';
import { pagination } from 'Components/Common/Tables/Tables';
import { Box, Button, FormControl, MenuItem, Select, Stack, TablePagination, Tooltip } from '@mui/material';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import FormatCurrency from 'helpers/FormatCurrency';
import { GlobalContexts } from 'contexts/GlobalContext';
import { DefaultState } from 'Components/Batch/Batch';
import { ApiResponse, Deposits, StateType } from 'contexts/intialstates/Types';
import { GenerateSingleReceipt } from 'Components/Reusable/GenerateReceipt/GenerateReceipt';
import { sortTable } from 'Components/Common/Tables/sortTable';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import useDepositActions from 'hooks/useDepositActions';
import TableFiltering, { DonorFiltering } from 'Components/Reusable/TableFiltering/TableFiltering';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import CustomAccordian from 'Components/Common/Accordian/Accordian';
import useUserActions from 'hooks/useUserActions';

export interface ConfirmationModalState {
	open: boolean;
	id: null | number | string;
}

export const canDeleteDeposit = (deposit: Deposits) => {
	if (deposit.itemStatus?.status === 'Posted') {
		return { invalid: true, message: 'Cannot delete posted donations' };
	}
	return { invalid: false, message: 'Delete' };
};

export const toggleColumn = (trimList: ColumnType[], label: string) => {
	const columnIndex = trimList.findIndex((item: ColumnType) => item.id === label);

	if (columnIndex > -1 && trimList[columnIndex].isChecked) return true;
	return false;
};

export const handleReducer = (total: number, trimItem: ColumnType) => {
	if (trimItem.isChecked) {
		return total + 1;
	} else {
		return total + 0;
	}
};
const DepositTable = ({
	depoInfo,
	pageSize,
	page,
	setPageSize,
	setPage,
	batchId,
	loading,
	label,
	orderType,
	setLabel,
	setOrderType,
	setOderBy,
	setIsReload,
	reload,
	setFilterPayload,
	filterPayload,
	setSearchDonor,
	searchDonor,
	type,
	trimList,
	setTrimList,
	setPapBankPayload,
	batchStatus,
}: any) => {
	const { handleRoles } = useUserActions();
	const location = useLocation();
	const isHCADonations = location.pathname.includes('hca-donations');
	const { deleteSingleDeposit } = useDepositActions();
	const { setErrorSnack } = React.useContext(GlobalContexts);
	const [defaultstate, setDefaultstate] = React.useState<DefaultState>({ id: '', open: false });
	const [papBankDeposit, setPapBankDeposit] = React.useState<Deposits[]>();

	const [deleteDeposit, setDeleteDeposit] = React.useState<StateType<ApiResponse>>({
		data: null,
		error: null,
		loading: false,
	});

	const navigate = useNavigate();

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleClose = () => setDefaultstate({ ...defaultstate, open: false });

	const cancelDeleteDepositById = () => {
		setDefaultstate({ ...defaultstate, id: '', open: false });
	};

	const data = Array.isArray(depoInfo) ? depoInfo : depoInfo?.data?.body;

	const editDeposit = (row: Deposits) => {
		if (isHCADonations) {
			navigate(`/hca/hca-donations/${row?.id}`);
			return;
		}
		if (row?.isWalkathon === true) {
			navigate(`/hca/walkathon/${row?.id}`);
			return;
		}
		if (batchId) {
			navigate(`/bookkeeping/deposits/${row?.id}/batch/${batchId}`);
		} else {
			navigate(`/bookkeeping/deposits/${row?.id}`);
		}
	};

	const handleDelete = async () => {
		await deleteSingleDeposit({
			depositId: defaultstate?.id as number,
			deleteDeposit,
			setDeleteDeposit,
			setErrorSnack,
		});
		cancelDeleteDepositById();
		setIsReload(!reload);
	};

	React.useEffect(() => {
		if (type === 'papbank' && data) {
			setPapBankDeposit(data);
		}
	}, [type, data]);

	React.useEffect(() => {
		if (type === 'papbank' && papBankDeposit?.length !== 0) {
			setPapBankPayload(
				papBankDeposit?.map((deposit: Deposits) => ({
					status: deposit?.papBankDepositStatus?.id,
					depositId: deposit?.id,
				})),
			);
		}
	}, [type, papBankDeposit]);

	const handleChange = (event: any, id: number) => {
		setPapBankDeposit(
			papBankDeposit?.map((deposit: Deposits) => {
				if (deposit.id === id) {
					return {
						...deposit,
						papBankDepositStatus: { ...deposit.papBankDepositStatus, id: event.target.value },
					};
				}
				return deposit;
			}),
		);
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType) => {
			return {
				...column,
				data: (
					<>
						{column.id !== 'societies' ? (
							<Tooltip title={`Sort by ${column.label}`}>
								<span
									style={{
										cursor: 'pointer',
										margin: '10px',
									}}
									onClick={() => {
										sortTable({
											column: column.id,
											setLabel,
											setOderBy,
											setOrderType,
											orderType,
											label,
										});
									}}
								>
									{column.label}
									{column.id == label && <OrderTypeArrow orderType={orderType} />}
								</span>
							</Tooltip>
						) : (
							column.label
						)}
						<>
							<DonorFiltering
								searchDonor={searchDonor}
								setSearchDonor={setSearchDonor}
								filterType={column.filterType}
								setPage={setPage}
							/>
							<TableFiltering
								field={column.id}
								filterType={column.filterType}
								list={column.list}
								reload={reload}
								setIsReload={setIsReload}
								setFilterPayload={setFilterPayload}
								filterPayload={filterPayload}
								setPage={setPage}
								page={page}
							/>
						</>
					</>
				),
			};
		});
	};

	const getDonorName = (row: Deposits) => {
		return row.donor?.orgName
			? row.donor?.orgName
			: row.donor?.firstName || row.donor?.lastName
			? `${row.donor.firstName ?? ''} ${row.donor.lastName ?? ''}`
			: row.schoolId
			? row.school?.name
			: row.parishId
			? row.parish?.parishName
			: row.schoolBoardId
			? row.schoolBoard?.name
			: row?.dioceseId
			? row.diocese?.dioceseName
			: 'Anonymous';
	};

	const getTableData = () => {
		return data?.length
			? data.map((row: Deposits, index: number) => {
					return {
						id: row.id,
						's/n': page * pageSize + index + 1,
						actions: type !== 'papbank' && type !== 'papcard' && (
							<Stack direction="row" zIndex={-999} justifyContent="center" alignItems="center">
								{type !== 'papbank' && type !== 'papcard' && (
									<GenerateSingleReceipt
										deposit={row}
										id={row.id as number}
										handleReload={() => setIsReload(!reload)}
									/>
								)}
							</Stack>
						),
						donor: getDonorName(row),
						referencenumber: row?.donor?.referenceNumber || 'N/A',
						creditAmount: row.creditAmount ? <FormatCurrency value={row.creditAmount} /> : 'N/A',
						paymentMethod: row.paymentMethod?.name ? row.paymentMethod?.name : 'N/A',
						itemStatus: row?.itemStatus ? row.itemStatus?.status : 'N/A',
						isWalkathon: row?.isWalkathon ? 'Yes' : 'No',
						creditDate: `${row?.creditDate ? convertDate(row?.creditDate) : 'N/A'}`,
						postDate: `${row?.postDate ? convertDate(row?.postDate) : 'N/A'}`,
						batchNumber: row.batch?.batchNumber ? row.batch?.batchNumber : 'N/A',
						chequeNumber: row.chequeNumber || 'N/A',
						accountNumber: row.accountNumber || 'N/A',
						societies: row?.societies?.join(', ') || 'N/A',
						created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
						createdBy: row?.createdBy?.name || 'N/A',
						updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
						updatedBy: row?.updatedBy?.name || 'N/A',
					};
			  })
			: [];
	};
	const papBankData = () => {
		if (type !== 'papbank') return;

		return {
			failed: () => {
				return papBankDeposit?.length
					? papBankDeposit
							.filter((ele: Deposits) => ele?.papBankDepositStatus?.id === 2)
							.map((row: Deposits, index: number) => {
								return {
									id: row.id,
									's/n': page * pageSize + index + 1,
									actions: type !== 'papbank' && type !== 'papcard' && (
										<Stack direction="row" zIndex={-999} justifyContent="center" alignItems="center">
											{type !== 'papbank' && type !== 'papcard' && (
												<GenerateSingleReceipt
													deposit={row}
													id={row.id as number}
													handleReload={() => setIsReload(!reload)}
												/>
											)}
										</Stack>
									),
									donor:
										row.donor?.firstName && row.donor?.lastName
											? `${row.donor.firstName} ${row.donor.lastName}`
											: row.donor?.orgName
											? row.donor.orgName
											: 'Anonymous',
									referencenumber: row?.donor?.referenceNumber || 'N/A',
									creditAmount: row.creditAmount ? <FormatCurrency value={row.creditAmount} /> : 'N/A',
									paymentMethod: row.paymentMethod?.name ? row.paymentMethod?.name : 'N/A',
									itemStatus: row?.itemStatus ? row.itemStatus?.status : 'N/A',
									isWalkathon: `${row?.isWalkathon}` ?? 'N/A',
									creditDate: `${row?.creditDate ? convertDate(row?.creditDate) : 'N/A'}`,
									postDate: `${row?.postDate ? convertDate(row?.postDate) : 'N/A'}`,
									batchNumber: row.batch?.batchNumber ? row.batch?.batchNumber : 'N/A',
									chequeNumber: row.chequeNumber || 'N/A',
									accountNumber: row.accountNumber || 'N/A',
									blank: handleRoles('bookkeeping-actions') && (
										<Stack direction="row" spacing={1}>
											<Button onClick={() => editDeposit(row)} sx={{ width: 100 }} variant={'contained'}>
												Edit
											</Button>

											{batchStatus !== 'Posted' && (
												<FormControl sx={{ width: 100 }} size="small">
													<Select
														sx={{
															color: 'var(--mainColor)',
															border: '1px solid var(--mainColor)',
															'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
																border: '1px solid var(--mainColor)',
																borderRadius: '5px 5px 0 0',
															},
														}}
														value={row?.papBankDepositStatus?.id}
														className="noSelect"
														displayEmpty
														onChange={(event) => handleChange(event, row.id)}
													>
														<MenuItem value={undefined}>Status</MenuItem>
														<MenuItem value={1}>Pass</MenuItem>
														<MenuItem value={2}>Fail</MenuItem>
													</Select>
												</FormControl>
											)}
										</Stack>
									),
									societies: row?.societies?.join(', ') || 'N/A',
								};
							})
					: [];
			},
			passed: () => {
				return papBankDeposit?.length
					? papBankDeposit
							?.filter((ele: Deposits) => ele?.papBankDepositStatus?.id !== 2)
							?.map((row: Deposits, index: number) => {
								return {
									id: row.id,
									's/n': page * pageSize + index + 1,
									actions: type !== 'papbank' && type !== 'papcard' && (
										<Stack direction="row" zIndex={-999} justifyContent="center" alignItems="center">
											{type !== 'papbank' && type !== 'papcard' && (
												<GenerateSingleReceipt
													deposit={row}
													id={row.id as number}
													handleReload={() => setIsReload(!reload)}
												/>
											)}
										</Stack>
									),
									donor:
										row.donor?.firstName && row.donor?.lastName
											? `${row.donor.firstName} ${row.donor.lastName}`
											: row.donor?.orgName
											? row.donor.orgName
											: 'Anonymous',
									referencenumber: row?.donor?.referenceNumber || 'N/A',
									creditAmount: row.creditAmount ? <FormatCurrency value={row.creditAmount} /> : 'N/A',
									paymentMethod: row.paymentMethod?.name ? row.paymentMethod?.name : 'N/A',
									itemStatus: row?.itemStatus ? row.itemStatus?.status : 'N/A',
									isWalkathon: `${row?.isWalkathon}` ?? 'N/A',
									creditDate: `${row?.creditDate ? convertDate(row?.creditDate) : 'N/A'}`,
									postDate: `${row?.postDate ? convertDate(row?.postDate) : 'N/A'}`,
									batchNumber: row.batch?.batchNumber ? row.batch?.batchNumber : 'N/A',
									chequeNumber: row.chequeNumber || 'N/A',
									accountNumber: row.accountNumber || 'N/A',
									blank: handleRoles('bookkeeping-actions') && (
										<Stack direction="row" spacing={1}>
											<Button onClick={() => editDeposit(row)} sx={{ width: 100 }} variant={'contained'}>
												Edit
											</Button>

											{batchStatus !== 'Posted' && (
												<FormControl sx={{ width: 100 }} size="small">
													<Select
														sx={{
															color: 'var(--mainColor)',
															border: '1px solid var(--mainColor)',
															'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
																border: '1px solid var(--mainColor)',
																borderRadius: '5px 5px 0 0',
															},
														}}
														value={row?.papBankDepositStatus?.id}
														className="noSelect"
														displayEmpty
														onChange={(event) => handleChange(event, row.id)}
													>
														<MenuItem value={undefined}>Status</MenuItem>
														<MenuItem value={1}>Pass</MenuItem>
														<MenuItem value={2}>Fail</MenuItem>
													</Select>
												</FormControl>
											)}
										</Stack>
									),
									societies: row?.societies?.join(', ') || 'N/A',
								};
							})
					: [];
			},
		};
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	return (
		<>
			<Stack justifyContent="center" alignItems="center">
				<ConfirmationModal
					handleClose={handleClose}
					message={'Are you sure you want to delete this Deposit?'}
					open={defaultstate?.open}
					handleConfirmation={handleDelete}
					handleCancel={cancelDeleteDepositById}
					loading={deleteDeposit?.loading}
				/>
				<>
					{type === 'papbank' ? (
						<Stack direction="column" spacing={3} sx={{ width: '100%', overflow: 'hidden' }}>
							<CustomAccordian
								isReport
								header={<Box sx={{ fontWeight: 600, fontSize: 17 }}>Pass\Unrated Donations</Box>}
								content={
									<CustomTable
										headerBg="white"
										unPaginated
										loading={loading}
										columnData={getColumnData()}
										tableData={papBankData()?.passed()}
										handleDrag={handleDrag}
										handleResize={handleResize}
										emptyMessage={'No deposits available'}
									/>
								}
								expandValue={true}
								isIconEnd
							/>
							<CustomAccordian
								isReport
								header={<Box sx={{ fontWeight: 600, fontSize: 17 }}>Failed Donations</Box>}
								content={
									<CustomTable
										headerBg="white"
										unPaginated
										loading={loading}
										columnData={getColumnData()}
										tableData={papBankData()?.failed()}
										handleDrag={handleDrag}
										handleResize={handleResize}
										emptyMessage={'No deposits available'}
									/>
								}
								expandValue={true}
								isIconEnd
							/>
						</Stack>
					) : (
						<Paper sx={{ width: '100%', overflow: 'hidden' }}>
							<CustomTable
								loading={loading}
								columnData={getColumnData()}
								tableData={getTableData()}
								handleDrag={handleDrag}
								handleResize={handleResize}
								emptyMessage={'No deposits available'}
								handleRowClick={(row) => editDeposit(row as Deposits)}
							/>

							{depoInfo?.data && data?.length > 0 && !batchId && (
								<TablePagination
									rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
									component="div"
									count={depoInfo?.data?.totalRecords}
									rowsPerPage={pageSize}
									page={page}
									sx={pagination}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
							)}
						</Paper>
					)}
				</>
			</Stack>
		</>
	);
};
export default DepositTable;
