import React from 'react';
import {
	ResponseError,
	StateType,
	FilterPayload,
	TimeStampInfoType,
	TimeStamp,
	Donor,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface Campaign {
	pageNumber: number;
	pageSize: number;
	totalPages: number;
	totalRecords: number;
	body: CampaignData[];
}
export interface GenerateCampaignPayload {
	campaignName: string;
	generateDonorPayload: {
		startDate: string;
		endDate: string;
		minAmount: number;
		maxAmount: number;
		noMail: boolean | null;
		excludeFromParishList: boolean | null;
		returnMail: boolean | null;
		deceased: boolean | null;
		isCommunicationNone: boolean | null;
		magazine?: boolean | null;
		faithPropagation: boolean | null;
		stPeter: boolean | null;
		holyChildhood: boolean | null;
		noFollowAppeal?: boolean | null;
		receiptFrequencyIds: number[] | null;
		donationTypeIds: number[] | null;
		societyIds: number[] | null;
		paymentMethodIds: number[] | null;
		donorIds: number[] | null;
	};
}
export interface CampaignData extends TimeStampInfoType, TimeStamp {
	id: number;
	name: string;
	minAmount: number;
	maxAmount: number;
	startDate: string;
	endDate: string;
	noMail: boolean;
	excludeFromParishList: boolean;
	returnMail: boolean;
	deceased: boolean;
	isCommunicationNone: boolean;
	magazine: boolean;
	faithPropagation: boolean;
	stPeter: boolean;
	holyChildhood: boolean;
	noFollowAppeal: boolean;
	isPrefSocietyNone: boolean;
	receiptFrequencyIds: number[];
	donationTypeIds: number[];
	societyIds: number[];
	paymentMethodIds: number[];
	donorIds: number[];
	donorCount: number;
	donors: adhocDonor[];
}
export interface adhocDonor {
	donorId: number;
	name: string;
}
export interface GetCampaignType {
	setCampaign: React.Dispatch<React.SetStateAction<StateType<Campaign>>>;
	campaign: StateType<Campaign>;
	page: number;
	pageSize: number;
	orderBy?: string;
	filterPayload?: FilterPayload[];
}
export interface GetCampaignByIDType {
	setCampaignId: React.Dispatch<React.SetStateAction<StateType<ICampaignOutput>>>;
	campaignId: StateType<ICampaignOutput>;
	id: string;
}
export interface CampaignOutputData {
	payload: GenerateCampaignPayload;
	setCampaignOutput: React.Dispatch<React.SetStateAction<StateType<ICampaignOutput>>>;
}
export interface IGenerateData {
	payload: GenerateCampaignPayload;
	campaignId: StateType<ICampaignOutput>;
	setCampaignId: React.Dispatch<React.SetStateAction<StateType<ICampaignOutput>>>;
	id: number;
}
export interface CampaignOutputDonor extends Donor {
	donorType: string;
	amount: number;
}
export interface ICampaignOutput extends CampaignData {
	campaignDonors: CampaignOutputDonor[];
}
export interface UpdateCampaignPayload {
	name: string;
	minAmount: number;
	maxAmount: number;
	startDate: string;
	endDate: string;
	noMail: boolean | null;
	excludeFromParishList: boolean | null;
	returnMail: boolean | null;
	deceased: boolean | null;
	isCommunicationNone: boolean | null;
	magazine?: boolean | null;
	faithPropagation: boolean | null;
	stPeter: boolean | null;
	holyChildhood: boolean | null;
	noFollowAppeal?: boolean | null;
	receiptFrequencyIds: number[];
	donationTypeIds: number[];
	societyIds: number[];
	paymentMethodIds: number[];
	donorIds: number[];
	donorCount: number;
	campaignDonors: CampaignOutputDonor[];
}

const useCampaignActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const getCampaignByID = async ({ setCampaignId, campaignId, id }: GetCampaignByIDType) => {
		try {
			setCampaignId({
				...campaignId,
				loading: true,
				error: null,
			});
			const response = await api.get(`campaign/${id}`);
			setCampaignId({
				...campaignId,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setCampaignId({
					...campaignId,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const getCampaigns = async ({
		setCampaign,
		campaign,
		page,
		pageSize,
		orderBy,
		filterPayload,
	}: GetCampaignType) => {
		try {
			setCampaign({
				...campaign,
				loading: true,
				error: null,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;

			if (page) {
				response = await api.get(
					`campaign/list?PageNumber=${page + 1}&PageSize=${pageSize}${filter}${
						orderBy || '&SortBy=created%20desc'
					}`,
				);
			} else {
				response = await api.get(
					`campaign/list?&PageSize=${pageSize}${filter}${orderBy || '&SortBy=created%20desc'}`,
				);
			}

			setCampaign({
				...campaign,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || campaign.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setCampaign({
					...campaign,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};
	const getCampaignOutput = async ({
		payload,
		setCampaignOutput,
	}: any): // CampaignOutputData

	Promise<boolean | void> => {
		try {
			setCampaignOutput({
				data: null,
				loading: true,
				error: null,
			});
			const payloadArr = Object.keys(payload.generateDonorPayload);
			const filteredPayload = payloadArr.filter((ele) => payload?.generateDonorPayload[ele] != null);
			const obj = filteredPayload.reduce(
				(o, key) => ({ ...o, [key]: payload.generateDonorPayload[key] }),
				{},
			);
			const response = await api.post(`campaign/generate-save`, {
				...payload,
				generateDonorPayload: obj,
			});
			if (response?.data?.result === null) {
				setCampaignOutput({
					data: null,
					loading: false,
					error: null,
				});
				return setSimpleErrorSnack({
					show: true,
					severity: 'info',
					message: 'No record found',
				});
			}
			if (response?.data?.statusCode === 0) {
				setCampaignOutput({
					data: response?.data?.result,
					loading: false,
					error: null,
				});
				if (response?.data?.result && response.data.result.length === 0) {
					setSimpleErrorSnack({
						message: 'No record found',
						severity: 'error',
						show: true,
					});
				}
			} else {
				setCampaignOutput({
					data: null,
					loading: false,
					error: null,
				});
			}
		} catch (error) {
			setCampaignOutput({
				data: null,
				loading: false,
				error: null,
			});
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
		}
	};
	const generateCampaign = async ({ payload, setCampaignId, campaignId, id }: IGenerateData) => {
		try {
			setCampaignId({
				...campaignId,
				loading: true,
			});

			const {
				startDate,
				endDate,
				maxAmount,
				minAmount,
				noMail,
				excludeFromParishList,
				returnMail,
				deceased,
				isCommunicationNone,
				magazine,
				faithPropagation,
				stPeter,
				holyChildhood,
				noFollowAppeal,
				receiptFrequencyIds,
				donationTypeIds,
				societyIds,
				paymentMethodIds,
				donorIds,
			} = payload.generateDonorPayload;
			const response = await api.get(`campaign/${id}/generate?start=${startDate}
			&end=${endDate}${minAmount ? `&minAmount=${minAmount}` : ''} 
			${maxAmount ? `&maxAmount=${maxAmount}` : ''}${noMail ? `&noMail=${noMail}` : ''}${
				excludeFromParishList ? `&excludeFromParishList=${excludeFromParishList}` : ''
			}${returnMail ? `&returnMail=${returnMail}` : ''}${deceased ? `&deceased=${deceased}` : ''}${
				isCommunicationNone ? `&isCommunicationNone=${isCommunicationNone}` : ''
			}${magazine === true ? `&magazine=true` : magazine === false ? `&magazine=false` : ''}${
				faithPropagation ? `&faithPropagation=${faithPropagation}` : ''
			}${stPeter ? `&stPeter=${stPeter}` : ''}${holyChildhood ? `&holyChildhood=${holyChildhood}` : ''}${
				noFollowAppeal === true
					? `&noFollowAppeal=true`
					: noFollowAppeal === false
					? '&noFollowAppeal=false'
					: ''
			}${receiptFrequencyIds ? `&receiptFrequencyIds=${receiptFrequencyIds}` : ''}${
				donationTypeIds ? `&donationTypeIds=${donationTypeIds}` : ''
			}${societyIds ? `&societyIds=${societyIds}` : ''}${
				paymentMethodIds ? `&paymentMethodIds=${paymentMethodIds}` : ''
			}${donorIds ? `&donorIds=${donorIds}` : ''}`);
			if (response?.data?.result === null) {
				setCampaignId({
					loading: false,
					data: null,
					error: null,
				});
				setSimpleErrorSnack({
					show: true,
					severity: 'info',
					message: 'No record found',
				});
				return true;
			}
			if (response?.data?.statusCode === 0) {
				setCampaignId({
					error: null,
					loading: false,
					data: {
						...response.data?.result,
						name: campaignId.data?.name,
						id: campaignId.data?.id,
					} as ICampaignOutput,
				});
				return true;
			} else {
				setCampaignId({
					...campaignId,
					error: null,
					loading: false,
				});
				return false;
			}
		} catch (error) {
			setCampaignId({
				...campaignId,
				error: null,
				loading: false,
			});
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const updateCampaign = async (id: string, payload: UpdateCampaignPayload): Promise<boolean> => {
		try {
			const response = await api.post(`/campaign/${id}/update`, payload);
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Campaign updated successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	return {
		getCampaignByID,
		getCampaigns,
		getCampaignOutput,
		updateCampaign,
		generateCampaign,
	};
};

export default useCampaignActions;
