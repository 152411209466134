import React, { useContext, useEffect, useState, FC } from 'react';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { Title } from './CreateDeposit.style';
import { CreditCardDepositPayload } from './Types';
import {
	Divider,
	Grid,
	Stack,
	TextField,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	Typography,
	Tooltip,
	Autocomplete,
} from '@mui/material';
import { useGlobalStyles } from 'Components/Common/global/global';
import { MonetizationOn, AccountCircle, Note, CreditCard } from '@mui/icons-material';
import useActions from '../../../hooks/useDonorActions';
import useDepositActions from 'hooks/useDepositActions';
import { GlobalContexts } from 'contexts/GlobalContext';
import { Donor, DonorData, StateType } from 'contexts/intialstates/Types';
import useDebounce from 'Components/Reusable/hooks/debounce';
import { LoadingButton } from '@mui/lab';
import { useLocation } from 'react-router-dom';

interface FormData {
	amount?: string;
	donorId?: string;
	note?: string;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			<Title>{children}</Title>
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

interface P {
	donorId?: number;
	donorInformation?: Donor;
}

const SingleCreditCardDeposit: FC<P> = ({ donorId, donorInformation }) => {
	const location = useLocation();
	const isDonor = location.pathname.includes('donors');
	const { setErrorSnack, setSimpleErrorSnack } = useContext(GlobalContexts);
	const global = useGlobalStyles();
	const [searchDonors, setSearchDonors] = useState<StateType<Donor[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [formData, setFormData] = useState<FormData>();
	const [formErrors, setFormErrors] = useState<string[]>([]);
	const [allDonors, setAllDonors] = useState<any>();
	const [open, setOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);

	const [donors, setDonors] = useState<StateType<DonorData>>({
		data: null,
		loading: false,
		error: null,
	});
	const { getDonors, getSearchDonors } = useActions();
	const { creditCardDeposit } = useDepositActions();
	const [searchTerm, setSearchTerm] = useState('');
	const [donorName, setDonorName] = React.useState('');
	const debouncedSearchTerm = useDebounce(searchTerm, 1000);

	React.useEffect(() => {
		getSearchDonors({ searchDonors, setSearchDonors, searchTerm });
	}, [debouncedSearchTerm]);
	useEffect(() => {
		getDonors({
			donors,
			setDonors,
			setErrorSnack,
		});
	}, []);

	useEffect(() => {
		if (donors) {
			setAllDonors(donors?.data?.body || []);
		}
		if (donorId && donors) {
			const currDonor = donors?.data?.body?.find((donor: Donor) => donor.id === donorId?.toString());
			setDonorName(`${currDonor?.firstName || ''} ${currDonor?.lastName || ''}`);
			const temp = { ...formData, donorId: donorId.toString() };
			setFormData(temp);
		}
	}, [donors, donorId]);

	const updateFormValue = (key: string, donorId?: any) => (e: any) => {
		if (formErrors.includes(key)) {
			setFormErrors(formErrors.filter((item) => item !== key));
		}
		let temp;
		if (key === 'donorId') {
			temp = { ...formData, donorId };
			setFormData(temp);
			return;
		}
		temp = { ...formData, [key]: e.target.value };
		setFormData(temp);
	};

	const startPayment = async () => {
		if (!formData) {
			return;
		}
		const errors = [];

		if (!formData.donorId) {
			errors.push('donorId');
		}

		if (!formData.amount) {
			errors.push('amount');
		}
		if (
			!Number.isInteger(parseFloat(formData.amount as string)) ||
			parseFloat(formData.amount as string) < 1
		) {
			errors.push('invalidAmount');
		}

		setFormErrors(errors);
		if (errors.length) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'Please fill all the required fields.',
			});
			return;
		}
		setLoading(true);
		const payload: CreditCardDepositPayload = {
			amount: parseFloat(formData.amount as string),
			donorId: formData.donorId ? parseInt(formData.donorId) : undefined,
			remark: formData.note,
			isRecurring: false,
		};

		await creditCardDeposit({ payload });
		setLoading(false);
	};

	return (
		<div>
			<Tooltip title={donorInformation?.archived ? 'Donation cannot be created for archived donors' : ''}>
				<span>
					<Button
						color="primary"
						variant="contained"
						sx={{ textTransform: 'none' }}
						onClick={() => setOpen(true)}
						startIcon={<CreditCard />}
						disabled={donorInformation?.archived}
					>
						CC Donation
					</Button>
				</span>
			</Tooltip>
			<div>
				<BootstrapDialog
					onClose={() => setOpen(false)}
					aria-labelledby="customized-dialog-title"
					fullWidth
					open={open}
				>
					<BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
						CC Donation
					</BootstrapDialogTitle>

					<DialogContent sx={{ px: 8 }}>
						<Divider />
						<Stack direction="row" justifyContent="space-between" py={2}>
							<Stack>
								<Grid container>
									<Grid item>
										<MonetizationOn className={global.formLabelIcon} />
									</Grid>
									<Grid item>
										<Typography variant="body2" className={global.formLabelText}>
											Amount
										</Typography>
									</Grid>
								</Grid>
								<TextField
									sx={{ minWidth: 250 }}
									value={formData?.amount}
									onChange={updateFormValue('amount')}
								/>
								{formErrors.includes('amount') ? (
									<span className={global.errorText}>Amount is required</span>
								) : formErrors.includes('invalidAmount') ? (
									<span className={global.errorText}>Invalid Amount</span>
								) : (
									''
								)}
							</Stack>
							<Stack>
								<Grid container>
									<Grid item>
										<AccountCircle className={global.formLabelIcon} />
									</Grid>
									<Grid item>
										<Typography variant="body2" className={global.formLabelText}>
											Donor
										</Typography>
									</Grid>
								</Grid>

								{allDonors && (
									<>
										<Autocomplete
											disabled={donorId !== undefined}
											freeSolo
											loadingText="loading..."
											loading={searchDonors.loading}
											value={
												isDonor
													? `${donorInformation?.firstName || ''} ${donorInformation?.lastName || ''}`
													: donorName ?? ''
											}
											sx={{
												width: 250,
											}}
											id="free-solo-2-demo"
											onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
												updateFormValue('donorId', val?.id)(event);
												setDonorName(val?.label);
											}}
											options={(searchDonors?.data || []).map((donor: Donor) => ({
												id: donor.id,
												label: `${donor.firstName || ''} ${donor.lastName || ''}`,
											}))}
											renderInput={(params) => (
												<Stack direction="row" spacing={1}>
													<TextField
														{...params}
														variant="outlined"
														label=""
														InputProps={{
															...params.InputProps,
														}}
														onChange={(event: any) => {
															setSearchTerm(event.target.value);
														}}
													/>
												</Stack>
											)}
										/>
										{formErrors.includes('donorId') && (
											<span className={global.errorText}>Donor is required</span>
										)}
									</>
								)}
							</Stack>
						</Stack>
						<Typography sx={{ fontSize: 14, fontWeight: 600, mt: 2 }}>Others</Typography>
						<Divider />
						<Stack direction="column" my={2} py={2} justifyContent="space-between" width="100%">
							<Stack>
								<Grid container>
									<Grid item>
										<Note className={global.formLabelIcon} />
									</Grid>
									<Grid item>
										<Typography variant="body2" className={global.formLabelText}>
											Note
										</Typography>
									</Grid>
								</Grid>
								<TextField
									value={formData?.note || ''}
									multiline
									rows={4}
									onChange={updateFormValue('note')}
								/>
							</Stack>
						</Stack>

						<LoadingButton
							disabled={!formData}
							loading={loading}
							fullWidth
							type="submit"
							color="primary"
							variant="contained"
							loadingPosition="start"
							onClick={() => {
								startPayment();
							}}
						>
							Donate
						</LoadingButton>
					</DialogContent>
				</BootstrapDialog>
			</div>
		</div>
	);
};

export default SingleCreditCardDeposit;
