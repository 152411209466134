import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TableRow from '@material-ui/core/TableRow';
import ReactDraggable from 'react-draggable';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
const ReactDrag: any = ReactDraggable;

interface GetListStyleProps {
	isDraggingOver: any;
	unPaginated?: boolean;
}

const getListStyle = ({ isDraggingOver, unPaginated }: GetListStyleProps) => ({
	display: 'flex',
	alignItems: 'center',
	background: `${unPaginated ? 'transparent' : 'var(--mainBackground)'}`,
	overflow: 'none',
});

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
	userSelect: 'none',
	margin: '5px',
	...draggableStyle,
	display: 'flex',
	justifyContent: 'center',
});

interface P {
	columnData: ColumnType[];
	handleDrag: (columnData: ColumnType[]) => void;
	handleResize: (columnData: ColumnType[]) => void;
	unPaginated?: boolean;
	headerBg?: string;
}

const EnhancedTableHead = ({ columnData, handleDrag, handleResize, unPaginated, headerBg }: P) => {
	const reorder = (list: ColumnType[], startIndex: number, endIndex: number): ColumnType[] => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const onDragEnd = (result: any) => {
		if (!result.destination) {
			return;
		}
		const columnReorderData = reorder(columnData, result.source.index, result.destination.index);
		columnReorderData.forEach((a: ColumnType) => delete a?.data);
		handleDrag(columnReorderData);
	};

	const handleWidthChange = (columnId: string, minWidth: number) => {
		const currentColumns = [...columnData];
		const currentColumnIndex = currentColumns.findIndex((column) => {
			return column.id === columnId;
		});
		const columnToChange = currentColumns[currentColumnIndex];
		const changedColumn = { ...columnToChange, minWidth };
		currentColumns.splice(currentColumnIndex, 1, changedColumn);
		currentColumns.forEach((a: ColumnType) => delete a?.data);
		handleResize?.(currentColumns);
	};

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<TableHead>
				<TableRow
					component={Droppable as React.ElementType<any>}
					droppableId="droppable"
					direction="horizontal"
				>
					{(provided: any, snapshot: any) => (
						<tr
							key={snapshot.toString()}
							ref={provided.innerRef}
							style={{
								...getListStyle({ isDraggingOver: snapshot.isDraggingOver, unPaginated }),
							}}
							{...provided.droppableProps}
						>
							{columnData
								.filter(({ isChecked }: ColumnType) => isChecked)
								.map((item: any, index: number) => (
									<TableCell
										key={item.id}
										style={{
											overflow: 'none',
											width: `${item.minWidth}px`,
											padding: '10px 0px',
											position: `${item.id === 'blank' ? 'sticky' : 'initial'}`,
											right: `${item.id === 'blank' ? 0 : 'initial'}`,
											background: `${item.id === 'blank' ? headerBg || 'var(--mainBackground)' : 'initial'}`,
										}}
									>
										<div style={{ display: 'flex' }}>
											<Draggable key={item.id} draggableId={item.id} index={index}>
												{(provided, snapshot) => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														style={getItemStyle(snapshot.isDragging, {
															...provided.draggableProps.style,
															width: `${item.minWidth}px` || '100px',
														})}
													>
														<div
															style={{
																width: `${item.minWidth - 25}px`,
																display: 'inline-block',
																whiteSpace: 'nowrap',
																overflow: 'hidden',
																textOverflow: 'ellipsis',
																textAlign: 'center',
																color: `${
																	item.id === 'blank'
																		? 'transparent'
																		: unPaginated
																		? 'var(--mainColor)'
																		: 'initial'
																}`,
															}}
														>
															{item.data || item.label}
														</div>
													</div>
												)}
											</Draggable>
											<ReactDrag
												axis="x"
												defaultClassName="ReactDragHandle"
												defaultClassNameDragging="ReactDragHandleActive"
												onStop={(event: any, data: any) => {
													const newWidth = item.minWidth + data.x;
													handleWidthChange(item.id, newWidth);
												}}
												position={{
													x: 0,
													y: 0,
												}}
											>
												<div
													style={{
														cursor: 'col-resize',
														backgroundColor: 'rgb(204, 226, 255)',
														display: 'flex',
														alignItems: 'center',
													}}
												>
													{item.id !== 'blank' && (
														<div
															className={'handle'}
															style={{
																border: `1px solid ${
																	unPaginated ? 'var(--lighterGray)' : 'rgb(255, 255, 255)'
																}`,
																height: '100%',
															}}
														/>
													)}
												</div>
											</ReactDrag>
										</div>
									</TableCell>
								))}
							{provided.placeholder}
						</tr>
					)}
				</TableRow>
			</TableHead>
		</DragDropContext>
	);
};

export default EnhancedTableHead;
