import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { LIGHT_GREY_COLOR, PRIMARY_COLOR, SECONDARY_COLOR } from './theme';

export const isDisabled = { opacity: 0.3, color: 'rgba(128, 128, 128, 0.1)' };

export const useGlobalStyles = makeStyles((theme: Theme) =>
	createStyles({
		formTitle: {
			fontWeight: 700,
			textAlign: 'center',
			marginBottom: '32px',
		},
		title: {
			fontWeight: 700,
			textAlign: 'center',
		},
		formSubTitle: {
			textAlign: 'center',
			marginBottom: '32px',
		},
		or: {
			textAlign: 'center',
			color: SECONDARY_COLOR,
		},
		formLabelIcon: {
			fontSize: '18px',
			marginRight: '5px',
			color: PRIMARY_COLOR,
		},
		formLabelIconSvg: {
			'& svg': {
				marginRight: '5px',
				marginBottom: '5px',
				color: PRIMARY_COLOR,
			}
		},
		formLabelText: {
			fontSize: '13px',
			color: SECONDARY_COLOR,
			fontWeight: 600,
		},
		formLabelTextMd: {
			fontSize: '15px',
			color: SECONDARY_COLOR,
			fontWeight: 600,
		},
		disabledInput: {
			background: 'var(--invalidBackground)'
		},
		hyperlink: {
			color: PRIMARY_COLOR,
			textTransform: 'none',
		},
		textSubtitleBlue: {
			fontSize: '18px',
			color: PRIMARY_COLOR,
		},
		mabo16: {
			marginBottom: '16px',
		},
		mabo32: {
			marginBottom: '32px',
		},
		ml: {
			marginLeft: theme.spacing(2),
		},
		mr: {
			marginRight: theme.spacing(2),
		},
		mt: {
			marginTop: theme.spacing(2),
		},
		mb: {
			marginBottom: theme.spacing(2),
		},
		mlSm: {
			marginLeft: theme.spacing(1),
		},
		mrSm: {
			marginRight: theme.spacing(1),
		},
		mrXs: {
			marginRight: theme.spacing(0.5),
		},
		mlXs: {
			marginLeft: theme.spacing(0.5),
		},
		mtSm: {
			marginTop: theme.spacing(1),
		},
		mbSm: {
			marginBottom: theme.spacing(1),
		},
		mlMd: {
			marginLeft: theme.spacing(3),
		},
		mrMd: {
			marginRight: theme.spacing(3),
		},
		mtMd: {
			marginTop: theme.spacing(3),
		},
		mbMd: {
			marginBottom: theme.spacing(3),
		},
		mbXs: {
			marginBottom: theme.spacing(0.5),
		},
		mx: {
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
		},
		my: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2),
		},
		mxSm: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
		},
		mySm: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
		mxXsDevice: {
			[theme.breakpoints.only('xs')]: {
				marginLeft: theme.spacing(2),
				marginRight: theme.spacing(2),
			},
		},
		centerElements: {
			marginRight: 'auto',
			marginLeft: 'auto',
			wordWrap: 'break-word',
			width: '80%',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
		px: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
		py: {
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(2),
		},
		pl: {
			paddingLeft: theme.spacing(2),
		},
		pr: {
			paddingRight: theme.spacing(2),
		},
		pt: {
			paddingTop: theme.spacing(2),
		},
		pb: {
			paddingBottom: theme.spacing(2),
		},
		pxSm: {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
		},
		pySm: {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
		},
		margin: {
			margin: theme.spacing(2),
		},
		padding: {
			padding: theme.spacing(2),
		},
		m0: {
			margin: '0px !important',
		},
		p0: {
			padding: '0 !important',
		},
		mrAuto: {
			marginRight: 'auto',
		},
		mlAuto: {
			marginLeft: 'auto',
		},
		mtAuto: {
			marginTop: 'auto',
		},
		mbAuto: {
			marginBottom: 'auto',
		},
		colorPrimary: {
			color: theme.palette.primary.main,
		},
		bgPrimary: {
			background: theme.palette.primary.main,
		},
		disabled: {
			opacity: 0.3,
			color: 'rgba(128, 128, 128, 0.1)',
		},
		errorText: {
			color: 'red',
		},
		file_uploader: {
			borderRadius: theme.spacing(0.5),
			padding: theme.spacing(2),
			background: 'rgba(73, 155, 251, 0.05)',
			border: `1px solid ${LIGHT_GREY_COLOR}`,
			display: 'flex',
			flexFlow: 'column',
			justifyContent: 'center',
			justifyItems: 'center',
			alignContent: 'center',
			alignItems: 'center',
			cursor: 'pointer',
		},
		selectNoOption: {
			color: theme.palette.text.primary,
		},
		selectOptions: {
			fontSize: 15,
			'& > span': {
				marginRight: 10,
				fontSize: 18,
			},
		},
		row: {
			display: 'flex',
		},
		centerElHorizontal: {
			justifyContent: 'center',
		},
		centerElVertical: {
			alignItems: 'center',
		},
		noUnderline: {
			textDecoration: 'none',
		},
		full: {
			width: '100%',
		},
		actionDisabled: {
			pointerEvents: 'none',
		},
		cursorPointer: {
			cursor: 'pointer',
		},
		textCenter: {
			textAlign: 'center',
		},
		spaceBetween: {
			justifyContent: 'space-between',
		},
		fieldXs: {
			'& input': {
				paddingTop: 7.5,
				paddingBottom: 9.5,
				fontSize: 13,
			},
		},
		selectXs: {
			'& .MuiSelect-root': {
				paddingTop: 2,
				paddingBottom: 2,
				fontSize: 13,
				lineHeight: 2.3,
			},
		},
		textXs: {
			fontSize: 12,
		},
		cancelBtn: {
			background: 'red',
			minWidth: '85px !important',
		},
		buttonXs: {
			height: '33px !important',
			minWidth: '85px !important',
		},
		center: {
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		capitalize: {
			textTransform: 'capitalize',
			'& *': {
				textTransform: 'capitalize',
			},
		},
		fitContent: {
			height: 'fit-content',
			width: 'fit-content',
		},
		column: {
			flexDirection: 'column',
		},
		gridWrap: {
			minWidth: '400px',
		},
		whiteBtn: {
			backgroundColor: 'var(--white) !important',
			color: 'var(--mainColor) !important',
		},
		paperFormWrap: {
			padding: '30px 0px 30px 40px',
			[theme.breakpoints.down('md')]: {
				padding: '30px'
			},
		}
	}),
);
