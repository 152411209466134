import { StateType } from 'contexts/intialstates/Types';
import React from 'react';
import useApi from './useApi';
import { GlobalContexts } from 'contexts/GlobalContext';
import { MissionOrderById } from 'hooks/useMissionSundayOrder';

export interface MissionSundayPayload {
	start: string;
	end: string;
	materialids?: string;
}

export interface MissionSundayReportProp {
	payload: MissionSundayPayload;
	setMissionSundayReportData: React.Dispatch<React.SetStateAction<StateType<MissionOrderById[]>>>;
}

const useReportActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack, setPendingFiles } = React.useContext(GlobalContexts);

	const getMissionSundayReport = async ({ payload, setMissionSundayReportData }: MissionSundayReportProp) => {
		try {
			setMissionSundayReportData({
				data: null,
				loading: true,
				error: null,
			});

			const response = await api.get(
				`report/missionSunday?start=${payload.start}&end=${payload.end}&materialids=${payload.materialids}`,
			);
			if (response?.data?.result && response.data.result.length === 0) {
				setSimpleErrorSnack({
					message: 'No record found',
					severity: 'info',
					show: true,
				});
				setMissionSundayReportData({
					data: null,
					loading: false,
					error: null,
				});
				return;
			}
			if (response?.data?.statusCode === 0) {
				setMissionSundayReportData({
					data: response?.data?.result,
					loading: false,
					error: null,
				});
			} else {
				setMissionSundayReportData({
					data: null,
					loading: false,
					error: null,
				});
			}
		} catch (error) {
			setMissionSundayReportData({
				data: null,
				loading: false,
				error: null,
			});
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
		}
	};
	const getMissionSundayReportPDF = async (payload: MissionSundayPayload) => {
		try {
			const response = await api.get(
				`report/missionSunday-pdf?start=${payload.start}&end=${payload.end}&materialids=${payload.materialids}`,
			);

			if (response?.data?.statusCode === 0 && response?.data?.result?.id) {
				setPendingFiles((prev) => [...prev, response?.data?.result?.id]);
				setSimpleErrorSnack({
					message: `Download is in progress, we will inform you when it is ready`,
					severity: 'info',
					show: true,
				});
				return true;
			}
		} catch (error) {
			setSimpleErrorSnack({
				message: 'PDF download failed',
				severity: 'error',
				show: true,
			});
		}
	};

	return {
		getMissionSundayReport,
		getMissionSundayReportPDF,
	};
};

export default useReportActions;
