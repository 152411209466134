import React from 'react';
import { Grid, Divider, Paper, Typography, Stack } from '@mui/material';
import { Donor } from 'contexts/intialstates/Types';
import { getDonorEdges } from '../../donor.util';
import { convertDate } from 'helpers/dayAndTime';

export interface IDonorOverview {
	donorInformation?: Donor;
}

interface DonorInfo {
	title: string;
	data: { [key: string]: string };
}

const Overview = ({ donorInformation }: IDonorOverview) => {
	const { appealsEdges, communicationsEdges, specialEdges } = getDonorEdges(donorInformation);

	const donorInfo: DonorInfo[] = [
		{
			title: 'Personal Details',
			data: {
				Name: donorInformation?.orgName
					? donorInformation?.orgName || ''
					: donorInformation?.firstName || donorInformation?.lastName
					? `${donorInformation?.firstName}  ${donorInformation?.lastName}`
					: '-',
				IsOrganization: donorInformation?.isOrganization ? 'Yes' : 'No',
				Organization: donorInformation?.orgName || '-',
				Parish: donorInformation?.parish?.parishName || '-',
				'Date Of Birth': donorInformation?.dateOfBirth ? convertDate(donorInformation?.dateOfBirth) : '-',
			},
		},
		{
			title: 'Contact Details',
			data: {
				Phone: donorInformation?.phone || '-',
				Mobile: donorInformation?.mobile || '-',
				Email: donorInformation?.email || '-',
				Fax: donorInformation?.fax || '-',
			},
		},
		{
			title: 'Address Details',
			data: {
				'Street Line 1': donorInformation?.streetline1 || '-',
				'Street Line 2': donorInformation?.streetline2 || '-',
				City: donorInformation?.city || '-',
				Province: donorInformation?.province?.provinceName || '-',
				'Postal Code': donorInformation?.postalCode || '-',
				Country: donorInformation?.country || '-',
				'Care Of': donorInformation?.careOf || '-',
			},
		},
		{
			title: 'Preferences',
			data: {
				'Receipt Salutation': donorInformation?.customReceiptSalutation || '-',
				'Receipt Type':
					donorInformation?.receiptSchedule?.schedule === 'Yearly'
						? 'Year-End'
						: donorInformation?.receiptSchedule?.schedule || '-',
				'Preferred Society': appealsEdges
					.map((item) => {
						if (item.value) {
							return item.label;
						}
					})
					.filter(Boolean)
					.join(', '),
				'Special Donor': specialEdges
					.map((item) => {
						if (item.value) {
							return item.label;
						}
					})
					.filter(Boolean)
					.join(', '),
				'Donor Type': donorInformation?.donorType || '-',
				Communication: communicationsEdges
					.map((item) => {
						if (item.value) {
							return item.label;
						}
					})
					.filter(Boolean)
					.join(', '),
				Magazine: donorInformation?.magazine ? 'Yes' : 'No',
				'No follow up appeal': donorInformation?.noFollowAppeal ? 'Yes' : 'No',
			},
		},
		{
			title: 'Annuitant Detail',
			data: {
				'Date Of Death': donorInformation?.dateOfDeath ? convertDate(donorInformation.dateOfDeath) : '-',
				'Death Record': donorInformation?.deathReported ? convertDate(donorInformation.deathReported) : '-',
				'Contact Person': donorInformation?.contactName || '-',
			},
		},
	];
	return (
		<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
			{donorInfo.map(({ title, data }, index) => (
				<Grid item xs={12} sm={6} key={index}>
					<Paper sx={{ margin: '10px', padding: '10px' }}>
						<Typography sx={{ fontSize: 14, fontWeight: 600, marginBottom: '5px' }}>{title}</Typography>
						<Divider />
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} p={3}>
							{Object.keys(data).map((item, index) => (
								<Grid item xs={12} sm={6} key={index} mb={2}>
									<Stack direction="column">
										<Typography
											sx={{ fontSize: 12, fontWeight: 600, color: 'var(--grayText)', marginBottom: '5px' }}
										>
											{item}
										</Typography>
										<Typography
											sx={{ fontSize: 14, fontWeight: 400, color: 'var(--fontColorHeaderSubTitle)' }}
										>
											{data[item] || '-'}
										</Typography>
									</Stack>
								</Grid>
							))}
						</Grid>
					</Paper>
				</Grid>
			))}
		</Grid>
	);
};

export default Overview;
