import React from 'react';
import { FilterAltOutlined, KeyboardArrowLeft, Settings } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from 'react-router-dom';
import WalkathonReportComp from './Walkathon';
import DonationsSummary from './DonationsSummary';
import {
	DonationSummaryReport,
	IHCAOrderReport,
	INonCashDonationsReport,
	StateType,
	WalkathonReportData,
} from 'contexts/intialstates/Types';
import HCAOrderReport from './HCAOrder';
import NonCashDonationsReport from './NonCashReport';

export interface FilterProps {
	configure: boolean;
	filter: boolean;
	editFilter: boolean;
	editConfigure: boolean;
}

const Configure = () => {
	const navigate = useNavigate();
	const { reportId } = useParams();
	const [panelControls, setPanelControls] = React.useState<{
		configure: boolean;
		filter: boolean;
	}>({ configure: false, filter: false });
	const [donationsSummaryReport, setDonationsSummaryReport] = React.useState<
		StateType<DonationSummaryReport[]>
	>({
		data: null,
		loading: false,
		error: null,
	});
	const [hcaOrderReport, setHcaOrderReport] = React.useState<StateType<IHCAOrderReport>>({
		data: null,
		loading: false,
		error: null,
	});
	const [walkathonReport, setWalkathonReport] = React.useState<StateType<WalkathonReportData>>({
		loading: false,
		data: null,
		error: null,
	});
	const [filterContols, setFilterControls] = React.useState<FilterProps>({
		configure: true,
		filter: true,
		editFilter: false,
		editConfigure: false,
	});
	const [nonCashDonationsReport, setNonCashDonationReport] = React.useState<
		StateType<INonCashDonationsReport[]>
	>({
		data: null,
		loading: false,
		error: null,
	});

	React.useEffect(() => {
		if (
			donationsSummaryReport.loading ||
			walkathonReport.loading ||
			hcaOrderReport.loading ||
			nonCashDonationsReport.loading
		)
			return;
		if (
			donationsSummaryReport.error ||
			donationsSummaryReport.data ||
			hcaOrderReport.error ||
			hcaOrderReport.data ||
			nonCashDonationsReport.data ||
			nonCashDonationsReport.error
		) {
			setPanelControls({
				configure: true,
				filter: true,
			});
			setFilterControls({
				...filterContols,
				editFilter: true,
				editConfigure: true,
			});
		}
		if (walkathonReport.data || walkathonReport.error) {
			setPanelControls({
				configure: true,
				filter: false,
			});
		}
		if (
			(donationsSummaryReport.data && donationsSummaryReport.data.length !== 0) ||
			(hcaOrderReport.data && hcaOrderReport.data.grandTotalOrders !== 0) ||
			(nonCashDonationsReport.data && nonCashDonationsReport.data.length !== 0) ||
			(walkathonReport.data && walkathonReport.data?.total !== 0)
		)
			setFilterControls({
				configure: false,
				filter: false,
				editFilter: true,
				editConfigure: true,
			});
	}, [donationsSummaryReport, walkathonReport, hcaOrderReport, nonCashDonationsReport]);

	const currReport = () => {
		if (reportId === 'hca-order-activity') {
			return {
				label: 'HCA Order Activity',
				element: (
					<HCAOrderReport
						hcaOrderReport={hcaOrderReport}
						setHcaOrderReport={setHcaOrderReport}
						setFilterControls={setFilterControls}
						filterContols={filterContols}
					/>
				),
			};
		}
		if (reportId === 'non-cash-donations') {
			return {
				label: 'Non Cash Donations',
				element: (
					<NonCashDonationsReport
						nonCashDonationsReport={nonCashDonationsReport}
						setNonCashDonationReport={setNonCashDonationReport}
						setFilterControls={setFilterControls}
						filterContols={filterContols}
					/>
				),
			};
		}

		if (reportId === 'walkathon-report') {
			return {
				label: 'Walkathon Report',
				element: (
					<WalkathonReportComp
						walkathonReport={walkathonReport}
						setWalkathonReport={setWalkathonReport}
						setFilterControls={setFilterControls}
						filterContols={filterContols}
					/>
				),
			};
		}

		return;
	};

	return (
		<Box sx={{ background: 'transparent' }}>
			<Stack
				direction={{ xs: 'row', sm: 'column' }}
				alignItems={{ xs: 'center', sm: 'initial' }}
				justifyContent={{ xs: 'center', sm: 'initial' }}
				spacing={{ xs: 3, sm: 0 }}
				sx={{ mb: 3 }}
			>
				<Stack
					alignItems={'center'}
					justifyContent={'center'}
					sx={{ display: { xs: 'flex', sm: 'none' }, background: '#fff' }}
				>
					<IconButton
						id="demo-customized-button"
						aria-haspopup="true"
						onClick={() => {
							navigate(-1);
						}}
					>
						<KeyboardArrowLeft color="primary" />
					</IconButton>
				</Stack>
				<Stack
					alignItems="center"
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent={'space-between'}
					spacing={1}
					width="100%"
				>
					<Stack
						alignItems={'center'}
						direction="row"
						justifyContent={'flex-start'}
						spacing={{ xs: 0, sm: 4 }}
						mb={{ xs: 1, sm: 0 }}
						width="100%"
					>
						<Stack
							alignItems={'center'}
							justifyContent={'center'}
							sx={{ display: { xs: 'none', sm: 'flex' }, background: '#fff' }}
						>
							<IconButton
								id="demo-customized-button"
								aria-haspopup="true"
								onClick={() => {
									navigate(-1);
								}}
							>
								<KeyboardArrowLeft color="primary" />
							</IconButton>
						</Stack>
						<Stack direction="row" justifyContent={'space-between'} width="100%">
							<Box
								sx={{
									fontSize: '32px',
									fontWeight: 700,
									color: 'var(--mainColor)',
									wordWrap: 'normal',
								}}
							>
								{currReport()?.label}
							</Box>
							<Stack direction="row" alignItems="center" spacing={1}>
								{panelControls.filter && (
									<IconButton
										id="demo-customized-button"
										sx={{
											background: 'white',
											borderRadius: '6px',
											width: 40,
											height: 30,
											border: '1px solid var(--mainColor)',
										}}
										aria-haspopup="true"
										onClick={() =>
											setFilterControls({
												...filterContols,
												filter: !filterContols.filter,
												configure: false,
												editFilter: true,
											})
										}
									>
										<FilterAltOutlined sx={{ fontSize: 16, color: 'var(--mainColor)' }} />
									</IconButton>
								)}
								{panelControls.configure && (
									<IconButton
										id="demo-customized-button"
										sx={{ background: 'var(--mainColor)', borderRadius: '6px', width: 40, height: 30 }}
										aria-haspopup="true"
										onClick={() =>
											setFilterControls({
												...filterContols,
												configure: !filterContols.configure,
												filter: false,
												editConfigure: true,
											})
										}
									>
										<Settings sx={{ fontSize: 16, color: 'white' }} />
									</IconButton>
								)}
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</Stack>

			{currReport()?.element}
		</Box>
	);
};

export default Configure;
