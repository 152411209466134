import React, { useEffect, useState } from 'react';
import { Deposits, DepositData, StateType, OrderType, FilterPayload } from 'contexts/intialstates/Types';
import { Skeleton, Stack, Typography } from '@mui/material';
import { GlobalContexts } from 'contexts/GlobalContext';
import useHCADonations from 'hooks/useHCADonationActions';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { ColumnType, hacDonationTableHead } from 'Components/Common/Tables/TableHeaders';
import HcaDonationsList from './List';
import { GenerateAcknowledgment } from 'Components/Reusable/GenerateReceipt/GenerateReceipt';
import useDebounce from 'Components/Reusable/hooks/debounce';

export const ButtonSkeleton = ({ length }: { length: number }) => {
	return (
		<>
			<Stack direction="row">
				{[...Array.from({ length })].map((i, index) => (
					<Stack mr={index === 2 ? 0 : 2} key={index}>
						<Skeleton width={89} height={60} />
					</Stack>
				))}
			</Stack>
		</>
	);
};

export const HCADonation = () => {
	const [allDeposit, setAllDeposit] = React.useState<StateType<DepositData>>({
		loading: false,
		error: null,
		data: null,
	});
	const { setErrorSnack } = React.useContext(GlobalContexts);
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);
	const [searchDonor, setSearchDonor] = React.useState<string>('');
	const debouncedSearchTerm = useDebounce(searchDonor, 1000);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [reload, setIsReload] = React.useState(false);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const { getAllDeposit } = useHCADonations();
	const [validDeposits, setValidDeposits] = useState<number>();

	React.useEffect(() => {
		getAllDeposit({
			pageNumber: page,
			sizeOfPage: pageSize,
			donorQuery: searchDonor,
			setAllDeposit,
			allDeposit,
			setErrorSnack,
			orderBy,
			filterPayload,
		});
	}, [debouncedSearchTerm, page, pageSize, orderBy, reload]);

	useEffect(() => {
		setValidDeposits(
			allDeposit?.data?.body.findIndex(
				(deposit: any) => deposit?.deposit?.itemStatus?.status === 'Incomplete',
			) as number,
		);
	}, [allDeposit?.data?.body]);

	return (
		<>
			<>
				<Stack
					alignItems="center"
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent={'space-between'}
					spacing={1}
					mb={5}
				>
					<Stack direction="column">
						<Stack direction="row" spacing={4} alignItems="center">
							<Typography variant="h6" sx={{ color: 'var(--mainColor)' }}>
								HCA Acknowledgment
							</Typography>
						</Stack>
						<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
							Add and manage HCA Acknowledgment here
						</Typography>
					</Stack>
					<Stack sx={{ mb: 3 }} direction="row" justifyContent="flex-end">
						<TrimTables
							name={'hacDonationTableHead'}
							list={hacDonationTableHead}
							trimList={trimList}
							setTrimList={setTrimList}
						/>
						{Number.isInteger(validDeposits) ? (
							<Stack direction="row-reverse" spacing={1} alignItems="center">
								<FilterListButton
									setIsReload={setIsReload}
									reload={reload}
									page={page}
									setPage={setPage}
									setFilterPayload={setFilterPayload}
									filterPayload={filterPayload}
								/>

								<GenerateAcknowledgment
									validDeposits={validDeposits as number}
									deposits={allDeposit?.data?.body as Deposits[]}
								/>
							</Stack>
						) : (
							<ButtonSkeleton length={1} />
						)}
					</Stack>
				</Stack>
				<HcaDonationsList
					depoInfo={allDeposit}
					setIsReload={setIsReload}
					reload={reload}
					page={page}
					setPageSize={setPageSize}
					pageSize={pageSize}
					setPage={setPage}
					loading={allDeposit?.loading}
					label={label}
					orderType={orderType}
					setLabel={setLabel}
					setOderBy={setOderBy}
					setOrderType={setOrderType}
					setFilterPayload={setFilterPayload}
					filterPayload={filterPayload}
					searchDonor={searchDonor}
					setSearchDonor={setSearchDonor}
					trimList={trimList}
					setTrimList={setTrimList}
				/>
			</>
		</>
	);
};

export default HCADonation;
