import React, { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, Stack, Paper, Button, Divider } from '@mui/material';
import { Archive, ArrowBackIosNew, RestoreFromTrash, Save } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useStyles } from '../School.style';
import CreateEditSchoolForm, { SchoolValues } from './CreateEditSchoolForm';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import DonationTable, { DonationType } from 'Components/DonationTable';
import useSchoolActions from 'hooks/useSchoolActions';
import LoadingButton from '@mui/lab/LoadingButton';
import { School, StateType } from 'contexts/intialstates/Types';
import { styles } from 'Components/Donors/DonorInfo/DonorInfo.style';
import NotesSchool from '../NotesSchool';

const CreateEditSchoolTab = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const { id } = useParams();

	const [tabValue, setTabValue] = useState('overview');
	const [isDelete, setIsDelete] = useState(false);
	const [reload, setReload] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const { deleteSchool, restoreSchool, getSchoolsByID, createSchool, updateSchool } = useSchoolActions();
	const [loading, setLoading] = useState<boolean>(false);
	const [formData, setFormData] = useState<SchoolValues>({
		name: '',
		districtCode: '',
		principal: '',
		contact: '',
		seal: '',
		streetline1: '',
		streetline2: '',
		city: '',
		province: '',
		postalCode: '',
		phone: '',
		fax: '',
		email: '',
		useEmail: false,
		useEFile: false,
		dioceseId: undefined,
		schoolBoardId: undefined,
		schoolStatusId: undefined,
		country: '',
	});
	const [school, setSchool] = React.useState<StateType<School>>({
		data: null,
		error: null,
		loading: false,
	});

	const [formErrors, setFormErrors] = useState<string[]>([]);

	const handleChange = (event: any, value: string) => {
		setTabValue(value);
	};

	const handleToggleDelete = () => {
		setIsDelete(false);
	};

	const handleDelete = async () => {
		if (!id) return;
		if (school.data?.archived) {
			setDeleteLoading(true);
			const isSucess = await restoreSchool(parseInt(id));
			setDeleteLoading(false);
			handleToggleDelete();
			if (isSucess) {
				setReload(!reload);
			}
			return;
		}
		setDeleteLoading(true);
		const isSucess = await deleteSchool(parseInt(id));
		setDeleteLoading(false);
		handleToggleDelete();
		if (isSucess) {
			navigate('/hca/schools');
		}
	};

	const handleSubmit = async () => {
		const errors = [];
		if (!formData.name) {
			errors.push('name');
		}
		if (!formData.dioceseId) {
			errors.push('dioceseId');
		}
		if (!formData.principal) {
			errors.push('principal');
		}

		if (!formData.schoolStatusId) {
			errors.push('schoolStatusId');
		}
		setFormErrors(errors);
		if (errors.length) return;

		let isSucess;
		setLoading(true);
		if (id) {
			isSucess = await updateSchool(formData, parseInt(id));
		} else {
			isSucess = await createSchool(formData);
		}
		setLoading(false);
		if (isSucess) {
			navigate('/hca/schools');
		}
	};

	useEffect(() => {
		if (!id) return;
		getSchoolsByID({ setSchool, school, id: parseInt(id) });
	}, [id, reload]);
	return (
		<Stack spacing={3}>
			<Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap={'wrap'}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={4}
					marginBottom={2}
				>
					<Stack className={classes.backIconWrapper}>
						<ArrowBackIosNew onClick={() => navigate(-1)} />
					</Stack>
					<Stack alignItems={'flex-start'}>
						<span className={classes.title}>School</span>
						<span className={classes.subTitle}></span>
						{id && (
							<Button sx={styles.statusBtn} disableElevation disableRipple variant="outlined">
								{school.data?.archived ? 'Archived' : 'Active'}
							</Button>
						)}
					</Stack>
				</Stack>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems={'center'}
					spacing={2}
					marginBottom={2}
				>
					{id && (
						<Button
							sx={{ textTransform: 'none' }}
							startIcon={school.data?.archived ? <RestoreFromTrash /> : <Archive />}
							variant="contained"
							onClick={() => setIsDelete(true)}
						>
							{school.data?.archived ? 'Unarchived' : 'Archive'}
						</Button>
					)}
					{tabValue === 'overview' && (
						<LoadingButton
							sx={{ textTransform: 'none' }}
							startIcon={<Save />}
							variant="contained"
							loading={loading}
							loadingPosition="start"
							onClick={handleSubmit}
						>
							Save
						</LoadingButton>
					)}
				</Stack>
			</Stack>
			<Paper sx={{ padding: 2 }}>
				<TabContext value={tabValue}>
					<TabList onChange={handleChange}>
						<Tab label="Details" value="overview" />
						{id && <Tab label="Donation" value="donation" />}
						{id && <Tab label="Notes" value="notes" />}
					</TabList>
					<Divider />
					<TabPanel value="overview">
						<CreateEditSchoolForm
							initial={formData}
							setInitial={setFormData}
							formErrors={formErrors}
							setFormErrors={setFormErrors}
						/>
					</TabPanel>
					{id && (
						<TabPanel value="donation">
							<DonationTable id={parseInt(id)} type={DonationType.School} />
						</TabPanel>
					)}
					{id && (
						<TabPanel value="notes">
							<NotesSchool schoolId={parseInt(id)} />
						</TabPanel>
					)}
				</TabContext>
			</Paper>
			{isDelete && (
				<ConfirmationModal
					handleClose={handleToggleDelete}
					message={
						school.data?.archived
							? 'Do you want to unarchive this school?'
							: 'Are you sure you want to archive this School?'
					}
					open={isDelete}
					handleConfirmation={handleDelete}
					handleCancel={handleToggleDelete}
					loading={deleteLoading}
				/>
			)}
		</Stack>
	);
};

export default CreateEditSchoolTab;
