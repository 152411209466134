import { ColumnType } from 'Components/Common/Tables/TableHeaders';

export const depositSlipHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'id',
		label: 'Donation Slip #',
		minWidth: 200,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'society',
		label: 'Society',
		minWidth: 200,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'an' }, { name: 'sp' }, { name: 'hc' }, { name: 'pf' }],
	},
	{
		id: 'totalAmount',
		label: 'Total Amount',
		minWidth: 200,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'bankDepositDate',
		label: 'Bank Deposit Date',
		minWidth: 200,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'status',
		label: 'Status',
		minWidth: 170,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'Pending' }, { name: 'Printed' }, { name: 'Posted' }],
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
];
