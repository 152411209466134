import React, { useEffect, useState } from 'react';
import {
	Stack,
	Button,
	Box,
	TextField,
	Autocomplete,
	IconButton,
	Divider,
	InputAdornment,
} from '@mui/material';
import { DeleteOutline, Search, Edit } from '@mui/icons-material';
import CustomAccordian from 'Components/Common/Accordian/Accordian';
import { SchoolDataType } from './CreateWalkathon';
import useSchoolActions from 'hooks/useSchoolActions';
import { StateType, School, SchoolData, OrderType } from 'contexts/intialstates/Types';
import useDebounce from 'Components/Reusable/hooks/debounce';
import WalkathonAccordianContent from './WalkathonAccordianContent';
import { useStyles } from '../Walkathon.style';
import WalkathonAdvancedSearch, {
	Field,
} from 'Components/Reusable/Modals/AdvancedSearch/AdvancedSearchModal';
import { serachSchoolField } from '../../Reusable/Modals/AdvancedSearch/Mock';
import SchoolTable from 'Components/AdminPanel/School/SchoolTable';
import { ColumnType, schoolHead } from 'Components/Common/Tables/TableHeaders';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { GlobalContexts } from 'contexts/GlobalContext';

interface WalkathonAccordianProp {
	accordianData: SchoolDataType;
	handleAddSchool: (data: SchoolDataType) => void;
	handleSetSchoolEdit: (key: string) => void;
	handleRemoveSchool: (key: string) => void;
	handleAddDonor: (key: string) => void;
	handleChangeDonor: (
		schoolKey: string,
		donorKey: string,
		control: string,
		value: number,
		name?: string,
	) => void;
	handleDonorDelete: (schoolKey: string, donorKey: string) => void;
	handleToggleExpand: (key: string) => void;
	isDisabled: boolean;
}
const WalkathonAccordian = ({
	accordianData,
	handleAddSchool,
	handleSetSchoolEdit,
	handleRemoveSchool,
	handleAddDonor,
	handleChangeDonor,
	handleDonorDelete,
	handleToggleExpand,
	isDisabled,
}: WalkathonAccordianProp) => {
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);
	const classes = useStyles();
	const { getSchoolsSearch, getSchools } = useSchoolActions();
	const [schoolSearch, setSchoolSearch] = useState<StateType<School[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [searchTerm, setSearchTerm] = useState('');
	const [schoolInput, setSchoolInput] = useState({
		schoolName: '',
		schoolId: 0,
		schoolAmount: '',
	});
	const debouncedSearchTerm = useDebounce(searchTerm, 1000);
	const [visibleSchoolSearch, setVisibleSchoolSearch] = useState({
		state: false,
		data: {
			organizationName: '',
			organizationId: 0,
		},
	});
	const [searchFields, setSearchFields] = useState<Field[]>(serachSchoolField);
	const [schoolsTable, setSchoolsTable] = useState<StateType<SchoolData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [filterPayload, setFilterPayload] = useState<any>([]);
	const [orderType, setOrderType] = useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const [donorTotal, setDonorTotal] = useState(0);
	useEffect(() => {
		if (!accordianData.donor.length) {
			setDonorTotal(0);
		} else {
			const totalVal = accordianData.donor
				.map((e) => (e.donorAmount ? parseFloat(e.donorAmount) : 0))
				.reduce((total, curr) => total + curr, 0);
			setDonorTotal(totalVal);
		}
	}, [accordianData.donor]);

	useEffect(() => {
		if (!visibleSchoolSearch.state) return;
		getSchools({
			schools: schoolsTable,
			setSchools: setSchoolsTable,
			page,
			pageSize,
			orderBy,
			filterPayload,
		});
	}, [visibleSchoolSearch.state, page, pageSize, orderBy, filterPayload.length]);

	useEffect(() => {
		if (!debouncedSearchTerm) return;
		getSchoolsSearch({ schoolSearch, setSchoolSearch, searchTerm: debouncedSearchTerm });
	}, [debouncedSearchTerm]);

	useEffect(() => {
		if (!Object.keys(visibleSchoolSearch.data).length) return;
		setSchoolInput((prevState) => {
			return {
				...prevState,
				schoolName: visibleSchoolSearch.data.organizationName,
				schoolId: visibleSchoolSearch.data.organizationId,
			};
		});
	}, [visibleSchoolSearch.data]);

	const handleVisibleSearch = () => {
		setVisibleSchoolSearch((prevState) => {
			return {
				...prevState,
				state: !visibleSchoolSearch.state,
			};
		});
	};

	const handleSchoolChange = (control: string, value: number, name?: string) => {
		setSchoolInput((prevState) => {
			return Object.assign(
				{
					...prevState,
					[control]: value,
				},
				name && { schoolName: name },
			);
		});
	};

	const onAddSchool = () => {
		handleAddSchool({
			...accordianData,
			schoolName: schoolInput.schoolName ? schoolInput.schoolName : accordianData.schoolName,
			schoolId: schoolInput?.schoolId ? schoolInput?.schoolId : accordianData.schoolId,
			schoolAmount: schoolInput.schoolAmount ? schoolInput.schoolAmount : accordianData.schoolAmount,
		});
	};

	const header = ({ schoolName, schoolAmount, isEdit }: SchoolDataType) => {
		useEffect(
			() =>
				setSchoolInput({
					...schoolInput,
					schoolAmount,
				}),
			[schoolAmount],
		);
		return (
			<Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
				<Stack flexDirection={'row'} alignItems={'center'}>
					{isEdit ? (
						<Stack flexDirection={'row'} alignItems={'center'}>
							<Stack flexDirection={'row'} alignItems={'center'} mr={4}>
								<Autocomplete
									freeSolo
									disabled={isDisabled}
									value={schoolInput.schoolName ? schoolInput.schoolName : schoolName ? schoolName : ''}
									sx={{ width: 250 }}
									onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
										handleSchoolChange('schoolId', val?.id, val?.label);
									}}
									options={
										schoolSearch?.data
											? schoolSearch?.data?.map((school: any) => ({
													id: school.id,
													label: school.name,
											  }))
											: []
									}
									renderOption={(props, option) => (
										<Box component="li" {...props} key={option.id}>
											{option.label}
										</Box>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											label=""
											InputProps={{
												...params.InputProps,
											}}
											onChange={(event: any) => {
												setSearchTerm(event.target.value);
											}}
											size={'small'}
											placeholder={'Search school'}
										/>
									)}
								/>
								<div className={classes.IconBtn} onClick={handleVisibleSearch}>
									<Search />
								</div>
							</Stack>
							<TextField
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
								}}
								variant="outlined"
								label=""
								value={schoolInput?.schoolAmount || null}
								onChange={(event: any) => {
									handleSchoolChange('schoolAmount', event.target.value);
								}}
								size={'small'}
								sx={{ width: 150 }}
								placeholder={'Enter amount'}
								disabled={isDisabled}
							/>
						</Stack>
					) : (
						<Stack direction={'row'} spacing={2} justifyContent="flex-start">
							<Box className={classes.schoolHead}>{schoolName}</Box>
							<Stack direction="column" alignItems={'center'} spacing={2}>
								<Box className={classes.schoolHead1}>Total Amount</Box>
								<Box>${parseFloat(schoolAmount)?.toFixed(2)}</Box>
							</Stack>
							<Stack direction="column" alignItems={'center'} spacing={2}>
								<Box className={classes.schoolHead1}> Allocated Amount</Box>
								<Box>${donorTotal?.toFixed(2)}</Box>
							</Stack>
							<Stack direction="column" alignItems={'center'} spacing={2}>
								<Box className={classes.schoolHead1}> Amount Donated by School</Box>
								<Box>${(parseFloat(schoolAmount) - donorTotal)?.toFixed(2)}</Box>
							</Stack>
						</Stack>
					)}
				</Stack>
				<Stack flexDirection={'row'} alignItems={'center'}>
					{!isEdit && (
						<IconButton disabled={isDisabled} onClick={() => handleSetSchoolEdit(accordianData.key)}>
							<Edit />
						</IconButton>
					)}
					<IconButton disabled={isDisabled} onClick={() => handleRemoveSchool(accordianData.key)}>
						<DeleteOutline />
					</IconButton>
					<Button
						sx={{ background: 'var(--white)' }}
						size={'small'}
						variant="outlined"
						onClick={() => (isEdit ? onAddSchool() : handleAddDonor(accordianData.key))}
						disabled={isDisabled}
					>
						{isEdit ? 'Save' : 'Add Donor'}
					</Button>
				</Stack>
			</Stack>
		);
	};

	const content = ({ donor }: SchoolDataType) => {
		return (
			<>
				{donor?.length ? (
					<>
						<Stack flexDirection={'row'} sx={{ margin: '10px' }}>
							<div className={classes.donorNameHead}>Donor Name</div>
							<div className={classes.donorHead}>Amount</div>
							<div className={classes.donorHead}>Receipt Number</div>
						</Stack>
						<Divider />
					</>
				) : null}
				{donor?.length
					? donor.map((donorData) => (
							<>
								<Stack key={donorData.key} sx={{ margin: '10px' }}>
									<WalkathonAccordianContent
										handleChangeDonor={handleChangeDonor}
										donorData={donorData}
										schoolKey={accordianData.key}
										handleDonorDelete={handleDonorDelete}
										isDisabled={isDisabled}
									/>
								</Stack>
								<Divider />
							</>
					  ))
					: null}
			</>
		);
	};

	const handleSearch = () => {
		const payload: any = searchFields.map(({ key, value }) => {
			if (value) {
				return {
					field: key,
					query: key !== 'phone' ? value : null,
					value: key === 'phone' ? [value] : null,
					from: null,
					to: null,
				};
			}
		});
		setFilterPayload(payload.filter(Boolean));
	};

	return (
		<>
			<CustomAccordian
				header={header(accordianData)}
				content={content(accordianData)}
				schoolKey={accordianData.key}
				handleToggleExpand={handleToggleExpand}
				expandValue={accordianData.expand}
			/>
			{visibleSchoolSearch.state && (
				<WalkathonAdvancedSearch
					visible={visibleSchoolSearch.state}
					handleClose={handleVisibleSearch}
					field={searchFields}
					setField={setSearchFields}
					title={'Search School'}
					handleSearch={handleSearch}
				>
					<>
						<Stack alignItems={'flex-end'}>
							<TrimTables
								name={'schoolHead'}
								list={schoolHead}
								trimList={trimList}
								setTrimList={setTrimList}
							/>
						</Stack>
						<SchoolTable
							trimList={trimList}
							setTrimList={setTrimList}
							page={page}
							pageSize={pageSize}
							setPage={setPage}
							setPageSize={setPageSize}
							label={label}
							orderType={orderType}
							setLabel={setLabel}
							setOderBy={setOderBy}
							setOrderType={setOrderType}
							schools={schoolsTable}
							hcaOrgList
							open={visibleSchoolSearch}
							setOpen={setVisibleSchoolSearch}
						/>
					</>
				</WalkathonAdvancedSearch>
			)}
		</>
	);
};

export default WalkathonAccordian;
