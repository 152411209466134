import React, { useContext, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Drawer } from '@mui/material';
import Header from 'Components/Header/Header';
import SideMenu from 'Components/Reusable/SideMenu/SideMenu';
import { useStyles } from './Layout.style';
import Logo from '../../assets/images/logo.png';
import { useLocation } from 'react-router-dom';
import { SimpleSnackbar } from 'Components/Common/ErrorSnack/ErrorSnack';
import { Box, Stack } from '@mui/material';
import { GlobalContexts } from 'contexts/GlobalContext';
import useUserActions from 'hooks/useUserActions';
import useDownloads from 'hooks/useDownloads';

const Layout = ({ children }: { children: React.ReactNode }) => {
	const classes = useStyles();
	const location = useLocation();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const { getCurrentUser } = useUserActions();
	const { currentUser, setCurrentUser, pendingFiles } = useContext(GlobalContexts);
	const { getFile } = useDownloads();
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	useEffect(() => {
		getCurrentUser({ currentUser, setCurrentUser });
	}, []);

	useEffect(() => {
		if (!pendingFiles.length) return;
		const interval = process.env.REACT_APP_LARGE_FILE_DOWNLOAD_INTERVAL
			? parseInt(process.env.REACT_APP_LARGE_FILE_DOWNLOAD_INTERVAL)
			: 30;
		const intervalId = setInterval(() => {
			pendingFiles.map((file, index) => {
				setTimeout(() => getFile(file), index * 2000);
			});
		}, interval * 1000);

		return () => clearInterval(intervalId);
	}, [pendingFiles.length]);

	return (
		<>
			<Box sx={{ height: '100%' }}>
				<CssBaseline />
				<Header mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
				<Drawer
					variant="temporary"
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true,
					}}
					sx={{
						flexShrink: 0,
						display: { xs: 'block', sm: 'none' },
					}}
					classes={{
						paper: classes.drawerPaper,
					}}
				>
					<SideMenu />
				</Drawer>
				<Drawer
					variant="permanent"
					sx={{
						flexShrink: 0,
						display: { xs: 'none', sm: 'block' },
						width: 100,
					}}
					classes={{
						paper: classes.drawerPaper,
					}}
				>
					<SideMenu />
				</Drawer>
				<main style={{ background: '#e5e5e5', minHeight: '100%' }}>
					<Stack
						sx={{
							padding: '30px 50px 30px 120px',
							pl: { xs: 2, sm: '120px', md: '150px' },
							pr: { xs: 2, sm: '30px', md: '50px' },
							pt: { xs: 10, sm: '100px' },
							pb: { xs: 1, sm: '30px', md: '30px' },
						}}
					>
						{location.pathname === '/' && (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									minHeight: 'calc(100vh - 170px)',
									opacity: '0.2',
								}}
							>
								<img src={Logo} alt="logo" height={600} />
							</div>
						)}
						{children}
					</Stack>
				</main>
			</Box>
			<SimpleSnackbar />
		</>
	);
};

export default Layout;
