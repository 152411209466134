import React, { useState, useEffect } from 'react';
import { Button, Stack, TablePagination, Tooltip, Paper } from '@mui/material';
import { pagination } from 'Components/Common/Tables/Tables';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { Add, OpenInNew } from '@mui/icons-material';
import useRelativesActions, { RelativeData } from 'hooks/useRelativesActions';
import { OrderType, StateType } from 'contexts/intialstates/Types';
import { sortTable } from 'Components/Common/Tables/sortTable';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import { convertDate } from 'helpers/dayAndTime';
import CreateEditRelatives from './CreateEditRelatives';
import { useNavigate } from 'react-router-dom';
import useUserActions from 'hooks/useUserActions';

interface P {
	donorId: number;
}

const relativeHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'firstName',
		label: 'First Name',
		minWidth: 200,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'lastName',
		label: 'Last Name',
		minWidth: 200,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'relation',
		label: 'Relation',
		minWidth: 200,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'email',
		label: 'Email',
		minWidth: 200,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'phoneNumber',
		label: 'Phone Number',
		minWidth: 200,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'address',
		label: 'Address',
		minWidth: 250,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
];

const Relatives = ({ donorId }: P) => {
	const { handleRoles } = useUserActions();
	const navigate = useNavigate();
	const { getRelatives } = useRelativesActions();
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [filterPayload, setFilterPayload] = useState<Array<any>>([]);
	const [reload, setReload] = useState(false);
	const [relatives, setRelatives] = useState<StateType<RelativeData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [page, setPage] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(10);
	const [orderType, setOrderType] = useState<OrderType>('asc');
	const [label, setLabel] = useState<string>('');
	const [orderBy, setOderBy] = useState<string>('');
	const [open, setOpen] = useState(false);
	const [selectedData, setSelectedData] = useState();

	useEffect(() => {
		getRelatives({ relatives, setRelatives, page, pageSize, filterPayload, orderBy, donorId });
	}, [page, pageSize, reload, orderBy]);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType) => {
			return {
				...column,
				data: (
					<>
						<Tooltip key={column.id} title={`Sort by ${column.label}`}>
							<span
								style={{ cursor: 'pointer' }}
								onClick={() =>
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									})
								}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							reload={reload}
							setIsReload={setReload}
							setPage={setPage}
							page={page}
						/>
					</>
				),
			};
		});
	};

	const getTableData = () => {
		return relatives?.data?.body?.length
			? relatives?.data.body.map((row: any, index: number) => {
					return {
						's/n': page * pageSize + index + 1,
						id: row?.id,
						firstName: row?.relatedDonorId ? (
							<Stack
								sx={{ flexDirection: 'row', alignItems: 'center', display: 'flex', justifyContent: 'center' }}
							>
								{row?.relatedDonorId === donorId ? row?.donor?.firstName : row?.firstName}
								<Stack
									sx={{
										'& svg': {
											color: '#1976D2',
										},
									}}
									onClick={() => {
										navigate(
											`/donors/${row?.relatedDonorId === donorId ? row?.donor.id : row?.relatedDonorId}`,
										);
									}}
								>
									<OpenInNew fontSize={'small'} />
								</Stack>
							</Stack>
						) : (
							row?.firstName
						),
						lastName: row?.relatedDonorId === donorId ? row?.donor?.lastName : row?.lastName,
						relation: row?.relation,
						relatedDonorId: row?.relatedDonorId,
						email: row?.email || '',
						phoneNumber: row?.phoneNumber || '',
						address: row?.address || '',
						created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
						createdBy: row?.createdBy?.name || 'N/A',
						updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
						updatedBy: row?.updatedBy?.name || 'N/A',
					};
			  })
			: [];
	};

	const handleToggle = () => {
		setOpen(!open);
		if (open) {
			setSelectedData(undefined);
		}
	};

	const handleEdit = (row: any) => {
		if (!handleRoles('donor-actions')) return;
		if (row?.relatedDonorId === donorId) return;
		setOpen(!open);
		setSelectedData({
			...row,
			firstName: relatives?.data?.body.find((item) => item.id === row.id)?.firstName ?? '',
		});
	};

	return (
		<>
			<Stack sx={{ mb: 3 }} direction="row" alignItems={'center'} justifyContent="flex-end">
				<TrimTables name="relativeHead" list={relativeHead} trimList={trimList} setTrimList={setTrimList} />
				{handleRoles('donor-actions') && (
					<Button
						sx={{ textTransform: 'none', mr: 2 }}
						onClick={handleToggle}
						variant="contained"
						startIcon={<Add />}
					>
						Add
					</Button>
				)}
				<FilterListButton
					setFilterPayload={setFilterPayload}
					filterPayload={filterPayload}
					reload={reload}
					setIsReload={setReload}
					setPage={setPage}
					page={page}
				/>
			</Stack>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<CustomTable
					loading={relatives?.loading}
					columnData={getColumnData()}
					tableData={getTableData()}
					handleDrag={handleDrag}
					handleResize={handleResize}
					emptyMessage={'No relatives available'}
					handleRowClick={(row) => handleEdit(row as any)}
				/>
				{(relatives?.data?.body.length as number) > 0 && (
					<TablePagination
						rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
						count={relatives?.data?.totalRecords as number}
						rowsPerPage={pageSize}
						page={page}
						sx={pagination}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
			</Paper>
			{open && (
				<CreateEditRelatives
					visible={open}
					donorId={donorId}
					handleClose={handleToggle}
					reload={reload}
					setReload={setReload}
					selectedData={selectedData}
				/>
			)}
		</>
	);
};

export default Relatives;
