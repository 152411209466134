import React, { useState, useEffect } from 'react';
import { Button, Stack, TablePagination, Tooltip, Paper, Typography } from '@mui/material';
import { pagination } from 'Components/Common/Tables/Tables';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { Add, Print } from '@mui/icons-material';
import useMissionSundayOrderActions, { MissionOrderData } from 'hooks/useMissionSundayOrder';
import { OrderType, StateType, Parish } from 'contexts/intialstates/Types';
import { sortTable } from 'Components/Common/Tables/sortTable';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import { convertDate } from 'helpers/dayAndTime';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import { useStyles } from './CreateEditMissionOrder/MissionOrder.style';

const missionOrderHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'id',
		label: 'Order ID',
		minWidth: 200,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'parishName',
		label: 'Parish',
		minWidth: 200,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'address',
		label: 'Address',
		minWidth: 200,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'receivedDate',
		label: 'Order received date',
		minWidth: 200,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
];

const MissionSundayOrder = () => {
	const navigate = useNavigate();
	const classes = useStyles();
	const { getMissionOrder, exportLabels } = useMissionSundayOrderActions();
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [filterPayload, setFilterPayload] = useState<Array<any>>([]);
	const [reload, setReload] = useState(false);
	const [missionOrder, setMissionOrder] = useState<StateType<MissionOrderData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [page, setPage] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(10);
	const [orderType, setOrderType] = useState<OrderType>('asc');
	const [label, setLabel] = useState<string>('');
	const [orderBy, setOderBy] = useState<string>('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getMissionOrder({ missionOrder, setMissionOrder, page, pageSize, filterPayload, orderBy });
	}, [page, pageSize, reload, orderBy]);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType) => {
			return {
				...column,
				data: (
					<>
						<Tooltip key={column.id} title={`Sort by ${column.label}`}>
							<span
								style={{ cursor: 'pointer' }}
								onClick={() =>
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									})
								}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							reload={reload}
							setIsReload={setReload}
							setPage={setPage}
							page={page}
						/>
					</>
				),
			};
		});
	};

	const getAddress = (parish: Parish) => {
		const { streetline1, streetline2, province, city, postalCode } = parish;
		return !streetline1 && !streetline2 && !province && !city && !postalCode
			? 'N/A'
			: `${streetline1 ?? ''} ${streetline2 ?? ''} ${province?.provinceName ?? ''} ${city ?? ''} ${
					postalCode ?? ''
			  }`;
	};

	const getTableData = () => {
		return missionOrder?.data?.body?.length
			? missionOrder?.data.body.map((row: any, index: number) => {
					return {
						's/n': page * pageSize + index + 1,
						id: row?.id,
						parishName: row?.parish?.parishName || 'N/A',
						address: row?.parish ? getAddress(row?.parish) : 'N/A',
						receivedDate: row?.receivedDate ? `${convertDate(row?.receivedDate)}` : 'N/A',
						created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
						createdBy: row?.createdBy?.name || 'N/A',
						updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
						updatedBy: row?.updatedBy?.name || 'N/A',
					};
			  })
			: [];
	};

	const handleEdit = (row: any) => {
		navigate(`${row.id}`);
	};

	const handleExport = async () => {
		setLoading(true);
		await exportLabels();
		setLoading(false);
	};

	return (
		<>
			<Stack direction={'row'} justifyContent={'space-between'} mb={3}>
				<Stack direction={'column'}>
					<Typography variant="h5" sx={{ color: '#1976D2' }}>
						Mission Sunday Order
					</Typography>
					<Stack>Add and Manage Mission Sunday Orders by Perishes.</Stack>
				</Stack>
				<Stack direction={'row'} alignItems={'center'}>
					<TrimTables
						name="missionOrderHead"
						list={missionOrderHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
					<LoadingButton
						sx={{ textTransform: 'none', mr: 2 }}
						onClick={handleExport}
						variant="contained"
						startIcon={<Print />}
						loading={loading}
						loadingPosition="start"
						className={classes.whiteBtn}
					>
						Export Labels
					</LoadingButton>
					<Button
						sx={{ textTransform: 'none', mr: 2 }}
						onClick={() => navigate('create')}
						variant="contained"
						startIcon={<Add />}
					>
						Add Order
					</Button>
					<FilterListButton
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
						reload={reload}
						setIsReload={setReload}
						setPage={setPage}
						page={page}
					/>
				</Stack>
			</Stack>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<CustomTable
					loading={missionOrder?.loading}
					columnData={getColumnData()}
					tableData={getTableData()}
					handleDrag={handleDrag}
					handleResize={handleResize}
					emptyMessage={'No mission sunday order available'}
					handleRowClick={(row) => handleEdit(row as any)}
				/>
				{(missionOrder?.data?.body.length as number) > 0 && (
					<TablePagination
						rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
						count={missionOrder?.data?.totalRecords as number}
						rowsPerPage={pageSize}
						page={page}
						sx={pagination}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
			</Paper>
		</>
	);
};

export default MissionSundayOrder;
