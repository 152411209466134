import React, { useState, useEffect } from 'react';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import { Grid, Stack, Typography, Button, TextField } from '@mui/material';
import ActionModal from 'Components/Reusable/Modals/ActionModal/ActionModal';
import { useGlobalStyles } from 'Components/Common/global/global';
import { TaskAlt, Close } from '@mui/icons-material';
import { Donor } from 'contexts/intialstates/Types';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import useDonorActions from 'hooks/useDonorActions';

interface P {
	visible: boolean;
	handleClose: () => void;
	donorId?: number;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	reload: boolean;
	donor?: Donor;
}

interface FormValues {
	firstName?: string;
	lastName?: string;
	donorId?: number;
	contactName?: string;
	dateReported?: string;
	certifiedDate?: string;
	doNotReversePayments?: boolean;
}

const RecordDateOfDeathModal = ({ visible, handleClose, donorId, setReload, reload, donor }: P) => {
	const global = useGlobalStyles();
	const { recordDateOfDeath } = useDonorActions();

	const [loading, setLoading] = useState(false);
	const [formValues, setFormValues] = useState<FormValues>({});
	const [isConfirmVisible, setConfirmVisible] = useState(false);
	const [formErrors, setFormErrors] = useState<string[]>([]);

	useEffect(() => {
		if (!donor) return;
		setFormValues({
			firstName: donor?.firstName ?? '',
			lastName: donor?.lastName ?? '',
		});
	}, [donor]);

	const handleSubmit = async () => {
		if (!formValues?.certifiedDate || !formValues?.contactName || !formValues.dateReported || !donorId)
			return;
		const payload = {
			certifiedDate: formValues.certifiedDate,
			contactName: formValues.contactName,
			dateReported: formValues.dateReported,
			doNotReversePayments: false,
		};
		setLoading(true);
		const isSuccess = await recordDateOfDeath(payload, donorId);
		if (isSuccess) {
			handleClose();
			setReload(!reload);
		}
		handleToggleConfimation();
		setLoading(false);
	};

	const handleChange = (name: string, value: string | number) => {
		setFormValues({
			...formValues,
			[name]: value,
		});
		if (formErrors.includes(name)) {
			setFormErrors(formErrors.filter((item: any) => item !== name));
		}
	};

	const handleToggleConfimation = () => {
		if (!isConfirmVisible) {
			const errors = [];
			if (!formValues.certifiedDate) {
				errors.push('certifiedDate');
			}
			if (!formValues.contactName) {
				errors.push('contactName');
			}
			if (!formValues.dateReported) {
				errors.push('dateReported');
			}
			setFormErrors(errors);
			if (errors.length) return;
		}

		setConfirmVisible(!isConfirmVisible);
	};

	return (
		<BasicModal onClose={handleClose} visible={visible} title={'Record Date of Death'}>
			<Stack>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
					<Grid item xs={12} md={6} mb={2}>
						<Grid container>
							<Typography variant="body2" className={global.formLabelText}>
								First Name
							</Typography>
						</Grid>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							placeholder="First Name"
							disabled
							value={formValues.firstName ?? ''}
							className={global.disabledInput}
						/>
					</Grid>
					<Grid item xs={12} md={6} mb={2}>
						<Grid container>
							<Typography variant="body2" className={global.formLabelText}>
								Last Name
							</Typography>
						</Grid>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							placeholder="Last Name"
							disabled
							value={formValues.lastName ?? ''}
							className={global.disabledInput}
						/>
					</Grid>
					<Grid item xs={12} md={6} mb={2}>
						<Grid container>
							<Typography variant="body2" className={global.formLabelText}>
								Date of Death
							</Typography>
						</Grid>
						<BasicDatePicker
							size="small"
							value={formValues.certifiedDate}
							setValue={(val) => handleChange('certifiedDate', val.target.value)}
						/>
						{formErrors.includes('certifiedDate') && (
							<span className={global.errorText}>Date of death is required</span>
						)}
					</Grid>
					<Grid item xs={12} md={6} mb={2}>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Death Record
							</Typography>
						</Grid>
						<BasicDatePicker
							size="small"
							value={formValues.dateReported}
							setValue={(val) => handleChange('dateReported', val.target.value)}
						/>
						{formErrors.includes('dateReported') && (
							<span className={global.errorText}>Death record is required</span>
						)}
					</Grid>
					<Grid item xs={12} md={6} mb={2}>
						<Grid container>
							<Typography variant="body2" className={global.formLabelText}>
								Contact Person
							</Typography>
						</Grid>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							placeholder="Contact Person"
							value={formValues.contactName}
							onChange={(e) => handleChange('contactName', e.target.value)}
						/>
						{formErrors.includes('contactName') && (
							<span className={global.errorText}>Contact person is required</span>
						)}
					</Grid>
				</Grid>
				<Stack direction="row" my={3} justifyContent={'center'} spacing={2} alignItems="center">
					<Button
						color="primary"
						variant="contained"
						startIcon={<TaskAlt />}
						onClick={handleToggleConfimation}
					>
						Save
					</Button>
					<Button variant="outlined" onClick={handleClose} startIcon={<Close />}>
						Cancel
					</Button>
				</Stack>
			</Stack>
			{isConfirmVisible && (
				<ActionModal
					open={isConfirmVisible}
					title={'Are you sure you want to record date of death ?'}
					submitText={'Confirm'}
					handleSubmit={handleSubmit}
					closeText={'Cancel'}
					handleClose={handleToggleConfimation}
					loading={loading}
				/>
			)}
		</BasicModal>
	);
};

export default RecordDateOfDeathModal;
