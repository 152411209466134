import { SET_ACTION } from 'contexts/actionTypes';
import { MessageStateType, Action, MessagesPayload } from 'contexts/intialstates/Types';

export const setAction = (
	state: MessageStateType,
	{ payload, type }: Action<MessagesPayload>,
): MessageStateType => {
	switch (type) {
		case SET_ACTION: {
			return {
				...state,
				action: payload as MessagesPayload,
			};
		}

		default:
			return state;
	}
};
