import { ResponseError, StateType, PaymentMethod } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';
import { useContext } from 'react';

export interface GetAllAppealTypes {
	setPM: React.Dispatch<React.SetStateAction<StateType<PaymentMethod[]>>>;
	allPM: StateType<PaymentMethod[]>;
}

const usePaymentMethodActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = useContext(GlobalContexts);

	const getPaymentMethods = async ({ allPM, setPM }: GetAllAppealTypes) => {
		try {
			setPM({
				...allPM,
				loading: true,
				error: null,
			});
			const response = await api.get(`/paymentmethod/list`);

			setPM({
				...allPM,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setPM({
					...allPM,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	return {
		getPaymentMethods,
	};
};

export default usePaymentMethodActions;
