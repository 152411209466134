import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    title: {
        color: 'var(--mainColor)',
        fontSize: '32px',
        fontWeight: 700,
    },
    subTitle: {
        border: '1px solid var(--mainColor)',
        borderRadius: '4px',
        padding: '5px',
        background: 'var(--white)',
        color: 'var(--mainColor)',
        fontSize: '12px',
    },
    backIconWrapper: {
        background: 'var(--white)',
        borderRadius: '4px',
        padding: '5px',
        color: 'var(--mainColor)',
        cursor: 'pointer'
    },
    paperWrap: {
        padding: '20px',
    },
    IconBtn: {
        cursor: 'pointer',
        margin: '0 12px',
        border: '1px solid var(--mainColor)',
        borderRadius: '6px',
        padding: '3px 10px',
        display: 'flex',
        background: 'var(--white)',
        '& svg': {
            color: 'var(--mainColor)',
        },
    },
    disabledIcon: {
        margin: '0 12px',
        border: '1px solid var(--disabled)',
        borderRadius: '6px',
        padding: '3px 10px',
        display: 'flex',
        background: 'var(--white)',
        '& svg': {
            color: 'var(--disabled)',
        },
    },
    disabledInput: {
        background: 'var(--invalidBackground)'
    },
    searchFieldIcon: {
        fontSize: '18px',
        marginRight: '5px',
        color: 'var(--mainColor)',
    },
    headerWrap: {
        background: 'var(--mainBackground)',
        borderRadius: '10px',
        padding: '13px',
        overflow: 'auto',
    },
    headerContent: {
        borderRight: '2px solid var(--white)'
    },
    titleHeader: {
        color: 'var(--mainColor)',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '24px'
    },
    subTitleHeader: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        color: 'var(--fontColorHeaderSubTitle)',
    },
    depositsTitle: {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '16px'
    },
    formFieldWrap: {
        border: '1px solid #DDE5EA',
        borderRadius: '10px',
        padding: '15px',
        marginTop: '20px'
    },
    gridWrap: {
        minWidth: '470px',
        display: 'flex',
        alignItems: "flex-start"
    },
    formLabelText: {
        fontSize: '13px',
        color: '#22396b',
        fontWeight: 600,
        width: '100px'
    },
    errorText: {
        color: 'red',
    },
    whiteBtn: {
        backgroundColor: 'var(--white) !important',
        color: 'var(--mainColor) !important',
    },
    deleteWrap: {
        right: '100px'
    }
});
