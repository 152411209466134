import React, { FC } from 'react';
import {
	Button,
	styled,
	Typography,
	DialogTitle,
	Dialog,
	DialogContent,
	IconButton,
	Stack,
	Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Archive, DeleteOutline } from '@mui/icons-material';
import { Box } from '@mui/system';

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
	isClose?: boolean;
}

interface P {
	onClose?: () => void;
	visible: boolean;
	title?: string;
	confirmation?: boolean;
	children?: React.ReactNode;
	data?: any;
	noDelete?: boolean;
	disabledDelete?: boolean;
	handleOpenDelete?: (deleteData: any) => void;
	isClose?: boolean;
	maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const Title = styled(Typography)`
	font-size: 18px;
	font-weight: 600;
`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

const BasicDialogTitle = (props: DialogTitleProps) => {
	const { children, onClose, isClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, px: 2, py: 1 }} {...other}>
			{isClose && onClose ? (
				<Stack direction="row" justifyContent={'flex-end'}>
					<IconButton
						aria-label="close"
						onClick={onClose as any}
						sx={{
							color: (theme) => theme.palette.grey[500],
							mb: 1,
						}}
					>
						<CloseIcon />
					</IconButton>
				</Stack>
			) : null}
			<Title>{children}</Title>
		</DialogTitle>
	);
};

const BasicModal: FC<P> = ({
	onClose,
	visible,
	title,
	children,
	data,
	handleOpenDelete,
	isClose = true,
	maxWidth,
	confirmation,
	noDelete,
	disabledDelete,
}) => {
	const handleDelete = () => {
		handleOpenDelete && data && handleOpenDelete(data);
	};
	return (
		<div>
			<BootstrapDialog
				onClose={onClose as any}
				aria-labelledby="customized-dialog-title"
				fullWidth
				open={visible}
				disablePortal
				maxWidth={maxWidth || 'sm'}
			>
				<BasicDialogTitle id="customized-dialog-title" onClose={onClose as any} isClose={isClose}>
					{!confirmation && (
						<Stack px={2} direction="row" justifyContent="space-between">
							<span style={{ color: 'var(--mainColor)' }}>{title}</span>
							{noDelete !== false && data && (
								<Stack direction="row" justifyContent={'flex-end'}>
									<Tooltip title="Delete ">
										<Button variant="outlined" size="medium" onClick={handleDelete} disabled={disabledDelete}>
											<DeleteOutline sx={{ fontSize: 20 }} />
										</Button>
									</Tooltip>
								</Stack>
							)}
						</Stack>
					)}
					{confirmation && (
						<Stack
							direction={'row'}
							justifyContent="center"
							style={{ color: 'var(--mainColor)', textAlign: 'center' }}
						>
							{title}
						</Stack>
					)}
				</BasicDialogTitle>
				<DialogContent sx={{ px: 8 }}>
					<Box px={2}>{children}</Box>
				</DialogContent>
			</BootstrapDialog>
		</div>
	);
};

export default BasicModal;
