import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

import PostDatedChequeList from './List';
import { postDatedChequeHead, ColumnType } from 'Components/Common/Tables/TableHeaders';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import { PostDatedChequesData, OrderType, StateType, FilterPayload } from 'contexts/intialstates/Types';
import usePostDatedChequesAction from 'hooks/usePostDatedChequesAction';
import useUserActions from 'hooks/useUserActions';
import useDebounce from 'Components/Reusable/hooks/debounce';

const PostDatedCheques = () => {
	const { handleRoles } = useUserActions();
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const { getPostDatedCheques, postDatedChequeNotification } = usePostDatedChequesAction();
	const navigate = useNavigate();
	const [reload, setReload] = React.useState(false);
	const [searchDonor, setSearchDonor] = React.useState<string>('');
	const debouncedSearchTerm = useDebounce(searchDonor, 1000);
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [notify, setNotify] = React.useState(false);

	const [postDatedCheques, setPostDatedCheques] = React.useState<StateType<PostDatedChequesData>>({
		data: null,
		error: null,
		loading: false,
	});

	useEffect(() => {
		getPostDatedCheques({
			donorQuery: searchDonor,
			setPostDatedCheques,
			postDatedCheques,
			pageSize,
			page,
			orderBy,
			filterPayload,
		});
	}, [page, pageSize, reload, orderBy, debouncedSearchTerm]);
	useEffect(() => {
		postDatedChequeNotification({ notify, setNotify });
	}, [notify]);

	return (
		<>
			<Box>
				<Stack
					alignItems="center"
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent={'space-between'}
					spacing={1}
					mb={5}
				>
					<Stack direction="column">
						<Stack direction="row" spacing={4} alignItems="center">
							<Typography variant="h6" sx={{ color: 'var(--mainColor)' }}>
								Post-dated Cheques
							</Typography>

							{notify && (
								<Chip sx={{ fontSize: 12 }} label="You have post dated cheques due! " color="error" />
							)}
						</Stack>
						<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
							Create and manage post-dated cheques to receive a notification reminder on due date of the
							cheque.
						</Typography>
					</Stack>
					<Stack direction={'row'} justifyContent={'center'} spacing={{ xs: 1, md: 2 }} alignItems="center">
						<TrimTables
							name="postDatedChequeHead"
							list={postDatedChequeHead}
							trimList={trimList}
							setTrimList={setTrimList}
						/>
						{handleRoles('bookkeeping-actions') && (
							<Button
								sx={{ textTransform: 'capitalize' }}
								variant="contained"
								disableElevation
								startIcon={<Add />}
								onClick={() => navigate('create')}
							>
								Add Post-dated Cheque
							</Button>
						)}
						<FilterListButton
							page={page}
							setPage={setPage}
							reload={reload}
							setIsReload={setReload}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
						/>
					</Stack>
				</Stack>
				<Stack direction="row">
					<PostDatedChequeList
						trimList={trimList}
						setTrimList={setTrimList}
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
						reload={reload}
						setReload={setReload}
						page={page}
						setPage={setPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						orderType={orderType}
						setOrderType={setOrderType}
						label={label}
						setLabel={setLabel}
						orderBy={orderBy}
						setOderBy={setOderBy}
						searchDonor={searchDonor}
						setSearchDonor={setSearchDonor}
						postDatedCheques={postDatedCheques}
					/>
				</Stack>
			</Box>
		</>
	);
};

export default PostDatedCheques;
