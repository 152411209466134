import React, { useEffect, useState } from 'react';
import {
	Stack,
	IconButton,
	Paper,
	Box,
	Tooltip,
	Typography,
	Button,
	FormControl,
	Select,
	OutlinedInput,
	MenuItem,
	SelectChangeEvent,
	InputLabel,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Add, ArrowBackIosNew, Cancel, Download, FilterAltOutlined, TaskAlt } from '@mui/icons-material';
import { StateType } from 'contexts/intialstates/Types';
import { useStyles } from './style';
import useCampaignActions, {
	CampaignOutputDonor,
	ICampaignOutput,
	UpdateCampaignPayload,
} from 'hooks/useCampaignActions';
import CampaignForm, { FormValues } from './Form';
import OutputList from './OutputList';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { campaignOutputHead, ColumnType } from 'Components/Common/Tables/TableHeaders';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import OutputForm, { Values } from './OutputForm';
import { LoadingButton } from '@mui/lab';
import ActionModal from 'Components/Reusable/Modals/ActionModal/ActionModal';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import moment from 'moment';
import useApi from 'hooks/useApi';
import { GlobalContexts } from 'contexts/GlobalContext';
import _ from 'lodash';

const optionsArr = [
	'Salutation',
	'LastName',
	'FirstName',
	'OrganizationName',
	'DonorType',
	'Amount',
	'Phone',
	'Mobile',
	'Email',
	'Streetline1',
	'Streetline2',
	'City',
	'Province',
	'PostalCode',
	'Parish',
	'Diocese',
	'DonorId',
	'Fax',
];

export function pascalCaseToSpaceSeparated(text: string) {
	// Add a space before each uppercase letter and convert to lowercase
	// return str.replace(/([A-Z])/g, ' $1').toLowerCase();
	const result = text.replace(/([A-Z])/g, ' $1');
	const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
	return finalResult;
}

const ManageCampaign = () => {
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);
	const navigate = useNavigate();
	const { api } = useApi();
	const { id } = useParams();
	const classes = useStyles();
	const [campaignOutput, setCampaignOutput] = useState<StateType<ICampaignOutput>>({
		data: null,
		loading: false,
		error: null,
	});
	const [campaignId, setCampaignId] = useState<StateType<ICampaignOutput>>({
		data: null,
		loading: false,
		error: null,
	});
	const [campaignValues, setCampaignValues] = useState<StateType<ICampaignOutput>>({
		data: null,
		loading: false,
		error: null,
	});
	const [loading, setLoading] = useState(false);
	const [formErrors, setFormErrors] = useState<string[]>([]);
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [isEdit, setIsEdit] = useState(false);
	const [isShowFilter, setIsShowFilter] = useState(true);
	const [visible, setVisible] = React.useState(false);
	const [isDelete, setIsDelete] = React.useState(false);
	const [reload, setReload] = React.useState(false);
	const [reloadOnSave, setReloadOnSave] = React.useState(false);
	const [shouldUpdate, setShouldUpdate] = React.useState(false);
	const [selectedData, setSelectedData] = React.useState<Values>();
	const [exportLoading, setExportLoading] = useState(false);
	const [updateLoading, setUpdateLoading] = useState(false);

	const [exportOptionsModal, setExportOptionsModal] = useState(false);
	const [options, setOptions] = useState<string[]>([]);

	const [campaignFormData, setCampaignFormData] = useState<FormValues>();
	const { getCampaignOutput, getCampaignByID, updateCampaign, generateCampaign } = useCampaignActions();
	const isEmptyArr = (arr: number[]) => {
		if (arr?.length === 0) return null;
		if (arr?.some((item) => item === null)) {
			const findNullIndex = arr?.findIndex((item) => item === null);
			arr?.splice(findNullIndex, 1);
		}

		return arr;
	};
	const [openRequiredModal, setOpenRequiredModal] = useState(false);
	const handleGetDonors = async (formValues: FormValues, isGenerate?: boolean) => {
		const payload = {
			campaignName: formValues.name,
			generateDonorPayload: {
				startDate: moment(formValues?.start).format('YYYY-MM-DD'),
				endDate: moment(formValues?.end).format('YYYY-MM-DD'),
				minAmount: formValues.minAmount ? parseInt(formValues.minAmount as string) : null,
				maxAmount: formValues.maxAmount ? parseInt(formValues.maxAmount as string) : null,
				noMail: formValues?.communication?.includes('noMail') ? true : null,
				excludeFromParishList: formValues?.communication?.includes('excludeFromParishList') ? true : null,
				returnMail: formValues?.communication?.includes('returnMail') ? true : null,
				deceased: formValues?.communication?.includes('deceased') ? true : null,
				isCommunicationNone: formValues?.communication?.includes('isCommunicationNone') ? true : null,
				magazine: formValues?.magazine,
				faithPropagation: formValues?.preferredSociety?.includes('faithPropagation') ? true : null,
				stPeter: formValues?.preferredSociety?.includes('stPeter') ? true : null,
				holyChildhood: formValues?.preferredSociety?.includes('holyChildhood') ? true : null,
				noFollowAppeal: formValues.noFollowAppeal,
				receiptFrequencyIds: isEmptyArr(formValues.receiptFrequency.map((item) => parseInt(item as string))),
				donationTypeIds: isEmptyArr(formValues.donationType.map((item) => parseInt(item as string))),
				societyIds: isEmptyArr(formValues.society.map((item) => parseInt(item as string))),
				paymentMethodIds: isEmptyArr(
					formValues.paymentMethod.map((item) => parseInt(item as string)),
				)?.filter((item) => item != null),
				donorIds: isEmptyArr(formValues.donors.map((item) => item?.donorId)),
			},
		};

		await getCampaignOutput({ payload, setCampaignOutput });
		if (isGenerate) {
			setIsEdit(true);
		}
		if (campaignOutput?.data?.campaignDonors?.length) {
			setIsShowFilter(false);
		}
	};
	const handleGenerateCampaign = async (formValues: FormValues, isGenerate?: boolean) => {
		if (!id) return;
		const payload = {
			campaignName: formValues.name,
			generateDonorPayload: {
				startDate: moment(formValues?.start).format('YYYY-MM-DD'),
				endDate: moment(formValues?.end).format('YYYY-MM-DD'),
				minAmount: parseInt(formValues.minAmount as string),
				maxAmount: parseInt(formValues.maxAmount as string),
				noMail: formValues?.communication?.includes('noMail'),
				excludeFromParishList: formValues?.communication?.includes('excludeFromParishList'),
				returnMail: formValues?.communication?.includes('returnMail'),
				deceased: formValues?.communication?.includes('deceased'),
				isCommunicationNone: formValues?.communication?.includes('isCommunicationNone'),
				magazine: formValues?.magazine,
				faithPropagation: formValues?.preferredSociety?.includes('faithPropagation'),
				holyChildhood: formValues?.preferredSociety?.includes('holyChildhood'),
				noFollowAppeal: formValues?.noFollowAppeal,
				stPeter: formValues?.preferredSociety?.includes('stPeter'),
				receiptFrequencyIds: isEmptyArr(formValues.receiptFrequency.map((item) => parseInt(item as string))),
				donationTypeIds: isEmptyArr(formValues.donationType.map((item) => parseInt(item as string))),
				societyIds: isEmptyArr(formValues.society.map((item) => parseInt(item as string))),
				paymentMethodIds: isEmptyArr(formValues.paymentMethod.map((item) => parseInt(item as string))),
				donorIds: isEmptyArr(formValues.donors.map((item) => item?.donorId)),
			},
		};

		const isSuccess = await generateCampaign({
			payload,
			campaignId,
			setCampaignId,
			id: parseInt(id as string),
		});
		if (isSuccess) {
			setReload(!reload);
		}
		if (isGenerate) {
			setIsEdit(true);
		}
		if (campaignOutput?.data?.campaignDonors?.length) {
			setIsShowFilter(false);
		}
	};

	const handleOptionsChange = (event: SelectChangeEvent<typeof options>) => {
		const {
			target: { value },
		} = event;
		setOptions(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value,
		);
		const currOption = typeof value === 'string' ? value.split(',') : value;
		localStorage.setItem('campaignExportOptions', currOption.toString());
	};

	const handleCancel = () => {
		setCampaignOutput({
			data: null,
			loading: false,
			error: null,
		});
	};

	const handleOpenEdit = (data: Values) => {
		setVisible(true);
		setSelectedData(data);
	};
	const handleOpenDelete = (data: Values) => {
		setIsDelete(true);
		setSelectedData(data);
	};
	const handleDelete = () => {
		if (!selectedData || !id) return;
		setCampaignId({
			...campaignId,
			data: {
				...campaignId.data,
				campaignDonors: campaignId.data?.campaignDonors.filter(
					(curr) => curr.id !== selectedData?.id,
				) as CampaignOutputDonor[],
			} as ICampaignOutput,
		}),
			setVisible(false);
		setIsDelete(false);
	};
	const handleSubmit = async (payload: Values) => {
		const {
			salutation,
			email,
			firstName,
			lastName,
			fax,
			mobile,
			city,
			postalCode,
			streetline1,
			streetline2,
			country,
			phone,
		} = payload;
		if (id) {
			const dataOutput = campaignId?.data;
			setCampaignId({
				...campaignId,
				data: {
					...campaignId.data,
					campaignDonors: dataOutput?.campaignDonors?.map((ele) => {
						if (payload?.id === ele?.id) {
							return {
								...ele,
								salutation,
								email,
								firstName,
								lastName,
								fax,
								mobile,
								city,
								postalCode,
								streetline1,
								streetline2,
								country,
								phone,
							};
						}
						return ele;
					}) as CampaignOutputDonor[],
				} as ICampaignOutput,
			});
		}
		setVisible(false);
	};
	const validationMessage = () => {
		if (!campaignFormData?.start || !campaignFormData?.end)
			return 'Please fill out donation recieved date range field';

		return 'Campaign Name is required';
	};

	const handleUpdate = async (formValues: FormValues) => {
		if (!id) return;

		const errors = [];
		if (!formValues.name) {
			errors.push('name');
		}
		if (!formValues.end) {
			errors.push('end');
		}
		setFormErrors(errors);
		if (errors.length) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'Please fill all the required fields.',
			});
			return;
		}

		const payload: UpdateCampaignPayload = {
			name: formValues.name,
			startDate: moment(formValues?.start).format('YYYY-MM-DD'),
			endDate: moment(formValues?.end).format('YYYY-MM-DD'),
			minAmount: parseInt(formValues.minAmount as string),
			maxAmount: parseInt(formValues.maxAmount as string),
			noMail: formValues?.communication?.includes('noMail'),
			excludeFromParishList: formValues?.communication?.includes('excludeFromParishList'),
			returnMail: formValues?.communication?.includes('returnMail'),
			deceased: formValues?.communication?.includes('deceased'),
			isCommunicationNone: formValues?.communication?.includes('isCommunicationNone'),
			magazine: formValues?.magazine,
			faithPropagation: formValues?.preferredSociety?.includes('faithPropagation'),
			holyChildhood: formValues?.preferredSociety?.includes('holyChildhood'),
			noFollowAppeal: formValues?.noFollowAppeal,
			stPeter: formValues?.preferredSociety?.includes('stPeter'),

			receiptFrequencyIds: isEmptyArr(
				formValues.receiptFrequency.map((item) => parseInt(item as string)),
			) as number[],
			donationTypeIds: isEmptyArr(
				formValues.donationType.map((item) => parseInt(item as string)),
			) as number[],
			societyIds: isEmptyArr(formValues.society.map((item) => parseInt(item as string))) as number[],

			paymentMethodIds: isEmptyArr(
				formValues.paymentMethod.map((item) => parseInt(item as string)),
			) as number[],
			donorIds: isEmptyArr(formValues.donors.map((item) => item?.donorId)) as number[],
			donorCount: campaignId.data?.donorCount as number,
			campaignDonors: campaignId.data?.campaignDonors as CampaignOutputDonor[],
		};
		setUpdateLoading(true);
		const isSucess = await updateCampaign(id, payload);
		if (isSucess) {
			setUpdateLoading(false);
			setVisible(false);
			setReloadOnSave(!reloadOnSave);
		}
		setVisible(false);
	};

	const handleExport = async () => {
		if (!options.length) {
			setSimpleErrorSnack({
				message: 'Please select at least one column',
				show: true,
				severity: 'error',
			});
			return;
		}
		try {
			setExportLoading(true);
			const response = await api.get(`campaign/${id}/export-csv?columns=${options.toString()}`);
			const url = window.URL.createObjectURL(new Blob([response?.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `PMS_Campaign_${moment().format('yyMM')}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			setExportLoading(false);
		} catch (e) {
			setExportLoading(false);
		}
	};
	const hideExportModal = () => {
		setExportOptionsModal(false);
	};
	useEffect(() => {
		if (campaignOutput?.data?.campaignDonors?.length) {
			setIsShowFilter(false);
		}
	}, [campaignOutput?.data?.campaignDonors?.length]);
	useEffect(() => {
		if (campaignOutput.data?.id) {
			navigate(`../${campaignOutput?.data?.id}`);
		}
	}, [campaignOutput?.data]);
	useEffect(() => {
		if (!id) return;
		getCampaignByID({ campaignId, setCampaignId, id });
		setIsEdit(true);
	}, [id, reloadOnSave]);
	useEffect(() => {
		if (campaignId.data) {
			setCampaignValues({
				...campaignValues,
				data: {
					...campaignId.data,
				},
			});
		}
	}, []);
	useEffect(() => {
		if (campaignId.data?.id) {
			setCampaignValues({
				...campaignValues,
				data: {
					...campaignId.data,
				},
			});
		}
	}, [campaignId.data?.id, reload]);
	useEffect(() => {
		if (!id && !campaignFormData) return;
		const dynamic = {
			startDate: campaignFormData?.start ? moment(campaignFormData?.start).format('YYYY-MM-DD') : '',
			endDate: campaignFormData?.end ? moment(campaignFormData?.end).format('YYYY-MM-DD') : '',
			minAmount: campaignFormData?.minAmount ? parseInt(campaignFormData?.minAmount as string) : null,
			maxAmount: campaignFormData?.maxAmount ? parseInt(campaignFormData?.maxAmount as string) : null,
			noMail: !campaignFormData?.communication?.includes('noMail'),
			excludeFromParishList: !campaignFormData?.communication?.includes('excludeFromParishList'),
			returnMail: !campaignFormData?.communication?.includes('returnMail'),
			deceased: !campaignFormData?.communication?.includes('deceased'),
			isCommunicationNone: !campaignFormData?.communication?.includes('isCommunicationNone'),
			magazine: !campaignFormData?.magazine as boolean,
			faithPropagation: !campaignFormData?.preferredSociety?.includes('faithPropagation'),
			stPeter: !campaignFormData?.preferredSociety?.includes('stPeter'),
			holyChildhood: !campaignFormData?.preferredSociety?.includes('holyChildhood'),
			noFollowAppeal: !campaignFormData?.noFollowAppeal as boolean,
			receiptFrequencyIds:
				isEmptyArr(
					campaignFormData?.receiptFrequency.map((item) => parseInt(item as string)) as number[],
				)?.sort() || [],
			donationTypeIds:
				isEmptyArr(
					campaignFormData?.donationType.map((item) => parseInt(item as string)) as number[],
				)?.sort() || [],
			societyIds:
				isEmptyArr(campaignFormData?.society.map((item) => parseInt(item as string)) as number[])?.sort() ||
				[],

			paymentMethodIds:
				isEmptyArr(
					campaignFormData?.paymentMethod.map((item) => parseInt(item as string)) as number[],
				)?.sort() || [],
			donorIds: isEmptyArr(campaignFormData?.donors.map((item) => item?.donorId) as number[])?.sort() || [],
		};
		const constant = {
			startDate: campaignValues.data?.startDate
				? moment(campaignValues.data?.startDate).format('YYYY-MM-DD')
				: '',
			endDate: campaignValues.data?.endDate ? moment(campaignValues.data?.endDate).format('YYYY-MM-DD') : '',
			minAmount: campaignValues.data?.minAmount,
			maxAmount: campaignValues.data?.maxAmount,
			noMail: !campaignValues.data?.noMail,
			excludeFromParishList: !campaignValues.data?.excludeFromParishList,
			returnMail: !campaignValues.data?.returnMail,
			deceased: !campaignValues.data?.deceased,
			isCommunicationNone: !campaignValues.data?.isCommunicationNone,
			magazine: !campaignValues.data?.magazine,
			faithPropagation: !campaignValues.data?.faithPropagation,
			stPeter: !campaignValues.data?.stPeter,
			holyChildhood: !campaignValues.data?.holyChildhood,
			noFollowAppeal: !campaignValues.data?.noFollowAppeal,
			receiptFrequencyIds: campaignValues.data?.receiptFrequencyIds?.sort() || [],
			donationTypeIds: campaignValues.data?.donationTypeIds?.sort() || [],
			societyIds: campaignValues.data?.societyIds?.sort() || [],
			paymentMethodIds: campaignValues.data?.paymentMethodIds?.sort() || [],
			donorIds: campaignValues.data?.donorIds?.sort() || [],
		};
		if (_.isEqual(constant, dynamic)) {
			setShouldUpdate(false);
		} else {
			setShouldUpdate(true);
		}
	}, [campaignFormData, campaignValues]);

	useEffect(() => {
		if (updateLoading || campaignId.loading || campaignOutput.loading) {
			setLoading(true);
			return;
		}
		setLoading(false);
	}, [updateLoading, campaignId.loading, campaignOutput.loading]);
	// useEffect(() => {
	// 	const campaignExportOptions = localStorage.getItem('campaignExportOptions');
	// 	if (campaignExportOptions) {
	// 		const arr = campaignExportOptions?.split(',');
	// 		setOptions(arr);
	// 	}
	// }, []);

	return (
		<>
			{
				<Stack>
					<Stack direction="row" marginBottom={2} alignItems={'center'} justifyContent="space-between">
						<Stack direction="row" alignItems={'center'} spacing={4}>
							<Stack className={classes.backIconWrapper}>
								<ArrowBackIosNew onClick={() => navigate('../')} />
							</Stack>
							<Stack>
								<span className={classes.title}>Campaign</span>
							</Stack>
						</Stack>
						<Stack direction="row" spacing={2} justifyContent={'flex-end'}>
							{isEdit && (
								<IconButton
									sx={{
										background: 'var(--mainColor)',
										borderRadius: '6px',
										width: 40,
										height: 30,
										border: '1px solid var(--mainColor)',
									}}
									onClick={() => setIsShowFilter(!isShowFilter)}
								>
									<FilterAltOutlined sx={{ fontSize: 16, color: 'white' }} />
								</IconButton>
							)}

							{id && (
								<LoadingButton
									className={classes.exportBtn}
									sx={{ textTransform: 'capitalize', backgroundColor: 'white' }}
									variant={'outlined'}
									disableElevation
									startIcon={<Download />}
									onClick={() => setExportOptionsModal(true)}
									loading={exportLoading}
									loadingPosition="start"
								>
									Export
								</LoadingButton>
							)}
							{id && (
								<Tooltip
									title={
										shouldUpdate
											? 'A change has been made in the criteria section please generate a new output to save'
											: ''
									}
								>
									<span>
										<LoadingButton
											sx={{
												textTransform: 'capitalize',
											}}
											variant={'contained'}
											disableElevation
											startIcon={<Add />}
											onClick={() => handleUpdate(campaignFormData as FormValues)}
											loading={loading}
											loadingPosition="start"
											disabled={shouldUpdate}
										>
											Save
										</LoadingButton>
									</span>
								</Tooltip>
							)}
						</Stack>
					</Stack>
					<Paper className={classes.paperWrap}>
						<CampaignForm
							handleGetDonors={handleGetDonors}
							handleGenerateCampaign={handleGenerateCampaign}
							loading={loading}
							onCancel={handleCancel}
							isEdit={isEdit}
							isShowFilter={isShowFilter}
							campaignId={campaignId}
							setCampaignFormData={setCampaignFormData as React.Dispatch<React.SetStateAction<FormValues>>}
							formErrors={formErrors}
							setFormErrors={setFormErrors}
						/>
						{(!campaignOutput.loading && campaignOutput?.data?.campaignDonors?.length) ||
						(id && !campaignId.loading && campaignId?.data?.campaignDonors?.length) ? (
							<>
								<Box className={classes.pageDividerMini}>Output</Box>
								<Stack direction="row" justifyContent="flex-end">
									<TrimTables
										name="campaignOutputHead"
										list={campaignOutputHead}
										trimList={trimList}
										setTrimList={setTrimList}
									/>
								</Stack>
								{id && (
									<OutputList
										trimList={trimList}
										setTrimList={setTrimList}
										output={campaignId}
										handleOpenEdit={handleOpenEdit}
									/>
								)}
							</>
						) : null}
					</Paper>
					<BasicModal
						visible={visible}
						title="Edit Output"
						data={selectedData || undefined}
						handleOpenDelete={handleOpenDelete}
					>
						<OutputForm
							handleSubmit={handleSubmit}
							selectedEditData={selectedData as Values}
							setSelectedData={setSelectedData}
							setVisible={setVisible}
						/>
					</BasicModal>
					<ConfirmationModal
						handleClose={() => setIsDelete(false)}
						message={'Are you sure you want to delete this this Donor ?'}
						open={isDelete}
						handleConfirmation={handleDelete}
						handleCancel={() => setIsDelete(false)}
					/>
					{openRequiredModal && (
						<ActionModal
							open={openRequiredModal}
							title={'Required field'}
							subTitle={validationMessage()}
							closeText={'Close'}
							handleClose={() => setOpenRequiredModal(false)}
						/>
					)}
				</Stack>
			}
			<BasicModal visible={exportOptionsModal} title={''}>
				<Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
					<Typography
						flexGrow={1}
						sx={{ fontWeight: 400, fontSize: 17, textAlign: 'center', color: 'var(--mainColor)' }}
					>
						Please select the columns to be exported.
					</Typography>
					<Tooltip title="Close">
						<IconButton onClick={hideExportModal}>
							<Cancel color="primary" />
						</IconButton>
					</Tooltip>
				</Stack>

				<Box px={2} mt={2}>
					<Select multiple value={options} onChange={handleOptionsChange} fullWidth>
						{optionsArr.map((item) => (
							<MenuItem key={item} value={item}>
								{pascalCaseToSpaceSeparated(item)}
							</MenuItem>
						))}
					</Select>
				</Box>

				<Stack direction="row" justifyContent="center" py={3} spacing={2}>
					<Button
						sx={{ textTransform: 'none' }}
						onClick={() => {
							setOptions([]);
							localStorage.setItem('campaignExportOptions', '');
						}}
						variant="outlined"
						style={{ marginTop: 4 }}
						startIcon={<Cancel />}
					>
						Clear
					</Button>
					<LoadingButton
						sx={{ textTransform: 'none' }}
						onClick={handleExport}
						variant="contained"
						style={{ marginTop: 4 }}
						startIcon={<TaskAlt />}
						loading={exportLoading}
						disabled={loading || exportLoading}
						loadingPosition="start"
					>
						Confirm
					</LoadingButton>
				</Stack>
			</BasicModal>
		</>
	);
};

export default ManageCampaign;
