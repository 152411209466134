import React from 'react';
import SideMenuItem from './SideMenuItem';
import { sideMenuItems } from './data';
import useUserActions from 'hooks/useUserActions';
import { Routes } from 'utils/routes';

const SideMenu: React.FC = () => {
	const { handleRoles } = useUserActions();
	return (
		<div style={{ overflow: 'hidden', padding: '17px 5px 0px 5px' }}>
			{sideMenuItems.map((item, index) => {
				if (item.link === Routes.HCA) {
					if (handleRoles('operations')) return <SideMenuItem {...item} key={index} />;
					return;
				}
				return <SideMenuItem {...item} key={index} />;
			})}
		</div>
	);
};

export default SideMenu;
