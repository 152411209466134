import React, { useEffect, useState } from 'react';
import { Stack, Paper, Typography, Grid, Button, Select, MenuItem } from '@mui/material';
import { useGlobalStyles } from 'Components/Common/global/global';
import { CorporateFare, DateRange } from '@mui/icons-material';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import Deposists, { Contribution } from './Deposists';
import { useStyles } from '../DepositSlip.style';
import usePaymentmethodsActions from 'hooks/usePaymentMethodAction';
import useAppealActions from 'hooks/useAppealActions';
import useDepositSlipAction, { DepositSlipsStatus } from 'hooks/useDepositSlipAction';
import { Appeal, PaymentMethod, Society, StateType } from 'contexts/intialstates/Types';
import useSocietyActions from 'hooks/useSocietyAction';

interface DepositSlipForm {
	societyId?: number;
	bankDepositDate?: string;
	status: string;
	error: string[];
	contributions: Contribution[];
}

interface P {
	formData: DepositSlipForm;
	setFormData: React.Dispatch<React.SetStateAction<DepositSlipForm>>;
}

const DepositSlipForm = ({ formData, setFormData }: P) => {
	const [allSociety, setAllSoceity] = React.useState<StateType<Society[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [allPM, setPM] = React.useState<StateType<PaymentMethod[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [allAppeals, setAllAppeals] = useState<StateType<Appeal[]>>({
		data: null,
		loading: false,
		error: null,
	});

	const global = useGlobalStyles();
	const classes = useStyles();
	const { getPaymentMethods } = usePaymentmethodsActions();
	const { getAllAppeals } = useAppealActions();
	const { getDepositSlipsStatus } = useDepositSlipAction();
	const { getSocieties } = useSocietyActions();
	const [depositSlipsStatus, setDepositSlipsStatus] = useState<StateType<DepositSlipsStatus[]>>({
		data: null,
		loading: false,
		error: null,
	});

	useEffect(() => {
		getSocieties({ allSociety, setAllSoceity });
		getAllAppeals({ setAllAppeals, allAppeals });
		getPaymentMethods({ allPM, setPM });
		getDepositSlipsStatus({ depositSlipsStatus, setDepositSlipsStatus });
	}, []);

	const paymentMethods =
		allPM.data
			?.filter(({ name }) => name === 'Cash' || name === 'Cheque')
			.map(({ id, name }) => {
				return {
					id,
					label: name,
				};
			}) || [];

	const appealCode =
		allAppeals?.data?.map(({ id, code }) => {
			return {
				id,
				label: code,
			};
		}) || [];

	const depositSlipStatus =
		depositSlipsStatus?.data?.map(({ id, status }) => {
			return {
				id,
				label: status,
			};
		}) || [];

	const handleAddContribution = () => {
		setFormData((prevState) => {
			return {
				...prevState,
				contributions: [
					...prevState.contributions,
					{
						donorName: '',
						amount: 0,
						chequeNumber: '',
						donationTypeName: '',
						isWalkathon: false,
						organizationType: '',
						organizationName: '',
						statusName: '',
						remark: '',
					},
				],
			};
		});
	};

	const handleChangeContribution = (
		index: number,
		data: { [key: string]: string | number | boolean | string[] | undefined },
	) => {
		const contributionData = [...formData.contributions];
		contributionData[index] = {
			...contributionData[index],
			...data,
		};
		setFormData((prevState) => {
			return {
				...prevState,
				contributions: contributionData,
			};
		});
	};

	const handleDelete = (index: number) => {
		let contributionData = [...formData.contributions];
		contributionData = contributionData.filter((item, i) => i !== index);
		setFormData((prevState) => {
			return {
				...prevState,
				contributions: contributionData,
			};
		});
	};

	return (
		<Paper sx={{ padding: '40px' }}>
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Stack direction="row" mb={1}>
						<CorporateFare className={global.formLabelIcon} />
						<Typography variant="body2" className={global.formLabelText}>
							Society
						</Typography>
					</Stack>
					<Stack>
						<Select
							fullWidth
							size={'small'}
							value={formData?.societyId ? formData.societyId : ''}
							onChange={(e) =>
								setFormData({
									...formData,
									societyId: parseInt(e.target.value.toString()),
									error: formData.error.filter((item) => item !== 'societyId'),
								})
							}
							disabled={formData.status === 'Posted'}
							className={formData.status === 'Posted' ? classes.disabledInput : ''}
						>
							{allSociety?.data
								?.filter((curr) => curr.code !== 'AN')
								.map((x) => (
									<MenuItem key={x.id} value={x.id}>
										{x.code}
									</MenuItem>
								))}
						</Select>
						{formData.error.includes('societyId') && (
							<span className={classes.errorText}>Society is required</span>
						)}
					</Stack>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Stack direction="row" mb={1}>
						<DateRange className={global.formLabelIcon} />
						<Typography variant="body2" className={global.formLabelText}>
							Bank Deposit Date
						</Typography>
					</Stack>
					<Stack>
						<BasicDatePicker
							disabled={formData.status === 'Posted'}
							size="small"
							value={formData?.bankDepositDate}
							setValue={(e) =>
								setFormData({
									...formData,
									bankDepositDate: e.target.value,
									error: formData.error.filter((item) => item !== 'bankDepositDate'),
								})
							}
						/>
						{formData.error.includes('bankDepositDate') && (
							<span className={classes.errorText}>Bank Deposit Date is required</span>
						)}
					</Stack>
				</Grid>
			</Grid>
			<Stack mt={4}>
				<Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-end'} mb={1}>
					<Stack className={classes.depositsTitle}>Desposits</Stack>
					<Stack>
						<Button
							variant="outlined"
							onClick={handleAddContribution}
							disabled={formData.status === 'Posted'}
						>
							Add Deposit
						</Button>
					</Stack>
				</Stack>
				<hr />
				{formData.contributions.map((contribution, index) => (
					<div key={index}>
						<Deposists
							paymentMethods={paymentMethods}
							appealCode={appealCode}
							depositSlipStatus={depositSlipStatus}
							contribution={contribution}
							handleChangeContribution={(data) => handleChangeContribution(index, data)}
							isDisabled={formData.status === 'Posted'}
							handleDelete={() => handleDelete(index)}
						/>
					</div>
				))}
			</Stack>
		</Paper>
	);
};

export default DepositSlipForm;
