import React, { useEffect, useState } from 'react';
import { Stack, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIosNew, FilterAltOutlined } from '@mui/icons-material';
import MissionSundayForm, { FormValues } from './MissionSundayForm';
import MissionSundayView from './MissionSundayView';
import useMissionReportActions from 'hooks/useMissionReportActions';
import { StateType } from 'contexts/intialstates/Types';
import { useStyles } from './MissionSunday.style';
import { MissionOrderById } from 'hooks/useMissionSundayOrder';
import moment from 'moment';

const MissionSundayReport = () => {
	const navigate = useNavigate();
	const classes = useStyles();
	const [formData, setFormData] = useState<FormValues>({
		start: '',
		end: '',
		materialids: [],
	});
	const [missionSundayReportData, setMissionSundayReportData] = useState<StateType<MissionOrderById[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [dateRange, setDateRange] = useState({
		start: '',
		end: '',
	});
	const [isEdit, setIsEdit] = useState(false);
	const [isShowFilter, setIsShowFilter] = useState(true);
	const { getMissionSundayReport, getMissionSundayReportPDF } = useMissionReportActions();

	const handleGetReport = async (formValues: FormValues, isGenerate?: boolean) => {
		const payload = {
			start: moment(formValues?.start).format('YYYY-MM-DD'),
			end: moment(formValues?.end).format('YYYY-MM-DD'),
			materialids: formValues.materialids.join(','),
		};
		await getMissionSundayReport({ payload, setMissionSundayReportData });
		if (isGenerate) {
		}
		setDateRange({
			start: formValues.start,
			end: formValues.end,
		});
	};

	const handleGetReportPdf = async () => {
		const payload = {
			start: moment(formData?.start).format('YYYY-MM-DD'),
			end: moment(formData?.end).format('YYYY-MM-DD'),
			materialids: formData.materialids.join(','),
		};
		await getMissionSundayReportPDF(payload);
	};

	const handleCancel = () => {
		setMissionSundayReportData({
			data: null,
			loading: false,
			error: null,
		});
	};
	useEffect(() => {
		if (missionSundayReportData.data) {
			setIsEdit(true);
			setIsShowFilter(false);
		}
	}, [missionSundayReportData.data]);

	return (
		<Stack>
			<Stack direction="row" marginBottom={2} alignItems={'center'} justifyContent="space-between">
				<Stack direction="row" alignItems={'center'} spacing={4}>
					<Stack className={classes.backIconWrapper}>
						<ArrowBackIosNew onClick={() => navigate(-1)} />
					</Stack>
					<Stack>
						<span className={classes.title}>Mission Sunday Report</span>
					</Stack>
				</Stack>
				{isEdit && (
					<IconButton
						sx={{
							background: 'white',
							borderRadius: '6px',
							width: 40,
							height: 30,
							border: '1px solid var(--mainColor)',
						}}
						onClick={() => setIsShowFilter(!isShowFilter)}
					>
						<FilterAltOutlined sx={{ fontSize: 16, color: 'var(--mainColor)' }} />
					</IconButton>
				)}
			</Stack>
			<MissionSundayForm
				handleGetReport={handleGetReport}
				loading={missionSundayReportData.loading}
				onCancel={handleCancel}
				isEdit={isEdit}
				isShowFilter={isShowFilter}
				formData={formData}
				setFormData={setFormData}
			/>
			{!missionSundayReportData.loading && missionSundayReportData?.data?.length ? (
				<MissionSundayView
					missionSundayReportData={missionSundayReportData?.data || []}
					start={dateRange.start}
					end={dateRange.end}
					handleGetReportPdf={handleGetReportPdf}
				/>
			) : null}
		</Stack>
	);
};

export default MissionSundayReport;
