import React, { useState } from 'react';
import { Stack, Typography, Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Notes } from '@mui/icons-material';
import { useGlobalStyles } from 'Components/Common/global/global';
import { NotesData } from 'hooks/useNotesActions';

interface P {
	selectedEditData?: NotesData;
	onSubmit?: (notValue: string) => void;
	saveLoading?: boolean;
}
const NotesModalContent = ({ selectedEditData, onSubmit, saveLoading }: P) => {
	const global = useGlobalStyles();
	const [values, setValues] = useState({ note: selectedEditData ? selectedEditData.note : '' });

	const handleChange = (value: string) => {
		setValues((prevState) => {
			return {
				...prevState,
				note: value,
			};
		});
	};

	const handleSubmit = () => {
		onSubmit?.(values.note);
	};

	return (
		<>
			<Stack>
				<Grid container>
					<Grid item>
						<Notes className={global.formLabelIcon} />
					</Grid>
					<Grid item>
						<Typography variant="body2" className={global.formLabelText}>
							Note
						</Typography>
					</Grid>
				</Grid>
				<TextField multiline rows={4} value={values.note} onChange={(e) => handleChange(e.target.value)} />
			</Stack>

			<LoadingButton
				fullWidth
				type="submit"
				color="primary"
				variant="contained"
				sx={{ marginTop: '30px' }}
				onClick={handleSubmit}
				disabled={!values.note}
				loading={saveLoading}
				loadingPosition="start"
			>
				{selectedEditData ? 'Edit Note' : 'Add Note'}
			</LoadingButton>
		</>
	);
};

export default NotesModalContent;
