import axios from 'axios';

export default () => {
	const baseURL = process.env.REACT_APP_BACKEND_URL;

	const axiosInstance = axios.create({
		baseURL: baseURL,
	});

	axiosInstance.interceptors.response.use(
		(response) =>
			new Promise((resolve, reject) => {
				resolve(response);
			}),
		(error) => {
			return new Promise((resolve, reject) => {
				reject(error);
			});
		},
	);

	return axiosInstance;
};
