import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Stack, Button, Tooltip } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ArrowBackIosNew, AddTask, Add, Delete } from '@mui/icons-material';
import { useStyles } from '../NonCashDonation.style';
import { useGlobalStyles } from 'Components/Common/global/global';
import { ContentSkeleton } from 'Components/Deposits/CreateEditDepositWithDonations/Content';
import { StateType } from 'contexts/intialstates/Types';
import CreateEditNonCashDonationForm, { NonCashFormValues } from './CreateEditNonCashDonationForm';
import useNonCashDonationActions, { NonCashDonationData } from 'hooks/useNonCashDonation';
import ActionModal from 'Components/Reusable/Modals/ActionModal/ActionModal';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import moments from 'moment';
import { GenerateNoncasReceipt } from 'Components/Reusable/GenerateReceipt/GenerateReceipt';
import useUserActions from 'hooks/useUserActions';

const CreateEditNonCashDonations = () => {
	const { handleRoles } = useUserActions();
	const { id } = useParams();
	const navigate = useNavigate();
	const classes = useStyles();
	const global = useGlobalStyles();
	const {
		createNonCashDonation,
		updateNonCashDonation,
		deleteNonCashDonation,
		getNonCashDonationById,
		postNonCashDonation,
	} = useNonCashDonationActions();
	const [formData, setFormData] = useState<NonCashFormValues>({
		status: 'Pending',
		error: [],
	});
	const [saveLoading, setSaveLoading] = useState(false);
	const [reload, setReload] = useState(false);
	const [nonCashDonation, setNonCashDonation] = useState<StateType<NonCashDonationData>>({
		data: null,
		loading: false,
		error: null,
	});
	const [openPostModal, setOpenPostModal] = useState(false);
	const [postLoading, setPostLoading] = useState(false);
	const [isDelete, setIsDelete] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [openRequiredModal, setOpenRequiredModal] = useState(false);

	useEffect(() => {
		if (!id) return;
		getNonCashDonationById({ nonCashDonation, setNonCashDonation, id: parseInt(id) });
	}, [id, reload]);

	useEffect(() => {
		const data = nonCashDonation?.data;
		if (data) {
			setFormData({
				societyId: data.societyId,
				status: data.status === 1 ? 'Pending' : 'Posted',
				amount: data.amount.toString(),
				donor: data.donor
					? {
							id: parseInt(data.donor.id),
							name: `${data.donor.firstName || ''} ${data.donor.lastName || ''}`,
					  }
					: undefined,
				donationType: {
					id: data.donationTypeId,
					label: `${data.donationType?.type}-${data.donationType?.description}`,
				},
				giftType: data.giftTypeId ? { id: data.giftTypeId, type: data.giftType.type } : undefined,
				receivedDate: data.receivedDate,
			});
		}
	}, [nonCashDonation?.data]);

	const handleSave = async () => {
		if (
			!formData?.amount ||
			!formData?.societyId ||
			!formData?.donationType ||
			!formData?.giftType ||
			!formData?.receivedDate
		) {
			const error = [];
			if (!formData?.donationType) error.push('donationType');
			if (!formData?.amount) error.push('amount');
			if (!formData?.societyId) error.push('societyId');
			if (!formData?.giftType) error.push('giftType');
			if (!formData?.receivedDate) error.push('receivedDate');
			setFormData({
				...formData,
				error,
			});
			setOpenRequiredModal(true);
		} else {
			const payload = {
				donorId: formData.donor?.id,
				amount: parseInt(formData.amount),
				societyId: formData.societyId,
				donationTypeId: formData.donationType?.id,
				giftTypeId: formData.giftType?.id,
				receivedDate: moments(formData.receivedDate).format('YYYY-MM-DD'),
			};
			setSaveLoading(true);
			let isSucess;
			if (id) {
				isSucess = await updateNonCashDonation(payload, parseInt(id));
				if (isSucess) {
					setReload(!reload);
				}
			} else {
				const id = await createNonCashDonation(payload);
				id && navigate(`/bookkeeping/${id}`);
			}
			setSaveLoading(false);
		}
	};

	const handleConfirmPost = async () => {
		if (!id) return;
		setPostLoading(true);
		const isSucess = await postNonCashDonation(parseInt(id));
		if (isSucess) {
			setReload(!reload);
		}
		setPostLoading(false);
		setOpenPostModal(false);
	};

	const handleDelete = async () => {
		if (id) {
			setDeleteLoading(true);
			const isSucess = await deleteNonCashDonation(parseInt(id));
			setDeleteLoading(false);
			setIsDelete(false);
			if (isSucess) {
				navigate('/bookkeeping/non-cash-donations');
			}
		}
	};

	return (
		<div>
			{nonCashDonation?.loading ? (
				<ContentSkeleton />
			) : (
				<Stack spacing={3}>
					<Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap={'wrap'}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={4}
							marginBottom={2}
						>
							<Stack className={classes.backIconWrapper}>
								<ArrowBackIosNew onClick={() => navigate('/bookkeeping/non-cash-donations')} />
							</Stack>
							<Stack alignItems={'flex-start'}>
								<span className={classes.title}>Non Cash Donation</span>
								<span className={classes.subTitle}>{formData.status}</span>
							</Stack>
						</Stack>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems={'center'}
							spacing={2}
							marginBottom={2}
						>
							{id && (
								<>
									{handleRoles('bookkeeping-list') && (
										<GenerateNoncasReceipt
											id={nonCashDonation?.data?.depositId as number}
											isDisabled={!nonCashDonation?.data?.depositId}
										/>
									)}
									{handleRoles('bookkeeping-actions') && (
										<>
											<Tooltip
												title={
													formData?.status === 'Posted' ? 'Posted non cash donation cannot be deleted' : ''
												}
											>
												<span>
													<Button
														startIcon={<Delete />}
														size={'small'}
														variant="contained"
														disabled={formData?.status === 'Posted'}
														onClick={() => setIsDelete(true)}
													>
														Delete
													</Button>
												</span>
											</Tooltip>
											<Button
												startIcon={<AddTask />}
												size={'small'}
												variant="outlined"
												disabled={formData?.status === 'Posted'}
												className={formData?.status !== 'Posted' ? global.whiteBtn : ''}
												onClick={() => setOpenPostModal(true)}
											>
												Post
											</Button>
										</>
									)}
								</>
							)}
							{handleRoles('bookkeeping-actions') && (
								<LoadingButton
									loadingPosition="start"
									loading={saveLoading}
									startIcon={<Add />}
									size={'small'}
									variant="contained"
									onClick={handleSave}
									disabled={formData?.status === 'Posted'}
								>
									Save
								</LoadingButton>
							)}
						</Stack>
					</Stack>
					<CreateEditNonCashDonationForm
						formData={formData}
						setFormData={setFormData}
						isDisabled={formData.status === 'Posted'}
					/>
					{openPostModal && (
						<ActionModal
							open={openPostModal}
							title={'Post Non Cash Donation'}
							subTitle={'Are you sure you want to Post this Non Cash Donation?'}
							description={`Note: Once a non cash donation is Posted you will not be able to make any changes`}
							submitText={'Post'}
							handleSubmit={handleConfirmPost}
							closeText={'Cancel'}
							handleClose={() => setOpenPostModal(false)}
							loading={postLoading}
						/>
					)}
					{isDelete && (
						<ConfirmationModal
							handleClose={() => setIsDelete(false)}
							message={'Are you sure you want to delete this non cash donation?'}
							open={isDelete}
							handleConfirmation={handleDelete}
							handleCancel={() => setIsDelete(false)}
							loading={deleteLoading}
						/>
					)}
					{openRequiredModal && (
						<ActionModal
							open={openRequiredModal}
							title={'Required field'}
							subTitle={'Please fill all required fields'}
							closeText={'Close'}
							handleClose={() => setOpenRequiredModal(false)}
						/>
					)}
				</Stack>
			)}
		</div>
	);
};

export default CreateEditNonCashDonations;
