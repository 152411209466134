import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import {
	Button,
	Paper,
	Stack,
	Grid,
	Typography,
	IconButton,
	Tooltip,
	Autocomplete,
	TextField,
	FormHelperText,
} from '@mui/material';
import {
	Add,
	KeyboardArrowLeft,
	TaskAlt,
	Numbers,
	CalendarToday,
	Archive,
	ContentCopy,
	MonetizationOn,
	BorderColor,
	AccountCircle,
} from '@mui/icons-material';
import { useGlobalStyles } from 'Components/Common/global/global';
import { ContentSkeleton } from 'Components/Deposits/CreateEditDepositWithDonations/Content';
import { Formik, Form, Field } from 'formik';
import { FormTextField } from 'Components/Common/TextField';
import usePostDatedChequesAction from 'hooks/usePostDatedChequesAction';
import { StateType, PostDatedCheques, Donor } from 'contexts/intialstates/Types';
import * as yup from 'yup';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import { LoadingButton } from '@mui/lab';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import useDonorAction from 'hooks/useDonorActions';
import useDebounce from 'Components/Reusable/hooks/debounce';
import useUserActions from 'hooks/useUserActions';

export interface PostDatedChequesValues {
	id?: number;
	cheque: string;
	donorId?: number;
	amount?: number;
	receivedDate: string;
	dueDate: string;
	remark: string;
}

const CreateEditPostDatedCheque = () => {
	const { handleRoles } = useUserActions();
	const global = useGlobalStyles();
	const navigate = useNavigate();
	const { id } = useParams();
	const {
		createPostDatedCheque,
		updatePostDatedCheque,
		getPostDatedChequeByID,
		depositPostDatedChequeByID,
		deletePostDatedCheque,
	} = usePostDatedChequesAction();
	const [reload, setReload] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);

	const [depositPostDatedCheque, setDepositPostDatedCheque] = React.useState<StateType<PostDatedCheques>>({
		data: null,
		error: null,
		loading: false,
	});
	const [initial, setInitial] = useState<PostDatedChequesValues>({
		id: undefined,
		cheque: '',
		donorId: undefined,
		amount: undefined,
		receivedDate: '',
		dueDate: '',
		remark: '',
	});
	const [postDatedChequeById, setPostDatedChequeById] = React.useState<StateType<PostDatedCheques>>({
		data: null,
		error: null,
		loading: false,
	});
	const [isDelete, setIsDelete] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [donorId, setDonorId] = React.useState<number>();
	const [searchDonors, setSearchDonors] = useState<StateType<Donor[]>>({
		data: null,
		loading: false,
		error: null,
	});

	const gotDonor = searchDonors?.data?.find((donor: Donor) => donor.id === donorId?.toString());
	const { getSearchDonors } = useDonorAction();

	const debouncedSearchTerm = useDebounce(searchTerm, 1000);

	useEffect(() => {
		getSearchDonors({ searchDonors, setSearchDonors, searchTerm });
	}, [debouncedSearchTerm]);
	useEffect(() => {
		if (!id) return;
		getPostDatedChequeByID({ setPostDatedChequeById, postDatedChequeById, id: parseInt(id) });
	}, [id, postDatedChequeById.data?.status, reload]);

	useEffect(() => {
		if (!postDatedChequeById?.data) return;
		const { id, cheque, donor, amount, receivedDate, dueDate, remark } = postDatedChequeById?.data;
		setInitial({
			id,
			cheque,
			donorId: parseInt(donor?.id),
			amount,
			receivedDate,
			dueDate,
			remark,
		});
	}, [postDatedChequeById]);

	const handleSubmit = async (values: PostDatedChequesValues) => {
		setLoading(true);
		if (id) {
			await updatePostDatedCheque({ ...values, amount: parseInt(values.amount as any) }, parseInt(id));
		} else {
			const response = await createPostDatedCheque({ ...values, amount: parseInt(values.amount as any) });
			if (response && typeof response === 'object') {
				navigate(`../${response?.id}`);
			}
		}
		setLoading(false);
	};

	const validationHcaOrders = yup.object().shape({
		cheque: yup.number().typeError('Invalid cheque number').required('Cheque is required'),
		amount: yup.number().typeError('Invalid amount').required('Total amount is required'),
		receivedDate: yup.string().required('Received date is required'),
		dueDate: yup.string().required('Due date is required'),
	});

	const handleDeposit = async () => {
		if (!id) return;
		await depositPostDatedChequeByID({ depositPostDatedCheque, setDepositPostDatedCheque, id: parseInt(id) });
		setReload(!reload);
	};

	const handleCloseDelete = () => {
		setIsDelete(false);
	};

	const handleDelete = async () => {
		if (id) {
			setSaveLoading(true);
			const isSucess = await deletePostDatedCheque(parseInt(id));
			setSaveLoading(false);
			handleCloseDelete();
			if (isSucess) {
				navigate('../');
			}
		}
	};

	return (
		<>
			<Box>
				{postDatedChequeById?.loading && initial ? (
					<ContentSkeleton />
				) : (
					<Formik
						enableReinitialize
						onSubmit={handleSubmit}
						validationSchema={validationHcaOrders}
						initialValues={initial}
					>
						{({ values, setFieldValue, touched, errors }) => (
							<Form noValidate autoComplete="off">
								<Stack
									direction={{ xs: 'row', sm: 'column' }}
									alignItems={{ xs: 'center', sm: 'initial' }}
									justifyContent={{ xs: 'center', sm: 'initial' }}
									spacing={{ xs: 3, sm: 0 }}
									mb={2}
								>
									<Stack
										alignItems={'center'}
										justifyContent={'center'}
										sx={{ display: { xs: 'flex', sm: 'none' }, background: '#fff' }}
									>
										<IconButton
											id="demo-customized-button"
											aria-haspopup="true"
											onClick={() => {
												navigate('/bookkeeping/post-dated-cheques');
											}}
										>
											<KeyboardArrowLeft color="primary" />
										</IconButton>
									</Stack>
									<Stack
										alignItems="center"
										direction={{ xs: 'column', sm: 'row' }}
										justifyContent={'space-between'}
										spacing={1}
									>
										<Stack
											alignItems={'center'}
											direction="row"
											justifyContent={'flex-start'}
											spacing={{ xs: 0, sm: 4 }}
											mb={{ xs: 1, sm: 0 }}
										>
											<Stack
												alignItems={'center'}
												justifyContent={'center'}
												sx={{ display: { xs: 'none', sm: 'flex' }, background: '#fff' }}
											>
												<IconButton
													id="demo-customized-button"
													aria-haspopup="true"
													onClick={() => {
														navigate('/bookkeeping/post-dated-cheques');
													}}
												>
													<KeyboardArrowLeft color="primary" />
												</IconButton>
											</Stack>
											<Stack direction="column">
												<Box
													sx={{
														fontSize: '32px',
														fontWeight: 700,
														color: 'var(--mainColor)',
														wordWrap: 'normal',
													}}
												>
													{id ? (
														<>
															Cheque ID: <span style={{ fontWeight: 900 }}>{id}</span>
														</>
													) : (
														'New Post Dated Cheque'
													)}
												</Box>
												{id && (
													<Button
														sx={{
															cursor: 'initial',
															width: 100,
															p: '4px 8px',
															borderRadius: '6px',
															fontSize: 12,
															textTransform: 'capitalize',
															background: '#fff',
															'&:hover': { background: '#fff', color: '#1976D2' },
															color: '#1976D2',
														}}
														disableElevation
														disableRipple
														variant="outlined"
													>
														{postDatedChequeById?.data?.status}
													</Button>
												)}
											</Stack>
										</Stack>
										{handleRoles('bookkeeping-actions') && (
											<Stack
												direction={{ xs: 'column', md: 'row' }}
												justifyContent={'flex-end'}
												flexWrap="wrap"
												gap={3}
											>
												{id && (
													<Button
														sx={{ textTransform: 'capitalize' }}
														variant="contained"
														disableElevation
														startIcon={<Archive />}
														onClick={() => setIsDelete(true)}
														disabled={postDatedChequeById?.data?.status === 'Deposited'}
													>
														Archive
													</Button>
												)}
												{id && (
													<Tooltip
														title={
															postDatedChequeById?.data?.status === 'Deposited'
																? 'Cheque has been deposited'
																: ''
														}
													>
														<span>
															<LoadingButton
																sx={{
																	textTransform: 'capitalize',
																	background: `${
																		postDatedChequeById?.data?.status === 'Deposited' ? 'initial' : '#fff'
																	}`,
																}}
																variant={
																	postDatedChequeById?.data?.status === 'Deposited' ? 'contained' : 'outlined'
																}
																disableElevation
																startIcon={<TaskAlt />}
																onClick={handleDeposit}
																loading={depositPostDatedCheque.loading}
																loadingPosition="start"
																disabled={postDatedChequeById?.data?.status === 'Deposited'}
															>
																Deposit
															</LoadingButton>
														</span>
													</Tooltip>
												)}

												<Tooltip
													title={
														postDatedChequeById && postDatedChequeById?.data?.status === 'Deposited'
															? 'Cannot update deposited cheques'
															: ''
													}
												>
													<span>
														<LoadingButton
															sx={{ textTransform: 'capitalize' }}
															variant="contained"
															disableElevation
															startIcon={<Add />}
															type="submit"
															loading={loading}
															loadingPosition="start"
															disabled={
																postDatedChequeById && postDatedChequeById?.data?.status === 'Deposited'
															}
														>
															Save
														</LoadingButton>
													</span>
												</Tooltip>
											</Stack>
										)}
									</Stack>
								</Stack>
								<Paper className={global.paperFormWrap}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Grid container>
												<Grid item>
													<ContentCopy className={global.formLabelIcon} />
												</Grid>
												<Grid item>
													<Typography
														variant="body2"
														sx={{ fontWeight: 600, fontSize: 15, color: 'var(--formLabelColor)' }}
													>
														Cheque
													</Typography>
												</Grid>
												<Grid item>
													<Numbers sx={{ color: 'var(--formLabelColor)', fontSize: 20 }} />
												</Grid>
											</Grid>
											<Field
												name="cheque"
												variant="outlined"
												placeholder="Cheque"
												component={FormTextField}
												disabled={postDatedChequeById.data?.status === 'Deposited'}
											/>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Grid container direction={'column'}>
												<Stack direction="row">
													<Grid item>
														<AccountCircle className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography variant="body2" className={global.formLabelText}>
															Donor Name
														</Typography>
													</Grid>
												</Stack>
											</Grid>
											<Autocomplete
												freeSolo
												loadingText="loading..."
												loading={searchDonors.loading}
												size="small"
												value={
													gotDonor
														? `${gotDonor.firstName ?? ''}  ${gotDonor.lastName ?? ''}`
														: postDatedChequeById?.data?.donor
														? `${postDatedChequeById?.data?.donor.firstName ?? ''}  ${
																postDatedChequeById?.data?.donor?.lastName ?? ''
														  }`
														: ''
												}
												sx={{
													background: `${
														postDatedChequeById?.data?.status === 'Deposited'
															? 'var(--invalidBackground)'
															: 'initial'
													}`,
												}}
												onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
													setDonorId(val?.id);
													setFieldValue('donorId', parseInt(val?.id));
												}}
												disabled={postDatedChequeById?.data?.status === 'Deposited'}
												options={(searchDonors?.data || []).map((donor: Donor) => ({
													id: donor.id,
													label: `${donor.firstName ?? ''} ${donor.lastName ?? ''}`,
												}))}
												renderInput={(params) => (
													<Stack direction="row" spacing={1}>
														<TextField
															{...params}
															variant="outlined"
															label=""
															InputProps={{
																...params.InputProps,
															}}
															onChange={(event: any) => {
																setSearchTerm(event.target.value);
															}}
														/>
													</Stack>
												)}
											/>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Grid container>
												<Grid item>
													<MonetizationOn className={global.formLabelIcon} />
												</Grid>
												<Grid item>
													<Typography
														variant="body2"
														sx={{ fontWeight: 600, fontSize: 15, color: 'var(--formLabelColor)' }}
													>
														Amount
													</Typography>
												</Grid>
											</Grid>
											<Field
												name="amount"
												variant="outlined"
												placeholder="Amount"
												component={FormTextField}
												disabled={postDatedChequeById.data?.status === 'Deposited'}
											/>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Grid container>
												<Grid item>
													<CalendarToday className={global.formLabelIcon} />
												</Grid>
												<Grid item>
													<Typography
														variant="body2"
														sx={{ fontWeight: 600, fontSize: 15, color: 'var(--formLabelColor)' }}
													>
														Received Date
													</Typography>
												</Grid>
											</Grid>
											<Field name="receivedDate" variant="outlined" placeholder=" Received Date">
												{() => (
													<BasicDatePicker
														disabled={postDatedChequeById.data?.status === 'Deposited'}
														value={values.receivedDate || null}
														setValue={(e) => {
															setFieldValue('receivedDate', e.target.value);
														}}
													/>
												)}
											</Field>
											{touched?.receivedDate && errors?.receivedDate && (
												<FormHelperText error>{errors?.receivedDate}</FormHelperText>
											)}
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Grid container>
												<Grid item>
													<CalendarToday className={global.formLabelIcon} />
												</Grid>
												<Grid item>
													<Typography
														variant="body2"
														sx={{ fontWeight: 600, fontSize: 15, color: 'var(--formLabelColor)' }}
													>
														Due Date
													</Typography>
												</Grid>
											</Grid>
											<Field name="dueDate" variant="outlined" placeholder=" Due Date">
												{() => (
													<BasicDatePicker
														disabled={postDatedChequeById.data?.status === 'Deposited'}
														value={values.dueDate || null}
														setValue={(e) => {
															setFieldValue('dueDate', e.target.value);
														}}
													/>
												)}
											</Field>
											{touched?.dueDate && errors?.dueDate && (
												<FormHelperText error>{errors?.dueDate}</FormHelperText>
											)}
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Grid container>
												<Grid item>
													<BorderColor className={global.formLabelIcon} />
												</Grid>
												<Grid item>
													<Typography variant="body2" className={global.formLabelText}>
														Remark
													</Typography>
												</Grid>
											</Grid>
											<Field
												name="remark"
												variant="outlined"
												placeholder="Remark"
												component={FormTextField}
												multiline
												rows={3}
												disabled={postDatedChequeById.data?.status === 'Deposited'}
											/>
										</Grid>
									</Grid>
								</Paper>
							</Form>
						)}
					</Formik>
				)}
			</Box>
			<ConfirmationModal
				handleClose={() => setIsDelete(false)}
				message={'Are you sure you want to delete this cheque ?'}
				open={isDelete}
				handleConfirmation={handleDelete}
				handleCancel={() => setIsDelete(false)}
				loading={saveLoading}
			/>
		</>
	);
};

export default CreateEditPostDatedCheque;
