import React from 'react';
import {
	Grid,
	Stack,
	Typography,
	Select,
	MenuItem,
	Divider,
	TextField,
	Autocomplete,
	Box,
	CircularProgress,
} from '@mui/material';
import { useGlobalStyles } from 'Components/Common/global/global';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import { Search, Add } from '@mui/icons-material';
import { useStyles } from '../../Annuities.style';
import ProvinceAutocomplete from 'Components/Province';

export interface Field {
	id: string;
	title: string;
	icon: any;
	fieldType: string;
	placeHolder?: string;
	menu?: {
		id: number;
		name: string;
	}[];
	isSearch?: boolean;
	isAdd?: boolean;
	hide?: boolean;
	disabled?: boolean;
	maxDate?: any;
	isDonorAutocompleteSearch?: boolean;
	isCoAutocompleteSearch?: boolean;
}

export interface FieldLayout {
	id?: string;
	hide?: boolean;
	title?: string;
	field: Field[];
}

export interface AutocompleteValue {
	id: number;
	name: string;
}

export interface Error {
	name: string;
	error: string;
}

interface P {
	fields: FieldLayout[];
	values?: { [key: string]: any };
	errors?: Error[];
	isAllDisabled?: boolean;
	handleChange: (name: string, value: string | number) => void;
	handleSearch?: (name: string) => void;
	handleAdd?: (name: string) => void;
	handleAutocompleteChange?: (name: string, value: any) => void;
	handleAutocompleteSearchChange?: (name: string, value: string) => void;
}

const FormFieldLayout = ({
	fields,
	values,
	errors,
	handleChange,
	isAllDisabled,
	handleSearch,
	handleAdd,
	handleAutocompleteChange,
	handleAutocompleteSearchChange,
}: P) => {
	const global = useGlobalStyles();
	const classes = useStyles();

	return (
		<div>
			{fields.map(({ title, field, hide }) => (
				<>
					{!hide && (
						<>
							{title && (
								<>
									<Typography sx={{ fontSize: 14, fontWeight: 600, mt: 2 }}>{title}</Typography>
									<Divider />
								</>
							)}
							<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
								{field.map(
									({
										id,
										title,
										icon,
										fieldType,
										menu,
										placeHolder,
										isSearch,
										isAdd,
										disabled,
										hide,
										maxDate,
										isDonorAutocompleteSearch,
										isCoAutocompleteSearch,
									}) => (
										<React.Fragment key={id}>
											{!hide && (
												<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
													<Grid container>
														<Grid item>
															<Stack className={global.formLabelIconSvg}>{icon}</Stack>
														</Grid>
														<Grid item>
															<Typography variant="body2" className={global.formLabelText}>
																{title}
															</Typography>
														</Grid>
													</Grid>
													<Stack>
														{fieldType === 'select' ? (
															<Select
																fullWidth
																size={'small'}
																value={(values?.[id] as string | number) ?? ''}
																onChange={(e) => handleChange(id, e.target.value)}
																disabled={disabled || isAllDisabled}
																className={disabled || isAllDisabled ? global.disabledInput : ''}
															>
																{menu?.map((x) => (
																	<MenuItem key={x.id} value={x.id}>
																		{x.name}
																	</MenuItem>
																))}
															</Select>
														) : fieldType === 'date' ? (
															<BasicDatePicker
																size="small"
																value={values?.[id]}
																setValue={(val) => {
																	handleChange(id, val.target.value);
																}}
																disabled={disabled}
																maxDate={maxDate}
															/>
														) : fieldType === 'textArea' ? (
															<TextField
																variant="outlined"
																size="small"
																fullWidth
																value={values?.[id]}
																placeholder={placeHolder}
																multiline
																rows={3}
																onChange={(e) => handleChange(id, e.target.value)}
																disabled={disabled || isAllDisabled}
																className={disabled || isAllDisabled ? global.disabledInput : ''}
															/>
														) : fieldType === 'autocomplete' ? (
															<Stack flexDirection={'row'} alignItems={'center'}>
																<Autocomplete
																	loading={isDonorAutocompleteSearch || isCoAutocompleteSearch}
																	loadingText="loading..."
																	freeSolo
																	fullWidth
																	disabled={disabled || isAllDisabled}
																	value={values?.[id]?.name ? values?.[id]?.name : ''}
																	onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
																		handleAutocompleteChange?.(id, val);
																	}}
																	options={(menu || []).map(({ id, name }) => ({
																		id,
																		label: name,
																	}))}
																	renderOption={(props, option) => (
																		<Box component="li" {...props} key={option.id}>
																			{option.label}
																		</Box>
																	)}
																	renderInput={(params) => (
																		<TextField
																			{...params}
																			variant="outlined"
																			label=""
																			InputProps={{
																				...params.InputProps,
																			}}
																			onChange={(event: any) => {
																				handleAutocompleteSearchChange?.(id, event.target.value);
																			}}
																			size={'small'}
																			placeholder={'Search donor'}
																			className={disabled || isAllDisabled ? classes.disabledInput : ''}
																		/>
																	)}
																/>
																{isSearch && (
																	<div
																		className={
																			disabled || isAllDisabled ? classes.disabledIcon : classes.IconBtn
																		}
																		onClick={() => !(disabled || isAllDisabled) && handleSearch?.(id)}
																	>
																		<Search />
																	</div>
																)}
																{isAdd && (
																	<div
																		className={
																			disabled || isAllDisabled ? classes.disabledIcon : classes.IconBtn
																		}
																		onClick={() => !(disabled || isAllDisabled) && handleAdd?.(id)}
																	>
																		<Add />
																	</div>
																)}
															</Stack>
														) : fieldType === 'province' ? (
															<ProvinceAutocomplete
																disabled={disabled || isAllDisabled}
																value={typeof values?.[id] === 'object' ? values?.[id]?.name : values?.[id]}
																handleChange={(val) => handleAutocompleteChange?.(id, val)}
															/>
														) : fieldType === 'password' ? (
															<TextField
																variant="outlined"
																size="small"
																fullWidth
																value={values?.[id]}
																type={'password'}
																placeholder={placeHolder}
																onChange={(e) => handleChange(id, e.target.value)}
																disabled={disabled || isAllDisabled}
																className={disabled || isAllDisabled ? global.disabledInput : ''}
															/>
														) : fieldType === 'number' ? (
															<TextField
																variant="outlined"
																size="small"
																fullWidth
																value={values?.[id]}
																type={'number'}
																placeholder={placeHolder}
																onChange={(e) => handleChange(id, e.target.value)}
																disabled={disabled || isAllDisabled}
																className={disabled || isAllDisabled ? global.disabledInput : ''}
															/>
														) : (
															<TextField
																variant="outlined"
																size="small"
																fullWidth
																value={values?.[id]}
																placeholder={placeHolder}
																onChange={(e) => handleChange(id, e.target.value)}
																disabled={disabled || isAllDisabled}
																className={disabled || isAllDisabled ? global.disabledInput : ''}
															/>
														)}
														{errors?.find((item) => item.name === id) && (
															<span className={global.errorText}>
																{errors?.find((item) => item.name === id)?.error}
															</span>
														)}
													</Stack>
												</Grid>
											)}
										</React.Fragment>
									),
								)}
							</Grid>
						</>
					)}
				</>
			))}
		</div>
	);
};

export default FormFieldLayout;
