import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormLabel } from '@mui/material';
import './DonorCheckBoxes.css';

export type TDonorEdge = {
	label: string;
	key: string;
	value: boolean | undefined;
};

interface IDonorCheckBoxes {
	title: string;
	edges: Array<TDonorEdge>;
	disabled?: boolean;
	setFieldValue?: (field: string, value: boolean) => void;
}

const DonorCheckBoxes = (props: IDonorCheckBoxes) => {
	const { title, edges, disabled, setFieldValue } = props;

	return (
		<>
			<FormControl sx={{ m: 2 }} component="fieldset" variant="standard">
				<FormLabel component="legend">{title}</FormLabel>
				{edges.map((edge: TDonorEdge) => {
					return (
						<FormControlLabel
							key={edge.key}
							disabled={disabled ?? false}
							control={
								<Checkbox
									onChange={(e) => setFieldValue?.(edge.key, e.target.checked)}
									defaultChecked={edge.value}
									name={edge.key}
								/>
							}
							label={edge.label}
						/>
					);
				})}
			</FormControl>
		</>
	);
};
export default DonorCheckBoxes;
