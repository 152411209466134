import {
	PAYMENTMETHODS_LOADING,
	PAYMENTMETHODS_LOAD_SUCCESS,
	PAYMENTMETHODS_LOAD_ERROR,
	PAYMENTMETHOD_LOADING,
	PAYMENTMETHOD_LOAD_SUCCESS,
	PAYMENTMETHOD_LOAD_ERROR,
} from 'contexts/actionTypes';
import {
	ResponseError,
	Action,
	AllPaymentMethodStateType,
	PaymentMethodData,
	PaymentMethod,
	SinglePaymentMethodStateType,
} from 'contexts/intialstates/Types';

export const allPaymentmethods = (
	state: AllPaymentMethodStateType,
	{ payload, type }: Action<PaymentMethodData>,
): AllPaymentMethodStateType => {
	switch (type) {
		case PAYMENTMETHODS_LOADING: {
			return {
				...state,
				error: null,
				loading: true,
			};
		}

		case PAYMENTMETHODS_LOAD_SUCCESS: {
			return {
				...state,
				loading: false,
				error: null,
				data: payload as PaymentMethodData,
			};
		}
		case PAYMENTMETHODS_LOAD_ERROR: {
			return {
				...state,
				loading: false,
				error: payload as ResponseError,
			};
		}
		default:
			return state;
	}
};

export const singlePaymentMethod = (
	state: SinglePaymentMethodStateType,
	{ payload, type }: Action<PaymentMethod>,
): SinglePaymentMethodStateType => {
	switch (type) {
		case PAYMENTMETHOD_LOADING: {
			return {
				...state,
				loading: true,
				error: null,
			};
		}

		case PAYMENTMETHOD_LOAD_SUCCESS: {
			return {
				...state,
				loading: false,
				error: null,
				data: payload as PaymentMethod,
			};
		}
		case PAYMENTMETHOD_LOAD_ERROR: {
			return {
				...state,
				loading: false,
				error: payload as ResponseError,
			};
		}
		default:
			return state;
	}
};
