import { ResponseError, StateType } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';

export interface ReceiptSchedule {
    id: number;
    schedule: string;
}

export interface GetReceiptScheduleProps {
    receiptSchedule: StateType<ReceiptSchedule[]>;
    setReceiptSchedule: React.Dispatch<React.SetStateAction<StateType<ReceiptSchedule[]>>>;
}

const useReceiptScheduleActions = () => {
    const { api } = useApi();

    const getReceiptSchedule = async ({ receiptSchedule, setReceiptSchedule }: GetReceiptScheduleProps) => {
        try {
            setReceiptSchedule({
                ...receiptSchedule,
                loading: true,
            });
            const response = await api.get(
                `receiptSchedule/list`
            );

            setReceiptSchedule({
                ...receiptSchedule,
                loading: false,
                data: response?.data?.result,
                error: null,
            });
        } catch (err) {
            if (Axios.isAxiosError(err)) {
                setReceiptSchedule({
                    ...receiptSchedule,
                    loading: false,
                    error: errorCodes(err?.response?.data) as ResponseError,
                });
            }
        }
    };

    return {
        getReceiptSchedule,
    };
};

export default useReceiptScheduleActions;
