import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { Stack, Autocomplete, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';

import useDioceseActions from 'hooks/useDioceseActions';
import { DioceseData, StateType, FilterPayload, Diocese } from 'contexts/intialstates/Types';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { ColumnType, dioceseHead } from 'Components/Common/Tables/TableHeaders';
import { OrderType } from 'contexts/intialstates/Types';
import { useStyles } from './style';
import DioceseTable from 'Components/AdminPanel/Diocese/DioceseTable';
import AdvancedSearchModal, {
	Field as AdvanceSearchField,
} from 'Components/Reusable/Modals/AdvancedSearch/AdvancedSearchModal';
import useDebounce from 'Components/Reusable/hooks/debounce';
import { searchDioceseField } from 'Components/Reusable/Modals/AdvancedSearch/Mock';

interface P {
	values: any;
	setFieldValue: any;
}
const DioceseField = ({ values, setFieldValue }: P) => {
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);
	const classes = useStyles();
	const { getDioceses, getDioceseBySearch } = useDioceseActions();
	const [dioceses, setDioceses, ,] = React.useState<StateType<DioceseData>>({
		data: null,
		error: null,
		loading: false,
	});

	const [searchDiocese, setSearchDiocese] = React.useState<StateType<Diocese[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [open, setOpen] = React.useState<any>({ state: false, type: '' });
	const [searchFieldsDiocese, setSearchFieldsDiocese] = useState<AdvanceSearchField[]>(searchDioceseField);
	const [visibleSearchDiocese, setVisibleSearchDiocese] = useState(false);
	const [dioceseSearchTerm, setDioceseSearchTerm] = useState('');
	const dioceseDebouncedSearchTerm = useDebounce(dioceseSearchTerm, 1000);

	const handleVisibleSearchDiocese = () => {
		setVisibleSearchDiocese(!visibleSearchDiocese);
	};
	const handleSearchDiocese = () => {
		const payload: any = searchFieldsDiocese.map(({ key, value }) => {
			if (value) {
				return {
					field: key,
					query: key !== 'phone' ? value : null,
					value: key === 'phone' ? [value] : null,
					from: null,
					to: null,
				};
			}
		});

		setFilterPayload(payload.filter(Boolean));
	};
	useEffect(() => {
		getDioceses({
			archive: '&Filters=field=archived;value=false',
			setDioceses,
			dioceses,
			pageSize,
			page,
			orderBy,
			filterPayload,
		});
	}, [page, pageSize, orderBy, filterPayload.length]);

	useEffect(() => {
		if (!dioceseDebouncedSearchTerm) return;
		getDioceseBySearch({ searchDiocese, setSearchDiocese, searchTerm: dioceseSearchTerm });
	}, [dioceseDebouncedSearchTerm]);

	const handleSelectRowDiocese = (row: any) => {
		setFieldValue('diocese', { id: parseInt(row?.id), dioceseName: row?.dioceseName });

		handleVisibleSearchDiocese();
	};

	return (
		<>
			<Stack flexDirection={'row'} alignItems={'center'}>
				<Autocomplete
					freeSolo
					value={values?.diocese?.dioceseName ? `${values?.diocese?.dioceseName}` : ''}
					fullWidth
					loading={searchDiocese.loading}
					loadingText="loading..."
					onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
						setFieldValue('diocese', {
							id: val?.id,
							dioceseName: val?.label,
						});
					}}
					options={(searchDiocese?.data || []).map((item: any) => ({
						id: item.id,
						label: item.dioceseName,
					}))}
					renderOption={(props, option) => (
						<Box component="li" {...props} key={option.id}>
							{option.label}
						</Box>
					)}
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label=""
							InputProps={{
								...params.InputProps,
							}}
							onChange={(event: any) => {
								setDioceseSearchTerm(event.target.value);
							}}
							size={'small'}
							placeholder={'Diocese'}
						/>
					)}
				/>
				<div className={classes.IconBtn} onClick={handleVisibleSearchDiocese}>
					<Search />
				</div>
			</Stack>
			{visibleSearchDiocese && (
				<AdvancedSearchModal
					visible={visibleSearchDiocese}
					handleClose={handleVisibleSearchDiocese}
					field={searchFieldsDiocese}
					setField={setSearchFieldsDiocese}
					title={'Search Diocese'}
					handleSearch={handleSearchDiocese}
				>
					<>
						<Stack alignItems={'flex-end'}>
							<TrimTables
								name="dioceseHead"
								list={dioceseHead}
								trimList={trimList}
								setTrimList={setTrimList}
							/>
						</Stack>
						<DioceseTable
							trimList={trimList}
							setTrimList={setTrimList}
							page={page}
							pageSize={pageSize}
							setPage={setPage}
							setPageSize={setPageSize}
							label={label}
							orderType={orderType}
							setLabel={setLabel}
							setOderBy={setOderBy}
							setOrderType={setOrderType}
							dioceses={dioceses}
							hcaOrgList
							open={open}
							setOpen={setOpen}
							handleOpenEdit={handleSelectRowDiocese}
						/>
					</>
				</AdvancedSearchModal>
			)}
		</>
	);
};

export default DioceseField;
