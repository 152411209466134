import { Container, styled } from '@mui/material';

export const UploadImageSection = styled(Container)`
	border: 1px dotted grey;
	border-radius: 5px;
    justify-content: center;
	display: flex;
	padding: 20px;
    cursor: pointer;
    background: #f7f7f7;

	&:hover {
		background: #dfdfdf;
	}
`;
