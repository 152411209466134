import { useContext } from 'react';
import { APPEAL_LOADING, APPEAL_LOAD_ERROR, APPEAL_LOAD_SUCCESS } from 'contexts/actionTypes';
import {
	ResponseError,
	Action,
	AppealData,
	StateType,
	FilterPayload,
	ApiResponse,
	Appeal,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import Axios from 'axios';
import { errorCodes } from 'errorCodes';
import { AppealValues } from 'Components/AdminPanel/Appeal/CreateEditAppeal';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface GetAllAppealProps {
	deleted?: StateType<ApiResponse>;
	setAppeal: React.Dispatch<React.SetStateAction<StateType<AppealData>>>;
	appeal: StateType<AppealData>;
	page: number;
	pageSize: number;
	orderBy?: string;
	filterPayload?: FilterPayload[];
}

export interface GetAllAppealTypes {
	setAllAppeals: React.Dispatch<React.SetStateAction<StateType<Appeal[]>>>;
	allAppeals: StateType<Appeal[]>;
}

const useAppealActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = useContext(GlobalContexts);

	const getAppeals = async ({
		setAppeal,
		appeal,
		page,
		pageSize,
		orderBy,
		filterPayload,
	}: GetAllAppealProps) => {
		try {
			setAppeal({
				...appeal,
				loading: true,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`/appeal/list?PageNumber=${page + 1}&PageSize=${pageSize}${orderBy || '&SortBy=updated%20desc'}${filter || ''}`,
				);
			} else {
				response = await api.get(`/appeal/list?${orderBy || 'SortBy=updated%20desc'}&PageSize=${pageSize}${filter || ''}`);
			}

			setAppeal({
				...appeal,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || appeal.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAppeal({
					...appeal,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};
	const getAllAppeals = async ({ allAppeals, setAllAppeals }: GetAllAppealTypes) => {
		try {
			setAllAppeals({
				...allAppeals,
				loading: true,
				error: null,
			});
			const response = await api.get(`/appeal/lookup-list`);

			setAllAppeals({
				...allAppeals,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAllAppeals({
					...allAppeals,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};
	const createAppeal = async (payload: AppealValues): Promise<boolean> => {
		try {
			const createAppealData = await api.post(`appeal/create`, {
				...payload,
			});
			if (createAppealData?.data?.result) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	const updateAppeal = async (payload: AppealValues, appealId: number): Promise<boolean> => {
		try {
			const updateAppealData = await api.put(`appeal/${appealId}/update`, {
				...payload,
				id: appealId,
			});
			if (updateAppealData?.data?.statusCode === 0) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	const deleteAppeal = async (appealId: number): Promise<boolean> => {
		try {
			const deleteAppeal = await api.remove(`appeal/${appealId}/archive`);
			if (deleteAppeal?.data?.statusCode === 0) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	return {
		getAppeals,
		createAppeal,
		updateAppeal,
		deleteAppeal,
		getAllAppeals,
	};
};

export default useAppealActions;
