import React from 'react';
import {
	FilterPayload,
	ResponseError,
	SchoolBoard,
	SchoolBoardData,
	StateType,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { SchoolBoardValues } from 'Components/AdminPanel/SchoolBoard/CreatEditSchoolBoard';
import { GlobalContexts } from 'contexts/GlobalContext';
import moment from 'moment';

export interface GetSchoolBoardsTypes {
	setSchoolBoards: React.Dispatch<React.SetStateAction<StateType<SchoolBoardData>>>;
	schoolBoards: StateType<SchoolBoardData>;
	page: number;
	pageSize: number;
	filterPayload?: FilterPayload[];
	orderBy?: string;
	archive?: string;
}
export interface GetAllSchoolBoardsTypes {
	setSchoolBoards: React.Dispatch<React.SetStateAction<StateType<SchoolBoardData>>>;
	schoolBoards: StateType<SchoolBoardData>;
}

export interface ExportSchoolBoardsTypes {
	setBoardExport: React.Dispatch<React.SetStateAction<StateType<SchoolBoardData>>>;
	boardExport: StateType<SchoolBoardData>;
	page: number;
	pageSize: number;
	filterPayload?: FilterPayload[];
	orderBy?: string;
	archive?: string;
}

export interface GetSchoolBoardTypes {
	setSchoolBoard: React.Dispatch<React.SetStateAction<StateType<SchoolBoard>>>;
	schoolBoard: StateType<SchoolBoard>;
	id: number;
}

export interface GetSchoolBoardSearchTypes {
	setSchoolBoardSearch: React.Dispatch<React.SetStateAction<StateType<SchoolBoard[]>>>;
	schoolBoardSearch: StateType<SchoolBoard[]>;
	searchTerm: string;
}

const useSchoolBoardActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const getSchoolBoardsByID = async ({ setSchoolBoard, schoolBoard, id }: GetSchoolBoardTypes) => {
		try {
			setSchoolBoard({
				...schoolBoard,
				loading: true,
				error: null,
			});
			const response = await api.get(`schoolboard/${id}`);
			setSchoolBoard({
				...schoolBoard,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSchoolBoard({
					...schoolBoard,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const getSchoolBoards = async ({
		setSchoolBoards,
		schoolBoards,
		page,
		pageSize,
		filterPayload,
		orderBy,
		archive,
	}: GetSchoolBoardsTypes) => {
		try {
			setSchoolBoards({
				...schoolBoards,
				loading: true,
				error: null,
			});

			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`/schoolboard/list?PageNumber=${page + 1}&PageSize=${pageSize}${filter}${archive ? archive : ''}${
						orderBy || '&SortBy=updated%20desc'
					}`,
				);
			} else {
				response = await api.get(
					`/schoolboard/list?&PageSize=${pageSize}${filter}${archive ? archive : ''}${
						orderBy || '&SortBy=updated%20desc'
					}`,
				);
			}

			setSchoolBoards({
				...schoolBoards,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || schoolBoards.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSchoolBoards({
					...schoolBoards,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};
	const getAllSchoolBoards = async ({ setSchoolBoards, schoolBoards }: GetAllSchoolBoardsTypes) => {
		try {
			setSchoolBoards({
				...schoolBoards,
				loading: true,
				error: null,
			});

			const response = await api.get(`/schoolboard/list?&Filters=field=archived;value=false`);
			setSchoolBoards({
				...schoolBoards,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSchoolBoards({
					...schoolBoards,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const exportBoardCSV = async ({
		setBoardExport,
		boardExport,
		page,
		pageSize,
		filterPayload,
		orderBy,
		archive,
	}: ExportSchoolBoardsTypes) => {
		try {
			setBoardExport({
				...boardExport,
				loading: true,
				error: null,
			});

			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`/schoolboard/export-csv?PageNumber=${page + 1}&PageSize=${pageSize}${filter}${
						archive ? archive : ''
					}${orderBy || ''}`,
				);
			} else {
				response = await api.get(
					`/schoolboard/export-csv?&PageSize=${pageSize}${filter}${archive ? archive : ''}${orderBy || ''}`,
				);
			}

			const url = window.URL.createObjectURL(new Blob([response?.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `School_Boards_Export_${moment().format('yyMM')}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			setBoardExport({
				...boardExport,
				loading: false,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setBoardExport({
					...boardExport,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const createSchoolBoard = async (payload: SchoolBoardValues): Promise<boolean> => {
		try {
			const response = await api.post(`schoolboard/create`, {
				...payload,
			});
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'School board created succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	const updateSchoolBoard = async (payload: SchoolBoardValues, schoolBoardID: number): Promise<boolean> => {
		try {
			const response = await api.put(`schoolboard/${schoolBoardID}/update`, {
				...payload,
				id: schoolBoardID,
			});
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'School board updated succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deleteSchoolBoard = async (schoolBoardID: number): Promise<boolean> => {
		try {
			const response = await api.remove(`schoolboard/${schoolBoardID}/archive`);
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'School board archived successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};
	const restoreSchoolBoard = async (schoolBoardID: number): Promise<boolean> => {
		try {
			const response = await api.post(`schoolboard/${schoolBoardID}/unarchive`);
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'School board unarchived successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const getSchoolBoardSearch = async ({
		setSchoolBoardSearch,
		schoolBoardSearch,
		searchTerm,
	}: GetSchoolBoardSearchTypes) => {
		try {
			setSchoolBoardSearch({
				...schoolBoardSearch,
				loading: true,
				error: null,
			});
			const response = await api.get(
				`schoolboard/search?query=${searchTerm}&Filters=field=archived;value=false`,
			);
			setSchoolBoardSearch({
				...schoolBoardSearch,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSchoolBoardSearch({
					...schoolBoardSearch,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	return {
		getSchoolBoardsByID,
		getSchoolBoards,
		createSchoolBoard,
		updateSchoolBoard,
		deleteSchoolBoard,
		getSchoolBoardSearch,
		exportBoardCSV,
		restoreSchoolBoard,
		getAllSchoolBoards,
	};
};

export default useSchoolBoardActions;
