import React, { useState, useEffect } from 'react';
import EFTTable, { eftGenerateAnnuities } from './EFTTable';
import { StateType } from 'contexts/intialstates/Types';
import useAnnuitiesActions, { EFTPaymentData } from 'hooks/useAnnuitiesActions';
import { Typography, Stack, Button } from '@mui/material';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import ActionModal from 'Components/Reusable/Modals/ActionModal/ActionModal';
import { fullMonthYear } from 'helpers/dayAndTime';
import useUserActions from 'hooks/useUserActions';
import { useGlobalStyles } from 'Components/Common/global/global';

const EFTGenerate = () => {
	const { getEFTPayment, generateAnnuitiesEFT, exportAnnuitiesCheque } = useAnnuitiesActions();
	const global = useGlobalStyles();
	const { handleRoles } = useUserActions();
	const [agreementIds, setAgreementIds] = useState<number[]>([]);
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [eftPayment, setEftPayment] = useState<StateType<EFTPaymentData>>({
		loading: false,
		error: null,
		data: null,
	});
	const [isCheckAll, setIsCheckAll] = useState(true);
	const [openGenerateConfirmation, setOpenGenerateConfirmation] = useState(false);
	const [generateLaoding, setGenerateLoading] = useState(false);
	const [openRequiredModal, setOpenRequiredModal] = useState(false);
	const [openChequeConfirmation, setOpenChequeConfirmation] = useState(false);
	const [chequeLoading, setChequeLoading] = useState(false);

	useEffect(() => {
		getEFTPayment({ eftPayment, setEftPayment });
	}, []);

	const handleCheck = (checked: boolean, id: number) => {
		const data = [...agreementIds];
		if (checked) {
			data.push(id);
			setAgreementIds(data);
		} else {
			setAgreementIds(data.filter((item) => item !== id));
		}
	};

	const handleCheckAll = () => {
		const paymentData = eftPayment?.data?.payments;
		if (!paymentData) return;
		if (isCheckAll) {
			setAgreementIds(
				paymentData?.map(({ id }) => {
					return id;
				}),
			);
		} else {
			setAgreementIds([]);
		}
		setIsCheckAll(!isCheckAll);
	};

	const handleGenerateConfirmation = () => {
		if (!openGenerateConfirmation) {
			if (!agreementIds.length) {
				setOpenRequiredModal(true);
				return;
			}
		}
		setOpenGenerateConfirmation(!openGenerateConfirmation);
	};

	const handleChequeConfirmation = () => {
		setOpenChequeConfirmation(!openChequeConfirmation);
	};

	const handleGenerate = async () => {
		setGenerateLoading(true);
		await generateAnnuitiesEFT(agreementIds.join(','));
		setGenerateLoading(false);
		handleGenerateConfirmation();
	};

	const handleChequeExport = async () => {
		setChequeLoading(true);
		await exportAnnuitiesCheque();
		setChequeLoading(false);
		handleChequeConfirmation();
	};

	return (
		<Stack>
			<Stack direction={'row'} justifyContent={'space-between'} mb={3}>
				<Stack direction={'column'}>
					<Typography variant="h5" sx={{ color: '#1976D2' }}>
						Generate Annuities EFT/Cheque File
					</Typography>
					<Stack>
						Payment ready for EFT generation :{' '}
						{eftPayment?.data?.currentPaymentDate
							? fullMonthYear(eftPayment?.data?.currentPaymentDate)
							: 'N/A'}
					</Stack>
				</Stack>
				<Stack direction={'row'} alignItems={'center'} spacing={1}>
					{handleRoles('annuities-actions') && (
						<Button variant="contained" size="small" onClick={handleCheckAll}>
							Select All
						</Button>
					)}
					{handleRoles('annuities-actions') && (
						<Button
							variant="outlined"
							size="small"
							onClick={handleGenerateConfirmation}
							className={global.whiteBtn}
						>
							Generate EFT
						</Button>
					)}
					{handleRoles('annuities-actions') && (
						<Button variant="contained" size="small" onClick={handleChequeConfirmation}>
							Export Cheque
						</Button>
					)}

					<TrimTables
						name={'eftGenerateAnnuities'}
						list={eftGenerateAnnuities}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
				</Stack>
			</Stack>
			<EFTTable
				agreementIds={agreementIds}
				handleCheck={handleCheck}
				eftPayment={eftPayment}
				setEftPayment={setEftPayment}
				trimList={trimList}
				setTrimList={setTrimList}
			/>
			{openGenerateConfirmation && (
				<ConfirmationModal
					handleCancel={handleGenerateConfirmation}
					handleConfirmation={handleGenerate}
					open={openGenerateConfirmation}
					handleClose={handleGenerateConfirmation}
					message="Do you want to generate a EFT ?"
					loading={generateLaoding}
				/>
			)}
			{openChequeConfirmation && (
				<ConfirmationModal
					handleCancel={handleChequeConfirmation}
					handleConfirmation={handleChequeExport}
					open={openChequeConfirmation}
					handleClose={handleChequeConfirmation}
					message="Do you want to export cheque ?"
					loading={chequeLoading}
				/>
			)}
			{openRequiredModal && (
				<ActionModal
					open={openRequiredModal}
					title={'Required field'}
					subTitle={'Please select atleast one agreement'}
					closeText={'Close'}
					handleClose={() => setOpenRequiredModal(false)}
				/>
			)}
		</Stack>
	);
};

export default EFTGenerate;
