import {
	GetAllBatchesProps,
	GetSingleBatchProps,
	UploadFileProps,
	GetEveryBatchesProps,
} from '../Components/Batch/Batch';
import { ResponseError } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { FileWithPath } from 'react-dropzone';
import useMessageAction from 'hooks/useMessageAction';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';
import { useContext } from 'react';

const useBatchActions = () => {
	const { api } = useApi();
	const { setActionAction } = useMessageAction();
	const { setSimpleErrorSnack } = useContext(GlobalContexts);

	const getAllBatches = async ({
		setAllBatch,
		allBatch,
		type,
		page,
		pageSize,
		orderBy,
		filterPayload,
	}: GetAllBatchesProps) => {
		try {
			setAllBatch({
				...allBatch,
				loading: true,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`/batch/${type}/list?PageNumber=${page + 1}&PageSize=${pageSize}${filter}${
						orderBy || '&SortBy=updated%20desc'
					}`,
				);
			} else {
				response = await api.get(
					`/batch/${type}/list?&PageSize=${pageSize}${filter}${orderBy || '&SortBy=updated%20desc'}`,
				);
			}

			setAllBatch({
				...allBatch,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || allBatch.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAllBatch({
					...allBatch,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const getEveryBatch = async ({
		setEveryBatch,
		everyBatch,
		page,
		pageSize,
		orderBy,
		filterPayload,
	}: GetEveryBatchesProps) => {
		try {
			setEveryBatch({
				...everyBatch,
				loading: true,
			});
			let filter = '';
			filterPayload.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`/batch/list?PageNumber=${page + 1}&PageSize=${pageSize}${filter}${
						orderBy || '&SortBy=updated%20desc'
					}`,
				);
			} else {
				response = await api.get(
					`/batch/list?&PageSize=${pageSize}${filter}${orderBy || '&SortBy=updated%20desc'}`,
				);
			}

			setEveryBatch({
				...everyBatch,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || everyBatch.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setEveryBatch({
					...everyBatch,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const deleteBatchById = async (batchId: number | string): Promise<boolean> => {
		try {
			const response = await api.remove(`batch/${batchId}/archive`);
			if (response?.data?.statusCode === 0) {
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				show: true,
				severity: 'error',
				message: 'An error occurred',
			});
			return false;
		}
	};

	const uploadFile = async ({
		setTriggerUpload,
		acceptedFiles,
		options,
		messageDispatch,
		setBatchUpload,
		batchUpload,
	}: UploadFileProps) => {
		const formData = new FormData();
		setTriggerUpload(true);
		acceptedFiles.forEach((acceptedFile: FileWithPath) => formData.append('CSVFile', acceptedFile));

		try {
			const uploadBatch = await api.post(`batch/upload`, formData, {
				...options,
				headers: { 'Content-Type': 'multipart/form-data' },
			});
			setActionAction(true)(messageDispatch);
			setBatchUpload({
				...batchUpload,
				loading: false,
				error: null,
				data: uploadBatch?.data,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setBatchUpload({
					...batchUpload,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const createPapBank = async (): Promise<boolean> => {
		try {
			const response = await api.get(`batch/papBankExistsForCurrentMonth`);
			if (response?.data?.result) {
				setSimpleErrorSnack({
					show: true,
					severity: 'error',
					message: 'There is an existing PAP Bank Batch for this month',
				});
				return false;
			} else {
				const createPap = await api.post(`batch/papbank/create`);
				if (createPap?.data?.result) {
					setSimpleErrorSnack({
						show: true,
						severity: 'success',
						message: 'Batch created successfully',
					});
				}
				return true;
			}
		} catch {
			setSimpleErrorSnack({
				show: true,
				severity: 'error',
				message: 'An error occurred',
			});
			return false;
		}
	};

	const getSingleBatch = async ({
		batchId,
		setSingleBatch,
		singleBatch,
		filterPayload,
		page,
		pageSize,
		orderBy,
		donorQuery,
	}: GetSingleBatchProps) => {
		try {
			setSingleBatch({
				...singleBatch,
				loading: true,
				error: null,
				data: null,
			});
			let filter = '';
			filterPayload.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});

			const response = await api.get(
				`/batch/${batchId}?donorQuery=${donorQuery}${filter || ''}${orderBy || ''}`,
			);
			setSingleBatch({
				...singleBatch,
				loading: false,
				error: null,
				data: response?.data?.result,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSingleBatch({
					...singleBatch,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const batchPost = async (batchId: number) => {
		try {
			const batchPosted = await api.post(`batch/${batchId}/post`);
			if (batchPosted?.data?.result) {
				setSimpleErrorSnack({
					message: 'Posted successfully ',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch {
			return false;
		}
	};
	const updateBatch = async (batchId: number, payload: { status: number; depositId: number }[]) => {
		try {
			const response = await api.put(`batch/papbank/${batchId}/update`, {
				deposits: payload,
			});
			if (response?.data?.statusCode === 0) {
				return true;
			}
			return false;
		} catch {
			return false;
		}
	};

	return {
		getAllBatches,
		deleteBatchById,
		uploadFile,
		getSingleBatch,
		createPapBank,
		batchPost,
		getEveryBatch,
		updateBatch,
	};
};

export default useBatchActions;
