import { useMsal } from '@azure/msal-react';
import { useContext } from 'react';
import { AxiosRequestConfig } from 'axios';
import { loginRequest } from '../authConfig';
import axiosInstance from 'helpers/axiosInstance';
import { GlobalContexts } from 'contexts/GlobalContext';
import { IApi } from '../apiTypes';

const useApi = (): { api: IApi; apiToken?: string } => {
	const { instance, accounts } = useMsal();
	const { setToken } = useContext(GlobalContexts);

	const getApiToken = async () => {
		if (instance && accounts && accounts.length > 0) {
			const request = {
				...loginRequest,
				account: accounts[0],
			};
			const response = await instance.acquireTokenSilent(request).catch(async (e) => {
				await instance.acquireTokenRedirect(request);
			});
			if (response) {
				setToken(response.accessToken);
				return response.accessToken;
			}
		}
		return null;
	};

	const get = async (url: string, options?: AxiosRequestConfig) => {
		const token = await getApiToken();
		const config = {
			...options,
			headers: { Authorization: `Bearer ${token}` },
		};
		return await axiosInstance().get(url, config);
	};

	const post = async (url: string, payload: any, options?: AxiosRequestConfig) => {
		const token = await getApiToken();
		const config = {
			...options,
			headers: { Authorization: `Bearer ${token}` },
		};
		return await axiosInstance().post(url, payload, config);
	};

	const put = async (url: string, payload: any, options?: AxiosRequestConfig) => {
		const token = await getApiToken();
		const config = {
			...options,
			headers: { Authorization: `Bearer ${token}` },
		};
		return await axiosInstance().put(url, payload, config);
	};

	const remove = async (url: string, options?: AxiosRequestConfig) => {
		const token = await getApiToken();
		const config = {
			...options,
			headers: { Authorization: `Bearer ${token}` },
		};
		return await axiosInstance().delete(url, config);
	};

	const api: IApi = {
		get,
		post,
		put,
		remove,
	};

	return {
		api,
	};
};

export default useApi;
