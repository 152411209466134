import React from 'react';
import { TablePagination, Paper, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import { pagination } from 'Components/Common/Tables/Tables';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import StatusButton from '../../Common/Buttons/StatusButton';
import { convertDate } from 'helpers/dayAndTime';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import TableFiltering, { DonorFiltering } from 'Components/Reusable/TableFiltering/TableFiltering';
import { sortTable } from 'Components/Common/Tables/sortTable';
import FormatCurrency from 'helpers/FormatCurrency';
import { Donor } from 'contexts/intialstates/Types';

const PostDatedChequeList = ({
	trimList,
	reload,
	setReload,
	filterPayload,
	setFilterPayload,
	setTrimList,
	page,
	setPage,
	pageSize,
	setPageSize,
	orderType,
	setOrderType,
	label,
	setLabel,
	setOderBy,
	postDatedCheques,
	setSearchDonor,
	searchDonor,
}: any) => {
	const navigate = useNavigate();

	const donorName = (donor: Donor) => {
		if (donor?.firstName && donor?.lastName) return `${donor?.firstName} ${donor?.lastName}`;
		if (donor?.firstName) return `${donor?.firstName}`;
		if (donor?.lastName) return ` ${donor?.lastName}`;
		if (donor?.orgName) return donor?.orgName;
		return 'Anonymous';
	};

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleEdit = (row: any) => {
		navigate(`${row?.id}`);
	};

	const getTableData = () => {
		return postDatedCheques?.data?.body?.length
			? postDatedCheques?.data?.body.map((row: any, index: any) => {
					return {
						id: row?.id || 'N/A',
						's/n': page * pageSize + index + 1,
						cheque: row?.cheque || 'N/A',
						donor: row && donorName(row?.donor),
						amount: row?.amount ? <FormatCurrency value={row?.amount} /> : 'N/A',
						receivedDate: `${row?.receivedDate ? convertDate(row?.receivedDate) : 'N/A'}`,
						dueDate: `${row?.dueDate ? convertDate(row?.dueDate) : 'N/A'}`,
						status: row?.status ? <StatusButton status={`${row?.status}`} /> : 'N/A',
						remark: row?.remark || 'N/A',
						created: `${row?.created ? convertDate(row?.created) : 'N/A'}`,
						createdBy: row?.createdBy?.name || 'N/A',
						updated: `${row?.updated ? convertDate(row?.updated) : 'N/A'}`,
						updatedBy: row?.updatedBy?.name || 'N/A',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
							<span
								style={{ cursor: 'pointer' }}
								onClick={() =>
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									})
								}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<>
							<DonorFiltering
								searchDonor={searchDonor}
								setSearchDonor={setSearchDonor}
								filterType={column.filterType}
								setPage={setPage}
							/>
							<TableFiltering
								field={column.id}
								filterType={column.filterType}
								list={column.list}
								reload={reload}
								setIsReload={setReload}
								setFilterPayload={setFilterPayload}
								filterPayload={filterPayload}
								setPage={setPage}
								page={page}
							/>
						</>
					</>
				),
			};
		});
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		reOrderedData && setTrimList?.(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		resizedData && setTrimList?.(resizedData);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<CustomTable
				loading={postDatedCheques?.loading}
				columnData={getColumnData()}
				tableData={getTableData()}
				handleDrag={handleDrag}
				handleResize={handleResize}
				emptyMessage={'No Orders available'}
				handleRowClick={(row) => handleEdit(row as any)}
			/>
			{(postDatedCheques?.data?.body?.length as number) > 0 && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
					component="div"
					count={postDatedCheques?.data?.totalRecords as number}
					rowsPerPage={pageSize}
					page={page}
					sx={pagination}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</Paper>
	);
};

export default PostDatedChequeList;
