import React from 'react';
import {
	Table,
	Box,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from '@mui/material';
import { useStyles } from './MissionOrder.style';
import { Material } from '.';

interface P {
	material: Material[];
	handleChange: (id: number, value: string, name: string) => void;
}

const OrderMaterial = ({ material, handleChange }: P) => {
	const classes = useStyles();

	return (
		<Box sx={{ mt: 2 }}>
			<Typography sx={{ fontSize: { xs: 13, sm: 14 }, fontWeight: 600, mt: 2, mb: 1 }}>
				Mission Sunday Materials
			</Typography>
			<hr />
			<TableContainer>
				<Table sx={{ minWidth: 700 }} aria-label="spanning table">
					<TableHead className={classes.tableHead}>
						<TableRow>
							<TableCell align="center">Material</TableCell>
							<TableCell align="center">Description</TableCell>
							<TableCell align="center">English (qty)</TableCell>
							<TableCell align="center">French (qty)</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{material?.map((row, index) => (
							<TableRow key={index} className={classes.tableRowWrap}>
								<TableCell align="center">{row.name || 'N/A'}</TableCell>
								<TableCell align="center">{row.description || 'N/A'}</TableCell>
								<TableCell align="center">
									<TextField
										type="number"
										value={row.enQuantity ?? ''}
										onChange={(e) => handleChange(row.materialId, e.target.value, 'enQuantity')}
										className={classes.textField}
									/>
								</TableCell>
								<TableCell align="center">
									<TextField
										type="number"
										value={row.frQuantity ?? ''}
										onChange={(e) => handleChange(row.materialId, e.target.value, 'frQuantity')}
										className={classes.textField}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default OrderMaterial;
