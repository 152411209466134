import React from 'react';
import { Button, InputAdornment, Stack, TextField } from '@mui/material';
import { Add, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import DonationsTable from './DonationsTable';
import ErrorSnack from 'Components/Common/ErrorSnack/ErrorSnack';
import SingleCreditCardDeposit from 'Components/Deposits/CreateDeposit/SingleCreditCardDeposit';
import { DonationsData, OrderType, StateType } from 'contexts/intialstates/Types';
import { GlobalContexts } from 'contexts/GlobalContext';
import useDonationActions from '../../hooks/useDonationActions';
import useDebounce from 'Components/Reusable/hooks/debounce';

const Donations = () => {
	const { setErrorSnack } = React.useContext(GlobalContexts);

	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [searchValue, setSearchValue] = React.useState('');
	const [searchDonation, setSearchDonation] = React.useState<any>('');
	const isFieldEmpty = searchValue.length == 0 ? true : false;
	const [searchDonor, setSearchDonor] = React.useState<any>('');
	const debouncedSearchTerm = useDebounce(searchDonor, 1000);
	const [allDonation, setAllDonation] = React.useState<StateType<DonationsData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');

	const { getAllDonation } = useDonationActions();

	React.useEffect(() => {
		if (searchDonor.length >= 3) {
			getAllDonation({
				page,
				pageSize,
				donorQuery: `&donorQuery=${searchDonor}`,
				allDonation,
				setAllDonation,
				setErrorSnack,
				orderBy,
			});
			return;
		}
		getAllDonation({
			page,
			pageSize,
			allDonation,
			setAllDonation,
			setErrorSnack,
			orderBy,
		});
	}, [page, pageSize, debouncedSearchTerm, orderBy]);

	const sortTable = (column: string) => {
		let sortBy;
		let orderParam: OrderType;

		if (column === 'actions' || column === 'remark') {
			return;
		}
		if (column == label) {
			if (orderType === 'asc') {
				orderParam = 'desc';
			} else {
				orderParam = 'asc';
			}
			sortBy = `&SortBy=${column} ${orderParam}`;
		} else {
			orderParam = 'asc';
			sortBy = `&SortBy=${column} ${orderParam}`;
			setLabel(column);
		}
		setOrderType(orderParam);
		setOderBy(sortBy);
	};
	const donationInfo = allDonation;
	const navigate = useNavigate();
	const handleBtnClick = () => {
		navigate('/bookkeeping/deposits/create-deposit');
	};
	React.useEffect(() => {
		searchValue.length == 0 && setSearchDonation('');
	}, [isFieldEmpty]);

	const ele: any = [];
	const newEle: any = [];

	donationInfo?.data?.body
		?.filter(
			(deposit: any) => deposit?.donor?.lastName !== undefined && deposit?.donor?.firstName !== undefined,
		)
		.forEach((e: any, index: any, arr: any) => {
			ele.push(`${e?.donor?.firstName}  ${e?.donor?.lastName}`);
		});

	ele.forEach((e: any, index: any, arr: any) => {
		if (newEle.includes(e) == false) {
			newEle.push(e);
		}
	});

	let output;

	if (donationInfo?.error) {
		setErrorSnack(true);
		output = null;
	} else {
		setErrorSnack(false);
		output = (
			<>
				<Stack sx={{ mb: 3 }} direction="row" justifyContent="space-between">
					<TextField
						onChange={(e) => setSearchDonor(e.target.value)}
						placeholder="Search by donor"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Search />
								</InputAdornment>
							),
						}}
						sx={{
							background: 'white',
							border: 'none',
							height: 50,
							borderRadius: '4px',
							input: { width: '200px', height: '100%', border: 'none' },
							div: { border: 'none' },
						}}
					/>

					<div>
						<Button
							onClick={handleBtnClick}
							sx={{ textTransform: 'none', mr: 2 }}
							startIcon={<Add />}
							variant="contained"
						>
							Add Donation
						</Button>
						<SingleCreditCardDeposit />
					</div>
				</Stack>
				{/* <DonationsTable
					donationData={donationInfo?.data}
					page={page}
					setPage={setPage}
					setPageSize={setPageSize}
					pageSize={pageSize}
					loading={donationInfo?.loading}
					sortTable={sortTable}
					label={label}
					orderType={orderType}
					setLabel={setLabel}
					setOderBy={setOderBy}
					setOrderType={setOrderType}
				/> */}
			</>
		);
	}

	return (
		<>
			{output}
			<ErrorSnack message={JSON.stringify(donationInfo?.error?.Message)} />
		</>
	);
};

export default Donations;
