import React from 'react';
import { Clear, Payment, WarningAmber, Menu, TaskAlt, WorkspacesOutlined } from '@mui/icons-material';
import { Avatar, Box, Button, IconButton, Paper, Stack } from '@mui/material';
import { fullMonthYear } from 'helpers/dayAndTime';
import { useNavigate } from 'react-router-dom';
interface Props {
	type: PropType;
	expiryDate?: string;
	count: number;
	handleClear: (type: PropType) => void;
}
export type PropType =
	| 'failedTransaction'
	| 'failedPapTransaction'
	| 'postDated'
	| 'expired'
	| 'expiring'
	| 'expiringSoon'
	| 'batch';
export const Card = ({ type, count, expiryDate, handleClear }: Props) => {
	const navigate = useNavigate();
	const currentNotification = () => {
		if (type === 'failedTransaction')
			return { color: 'var(--warning)', ele: <WarningAmber sx={{ color: 'var(--warning)' }} /> };
		if (type === 'failedPapTransaction')
			return { color: 'var(--orange)', ele: <WorkspacesOutlined sx={{ color: 'var(--orange)' }} /> };
		if (type === 'postDated')
			return { color: 'var(--success)', ele: <TaskAlt sx={{ color: 'var(--success)' }} /> };
		if (type === 'expired' || type === 'expiring' || type === 'expiringSoon')
			return { color: 'var(--danger)', ele: <Payment sx={{ color: 'var(--danger)' }} /> };
		if (type === 'batch')
			return {
				color: 'var(--fontColorHeaderSubTitle)',
				ele: <Menu sx={{ color: 'var(--fontColorHeaderSubTitle)' }} />,
			};
	};

	const message = () => {
		if (type === 'failedTransaction') return `You have ${count} failed donation(s) transaction.`;
		if (type === 'failedPapTransaction') return `You have ${count} failed Pre Authorized donation(s).`;
		if (type === 'postDated') return `You have ${count} post-dated cheque(s) ready for deposit.`;
		if (type === 'expired') return `You have ${count} expired credit cards.`;
		if (type === 'expiringSoon') return `You have ${count} credit cards expiring soon.`;
		if (type === 'expiring' && expiryDate)
			return `You have ${count} expiring in ${fullMonthYear(expiryDate)} .`;
		if (type === 'batch') return `You have ${count} non posted batches`;
	};
	const handleNavigate = () => {
		if (type === 'failedTransaction') return navigate('failed-transactions');
		if (type === 'failedPapTransaction') return navigate('pap');
		if (type === 'postDated') return navigate('post-dated-cheques');
		if (type === 'expired' || type === 'expiring' || type === 'expiringSoon') return navigate('expiring-cc');
		if (type === 'batch') return navigate('batch');
	};

	return (
		<Paper elevation={0} sx={{ background: 'var(--lightGray)', p: '5px 10px', mb: 0.5 }}>
			<Stack direction={'row'} justifyContent="space-between">
				<Stack spacing={2} direction={'row'} alignItems="center" justifyContent="flex-start">
					<Avatar
						sx={{
							bgcolor: 'white',
							width: 40,
							height: 40,
							border: `1px solid ${currentNotification()?.color}`,
						}}
					>
						{currentNotification()?.ele}
					</Avatar>
					<Stack>{message()}</Stack>
				</Stack>
				<Stack direction="row" spacing={2} alignItems="center">
					<Stack>
						<Button
							onClick={handleNavigate}
							sx={{ width: 'fit-content', py: 0.5, textTransform: 'capitalize' }}
							variant="contained"
						>
							View
						</Button>
					</Stack>
					<IconButton
						onClick={() => handleClear(type)}
						sx={{ background: 'white', borderRadius: '2px', p: 0, border: '1px solid var(--mainColor)' }}
					>
						<Clear sx={{ color: 'var(--mainColor)' }} />
					</IconButton>
				</Stack>
			</Stack>
		</Paper>
	);
};

export const ListCard = () => {
	return (
		<Box width="100%">
			<Stack
				sx={{
					height: 40,
					background: 'var(--mainBackground)',
					color: 'var(--mainColor)',
					fontSize: 16,
					fontWeight: 600,
					px: 3,
				}}
			>
				Expiry Cards
			</Stack>
		</Box>
	);
};
