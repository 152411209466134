import React, { useEffect, useState } from 'react';
import {
	Typography,
	Grid,
	MenuItem,
	Paper,
	Select,
	Stack,
	TextField,
	Button,
	IconButton,
	Box,
	Autocomplete,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGlobalStyles } from 'Components/Common/global/global';
import {
	CalendarToday,
	ConfirmationNumber,
	Event,
	Save,
	Receipt,
	Message,
	Archive,
	FileCopy,
	KeyboardArrowLeft,
} from '@mui/icons-material';
import BasicDatePicker, { Months } from 'Components/Reusable/DatePicker';
import { Appeal, DonationTypes, Society, StateType } from 'contexts/intialstates/Types';
import { useNavigate, useParams } from 'react-router-dom';
import { GlobalContexts } from 'contexts/GlobalContext';
import { ContentSkeleton } from 'Components/Deposits/CreateEditDepositWithDonations/Content';
import { ReceiptFormValues } from './types';
import RichEditor from 'Components/Reusable/RichEditor';
import useSocietyActions from 'hooks/useSocietyAction';
import useAppealActions from 'hooks/useAppealActions';
import useReceiptTemplateActions from 'hooks/useReceiptTemplateAction';
import useDonationTypesActions from 'hooks/useDonationTypesActions';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import useUserActions from 'hooks/useUserActions';
import useDebounce from 'Components/Reusable/hooks/debounce';

interface Payload {
	id?: string | number;
	month: number;
	year: number;
	name: string;
	societyId: number;
	appealId?: number;
	text: string;
	donationTypeId?: number;
	blankSpaces: number;
}

const CreateEditReceiptTemplate = () => {
	const { getDonationTypeBySearch } = useDonationTypesActions();

	const { handleRoles } = useUserActions();
	const { getAllDonationTypes } = useDonationTypesActions();
	const navigate = useNavigate();
	const global = useGlobalStyles();
	const [saveLoading, setSaveLoading] = useState<boolean>(false);
	const [isDelete, setIsDelete] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [initialize, setInitialize] = useState<boolean>(false);
	const {
		getSingleReceiptTemplate,
		deleteSingleReceiptTemplate,
		updateSingleReceiptTemplate,
		createSingleReceiptTemplate,
	} = useReceiptTemplateActions();
	const { setErrorSnack, setSimpleErrorSnack } = React.useContext(GlobalContexts);
	const [donationTypes, setDonationTypes] = React.useState<StateType<DonationTypes[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [formData, setFormData] = useState<ReceiptFormValues>({
		name: null,
		appealId: null,
		societyId: null,
		month: null,
		year: null,
		text: null,
	});
	const { receptId } = useParams();
	const { cloneId } = useParams();
	const { getSocieties } = useSocietyActions();
	const { getAllAppeals } = useAppealActions();
	const [allSociety, setAllSoceity] = useState<StateType<Society[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [allAppeals, setAllAppeals] = useState<StateType<Appeal[]>>({
		data: null,
		loading: false,
		error: null,
	});

	const [updateReceipt, setUpdateReceipt] = useState<StateType<any>>({
		data: null,
		loading: false,
		error: null,
	});
	const [createReceipt, setCreateReceipt] = useState<StateType<any>>({
		data: null,
		loading: false,
		error: null,
	});
	const [receipt, setReceipt] = useState<StateType<any>>({
		data: null,
		loading: false,
		error: null,
	});
	const [formErrors, setFormErrors] = useState<string[]>([]);
	const [messageErr, setMessageErr] = useState(false);
	const [emptyText, setEmptyText] = useState(false);
	const [textLength, setTextLength] = useState(0);

	const [donationTypeSearchTerm, setDonationTypeSearchTerm] = useState('');
	const debouncedDonationSearchTerm = useDebounce(donationTypeSearchTerm, 1000);
	const [donationType, setDonationType] = useState<StateType<DonationTypes[]>>({
		data: null,
		loading: false,
		error: null,
	});

	const [totalParagraphCount, setTotalParagraphCount] = useState(0);

	const maxLines = 18;
	const maximumCharPerLine = 108;

	useEffect(() => {
		if (!debouncedDonationSearchTerm) return;
		getDonationTypeBySearch({
			donationType,
			setDonationType,
			searchTerm: debouncedDonationSearchTerm,
		});
	}, [debouncedDonationSearchTerm]);

	useEffect(() => {
		const isLoading = allSociety?.loading || allAppeals?.loading || receipt?.loading;
		if (isLoading || !initialize) return;
		setLoading(false);
	}, [allSociety, allAppeals, receipt?.loading, initialize]);

	useEffect(() => {
		getSocieties({ allSociety, setAllSoceity });
		getAllAppeals({ setAllAppeals, allAppeals });
	}, []);

	React.useEffect(() => {
		if (receptId || cloneId)
			getSingleReceiptTemplate({
				receiptTemplateId: receptId || cloneId,
				receipt,
				setReceipt,
				setErrorSnack,
			});
	}, [receptId, cloneId]);

	React.useEffect(() => {
		if ((receptId || cloneId) && receipt?.data) {
			const { month, year, name, text, society, appeal, donationType } = receipt?.data;
			setFormData({
				month,
				year,
				name,
				text,
				societyId: society?.id?.toString(),
				appealId: appeal?.id?.toString(),
				donationType: {
					id: donationType?.id,
					label: donationType?.id ? `${donationType?.type || ''} - ${donationType?.description || ''}` : '',
				},
			});
		}
		setInitialize(true);
	}, [receptId, receipt?.data, cloneId]);

	const handleUpdateDeposit = async (payload: any) => {
		if (receptId) {
			await updateSingleReceiptTemplate({
				payload,
				receiptTemplateId: receptId,
				updateReceipt,
				setUpdateReceipt,
				setErrorSnack,
			});
		} else {
			await createSingleReceiptTemplate({
				payload,
				createReceipt,
				setCreateReceipt,
				setErrorSnack,
			});
		}

		navigate(`../`);
	};

	const validation = (): boolean => {
		const errors = [];
		if (!formData.name) {
			errors.push('name');
		}
		if (emptyText || !formData.text) {
			errors.push('text');
		}

		if (messageErr) {
			errors.push('textErr');
		}
		if (!formData.month) {
			errors.push('month');
		}
		if (!formData.year) {
			errors.push('year');
		}
		if (!formData.societyId) {
			errors.push('societyId');
		}

		setFormErrors(errors);
		if (errors.length) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'Please fill all the required fields.',
			});
			return false;
		}
		return true;
	};

	const updateDepositForm = async () => {
		if (!validation()) return;

		if (!formData.name || !formData.text || !formData.month || !formData.year || !formData.societyId) return;
		const getTextCountsResult = getTextCounts();

		const maxCharPerLine = maximumCharPerLine;
		const totalCharCount = getTextCountsResult?.characterLength || 0;
		const maxCharCount = maxLines * maxCharPerLine;

		const remainingCharCount = maxCharCount - totalCharCount;
		const noOfSpaces = remainingCharCount / maxCharPerLine;
		const payload: Payload = {
			id: receptId ? parseInt(receptId.toString()) : undefined,
			month: parseInt(formData.month.toString()),
			year: parseInt(formData.year.toString()),
			name: formData.name,
			societyId: parseInt(formData.societyId.toString()),
			donationTypeId: formData.donationType ? formData.donationType.id : undefined,
			appealId: formData.appealId ? parseInt(formData.appealId.toString()) : undefined,
			text: formData.text,
			blankSpaces: Math.max(0, Math.round(noOfSpaces - (getTextCountsResult?.paragraphCount || 0) * 0.63)),
		};

		setSaveLoading(true);
		await handleUpdateDeposit?.(payload);
		setSaveLoading(false);
	};

	const totalParagraphCountFunc = (value: any[]) => {
		return value.reduce((total: number, curr: any) => total + 1, 0);
	};

	const totalTextLengthFunc = (value: any[]) => {
		return value.reduce(
			(total: number, curr: any) =>
				total +
				curr.children.reduce((childrenTotal: number, currChild: any) => {
					const cLength = currChild.text.length;
					const numOfLines = Math.max(1, Math.ceil(cLength / maximumCharPerLine));
					return childrenTotal + numOfLines * maximumCharPerLine;
				}, 0),
			0,
		);
	};
	const updateFormValue = (key: string) => (e: any) => {
		if (formErrors.some((curr) => curr.includes(key))) {
			setFormErrors(formErrors.filter((item) => !item.includes(key)));
		}
		let temp;
		if (key === 'text') {
			const richValue = e;

			temp = { ...formData, [key]: JSON.stringify(richValue) };
			const totalTextLength = totalTextLengthFunc(richValue);
			setTextLength(totalTextLength);

			setFormData(temp);
			return;
		}

		if (key === 'year') {
			temp = { ...formData, [key]: new Date(e.target.value).getFullYear() };
			setFormData(temp);
			return;
		}
		temp = { ...formData, [key]: e.target.value };
		setFormData(temp);
	};

	const getTextCounts = (): { paragraphCount: number; characterLength: number } | undefined => {
		if (!formData.text) return;

		if (formData['text'].length == 0) {
			return;
		}

		let textData = JSON.parse(formData['text']);
		textData = textData.filter((item: any) =>
			item.children.some((child: any) => child.text || child.bold || child.underline),
		);

		const paragraphCount = totalParagraphCountFunc(textData);
		const characterLength = totalTextLengthFunc(textData);

		return {
			paragraphCount,
			characterLength,
		};
	};

	React.useEffect(() => {
		getAllDonationTypes({ donationTypes, setDonationTypes });
	}, []);

	const handleCloseDelete = () => {
		setIsDelete(false);
	};

	const handleDelete = async () => {
		if (receptId) {
			setDeleteLoading(true);
			const isSucess = await deleteSingleReceiptTemplate(parseInt(receptId));
			setDeleteLoading(false);
			handleCloseDelete();
			if (isSucess) {
				navigate('../');
			}
		}
	};
	const cloneReceiptTemplate = (id: any) => {
		navigate(`../clone/${id}`);
	};

	return (
		<>
			<Stack direction="column" justifyContent="center" alignItems="center">
				<>
					<Stack sx={{ width: '100%' }} py={3}>
						<Stack>
							<Stack
								direction={{ xs: 'row', sm: 'column' }}
								alignItems={{ xs: 'center', sm: 'initial' }}
								justifyContent={{ xs: 'center', sm: 'initial' }}
								spacing={{ xs: 3, sm: 0 }}
								mb={5}
							>
								<Stack
									alignItems={'center'}
									justifyContent={'center'}
									sx={{ display: { xs: 'flex', sm: 'none' }, background: '#fff' }}
								>
									<IconButton
										id="demo-customized-button"
										aria-haspopup="true"
										onClick={() => {
											navigate(-1);
										}}
									>
										<KeyboardArrowLeft color="primary" />
									</IconButton>
								</Stack>
								<Stack
									alignItems="center"
									direction={{ xs: 'column', sm: 'row' }}
									justifyContent={'space-between'}
									spacing={1}
								>
									<Stack
										alignItems={'center'}
										direction="row"
										justifyContent={'flex-start'}
										spacing={{ xs: 0, sm: 4 }}
										mb={{ xs: 1, sm: 0 }}
									>
										<Stack
											alignItems={'center'}
											justifyContent={'center'}
											sx={{ display: { xs: 'none', sm: 'flex' }, background: '#fff' }}
										>
											<IconButton
												id="demo-customized-button"
												aria-haspopup="true"
												onClick={() => {
													navigate(-1);
												}}
											>
												<KeyboardArrowLeft color="primary" />
											</IconButton>
										</Stack>
										<Stack direction="column">
											<Box
												sx={{
													fontSize: '32px',
													fontWeight: 700,
													color: 'var(--mainColor)',
													wordWrap: 'normal',
												}}
											>
												Receipt Letter Template
											</Box>
										</Stack>
									</Stack>
									{handleRoles('admin-actions') && (
										<Stack direction="row" spacing={2}>
											{receptId && (
												<>
													<Button
														sx={{ textTransform: 'none' }}
														startIcon={<FileCopy />}
														variant="contained"
														onClick={() => cloneReceiptTemplate(receptId)}
													>
														Clone
													</Button>
													<Button
														sx={{ textTransform: 'none' }}
														startIcon={<Archive />}
														variant="contained"
														onClick={() => setIsDelete(true)}
													>
														Archive
													</Button>
												</>
											)}
											<Stack mr={2}>
												<LoadingButton
													sx={{ textTransform: 'none' }}
													startIcon={<Save />}
													variant="contained"
													onClick={updateDepositForm}
													type="submit"
													loading={saveLoading}
													loadingPosition="start"
												>
													Save
												</LoadingButton>
											</Stack>
										</Stack>
									)}
								</Stack>
							</Stack>
							<Paper className={global.paperFormWrap}>
								{loading ? (
									<ContentSkeleton />
								) : (
									<>
										<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Grid container>
													<Grid item>
														<Receipt className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography variant="body2" className={global.formLabelText}>
															Template Name
														</Typography>
													</Grid>
												</Grid>
												<TextField
													size="small"
													fullWidth
													label=""
													variant="outlined"
													value={formData?.name}
													onChange={updateFormValue('name')}
												/>
												{formErrors.includes('name') && (
													<span className={global.errorText}>Template name is required</span>
												)}
											</Grid>
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Grid container>
													<Grid item>
														<ConfirmationNumber className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography variant="body2" className={global.formLabelText}>
															Appeal
														</Typography>
													</Grid>
												</Grid>
												<Select
													size="small"
													fullWidth
													placeholder="Appeal"
													value={formData?.appealId}
													onChange={updateFormValue('appealId')}
												>
													<MenuItem value={''}>No Appeal</MenuItem>
													{(allAppeals?.data || []).map((x: { id: number; code: string }) => (
														<MenuItem key={x.id} value={x.id}>
															{x.code || ''}
														</MenuItem>
													))}
												</Select>
											</Grid>
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Grid container>
													<Grid item>
														<ConfirmationNumber className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography variant="body2" className={global.formLabelText}>
															Society
														</Typography>
													</Grid>
												</Grid>
												<Select
													size="small"
													fullWidth
													placeholder="Society"
													value={formData?.societyId}
													onChange={updateFormValue('societyId')}
												>
													{allSociety?.data
														?.filter((curr) => curr.code !== 'AN')
														.map((x: { id: number; code: string }) => (
															<MenuItem key={x.id} value={x.id}>
																{x.code || ''}
															</MenuItem>
														))}
												</Select>
												{formErrors.includes('societyId') && (
													<span className={global.errorText}>Society is required</span>
												)}
											</Grid>
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Grid container>
													<Grid item>
														<ConfirmationNumber className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography variant="body2" className={global.formLabelText}>
															Donation Type
														</Typography>
													</Grid>
												</Grid>
												<Autocomplete
													freeSolo
													value={formData.donationType?.label || ''}
													loading={donationType.loading}
													loadingText="loading..."
													onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
														setFormData({ ...formData, donationType: val });
													}}
													fullWidth
													options={(donationType?.data || []).map((donation: DonationTypes) => ({
														id: donation.id,
														label: `${donation.type} - ${donation.description}`,
													}))}
													renderOption={(props, option) => (
														<Box component="li" {...props} key={option.id}>
															{option.label}
														</Box>
													)}
													renderInput={(params) => (
														<TextField
															{...params}
															variant="outlined"
															label=""
															InputProps={{
																...params.InputProps,
															}}
															onChange={(event: any) => {
																setDonationTypeSearchTerm(event.target.value);
															}}
															size={'small'}
															placeholder={'Search donation type'}
														/>
													)}
												/>
											</Grid>
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Grid container>
													<Grid item>
														<Event className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography variant="body2" className={global.formLabelText}>
															Month
														</Typography>
													</Grid>
												</Grid>
												<Select
													size="small"
													fullWidth
													placeholder="Month"
													value={formData?.month}
													onChange={updateFormValue('month')}
												>
													{Months.map((x: { id: number; name: string }) => (
														<MenuItem key={x.id} value={x.id}>
															{x.name}
														</MenuItem>
													))}
												</Select>
												{formErrors.includes('month') && (
													<span className={global.errorText}>Month is required</span>
												)}
											</Grid>
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Grid container>
													<Grid item>
														<CalendarToday className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography variant="body2" className={global.formLabelText}>
															Year
														</Typography>
													</Grid>
												</Grid>
												<BasicDatePicker
													size="small"
													year
													value={formData?.year && `01/01/${formData?.year}`}
													setValue={updateFormValue('year')}
												/>
												{formErrors.includes('year') && (
													<span className={global.errorText}>Year is required</span>
												)}
											</Grid>
										</Grid>
										<Stack direction="column" py={2}>
											<Stack direction="column" sx={{ width: '100%', pr: 4 }}>
												<Stack mb={2}>
													<Grid container>
														<Grid item>
															<Message className={global.formLabelIcon} />
														</Grid>
														<Grid item sx={{ mb: 3 }}>
															<Typography variant="body2" className={global.formLabelText}>
																Template Message
															</Typography>
														</Grid>
													</Grid>
													<RichEditor
														formDataValue={JSON.parse(formData?.text as string) || ''}
														updateFormValue={updateFormValue}
													/>
													{formErrors.includes('text') ? (
														<span className={global.errorText}>Message is required</span>
													) : formErrors.includes('textErr') ? (
														<span className={global.errorText}>Text limit exceeded</span>
													) : (
														''
													)}
												</Stack>
											</Stack>
										</Stack>
									</>
								)}
							</Paper>
						</Stack>
					</Stack>
					<ConfirmationModal
						handleClose={() => setIsDelete(false)}
						message={'Are you sure you want to delete this template?'}
						open={isDelete}
						handleConfirmation={handleDelete}
						handleCancel={() => setIsDelete(false)}
						loading={deleteLoading}
					/>
				</>
			</Stack>
		</>
	);
};

export default CreateEditReceiptTemplate;
