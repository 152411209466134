import React, { useState, useEffect } from 'react';
import { Typography, Button, Divider, Grid, Paper, Stack, TextField, Chip, Tooltip } from '@mui/material';
import { Close, ChildCare, AccountCircle, CalendarToday, Receipt, Download } from '@mui/icons-material';
import { useGlobalStyles } from 'Components/Common/global/global';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import { useNavigate } from 'react-router-dom';
import {
	GenerateIssuedEOYReceipt,
	ConfirmationModalState,
} from 'Components/Reusable/GenerateReceipt/GenerateReceipt';
import useReceiptActions, { IssueReceipt } from 'hooks/useReceiptActions';
import AccordianTable from 'Components/Common/TableAccordian';
import { StateType, OrderType } from 'contexts/intialstates/Types';
import LoadingButton from '@mui/lab/LoadingButton';
import { yearEndHead, yearEndSuccessfulHead } from './YearEndReceiptMock';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import { sortTable } from 'Components/Common/Tables/sortTable';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';

const YearEndReceipt = () => {
	const global = useGlobalStyles();
	const navigate = useNavigate();
	const [issueReceiptDate, setIssueReceiptDate] = useState<null | number>(null);
	const [issueReceipt, setIssueReceipt] = useState<StateType<IssueReceipt>>({
		data: null,
		error: null,
		loading: false,
	});
	const { issueAllEOYReceipt, generateSingleEOY } = useReceiptActions();
	const [isOpen, setIsopen] = useState<ConfirmationModalState>({
		open: false,
		id: null,
	});
	const [loading, setLoading] = useState(false);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [sendEmail, setSendEmail] = useState(false);

	useEffect(() => {
		if (!orderBy) return;
		handleIssueReceipt();
	}, [orderBy]);

	const handleGenerateReceipt = (id: number) => {
		setIsopen({
			open: true,
			id,
		});
	};

	const cancelConfirmation = () => {
		setIsopen({
			open: false,
			id: null,
		});
	};

	const generateReceipt = async () => {
		if (isOpen.id) {
			setLoading(true);
			await generateSingleEOY(isOpen.id, sendEmail);
			setLoading(false);
			cancelConfirmation();
		}
	};

	const handleIssueReceipt = async () => {
		if (!issueReceiptDate) return;
		await issueAllEOYReceipt({ year: issueReceiptDate, issueReceipt, setIssueReceipt, orderBy });
	};

	const updateReceiptDate = (key: string) => (e: any) => {
		if (key === 'issueReceiptDate') {
			setIssueReceiptDate(new Date(e.target.value).getFullYear());
			return;
		}
	};

	const header = () => {
		return (
			<Stack direction="row" justifyContent="space-between" alignItems="center" mr={2}>
				<Stack>Receipt Generated Successfully</Stack>
				<Stack>
					<GenerateIssuedEOYReceipt year={issueReceiptDate} />
				</Stack>
			</Stack>
		);
	};

	const headerUnsuccessful = () => {
		return (
			<Stack direction="row" alignItems="center" spacing={1}>
				<Stack>Receipt Generated Unsuccessfully</Stack>
				<Chip
					color="warning"
					variant="outlined"
					label="Please review incomplete donations linked with these donors to generate a receipt."
				/>
			</Stack>
		);
	};

	const getTableData = () => {
		return issueReceipt?.data?.successful.map((row, index) => {
			return {
				's/n': index + 1,
				donor: `${row.donor?.firstName ?? ''} ${row.donor?.lastName ?? ''}`,
				donorId: row.donor?.id || 'N/A',
				amount: row.amount,
				society: row.society?.code || 'N/A',
				actions: (
					<Button variant="outlined" size="small" onClick={() => handleGenerateReceipt(row.id)}>
						<Download color="primary" />
					</Button>
				),
			};
		});
	};

	const getColumnData = () => {
		return yearEndHead.map((column: any) => {
			return {
				...column,
				data: (
					<Tooltip title={column.id == 'actions' || column.id == 's/n' ? '' : `Sort by ${column.label}`}>
						<span
							style={{ cursor: 'pointer' }}
							onClick={() =>
								sortTable({
									column: column.id,
									setLabel,
									setOderBy,
									setOrderType,
									orderType,
									label,
								})
							}
						>
							{column.label}
							{column.id == label && <OrderTypeArrow orderType={orderType} IsNotWhite />}
						</span>
					</Tooltip>
				),
			};
		});
	};

	const getSuccessfulColumnData = () => {
		return yearEndSuccessfulHead.map((column: any) => {
			return {
				...column,
				data: (
					<Tooltip title={column.id == 'actions' || column.id == 's/n' ? '' : `Sort by ${column.label}`}>
						<span
							style={{ cursor: 'pointer' }}
							onClick={() =>
								sortTable({
									column: column.id,
									setLabel,
									setOderBy,
									setOrderType,
									orderType,
									label,
								})
							}
						>
							{column.label}
							{column.id == label && <OrderTypeArrow orderType={orderType} IsNotWhite />}
						</span>
					</Tooltip>
				),
			};
		});
	};

	const getUnsuccessfulTableData = () => {
		return issueReceipt?.data?.unSuccessful.map((row, index) => {
			return {
				's/n': index + 1,
				donor: `${row.donor?.firstName ?? ''} ${row.donor?.lastName ?? ''}`,
				donorId: row.donor?.id || 'N/A',
				amount: row.amount,
				actions: (
					<Button variant="outlined" size="small" onClick={() => navigate(`/donors/${row.donor.id}?tab=1`)}>
						View
					</Button>
				),
			};
		});
	};

	const getCount = () => {
		return issueReceipt?.data?.successful.length || 0;
	};

	return (
		<>
			<Stack>
				<Paper sx={{ width: '100%', p: 3, mb: 4 }}>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Stack>
							<Typography sx={{ fontSize: 18, fontWeight: 600 }}>Year End Receipt</Typography>
							<Typography sx={{ fontSize: 12, fontWeight: 200, maxWidth: 800 }}>
								{`This process generates one summary receipt for each donor for donations contributed to each
							Society in previous year up to the Fiscal year cut off date and with receipt type "Y". The year
							end receipt will carry a donation date as Dec. 30 of previous year.`}
							</Typography>
						</Stack>
						<Stack direction="row" mr={2}>
							<Button
								sx={{ textTransform: 'none' }}
								startIcon={<Close />}
								variant="contained"
								onClick={() => navigate('/admin')}
							>
								Cancel
							</Button>
						</Stack>
					</Stack>
					<Typography sx={{ fontSize: 14, fontWeight: 600, mt: 2 }}>Issue Receipts</Typography>
					<Divider />
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<AccountCircle className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Propagation of Faith
									</Typography>
								</Grid>
							</Grid>
							<Stack>
								<TextField
									disabled
									size="small"
									label=""
									variant="outlined"
									type="number"
									defaultValue={0}
									value={getCount()}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<ChildCare className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Holy Childhood
									</Typography>
								</Grid>
							</Grid>
							<Stack>
								<TextField
									disabled
									size="small"
									label=""
									variant="outlined"
									type="number"
									defaultValue={0}
									value={getCount()}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<AccountCircle className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										St. Peter
									</Typography>
								</Grid>
							</Grid>
							<Stack>
								<TextField
									disabled
									size="small"
									label=""
									variant="outlined"
									type="number"
									defaultValue={0}
									value={getCount()}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid sx={{ mb: 0.5 }} item>
									<CalendarToday className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Receipt Year
									</Typography>
								</Grid>
							</Grid>
							<BasicDatePicker
								size="small"
								year
								maxDate={new Date(`${new Date().getFullYear() - 1}-12-31`)}
								value={issueReceiptDate ? `12/31/${issueReceiptDate}` : null}
								setValue={updateReceiptDate('issueReceiptDate')}
							/>
						</Grid>
						<Stack direction="column" justifyContent="end" py={2} mb={1}>
							<LoadingButton
								sx={{ textTransform: 'none' }}
								onClick={() => handleIssueReceipt()}
								disabled={issueReceiptDate ? issueReceiptDate >= new Date().getFullYear() : true}
								startIcon={<Receipt />}
								variant="contained"
								loading={!orderBy && issueReceipt?.loading}
								loadingPosition="start"
							>
								Issue Receipts
							</LoadingButton>
						</Stack>
					</Grid>
				</Paper>
				{issueReceipt?.data?.successful?.length ? (
					<AccordianTable
						header={header}
						columnData={getSuccessfulColumnData()}
						tableData={getTableData()}
						loading={issueReceipt?.loading}
					/>
				) : null}
				<Stack mt={4}>
					{issueReceipt?.data?.unSuccessful?.length ? (
						<AccordianTable
							header={headerUnsuccessful}
							columnData={getColumnData()}
							tableData={getUnsuccessfulTableData()}
							loading={issueReceipt?.loading}
						/>
					) : null}
				</Stack>
			</Stack>
			<ConfirmationModal
				handleCancel={cancelConfirmation}
				handleConfirmation={generateReceipt}
				open={isOpen?.open}
				handleClose={cancelConfirmation}
				message="Do you want to generate a receipt for this deposit?"
				loading={loading}
				sendEmail={sendEmail}
				setSendEmail={setSendEmail}
			/>
		</>
	);
};

export default YearEndReceipt;
