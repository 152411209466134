import React, { useState } from 'react';

import RichEditor from './RichEditor';

const Editor = ({ formDataValue, updateFormValue, error }: any) => {
	const initialValue = formDataValue || [
		{
			type: 'paragraph',
			children: [{ text: `Thank you for your contribution` }],
		},
	];
	const [input, setInput] = useState(initialValue);

	return (
		<RichEditor
			formDataValue={formDataValue}
			updateFormValue={updateFormValue}
			value={input}
			setValue={setInput}
			error={error}
		/>
	);
};

export default Editor;
