import React, { useRef } from 'react';
import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { DonationDetailedReport, DonationDetailedReportBody } from 'contexts/intialstates/Types';
import { convertDate } from 'helpers/dayAndTime';
import FormatCurrency from 'helpers/FormatCurrency';
import { fieldName } from 'Components/Reusable/FilterList/FilterList';
import { DonationsSummaryPayload, FormDataPayload } from '.';
import Logo from './../../../assets/images/logo.png';
import { currentDate } from 'helpers/dayAndTime';
import { Typography } from '@mui/material';
import Report from '../Report';
import { useStyles } from 'Components/Reports/DonorReports/DonorReport.style';
import { useReactToPrint } from 'react-to-print';
import useReportActions from 'hooks/useReportAction';

interface Props {
	data: DonationDetailedReport;
	formData: FormDataPayload;
	loading: boolean;
}

const DetailedReport = ({ data, formData, loading }: Props) => {
	const classes = useStyles();
	const componentRef = useRef(null);

	const { getDonationsDetailedCSV, getDonationsDetailedPDF } = useReportActions();

	const splitter = () => {
		if (!data?.body.length) return;
		const detailedData: any = [];
		const finalData = [];
		data.body.map((summary: DonationDetailedReportBody) => {
			detailedData.push({
				groupName: summary.groupName || '-',
				categoryName: summary.categoryName === 'Board' ? 'School Board' : summary.categoryName || '-',
				receiptNumber: summary.receiptNumber || '-',
				donorId: summary.donorId || '-',
				donorName: summary.donorName || '-',
				amount: summary.amount ? <FormatCurrency value={summary.amount} /> : '-',
			});
		});
		for (let i = 0; i < detailedData.length; i += 6) {
			finalData.push(detailedData.slice(i, i + 6));
		}
		return finalData;
	};

	const printContent = () => (
		<>
			{loading ? (
				'Processing...'
			) : (
				<Box width="100%">
					<Table sx={{ minWidth: 700 }} aria-label="spanning table">
						<TableHead className={classes.tableHead}>
							<TableRow>
								<TableCell align="center">{fieldName(formData.groupby)}</TableCell>
								<TableCell align="center">{fieldName(formData.category)}</TableCell>
								<TableCell align="center">Receipt #</TableCell>
								<TableCell align="center">Donor</TableCell>
								<TableCell align="center">Name</TableCell>
								<TableCell align="center">Donation Amount</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{splitter()
								?.flatMap((item: any) => item)
								?.map((row: DonationDetailedReportBody, index: number) => (
									<TableRow key={index} className={classes.tableRowWrap}>
										<TableCell
											align="center"
											sx={{
												color: '#445f6f',
												fontSize: 18,
												textTransform: 'capitalize',
												fontWeight: 600,
											}}
										>
											{row.groupName || ''}
										</TableCell>
										<TableCell align="center">{row.categoryName || ''}</TableCell>
										<TableCell align="center">{row.receiptNumber || ''}</TableCell>
										<TableCell align="center">{row.donorId || ''}</TableCell>
										<TableCell align="center">{row.donorName || ''}</TableCell>
										<TableCell align="center">{row.amount || ''}</TableCell>
									</TableRow>
								))}
							<TableRow className={classes.tableTotal}>
								<TableCell align="center" colSpan={5}>
									<Stack py={1} sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}>
										<Stack
											direction="row"
											justifyContent={'center'}
											sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
										>
											Grand Total
										</Stack>
									</Stack>
								</TableCell>
								<TableCell colSpan={1} align="center">
									<Stack py={1} sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}>
										<Stack
											direction="row"
											justifyContent={'center'}
											sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
										>
											{data?.grandTotal ? <FormatCurrency value={data?.grandTotal} /> : '-'}
										</Stack>
									</Stack>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Box>
			)}
		</>
	);

	const printData = () => {
		const splitData = splitter();
		return (
			<TableContainer>
				{splitData?.map((dataSplit, index) => {
					return (
						<div className={classes.pageBreak} key={index}>
							<Table sx={{ minWidth: 700 }} key={index} aria-label="spanning table">
								<TableHead className={classes.tableHead}>
									<TableRow>
										<TableCell align="center">{fieldName(formData.groupby)}</TableCell>
										<TableCell align="center">{fieldName(formData.category)}</TableCell>
										<TableCell align="center">Receipt #</TableCell>
										<TableCell align="center">Donor</TableCell>
										<TableCell align="center">Name</TableCell>
										<TableCell align="center">Donation Amount</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{dataSplit.map((row: DonationDetailedReportBody, index: number) => (
										<TableRow key={index} className={classes.tableRowWrap}>
											<TableCell
												align="center"
												sx={{
													color: '#445f6f',
													fontSize: 18,
													textTransform: 'capitalize',
													fontWeight: 600,
												}}
											>
												{row.groupName || ''}
											</TableCell>
											<TableCell align="center">{row.categoryName || ''}</TableCell>
											<TableCell align="center">{row.receiptNumber || ''}</TableCell>
											<TableCell align="center">{row.donorId || ''}</TableCell>
											<TableCell align="center">{row.donorName || ''}</TableCell>
											<TableCell align="center">{row.amount || ''}</TableCell>
										</TableRow>
									))}
									{index === splitData.length - 1 && (
										<TableRow className={classes.tableTotal}>
											<TableCell align="center" colSpan={5}>
												<Stack
													py={1}
													sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
												>
													<Stack
														direction="row"
														justifyContent={'center'}
														sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
													>
														Grand Total
													</Stack>
												</Stack>
											</TableCell>
											<TableCell colSpan={1} align="center">
												<Stack
													py={1}
													sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
												>
													<Stack
														direction="row"
														justifyContent={'center'}
														sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
													>
														{data?.grandTotal ? <FormatCurrency value={data?.grandTotal} /> : '-'}
													</Stack>
												</Stack>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</div>
					);
				})}
			</TableContainer>
		);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const handleExport = async (isPdf?: boolean) => {
		if (!formData?.isdetailed) return;
		const payload: DonationsSummaryPayload = {
			category: formData?.category,
			groupby: formData?.groupby,
			isdetailed: formData?.isdetailed,
			start: `${formData.start.getFullYear()}-${formData.start.getMonth() + 1}-${formData.start.getDate()}`,
			end: `${formData.end.getFullYear()}-${formData.end.getMonth() + 1}-${formData.end.getDate()}`,
			organizationtypes: formData.organizationtypes?.toString(),
			receiptFrequency: formData.receiptFrequency?.toString(),
			societyids: formData.societyids?.toString(),
			provinceids: formData.provinceids?.toString(),
			paymentmethodids: formData.paymentmethodids?.toString(),
			donationtypeids: formData?.donationtype?.map((item) => item.id).join(','),
			batchno: formData?.batchno,
		};
		if (isPdf === true) {
			await getDonationsDetailedPDF(payload);
		} else {
			await getDonationsDetailedCSV(payload);
		}
	};

	return (
		<>
			{' '}
			<Report
				title="Donations Detailed Report"
				subTitle={`From ${convertDate(formData.start)} To ${convertDate(formData.end)}`}
				handlePrint={() => handleExport(true)}
				handleExport={handleExport}
			>
				{printContent()}
			</Report>
			<div style={{ display: 'none' }}>
				<div style={{ padding: 16 }} ref={componentRef}>
					<Stack sx={{ my: 2, px: 1 }}>
						<>
							<Stack width="100%" direction="row">
								<Stack flex={0.5} alignItems="flex-start" direction="column">
									<img src={Logo} alt="logo" width={40} height={50} />
								</Stack>

								<Stack sx={{ mb: 2, textAlign: 'left' }} alignItems="left" direction="column">
									<Typography sx={{ color: '#1976d2', fontSize: 20, fontWeight: 700 }}>
										Donations Detailed Report
									</Typography>
									<Typography sx={{ color: '#445f6f', fontSize: 18, fontWeight: 400 }}>
										{`From ${convertDate(formData.start)} To ${convertDate(formData.end)}`}
									</Typography>
								</Stack>
							</Stack>
							<Stack
								direction={'row'}
								justifyContent="flex-end"
								mb={3}
								sx={{ color: '#445f6f', fontSize: 16, fontWeight: 400 }}
							>
								{currentDate()}
							</Stack>
						</>

						<Box width="100%">{printData()}</Box>
					</Stack>
				</div>
			</div>
		</>
	);
};

export default DetailedReport;
