import React, { useState, useEffect } from 'react';
import { Stack, Autocomplete, TextField, Box } from '@mui/material';
import useProvinceActions from 'hooks/useProvinceActions';
import { StateType, Province } from 'contexts/intialstates/Types';
import { useGlobalStyles } from 'Components/Common/global/global';
import useDebounce from 'Components/Reusable/hooks/debounce';

interface P {
	disabled?: boolean;
	value?: string;
	handleChange?: (val: any) => void;
}

const ProvinceAutocomplete = ({ disabled, value, handleChange }: P) => {
	const { getProvinceBySearch } = useProvinceActions();
	const global = useGlobalStyles();
	const [provinceSearch, setProvinceSearch] = useState<StateType<Province[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [searchTerm, setSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce(searchTerm, 1000);

	useEffect(() => {
		if (!debouncedSearchTerm) return;
		getProvinceBySearch({ provinceSearch, setProvinceSearch, searchTerm });
	}, [debouncedSearchTerm]);

	return (
		<Stack>
			<Autocomplete
				freeSolo
				fullWidth
				disabled={disabled}
				value={value ?? ''}
				onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
					handleChange?.(val);
				}}
				options={(provinceSearch?.data || []).map((item) => ({
					id: item.id,
					label: item.provinceName,
				}))}
				renderOption={(props, option) => (
					<Box component="li" {...props} key={option.id}>
						{option.label}
					</Box>
				)}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
						label=""
						InputProps={{
							...params.InputProps,
						}}
						onChange={(event: any) => {
							setSearchTerm(event.target.value);
						}}
						size={'small'}
						placeholder={'Search province'}
						className={disabled ? global.disabledInput : ''}
					/>
				)}
			/>
		</Stack>
	);
};

export default ProvinceAutocomplete;
