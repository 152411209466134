import { useContext } from 'react';
import { ResponseError, StateType, SchoolBoard, School, Donor } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface WalkathonData {
	id: number;
	amount: number;
	status: string;
	receivedDate: string;
	created: string;
	updated: string;
	schoolBoard: SchoolBoard;
}

interface DonorContribution {
	id: number;
	receiptNum: number;
	amount: number;
	donor: Donor;
	depositId: number;
}

interface SchoolContribution {
	id: number;
	amount: number;
	school: School;
	donorContributions: DonorContribution[];
}

export interface WalkathonById extends WalkathonData {
	schoolContributions: SchoolContribution[];
}

export interface Walkathon {
	pageNumber: number;
	pageSize: number;
	totalPages: number;
	totalRecords: number;
	body: WalkathonData[];
}

export interface GetWalkathonProps {
	walkathon: StateType<Walkathon>;
	setWalkathon: React.Dispatch<React.SetStateAction<StateType<Walkathon>>>;
	page: number;
	pageSize: number;
	orderBy?: string;
}

export interface DonorContributionInput {
	id?: number;
	donorId: number;
	amount: number;
}

export interface SchoolContributionInput {
	id?: number;
	schoolId: number;
	amount: number;
	donorContributions: DonorContributionInput[];
}

export interface CreateWalkathonPayload {
	schoolBoardId: number;
	amount: number;
	receivedDate: string;
	schoolContributions: SchoolContributionInput[];
}

interface GetWalkathonIdProps {
	walkathon: StateType<WalkathonById>;
	setWalkathon: React.Dispatch<React.SetStateAction<StateType<WalkathonById>>>;
	id: number;
}

const useWalkathonActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = useContext(GlobalContexts);

	const getWalkathon = async ({ walkathon, setWalkathon, page, pageSize, orderBy }: GetWalkathonProps) => {
		try {
			setWalkathon({
				...walkathon,
				loading: true,
			});

			let response;
			if (walkathon.data) {
				response = await api.get(
					`walkathon/list?PageNumber=${page + 1}&PageSize=${pageSize}${orderBy || ''}`,
				);
			} else {
				response = await api.get(`walkathon/list?&SortBy=receivedDate%20desc`);
			}

			setWalkathon({
				...walkathon,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || walkathon.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setWalkathon({
					...walkathon,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			alert('An error occurred');
		}
	};

	const createWalkathon = async (payload: CreateWalkathonPayload): Promise<number | boolean> => {
		try {
			const response = await api.post(`walkathon/create`, {
				...payload,
			});
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Walkathon created succesfully',
					severity: 'success',
					show: true,
				});
				return response?.data?.result?.id;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const getWalkathonById = async ({ walkathon, setWalkathon, id }: GetWalkathonIdProps) => {
		try {
			setWalkathon({
				...walkathon,
				loading: true,
			});
			const response = await api.get(`walkathon/${id}`);

			setWalkathon({
				...walkathon,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setWalkathon({
					...walkathon,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			alert('An error occurred');
		}
	};

	const updateWalkathon = async (payload: CreateWalkathonPayload, id: number): Promise<boolean> => {
		try {
			const response = await api.put(`walkathon/${id}/update`, {
				...payload,
				id,
			});
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Walkathon updated succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deleteWalkathon = async (walkathonId: number): Promise<boolean> => {
		try {
			const deleteWalkathon = await api.remove(`/walkathon/${walkathonId}/archive`);
			if (deleteWalkathon?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Walkathon deleted succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const postWalkathon = async (walkathonId: number): Promise<boolean> => {
		try {
			const postedWalkathon = await api.post(`/walkathon/${walkathonId}/post`);
			if (postedWalkathon?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Walkathon posted succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const generateReceipt = async (walkathonId: number, sendEmail: boolean) => {
		try {
			const response = await api.get(
				`receipt/walkathon/${walkathonId}${sendEmail ? '?sendEmail=true' : ''}`,
				{
					responseType: 'blob',
				},
			);
			const url = window.URL.createObjectURL(new Blob([response?.data], { type: 'application/zip' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'receipt.zip');
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
		}
	};

	return {
		getWalkathon,
		createWalkathon,
		getWalkathonById,
		updateWalkathon,
		deleteWalkathon,
		postWalkathon,
		generateReceipt,
	};
};

export default useWalkathonActions;
