import { makeStyles, createStyles } from '@material-ui/core/styles';

const drawerWidth = 260;

export const useStyles = makeStyles(() =>
	createStyles({
		menuItem: {
			flexDirection: 'column',
			alignItems: 'center',
			color: '#fff',
			'&.active': {
				background: 'var(--mainBackground)',
				color: '#fff',
				borderRadius: 10,
				'& .MuiListItemIcon-root': {
					color: '#fff',
				},
			},
		},
		menuItemIcon: {
			color: '#fff',
			minWidth: 'auto',
			transform: 'scale(0.8)',
		},
		itemText: {
			fontSize: 12,
		},
		sideMenu: {
			width: '100%',
		},
		navList: {
			width: drawerWidth,
		},
		menuItemDrawer: {
			width: drawerWidth,
		},
		menuItemIconInDrawer: {
			color: '#97c05c',
		},
		boxItemBG: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			color: 'whitesmoke',
			background: '#1976D2',
			width: 40,
			height: 40,
			marginTop: 12,
			borderRadius: 5,
		},
	}),
);
