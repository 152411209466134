import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import {
	Button,
	Paper,
	Stack,
	Grid,
	Typography,
	Divider,
	IconButton,
	Select,
	MenuItem,
	FormHelperText,
	TextField,
	Tooltip,
} from '@mui/material';
import {
	Add,
	KeyboardArrowLeft,
	TaskAlt,
	Numbers,
	Domain,
	CalendarToday,
	Search,
	Home,
	Archive,
} from '@mui/icons-material';
import { useGlobalStyles } from 'Components/Common/global/global';
import { ContentSkeleton } from 'Components/Deposits/CreateEditDepositWithDonations/Content';
import { Formik, Form, Field } from 'formik';
import { FormTextField } from 'Components/Common/TextField';
import useHcaOrdersActions from 'hooks/useHcaOrdersActions';
import useHCAMaterialsActions from 'hooks/useHCAMaterialsActions';
import { StateType, HcaOrders, HCAMaterials, HcaOrdersData } from 'contexts/intialstates/Types';
import * as yup from 'yup';
import OrdersModal from './OrdersModal';
import { organizationType } from '../data';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import { LoadingButton } from '@mui/lab';
import { GlobalContexts } from 'contexts/GlobalContext';
import PackingSlipBtn from './PackingSlipBtn';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';

export interface HcaOrdersValues {
	id?: number;
	organizationId?: number;
	organizationType: string;
	organizationName: string;
	address: string;
	receivedDate: string;
	materials?: {
		hcaMaterialId: number;
		quantity?: number;
	}[];
}

export interface OrderMaterials {
	hcaMaterialId: number;
	quantity: number;
}

export interface ModalState {
	state: boolean;
	type: string;
	orgId: number | null;
}

const CreateEditOrders = () => {
	const { routeHistory, setSimpleErrorSnack } = React.useContext(GlobalContexts);
	const global = useGlobalStyles();
	const [open, setOpen] = React.useState<any>({ state: false, type: '' });
	const navigate = useNavigate();
	const { id } = useParams();
	const { getHcaOrders, createHcaOrder, updateHcaOrder, getHcaOrdersByID, confirmOrderByID, deleteHcaOrder } =
		useHcaOrdersActions();
	const { getAllHCAMaterials } = useHCAMaterialsActions();
	const [reload, setReload] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [hcaMaterials, setHcaMaterials] = React.useState<StateType<HCAMaterials[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [hcaOrders, setHcaOrders] = React.useState<StateType<HcaOrdersData>>({
		data: null,
		error: null,
		loading: false,
	});

	const [confirmHcaOrder, setConfirmHcaOrder] = React.useState<StateType<HcaOrders>>({
		data: null,
		error: null,
		loading: false,
	});
	const [initial, setInitial] = useState<HcaOrdersValues>({
		id: undefined,
		organizationId: undefined,
		organizationType: '',
		organizationName: '', //readonly
		address: '',
		receivedDate: '',
		materials: hcaMaterials.data?.map((material: HCAMaterials) => ({
			hcaMaterialId: material.id,
			quantity: 0,
		})),
	});
	const [hcaOrder, setHcaOrder] = React.useState<StateType<HcaOrders>>({
		data: null,
		error: null,
		loading: false,
	});
	const [materialData, setMaterialData] = React.useState<HCAMaterials[]>();
	const [isDelete, setIsDelete] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);

	useEffect(() => {
		if (id) {
			getHcaOrders({ setHcaOrders, hcaOrders, pageSize: 1000, page: 0 });
		}
		getAllHCAMaterials({ hcaMaterials, setHcaMaterials });
	}, []);

	useEffect(() => {
		if (!id) return;
		getHcaOrdersByID({ setHcaOrder, hcaOrder, id: parseInt(id) });
	}, [id, confirmHcaOrder?.data, reload]);

	useEffect(() => {
		if (!hcaOrder?.data) return;
		const { id, organizationId, organizationType, organizationName, address, receivedDate, materials } =
			hcaOrder?.data;
		setInitial({
			id, //readonly
			organizationId,
			organizationType,
			organizationName, //readonly
			address: address.replaceAll('N/A', '').trim(),
			receivedDate,
			materials: materials?.map((material: OrderMaterials) => ({
				hcaMaterialId: material.hcaMaterialId,
				quantity: material.quantity,
			})),
		});
	}, [hcaOrder]);

	useEffect(() => {
		if (!open?.data) return;
		const { id, organizationType, receivedDate, materials, organizationId, organizationName, address } =
			open?.data;
		setInitial({
			id, //readonly
			organizationId,
			organizationType,
			organizationName: organizationName.replaceAll('N/A', '').trim(),
			address: address.replaceAll('N/A', '').trim(),
			receivedDate,
			materials: materials?.map((material: OrderMaterials) => ({
				hcaMaterialId: material.hcaMaterialId,
				quantity: material.quantity,
			})),
		});
	}, [open.data]);

	const handleSubmit = async (values: HcaOrdersValues) => {
		const materialIndex = materialData?.findIndex((m: any) => parseInt(m.quantity) > 0);

		if (!values.organizationId) {
			setSimpleErrorSnack({
				severity: 'error',
				message: 'Please select an organization from the pop up menu',
				show: true,
			});
			return;
		}
		if (materialIndex === -1) {
			setSimpleErrorSnack({
				severity: 'error',
				message: 'You need to add at least one material',
				show: true,
			});
			return;
		}
		if (!values.receivedDate) {
			setSimpleErrorSnack({ severity: 'error', message: 'Received date is required', show: true });
			return;
		}
		if (!values.organizationType) {
			setSimpleErrorSnack({ severity: 'error', message: 'Received date is required', show: true });
			return;
		}
		setLoading(true);
		if (id) {
			await updateHcaOrder(
				{
					...values,
					materials: materialData?.map((material: HCAMaterials) => ({
						hcaMaterialId: material.id,
						quantity: parseInt(material.quantity),
					})),
				},
				parseInt(id),
			);
		} else {
			const response = await createHcaOrder({
				...values,
				materials: materialData?.map((material: HCAMaterials) => ({
					hcaMaterialId: material.id,
					quantity: parseInt(material.quantity),
				})),
			});
			if (response && typeof response === 'object') {
				navigate(`/hca/create-edit-hca-orders/${response?.id}`);
			}
		}
		setLoading(false);
	};

	const validationHcaOrders = yup.object().shape({
		organizationName: yup.string().required('Organization name is required'),
		address: yup.string().required('Address is required'),
	});

	React.useEffect(() => {
		hcaMaterials.data &&
			setMaterialData(
				hcaMaterials.data?.map((material: HCAMaterials) => {
					if (id) {
						const materialIndex = hcaOrder.data?.materials?.findIndex(
							(m: OrderMaterials) => material.id == m.hcaMaterialId,
						);

						return materialIndex !== undefined && materialIndex > -1
							? { ...material, isEditable: true, quantity: hcaOrder.data?.materials[materialIndex].quantity }
							: { ...material, isEditable: true, quantity: 0 };
					}
					return { ...material, isEditable: true, quantity: 0 };
				}),
			);
	}, [hcaMaterials.data?.length, id, hcaOrder.data]);

	const onInputChange = ({ e, row }: any) => {
		const mat =
			materialData &&
			materialData.map((m: HCAMaterials) => {
				if (m.id === row.id && m.quantity !== undefined) {
					return { ...m, quantity: e.target.value >= 0 ? e.target.value : 0 };
				} else {
					return m;
				}
			});
		mat && setMaterialData(mat);
	};

	const handleConfirmOrder = () => {
		if (!id) return;
		confirmOrderByID({ confirmHcaOrder, setConfirmHcaOrder, id: parseInt(id) });
		setReload(!reload);
	};

	const handleCloseDelete = () => {
		setIsDelete(false);
	};

	const handleDelete = async () => {
		if (id) {
			setSaveLoading(true);
			const isSucess = await deleteHcaOrder(parseInt(id));
			setSaveLoading(false);
			handleCloseDelete();
			if (isSucess) {
				navigate('/hca/hca-orders');
			}
		}
	};

	return (
		<>
			<Box>
				{hcaOrder?.loading && initial && organizationType ? (
					<ContentSkeleton />
				) : (
					<Formik
						enableReinitialize
						onSubmit={handleSubmit}
						validationSchema={validationHcaOrders}
						initialValues={initial}
					>
						{({ values, setFieldValue, errors, touched }) => (
							<Form noValidate autoComplete="off">
								<Stack
									direction={{ xs: 'row', sm: 'column' }}
									alignItems={{ xs: 'center', sm: 'initial' }}
									justifyContent={{ xs: 'center', sm: 'initial' }}
									spacing={{ xs: 3, sm: 0 }}
									mb={2}
								>
									<Stack
										alignItems={'center'}
										justifyContent={'center'}
										sx={{ display: { xs: 'flex', sm: 'none' }, background: '#fff' }}
									>
										<IconButton
											id="demo-customized-button"
											aria-controls={open.state ? 'demo-customized-menu' : undefined}
											aria-haspopup="true"
											aria-expanded={open.state ? 'true' : undefined}
											onClick={() => {
												if (routeHistory === 'Board') {
													navigate('/admin/school-boards');
													return;
												}
												navigate(-1);
											}}
										>
											<KeyboardArrowLeft color="primary" />
										</IconButton>
									</Stack>
									<Stack
										alignItems="center"
										direction={{ xs: 'column', sm: 'row' }}
										justifyContent={'space-between'}
										spacing={1}
									>
										<Stack
											alignItems={'center'}
											direction="row"
											justifyContent={'flex-start'}
											spacing={{ xs: 0, sm: 4 }}
											mb={{ xs: 1, sm: 0 }}
										>
											<Stack
												alignItems={'center'}
												justifyContent={'center'}
												sx={{ display: { xs: 'none', sm: 'flex' }, background: '#fff' }}
											>
												<IconButton
													id="demo-customized-button"
													aria-controls={open.state ? 'demo-customized-menu' : undefined}
													aria-haspopup="true"
													aria-expanded={open.state ? 'true' : undefined}
													onClick={() => {
														if (routeHistory === 'Board') {
															navigate('/admin/school-boards');
															return;
														}
														navigate('/hca/hca-orders');
													}}
												>
													<KeyboardArrowLeft color="primary" />
												</IconButton>
											</Stack>
											<Stack direction="column">
												<Box
													sx={{
														fontSize: '32px',
														fontWeight: 700,
														color: 'var(--mainColor)',
														wordWrap: 'normal',
													}}
												>
													{id ? (
														<>
															Order ID: <span style={{ fontWeight: 900 }}>{id}</span>
														</>
													) : (
														'New Order'
													)}
												</Box>
												{id && (
													<Button
														sx={{
															cursor: 'initial',
															width: 100,
															p: '4px 8px',
															borderRadius: '6px',
															fontSize: 12,
															textTransform: 'capitalize',
															background: '#fff',
															'&:hover': { background: '#fff', color: '#1976D2' },
															color: '#1976D2',
														}}
														disableElevation
														disableRipple
														variant="outlined"
													>
														{hcaOrder?.data?.status}
													</Button>
												)}
											</Stack>
										</Stack>
										<Stack
											direction={{ xs: 'column', md: 'row' }}
											justifyContent={'flex-end'}
											flexWrap="wrap"
											gap={1}
										>
											{id && (
												<Tooltip
													title={
														hcaOrder?.data?.status === 'Confirmed'
															? 'Cannot delete/archive confirmed order'
															: ''
													}
												>
													<span>
														<Button
															sx={{
																textTransform: 'capitalize',
																background: `${hcaOrder?.data?.status === 'Confirmed' ? 'initial' : '#fff'}`,
															}}
															variant={hcaOrder?.data?.status === 'Confirmed' ? 'contained' : 'outlined'}
															disableElevation
															startIcon={<Archive />}
															onClick={() => setIsDelete(true)}
															disabled={hcaOrder?.data?.status === 'Confirmed'}
														>
															Archive
														</Button>
													</span>
												</Tooltip>
											)}
											{id && (
												<Tooltip
													title={hcaOrder?.data?.status === 'Confirmed' ? 'Order has been confirmed' : ''}
												>
													<span>
														<LoadingButton
															sx={{ textTransform: 'capitalize' }}
															variant="contained"
															disableElevation
															startIcon={<TaskAlt />}
															onClick={handleConfirmOrder}
															loading={confirmHcaOrder.loading}
															loadingPosition="start"
															disabled={hcaOrder?.data?.status === 'Confirmed'}
														>
															Confirm Order
														</LoadingButton>
													</span>
												</Tooltip>
											)}

											{id && <PackingSlipBtn reload={reload} setReload={setReload} id={parseInt(id)} />}
											<Tooltip
												title={
													hcaOrder && hcaOrder?.data?.status === 'Confirmed'
														? 'Cannot update confirmed Orders'
														: ''
												}
											>
												<span>
													<LoadingButton
														sx={{ textTransform: 'capitalize' }}
														variant="contained"
														disableElevation
														startIcon={<Add />}
														type="submit"
														loading={loading}
														loadingPosition="start"
														disabled={hcaOrder && hcaOrder?.data?.status === 'Confirmed'}
													>
														Save
													</LoadingButton>
												</span>
											</Tooltip>
										</Stack>
									</Stack>
								</Stack>
								<Paper className={global.paperFormWrap}>
									<>
										<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Grid container>
													<Grid item>
														<Numbers className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography
															variant="body2"
															sx={{ fontWeight: 600, fontSize: 15, color: '#033959' }}
														>
															Order ID
														</Typography>
													</Grid>
												</Grid>
												<Field
													name="id"
													variant="outlined"
													placeholder="Order ID"
													disabled={true}
													component={FormTextField}
													sx={{
														background: `${
															hcaOrder.data?.status === 'Confirmed' ? 'var(--invalidBackground)' : 'initial'
														}`,
													}}
												/>
											</Grid>
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Grid container>
													<Grid item>
														<Domain className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography
															variant="body2"
															sx={{ fontWeight: 600, fontSize: 15, color: '#033959' }}
														>
															Organization Type
														</Typography>
													</Grid>
												</Grid>
												<Stack direction="row">
													<Field
														component={() => (
															<Select
																fullWidth
																size="small"
																disabled={hcaOrder.data?.status === 'Confirmed'}
																sx={{
																	background: `${
																		hcaOrder.data?.status === 'Confirmed'
																			? 'var(--invalidBackground)'
																			: 'initial'
																	}`,
																}}
																onChange={(e) => {
																	setFieldValue('organizationType', e.target.value);
																}}
																value={values.organizationType}
																error={Boolean(touched?.organizationType && errors?.organizationType)}
															>
																<MenuItem value="">Select Organization Type</MenuItem>
																{organizationType.map(({ label, id }) => (
																	<MenuItem key={id} value={id}>
																		{label}
																	</MenuItem>
																))}
															</Select>
														)}
														name="organizationType"
														as={'select'}
														label="Organization Type"
														variant="outlined"
													/>
													{touched?.organizationType && errors?.organizationType && (
														<FormHelperText error>{errors?.organizationType}</FormHelperText>
													)}
												</Stack>
											</Grid>
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Grid container>
													<Grid item>
														<CalendarToday className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography
															variant="body2"
															sx={{ fontWeight: 600, fontSize: 15, color: '#033959' }}
														>
															Order Received Date
														</Typography>
													</Grid>
												</Grid>
												<Field name="receivedDate" variant="outlined" placeholder="Order Received Date">
													{() => (
														<BasicDatePicker
															disabled={hcaOrder.data?.status === 'Confirmed'}
															value={values.receivedDate || null}
															setValue={(e) => {
																setFieldValue('receivedDate', e.target.value);
															}}
														/>
													)}
												</Field>
											</Grid>
										</Grid>
										<Box sx={{ mt: 2 }}>
											<Typography sx={{ fontSize: { xs: 13, sm: 14 }, fontWeight: 600, mt: 2 }}>
												Organization Details
											</Typography>
											<Divider />
											<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
												<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
													<Grid container>
														<Grid item>
															<Home className={global.formLabelIcon} />
														</Grid>
														<Grid item>
															<Typography
																variant="body2"
																sx={{ fontWeight: 600, fontSize: 15, color: '#033959' }}
															>
																Organization Name
															</Typography>
														</Grid>
													</Grid>
													<Stack direction="row">
														<Stack direction="column" mr={1} width="100%">
															<Field
																name="organizationName"
																variant="outlined"
																placeholder="Organization Name"
																component={FormTextField}
																disabled={hcaOrder.data?.status === 'Confirmed'}
															/>
														</Stack>

														<Button
															sx={{
																textTransform: 'capitalize',
																background: '#fff',
																color: '#1976D2',
																py: 0.5,
															}}
															variant="outlined"
															startIcon={<Search />}
															onClick={() =>
																setOpen({
																	...open,
																	state: true,
																	type: values.organizationType,
																	data: {
																		id: values.id,
																		organizationId: values.organizationId,
																		organizationType: values.organizationType,
																		organizationName: values.organizationName, //readonly
																		address: values.address.replaceAll('N/A', '').trim(),
																		receivedDate: values.receivedDate,
																		materials: materialData,
																	},
																})
															}
															disabled={
																values.organizationType === undefined ||
																values.organizationType === '' ||
																hcaOrder.data?.status === 'Confirmed'
															}
														>
															Search
														</Button>
													</Stack>
												</Grid>
												<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
													<Grid container>
														<Grid item>
															<Add className={global.formLabelIcon} />
														</Grid>
														<Grid item>
															<Typography
																variant="body2"
																sx={{ fontWeight: 600, fontSize: 15, color: '#033959' }}
															>
																Address
															</Typography>
														</Grid>
													</Grid>
													<Field
														name="address"
														variant="outlined"
														placeholder="Address"
														component={FormTextField}
														disabled={hcaOrder.data?.status === 'Confirmed'}
													/>
												</Grid>
											</Grid>
										</Box>
										<Box sx={{ mt: 2 }}>
											<Typography sx={{ fontSize: { xs: 13, sm: 14 }, fontWeight: 600, mt: 2 }}>
												Order Materials
											</Typography>
											<Divider />
											{
												<>
													<Stack px={3}>
														<Stack
															mt={4}
															fontSize={18}
															width="95%"
															direction="row"
															borderBottom="1px solid #DDE5EA"
														>
															<Stack width="30%">Material</Stack>
															<Stack width="30%">Remarks</Stack>
															<Stack width="40%">Quantity</Stack>
														</Stack>
														{materialData?.map((row: HCAMaterials) => (
															<Stack
																width="95%"
																direction="row"
																borderBottom="1px solid #DDE5EA"
																key={row.itemCode}
																fontSize={18}
																py={2}
															>
																<Stack width="30%" pl={1}>
																	<span
																		style={{
																			color: '#04598B',
																			wordWrap: 'break-word',
																			paddingRight: '100px',
																			fontSize: 14,
																		}}
																	>
																		{row?.description || 'N/A'}
																	</span>
																</Stack>
																<Stack sx={{ wordWrap: 'break-word', fontSize: 14 }} width="30%" pl={1}>
																	{row?.remark || 'N/A'}
																</Stack>
																<Stack width="40%" pl={1}>
																	<TextField
																		value={row.quantity}
																		onChange={(e) => onInputChange({ e, row })}
																		sx={{
																			width: '60px',
																			textAlign: 'center',
																			background: `${
																				hcaOrder.data?.status === 'Confirmed'
																					? 'var(--invalidBackground)'
																					: 'initial'
																			}`,
																			'& input': { padding: '10px 0', textAlign: 'center' },
																		}}
																		disabled={hcaOrder.data?.status === 'Confirmed'}
																	/>
																</Stack>
															</Stack>
														))}
													</Stack>
												</>
											}
										</Box>
									</>
								</Paper>
							</Form>
						)}
					</Formik>
				)}
			</Box>
			<OrdersModal open={open} setOpen={setOpen} />
			<ConfirmationModal
				handleClose={() => setIsDelete(false)}
				message={'Are you sure you want to delete this order ?'}
				open={isDelete}
				handleConfirmation={handleDelete}
				handleCancel={() => setIsDelete(false)}
				loading={saveLoading}
			/>
		</>
	);
};

export default CreateEditOrders;
