import React, { useEffect, useState } from 'react';
import { Stack, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIosNew, FilterAltOutlined } from '@mui/icons-material';
import { useStyles } from './Annuities.style';
import AnnuitiesForm, { FormValues } from './AnnuitiesForm';
import AnnuitiesReportView, { AnnuitiesData } from './AnnuitiesReportView';
import useReportAction from 'hooks/useReportAction';
import { StateType } from 'contexts/intialstates/Types';
import moment from 'moment';

const AnnuitiesReport = () => {
	const navigate = useNavigate();
	const classes = useStyles();
	const { getAnnuitiesReport, exportAgreementCSV, downloadAgreementPDF } = useReportAction();

	const [isEdit, setIsEdit] = useState(false);
	const [isShowFilter, setIsShowFilter] = useState(true);
	const [dateRange, setDateRange] = useState({
		start: '',
		end: '',
	});
	const [annuitiesReportData, setAnnuitiesReportData] = useState<StateType<AnnuitiesData[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [formData, setFormData] = useState<FormValues>({
		start: '',
		end: '',
		donors: [],
		agreementType: [],
		frequency: [],
		paymentMethod: [],
		provinceOfRegistration: [],
		agreementStatus: [],
		society: [],
	});
	const handleCancel = () => {
		setAnnuitiesReportData({
			data: null,
			loading: false,
			error: null,
		});
	};

	const handleGetReport = async (formValues: FormValues, isGenerate?: boolean) => {
		const payload = {
			startDate: formValues.start ? moment(formValues?.start).format('YYYY-MM-DD') : '',
			endDate: formValues.end ? moment(formValues?.end).format('YYYY-MM-DD') : '',
			agreementTypeIds: formValues.agreementType.join(','),
			frequencyIds: formValues.frequency.join(','),
			paymentMethodIds: formValues.paymentMethod.join(','),
			agreementStatusIds: formValues.agreementStatus.join(','),
			minReceivedAmount: formValues.receivedMinAmount,
			maxReceivedAmount: formValues.receivedMaxAmount,
			minOverPaymentAmount: formValues.overpaymentMinAmount,
			maxOverPaymentAmount: formValues.overpaymentMaxAmount,
			provinceIds: formValues.provinceOfRegistration.join(','),
			beneficiaryIds: formValues.society.join(','),
			donorIds: formValues.donors.join(','),
		};
		await getAnnuitiesReport({ payload, setAnnuitiesReportData });

		setDateRange({
			start: formValues.start,
			end: formValues.end,
		});
	};

	const handleExport = async (isPdf?: boolean) => {
		const payload = {
			startDate: formData.start ? moment(formData?.start).format('YYYY-MM-DD') : '',
			endDate: formData.end ? moment(formData?.end).format('YYYY-MM-DD') : '',
			agreementTypeIds: formData.agreementType.join(','),
			frequencyIds: formData.frequency.join(','),
			paymentMethodIds: formData.paymentMethod.join(','),
			agreementStatusIds: formData.agreementStatus.join(','),
			minReceivedAmount: formData.receivedMinAmount,
			maxReceivedAmount: formData.receivedMaxAmount,
			minOverPaymentAmount: formData.overpaymentMinAmount,
			maxOverPaymentAmount: formData.overpaymentMaxAmount,
			provinceIds: formData.provinceOfRegistration.join(','),
			beneficiaryIds: formData.society.join(','),
			donorIds: formData.donors.join(','),
		};
		if (isPdf === true) {
			await downloadAgreementPDF(payload);
		} else {
			await exportAgreementCSV(payload);
		}

		setDateRange({
			start: formData.start,
			end: formData.end,
		});
	};

	useEffect(() => {
		if (annuitiesReportData.data) {
			setIsEdit(true);
			setIsShowFilter(false);
		}
	}, [annuitiesReportData.data]);

	return (
		<div>
			<Stack direction="row" marginBottom={2} alignItems={'center'} justifyContent="space-between">
				<Stack direction="row" alignItems={'center'} spacing={4}>
					<Stack className={classes.backIconWrapper}>
						<ArrowBackIosNew onClick={() => navigate('/reports')} />
					</Stack>
					<Stack>
						<span className={classes.title}>Agreements Report</span>
					</Stack>
				</Stack>
				{isEdit && (
					<IconButton
						sx={{
							background: 'white',
							borderRadius: '6px',
							width: 40,
							height: 30,
							border: '1px solid var(--mainColor)',
						}}
						onClick={() => setIsShowFilter(!isShowFilter)}
					>
						<FilterAltOutlined sx={{ fontSize: 16, color: 'var(--mainColor)' }} />
					</IconButton>
				)}
			</Stack>
			<AnnuitiesForm
				handleGetReport={handleGetReport}
				loading={annuitiesReportData?.loading}
				onCancel={handleCancel}
				isEdit={isEdit}
				isShowFilter={isShowFilter}
				formData={formData}
				setFormData={setFormData}
			/>
			{!annuitiesReportData.loading && annuitiesReportData?.data?.length ? (
				<AnnuitiesReportView
					annuitiesData={annuitiesReportData?.data || []}
					start={dateRange.start}
					end={dateRange.end}
					handleExport={handleExport}
				/>
			) : null}
		</div>
	);
};

export default AnnuitiesReport;
