import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles: any = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			color: '#1976d2',
			fontSize: '32px',
			fontWeight: 700,
		},
		backIconWrapper: {
			background: 'var(--white)',
			borderRadius: '4px',
			padding: '5px',
			color: '#1976d2',
			cursor: 'pointer',
		},
		headerFilter: {
			backgroundColor: 'var(--mainBackground) !important',
			padding: '10px',
			borderTopLeftRadius: '4px',
			borderTopRightRadius: '4px',
			'& span': {
				fontWeight: 500,
				fontSize: '20px',
				lineHeight: '28px',
				marginLeft: '20px',
			},
		},
		paperWrap: {
			padding: '30px 0px 30px 40px',
			[theme.breakpoints.down('md')]: {
				padding: '30px',
			},
			borderRadius: '0 !important',
			borderBottomLeftRadius: '4px !important',
			borderBottomRightRadius: '4px !important',
		},
		buttonWrap: {
			margin: '20px',
		},
		tableHead: {
			'& .MuiTableRow-head': {
				'& .MuiTableCell-head': {
					textDecorationLine: 'underline',
					color: '#1976D2',
					fontWeight: 600,
					fontSize: '18px',
					lineHeight: '26px',
					borderBottom: 'none',
				},
			},
		},
		tableRowWrap: {
			'& .MuiTableCell-root': {
				borderBottom: 'none',
				fontSize: '16px',
				lineHeight: '26px',
				padding: '5px',
				backgroundColor: 'initial',
			},
		},
		tableTotal: {
			'& .MuiTableCell-root': {
				borderBottom: 'none',
				color: '#1976D2',
				fontWeight: 600,
				fontSize: '18px',
				lineHeight: '26px',
				padding: '30px 0',
				width: '70%',
				backgroundColor: 'initial',
			},
		},
		pageBreak: {
			pageBreakInside: 'avoid',
			display: 'block',
			pageBreakBefore: 'always',
		},
	}),
);

export const style = {
	fullWidth: { width: '100%' },
	fullLeft: { width: '100%', textAlign: 'left' },
	donor: {
		width: '15%',
		color: '#1976d2',
		textDecoration: 'underline',
		fontWeight: 700,
		fontSize: 16,
		px: 1,
		my: 1,
	},
	others: {
		width: '70%',
		color: '#1976d2',
		textDecoration: 'underline',
		fontWeight: 700,
		fontSize: 16,
		px: 1,
		my: 1,
	},
	donorBody: {
		width: '15%',
		fontSize: 16,
		px: 1,
		my: 1,
		textTransform: 'uppercase',
		fontWeight: 500,
	},
	othersBody: {
		textTransform: 'uppercase',
		width: '70%',
		fontSize: 16,
		px: 1,
		my: 1,
		color: ' #445f6f',
		fontWeight: 500,
	},
	body: { width: '100%', mt: 3, textAlign: 'left' },
	totalTitle: {
		width: '86%',
		fontWeight: 700,
		color: ' #445f6f',
		fontSize: 16,
		textIndent: 100,
	},
	totalAmount: {
		width: '14%',
		fontWeight: 700,
		fontSize: 16,
		color: ' #445f6f',
	},
	wrapper: {
		width: '100%',
		color: ' #445f6f',
		px: 3,
	},
	subTotal: {
		mt: 0.5,
		mb: 2,
	},
	subTotalValue: {
		borderTop: '1px solid #445f6f ',
		width: 'fit-content',
	},
	borderBottom: {
		borderBottom: '1px solid #445f6f ',
		py: 1,
	},
	borderY: {
		borderTop: '1px solid #445f6f ',
		borderBottom: '1px solid #445f6f ',
		width: '75%',
		pb: 0.25,
	},
	grandTotal: {
		width: '81%',
		color: '#1976d2',
		fontWeight: 700,
		fontSize: 17,
		textIndent: 20,
	},
	grandTotalValue: {
		width: '19%',
		color: '#1976d2',
		fontWeight: 700,
		fontSize: 17,
	},
};
