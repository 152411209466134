import React, { useEffect, useState } from 'react';
import { Stack, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIosNew, FilterAltOutlined } from '@mui/icons-material';
import { useStyles } from './BurseChalice.style';
import BurseChaliceForm, { FormValues } from './BurseChaliceForm';
import BurseChaliceReportView, { BurseChaliceData } from './BurseChaliceReportView';
import useReportAction from 'hooks/useReportAction';
import { StateType } from 'contexts/intialstates/Types';
import moment from 'moment';

const BurseChalice = () => {
	const navigate = useNavigate();
	const classes = useStyles();
	const { getBurseChaliceReport } = useReportAction();

	const [isEdit, setIsEdit] = useState(false);
	const [isShowFilter, setIsShowFilter] = useState(true);
	const [dateRange, setDateRange] = useState({
		start: '',
		end: '',
	});
	const [burseChaliceReportData, setBurseChaliceReportData] = useState<StateType<BurseChaliceData>>({
		data: null,
		loading: false,
		error: null,
	});
	const [formData, setFormData] = useState<FormValues>({
		start: '',
		end: '',
		donors: [],
		donationType: [],
		status: [],
		contributionType: [],
	});

	const handleCancel = () => {
		setBurseChaliceReportData({
			data: null,
			loading: false,
			error: null,
		});
	};

	const handleGetReport = async (formValues: FormValues, isGenerate?: boolean) => {
		const payload = {
			start: formValues.start ? moment(formValues?.start).format('YYYY-MM-DD') : '',
			end: formValues.end ? moment(formValues?.end).format('YYYY-MM-DD') : '',
			minAmount: formValues.minAmount,
			maxAmount: formValues.maxAmount,
			contributionType: formValues.contributionType.join(','),
			donationTypeIds: formValues.donationType.map((item: any) => item.id).join(','),
			statusIds: formValues.status.join(','),
			donorIds: formValues.donors.map((item: any) => item.id).join(','),
		};
		await getBurseChaliceReport({ payload, setBurseChaliceReportData });
		if (isGenerate) {
		}
		setDateRange({
			start: formValues.start,
			end: formValues.end,
		});
	};
	useEffect(() => {
		if (burseChaliceReportData.data) {
			setIsEdit(true);
			setIsShowFilter(false);
		}
	}, [burseChaliceReportData.data]);
	return (
		<div>
			<Stack direction="row" marginBottom={2} alignItems={'center'} justifyContent="space-between">
				<Stack direction="row" alignItems={'center'} spacing={4}>
					<Stack className={classes.backIconWrapper}>
						<ArrowBackIosNew onClick={() => navigate(-1)} />
					</Stack>
					<Stack>
						<span className={classes.title}>Burse/Chalice Report</span>
					</Stack>
				</Stack>
				{isEdit && (
					<IconButton
						sx={{
							background: 'white',
							borderRadius: '6px',
							width: 40,
							height: 30,
							border: '1px solid var(--mainColor)',
						}}
						onClick={() => setIsShowFilter(!isShowFilter)}
					>
						<FilterAltOutlined sx={{ fontSize: 16, color: 'var(--mainColor)' }} />
					</IconButton>
				)}
			</Stack>
			<BurseChaliceForm
				handleGetReport={handleGetReport}
				loading={burseChaliceReportData.loading}
				onCancel={handleCancel}
				isEdit={isEdit}
				isShowFilter={isShowFilter}
				formData={formData}
				setFormData={setFormData}
			/>
			{!burseChaliceReportData.loading && burseChaliceReportData?.data?.burseChaliceList?.length ? (
				<BurseChaliceReportView
					burseChaliceData={burseChaliceReportData?.data}
					start={dateRange.start}
					end={dateRange.end}
					formValues={formData}
				/>
			) : null}
		</div>
	);
};

export default BurseChalice;
