import React, { useState } from 'react';
import { Box, Stack, Typography, Tooltip, IconButton, Button, Skeleton } from '@mui/material';
import { Edit, Delete, Notes } from '@mui/icons-material';
import { makeStyles } from '@material-ui/core';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import CreateEditNotes from './CreateEditNotes';
import { StateType } from 'contexts/intialstates/Types';
import moment from 'moment';
import { GlobalContexts } from 'contexts/GlobalContext';
import ErrorSnack from 'Components/Common/ErrorSnack/ErrorSnack';
import BlockIcon from '@mui/icons-material/Block';
import { DonorNotesData } from 'hooks/useNotesActions';
import useUserActions from 'hooks/useUserActions';

interface P {
	notes: StateType<DonorNotesData>;
	handleSubmitNotes?: (note: string, id?: number) => Promise<boolean>;
	handleDeleteNotes?: (id: number) => Promise<boolean>;
	setIsReload: React.Dispatch<React.SetStateAction<boolean>>;
	isReload: boolean;
}

const useStyles = makeStyles({
	addButton: {
		marginBottom: '10px',
	},
	noteBox: {
		padding: '10px',
		border: '1px solid #499BFB',
		borderRadius: '10px',
		marginBottom: '10px',
		backgroundColor: '#FFFFFF',
		minHeight: '200px',
		display: 'flex',
		'& [class*="actionContent"]': {
			display: 'none',
		},
		'&:hover': {
			backgroundColor: '#f5f5f7',
			'& [class*="actionContent"]': {
				display: 'flex',
			},
		},
	},
	innerNoteBox: {
		flexGrow: 1,
	},
	noteIcon: {
		color: '#1976d2',
	},
	textInfo: {
		color: '#A0A0A0',
	},
	actionContent: {
		display: 'none',
	},
	skeleton: {
		transform: 'none !important',
	},
});

const SkeletonContent = () => {
	const classes = useStyles();
	return (
		<>
			{[...Array.from({ length: 5 })].map((e, index) => (
				<Box key={index} className={classes.noteBox}>
					<Stack direction="row" spacing={2} className={classes.innerNoteBox}>
						<Notes className={classes.noteIcon} />
						<Stack justifyContent={'space-between'} width={'100%'}>
							<Skeleton variant="text" className={classes.skeleton} width={'100%'} height={100} />
							<Stack>
								<Skeleton variant="text" width={200} height={40} />
								<Skeleton variant="text" width={180} height={40} />
							</Stack>
						</Stack>
					</Stack>
				</Box>
			))}
		</>
	);
};

const NotesContent = ({ notes, handleSubmitNotes, handleDeleteNotes, setIsReload, isReload }: P) => {
	const { handleRoles } = useUserActions();
	const classes = useStyles();
	const { setErrorSnack } = React.useContext(GlobalContexts);
	const [visible, setVisible] = useState(false);
	const [isDelete, setIsDelete] = useState(false);
	const [selectedData, setSelectedData] = useState<any>();
	const [saveLoading, setSaveLoading] = useState(false);

	const handleDelete = async () => {
		if (selectedData) {
			setSaveLoading(true);
			const isSucess = await handleDeleteNotes?.(selectedData.id);
			setSaveLoading(false);
			if (isSucess) {
				setIsDelete(false);
				setIsReload(!isReload);
				setErrorSnack(false);
			} else {
				setErrorSnack(true);
			}
		}
	};

	const handleSubmit = async (note: string) => {
		setSaveLoading(true);
		const isSucess = await handleSubmitNotes?.(note, selectedData ? selectedData.id : undefined);
		setSaveLoading(false);
		if (isSucess) {
			setVisible(false);
			setSelectedData(undefined);
			setIsReload(!isReload);
			setErrorSnack(false);
		} else {
			setErrorSnack(true);
		}
	};

	return (
		<Box>
			<Stack alignItems={'flex-end'} className={classes.addButton}>
				{handleRoles('notes') && (
					<Button variant={'contained'} onClick={() => setVisible(true)}>
						Add Note
					</Button>
				)}
			</Stack>
			{notes.loading ? (
				<SkeletonContent />
			) : notes?.data?.body?.length ? (
				notes?.data?.body.map((item: any) => (
					<Box key={item.id} className={classes.noteBox}>
						<Stack direction="row" spacing={2} width={'100%'}>
							<Notes className={classes.noteIcon} />
							<Stack spacing={2} justifyContent={'space-between'} width={'100%'}>
								<Typography variant="subtitle2">{item.note}</Typography>
								<Stack direction="row" justifyContent={'space-between'}>
									<Stack>
										<Typography variant="caption" className={classes.textInfo}>
											{`Created by ${item.createdBy} on ${moment(item.created).format('MMM Do, YYYY')}`}
										</Typography>
										<Typography variant="caption" className={classes.textInfo}>
											{`Updated by ${item.updatedBy} on ${moment(item.updated).format('MMM Do, YYYY')}`}
										</Typography>
									</Stack>
									{handleRoles('notes') && (
										<Stack direction="row" spacing={2} className={classes.actionContent}>
											<Tooltip title="Edit">
												<IconButton
													color="primary"
													onClick={() => {
														setSelectedData(item);
														setVisible(true);
													}}
												>
													<Edit />
												</IconButton>
											</Tooltip>
											<Tooltip title="Delete">
												<IconButton
													color="primary"
													onClick={() => {
														setIsDelete(true);
														setSelectedData(item);
													}}
												>
													<Delete />
												</IconButton>
											</Tooltip>
										</Stack>
									)}
								</Stack>
							</Stack>
						</Stack>
					</Box>
				))
			) : (
				notes?.data?.body && (
					<Box
						className={classes.noteBox}
						sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
					>
						<BlockIcon
							sx={{
								fontSize: '90px',
								color: '#dcdcdc',
							}}
						/>
						<Typography>No notes available</Typography>
					</Box>
				)
			)}
			<BasicModal
				onClose={() => {
					setVisible(false);
					selectedData && setSelectedData(undefined);
				}}
				visible={visible}
				title={selectedData ? 'Edit Note' : 'Create Note'}
			>
				<CreateEditNotes selectedEditData={selectedData} onSubmit={handleSubmit} saveLoading={saveLoading} />
			</BasicModal>
			<ConfirmationModal
				handleClose={() => setIsDelete(false)}
				message={'Are you sure you want to delete note ?'}
				open={isDelete}
				handleConfirmation={handleDelete}
				handleCancel={() => setIsDelete(false)}
				loading={saveLoading}
			/>
			<ErrorSnack />
		</Box>
	);
};

export default NotesContent;
