import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const StatusButton = ({ status }: { status: string }) => {
	return (
		<Stack direction="row" width="100%" justifyContent="center">
			<Box
				sx={{
					py: 0.5,
					px: 2,
					background: '#fff',
					color: 'var(--mainColor)',
					border: '1px solid var(--mainColor)',
					borderRadius: '25px',
					textAlign: 'center',
				}}
			>
				{status}
			</Box>
		</Stack>
	);
};

export default StatusButton;
