import React, { useState, useEffect } from 'react';
import { TablePagination, Paper, Tooltip, Chip } from '@mui/material';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import { pagination } from 'Components/Common/Tables/Tables';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { sortTable } from 'Components/Common/Tables/sortTable';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import { convertDate } from 'helpers/dayAndTime';
import useNonCashDonation, { NonCashDonation } from 'hooks/useNonCashDonation';
import { StateType, OrderType } from 'contexts/intialstates/Types';
import { useNavigate } from 'react-router-dom';
import TableFiltering, { DonorFiltering } from 'Components/Reusable/TableFiltering/TableFiltering';
import useDebounce from 'Components/Reusable/hooks/debounce';

export const nonCashDonationHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'receivedDate',
		label: 'Date Received',
		minWidth: 220,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'donor',
		label: 'Donor',
		minWidth: 220,
		align: 'center',
		filterType: 'search',
	},
	{
		id: 'amount',
		label: 'Estimated Value',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'society',
		label: 'Society',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'an' }, { name: 'sp' }, { name: 'hc' }, { name: 'pf' }],
	},
	{
		id: 'giftType',
		label: 'Gift Type',
		minWidth: 220,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'status',
		label: 'Status',
		minWidth: 220,
		align: 'center',
		filterType: 'list',
		list: [{ name: 'Pending' }, { name: 'Posted' }],
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
];

interface P {
	trimList: ColumnType[];
	setTrimList: React.Dispatch<React.SetStateAction<ColumnType[]>>;
	filterPayload: any[];
	setFilterPayload: React.Dispatch<React.SetStateAction<any[]>>;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	page: number;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	reload: boolean;
}

const NonCashDonationTable = ({
	trimList,
	setTrimList,
	filterPayload,
	setFilterPayload,
	setPage,
	page,
	reload,
	setReload,
}: P) => {
	const navigate = useNavigate();
	const { getNonCashDonations } = useNonCashDonation();
	const [nonCashDonations, setNonCashDonations] = useState<StateType<NonCashDonation>>({
		data: null,
		loading: false,
		error: null,
	});
	const [pageSize, setPageSize] = useState(10);
	const [orderType, setOrderType] = useState<OrderType>('asc');
	const [label, setLabel] = useState<string>('');
	const [orderBy, setOderBy] = useState<string>('');
	const [donorQuery, setDonorQuery] = useState<string>('');
	const debouncedSearchTerm = useDebounce(donorQuery, 1000);

	useEffect(() => {
		getNonCashDonations({
			nonCashDonations,
			setNonCashDonations,
			page,
			pageSize,
			orderBy,
			filterPayload: filterPayload.map((item) => {
				if (item.field === 'status') {
					return {
						...item,
						value: item.value.map((val: string) => {
							if (val === 'Pending') return '1';
							if (val === 'Posted') return '2';
						}),
					};
				}
				return item;
			}),
			donorQuery,
		});
	}, [page, pageSize, orderBy, reload, debouncedSearchTerm]);

	const getTableData = () => {
		return nonCashDonations?.data?.body.length
			? nonCashDonations.data.body.map((row: any, index: number) => {
					return {
						's/n': index + 1,
						id: row.id,
						receivedDate: row.receivedDate ? convertDate(row.receivedDate) : 'N/A',
						donor: row.donor ? `${row.donor.firstName ?? ''} ${row.donor.lastName ?? ''}` : 'N/A',
						amount: row.amount || 'N/A',
						society: row.society?.code || 'N/A',
						giftType: row.giftType?.type || 'N/A',
						status: (
							<Chip label={row.status === 1 ? 'Pending' : 'Posted'} color="primary" variant="outlined" />
						),
						createdBy: row?.createdBy?.name || 'N/A',
						updatedBy: row?.updatedBy?.name || 'N/A',
						created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
						updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
							<span
								style={{
									cursor: 'pointer',
									margin: '10px',
								}}
								onClick={() => {
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									});
								}}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<DonorFiltering
							searchDonor={donorQuery}
							setSearchDonor={setDonorQuery}
							filterType={column.filterType}
							setPage={setPage}
						/>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							reload={reload}
							setIsReload={setReload}
							setFilterPayload={setFilterPayload}
							setPage={setPage}
							page={page}
							filterPayload={filterPayload}
						/>
					</>
				),
			};
		});
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	const handleRowClick = (row: any) => {
		navigate(`/bookkeeping/${row.id}`);
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<CustomTable
				loading={nonCashDonations?.loading}
				columnData={getColumnData()}
				tableData={getTableData()}
				handleDrag={handleDrag}
				handleResize={handleResize}
				emptyMessage={'No non cash donations available'}
				handleRowClick={handleRowClick}
			/>
			{(nonCashDonations?.data?.body?.length as number) > 0 && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
					component="div"
					count={nonCashDonations?.data?.totalRecords as number}
					rowsPerPage={pageSize}
					page={page}
					sx={pagination}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</Paper>
	);
};

export default NonCashDonationTable;
