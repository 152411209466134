import React, { useEffect, useState } from 'react';
import { Stack, IconButton, Grid, Typography, Button, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AddTask, ArrowBackIosNew, Clear, FilterAltOutlined } from '@mui/icons-material';
import useReportAction, { PaymentListReport } from 'hooks/useReportAction';
import { StateType } from 'contexts/intialstates/Types';
import moment from 'moment';
import { MiniCard } from 'Components/Reports/Card';
import BasicDatePicker, { Months } from 'Components/Reusable/DatePicker';
import { LoadingButton } from '@mui/lab';
import { useGlobalStyles } from 'Components/Common/global/global';
import { useStyles } from '../style';
import Report from './Report';

interface FormValues {
	year: number | null;
	month: number | null;
}

const PaymentList = () => {
	const navigate = useNavigate();
	const classes = useStyles();
	const global = useGlobalStyles();

	const { getPaymentListFilter, getPaymentListFilterPDF } = useReportAction();

	const [isEdit, setIsEdit] = useState(false);
	const [isShowFilter, setIsShowFilter] = useState(true);
	const [paymentList, setPaymentList] = useState<StateType<PaymentListReport[]>>({
		data: null,
		loading: false,
		error: null,
	});

	const [formData, setFormData] = useState<FormValues>({
		month: null,
		year: null,
	});
	const [formErrors, setFormErrors] = useState<string[]>([]);

	const handleCancel = () => {
		setFormData({
			year: null,
			month: null,
		});
		setPaymentList({
			data: null,
			loading: false,
			error: null,
		});
	};

	const handleChange = (field: string, value: string) => {
		if (formErrors.includes(field)) {
			setFormErrors(formErrors.filter((item) => item !== field));
		}
		if (field === 'year') {
			setFormData({
				...formData,
				[field]: new Date(value).getFullYear(),
			});

			return;
		}
		setFormData({
			...formData,
			[field]: value,
		});
	};

	const handleGetReport = async () => {
		const errors = [];
		if (!formData.month) {
			errors.push('month');
		}
		if (!formData.year) {
			errors.push('year');
		}
		setFormErrors(errors);
		if (errors.length) return;

		await getPaymentListFilter({
			paymentList,
			setPaymentList,
			month: formData?.month || parseInt(moment().format('MM')),
			year: formData.year || parseInt(moment().format('YYYY')),
		});
	};
	const handleGetReportPDF = async () => {
		await getPaymentListFilterPDF({
			month: formData?.month || parseInt(moment().format('MM')),
			year: formData.year || parseInt(moment().format('YYYY')),
		});
	};

	useEffect(() => {
		if (paymentList.data) {
			setIsEdit(true);
			setIsShowFilter(false);
		}
	}, [paymentList.data]);

	return (
		<div>
			<Stack direction="row" marginBottom={2} alignItems={'center'} justifyContent="space-between">
				<Stack direction="row" alignItems={'center'} spacing={4}>
					<Stack className={classes.backIconWrapper}>
						<ArrowBackIosNew onClick={() => navigate('/reports')} />
					</Stack>
					<Stack>
						<span className={classes.title}>Payment List Report</span>
					</Stack>
				</Stack>
				{isEdit && (
					<IconButton
						sx={{
							background: 'white',
							borderRadius: '6px',
							width: 40,
							height: 30,
							border: '1px solid var(--mainColor)',
						}}
						onClick={() => setIsShowFilter(!isShowFilter)}
					>
						<FilterAltOutlined sx={{ fontSize: 16, color: 'var(--mainColor)' }} />
					</IconButton>
				)}
			</Stack>
			{isShowFilter && (
				<MiniCard title="Filters">
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
						<Grid item xs={12} md={4} sm={6} mb={3}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Year
							</Typography>
							<Stack>
								<BasicDatePicker
									size="small"
									year
									value={formData?.year && `01/01/${formData?.year}`}
									setValue={(e: any) => handleChange('year', e.target.value)}
								/>
								{formErrors.includes('year') && <span className={global.errorText}>Year is required</span>}
							</Stack>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Month
							</Typography>
							<Stack>
								<Select
									size="small"
									fullWidth
									placeholder="Month"
									value={formData?.month}
									onChange={(e: any) => handleChange('month', e.target.value)}
								>
									{Months.map((x: { id: number; name: string }) => (
										<MenuItem key={x.id} value={x.id}>
											{x.name}
										</MenuItem>
									))}
								</Select>
								{formErrors.includes('month') && <span className={global.errorText}>Month is required</span>}
							</Stack>
						</Grid>

						{isEdit && (
							<Grid item xs={12} md={4} sm={6} mb={3}>
								<Stack direction="row" spacing={2} height="100%" alignItems="flex-end" p={1}>
									<LoadingButton
										startIcon={<AddTask />}
										variant="contained"
										onClick={handleGetReport}
										type="submit"
										loading={paymentList.loading}
										loadingPosition="start"
										size="small"
									>
										Apply
									</LoadingButton>

									<Button
										startIcon={<Clear />}
										variant="outlined"
										className={global.whiteBtn}
										onClick={handleCancel}
										size="small"
									>
										Cancel
									</Button>
								</Stack>
							</Grid>
						)}
					</Grid>
				</MiniCard>
			)}
			{!isEdit && (
				<Stack m={3} direction="row">
					<LoadingButton
						startIcon={<AddTask />}
						variant="contained"
						onClick={handleGetReport}
						loading={paymentList.loading}
						loadingPosition="start"
						size="small"
					>
						Generate Report
					</LoadingButton>
				</Stack>
			)}
			{!paymentList.loading && paymentList?.data?.length ? (
				<Report
					handleGetReportPDF={handleGetReportPDF}
					paymentList={paymentList}
					year={formData?.year as any}
					month={formData?.month as any}
				/>
			) : null}
		</div>
	);
};

export default PaymentList;
