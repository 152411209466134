import React, { useState, useEffect } from 'react';
import { Stack, Button, Paper, Typography, Chip, TablePagination, Tooltip } from '@mui/material';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { Add } from '@mui/icons-material';
import { ColumnType, walkathonHead } from 'Components/Common/Tables/TableHeaders';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { useNavigate } from 'react-router-dom';
import useWalkathonActions, { Walkathon } from 'hooks/useWalkathonAction';
import { StateType, OrderType } from 'contexts/intialstates/Types';
import { convertDate } from 'helpers/dayAndTime';
import { pagination } from 'Components/Common/Tables/Tables';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import { sortTable } from 'Components/Common/Tables/sortTable';

const WalkthonList = () => {
	const navigate = useNavigate();
	const { getWalkathon } = useWalkathonActions();
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [walkathon, setWalkathon] = useState<StateType<Walkathon>>({
		data: null,
		loading: false,
		error: null,
	});
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [orderType, setOrderType] = useState<OrderType>('asc');
	const [label, setLabel] = useState<string>('');
	const [orderBy, setOderBy] = useState<string>('');

	useEffect(() => {
		getWalkathon({ walkathon, setWalkathon, page, pageSize, orderBy });
	}, [orderBy, page, pageSize]);

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const getTableData = () => {
		return walkathon?.data?.body.length
			? walkathon.data.body.map((row: any, index: number) => {
					return {
						's/n': page * pageSize + index + 1,
						id: row.id,
						schoolBoard: row.schoolBoard?.name || 'N/A',
						amount: row.amount || 'N/A',
						receivedDate: row.receivedDate ? `${convertDate(row.receivedDate)}` : 'N/A',
						status: <Chip label={row.status} color="primary" variant="outlined" />,
						createdBy: row?.createdBy?.name || 'N/A',
						updatedBy: row?.updatedBy?.name || 'N/A',
						created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
						updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column) => {
			return {
				...column,
				data: (
					<Tooltip title={`Sort by ${column.label}`}>
						<span
							style={{ cursor: 'pointer' }}
							onClick={() =>
								sortTable({
									column: column.id,
									setLabel,
									setOderBy,
									setOrderType,
									orderType,
									label,
								})
							}
						>
							{column.label}
							{column.id == label && <OrderTypeArrow orderType={orderType} />}
						</span>
					</Tooltip>
				),
			};
		});
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	const handleRowClick = (row: any) => {
		navigate(`/hca/walkathon/${row.id}`);
	};

	return (
		<Stack>
			<Stack direction={'row'} justifyContent={'space-between'} mb={3}>
				<Stack direction={'column'}>
					<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
						Walkathon
					</Typography>
					<Stack>Manage and Add Walkathon donation from here.</Stack>
				</Stack>
				<Stack direction={'row'} alignItems={'center'}>
					<TrimTables
						name={'walkathonHead'}
						list={walkathonHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
					<Button
						onClick={() => navigate('/hca/walkathon/create')}
						size={'small'}
						startIcon={<Add />}
						variant="contained"
					>
						Add New
					</Button>
				</Stack>
			</Stack>
			<Stack>
				<Paper sx={{ width: '100%', overflow: 'hidden' }}>
					<CustomTable
						loading={walkathon?.loading}
						columnData={getColumnData()}
						tableData={getTableData()}
						handleDrag={handleDrag}
						handleResize={handleResize}
						emptyMessage={'No walkathon available'}
						handleRowClick={handleRowClick}
					/>
					<TablePagination
						rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
						component="div"
						count={(walkathon?.data?.totalRecords as number) || 0}
						rowsPerPage={pageSize}
						page={page}
						sx={pagination}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Paper>
			</Stack>
		</Stack>
	);
};

export default WalkthonList;
