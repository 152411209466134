import { useContext } from 'react';
import {
	GetSearchDonorsProps,
	GetAllDonorsProps,
	GetDonorProps,
	GetDonorsProps,
	MergeDonorsProps,
	ExportDonorsProps,
} from '../Components/Donors/Types';
import { ResponseError } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import type { DonorFormValues } from 'Components/Donors/CreateEditDonorModal';
import moment from 'moment';
import { GlobalContexts } from 'contexts/GlobalContext';

interface DonorPayload {
	salutation: string;
	lastName: string;
	firstName: string;
	orgName: string;
	careOf: string;
	parishId: number;
	streetline1: string;
	streetline2: string;
	city: string;
	province: string;
	postalCode: string;
	country: string;
	phone: string;
	mobile: string;
	email: string;
	isOrganization: boolean;
	noMail: boolean;
	returnMail: boolean;
	deceased: boolean;
	magazine: boolean;
	faithPropagation: boolean;
	stPeter: boolean;
	holyChildhood: boolean;
	noFollowAppeal: boolean;
	excludeFromParishList: boolean;
	returnedMail: boolean;
	principal: boolean;
	member: boolean;
	pap: boolean;
	annuity: boolean;
	estate: boolean;
	remark: string;
	receiptScheduleId: number;
	principalDonorId: number;
	donorType: string;
}

interface RecordPayload {
	contactName: string;
	dateReported: string;
	certifiedDate: string;
	doNotReversePayments: boolean;
}

const useDonorActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = useContext(GlobalContexts);

	const getAllDonors = async ({
		donors,
		setDonors,
		setErrorSnack,
		page,
		pageSize,
		orderBy,
		filterPayload,
		donorQuery,
		archive,
		donorId,
	}: GetAllDonorsProps) => {
		try {
			setDonors({
				...donors,
				loading: true,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});

			const url = donorId ? `donor/${donorId}/list` : `donor/list`;
			let response;
			if (page) {
				response = await api.get(
					`${url}?PageNumber=${page + 1}&PageSize=${pageSize}${archive ? archive : ''}${
						donorQuery ? '&donorQuery=' + donorQuery : ''
					}${filter}${orderBy || '&SortBy=updated%20desc&Filters=field=archived;value=false'}`,
				);
			} else {
				response = await api.get(
					`${url}?&PageSize=${pageSize}${archive ? archive : ''}${
						donorQuery ? '&donorQuery=' + donorQuery : ''
					}${filter}${orderBy || '&SortBy=updated%20desc&Filters=field=archived;value=false'}`,
				);
			}

			setDonors({
				...donors,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || donors.data?.totalRecords,
				},
				error: null,
			});
			setErrorSnack(false);
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDonors({
					...donors,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			setErrorSnack(true);
		}
	};
	const exportDonorCSV = async ({
		exportDonor,
		setExportDonor,
		setErrorSnack,
		page,
		pageSize,
		orderBy,
		filterPayload,
		donorQuery,
		archive,
	}: ExportDonorsProps) => {
		try {
			setExportDonor({
				...exportDonor,
				loading: true,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`donor/export-csv?PageNumber=${page + 1}&PageSize=${pageSize}${archive ? archive : ''}${
						donorQuery ? '&donorQuery=' + donorQuery : ''
					}${filter}${orderBy || '&SortBy=updated%20desc&Filters=field=archived;value=false'}`,
				);
			} else {
				response = await api.get(
					`donor/export-csv?${archive ? archive : ''}${
						donorQuery ? '&donorQuery=' + donorQuery : ''
					}${filter}&PageSize=${pageSize}${
						orderBy || '&SortBy=updated%20desc&Filters=field=archived;value=false'
					}`,
				);
			}

			const url = window.URL.createObjectURL(new Blob([response?.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Donor_Export_${moment().format('yyMM')}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			setExportDonor({
				...exportDonor,
				loading: false,
			});
			setErrorSnack(false);
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setExportDonor({
					...exportDonor,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			setErrorSnack(true);
		}
	};

	const getDonors = async ({ donors, setDonors, setErrorSnack }: GetDonorsProps) => {
		try {
			setDonors({
				...donors,
				loading: true,
			});
			const getDonor = await api.get(`donor/list?&Filters=field=archived;value=false`);

			setDonors({
				...donors,
				loading: false,
				data: getDonor?.data?.result,
				error: null,
			});
			setErrorSnack?.(false);
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDonors({
					...donors,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			setErrorSnack?.(true);
		}
	};

	const getSearchDonors = async ({
		searchDonors,
		setSearchDonors,
		searchTerm,
		setErrorSnack,
	}: GetSearchDonorsProps) => {
		try {
			setSearchDonors({
				...searchDonors,
				loading: true,
			});
			const getDonor = await api.get(
				`donor/search?donorQuery=${searchTerm}&Filters=field=archived;value=false`,
			);

			setSearchDonors({
				...searchDonors,
				loading: false,
				data: getDonor?.data?.result,
				error: null,
			});
			setErrorSnack?.(false);
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSearchDonors({
					...searchDonors,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			setErrorSnack?.(true);
		}
	};

	const getSingleDonor = async ({ donorId, singleDonor, setSingleDonor, setErrorSnack }: GetDonorProps) => {
		if (donorId) {
			try {
				setSingleDonor({
					...singleDonor,
					loading: true,
				});
				const getDonor = await api.get(`/donor/${donorId}`);
				setSingleDonor({
					...singleDonor,
					loading: false,
					data: getDonor.data?.result,
					error: null,
				});
				setErrorSnack?.(false);
			} catch (err) {
				if (Axios.isAxiosError(err)) {
					setSingleDonor({
						...singleDonor,
						loading: false,
						error: errorCodes(err?.response?.data) as ResponseError,
					});
				}
				setErrorSnack?.(true);
			}
		}
	};

	const createSingleDonor = async (payload: DonorFormValues | DonorPayload): Promise<boolean | number> => {
		try {
			const createDonors = await api.post(`/donor/create`, { ...payload });
			if (createDonors?.data?.result?.id) {
				return createDonors.data.result.id;
			}
			return false;
		} catch (err) {
			return false;
		}
	};

	const updateSingleDonor = async (payload: DonorFormValues, donorId: number): Promise<boolean> => {
		try {
			const updateDonorResponse = await api.put(`/donor/${donorId}/update`, { ...payload });
			if (updateDonorResponse?.data?.statusCode === 0) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	const deleteSingleDonor = async (donorId: number): Promise<boolean> => {
		try {
			const deleteDonors = await api.remove(`/donor/${donorId}/archive`);
			if (deleteDonors?.data?.statusCode === 0) {
				return true;
			}
			return false;
		} catch (err) {
			return false;
		}
	};
	const restoreSingleDonor = async (donorId: number): Promise<boolean> => {
		try {
			const deleteDonors = await api.post(`/donor/${donorId}/unarchive`);
			if (deleteDonors?.data?.statusCode === 0) {
				return true;
			}
			return false;
		} catch (err) {
			return false;
		}
	};

	const mergeDonors = async ({ mergeFromId, mergeIntoId }: MergeDonorsProps): Promise<boolean> => {
		try {
			const response = await api.post(`/donor/merge`, { mergeFromId, mergeIntoId });
			if (response?.data?.statusCode === 0) {
				return true;
			}
			return false;
		} catch (err) {
			return false;
		}
	};

	const recordDateOfDeath = async (payload: RecordPayload, donorId: number): Promise<boolean | number> => {
		try {
			const updateData = await api.post(`/donor/${donorId}/record-date-of-death`, { ...payload });
			if (updateData?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Recorded Date Of Death successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	return {
		getAllDonors,
		getDonors,
		getSingleDonor,
		createSingleDonor,
		deleteSingleDonor,
		updateSingleDonor,
		getSearchDonors,
		mergeDonors,
		exportDonorCSV,
		restoreSingleDonor,
		recordDateOfDeath,
	};
};

export default useDonorActions;
