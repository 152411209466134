import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import React from 'react';

const OrderTypeArrow = (type: any) => {
	const { orderType, IsNotWhite } = type;
	return (
		<>
			{orderType == 'asc' ? (
				<ArrowUpward
					className={!IsNotWhite ? 'order_arrow' : ''}
					sx={{
						fontSize: 20,
						transform: 'translateY(5px)',
						ml: 0.25,
					}}
				/>
			) : (
				<ArrowDownward
					className={!IsNotWhite ? 'order_arrow' : ''}
					sx={{
						fontSize: 20,
						transform: 'translateY(5px)',
						ml: 0.25,
					}}
				/>
			)}
		</>
	);
};

export default OrderTypeArrow;
