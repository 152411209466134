import { ResponseError, StateType } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';

export interface NotesData {
	id: number;
	note: string;
	createdBy: string;
	createdAt: string;
	updatedBy: string;
	updatedAt: string;
}

export interface DonorNotesData {
	pageNumber: number;
	pageSize: number;
	totalPages: number;
	totalRecords: number;
	body: NotesData[];
}

interface getDonorNotesProp {
	id: number;
	notes: StateType<DonorNotesData>;
	setNotes: React.Dispatch<React.SetStateAction<StateType<DonorNotesData>>>;
	type: string;
}

const useNotesActions = () => {
	const { api } = useApi();

	const getNotes = async ({ id, notes, setNotes, type }: getDonorNotesProp) => {
		try {
			setNotes({
				...notes,
				loading: true,
			});
			const url = type === 'donor' ? `note/${id}/list` : `note/${id}/list-${type}`
			const getData = await api.get(`${url}?SortBy=created desc`);

			setNotes({
				...notes,
				loading: false,
				data: {
					...getData?.data?.result,
					body: getData?.data?.result?.body.map((item: any) => {
						return {
							id: item.id,
							note: item.text,
							createdBy: item.createdBy.name,
							createdAt: item.createdBy.created,
							updatedBy: item.updatedBy.name,
							updatedAt: item.updatedBy.updated,
						};
					}),
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setNotes({
					...notes,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const createNotes = async (id: number, note: string, type: string): Promise<boolean> => {
		try {
			const createNotes = await api.post(`note/${id}/${type === 'donor' ? 'create' : `create-${type}-note`}`, Object.assign({
				text: note,
			},
				type === 'donor' ? { donorId: id } : type === 'diocese' ? { dioceseId: id } : type === 'parish' ? { parishId: id } : type === 'School' ? { schoolId: id } : { schoolBoardId: id }));
			if (createNotes?.data?.result) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	const updateNotes = async (noteId: number, note: string): Promise<boolean> => {
		try {
			const updateDonor = await api.put(`note/${noteId}/update`, {
				id: noteId,
				text: note,
			});
			if (updateDonor) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	const deleteNotes = async (noteId: number): Promise<boolean> => {
		try {
			const deleteDonor = await api.remove(`note/${noteId}/archive`);
			if (deleteDonor) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	return {
		getNotes,
		createNotes,
		updateNotes,
		deleteNotes,
	};
};

export default useNotesActions;
