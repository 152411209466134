import React, { useState, useContext, useEffect } from 'react';
import { Stack, Button, Typography } from '@mui/material';
import { TemplateDepositContext } from 'contexts/TemplateDepositContext';
import TemplateDepositTable from './TemplateDepositTable';
import SingleCreditCardTemplateDeposit from './SingleCreditCardTemplateDeposit';
import { FilterPayload, OrderType } from 'contexts/intialstates/Types';
import useTemplateDepositActions from 'hooks/useTemplateDepositActions';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import { useNavigate } from 'react-router-dom';
import { DepositsTableHead, ColumnType } from 'Components/Common/Tables/TableHeaders';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import useUserActions from 'hooks/useUserActions';
import useDebounce from 'Components/Reusable/hooks/debounce';
interface P {
	type: string;
}
const TemplateDeposit = ({ type }: P) => {
	const { handleRoles } = useUserActions();
	const { templateDepositDispatch, templateDepositsState, deleteTemplateDepositDispatch } =
		useContext(TemplateDepositContext);

	const { deleteTemplateDeposit, getAllTemplateDeposits } = useTemplateDepositActions();

	const [page, setPage] = useState<number>(0);
	const [pageSize, setPageSize] = useState<number>(10);
	const [searchDonor, setSearchDonor] = useState<string>('');
	const debouncedSearchTerm = useDebounce(searchDonor, 1000);
	const [isReload, setIsReload] = useState<boolean>(false);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);
	const [reload, setReload] = React.useState(false);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);

	const navigate = useNavigate();

	useEffect(() => {
		if (!templateDepositDispatch || !type) return;
		getAllTemplateDeposits({
			pageNumber: page,
			pageSize,
			donorQuery: searchDonor,
			orderBy,
			filterPayload,
			type,
		})(templateDepositDispatch);
	}, [type, templateDepositDispatch, page, pageSize, debouncedSearchTerm, isReload, orderBy, reload]);

	const handleChangePage = (newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const deleteTemplateDepositById = async (id: number) => {
		if (deleteTemplateDepositDispatch) {
			await deleteTemplateDeposit(id)(deleteTemplateDepositDispatch);
			setIsReload(!isReload);
		}
	};

	return (
		<>
			<Stack
				alignItems="center"
				direction={{ xs: 'column', sm: 'row' }}
				justifyContent={'space-between'}
				mb={3}
			>
				<Stack direction={'column'}>
					<Typography variant="h6" sx={{ color: 'var(--mainColor)' }}>
						{type === 'creditcard' ? 'PAP Credit Card' : 'PAP Bank'}
					</Typography>
					<Stack sx={{ color: 'var(--grayText)', fontSize: 14, wordWrap: 'wrap' }}>
						{type === 'creditcard'
							? 'Create and manage a Recurring PAP Credit Card Donation here to record them in Batch Donations.'
							: 'Create and manage a Recurring PAP Bank Donation here to record them in Batch Donations.'}
					</Stack>
				</Stack>
				<Stack sx={{ mb: 3 }} direction="row" justifyContent="flex-end" alignItems="center">
					<TrimTables
						name="DepositsTableHead"
						list={DepositsTableHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
					{type === 'creditcard'
						? handleRoles('bookkeeping-actions') && <SingleCreditCardTemplateDeposit />
						: handleRoles('bookkeeping-actions') && (
								<Button
									color="primary"
									variant="contained"
									sx={{ textTransform: 'none' }}
									onClick={() => navigate('create-template-deposit?tab=bank')}
								>
									Recurring Donation
								</Button>
						  )}
					<FilterListButton
						page={page}
						setPage={setPage}
						reload={reload}
						setIsReload={setReload}
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
					/>
				</Stack>
			</Stack>

			<TemplateDepositTable
				templateDeposit={templateDepositsState?.data?.body}
				totalRecords={templateDepositsState?.data?.totalRecords ?? 0}
				page={page}
				setPage={setPage}
				pageSize={pageSize}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
				deleteTemplateDepositById={deleteTemplateDepositById}
				loading={templateDepositsState?.loading}
				setLabel={setLabel}
				setOderBy={setOderBy}
				setOrderType={setOrderType}
				label={label}
				orderType={orderType}
				setFilterPayload={setFilterPayload}
				filterPayload={filterPayload}
				setIsReload={setReload}
				reload={reload}
				searchDonor={searchDonor}
				setSearchDonor={setSearchDonor}
				trimList={trimList}
				type={type}
				setTrimList={setTrimList}
				setPageSize={setPageSize}
			/>
		</>
	);
};

export default TemplateDeposit;
