import {
	TableCell,
	TableRow,
	tableCellClasses,
	styled,
	TableHead,
	TablePagination,
	Table,
	TableCellProps,
} from '@mui/material';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	borderCollapse: 'collapse',
	borderSpacing: '50px 40px',
	boxShadow: '0px 4px 100px rgba(215, 215, 215, 0.14)',
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#767676',
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 12,
	},
	'&:first-child': {
		borderTopLeftRadius: '5px',
		borderBottomLeftRadius: '5px',
	},
	'&:last-child': {
		borderTopRightRadius: '5px',
		borderBottomRightRadius: '5px',
	},
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
	border: 'none',
	borderCollapse: 'separate',
	borderSpacing: '50px 50px',
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

export const StyledBatchTableRow = styled(TableRow)(() => ({
	border: 'none',
	borderCollapse: 'separate',
	borderSpacing: '50px 50px',
	backgroundColor: '#e6f0f9',
	// '&:nth-of-type(odd)': {
	// 	backgroundColor: theme.palette.action.hover,
	// },
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

export const MainTable = styled(Table)`
	background: #e5e5e5;
	display: block;
	margin-top: 30px;
	position: relative;
	height: 100%;
	overflow: auto;
`;

export const TableCells = styled(TableCell)`
	width: 300px;
	border: none;
	font-size: 12px;

	span {
		color: #499bfb;
		margin-right: 12px;
	}
`;
export const TableRows = styled(TableRow)`
	background: white;
	display: block !important;
	margin-bottom: 10px;
	border: none;
	border-radius: 7px;
	width: 100%;
	padding: 0 10px;

	&:hover {
		background: white !important;
	}

	&.active::after {
		content: '';
		width: 1%;
		position: absolute;
		top: 0;
		left: 0;
		background: #499bfb;
		height: 100%;
	}
`;
export const Pagination = styled(TablePagination)`
	display: flex;
	justify-content: center;
	background: white;
	width: 100%;
	border-radius: 10px;
	position: relative;
`;
export const TableHeader = styled(TableHead)`
	margin-bottom: 20px;
	display: inline-block;
	background: #264889;
	border-radius: 7px;
	padding: 0 10px;
	z-index: 999;
	width: 100%;
	background: white;
	position: sticky;
	top: 0;
	left: 0;
	tr {
		th:not(:last-child) {
			font-weight: 600;
			border-right: 1px solid #cacaca !important;
		}
		td {
			width: 300px;
			font-weight: 600;
		}
		th {
			width: 300px;
			font-weight: 600;
		}
	}
`;

export const tableHead = {
	fontWeight: '600',
};
export const tableRow = {
	td: {
		padding: '15px 0px',
		borderBottom: '1px solid #DDE5EA',
	},
};
export const pagination: TableCellProps['sx'] = {
	display: 'flex',
	justifyContent: 'center',
	background: 'white',
	width: '100%',
	position: 'relative',
};
