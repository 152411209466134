import React, { useState, useEffect } from 'react';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { Paper, TablePagination, Tooltip } from '@mui/material';
import { pagination } from 'Components/Common/Tables/Tables';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import useAnnuitiesActions, { IPostingLog, PostingLogDetail } from 'hooks/useAnnuitiesActions';
import { StateType, OrderType, FilterPayload } from 'contexts/intialstates/Types';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import { sortTable } from 'Components/Common/Tables/sortTable';
import StatusButton from 'Components/Common/Buttons/StatusButton';

interface P {
	trimList: ColumnType[];
	setTrimList: React.Dispatch<React.SetStateAction<ColumnType[]>>;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	reload: boolean;
	postingLogs: StateType<IPostingLog>;
	setPostingLogs: React.Dispatch<React.SetStateAction<StateType<IPostingLog>>>;
	id: string;
}

const List = ({ trimList, setTrimList, reload, setReload, postingLogs, setPostingLogs, id }: P) => {
	const { getPostinLogs } = useAnnuitiesActions();
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [orderBy, setOrderBy] = useState<string>('');
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);
	const [orderType, setOrderType] = useState<OrderType>('asc');
	const [label, setLabel] = useState<string>('');

	useEffect(() => {
		if (!id) return;
		getPostinLogs({
			postingLogs,
			setPostingLogs,
			orderBy,
			id,
		});
	}, [orderBy, reload]);

	const getTableData = () => {
		let postinLogData: PostingLogDetail[] | undefined;
		if (pageSize > 0) {
			postinLogData = postingLogs?.data?.details?.slice(page * pageSize, page * pageSize + pageSize);
		} else {
			postinLogData = postingLogs.data?.details;
		}

		return postinLogData?.length
			? postinLogData?.map((row, index: number) => {
					return {
						's/n': page * pageSize + index + 1,
						agreementNumber: row.agreementNumber || 'N/A',
						agreementType: row.agreementType || 'N/A',
						status: row?.status ? <StatusButton status={`${row?.status}`} /> : 'N/A',
						annuitant: row.annuitant || 'N/A',
						residualBeginning: row.residualBeginning || 'N/A',
						newGift: row.newGift || 'N/A',
						annuityPayment: row.annuityPayment || 'N/A',
					};
			  })
			: [];
	};
	const getColumnData = () => {
		return trimList.map((column) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
							<span
								style={{
									cursor: 'pointer',
									margin: '10px',
								}}
								onClick={() => {
									sortTable({
										column: column.id,
										setLabel,
										setOderBy: setOrderBy,
										setOrderType,
										orderType,
										label,
									});
								}}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							reload={reload}
							setIsReload={setReload}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
						/>
					</>
				),
			};
		});
	};
	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};
	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};
	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<CustomTable
				loading={postingLogs?.loading}
				columnData={getColumnData()}
				tableData={getTableData()}
				handleDrag={handleDrag}
				handleResize={handleResize}
				emptyMessage={'No data available'}
			/>
			<TablePagination
				rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
				component="div"
				count={postingLogs?.data?.details.length as number}
				rowsPerPage={pageSize}
				page={page}
				sx={pagination}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
};
export default List;
