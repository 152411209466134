import { SET_ACTION } from 'contexts/actionTypes';
import { Action, MessagesPayload } from 'contexts/intialstates/Types';
// import useApi from './useApi';

const usePaymentMethodActions = () => {
    // const { api } = useApi();

    const setActionAction = (value?: boolean) => (dispatch: (arg0: Action<MessagesPayload>) => void) => {
        return dispatch({
            type: SET_ACTION,
            payload: { data: value || false },
        });
    };

	return {
		setActionAction
	};
};

export default usePaymentMethodActions;
