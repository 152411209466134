import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	title: {
		color: 'var(--mainColor)',
		fontSize: '32px',
		fontWeight: 700,
	},
	subTitle: {
		color: 'var(--grayText)',
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '16px',
	},
	backIconWrapper: {
		background: 'var(--white)',
		borderRadius: '4px',
		padding: '5px',
		color: 'var(--mainColor)',
		cursor: 'pointer',
	},
	paperWrap: {
		padding: '20px',
	},
	IconBtn: {
		cursor: 'pointer',
		margin: '0 12px',
		border: '1px solid var(--mainColor)',
		borderRadius: '6px',
		padding: '3px 10px',
		display: 'flex',
		background: 'var(--white)',
		'& svg': {
			color: 'var(--mainColor)',
		},
	},
});

export const tabParentDirection: any = {
	xs: 'column',
	sm: 'row',
};
export const tabChildDirection: any = 'column';
export const tabChildSpacing = {
	mr: 3,
	mt: 3,
};
export const flexWrap: any = {
	flexWrap: 'wrap',
};
export const alignItems: any = { xs: 'flex-start', sm: 'center' };
export const TypoStyle1: any = { fontSize: 14, fontWeight: 600 };
export const TypoStyle2: any = { fontSize: 12, color: '#A0A0A0' };

export const styles = {
	statusBtn: {
		cursor: 'initial',
		width: 100,
		p: '4px 8px',
		borderRadius: '6px',
		fontSize: 12,
		textTransform: 'capitalize',
		background: '#fff',
		'&:hover': { background: '#fff', color: '#1976D2' },
		color: '#1976D2',
	},
};
