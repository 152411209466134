import React from 'react';
import {
	ResponseError,
	HcaOrders,
	HcaOrdersData,
	StateType,
	FilterPayload,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { HcaOrdersValues } from 'Components/HCA/Orders/CreateEditOrders';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface GetHcaOrdersTypes {
	setHcaOrders: React.Dispatch<React.SetStateAction<StateType<HcaOrdersData>>>;
	hcaOrders: StateType<HcaOrdersData>;
	page: number;
	pageSize: number;
	category?: string;
	orderBy?: string;
	filterPayload?: FilterPayload[];
}

export interface GetHcaOrderTypes {
	setHcaOrder: React.Dispatch<React.SetStateAction<StateType<HcaOrders>>>;
	hcaOrder: StateType<HcaOrders>;
	id: number;
}
export interface ConfirmHcaOrderTypes {
	setConfirmHcaOrder: React.Dispatch<React.SetStateAction<StateType<HcaOrders>>>;
	confirmHcaOrder: StateType<HcaOrders>;
	id: number;
}
export interface GetPackingSlipTypes {
	id: number;
}

const useHcaOrdersActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const getHcaOrdersByID = async ({ setHcaOrder, hcaOrder, id }: GetHcaOrderTypes) => {
		try {
			setHcaOrder({
				...hcaOrder,
				loading: true,
				error: null,
			});
			const response = await api.get(`order/${id}`);
			setHcaOrder({
				...hcaOrder,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setHcaOrder({
					...hcaOrder,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const confirmOrderByID = async ({ setConfirmHcaOrder, confirmHcaOrder, id }: ConfirmHcaOrderTypes) => {
		try {
			setConfirmHcaOrder({
				...confirmHcaOrder,
				loading: true,
				error: null,
			});
			const response = await api.post(`order/${id}/confirm`, {});
			setConfirmHcaOrder({
				...confirmHcaOrder,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setConfirmHcaOrder({
					...confirmHcaOrder,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const getHcaOrders = async ({
		setHcaOrders,
		hcaOrders,
		page,
		pageSize,
		category,
		orderBy,
		filterPayload,
	}: GetHcaOrdersTypes) => {
		try {
			setHcaOrders({
				...hcaOrders,
				loading: true,
				error: null,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`/order/list?${category ? category : ''}PageNumber=${page + 1}&PageSize=${pageSize}${filter}${
						orderBy || '&SortBy=receivedDate%20desc'
					}`,
				);
			} else {
				response = await api.get(
					`/order/list?${category ? category : ''}PageSize=${pageSize}${filter}${
						orderBy || '&SortBy=receivedDate%20desc'
					}`,
				);
			}

			setHcaOrders({
				...hcaOrders,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || hcaOrders.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setHcaOrders({
					...hcaOrders,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const createHcaOrder = async (payload: HcaOrdersValues): Promise<HcaOrders | boolean> => {
		try {
			const response = await api.post(`order/create`, {
				...payload,
			});
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Order created succesfully',
					severity: 'success',
					show: true,
				});
				return response?.data?.result;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	const updateHcaOrder = async (payload: HcaOrdersValues, orderID: number): Promise<boolean> => {
		try {
			const response = await api.put(`order/${orderID}/update`, {
				...payload,
				id: orderID,
			});
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Order updated succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deleteHcaOrder = async (orderID: number): Promise<boolean> => {
		try {
			const response = await api.remove(`order/${orderID}/archive`);
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Order deleted successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	return {
		getHcaOrdersByID,
		getHcaOrders,
		createHcaOrder,
		updateHcaOrder,
		deleteHcaOrder,
		confirmOrderByID,
	};
};

export default useHcaOrdersActions;
