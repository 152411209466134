import * as React from 'react';
import { Button, Stack, TablePagination, Paper, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { OrderType, StateType } from 'contexts/intialstates/Types';
import { pagination } from 'Components/Common/Tables/Tables';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import ErrorSnack from 'Components/Common/ErrorSnack/ErrorSnack';
import { convertDate } from 'helpers/dayAndTime';
import { BatchData, DefaultState } from './Batch';
import { GlobalContexts } from 'contexts/GlobalContext';
import { sortTable } from 'Components/Common/Tables/sortTable';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import useActions from '../../hooks/useBatchActions';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';

interface P {
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	reload: boolean;
	filterPayload: any[];
	setFilterPayload: React.Dispatch<React.SetStateAction<any[]>>;
	trimList: ColumnType[];
	setTrimList: React.Dispatch<React.SetStateAction<ColumnType[]>>;
	archive?: string;
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	type: string;
}
const BatchTable = ({
	setFilterPayload,
	filterPayload,
	type,
	reload,
	setReload,
	trimList,
	setTrimList,
	page,
	setPage,
}: P) => {
	const [defaultState, setDefaultState] = React.useState<DefaultState>({ id: null, open: false });
	const { setErrorSnack } = React.useContext(GlobalContexts);
	const navigate = useNavigate();
	const [pageSize, setPageSize] = React.useState(10);
	const [isDeleted, setIsDeleted] = React.useState(false);

	const [allBatch, setAllBatch] = React.useState<StateType<BatchData>>({
		loading: false,
		error: null,
		data: null,
	});

	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const { getAllBatches, deleteBatchById } = useActions();

	React.useEffect(() => {
		if (!type) return;
		getAllBatches({
			setAllBatch,
			allBatch,
			page,
			pageSize,
			orderBy,
			filterPayload,
			type,
		});
	}, [page, pageSize, orderBy, reload, type]);

	const cancelDeleteBatchById = () => {
		setDefaultState({ ...defaultState, id: null, open: false });
	};
	const handleClose = () => setDefaultState({ ...defaultState, open: false });

	const editBatchDeposit = (id: number) => {
		if (id) {
			return navigate(`/bookkeeping/batch/${id}?tab=${type}`, { state: { batchId: id } as unknown });
		}
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleDelete = async () => {
		if (!defaultState?.id) return;
		setIsDeleted(true);
		const isSucess = await deleteBatchById(defaultState.id);
		if (isSucess) {
			setReload(!reload);
			handleClose();
		}
		setIsDeleted(false);
	};

	const getTableData = () => {
		return allBatch?.data?.body?.length
			? allBatch.data.body.map((row, index: number) => {
					return {
						id: row.id,
						's/n': page * pageSize + index + 1,
						batchNumber: row.batchNumber || 'N/A',
						batchStatus: row?.batchStatus || 'N/A',
						deposits: row.depositCount || 'N/A',
						created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
						createdBy: row?.createdBy?.name || 'N/A',
						updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
						updatedBy: row?.updatedBy?.name || 'N/A',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
							<span
								style={{ cursor: 'pointer' }}
								onClick={() =>
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									})
								}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							reload={reload}
							setIsReload={setReload}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							setPage={setPage}
							page={page}
						/>
					</>
				),
			};
		});
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	let output;

	if (allBatch?.error) {
		setErrorSnack(true);
		output = null;
	} else {
		setErrorSnack(false);
		output = (
			<>
				<Paper sx={{ width: '100%', overflow: 'hidden' }}>
					<CustomTable
						loading={allBatch?.loading}
						columnData={getColumnData()}
						tableData={getTableData()}
						handleDrag={handleDrag}
						handleResize={handleResize}
						emptyMessage={'No batches available'}
						handleRowClick={(row) => editBatchDeposit(row.id as number)}
					/>
					{(allBatch?.data?.body?.length as number) > 0 && (
						<TablePagination
							rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
							component="div"
							count={allBatch?.data?.totalRecords as number}
							rowsPerPage={pageSize}
							page={page}
							sx={pagination}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					)}
				</Paper>
			</>
		);
	}

	return (
		<>
			<ConfirmationModal
				handleClose={handleClose}
				message={'Are you sure you want to delete this Batch?'}
				open={defaultState?.open}
				handleConfirmation={handleDelete}
				handleCancel={cancelDeleteBatchById}
				loading={isDeleted}
			/>
			{output}

			<ErrorSnack message={JSON.stringify(allBatch?.error?.Message)} />
		</>
	);
};

export default BatchTable;
