import React from 'react';
import {
	AllDonationProps,
	DonationsByDonorProps,
	DonationsByIdProps,
	DonationsBySchoolBoardProps,
} from '../Components/Donations/Types';
import { ResponseError, StateType, DonationsData, FilterPayload } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';
import { DonationType } from 'Components/DonationTable';

export interface DonationsByTypeProps {
	page: number;
	pageSize: number;
	id: number;
	type: DonationType;
	donations: StateType<DonationsData>;
	setDonations: React.Dispatch<React.SetStateAction<StateType<DonationsData>>>;
	orderBy?: string;
	filterPayload?: FilterPayload[];
}

const useDonorActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const getAllDonation = async ({
		page,
		pageSize,
		donorQuery,
		depositId,
		allDonation,
		setAllDonation,
		setErrorSnack,
		orderBy,
	}: AllDonationProps) => {
		try {
			setAllDonation({
				...allDonation,
				loading: true,
			});
			let response;

			if (allDonation.data) {
				if (depositId) {
					response = await api.get(
						`donation/list?depositId=${depositId}&PageNumber=${page + 1}&PageSize=${pageSize}${
							donorQuery || ''
						}${orderBy || ''}`,
					);
				} else {
					response = await api.get(
						`donation/list?PageNumber=${page + 1}&PageSize=${pageSize}${donorQuery || ''}${orderBy || ''}`,
					);
				}
			} else {
				if (depositId) {
					response = await api.get(`donation/list?depositId=${depositId}`);
				} else {
					response = await api.get(`donation/list`);
				}
			}

			setAllDonation({
				...allDonation,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || allDonation.data?.totalRecords,
				},
				error: null,
			});
			setErrorSnack(false);
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAllDonation({
					...allDonation,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			setErrorSnack(true);
		}
	};

	const getAllDonationByDonor = async ({
		page,
		pageSize,
		donorId,
		allDonationsByDonor,
		setAllDonationsByDonor,
		setErrorSnack,
		orderBy,
		filterPayload,
	}: DonationsByDonorProps) => {
		try {
			setAllDonationsByDonor({
				...allDonationsByDonor,
				loading: true,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`/donation/donor/${donorId}/list?PageNumber=${page + 1}&PageSize=${pageSize}${filter}${
						orderBy || '&SortBy=updated%20desc'
					}`,
				);
			} else {
				response = await api.get(
					`/donation/donor/${donorId}/list?&PageSize=${pageSize}${filter}${
						orderBy || '&SortBy=updated%20desc'
					}`,
				);
			}

			setAllDonationsByDonor({
				...allDonationsByDonor,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || allDonationsByDonor.data?.totalRecords,
				},
				error: null,
			});
			setErrorSnack(false);
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAllDonationsByDonor({
					...allDonationsByDonor,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			setErrorSnack(true);
		}
	};
	const getDonationsBySchoolBoard = async ({
		page,
		pageSize,
		schoolBoardId,
		allDonationsBySchoolBoard,
		setAllDonationsBySchoolBoard,
		orderBy,
		filterPayload,
	}: DonationsBySchoolBoardProps) => {
		try {
			setAllDonationsBySchoolBoard({
				...allDonationsBySchoolBoard,
				loading: true,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;

			if (page) {
				response = await api.get(
					`/donation/schoolBoard/${schoolBoardId}/list?PageNumber=${page + 1}&PageSize=${pageSize}${filter}${
						orderBy || ''
					}`,
				);
			} else {
				response = await api.get(
					`/donation/schoolBoard/${schoolBoardId}/list?&PageSize=${pageSize}${filter}${orderBy || ''}`,
				);
			}

			setAllDonationsBySchoolBoard({
				...allDonationsBySchoolBoard,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || allDonationsBySchoolBoard.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAllDonationsBySchoolBoard({
					...allDonationsBySchoolBoard,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			setSimpleErrorSnack({ severity: 'error', show: true, message: 'An error occurred' });
		}
	};

	const getAllDonationByType = async ({
		page,
		pageSize,
		id,
		type,
		donations,
		setDonations,
		orderBy,
		filterPayload,
	}: DonationsByTypeProps) => {
		try {
			setDonations({
				...donations,
				loading: true,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;

			if (page) {
				response = await api.get(
					`/donation/${type}/${id}/list?PageNumber=${page + 1}&PageSize=${pageSize}${filter}${orderBy || ''}`,
				);
			} else {
				response = await api.get(
					`/donation/${type}/${id}/list?&PageSize=${pageSize}${filter}${orderBy || ''}`,
				);
			}

			setDonations({
				...donations,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || donations.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDonations({
					...donations,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			setSimpleErrorSnack({ severity: 'error', show: true, message: 'An error occurred' });
		}
	};

	const getAllDonationById = async ({
		donationId,
		allDonationsById,
		setAllDonationsById,
		setErrorSnack,
	}: DonationsByIdProps) => {
		try {
			setAllDonationsById({
				...allDonationsById,
				loading: true,
			});
			const getDonationById = await api.get(`/donation/${donationId}`);

			setAllDonationsById({
				...allDonationsById,
				loading: false,
				data: getDonationById?.data?.result,
				error: null,
			});
			setErrorSnack(false);
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAllDonationsById({
					...allDonationsById,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			setErrorSnack(true);
		}
	};

	return {
		getAllDonation,
		getAllDonationByDonor,
		getAllDonationById,
		getDonationsBySchoolBoard,
		getAllDonationByType,
	};
};

export default useDonorActions;
