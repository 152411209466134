import React, { FC, useEffect, useState, Dispatch, SetStateAction } from 'react';
import {
	Typography,
	Button,
	Divider,
	Grid,
	MenuItem,
	Paper,
	Select,
	Stack,
	TextField,
	Skeleton,
	Autocomplete,
	Box,
	IconButton,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGlobalStyles } from 'Components/Common/global/global';
import {
	AccountBalance,
	AccountCircle,
	AccountBalanceWallet,
	Add,
	CalendarToday,
	ConfirmationNumber,
	DateRange,
	Event,
	MonetizationOn,
	PausePresentation,
	Delete,
	Search,
	Domain,
	KeyboardArrowLeft,
} from '@mui/icons-material';
import { DepositFormPayload, DepositFormValues, DonationFormValues } from '../CreateDeposit/Types';
import DonationsModal from '../CreateDeposit/DonationsModal';
import DepositDonationTable from '../DepositDonationTable';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import {
	DioceseData,
	Donor,
	OrderType,
	ParishData,
	SchoolBoardData,
	SchoolData,
	StateType,
	School,
	SchoolBoard,
	Parish,
	Diocese,
} from 'contexts/intialstates/Types';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { GenerateSingleReceipt } from 'Components/Reusable/GenerateReceipt/GenerateReceipt';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import useDonorAction from 'hooks/useDonorActions';
import useDebounce from 'Components/Reusable/hooks/debounce';
import AdvancedSearch, { Field } from 'Components/Reusable/Modals/AdvancedSearch/AdvancedSearchModal';
import {
	serachDonorField,
	searchDioceseField,
	searchParishField,
	serachSchoolBoardField,
	serachSchoolField,
} from 'Components/Reusable/Modals/AdvancedSearch/Mock';
import DonorTable from 'Components/Donors/DonorListTable';
import {
	ColumnType,
	donorHead,
	schoolBoardHead,
	schoolHead,
	parishHead,
	dioceseHead,
} from 'Components/Common/Tables/TableHeaders';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import useDioceseActions from 'hooks/useDioceseActions';
import useSchoolActions from 'hooks/useSchoolActions';
import useSchoolBoardActions from 'hooks/useSchoolBoardActions';
import useParishesActions from 'hooks/useParishesActions';
import SchoolTable from 'Components/AdminPanel/School/SchoolTable';
import SchoolBoardTable from 'Components/AdminPanel/SchoolBoard/SchoolBoardTable';
import DioceseTable from 'Components/AdminPanel/Diocese/DioceseTable';
import ParishesTable from 'Components/AdminPanel/Parishes/ParishesTable';
import useDepositActions from 'hooks/useDepositActions';
import useUserActions from 'hooks/useUserActions';
import { GlobalContexts } from 'contexts/GlobalContext';

export const ContentSkeleton = () => {
	return (
		<Box sx={{ width: '100%' }}>
			<Box display={{ xs: 'block', sm: 'none', md: 'none' }}>
				<Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
					<Stack alignItems="center">
						<Skeleton variant="text" width={'50%'} height={30} />
						<Skeleton variant="text" width={'70%'} />
					</Stack>
					<Stack direction={{ xs: 'column', sm: 'row' }}>
						{[...Array.from({ length: 2 })].map((i, index) => (
							<Stack
								alignItems="center"
								direction="column"
								mr={{ xs: 0, sm: index === 2 ? 0 : 2 }}
								key={index}
							>
								<Skeleton width={'40%'} height={50} />
							</Stack>
						))}
					</Stack>
				</Stack>
				{[...Array.from({ length: 4 })].map((i, index) => (
					<>
						<Skeleton variant="text" width={'30%'} />
						<Divider />
						<Stack direction="column" flexWrap="wrap" justifyContent="center" py={2}>
							{[...Array.from({ length: 1 })].map((i, index) => (
								<Stack mb={2} key={index}>
									<Skeleton variant="text" width={'40%'} height={40} />
									<Skeleton variant="rectangular" width={'100%'} height={50} />
								</Stack>
							))}
						</Stack>
					</>
				))}
			</Box>
			<Box display={{ xs: 'none', sm: 'block', md: 'none' }}>
				<Stack direction="row" justifyContent="space-between" spacing={2}>
					<Stack>
						<Skeleton variant="text" width={70} height={30} />
						<Skeleton variant="text" width={250} />
					</Stack>
					<Stack direction="row">
						{[...Array.from({ length: 2 })].map((i, index) => (
							<Stack mr={index === 2 ? 0 : 2} key={index}>
								<Skeleton width={70} height={50} />
							</Stack>
						))}
					</Stack>
				</Stack>
				{[...Array.from({ length: 4 })].map((i, index) => (
					<>
						<Skeleton variant="text" width={130} />
						<Divider />
						<Stack direction="row" flexWrap="wrap" justifyContent="space-between" py={2}>
							{[...Array.from({ length: 2 })].map((i, index) => (
								<Stack mb={2} key={index}>
									<Skeleton variant="text" width={100} height={40} />
									<Skeleton variant="rectangular" width={220} height={50} />
								</Stack>
							))}
						</Stack>
					</>
				))}
			</Box>
			<Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
				<Stack direction="row" justifyContent="space-between">
					<Stack>
						<Skeleton variant="text" width={130} height={30} />
						<Skeleton variant="text" width={400} />
					</Stack>
					<Stack direction="row">
						{[...Array.from({ length: 3 })].map((i, index) => (
							<Stack mr={index === 2 ? 0 : 2} key={index}>
								<Skeleton width={89} height={60} />
							</Stack>
						))}
					</Stack>
				</Stack>
				{[...Array.from({ length: 4 })].map((i, index) => (
					<>
						<Skeleton variant="text" width={130} />
						<Divider />
						<Stack direction="row" flexWrap="wrap" justifyContent="space-between" py={2}>
							{[...Array.from({ length: 3 })].map((i, index) => (
								<Stack mb={2} key={index}>
									<Skeleton variant="text" width={130} height={40} />
									<Skeleton variant="rectangular" width={250} height={50} />
								</Stack>
							))}
						</Stack>
					</>
				))}
			</Box>
		</Box>
	);
};

interface P {
	depositId?: number;
	batchId?: number;
	donorId?: number;
	depositData?: any;
	loading?: boolean;
	allPaymentMethods?: any;
	handleUpdateDeposit?: (payload: DepositFormPayload) => void;
	handleDelete?: () => void;
	isTemplateDeposit?: boolean;
	handleReload?: () => void;
	type: string;
	reloader: boolean;
	setReloader: Dispatch<SetStateAction<boolean>>;
	acknowledged?: boolean;
	showBatchNumber?: boolean;
}

const Content: FC<P> = ({
	depositId,
	batchId,
	donorId,
	depositData,
	loading,
	allPaymentMethods,
	handleUpdateDeposit,
	handleDelete,
	isTemplateDeposit,
	handleReload,
	type,
	reloader,
	setReloader,
	acknowledged,
	showBatchNumber,
}) => {
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);
	const { handleRoles } = useUserActions();
	const navigate = useNavigate();
	const location = useLocation();
	const isHCADonations = location.pathname.includes('hca-donations');
	const [toggleModal, setToggleModal] = useState<boolean>(false);
	const global = useGlobalStyles();
	const { getDioceses, getDioceseBySearch } = useDioceseActions();
	const { getSchools, getSchoolsSearch } = useSchoolActions();
	const { getSchoolBoards, getSchoolBoardSearch } = useSchoolBoardActions();
	const { getParishes, getParishesBySearch } = useParishesActions();
	const { postDeposit } = useDepositActions();
	const [formErrors, setFormErrors] = useState<string[]>([]);

	const [schools, setSchools] = React.useState<StateType<SchoolData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [schoolBoards, setSchoolBoards] = React.useState<StateType<SchoolBoardData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [dioceses, setDioceses, ,] = React.useState<StateType<DioceseData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [parishes, setParishes] = React.useState<StateType<ParishData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [searchParishes, setSearchParishes] = React.useState<StateType<Parish[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [schoolBoardSearch, setSchoolBoardSearch] = React.useState<StateType<SchoolBoard[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [schoolSearch, setSchoolSearch] = React.useState<StateType<School[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [searchDiocese, setSearchDiocese] = React.useState<StateType<Diocese[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);

	const [saveLoading, setSaveLoading] = useState<boolean>(false);
	const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [schoolSearchTerm, setSchoolSearchTerm] = useState('');
	const [boardSearchTerm, setBoardSearchTerm] = useState('');
	const [parishSearchTerm, setParishSearchTerm] = useState('');
	const [dioceseSearchTerm, setDioceseSearchTerm] = useState('');
	const [triggerRefresh, setTriggerRefresh] = React.useState<boolean>(false);
	const [searchDonors, setSearchDonors] = useState<StateType<Donor[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [singleDonor, setSingleDonor] = useState<StateType<Donor>>({
		data: null,
		loading: false,
		error: null,
	});
	const [formData, setFormData] = useState<DepositFormValues>();
	const [currentDonationId, setCurrentDonationId] = useState<number>();
	const { id } = useParams();
	const { getSearchDonors, getSingleDonor } = useDonorAction();
	const [donorName, setDonorName] = React.useState('');
	const [schoolName, setSchoolName] = React.useState<string | undefined>('');
	const [boardName, setBoardName] = React.useState<string | undefined>('');
	const [parishName, setParishName] = React.useState<string | undefined>('');
	const [dioceseName, setDioceseName] = React.useState<string | undefined>('');
	const debouncedSearchTerm = useDebounce(searchTerm, 1000);
	const schoolDebouncedSearchTerm = useDebounce(schoolSearchTerm, 1000);
	const boardDebouncedSearchTerm = useDebounce(boardSearchTerm, 1000);
	const parishDebouncedSearchTerm = useDebounce(parishSearchTerm, 1000);
	const dioceseDebouncedSearchTerm = useDebounce(dioceseSearchTerm, 1000);
	const [postLoading, setPostLoading] = useState(false);

	const [visibleSearch, setVisibleSearch] = useState(false);
	const [visibleSearchParish, setVisibleSearchParish] = useState(false);
	const [visibleSearchBoard, setVisibleSearchBoard] = useState(false);
	const [visibleSearchSchool, setVisibleSearchSchool] = useState(false);
	const [visibleSearchDiocese, setVisibleSearchDiocese] = useState(false);
	const [searchFields, setSearchFields] = useState<Field[]>(serachDonorField);
	const [searchFieldsDiocese, setSearchFieldsDiocese] = useState<Field[]>(searchDioceseField);
	const [searchFieldsParish, setSearchFieldsParish] = useState<Field[]>(searchParishField);
	const [searchFieldsBoard, setSearchFieldsBoard] = useState<Field[]>(serachSchoolBoardField);
	const [searchFieldsSchool, setSearchFieldsSchool] = useState<Field[]>(serachSchoolField);
	const [reload, setReload] = useState(false);
	const [filterPayload, setFilterPayload] = useState<Array<any>>([]);
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [open, setOpen] = React.useState<any>({ state: false, type: '' });
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [contributionSum, setContributionSum] = React.useState<number | undefined>();

	useEffect(() => {
		if (!donorId) return;
		getSingleDonor({ donorId, singleDonor, setSingleDonor });
	}, [donorId]);

	useEffect(() => {
		if (singleDonor?.data) {
			if (singleDonor?.data.isOrganization && singleDonor?.data.orgName) {
				setDonorName(singleDonor?.data.orgName);
				return;
			}
			setDonorName(`${singleDonor?.data.firstName ?? ''} ${singleDonor?.data.lastName ?? ''}`);
		}
	}, [singleDonor?.data]);

	useEffect(() => {
		if (!debouncedSearchTerm) return;
		getSearchDonors({ searchDonors, setSearchDonors, searchTerm });
	}, [debouncedSearchTerm]);
	useEffect(() => {
		if (!parishDebouncedSearchTerm) return;
		getParishesBySearch({ searchParishes, setSearchParishes, searchTerm: parishSearchTerm });
	}, [parishDebouncedSearchTerm]);
	useEffect(() => {
		if (!schoolDebouncedSearchTerm) return;
		getSchoolsSearch({ schoolSearch, setSchoolSearch, searchTerm: schoolSearchTerm });
	}, [schoolDebouncedSearchTerm]);
	useEffect(() => {
		if (!boardDebouncedSearchTerm) return;
		getSchoolBoardSearch({ schoolBoardSearch, setSchoolBoardSearch, searchTerm: boardSearchTerm });
	}, [boardDebouncedSearchTerm]);
	useEffect(() => {
		if (!dioceseDebouncedSearchTerm) return;
		getDioceseBySearch({ searchDiocese, setSearchDiocese, searchTerm: dioceseSearchTerm });
	}, [dioceseDebouncedSearchTerm]);
	useEffect(() => {
		setFormData({
			donorId,
			batchId,
			donations: [] as Array<DonationFormValues>,
		} as DepositFormValues);
	}, [depositId, batchId, donorId]);

	const [isDelete, setIsDelete] = useState(false);

	useEffect(() => {
		setSchoolName('');
		setParishName('');
		setDioceseName('');
		setBoardName('');
		if (depositData && depositId) {
			setFormData({
				accountNumber: depositData?.accountNumber,
				depositAccount: depositData?.depositAccount,
				creditAmount: depositData?.creditAmount,
				batchNumber: depositData?.batch?.batchNumber,
				postAmount: depositData?.postAmount,
				chequeNumber: depositData?.chequeNumber,
				receivedDate: depositData?.receivedDate,
				postDate: depositData?.postDate,
				creditDate: depositData?.creditDate,
				sequenceNumber: depositData?.sequenceNumber,
				itemStatus: depositData?.itemStatus?.status,
				paymentMethodId: depositData?.paymentMethodId,
				schoolId: depositData?.schoolId,
				schoolBoardId: depositData?.schoolBoardId,
				parishId: depositData?.parishId,
				dioceseId: depositData?.dioceseId,
				batchId: depositData?.batchId,
				donorId: depositData?.donorId?.toString(),
				donorBankNumber: depositData?.donorBankNumber,
				donorBranchNumber: depositData?.donorBranchNumber,
				donations: depositData.donations?.map((x: any) => ({
					id: x.id,
					depositId: x.depositId,
					receiptNum: x.receiptNum,
					contribution: x.contribution,
					issueDate: x.issueDate,
					remark: x.remark,
					donorId: x.donorId,
					appealId: x.appealId,
					societyId: x.societyId,
					voidTypeId: x.voidTypeId,
					donationType: {
						id: x.donationType?.id,
						label: `${x.donationType?.type} - ${x.donationType?.description}`,
					},
				})),
			});
			if (depositData?.schoolBoard) {
				setBoardName(depositData.schoolBoard.name);
			}
			if (depositData?.diocese) {
				setDioceseName(depositData.diocese.dioceseName);
			}
			if (depositData?.parish) {
				setParishName(depositData.parish.parishName);
			}
			if (depositData?.school) {
				setSchoolName(depositData.school.name);
			}
			if (depositData?.donor) {
				if (depositData?.donor?.isOrganization && depositData?.donor?.orgName) {
					setDonorName(depositData?.donor?.orgName);
					return;
				}
				setDonorName(`${depositData.donor?.firstName ?? ''}  ${depositData.donor?.lastName ?? ''}`);
			}
		}
	}, [depositData, depositId]);

	useEffect(() => {
		setContributionSum(
			formData?.donations.reduce(
				(partial_sum, x) => partial_sum + parseFloat(x.contribution?.toString() || '0'),
				0,
			),
		);
	}, [formData?.donations.length, formData?.donations]);

	const validationFunc = (): boolean => {
		if (!formData) return false;

		const errors = [];

		if (!formData.creditAmount) {
			errors.push('creditAmount');
		}
		if (isTemplateDeposit && type === 'bank' && !formData.donorId) {
			errors.push('donorId');
		}

		if (!formData.postDate) {
			errors.push('postDate');
		}
		if (!formData.creditDate) {
			errors.push('creditDate');
		}
		if (!formData.receivedDate) {
			errors.push('receivedDate');
		}

		if (!formData.paymentMethodId && type !== 'bank') {
			errors.push('paymentMethodId');
		}
		if (!formData.donorBankNumber && type === 'bank') {
			errors.push('donorBankNumber');
		}
		if (!formData.donorBranchNumber && type === 'bank') {
			errors.push('donorBranchNumber');
		}
		if (!formData.accountNumber && type === 'bank') {
			errors.push('accountNumber');
		}

		setFormErrors(errors);
		if (errors.length) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'Please fill all the required fields.',
			});
			return false;
		}

		if (contributionSum !== parseFloat(formData.creditAmount.toString())) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'Total split donation contribution amounts should be equal to the donation deposit amount.',
			});
			return false;
		}

		if (!formData.donations || formData.donations.length < 1) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'Donation Deposit must be assigned to one society.',
			});
			return false;
		}

		if (!formData.donations.every((x) => x.contribution && parseFloat(x.contribution.toString()) > 0)) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'All Split donation contibution amount are required.',
			});
			return false;
		}

		if (!formData.donations.every((x) => x.societyId)) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'All Split donations must be assigned to a society.',
			});
			return false;
		}

		if (!formData.donations.every((x) => x.donationType?.id)) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'All Split donations must have a donation type.',
			});
			return false;
		}
		return true;
	};

	const updateDepositForm = async () => {
		if (!formData) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'Please fill out the form',
			});
			return;
		}
		if (!validationFunc()) return;

		const payload: DepositFormPayload = {
			...formData,
			id: depositId ? depositId : undefined,
			postDate: new Date(formData.postDate as string | number),
			creditDate: new Date(formData.creditDate as string | number),
			receivedDate: new Date(formData.receivedDate as string | number),
			creditAmount: parseFloat(formData.creditAmount?.toString()),
			batchNumber: parseFloat(formData?.batchNumber?.toString() as string),

			donorId: formData.donorId ? parseInt(formData.donorId?.toString()) : undefined,

			paymentMethodId:
				type === 'bank'
					? allPaymentMethods.find((item: any) => item.code === 'DD')?.id
					: formData.paymentMethodId,
			donations: formData.donations.map((x) => ({
				...x,
				id: !x.id || x.id < 0 ? undefined : x.id,
				contribution: parseFloat(x.contribution?.toString() || '0'),
				depositId: depositId,
				donorId: formData.donorId ? parseInt(formData.donorId?.toString()) : undefined,
				paymentMethodId:
					type === 'bank'
						? allPaymentMethods.find((item: any) => item.code === 'DD')?.id
						: formData.paymentMethodId,
				donationTypeId: x.donationType?.id,
			})),
		};
		setSaveLoading(true);
		await handleUpdateDeposit?.(payload);
		setSaveLoading(false);
	};

	const handlePostDeposit = async () => {
		if (!id) return;
		if (!formData) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'Please fill out the form',
			});
			return;
		}
		if (!validationFunc()) return;

		setPostLoading(true);
		await postDeposit(parseInt(depositData?.id));
		setPostLoading(false);
		setReloader(!reloader);
	};

	const updateFormValue = (key: string, donorId?: any) => (e: any) => {
		if (!formData) {
			return;
		}
		if (formErrors.includes(key)) {
			setFormErrors(formErrors.filter((item) => item !== key));
		}
		let temp;
		if (key === 'donorId') {
			temp = { ...formData, donorId };
			setFormData(temp);
			return;
		}
		if (key === 'schoolId') {
			temp = { ...formData, schoolId: donorId };
			setFormData(temp);
			return;
		}
		if (key === 'schoolBoardId') {
			temp = { ...formData, schoolBoardId: donorId };
			setFormData(temp);
			return;
		}
		if (key === 'dioceseId') {
			temp = { ...formData, dioceseId: donorId };
			setFormData(temp);
			return;
		}
		if (key === 'parishId') {
			temp = { ...formData, parishId: donorId };
			setFormData(temp);
			return;
		}
		temp = { ...formData, [key]: e.target.value };
		setFormData(temp);
	};
	const saveDonationToDeposit = (donation: DonationFormValues) => {
		if (!formData) {
			return;
		}

		const temp = [...formData?.donations];
		const index = temp.findIndex((x) => x.id === donation.id);
		if (index > -1) {
			temp[index] = donation;
		} else {
			temp.push({ ...donation, id: -1 * Math.random() });
		}
		setFormData({ ...formData, donations: temp });
		setCurrentDonationId(undefined);
		setToggleModal(false);
	};

	const handleOpenDelete = () => {
		setIsDelete(true);
	};

	const onDelete = async () => {
		setDeleteLoading(true);
		await handleDelete?.();
		setDeleteLoading(false);
		setIsDelete(false);
	};

	const handleVisibleSearch = () => {
		setVisibleSearch(!visibleSearch);
	};

	const handleVisibleSearchSchool = () => {
		setVisibleSearchSchool(!visibleSearchSchool);
	};

	const handleVisibleSearchBoard = () => {
		setVisibleSearchBoard(!visibleSearchBoard);
	};

	const handleVisibleSearchDiocese = () => {
		setVisibleSearchDiocese(!visibleSearchDiocese);
	};

	const handleVisibleSearchParish = () => {
		setVisibleSearchParish(!visibleSearchParish);
	};

	const handleSearch = () => {
		const payload: any = searchFields.map(({ key, value }) => {
			if (value) {
				return {
					field: key,
					query: key !== 'phone' ? value : null,
					value: key === 'phone' ? [value] : null,
					from: null,
					to: null,
				};
			}
		});
		setFilterPayload(payload.filter(Boolean));
	};
	const handleSearchBoard = () => {
		const payload: any = searchFieldsBoard.map(({ key, value }) => {
			if (value) {
				return {
					field: key,
					query: key !== 'phone' ? value : null,
					value: key === 'phone' ? [value] : null,
					from: null,
					to: null,
				};
			}
		});
		setFilterPayload(payload.filter(Boolean));
	};
	const handleSearchParish = () => {
		const payload: any = searchFieldsParish.map(({ key, value }) => {
			if (value) {
				return {
					field: key,
					query: key !== 'phone' ? value : null,
					value: key === 'phone' ? [value] : null,
					from: null,
					to: null,
				};
			}
		});
		setFilterPayload(payload.filter(Boolean));
	};
	const handleSearchSchool = () => {
		const payload: any = searchFieldsSchool.map(({ key, value }) => {
			if (value) {
				return {
					field: key,
					query: key !== 'phone' ? value : null,
					value: key === 'phone' ? [value] : null,
					from: null,
					to: null,
				};
			}
		});
		setFilterPayload(payload.filter(Boolean));
	};
	const handleSearchDiocese = () => {
		const payload: any = searchFieldsDiocese.map(({ key, value }) => {
			if (value) {
				return {
					field: key,
					query: key !== 'phone' ? value : null,
					value: key === 'phone' ? [value] : null,
					from: null,
					to: null,
				};
			}
		});

		setFilterPayload(payload.filter(Boolean));
	};

	const handleSelectRow = (row: any) => {
		const temp = { ...formData, donorId: parseInt(row.id) };
		setFormData(temp as any);
		setDonorName(row.firstname);
		handleVisibleSearch();
	};
	const handleSelectRowParish = (row: any) => {
		const temp = { ...formData, parishId: parseInt(row.id) };
		setFormData(temp as any);
		setParishName(row.parishName);
		handleVisibleSearchParish();
	};
	const handleSelectRowBoard = (row: any) => {
		const temp = { ...formData, schoolBoardId: parseInt(row.id) };
		setFormData(temp as any);
		setBoardName(row.name);
		handleVisibleSearchBoard();
	};
	const handleSelectRowSchool = (row: any) => {
		const temp = { ...formData, schoolId: parseInt(row.id) };
		setFormData(temp as any);
		setSchoolName(row.name);
		handleVisibleSearchSchool();
	};

	const handleSelectRowDiocese = (row: any) => {
		const temp = { ...formData, dioceseId: parseInt(row.id) };
		setFormData(temp as any);
		setDioceseName(row.dioceseName);
		handleVisibleSearchDiocese();
	};

	useEffect(() => {
		getSchools({
			archive: '&Filters=field=archived;value=false',
			schools,
			setSchools,
			page,
			pageSize,
			orderBy,
			filterPayload,
		});
		getSchoolBoards({
			archive: '&Filters=field=archived;value=false',
			schoolBoards,
			setSchoolBoards,
			page,
			pageSize,
			orderBy,
			filterPayload,
		});
		getDioceses({
			archive: '&Filters=field=archived;value=false',
			setDioceses,
			dioceses,
			pageSize,
			page,
			orderBy,
			filterPayload,
		});
		getParishes({
			archive: '&Filters=field=archived;value=false',
			parishes,
			setParishes,
			page,
			pageSize,
			orderBy,
			filterPayload,
		});
	}, [page, pageSize, orderBy, reload, filterPayload.length]);

	const isDisabled = (isHCA?: string) => {
		if (isHCADonations && isHCA) return false;
		return depositData?.itemStatus?.status === 'Posted';
	};

	const setPaymentMethod = () => {
		if (type === 'bank') return allPaymentMethods.find((item: any) => item.code === 'DD')?.id;
		if (type === 'creditcard') return allPaymentMethods.find((item: any) => item.code === 'CC')?.id;
		return formData?.paymentMethodId;
	};

	return (
		<>
			<Stack direction="column" justifyContent="center" alignItems="center">
				<ConfirmationModal
					handleCancel={() => setIsDelete(false)}
					handleConfirmation={onDelete}
					open={isDelete}
					handleClose={() => setIsDelete(false)}
					message={'Do you want to delete this donation?'}
					loading={deleteLoading}
				/>
				{loading ? (
					<ContentSkeleton />
				) : (
					<>
						<Stack sx={{ width: '100%' }} py={3}>
							<Stack direction="column">
								<Stack
									direction={{ xs: 'row', sm: 'column' }}
									alignItems={{ xs: 'center', sm: 'initial' }}
									justifyContent={{ xs: 'center', sm: 'initial' }}
									spacing={{ xs: 3, sm: 0 }}
									mb={5}
								>
									<Stack
										alignItems={'center'}
										justifyContent={'center'}
										sx={{ display: { xs: 'flex', sm: 'none' }, background: '#fff' }}
									>
										<IconButton id="demo-customized-button" aria-haspopup="true" onClick={() => navigate(-1)}>
											<KeyboardArrowLeft color="primary" />
										</IconButton>
									</Stack>
									<Stack
										alignItems="center"
										direction={{ xs: 'column', sm: 'row' }}
										justifyContent={'space-between'}
										spacing={1}
									>
										<Stack
											alignItems={'center'}
											direction="row"
											justifyContent={'flex-start'}
											spacing={{ xs: 0, sm: 4 }}
											mb={{ xs: 1, sm: 0 }}
										>
											<Stack
												alignItems={'center'}
												justifyContent={'center'}
												sx={{ display: { xs: 'none', sm: 'flex' }, background: '#fff' }}
											>
												<IconButton
													id="demo-customized-button"
													aria-haspopup="true"
													onClick={() => navigate(-1)}
												>
													<KeyboardArrowLeft color="primary" />
												</IconButton>
											</Stack>
											<Stack direction="column">
												<Box
													sx={{
														fontSize: '32px',
														fontWeight: 700,
														color: 'var(--mainColor)',
														wordWrap: 'normal',
													}}
												>
													Donation
												</Box>
												{id && (
													<Button
														sx={{
															cursor: 'initial',
															width: 110,
															p: '4px 8px',
															borderRadius: '6px',
															fontSize: 12,
															textTransform: 'capitalize',
															background: '#fff',
															'&:hover': { background: '#fff', color: '#1976D2' },
															color: '#1976D2',
														}}
														disableElevation
														disableRipple
														variant="outlined"
													>
														{formData?.itemStatus || 'N/A'}
													</Button>
												)}
												{id && isHCADonations && (
													<Button
														sx={{
															cursor: 'initial',
															mt: 1,
															width: 110,
															p: '4px 8px',
															borderRadius: '6px',
															fontSize: 12,
															textTransform: 'capitalize',
															background: '#fff',
															'&:hover': { background: '#fff', color: '#1976D2' },
															color: '#1976D2',
														}}
														disableElevation
														disableRipple
														variant="outlined"
													>
														{acknowledged ? 'Acknowledged' : 'Unacknowledged'}
													</Button>
												)}
											</Stack>
										</Stack>
										<Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', justifyContent: 'flex-end' }}>
											{id || isTemplateDeposit
												? handleRoles('bookkeeping-actions') && (
														<Button
															sx={{
																textTransform: 'none',
																background: `${
																	depositData?.itemStatus?.status === 'Posted' ||
																	depositData?.itemStatus?.status === 'Locked'
																		? 'initial'
																		: '#fff'
																}`,
															}}
															startIcon={<Delete />}
															variant={
																depositData?.itemStatus?.status === 'Posted' ||
																depositData?.itemStatus?.status === 'Locked'
																	? 'contained'
																	: 'outlined'
															}
															onClick={handleOpenDelete}
															disabled={
																depositData?.itemStatus?.status === 'Posted' ||
																depositData?.itemStatus?.status === 'Locked'
															}
														>
															{isTemplateDeposit ? 'Cancel' : 'Delete'}
														</Button>
												  )
												: ''}

											{id && !isTemplateDeposit && handleRoles('bookkeeping-list') && (
												<GenerateSingleReceipt
													deposit={depositData}
													handleReload={handleReload}
													onEditDeposit
													isHCA={isHCADonations}
													id={id as string}
												/>
											)}
											{handleRoles('bookkeeping-actions') && (
												<Button
													onClick={() => {
														setToggleModal(true);
													}}
													sx={{
														textTransform: 'none',
														background: `${
															depositData?.itemStatus?.status === 'Posted' ||
															depositData?.itemStatus?.status === 'Locked'
																? 'initial'
																: '#fff'
														}`,
													}}
													startIcon={<Add />}
													variant={
														depositData?.itemStatus?.status === 'Posted' ||
														depositData?.itemStatus?.status === 'Locked'
															? 'contained'
															: 'outlined'
													}
													disabled={
														depositData?.itemStatus?.status === 'Posted' ||
														depositData?.itemStatus?.status === 'Locked' ||
														contributionSum == formData?.creditAmount
													}
												>
													Split Donation
												</Button>
											)}
											{id && !isTemplateDeposit && handleRoles('bookkeeping-actions') && (
												<LoadingButton
													sx={{ textTransform: 'none' }}
													startIcon={<Add />}
													variant="contained"
													onClick={handlePostDeposit}
													type="submit"
													loading={postLoading}
													loadingPosition="start"
													disabled={depositData?.itemStatus?.status !== 'Completed'}
												>
													Post
												</LoadingButton>
											)}
											{handleRoles('bookkeeping-actions') && (
												<LoadingButton
													sx={{
														textTransform: 'none',
													}}
													startIcon={<Add />}
													variant={
														depositData?.itemStatus?.status === 'Posted' ||
														depositData?.itemStatus?.status === 'Locked'
															? 'contained'
															: 'outlined'
													}
													onClick={updateDepositForm}
													type="submit"
													loading={saveLoading}
													loadingPosition="start"
												>
													Save
												</LoadingButton>
											)}
										</Stack>
									</Stack>
								</Stack>
								<Paper sx={{ width: '100%', px: 6, py: 4 }}>
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Grid container direction={'column'}>
												<Stack direction="row">
													<Grid item>
														<AccountCircle className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography variant="body2" className={global.formLabelText}>
															Donor Name
														</Typography>
													</Grid>
												</Stack>
											</Grid>
											<Autocomplete
												freeSolo
												value={donorName ?? ''}
												loadingText="loading..."
												loading={searchDonors.loading}
												size="small"
												fullWidth
												id="free-solo-2-demo"
												onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
													updateFormValue('donorId', val?.id)(event);
													setDonorName(val?.label);
												}}
												options={(searchDonors?.data || []).map((donor: Donor) => ({
													id: donor.id,
													label: `${donor.firstName ?? ''} ${donor.lastName ?? ''}`,
												}))}
												disabled={isDisabled()}
												renderInput={(params) => (
													<Stack alignItems="center" direction="row" spacing={1}>
														<TextField
															{...params}
															variant="outlined"
															label=""
															InputProps={{
																...params.InputProps,
															}}
															onChange={(event: any) => {
																setSearchTerm(event.target.value);
															}}
															className={isDisabled() ? global.disabledInput : ''}
														/>
														<IconButton
															size="small"
															onClick={() => handleVisibleSearch()}
															sx={{ borderRadius: '5px', border: '1px solid var(--mainColor)' }}
															disabled={isDisabled()}
														>
															<Search color="primary" />
														</IconButton>
														{handleRoles('operations') && (
															<IconButton
																size="small"
																onClick={() => window.open('/donors/create')}
																sx={{ borderRadius: '5px', border: '1px solid var(--mainColor)' }}
																disabled={isDisabled()}
															>
																<Add color="primary" />
															</IconButton>
														)}
													</Stack>
												)}
											/>
											{formErrors.includes('donorId') && (
												<span className={global.errorText}>Donor is required</span>
											)}
										</Grid>
										{!isTemplateDeposit && (
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Grid container>
													<Grid item>
														<Domain className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography variant="body2" className={global.formLabelText}>
															Diocese
														</Typography>
													</Grid>
												</Grid>
												<Stack alignItems="center" direction="row" spacing={1}>
													<Autocomplete
														freeSolo
														value={dioceseName ?? ''}
														size="small"
														loading={searchDiocese.loading}
														loadingText="loading..."
														fullWidth
														onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
															updateFormValue('dioceseId', val?.id)(event);
															setDioceseName(val?.label);
														}}
														options={(searchDiocese?.data || []).map((d: Diocese) => ({
															id: d.id,
															label: `${d.dioceseName}`,
														}))}
														renderInput={(params) => (
															<Stack alignItems="center" direction="row" spacing={1}>
																<TextField
																	{...params}
																	variant="outlined"
																	label=""
																	InputProps={{
																		...params.InputProps,
																	}}
																	onChange={(event: any) => {
																		setDioceseSearchTerm(event.target.value);
																	}}
																/>

																<IconButton
																	size="small"
																	onClick={() => handleVisibleSearchDiocese()}
																	sx={{ borderRadius: '5px', border: '1px solid var(--mainColor)' }}
																>
																	<Search color="primary" />
																</IconButton>
															</Stack>
														)}
													/>
												</Stack>
												{formErrors.includes('dioceseId') && (
													<span className={global.errorText}>Diocese is required</span>
												)}
											</Grid>
										)}
										{!isTemplateDeposit && (
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Grid container>
													<Grid item>
														<Domain className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography variant="body2" className={global.formLabelText}>
															Parish
														</Typography>
													</Grid>
												</Grid>
												<Stack alignItems="center" direction="row" spacing={1}>
													<Autocomplete
														freeSolo
														value={parishName ?? ''}
														fullWidth
														loading={searchParishes.loading}
														loadingText="loading..."
														size="small"
														onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
															updateFormValue('parishId', val?.id)(event);
															setParishName(val?.label);
														}}
														options={(searchParishes?.data || []).map((p: Parish) => ({
															id: p.id,
															label: `${p.parishName}`,
														}))}
														renderInput={(params) => (
															<Stack alignItems="center" direction="row" spacing={1}>
																<TextField
																	{...params}
																	variant="outlined"
																	label=""
																	InputProps={{
																		...params.InputProps,
																	}}
																	onChange={(event: any) => {
																		setParishSearchTerm(event.target.value);
																	}}
																/>

																<IconButton
																	size="small"
																	onClick={() => handleVisibleSearchParish()}
																	sx={{ borderRadius: '5px', border: '1px solid var(--mainColor)' }}
																>
																	<Search color="primary" />
																</IconButton>
															</Stack>
														)}
													/>
												</Stack>
												{formErrors.includes('parishId') && (
													<span className={global.errorText}>Parish is required</span>
												)}
											</Grid>
										)}
										{!isTemplateDeposit && (
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Grid container>
													<Grid item>
														<Domain className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography variant="body2" className={global.formLabelText}>
															School Board
														</Typography>
													</Grid>
												</Grid>
												<Stack alignItems="center" direction="row" spacing={1}>
													<Autocomplete
														freeSolo
														value={boardName ?? ''}
														fullWidth
														size="small"
														loading={schoolBoardSearch.loading}
														loadingText="loading..."
														onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
															updateFormValue('schoolBoardId', val?.id)(event);
															setBoardName(val?.label);
														}}
														options={(schoolBoardSearch?.data || []).map((s: SchoolBoard) => ({
															id: s.id,
															label: `${s.name}`,
														}))}
														renderInput={(params) => (
															<Stack alignItems="center" direction="row" spacing={1}>
																<TextField
																	{...params}
																	variant="outlined"
																	label=""
																	InputProps={{
																		...params.InputProps,
																	}}
																	onChange={(event: any) => {
																		setBoardSearchTerm(event.target.value);
																	}}
																/>
																<IconButton
																	size="small"
																	onClick={() => handleVisibleSearchBoard()}
																	sx={{ borderRadius: '5px', border: '1px solid var(--mainColor)' }}
																>
																	<Search color="primary" />
																</IconButton>
															</Stack>
														)}
													/>
												</Stack>
												{formErrors.includes('schoolBoardId') && (
													<span className={global.errorText}>School Board is required</span>
												)}
											</Grid>
										)}
										{!isTemplateDeposit && (
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Grid container>
													<Grid item>
														<Domain className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography variant="body2" className={global.formLabelText}>
															School
														</Typography>
													</Grid>
												</Grid>
												<Stack alignItems="center" direction="row" spacing={1}>
													<Autocomplete
														freeSolo
														value={schoolName ?? ''}
														fullWidth
														size="small"
														loading={schoolSearch.loading}
														loadingText="loading..."
														onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
															updateFormValue('schoolId', val?.id)(event);
															setSchoolName(val?.label);
														}}
														options={(schoolSearch?.data || []).map((s: School) => ({
															id: s.id,
															label: `${s.name}`,
														}))}
														renderInput={(params) => (
															<Stack alignItems="center" direction="row" spacing={1}>
																<TextField
																	{...params}
																	variant="outlined"
																	label=""
																	InputProps={{
																		...params.InputProps,
																	}}
																	onChange={(event: any) => {
																		setSchoolSearchTerm(event.target.value);
																	}}
																/>
																<IconButton
																	size="small"
																	onClick={() => handleVisibleSearchSchool()}
																	sx={{ borderRadius: '5px', border: '1px solid var(--mainColor)' }}
																>
																	<Search color="primary" />
																</IconButton>
															</Stack>
														)}
													/>
													{formErrors.includes('schoolId') && (
														<span className={global.errorText}>School is required</span>
													)}
												</Stack>
											</Grid>
										)}
									</Grid>

									<Typography sx={{ fontSize: 14, fontWeight: 600 }}>Donation Details</Typography>
									<Divider />
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Grid container>
												<Grid item>
													<MonetizationOn className={global.formLabelIcon} />
												</Grid>
												<Grid item>
													<Typography variant="body2" className={global.formLabelText}>
														Amount
													</Typography>
												</Grid>
											</Grid>
											<TextField
												size="small"
												fullWidth
												label=""
												variant="outlined"
												value={formData?.creditAmount}
												onChange={updateFormValue('creditAmount')}
												disabled={isDisabled()}
												className={isDisabled() ? global.disabledInput : ''}
											/>
											{formErrors.includes('creditAmount') && (
												<span className={global.errorText}>Amount is required</span>
											)}
										</Grid>

										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Grid container>
												<Grid item>
													<PausePresentation className={global.formLabelIcon} />
												</Grid>
												<Grid item>
													<Typography variant="body2" className={global.formLabelText}>
														Payment Method
													</Typography>
												</Grid>
											</Grid>
											{allPaymentMethods && (
												<>
													<Select
														size="small"
														fullWidth
														placeholder="Payment Method"
														value={setPaymentMethod()}
														onChange={updateFormValue('paymentMethodId')}
														disabled={type === 'bank' || type === 'creditcard' || isDisabled()}
														className={isDisabled() ? global.disabledInput : ''}
													>
														{allPaymentMethods.map((x: { id: number; name: string }) => (
															<MenuItem key={x.id} value={x.id}>
																{x.name}
															</MenuItem>
														))}
													</Select>
													{formErrors.includes('paymentMethodId') && (
														<span className={global.errorText}>Payment Method is required</span>
													)}
												</>
											)}
										</Grid>
										{showBatchNumber && (
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Grid container>
													<Grid item>
														<MonetizationOn className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography variant="body2" className={global.formLabelText}>
															Batch Number
														</Typography>
													</Grid>
												</Grid>
												<TextField
													size="small"
													fullWidth
													label=""
													variant="outlined"
													value={formData?.batchNumber}
													onChange={updateFormValue('batchNumber')}
													disabled={isDisabled()}
													className={isDisabled() ? global.disabledInput : ''}
												/>
											</Grid>
										)}
									</Grid>

									<Typography sx={{ fontSize: 14, fontWeight: 600 }}>Account Details</Typography>
									<Divider />
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Grid container>
												<Grid item>
													<AccountBalanceWallet className={global.formLabelIcon} />
												</Grid>
												<Grid item>
													<Typography variant="body2" className={global.formLabelText}>
														Donor Bank Account
													</Typography>
												</Grid>
											</Grid>
											<TextField
												size="small"
												fullWidth
												label=""
												variant="outlined"
												value={formData?.accountNumber}
												onChange={updateFormValue('accountNumber')}
												disabled={isDisabled()}
												className={isDisabled() ? global.disabledInput : ''}
											/>
											{formErrors.includes('accountNumber') && (
												<span className={global.errorText}>Donor Bank Account is required</span>
											)}
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Grid container>
												<Grid item>
													<AccountBalance className={global.formLabelIcon} />
												</Grid>
												<Grid item>
													<Typography variant="body2" className={global.formLabelText}>
														Society Bank Account
													</Typography>
												</Grid>
											</Grid>
											<TextField
												size="small"
												fullWidth
												label=""
												variant="outlined"
												value={formData?.depositAccount}
												onChange={updateFormValue('depositAccount')}
												disabled={isDisabled()}
												className={isDisabled() ? global.disabledInput : ''}
											/>
											{formErrors.includes('depositAccount') && (
												<span className={global.errorText}>Society Bank Account is required</span>
											)}
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Grid container>
												<Grid item>
													<ConfirmationNumber className={global.formLabelIcon} />
												</Grid>
												<Grid item>
													<Typography variant="body2" className={global.formLabelText}>
														Cheque Number
													</Typography>
												</Grid>
											</Grid>
											<TextField
												size="small"
												fullWidth
												label=""
												variant="outlined"
												value={formData?.chequeNumber}
												onChange={updateFormValue('chequeNumber')}
												disabled={isDisabled()}
												className={isDisabled() ? global.disabledInput : ''}
											/>
											{formErrors.includes('chequeNumber') && (
												<span className={global.errorText}>Cheque Number is required</span>
											)}
										</Grid>
										{allPaymentMethods?.find((item: any) => item.code === 'DD')?.id ==
											formData?.paymentMethodId || type === 'bank' ? (
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Grid container>
													<Grid item>
														<AccountBalanceWallet className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography variant="body2" className={global.formLabelText}>
															Donor Bank Number
														</Typography>
													</Grid>
												</Grid>
												<TextField
													size="small"
													fullWidth
													label=""
													variant="outlined"
													value={formData?.donorBankNumber}
													onChange={updateFormValue('donorBankNumber')}
													disabled={isDisabled()}
													className={isDisabled() ? global.disabledInput : ''}
												/>
												{formErrors.includes('donorBankNumber') && (
													<span className={global.errorText}>Donor Bank Number is required</span>
												)}
											</Grid>
										) : null}
										{allPaymentMethods.find((item: any) => item.code === 'DD')?.id ===
											formData?.paymentMethodId || type === 'bank' ? (
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Grid container>
													<Grid item>
														<AccountBalance className={global.formLabelIcon} />
													</Grid>
													<Grid item>
														<Typography variant="body2" className={global.formLabelText}>
															Donor Branch Number
														</Typography>
													</Grid>
												</Grid>
												<TextField
													size="small"
													fullWidth
													label=""
													variant="outlined"
													value={formData?.donorBranchNumber}
													onChange={updateFormValue('donorBranchNumber')}
													disabled={isDisabled()}
													className={isDisabled() ? global.disabledInput : ''}
												/>
												{formErrors.includes('donorBranchNumber') && (
													<span className={global.errorText}>Donor Branch Number is required</span>
												)}
											</Grid>
										) : null}
									</Grid>
									<Typography sx={{ fontSize: 14, fontWeight: 600 }}>Dates</Typography>
									<Divider />
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Grid container>
												<Grid item>
													<Event className={global.formLabelIcon} />
												</Grid>
												<Grid item>
													<Typography variant="body2" className={global.formLabelText}>
														Date Received
													</Typography>
												</Grid>
											</Grid>
											<BasicDatePicker
												value={formData?.receivedDate || null}
												setValue={updateFormValue('receivedDate')}
												disabled={isDisabled()}
											/>
											{formErrors.includes('receivedDate') && (
												<span className={global.errorText}>Date Received is required</span>
											)}
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Grid container>
												<Grid item>
													<CalendarToday className={global.formLabelIcon} />
												</Grid>
												<Grid item>
													<Typography variant="body2" className={global.formLabelText}>
														Date Posted
													</Typography>
												</Grid>
											</Grid>
											<BasicDatePicker
												value={formData?.postDate}
												setValue={updateFormValue('postDate')}
												disabled={isDisabled()}
											/>
											{formErrors.includes('postDate') && (
												<span className={global.errorText}>Date Posted is required</span>
											)}
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Grid container>
												<Grid item>
													<DateRange className={global.formLabelIcon} />
												</Grid>
												<Grid item>
													<Typography variant="body2" className={global.formLabelText}>
														Date Credited
													</Typography>
												</Grid>
											</Grid>
											<BasicDatePicker
												value={formData?.creditDate}
												setValue={updateFormValue('creditDate')}
												disabled={isDisabled()}
											/>
											{formErrors.includes('creditDate') && (
												<span className={global.errorText}>Date Credited is required</span>
											)}
										</Grid>
									</Grid>
								</Paper>
							</Stack>
							{formData && (
								<DonationsModal
									open={toggleModal}
									triggerRefresh={triggerRefresh}
									setTriggerRefresh={setTriggerRefresh}
									saveDonation={saveDonationToDeposit}
									closeModal={() => {
										setCurrentDonationId(undefined);
										setToggleModal(false);
									}}
									donation={formData.donations.find((x) => x.id == currentDonationId)}
									donations={formData.donations}
									isDisabled={isDisabled}
								/>
							)}
						</Stack>
						{formData?.donations ? (
							<DepositDonationTable
								depositData={depositData}
								donorId={formData.donorId}
								triggerRefresh={triggerRefresh}
								setTriggerRefresh={setTriggerRefresh}
								donations={formData.donations}
								openDonationForm={(donationId: number) => {
									setCurrentDonationId(donationId);
									setToggleModal(true);
								}}
								loading={loading}
								isDisabled={isDisabled}
							/>
						) : (
							<Paper sx={{ px: 2, py: 2, mb: 8 }}>
								<Typography>NO DONATION SPLITS ADDED YET, You Split donations will appear here</Typography>
							</Paper>
						)}
					</>
				)}
			</Stack>
			{visibleSearch && (
				<AdvancedSearch
					visible={visibleSearch}
					handleClose={handleVisibleSearch}
					field={searchFields}
					setField={setSearchFields}
					title={'Search Donor'}
					handleSearch={handleSearch}
				>
					<>
						<Stack alignItems={'flex-end'}>
							<TrimTables name="donorHead" list={donorHead} trimList={trimList} setTrimList={setTrimList} />
						</Stack>
						<DonorTable
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							reload={reload}
							setReload={setReload}
							trimList={trimList}
							setTrimList={setTrimList}
							handleOpenEdit={handleSelectRow}
							isSearch
							archive="&Filters=field=archived;value=false"
							setPage={setPage}
							page={page}
						/>
					</>
				</AdvancedSearch>
			)}
			{visibleSearchSchool && (
				<AdvancedSearch
					visible={visibleSearchSchool}
					handleClose={handleVisibleSearchSchool}
					field={searchFieldsSchool}
					setField={setSearchFieldsSchool}
					title={'Search School'}
					handleSearch={handleSearchSchool}
				>
					<>
						<Stack alignItems={'flex-end'}>
							<TrimTables name="schoolHead" list={schoolHead} trimList={trimList} setTrimList={setTrimList} />
						</Stack>
						<SchoolTable
							trimList={trimList}
							setTrimList={setTrimList}
							page={page}
							pageSize={pageSize}
							setPage={setPage}
							setPageSize={setPageSize}
							label={label}
							orderType={orderType}
							setLabel={setLabel}
							setOderBy={setOderBy}
							setOrderType={setOrderType}
							schools={schools}
							hcaOrgList
							open={open}
							setOpen={setOpen}
							handleOpenEdit={handleSelectRowSchool}
						/>
					</>
				</AdvancedSearch>
			)}
			{visibleSearchBoard && (
				<AdvancedSearch
					visible={visibleSearchBoard}
					handleClose={handleVisibleSearchBoard}
					field={searchFieldsBoard}
					setField={setSearchFieldsBoard}
					title={'Search School Board'}
					handleSearch={handleSearchBoard}
				>
					<>
						<Stack alignItems={'flex-end'}>
							<TrimTables
								name="schoolBoardHead"
								list={schoolBoardHead}
								trimList={trimList}
								setTrimList={setTrimList}
							/>
						</Stack>
						<SchoolBoardTable
							trimList={trimList}
							setTrimList={setTrimList}
							page={page}
							pageSize={pageSize}
							setPage={setPage}
							setPageSize={setPageSize}
							label={label}
							orderType={orderType}
							setLabel={setLabel}
							setOderBy={setOderBy}
							setOrderType={setOrderType}
							schoolBoards={schoolBoards}
							hcaOrgList
							open={open}
							setOpen={setOpen}
							handleOpenEdit={handleSelectRowBoard}
						/>
					</>
				</AdvancedSearch>
			)}
			{visibleSearchParish && (
				<AdvancedSearch
					visible={visibleSearchParish}
					handleClose={handleVisibleSearchParish}
					field={searchFieldsParish}
					setField={setSearchFieldsParish}
					title={'Search Parish'}
					handleSearch={handleSearchParish}
				>
					<>
						<Stack alignItems={'flex-end'}>
							<TrimTables name="parishHead" list={parishHead} trimList={trimList} setTrimList={setTrimList} />
						</Stack>
						<ParishesTable
							trimList={trimList}
							setTrimList={setTrimList}
							page={page}
							pageSize={pageSize}
							setPage={setPage}
							setPageSize={setPageSize}
							label={label}
							orderType={orderType}
							setLabel={setLabel}
							setOderBy={setOderBy}
							setOrderType={setOrderType}
							parishes={parishes}
							hcaOrgList
							open={open}
							setOpen={setOpen}
							handleOpenEdit={handleSelectRowParish}
						/>
					</>
				</AdvancedSearch>
			)}
			{visibleSearchDiocese && (
				<AdvancedSearch
					visible={visibleSearchDiocese}
					handleClose={handleVisibleSearchDiocese}
					field={searchFieldsDiocese}
					setField={setSearchFieldsDiocese}
					title={'Search Diocese'}
					handleSearch={handleSearchDiocese}
				>
					<>
						<Stack alignItems={'flex-end'}>
							<TrimTables
								name="dioceseHead"
								list={dioceseHead}
								trimList={trimList}
								setTrimList={setTrimList}
							/>
						</Stack>
						<DioceseTable
							trimList={trimList}
							setTrimList={setTrimList}
							page={page}
							pageSize={pageSize}
							setPage={setPage}
							setPageSize={setPageSize}
							label={label}
							orderType={orderType}
							setLabel={setLabel}
							setOderBy={setOderBy}
							setOrderType={setOrderType}
							dioceses={dioceses}
							hcaOrgList
							open={open}
							setOpen={setOpen}
							handleOpenEdit={handleSelectRowDiocese}
						/>
					</>
				</AdvancedSearch>
			)}
			{/* {open.state && <OrdersModal open={open} setOpen={setOpen} />} */}
		</>
	);
};

export default Content;
