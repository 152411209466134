import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Stack, Typography, Button, Checkbox, FormControlLabel } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormTextField } from 'Components/Common/TextField';
import { useGlobalStyles } from 'Components/Common/global/global';
import { DonationTypes } from 'contexts/intialstates/Types';
import { ContentCopy, DriveFileRenameOutline, TaskAlt, Close } from '@mui/icons-material';
import * as yup from 'yup';
import useUserActions from 'hooks/useUserActions';

export interface DonationTypeValues {
	type: string;
	description: string;
	receipting: boolean;
}

interface CreateEditAppealProp {
	handleSubmit: (values: DonationTypeValues) => void;
	selectedEditData?: DonationTypes;
	saveLoading?: boolean;
	setVisible: any;
	setSelectedData: any;
}

const CreditEditDonationType = ({
	handleSubmit,
	selectedEditData,
	saveLoading,
	setVisible,
	setSelectedData,
}: CreateEditAppealProp) => {
	const { handleRoles } = useUserActions();
	const global = useGlobalStyles();
	const [initial, setInitial] = useState<DonationTypeValues>({
		type: '',
		description: '',
		receipting: false,
	});

	useEffect(() => {
		if (!selectedEditData) {
			setInitial({
				type: '',
				description: '',
				receipting: false,
			});
		} else {
			const { type, description, receipting } = selectedEditData;
			setInitial({
				type,
				description,
				receipting,
			});
		}
	}, [selectedEditData]);

	const validationAPpeal = yup.object().shape({
		type: yup.string().required('Type is required'),
		description: yup.string().required('Description is required'),
	});

	const handleCloseEdit = () => {
		setVisible(false);
		setSelectedData(undefined);
	};
	return (
		<Formik
			enableReinitialize
			onSubmit={handleSubmit}
			validationSchema={validationAPpeal}
			initialValues={initial}
		>
			{({ values, setFieldValue }) => (
				<Form noValidate autoComplete="off">
					<Stack>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
							<Grid item xs={6} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
								<Grid container>
									<Grid item>
										<ContentCopy className={global.formLabelIcon} />
									</Grid>
									<Grid item>
										<Typography variant="body2" className={global.formLabelText}>
											Type
										</Typography>
									</Grid>
								</Grid>
								<Field
									fullWidth
									name="type"
									size="small"
									variant="outlined"
									placeholder="Type"
									component={FormTextField}
								/>
							</Grid>
							<Grid item xs={6} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
								<FormControlLabel
									name="receipting"
									value="top"
									control={
										<Checkbox
											checked={values.receipting}
											onChange={(e) => setFieldValue('receipting', e.target.checked)}
										/>
									}
									label="Receipts"
								/>
							</Grid>
							<Grid item xs={6} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
								<Grid container>
									<Grid item>
										<DriveFileRenameOutline className={global.formLabelIcon} />
									</Grid>
									<Grid item>
										<Typography variant="body2" className={global.formLabelText}>
											Description
										</Typography>
									</Grid>
								</Grid>
								<Field
									fullWidth
									name="description"
									size="small"
									variant="outlined"
									placeholder="Description"
									component={FormTextField}
									multiline
									rows={4}
								/>
							</Grid>
						</Grid>
						<Stack direction="row" my={3} justifyContent={'center'} spacing={2} alignItems="center">
							{handleRoles('admin-actions') && (
								<LoadingButton
									type="submit"
									color="primary"
									variant="contained"
									sx={{ width: 'fit-content', textTransform: 'capitalize' }}
									loading={saveLoading}
									startIcon={<TaskAlt />}
									loadingPosition="start"
								>
									Save
								</LoadingButton>
							)}
							<Button
								variant="outlined"
								sx={{ width: 'fit-content', textTransform: 'capitalize' }}
								onClick={handleCloseEdit}
								startIcon={<Close />}
							>
								Cancel
							</Button>
						</Stack>
					</Stack>
				</Form>
			)}
		</Formik>
	);
};

export default CreditEditDonationType;
