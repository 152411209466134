export const orders = [
	{
		id: 10001,
		organizationId: 1,
		orgationzationType: 'School',
		organizationName: 'Clearwood centre',
		address: '3665 BENVOULIN ROAD',
		orderReceivedDate: '2022-05-21T00:00:00',
		materials: [
			{
				hcaMaterialId: 1,
				quantity: 3,
			},
			{
				hcaMaterialId: 2,
				quantity: 4,
			},
			{
				hcaMaterialId: 3,
				quantity: 7,
			},
		],
		status: 'Confirmed',
	},
	{
		id: 10002,
		organizationId: 2,
		orgationzationType: 'School Board',
		organizationName: 'School Board ',
		address: '3665 BENVOULIN ROAD',
		orderReceivedDate: '2022-05-21T00:00:00',
		materials: [
			{
				hcaMaterialId: 1,
				quantity: 3,
			},
			{
				hcaMaterialId: 2,
				quantity: 4,
			},
			{
				hcaMaterialId: 3,
				quantity: 7,
			},
		],
		status: 'Confirmed',
	},
	{
		id: 10003,
		organizationId: 3,
		orgationzationType: 'Diocese',
		organizationName: 'Diocese',
		address: '3665 BENVOULIN ROAD',
		orderReceivedDate: '2022-05-21T00:00:00',
		materials: [
			{
				hcaMaterialId: 1,
				quantity: 3,
			},
			{
				hcaMaterialId: 2,
				quantity: 4,
			},
			{
				hcaMaterialId: 3,
				quantity: 7,
			},
		],
		status: 'pending',
	},
	{
		id: 10004,
		organizationId: 4,
		orgationzationType: 'Parish',
		organizationName: 'Parish',
		address: '3665 BENVOULIN ROAD',
		orderReceivedDate: '2022-05-21T00:00:00',
		materials: [
			{
				hcaMaterialId: 1,
				quantity: 3,
			},
			{
				hcaMaterialId: 2,
				quantity: 4,
			},
			{
				hcaMaterialId: 3,
				quantity: 7,
			},
		],
		status: 'pending',
	},
	{
		id: 10005,
		organizationId: 5,
		orgationzationType: 'School',
		organizationName: 'Clearwood School',
		address: '3665 BENVOULIN ROAD',
		orderReceivedDate: '2022-05-21T00:00:00',
		materials: [
			{
				hcaMaterialId: 1,
				quantity: 3,
			},
			{
				hcaMaterialId: 2,
				quantity: 4,
			},
			{
				hcaMaterialId: 3,
				quantity: 7,
			},
		],
		status: 'pending',
	},
	{
		id: 10006,
		organizationId: 6,
		orgationzationType: 'Parsih',
		organizationName: 'Parish centre',
		address: '3665 BENVOULIN ROAD',
		orderReceivedDate: '2022-05-21T00:00:00',
		materials: [
			{
				hcaMaterialId: 1,
				quantity: 3,
			},
			{
				hcaMaterialId: 2,
				quantity: 4,
			},
			{
				hcaMaterialId: 3,
				quantity: 7,
			},
		],
		status: 'pending',
	},
];

export const orderData = {
	body: orders,
	pageNumber: 1,
	pageSize: 6,
	totalPages: 1,
	totalRecords: 6,
};
export const organizationType = [
	{
		label: 'School',
		id: 'School',
	},
	{
		label: 'School Board',
		id: 'Board',
	},
	{
		label: 'Diocese',
		id: 'Diocese',
	},
	{
		label: 'Parish',
		id: 'Parish',
	},
	// {
	// 	label: 'Individual',
	// 	id: 'Individual',
	// },
];
