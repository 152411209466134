import React, { useState, useEffect, useContext } from 'react';
import { Add, ArrowBackIosNew, Edit, Delete, AddTask, Receipt } from '@mui/icons-material';
import { Stack, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useStyles } from '../Annuities.style';
import LoadingButton from '@mui/lab/LoadingButton';
import ViewAnnuities from './ViewAnnuities';
import { useGlobalStyles } from 'Components/Common/global/global';
import CreateAnnuities, { FormValues } from './CreateEditAnnuities/CreateAnnuities';
import useAnnuitiesActions, { Agreement } from 'hooks/useAnnuitiesActions';
import { Error } from 'Components/Annuities/CreateEdit/CreateEditAnnuities/FormFieldLayout';
import { GlobalContexts } from 'contexts/GlobalContext';
import { StateType } from 'contexts/intialstates/Types';
import ActionModal from 'Components/Reusable/Modals/ActionModal/ActionModal';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import useUserActions from 'hooks/useUserActions';

const CreateEditView = () => {
	const { handleRoles } = useUserActions();
	const {
		createAnnuity,
		getAgreementById,
		updateAnnuity,
		postAgreement,
		deleteAgreement,
		revokeAgreement,
		generateAnnuitiesReceipt,
		getAcknowledgement,
		updateCommentAnnuity,
	} = useAnnuitiesActions();
	const { setSimpleErrorSnack } = useContext(GlobalContexts);
	const global = useGlobalStyles();
	const classes = useStyles();
	const navigate = useNavigate();
	const { id } = useParams();
	const [isView, setIsView] = useState(false);
	const [values, setValues] = useState<FormValues>({});
	const [errors, setErrors] = useState<Error[]>([]);
	const [saveLoading, setSaveLoading] = useState(false);
	const [agreement, setAgreement] = useState<StateType<Agreement>>({
		data: null,
		loading: false,
		error: null,
	});
	const [reload, setReload] = useState(false);
	const [openPostModal, setOpenPostModal] = useState(false);
	const [openReceiptModal, setOpenReceiptModal] = useState(false);
	const [openAcknowledgeModal, setOpenAcknowledgeModal] = useState(false);
	const [postLoading, setPostLoading] = useState(false);
	const [isDelete, setIsDelete] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [revokeLoading, setRevokeLoading] = useState(false);
	const [openRevokeModal, setOpenRevokeModal] = useState(false);
	const [receiptLoading, setReceiptLoading] = useState(false);
	const [acknowledgeLoading, setAcknowledgeLoading] = useState(false);
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		if (id) {
			setIsView(true);
			getAgreementById({ agreement, setAgreement, id: parseInt(id) });
		}
	}, [id, reload]);

	useEffect(() => {
		const data = agreement.data;
		if (!data) return;
		setValues({
			agreementNumber: data.agreementNumber,
			agreementTypeId: data.agreementType.id,
			agreementTypeName: data.agreementType.type,
			provinceOfRegistration: data.provinceOfRegistration
				? {
						id: data.provinceOfRegistration.id,
						name: data.provinceOfRegistration.provinceName,
				  }
				: undefined,
			paymentMethodId: data.paymentMethod.id,
			donor: {
				id: parseInt(data.annuitant.donor.id),
				name: `${data.annuitant.donor.firstName} ${data.annuitant.donor.lastName}`,
				altName: `${data.annuitant.donor.firstName} ${data.annuitant.donor.lastName}`,
			},
			donorId: parseInt(data.annuitant.donor.id),
			salutation: data.annuitant.donor.salutation,
			sin: data.annuitant.sin,
			genderId: data.annuitant?.gender?.id,
			maritalStatusId: data.annuitant?.maritalStatus?.id,
			dateOfBirth: data.annuitant?.dateOfBirth,
			dateOfDeath: data.annuitant?.dateOfDeath,
			coSin: data.coAnnuitant?.sin ?? '',
			coDonor: data.coAnnuitant
				? {
						id: parseInt(data.coAnnuitant.donor.id),
						name: `${data.coAnnuitant.donor.firstName} ${data.coAnnuitant.donor.lastName}`,
						altName: `${data.coAnnuitant.donor.firstName} ${data.coAnnuitant.donor.lastName}`,
				  }
				: undefined,
			coDonorId: data.coAnnuitant ? parseInt(data.coAnnuitant?.donor.id) : undefined,
			coSalutation: data.coAnnuitant?.donor.salutation,
			coGenderId: data.coAnnuitant?.gender?.id,
			coMaritalStatusId: data.coAnnuitant?.maritalStatus?.id,
			coDateOfBirth: data.coAnnuitant?.dateOfBirth,
			coDateOfDeath: data.coAnnuitant?.dateOfDeath,
			streetline1: data.annuitant.donor.streetline1,
			streetline2: data.annuitant.donor.streetline2,
			city: data.annuitant.donor.city,
			province: data.annuitant.donor?.province?.provinceName,
			postalCode: data.annuitant.donor.postalCode,
			country: data.annuitant.donor.country,
			bankName: data.bankName,
			bankAddress: data.bankAddress,
			bankNumber: data.bankNumber,
			bankBranchNumber: data.bankBranchNumber,
			bankAccountNumber: data.bankAccountNumber,
			bankPayableTo: data.bankPayableTo,
			receivedAmount: data.receivedAmount.toString(),
			receiptedAmount: data.receiptedAmount.toString(),
			dateReceived: data.dateReceived,
			paymentFrequencyId: data.paymentFrequency.id,
			firstPaymentDate: data.firstPaymentDate,
			firstAnnuityPayment: data.firstAnnuityPayment.toString(),
			periodicPayment: data.periodicPayment.toString(),
			annualPayment: data.annualPayment.toString(),
			annualRate: data.annualRate.toString(),
			taxPortionAnnuity: data.taxPortionAnnuity.toString(),
			firstPaymentTaxableAmount: data.firstPaymentTaxableAmount.toString(),
			annualPaymentTaxableAmount: data.annualPaymentTaxableAmount.toString(),
			beneficiarySocietyId: data.beneficiarySociety.id,
			comment: data.comment,
			accountBalance: data.accountBalance,
			accountDate: data.accountDate,
			acturialValue: data.acturialValue,
			valuationDate: data.valuationDate,
			lifeExpectancy: data.lifeExpectancy,
			firstPaymentNonTaxablePortion: data.firstAnnuityPayment - data.firstPaymentTaxableAmount,
			annualPaymentNonTaxablePortion: data.annualPayment - data.annualPaymentTaxableAmount,
		});
		setDisabled(data?.state?.state === 'Posted');
	}, [agreement?.data]);

	const validate = (): boolean => {
		const errors = [];
		if (!values.agreementTypeId) {
			errors.push({
				name: 'agreementTypeId',
				error: 'Agreement type is required',
			});
		}
		if (!values.paymentMethodId) {
			errors.push({
				name: 'paymentMethodId',
				error: 'Payment Method is required',
			});
		}
		if (!values.paymentFrequencyId) {
			errors.push({
				name: 'paymentFrequencyId',
				error: 'Payment Frequency is required',
			});
		}
		if (!values.dateReceived) {
			errors.push({
				name: 'dateReceived',
				error: 'Date Received is required',
			});
		}
		if (!values.beneficiarySocietyId) {
			errors.push({
				name: 'beneficiarySocietyId',
				error: 'Beneficiary Society is required',
			});
		}
		if (!values.provinceOfRegistration?.id) {
			errors.push({
				name: 'provinceOfRegistration',
				error: 'Province of registration is required',
			});
		}
		if (!values.donor?.id) {
			errors.push({
				name: 'donor',
				error: 'Annuitant is required',
			});
		}
		if (!values.sin) {
			errors.push({
				name: 'sin',
				error: 'Annuitant sin is required',
			});
		}
		if (!values.genderId) {
			errors.push({
				name: 'genderId',
				error: 'Annuitant gender is required',
			});
		}
		if (!values.dateOfBirth) {
			errors.push({
				name: 'dateOfBirth',
				error: 'Annuitant Date Of Birth is required',
			});
		}
		// if (!values.maritalStatusId) {
		// 	errors.push({
		// 		name: 'maritalStatusId',
		// 		error: 'Annuitant marital status is required',
		// 	});
		// }
		if (values.agreementTypeName === 'Joint') {
			if (!values.coDonor?.id) {
				errors.push({
					name: 'coDonor',
					error: 'Co Annuitant is required',
				});
			}
			if (!values.coSin) {
				errors.push({
					name: 'coSin',
					error: 'Co annuitant sin is required',
				});
			}
			if (!values.coGenderId) {
				errors.push({
					name: 'coGenderId',
					error: 'Co annuitant gender is required',
				});
			}
			if (!values.coDateOfBirth) {
				errors.push({
					name: 'coDateOfBirth',
					error: 'Co annuitant Date Of Birth is required',
				});
			}
			// if (!values.coMaritalStatusId) {
			// 	errors.push({
			// 		name: 'coMaritalStatusId',
			// 		error: 'Co annuitant marital status is required',
			// 	});
			// }
		}
		if (!values.receivedAmount) {
			errors.push({
				name: 'receivedAmount',
				error: 'Received amount is required',
			});
		}
		if (!values.firstAnnuityPayment) {
			errors.push({
				name: 'firstAnnuityPayment',
				error: 'First Annuity Payment is required',
			});
		} else if (parseInt(values.firstAnnuityPayment) <= 0) {
			errors.push({
				name: 'firstAnnuityPayment',
				error: 'First Annuity Payment must be greater than 0',
			});
		}
		if (!values.periodicPayment) {
			errors.push({
				name: 'periodicPayment',
				error: 'Periodic Payment is required',
			});
		} else if (parseInt(values.periodicPayment) <= 0) {
			errors.push({
				name: 'periodicPayment',
				error: 'Periodic Payment must be greater than 0',
			});
		}
		if (!values.annualPayment) {
			errors.push({
				name: 'annualPayment',
				error: 'Annual Payment is required',
			});
		} else if (parseInt(values.annualPayment) <= 0) {
			errors.push({
				name: 'annualPayment',
				error: 'Annual Payment must be greater than 0',
			});
		}
		if (!values.annualRate) {
			errors.push({
				name: 'annualRate',
				error: 'Annual Rate is required',
			});
		} else if (parseInt(values.annualRate) <= 0) {
			errors.push({
				name: 'annualRate',
				error: 'Annual Rate must be greater than 0',
			});
		}
		if (!values.taxPortionAnnuity) {
			errors.push({
				name: 'taxPortionAnnuity',
				error: 'Tax Portion Annuity is required',
			});
		}
		// else if (parseInt(values.taxPortionAnnuity) <= 0) {
		// 	errors.push({
		// 		name: 'taxPortionAnnuity',
		// 		error: 'Tax Portion Annuity must be greater than 0',
		// 	});
		// }
		if (!values.firstPaymentTaxableAmount) {
			errors.push({
				name: 'firstPaymentTaxableAmount',
				error: 'First Payment Taxable Amount is required',
			});
		}

		// else if (parseInt(values.firstPaymentTaxableAmount) <= 0) {
		// 	errors.push({
		// 		name: 'firstPaymentTaxableAmount',
		// 		error: 'First Payment Taxable Amount must be greater than 0',
		// 	});
		// }
		if (!values.annualPaymentTaxableAmount) {
			errors.push({
				name: 'annualPaymentTaxableAmount',
				error: 'Annual Payment Taxable Amount is required',
			});
		}

		// else if (parseInt(values.annualPaymentTaxableAmount) <= 0) {
		// 	errors.push({
		// 		name: 'annualPaymentTaxableAmount',
		// 		error: 'Annual Payment Taxable Amount must be greater than 0',
		// 	});
		// }
		let bankCount = 0;
		if (values.bankAddress) {
			bankCount++;
		}
		if (values.bankName) {
			bankCount++;
		}
		if (values.bankAccountNumber) {
			bankCount++;
		}
		if (values.bankBranchNumber) {
			bankCount++;
		}
		if (values.bankNumber) {
			bankCount++;
		}
		if (values.bankPayableTo) {
			bankCount++;
		}
		if (bankCount !== 0 && bankCount < 5) {
			setSimpleErrorSnack({
				message: 'Bank details fields either be all empty or all filled.',
				severity: 'error',
				show: true,
			});
		}
		setErrors(errors);
		if (errors.length || (bankCount !== 0 && bankCount < 5)) return true;
		return false;
	};

	const handleSave = async () => {
		const isError = validate();
		if (isError) {
			setSimpleErrorSnack({
				severity: 'error',
				message: 'Complete required fields',
				show: true,
			});
			return;
		}

		const payload = Object.assign(
			{
				agreementTypeId: values.agreementTypeId,
				provinceOfRegistrationId: values?.provinceOfRegistration?.id,
				paymentMethodId: values.paymentMethodId,
				annuitant: {
					donorId: values.donor?.id,
					genderId: values.genderId,
					maritalStatusId: values.maritalStatusId,
					dateOfBirth: values.dateOfBirth,
					sin: values.sin,
				},
				receivedAmount: values.receivedAmount ? parseFloat(values.receivedAmount) : 0,
				receiptedAmount: values.receiptedAmount ? parseFloat(values.receiptedAmount) : 0,
				dateReceived: values.dateReceived,
				paymentFrequencyId: values.paymentFrequencyId,
				firstPaymentDate: values.firstPaymentDate,
				firstAnnuityPayment: values.firstAnnuityPayment ? parseFloat(values.firstAnnuityPayment) : 0,
				periodicPayment: values.periodicPayment ? parseFloat(values.periodicPayment) : 0,
				annualPayment: values.annualPayment ? parseFloat(values.annualPayment) : 0,
				annualRate: values.annualRate ? parseFloat(values.annualRate) : 0,
				taxPortionAnnuity: values.taxPortionAnnuity ? parseFloat(values.taxPortionAnnuity) : 0,
				firstPaymentTaxableAmount: values.firstPaymentTaxableAmount
					? parseFloat(values.firstPaymentTaxableAmount)
					: 0,
				annualPaymentTaxableAmount: values.annualPaymentTaxableAmount
					? parseFloat(values.annualPaymentTaxableAmount)
					: 0,
				beneficiarySocietyId: values.beneficiarySocietyId,
				comment: values.comment,
				bankName: values.bankName,
				bankAddress: values.bankAddress,
				bankNumber: values.bankNumber,
				bankBranchNumber: values.bankBranchNumber,
				bankAccountNumber: values.bankAccountNumber,
				bankPayableTo: values.bankPayableTo,
			},
			values.agreementTypeName === 'Joint'
				? {
						coAnnuitant: {
							donorId: values.coDonor?.id,
							genderId: values.coGenderId,
							maritalStatusId: values.coMaritalStatusId,
							dateOfBirth: values.coDateOfBirth,
							sin: values.coSin,
						},
				  }
				: { coAnnuitant: {} },
		);
		setSaveLoading(true);
		if (id) {
			const success = await updateAnnuity(payload, parseInt(id));
			if (success) {
				setReload(!reload);
			}
		} else {
			const successId = await createAnnuity(payload);
			if (successId) {
				navigate(`/annuities/agreement/${successId}`);
			}
		}
		setSaveLoading(false);
	};

	const handleUpdateComment = async () => {
		if (!id) return;
		setSaveLoading(true);

		const success = await updateCommentAnnuity(values.comment ?? '', parseInt(id));
		if (success) {
			setReload(!reload);
		}
		setSaveLoading(false);
	};

	const handleConfirmPost = async () => {
		if (!id) return;
		setPostLoading(true);
		const isSucess = await postAgreement(parseInt(id));
		if (isSucess) {
			setReload(!reload);
		}
		setPostLoading(false);
		setOpenPostModal(false);
	};

	const handleToggleDelete = () => {
		setIsDelete(!isDelete);
	};

	const handleToggleRevoke = () => {
		setOpenRevokeModal(!openRevokeModal);
	};

	const handleDelete = async () => {
		if (!id) return;
		setDeleteLoading(true);
		const isSucess = await deleteAgreement(parseInt(id));
		setDeleteLoading(false);
		handleToggleDelete();
		if (isSucess) {
			navigate('../');
		}
	};

	const handleConfirmRevoke = async () => {
		if (!id) return;
		setRevokeLoading(true);
		const isSucess = await revokeAgreement(parseInt(id));
		if (isSucess) {
			setReload(!reload);
		}
		setRevokeLoading(false);
		handleToggleRevoke();
	};

	const handleGenerate = async () => {
		if (!id) return;
		setReceiptLoading(true);
		await generateAnnuitiesReceipt(parseInt(id));
		setReceiptLoading(false);
		handleReceiptConfirmation();
	};

	const handleAcknowledgement = async () => {
		if (!id) return;
		setAcknowledgeLoading(true);
		await getAcknowledgement(parseInt(id));
		setAcknowledgeLoading(false);
		handleAcknowledConfirmation();
	};

	const handleReceiptConfirmation = () => {
		setOpenReceiptModal(!openReceiptModal);
	};

	const handleAcknowledConfirmation = () => {
		setOpenAcknowledgeModal(!openAcknowledgeModal);
	};

	return (
		<Stack spacing={3}>
			<Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap={'wrap'}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={4}
					marginBottom={2}
				>
					<Stack className={classes.backIconWrapper}>
						<ArrowBackIosNew onClick={() => navigate('../')} />
					</Stack>
					<Stack alignItems={'flex-start'}>
						<span className={classes.title}>
							{id ? `Agreement Id : ${agreement?.data?.agreementNumber ?? 'N/A'}` : 'Create Agreement'}
						</span>
						<Stack direction="row" spacing={1}>
							{agreement?.data?.status?.status ? (
								<span className={classes.subTitle}>{agreement?.data?.status?.status}</span>
							) : null}
							{agreement?.data?.state ? (
								<span className={classes.subTitle}>{agreement?.data?.state?.state}</span>
							) : null}
						</Stack>
					</Stack>
				</Stack>
				{handleRoles('annuities-actions') && (
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems={'center'}
						spacing={2}
						marginBottom={2}
					>
						{isView && agreement?.data?.state?.state !== 'Posted' && (
							<Button
								startIcon={<AddTask />}
								size={'small'}
								variant={agreement?.data?.state?.state !== 'Posted' ? 'outlined' : 'contained'}
								className={agreement?.data?.state?.state !== 'Posted' ? global.whiteBtn : ''}
								onClick={() => setOpenPostModal(true)}
							>
								Post
							</Button>
						)}
						{isView && agreement?.data?.state?.state !== 'Posted' && (
							<Button
								startIcon={<Delete />}
								size={'small'}
								variant="contained"
								onClick={() => handleToggleDelete()}
							>
								Archive
							</Button>
						)}
						{isView &&
							(agreement?.data?.paymentCount === 0 || agreement?.data?.status?.status !== 'Revoked') && (
								<Button
									startIcon={<Add />}
									className={global.whiteBtn}
									size={'small'}
									variant={'outlined'}
									onClick={handleToggleRevoke}
								>
									Revoke
								</Button>
							)}
						{isView && agreement?.data?.state?.state === 'Posted' && (
							<Button
								startIcon={<Receipt />}
								size={'small'}
								variant={'contained'}
								onClick={() => setOpenReceiptModal(true)}
							>
								Generate Receipt
							</Button>
						)}
						{isView && agreement?.data?.state?.state === 'Posted' && (
							<Button
								startIcon={<AddTask />}
								size={'small'}
								variant={'outlined'}
								onClick={() => setOpenAcknowledgeModal(true)}
								className={global.whiteBtn}
							>
								Acknowledgement
							</Button>
						)}
						{!isView && (
							<LoadingButton
								loadingPosition="start"
								loading={saveLoading}
								startIcon={<Add />}
								size={'small'}
								variant="contained"
								onClick={() =>
									agreement?.data?.state?.state === 'Posted' ? handleUpdateComment() : handleSave()
								}
							>
								Save
							</LoadingButton>
						)}
						{isView && (
							<Button
								size={'small'}
								variant="contained"
								startIcon={<Edit />}
								onClick={() => setIsView(false)}
							>
								Edit Overview
							</Button>
						)}
					</Stack>
				)}
			</Stack>

			{!isView ? (
				<CreateAnnuities
					id={id ? parseInt(id) : undefined}
					values={values}
					setValues={setValues}
					errors={errors}
					setErrors={setErrors}
					disabled={disabled}
				/>
			) : (
				<ViewAnnuities agreement={agreement} />
			)}
			{openPostModal && (
				<ActionModal
					open={openPostModal}
					title={'Post Agreement'}
					subTitle={'Are you sure you want to Post this Agreement?'}
					description={`Note: You will not be able to edit the Agreement once it is posted`}
					submitText={'Post'}
					handleSubmit={handleConfirmPost}
					closeText={'Cancel'}
					handleClose={() => setOpenPostModal(false)}
					loading={postLoading}
				/>
			)}
			{openRevokeModal && (
				<ActionModal
					open={openRevokeModal}
					title={'Revoke Agreement'}
					subTitle={'Are you sure you want to revoke this Agreement?'}
					submitText={'Revoke'}
					handleSubmit={handleConfirmRevoke}
					closeText={'Cancel'}
					handleClose={handleToggleRevoke}
					loading={revokeLoading}
				/>
			)}
			{isDelete && (
				<ConfirmationModal
					handleClose={handleToggleDelete}
					message={'Are you sure you want to delete Agreement ?'}
					open={isDelete}
					handleConfirmation={handleDelete}
					handleCancel={handleToggleDelete}
					loading={deleteLoading}
				/>
			)}
			{openReceiptModal && (
				<ConfirmationModal
					handleCancel={handleReceiptConfirmation}
					handleConfirmation={handleGenerate}
					open={openReceiptModal}
					handleClose={handleReceiptConfirmation}
					message="Do you want to generate a receipt for this agreement ?"
					loading={receiptLoading}
				/>
			)}
			{openAcknowledgeModal && (
				<ConfirmationModal
					handleCancel={handleAcknowledConfirmation}
					handleConfirmation={handleAcknowledgement}
					open={openAcknowledgeModal}
					handleClose={handleAcknowledConfirmation}
					message="Do you want to get an acknowledgment for this agreement ?"
					loading={acknowledgeLoading}
				/>
			)}
		</Stack>
	);
};

export default CreateEditView;
