import { AllPaymentMethodStateType, SinglePaymentMethodStateType } from './Types';

export const allPaymentMethodInitialState: AllPaymentMethodStateType = {
	loading: false,
	error: null,
	data: null,
};

export const singlePaymentMethodInitialState: SinglePaymentMethodStateType = {
	loading: false,
	error: null,
	data: null,
};
