import { Group, ResponseError, StateType } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';

export interface GetProvince {
	groups: StateType<Group[]>;
	setGroups: React.Dispatch<React.SetStateAction<StateType<Group[]>>>;
}

const useGroupActions = () => {
	const { api } = useApi();

	const getGroup = async ({ groups, setGroups }: GetProvince) => {
		try {
			setGroups({
				...groups,
				loading: true,
			});
			const response = await api.get(`group/list`);

			setGroups({
				...groups,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setGroups({
					...groups,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	return {
		getGroup,
	};
};

export default useGroupActions;
