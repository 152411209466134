import { useContext } from 'react';
import {
	ResponseError,
	StateType,
	TimeStamp,
	TimeStampInfoType,
	Province,
	PaymentMethod,
	FilterPayload,
	Annuitant,
	AnnuitantState,
	AnnuitantStatus,
	Society,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface Annuities {
	pageNumber: number;
	pageSize: number;
	totalPages: number;
	totalRecords: number;
	body: AnnuitiesData[];
}

export interface AgreementType {
	id: number;
	type: string;
}

export interface AnnuitiesData extends TimeStamp, TimeStampInfoType {
	id: number;
	agreementNumber: string;
	state: AnnuitantState;
	status: AnnuitantStatus;
	annuitant: Annuitant;
	coAnnuitant: Annuitant;
	receivedAmount: number;
	agreementType: AgreementType;
}

export interface AnnuitiesPostingData extends TimeStamp, TimeStampInfoType {
	id: number;
	postingNumber: number;
	type: {
		id: number;
		type: string;
	};
	postingPeriod: string;
	postingDate: string;
	created: string;
	updated: string;
	createdBy: {
		id: number;
		name: string;
		created: string;
		updated: string;
	};
	updatedBy: {
		id: number;
		name: string;
		created: string;
		updated: string;
	};
}

export interface AnnuitiesPosting {
	pageNumber: number;
	pageSize: number;
	totalPages: number;
	totalRecords: number;
	body: AnnuitiesPostingData[];
}

export interface IPostingPeriods {
	last: string;
	current: string;
	canPost: boolean;
}

export interface PostingPeriodsProps {
	annuitiesPostingPeriods: StateType<IPostingPeriods>;
	setAnnuitiesPostingPeriods: React.Dispatch<React.SetStateAction<StateType<IPostingPeriods>>>;
	type: 'monthly' | 'annual';
}
export interface GetPostingLogProps {
	postingLogs: StateType<IPostingLog>;
	setPostingLogs: React.Dispatch<React.SetStateAction<StateType<IPostingLog>>>;
	orderBy?: string;
	id: string;
}
export interface GetAnnuitiesPostingProps {
	annuitiesPosting: StateType<AnnuitiesPosting>;
	setAnnuitiesPosting: React.Dispatch<React.SetStateAction<StateType<AnnuitiesPosting>>>;
	page: number;
	pageSize: number;
	orderBy?: string;
	filterPayload: FilterPayload[];
	type: 'monthly' | 'annual';
}
export interface GetAnnuitiesProps {
	annuities: StateType<Annuities>;
	setAnnuities: React.Dispatch<React.SetStateAction<StateType<Annuities>>>;
	page: number;
	pageSize: number;
	orderBy?: string;
	filterPayload: FilterPayload[];
}

export interface DonorAnnuitiesData extends TimeStamp, TimeStampInfoType {
	id: number;
	agreementNumber: string;
	state: AnnuitantState;
	status: AnnuitantStatus;
	annuitant: Annuitant;
	coAnnuitant: Annuitant;
	receivedAmount: number;
	agreementType: AgreementType;
	bankName: string;
	bankAddress: string;
	bankNumber: string;
	bankBranchNumber: string;
	bankAccountNumber: string;
	bankPayableTo: string;
	role: {
		id: number;
		role: string;
	};
}

export interface DonorAnnuities {
	pageNumber: number;
	pageSize: number;
	totalPages: number;
	totalRecords: number;
	body: DonorAnnuitiesData[];
}

export interface GetDonorAnnuitiesProps {
	donorAnnuities: StateType<DonorAnnuities>;
	setDonorAnnuities: React.Dispatch<React.SetStateAction<StateType<DonorAnnuities>>>;
	page: number;
	pageSize: number;
	orderBy?: string;
	filterPayload: FilterPayload[];
	donorId: number;
}

export interface PaymentData extends TimeStamp, TimeStampInfoType {
	id: number;
	paymentNumber: number;
	paymentDate: string;
	annuity: number;
	residual: number;
	expense: number;
	interest: number;
	balance: number;
	agreement: AnnuitiesData;
	isOverPayment: boolean;
}

export interface Payment {
	pageNumber: number;
	pageSize: number;
	totalPages: number;
	totalRecords: number;
	body: PaymentData[];
}

export interface GetPaymentProps {
	payment: StateType<Payment>;
	setPayment: React.Dispatch<React.SetStateAction<StateType<Payment>>>;
	page: number;
	pageSize: number;
	orderBy?: string;
	filterPayload?: FilterPayload[];
	agreementId: number;
}

interface CreateAnnuitant {
	donorId?: number;
	genderId?: number;
	maritalStatusId?: number;
	statusId?: number;
	dateOfBirth?: string;
	sin?: string;
}

interface CreatePayload {
	agreementTypeId?: number;
	provinceOfRegistrationId?: number;
	paymentMethodId?: number;
	annuitant: CreateAnnuitant;
	coAnnuitant: CreateAnnuitant;
	receivedAmount?: number;
	receiptedAmount?: number;
	dateReceived?: string;
	paymentFrequencyId?: number;
	firstPaymentDate?: string;
	firstAnnuityPayment?: number;
	periodicPayment?: number;
	annualPayment?: number;
	annualRate?: number;
	taxPortionAnnuity?: number;
	firstPaymentTaxableAmount?: number;
	annualPaymentTaxableAmount?: number;
	beneficiarySocietyId?: number;
	comment?: string;
	bankName?: string;
	bankAddress?: string;
	bankNumber?: string;
	bankBranchNumber?: string;
	bankAccountNumber?: string;
	bankPayableTo?: string;
}

export interface GetAgreementType {
	agreementType: StateType<AgreementType[]>;
	setAgreementType: React.Dispatch<React.SetStateAction<StateType<AgreementType[]>>>;
}

export interface AgreementStatus {
	id: number;
	status: string;
}

export interface GetAgreementStatus {
	agreementStatus: StateType<AgreementStatus[]>;
	setAgreementStatus: React.Dispatch<React.SetStateAction<StateType<AgreementStatus[]>>>;
}

export interface PaymentFrequency {
	id: number;
	frequency: string;
}

export interface GetPaymentFrequency {
	paymentFrequency: StateType<PaymentFrequency[]>;
	setPaymentFrequency: React.Dispatch<React.SetStateAction<StateType<PaymentFrequency[]>>>;
}

export interface Agreement extends TimeStamp, TimeStampInfoType {
	agreementId: number;
	agreementNumber: string;
	lifeExpectancy: number;
	receivedAmount: number;
	receiptedAmount: number;
	dateReceived: string;
	firstPaymentDate: string;
	firstAnnuityPayment: number;
	periodicPayment: number;
	annualPayment: number;
	annualRate: number;
	taxPortionAnnuity: number;
	firstPaymentTaxableAmount: number;
	firstPaymentNonTaxablePortion: number;
	annualPaymentTaxableAmount: number;
	annualPaymentNonTaxablePortion: number;
	accountBalance: number;
	accountDate: string;
	acturialValue: number;
	valuationDate: string;
	state: {
		id: number;
		state: string;
	};
	status: {
		id: number;
		status: string;
	};
	agreementType: AgreementType;
	paymentFrequency: PaymentFrequency;
	provinceOfRegistration: Province;
	paymentMethod: PaymentMethod;
	annuitant: Annuitant;
	coAnnuitant: Annuitant;
	beneficiarySociety: Society;
	comment: string;
	bankName: string;
	bankAddress: string;
	bankNumber: string;
	bankBranchNumber: string;
	bankAccountNumber: string;
	bankPayableTo: string;
	overPaymentAmount: number;
	paymentCount: number;
	lastPaymentDate: string;
}

interface GetAgreementByIdProps {
	agreement: StateType<Agreement>;
	setAgreement: React.Dispatch<React.SetStateAction<StateType<Agreement>>>;
	id: number;
}

interface UpdateOverPaymentPayload {
	updates: [
		{
			agreementPaymentId: number;
			isOverPayment: boolean;
		},
	];
}
export interface PostingLogDetail {
	agreementId: number;
	agreementNumber: string;
	agreementType: string;
	status: string;
	annuitant: string;
	residualBeginning: number;
	newGift: number;
	annuityPayment: number;
	expense: number;
	interestCredit: number;
	residualEnd: number;
}
export interface PostingLog {
	postingLog: {
		id: number;
		postingNumber: number;
		type: {
			id: number;
			type: string;
		};
		postingPeriod: string;
		postingDate: string;
		created: string;
		updated: string;
		createdBy: {
			id: number;
			name: string;
			created: string;
			updated: string;
		};
		updatedBy: {
			id: number;
			name: string;
			created: string;
			updated: string;
		};
	};
	details: PostingLogDetail[];
}
export interface IPostingLog {
	id: number;
	postingNumber: number;
	type: string;
	postingPeriod: string;
	postingDate: string;
	details: PostingLogDetail[];
}

interface EFTPayment {
	id: number;
	fullName: string;
	dueDate: string;
	account: string;
	transit: string;
	annuity: number;
	sin: string;
}

export interface EFTPaymentData {
	currentPaymentDate: string;
	payments: EFTPayment[];
}

interface GetEFTPayment {
	eftPayment: StateType<EFTPaymentData>;
	setEftPayment: React.Dispatch<React.SetStateAction<StateType<EFTPaymentData>>>;
}

const useAnnuitiesActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = useContext(GlobalContexts);

	const getAnnuities = async ({
		annuities,
		setAnnuities,
		page,
		pageSize,
		orderBy,
		filterPayload,
	}: GetAnnuitiesProps) => {
		try {
			setAnnuities({
				...annuities,
				loading: true,
			});
			let filter = '';
			filterPayload.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`annuity/agreement/list?PageNumber=${page + 1}&PageSize=${pageSize}${filter}${orderBy || ''}`,
				);
			} else {
				response = await api.get(`annuity/agreement/list?&PageSize=${pageSize}${filter}${orderBy || ''}`);
			}

			setAnnuities({
				...annuities,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || annuities.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAnnuities({
					...annuities,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const getDonorAnnuities = async ({
		donorAnnuities,
		setDonorAnnuities,
		page,
		pageSize,
		orderBy,
		filterPayload,
		donorId,
	}: GetDonorAnnuitiesProps) => {
		try {
			setDonorAnnuities({
				...donorAnnuities,
				loading: true,
			});
			let filter = '';
			filterPayload.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;

			if (page) {
				response = await api.get(
					`annuity/agreement/donor/${donorId}/list?PageNumber=${page + 1}&PageSize=${pageSize}${filter}${
						orderBy || ''
					}`,
				);
			} else {
				response = await api.get(
					`annuity/agreement/donor/${donorId}/list?&PageSize=${pageSize}${filter}${orderBy || ''}`,
				);
			}

			setDonorAnnuities({
				...donorAnnuities,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || donorAnnuities.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDonorAnnuities({
					...donorAnnuities,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const createAnnuity = async (payload: CreatePayload): Promise<boolean | number> => {
		try {
			const createdData = await api.post(`/annuity/agreement/create`, { ...payload });
			if (createdData?.data?.result) {
				setSimpleErrorSnack({
					message: 'Annuity created successfully',
					severity: 'success',
					show: true,
				});
				return createdData?.data?.result?.agreementId;
			}
			return false;
		} catch (err: any) {
			if (err.response?.data?.StatusCode === 306) {
				setSimpleErrorSnack({
					message: 'Invalid Annuitant Date Of Birth',
					severity: 'error',
					show: true,
				});

				return false;
			}
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const updateAnnuity = async (payload: CreatePayload, id: number): Promise<boolean | number> => {
		try {
			const updateData = await api.put(`/annuity/agreement/${id}/update`, { ...payload });
			if (updateData?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Agreement updated successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const updateCommentAnnuity = async (comment: string, id: number): Promise<boolean | number> => {
		try {
			const updateData = await api.put(`/annuity/agreement/${id}/update-comment`, { comment });
			if (updateData?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Agreement updated successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const getAgreementTypes = async ({ agreementType, setAgreementType }: GetAgreementType) => {
		try {
			setAgreementType({
				...agreementType,
				loading: true,
			});
			const response = await api.get(`annuity/agreementType/list`);

			setAgreementType({
				...agreementType,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAgreementType({
					...agreementType,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const getAgreementStatus = async ({ agreementStatus, setAgreementStatus }: GetAgreementStatus) => {
		try {
			setAgreementStatus({
				...agreementStatus,
				loading: true,
			});
			const response = await api.get(`annuity/agreementStatus/list`);

			setAgreementStatus({
				...agreementStatus,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAgreementStatus({
					...agreementStatus,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const getPaymentFrequency = async ({ paymentFrequency, setPaymentFrequency }: GetPaymentFrequency) => {
		try {
			setPaymentFrequency({
				...paymentFrequency,
				loading: true,
			});
			const response = await api.get(`annuity/agreementPaymentFrequency/list`);

			setPaymentFrequency({
				...paymentFrequency,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setPaymentFrequency({
					...paymentFrequency,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const getAgreementById = async ({ agreement, setAgreement, id }: GetAgreementByIdProps) => {
		try {
			setAgreement({
				...agreement,
				loading: true,
			});
			const response = await api.get(`/annuity/agreement/${id}`);

			setAgreement({
				...agreement,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAgreement({
					...agreement,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const getPaymentHistory = async ({
		payment,
		setPayment,
		page,
		pageSize,
		orderBy,
		filterPayload,
		agreementId,
	}: GetPaymentProps) => {
		try {
			setPayment({
				...payment,
				loading: true,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;

			if (page) {
				response = await api.get(
					`annuity/agreement/${agreementId}/payments?PageNumber=${page + 1}&PageSize=${pageSize}${filter}${
						orderBy || '&SortBy=paymentDate desc'
					}`,
				);
			} else {
				response = await api.get(
					`annuity/agreement/${agreementId}/payments?&PageSize=${pageSize}${filter}${
						orderBy || '&SortBy=paymentDate desc'
					}`,
				);
			}

			setPayment({
				...payment,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || payment.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setPayment({
					...payment,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const postAgreement = async (id: number): Promise<boolean> => {
		try {
			const postedData = await api.post(`/annuity/agreement/${id}/post`);
			if (postedData?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Agreement posted succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const revokeAgreement = async (id: number): Promise<boolean> => {
		try {
			const revokedData = await api.post(`/annuity/agreement/${id}/revoke`);
			if (revokedData?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Agreement revoked successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err: any) {
			if (err.response?.data?.StatusCode === 340) {
				setSimpleErrorSnack({
					message: 'Agreement with payment cannot be revoked!',
					severity: 'error',
					show: true,
				});
				return false;
			}

			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deleteAgreement = async (id: number): Promise<boolean> => {
		try {
			const deletedData = await api.remove(`annuity/agreement/${id}/archive`);
			if (deletedData?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Agreement archived successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const updateOverpayment = async (payload: UpdateOverPaymentPayload, id: number): Promise<boolean> => {
		try {
			const updatedData = await api.post(`/annuity/agreement/${id}/updateOverPayment`, { ...payload });
			if (updatedData?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Overpayment updated successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deleteAgreementPayment = async (paymentId: number, agreementId: number): Promise<boolean> => {
		try {
			const deletedData = await api.remove(`annuity/agreement/${agreementId}/archivePayment/${paymentId}`);
			if (deletedData?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Payment history archived successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const annuitiesPosting = async (type: 'annual' | 'monthly'): Promise<PostingLog | null> => {
		try {
			const postedData = await api.post(
				`/annuity/${type === 'monthly' ? 'monthlyPaymentGenerationAndPosting' : 'annualPosting'}`,
				{},
			);
			if (postedData?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Added succesfully',
					severity: 'success',
					show: true,
				});
				return postedData.data.result;
			}
			return null;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return null;
		}
	};

	const getAnnuitiesPosting = async ({
		annuitiesPosting,
		setAnnuitiesPosting,
		page,
		pageSize,
		orderBy,
		filterPayload,
		type,
	}: GetAnnuitiesPostingProps) => {
		try {
			setAnnuitiesPosting({
				...annuitiesPosting,
				loading: true,
			});
			let filter = '';
			filterPayload.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`annuity/postingLog/${type === 'monthly' ? 'monthly' : 'annual'}/list?PageNumber=${
						page + 1
					}&PageSize=${pageSize}${filter}${orderBy || ''}`,
				);
			} else {
				response = await api.get(
					`annuity/postingLog/${
						type === 'monthly' ? 'monthly' : 'annual'
					}/list?&PageSize=${pageSize}${filter}${orderBy || ''}`,
				);
			}

			setAnnuitiesPosting({
				...annuitiesPosting,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || annuitiesPosting.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAnnuitiesPosting({
					...annuitiesPosting,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};
	const getPostinLogs = async ({ postingLogs, setPostingLogs, orderBy, id }: GetPostingLogProps) => {
		try {
			setPostingLogs({
				...postingLogs,
				loading: true,
			});

			const response = await api.get(`annuity/postingLog/${id}?${orderBy || ''}`);

			setPostingLogs({
				...postingLogs,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setPostingLogs({
					...postingLogs,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};
	const getPostingPeriods = async ({
		annuitiesPostingPeriods,
		setAnnuitiesPostingPeriods,
		type,
	}: PostingPeriodsProps) => {
		try {
			setAnnuitiesPostingPeriods({
				...annuitiesPostingPeriods,
				loading: true,
			});

			const response = await api.get(
				`annuity/${type === 'monthly' ? 'currentMonthlyPostingPeriods' : 'currentAnnualPostingPeriods'}`,
			);

			setAnnuitiesPostingPeriods({
				...annuitiesPostingPeriods,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAnnuitiesPostingPeriods({
					...annuitiesPostingPeriods,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const generateAnnuitiesReceipt = async (id: number) => {
		try {
			const response = await api.get(`receipt/agreement/${id}/receipt`, {
				responseType: 'blob',
			});
			const url = window.URL.createObjectURL(new Blob([response?.data], { type: 'application/zip' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'receipt.zip');
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
		}
	};

	const getAcknowledgement = async (id: number) => {
		try {
			const response = await api.get(`receipt/agreement/${id}/acknowledgement`, {
				responseType: 'blob',
			});
			const url = window.URL.createObjectURL(new Blob([response?.data], { type: 'application/zip' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'acknowledgement.zip');
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
		}
	};

	const getEFTPayment = async ({ eftPayment, setEftPayment }: GetEFTPayment) => {
		try {
			setEftPayment({
				...eftPayment,
				loading: true,
			});
			const response = await api.get(`annuity/eft/getPayments`);

			setEftPayment({
				...eftPayment,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setEftPayment({
					...eftPayment,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const generateAnnuitiesEFT = async (agreementIds: string) => {
		try {
			const response = await api.get(`annuity/eft/generate?agreementIds=${agreementIds}`, {
				responseType: 'blob',
			});
			const url = window.URL.createObjectURL(new Blob([response?.data], { type: 'application/txt' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `eft.txt`);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
		}
	};

	const exportAnnuitiesCheque = async () => {
		try {
			const response = await api.get(`annuity/cheque/generate`, {
				responseType: 'blob',
			});
			const url = window.URL.createObjectURL(new Blob([response?.data], { type: 'application/csv' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'cheque-export.csv');
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
		}
	};

	return {
		getAnnuities,
		createAnnuity,
		getAgreementTypes,
		getPaymentFrequency,
		getAgreementById,
		updateAnnuity,
		getPaymentHistory,
		postAgreement,
		deleteAgreement,
		getDonorAnnuities,
		getAnnuitiesPosting,
		annuitiesPosting,
		getPostingPeriods,
		getAgreementStatus,
		revokeAgreement,
		updateOverpayment,
		deleteAgreementPayment,
		generateAnnuitiesReceipt,
		getAcknowledgement,
		getEFTPayment,
		generateAnnuitiesEFT,
		exportAnnuitiesCheque,
		getPostinLogs,
		updateCommentAnnuity,
	};
};

export default useAnnuitiesActions;
