export const yearEndHead = [
	{
		id: 's/n',
		label: 'S/N',
	},
	{
		id: 'donor',
		label: 'Donor',
	},
	{
		id: 'donorId',
		label: 'Donor ID',
	},
	{
		id: 'amount',
		label: 'Amount',
	},
	{
		id: 'actions',
		label: '',
	},
];

export const yearEndSuccessfulHead = [
	{
		id: 's/n',
		label: 'S/N',
	},
	{
		id: 'donor',
		label: 'Donor',
	},
	{
		id: 'donorId',
		label: 'Donor ID',
	},
	{
		id: 'amount',
		label: 'Amount',
	},
	{
		id: 'society',
		label: 'Society',
	},
	{
		id: 'actions',
		label: '',
	},
];
