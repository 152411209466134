import React from 'react';
import {
	Add,
	Tag,
	CorporateFare,
	Money,
	Timeline,
	Home,
	LocationOn,
	Public,
	PersonOutline,
	CalendarMonth,
	Cached,
	PlayArrow,
	Percent,
	ChatBubbleOutline,
	Handshake,
} from '@mui/icons-material';

export const hideFields = [
	'agreementNumber',
	'lifeExpectancy',
	'firstPaymentNonTaxablePortion',
	'annualPaymentNonTaxablePortion',
];

export const formFieldData = [
	{
		field: [
			{
				id: 'agreementNumber',
				title: 'Agreement ID',
				icon: <Tag />,
				fieldType: 'text',
				placeHolder: 'ID',
				disabled: true,
				hide: true,
			},
			{
				id: 'agreementTypeId',
				title: 'Agreement Type',
				icon: <CorporateFare />,
				fieldType: 'select',
				menu: [],
			},
			{
				id: 'provinceOfRegistration',
				title: 'Province Of Registration',
				icon: <CorporateFare />,
				fieldType: 'province',
				placeHolder: 'Province Of Registration',
			},
			{
				id: 'paymentMethodId',
				title: 'Payment Method',
				icon: <Money />,
				fieldType: 'select',
			},
			{
				id: 'lifeExpectancy',
				title: 'Life Expectancy',
				icon: <Timeline />,
				fieldType: 'text',
				placeHolder: 'Life Expectancy',
				disabled: true,
				hide: true,
			},
		],
	},
	{
		title: 'Annuitant Details',
		field: [
			{
				id: 'sin',
				title: 'SIN',
				icon: <Home />,
				fieldType: 'password',
				placeHolder: 'SIN',
			},
			{
				id: 'donorId',
				title: 'Donor Id',
				icon: <Home />,
				fieldType: 'text',
				placeHolder: 'Donor Id',
				disabled: true,
			},
			{
				id: 'salutation',
				title: 'Salutation',
				icon: <Home />,
				fieldType: 'text',
				placeHolder: 'Salutation',
				disabled: true,
			},
			{
				id: 'donor',
				title: 'Name',
				icon: <Home />,
				fieldType: 'autocomplete',
				placeHolder: 'Name',
				isSearch: true,
				isDonorAutocompleteSearch: false,
			},
			{
				id: 'genderId',
				title: 'Gender',
				icon: <Add />,
				fieldType: 'select',
				menu: [],
			},
			{
				id: 'maritalStatusId',
				title: 'Marital Status',
				icon: <Add />,
				fieldType: 'select',
				menu: [],
			},
			{
				id: 'dateOfBirth',
				title: 'Date of Birth',
				icon: <Add />,
				fieldType: 'date',
				maxDate: new Date(new Date().setDate(new Date().getDate() - 1)),
			},
			{
				id: 'dateOfDeath',
				title: 'Date of Death',
				icon: <Add />,
				fieldType: 'date',
				disabled: true,
			},
		],
	},
	{
		id: 'coAnnuitant',
		title: 'Co-Annuitant Details',
		hide: true,
		field: [
			{
				id: 'coSin',
				title: 'SIN',
				icon: <Home />,
				fieldType: 'password',
				placeHolder: 'SIN',
			},
			{
				id: 'coDonorId',
				title: 'Donor Id',
				icon: <Home />,
				fieldType: 'text',
				placeHolder: 'Donor Id',
				disabled: true,
			},
			{
				id: 'coSalutation',
				title: 'Salutation',
				icon: <Home />,
				fieldType: 'text',
				placeHolder: 'Salutation',
				disabled: true,
			},
			{
				id: 'coDonor',
				title: 'Name',
				icon: <Home />,
				fieldType: 'autocomplete',
				placeHolder: 'Name',
				isSearch: true,
				isCoAutocompleteSearch: false,
			},
			{
				id: 'coGenderId',
				title: 'Gender',
				icon: <Add />,
				fieldType: 'select',
				menu: [],
			},
			{
				id: 'coMaritalStatusId',
				title: 'Marital Status',
				icon: <Add />,
				fieldType: 'select',
				menu: [],
			},
			{
				id: 'coDateOfBirth',
				title: 'Date of Birth',
				icon: <Add />,
				fieldType: 'date',
				maxDate: new Date(new Date().setDate(new Date().getDate() - 1)),
				disabled: false,
			},
			{
				id: 'coDateOfDeath',
				title: 'Date of Death',
				icon: <Add />,
				fieldType: 'date',
				disabled: true,
			},
		],
	},
	{
		title: 'Address Details',
		field: [
			{
				id: 'streetline1',
				title: 'Streetline 1',
				icon: <LocationOn />,
				fieldType: 'text',
				placeHolder: 'Streetline 1',
				disabled: true,
			},
			{
				id: 'streetline2',
				title: 'Streetline 2',
				icon: <LocationOn />,
				fieldType: 'text',
				placeHolder: 'Streetline 2',
				disabled: true,
			},
			{
				id: 'city',
				title: 'City',
				icon: <Tag />,
				fieldType: 'text',
				placeHolder: 'City',
				disabled: true,
			},
			{
				id: 'province',
				title: 'Province',
				icon: <Tag />,
				fieldType: 'text',
				placeHolder: 'Province',
				disabled: true,
			},
			{
				id: 'postalCode',
				title: 'Postal Code',
				icon: <Tag />,
				fieldType: 'text',
				placeHolder: 'Postal Code',
				disabled: true,
			},
			{
				id: 'country',
				title: 'Country',
				icon: <Public />,
				fieldType: 'text',
				placeHolder: 'Country',
				disabled: true,
			},
		],
	},
	{
		title: 'Bank Details',
		field: [
			{
				id: 'bankName',
				title: 'Name',
				icon: <Home />,
				fieldType: 'text',
				placeHolder: 'Name',
			},
			{
				id: 'bankAddress',
				title: 'Address',
				icon: <LocationOn />,
				fieldType: 'text',
				placeHolder: 'Address',
			},
			{
				id: 'bankNumber',
				title: 'Bank Number',
				icon: <CorporateFare />,
				fieldType: 'text',
				placeHolder: 'Bank Number',
			},
			{
				id: 'bankBranchNumber',
				title: 'Bank Branch Number',
				icon: <Home />,
				fieldType: 'text',
				placeHolder: 'Bank Branch Number',
			},
			{
				id: 'bankAccountNumber',
				title: 'Bank Account Number',
				icon: <Add />,
				fieldType: 'text',
				placeHolder: 'Bank Account Number',
			},
			{
				id: 'bankPayableTo',
				title: 'Payable To',
				icon: <PersonOutline />,
				fieldType: 'text',
				placeHolder: 'Payable To',
			},
		],
	},
	{
		title: 'Purchase',
		field: [
			{
				id: 'receivedAmount',
				title: 'Received Amount',
				icon: <Money />,
				fieldType: 'number',
				placeHolder: 'Received Amount',
			},
			{
				id: 'receiptedAmount',
				title: 'Receipt Amount',
				icon: <Money />,
				fieldType: 'number',
				placeHolder: 'Receipt Amount',
			},
			{
				id: 'dateReceived',
				title: 'Date Received',
				icon: <CalendarMonth />,
				fieldType: 'date',
			},
		],
	},
	{
		title: 'Annuity Payment',
		field: [
			{
				id: 'paymentFrequencyId',
				title: 'Payment Frequency',
				icon: <Cached />,
				fieldType: 'select',
				placeHolder: 'Payment Frequency',
			},
			{
				id: 'firstPaymentDate',
				title: 'First Payment Date',
				icon: <CalendarMonth />,
				fieldType: 'date',
			},
			{
				id: 'firstAnnuityPayment',
				title: 'First Annuity Payment',
				icon: <PlayArrow />,
				fieldType: 'number',
				placeHolder: 'First Annuity Payment',
			},
			{
				id: 'periodicPayment',
				title: 'Periodic Payment',
				icon: <Money />,
				fieldType: 'number',
				placeHolder: 'Periodic Payment',
			},
			{
				id: 'annualPayment',
				title: 'Annual Payment',
				icon: <Money />,
				fieldType: 'number',
				placeHolder: 'Annual Payment',
			},
			{
				id: 'annualRate',
				title: 'Annual Rate',
				icon: <Percent />,
				fieldType: 'number',
				placeHolder: 'Annual Rate',
			},
		],
	},
	{
		title: 'Tax Related',
		field: [
			{
				id: 'taxPortionAnnuity',
				title: 'Tax Portion Annuity',
				icon: <Percent />,
				fieldType: 'number',
				placeHolder: 'Tax Portion Annuity',
			},
			{
				id: 'firstPaymentTaxableAmount',
				title: 'First Payment Taxable Amount',
				icon: <Money />,
				fieldType: 'number',
				placeHolder: 'First Payment Taxable Amount',
			},
			{
				id: 'firstPaymentNonTaxablePortion',
				title: 'First Payment Non Taxable Portion',
				icon: <Money />,
				fieldType: 'number',
				placeHolder: 'First Payment Non Taxable Portion',
				disabled: true,
				hide: true,
			},
			{
				id: 'annualPaymentTaxableAmount',
				title: 'Annual Payment Taxable Amount',
				icon: <Money />,
				fieldType: 'number',
				placeHolder: 'Annual Payment Taxable Amount',
			},
			{
				id: 'annualPaymentNonTaxablePortion',
				title: 'Annual Payment Non Taxable Portion',
				icon: <Money />,
				fieldType: 'number',
				placeHolder: 'Annual Payment Non Taxable Portion',
				disabled: true,
				hide: true,
			},
		],
	},
	{
		title: 'Account',
		hide: true,
		field: [
			{
				id: 'accountBalance',
				title: 'Account Balance',
				icon: <Money />,
				fieldType: 'number',
				placeHolder: 'Account Balance',
				disabled: true,
			},
			{
				id: 'accountDate',
				title: 'Account Date',
				icon: <CalendarMonth />,
				fieldType: 'date',
				placeHolder: 'Account Date',
				disabled: true,
			},
			{
				id: 'acturialValue',
				title: 'Acturial Value',
				icon: <Money />,
				fieldType: 'text',
				placeHolder: 'Acturial Value',
				disabled: true,
			},
			{
				id: 'valuationDate',
				title: 'Valuation Date',
				icon: <CalendarMonth />,
				fieldType: 'date',
				disabled: true,
			},
		],
	},
	{
		title: 'Beneficiary',
		field: [
			{
				id: 'beneficiarySocietyId',
				title: 'Beneficiary Society',
				icon: <Handshake />,
				fieldType: 'select',
				placeHolder: 'Beneficiary Society',
			},
			{
				id: 'comment',
				title: 'Comment',
				icon: <ChatBubbleOutline />,
				fieldType: 'textArea',
				placeHolder: 'Comment',
			},
		],
	},
];
