import React, { useState, useEffect } from 'react';
import { StateType } from 'contexts/intialstates/Types';
import useAction, {  DonorNotesData } from 'hooks/useNotesActions';
import NotesContent from 'Components/NotesLayout';

interface P {
	donorId?: number;
}

const DonorNotes = ({ donorId }: P) => {
	const [notes, setNotes] = useState<StateType<DonorNotesData>>({
		error: null,
		loading: false,
		data: null,
	});
	const [isReload, setIsReload] = useState(false);
	const { getNotes, createNotes, deleteNotes, updateNotes } = useAction();

	useEffect(() => {
		if (!donorId) return;
		getNotes({ id: donorId, notes, setNotes, type: 'donor' });
	}, [isReload, donorId]);

	const handleDelete = async (id: number): Promise<boolean> => {
		return await deleteNotes(id);
	};

	const handleSubmit = async (note: string, id?: number): Promise<boolean> => {
		if (!donorId) return false;
		let isSucess;
		if (id) {
			isSucess = await updateNotes(id, note);
		} else {
			isSucess = await createNotes(donorId, note, 'donor');
		}
		return isSucess;
	};

	return (
		<NotesContent
			notes={notes}
			handleDeleteNotes={handleDelete}
			handleSubmitNotes={handleSubmit}
			setIsReload={setIsReload}
			isReload={isReload}
		/>
	);
};

export default DonorNotes;
