import React from 'react';
import { Add } from '@mui/icons-material';
import { Button, Paper, Stack, TablePagination, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { pagination } from 'Components/Common/Tables/Tables';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { OrderType, StateType } from 'contexts/intialstates/Types';
import { convertDate } from 'helpers/dayAndTime';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import { sortTable } from 'Components/Common/Tables/sortTable';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import useUserActions from 'hooks/useUserActions';
import useActivityLogActions, { Acitvity, ActivitiesType } from 'hooks/useActivityLog';

export const activityLogTableHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'recordType',
		label: 'Module',
		minWidth: 290,
		align: 'center',
		filterType: 'list',
		list: [
			{ name: 'donor', label: 'Donor' },
			{ name: 'hcaOrder', label: 'HCA Order' },
			{ name: 'deposit', label: 'Donations' },
			{ name: 'walkathon', label: 'Walkathon' },
			{ name: 'batch', label: 'Batch' },
			{ name: 'depositTemplate', label: 'Recurring Donations' },
			{ name: 'agreement', label: 'Agreement' },
			{ name: 'lifeExpectancyAdjustment', label: 'Life Expectancy' },
			{ name: 'interestRate', label: 'Interest Rate' },
			{ name: 'donationType', label: 'Donation Type' },
			{ name: 'userManagement', label: 'User Management' },
		],
	},
	{
		id: 'originalRecordId',
		label: 'Record ID',
		minWidth: 290,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'date',
		label: 'Date',
		minWidth: 290,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'details',
		label: 'Activity Details',
		minWidth: 290,
		align: 'center',
	},
];

const ActivityLog = () => {
	const navigate = useNavigate();
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);

	const { handleRoles } = useUserActions();
	const { getActivityLogs } = useActivityLogActions();
	const [activities, setActivities] = React.useState<StateType<ActivitiesType>>({
		data: null,
		error: null,
		loading: false,
	});
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [filterPayload, setFilterPayload] = React.useState<Array<any>>([]);
	const [reload, setIsReload] = React.useState(false);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);

	React.useEffect(() => {
		getActivityLogs({
			page,
			pageSize,
			activities,
			setActivities,
			orderBy,
			filterPayload,
		});
	}, [page, pageSize, orderBy, reload, orderBy, filterPayload]);

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleClick = (activity: Acitvity) => {
		if (!activity) return;
		if (activity?.recordType === 'Donor') {
			navigate(`/donors/${activity?.originalRecordId}?activityId=${activity?.activityId}`);
		}
	};

	const getActivityDetails = (activity: Acitvity) => {
		if (!activity) return;
		if (activity?.activityType === 'Create') {
			return `${activity?.user?.name || ''} added a new record`;
		}
		if (activity?.activityType === 'Update') {
			return `${activity?.user?.name || ''} updated the record`;
		}
		if (activity?.activityType === 'Delete') {
			return `${activity?.user?.name || ''} deleted the record`;
		}
		return '';
	};
	function pascalToSpace(pascalStr: string) {
		let result = '';
		for (let i = 0; i < pascalStr.length; i++) {
			if (i === 0) {
				result += pascalStr[i];
			} else if (pascalStr[i] === pascalStr[i].toUpperCase()) {
				result += ' ' + pascalStr[i];
			} else {
				result += pascalStr[i];
			}
		}
		return result;
	}

	const getTableData = () => {
		return activities?.data?.body?.length
			? activities?.data?.body?.map((row, index: number) => {
					return {
						activityId: row?.activityId,
						's/n': page * pageSize + index + 1,
						recordType: row?.recordType ? pascalToSpace(row?.recordType) : '-',
						originalRecordId: row?.originalRecordId || '-',
						date: row?.date ? convertDate(row?.date) : '-',
						details: getActivityDetails(row),
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column: any) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
							<span
								style={{
									cursor: 'pointer',
									margin: '10px',
								}}
								onClick={() => {
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									});
								}}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							reload={reload}
							setIsReload={setIsReload}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							setPage={setPage}
							page={page}
						/>
					</>
				),
			};
		});
	};
	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	return (
		<>
			<Stack
				alignItems="center"
				direction={{ xs: 'column', sm: 'row' }}
				justifyContent={'space-between'}
				spacing={1}
				mb={5}
			>
				<Stack direction="column">
					<Stack direction="row" spacing={4} alignItems="center">
						<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
							Activity Log
						</Typography>
					</Stack>
					<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
						View Activities log here.
					</Typography>
				</Stack>
				<Stack sx={{ mb: 3 }} direction="row" alignItems={'center'} justifyContent="flex-end">
					<TrimTables
						name={'activityLogTableHead'}
						list={activityLogTableHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>

					<FilterListButton
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
						reload={reload}
						setIsReload={setIsReload}
						setPage={setPage}
						page={page}
					/>
				</Stack>
			</Stack>

			<Stack justifyContent="center" alignItems="center">
				<Paper sx={{ width: '100%', overflow: 'hidden' }}>
					<CustomTable
						loading={activities?.loading}
						columnData={getColumnData()}
						tableData={getTableData()}
						handleDrag={handleDrag}
						handleResize={handleResize}
						emptyMessage={'No receipt template available'}
						handleRowClick={(row) => handleClick(row as Acitvity)}
					/>
					{(activities?.data?.body?.length as number) > 0 && (
						<TablePagination
							rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
							component="div"
							count={activities?.data?.totalRecords as number}
							rowsPerPage={pageSize}
							page={page}
							sx={pagination}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					)}
				</Paper>
			</Stack>
		</>
	);
};

export default ActivityLog;
