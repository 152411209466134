import * as React from 'react';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate } from 'react-router-dom';
import { convertDate } from 'helpers/dayAndTime';
import { pagination } from 'Components/Common/Tables/Tables';
import { Stack, TablePagination, Tooltip } from '@mui/material';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import FormatCurrency from 'helpers/FormatCurrency';
import { GlobalContexts } from 'contexts/GlobalContext';
import { DefaultState } from 'Components/Batch/Batch';
import { ApiResponse, Deposits, StateType } from 'contexts/intialstates/Types';
import { sortTable } from 'Components/Common/Tables/sortTable';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import useDepositActions from 'hooks/useDepositActions';
import TableFiltering, { DonorFiltering } from 'Components/Reusable/TableFiltering/TableFiltering';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';

export interface ConfirmationModalState {
	open: boolean;
	id: null | number | string;
}

export const canDeleteDeposit = (deposit: Deposits) => {
	if (deposit?.itemStatus?.status === 'Posted') {
		return { invalid: true, message: 'Cannot delete posted donations' };
	}
	return { invalid: false, message: 'Delete' };
};

export const toggleColumn = (trimList: ColumnType[], label: string) => {
	const columnIndex = trimList.findIndex((item: ColumnType) => item.id === label);

	if (columnIndex > -1 && trimList[columnIndex].isChecked) return true;
	return false;
};

export const handleReducer = (total: number, trimItem: ColumnType) => {
	if (trimItem.isChecked) {
		return total + 1;
	} else {
		return total + 0;
	}
};
const HcaDonationsList = ({
	depoInfo,
	pageSize,
	page,
	setPageSize,
	setPage,
	batchId,
	loading,
	label,
	orderType,
	setLabel,
	setOrderType,
	setOderBy,
	setIsReload,
	reload,
	setFilterPayload,
	filterPayload,
	setSearchDonor,
	searchDonor,
	trimList,
	setTrimList,
}: any) => {
	const location = useLocation();
	const isHCADonations = location.pathname.includes('hca-donations');
	const { deleteSingleDeposit } = useDepositActions();
	const { setErrorSnack } = React.useContext(GlobalContexts);
	const [defaultstate, setDefaultstate] = React.useState<DefaultState>({ id: '', open: false });

	const [deleteDeposit, setDeleteDeposit] = React.useState<StateType<ApiResponse>>({
		data: null,
		error: null,
		loading: false,
	});

	const navigate = useNavigate();

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleClose = () => setDefaultstate({ ...defaultstate, open: false });

	const cancelDeleteDepositById = () => {
		setDefaultstate({ ...defaultstate, id: '', open: false });
	};

	const data = Array.isArray(depoInfo) ? depoInfo : depoInfo?.data?.body;

	const editDeposit = (row: Deposits) => {
		if (isHCADonations) {
			navigate(`/hca/hca-donations/${row?.id}`);
			return;
		}
		if (row?.isWalkathon === true) {
			navigate(`/hca/walkathon/${row?.id}`);
			return;
		}
		if (batchId) {
			navigate(`/bookkeeping/deposits/${row?.id}/batch/${batchId}`);
		} else {
			navigate(`/bookkeeping/deposits/${row?.id}`);
		}
	};

	const handleDelete = async () => {
		await deleteSingleDeposit({
			depositId: defaultstate?.id as number,
			deleteDeposit,
			setDeleteDeposit,
			setErrorSnack,
		});
		cancelDeleteDepositById();
		setIsReload(!reload);
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType) => {
			return Object.assign(
				{
					...column,
				},
				column.id !== 'societies' && {
					data: (
						<>
							<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
								<span
									style={{
										cursor: 'pointer',
										margin: '10px',
									}}
									onClick={() => {
										sortTable({
											column: column.id,
											setLabel,
											setOderBy,
											setOrderType,
											orderType,
											label,
										});
									}}
								>
									{column.label}
									{column.id == label && <OrderTypeArrow orderType={orderType} />}
								</span>
							</Tooltip>
							<>
								<DonorFiltering
									searchDonor={searchDonor}
									setSearchDonor={setSearchDonor}
									filterType={column.filterType}
								/>
								<TableFiltering
									field={column.id}
									filterType={column.filterType}
									list={column.list}
									reload={reload}
									setIsReload={setIsReload}
									setFilterPayload={setFilterPayload}
									filterPayload={filterPayload}
								/>
							</>
						</>
					),
				},
			);
		});
	};

	const getDonorName = (row: Deposits) => {
		if (!row) return 'Anonymous';
		if (row?.donor?.firstName || row?.donor?.lastName)
			return `${row?.donor.firstName ?? ''} ${row?.donor.lastName ?? ''}`;
		if (row?.donor?.firstName) return `${row?.donor.firstName ?? ''}`;
		if (row?.donor?.lastName) return `${row?.donor.lastName ?? ''}`;
		if (row?.diocese) return `${row?.diocese?.dioceseName ?? ''}`;
		if (row?.schoolBoard) return `${row?.schoolBoard?.name ?? ''}`;
		if (row?.school) return `${row?.school?.name ?? ''}`;
		if (row?.parish) return `${row?.parish?.parishName ?? ''}`;
		if (row?.donor?.orgName) return `${row?.donor.orgName ?? ''}`;
		return 'Anonymous';
	};
	const getDonorCode = (row: Deposits) => {
		if (row?.donor?.referenceNumber) return `${row?.donor?.referenceNumber ?? ''}`;
		if (row?.diocese?.code) return `${row?.diocese?.code ?? ''}`;
		if (row?.parish?.parishCode) return `${row?.parish?.parishCode ?? ''}`;
		if (row?.schoolBoard?.code) return `${row?.schoolBoard?.code ?? ''}`;
		if (row?.school?.code) return `${row?.school?.code ?? ''}`;
		return 'N/A';
	};
	const getTableData = () => {
		return data?.length
			? data.map((row: any, index: number) => {
					return {
						id: row.id,
						's/n': page * pageSize + index + 1,
						donor: getDonorName(row?.deposit),
						acknowledged: `${row?.acknowledged}` || 'N/A',
						referencenumber: getDonorCode(row?.deposit),
						creditAmount: row?.deposit?.creditAmount ? (
							<FormatCurrency value={row?.deposit?.creditAmount} />
						) : (
							'N/A'
						),
						paymentMethod: row?.deposit?.paymentMethod?.name ? row?.deposit?.paymentMethod?.name : 'N/A',
						itemStatus: row?.deposit?.itemStatus ? row?.deposit?.itemStatus?.status : 'N/A',
						isWalkathon: row?.deposit?.isWalkathon ? 'Yes' : 'No',
						creditDate: `${row?.deposit?.creditDate ? convertDate(row?.deposit?.creditDate) : 'N/A'}`,
						postDate: `${row?.deposit?.postDate ? convertDate(row?.deposit?.postDate) : 'N/A'}`,
						batchNumber: row?.deposit?.batch?.batchNumber ? row?.deposit?.batch?.batchNumber : 'N/A',
						chequeNumber: row?.deposit?.chequeNumber || 'N/A',
						accountNumber: row?.deposit?.accountNumber || 'N/A',
						societies: row?.deposit?.societies?.join(',') || 'N/A',
						created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
						updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
						depositCreated: row?.deposit?.created ? `${convertDate(row?.deposit?.created)}` : 'N/A',
						depositUpdated: row?.deposit?.updated ? `${convertDate(row?.deposit?.updated)}` : 'N/A',
						createdBy: row?.deposit?.createdBy?.name || 'N/A',
						updatedBy: row?.deposit?.updatedBy?.name || 'N/A',
					};
			  })
			: [];
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	return (
		<>
			<Stack justifyContent="center" alignItems="center">
				<ConfirmationModal
					handleClose={handleClose}
					message={'Are you sure you want to delete this Deposit?'}
					open={defaultstate?.open}
					handleConfirmation={handleDelete}
					handleCancel={cancelDeleteDepositById}
					loading={deleteDeposit?.loading}
				/>
				<>
					<Paper sx={{ width: '100%', overflow: 'hidden' }}>
						<CustomTable
							loading={loading}
							columnData={getColumnData()}
							tableData={getTableData()}
							handleDrag={handleDrag}
							handleResize={handleResize}
							emptyMessage={'No deposits available'}
							handleRowClick={(row) => editDeposit(row as Deposits)}
						/>

						{depoInfo?.data && data?.length > 0 && !batchId && (
							<TablePagination
								rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
								component="div"
								count={depoInfo?.data?.totalRecords}
								rowsPerPage={pageSize}
								page={page}
								sx={pagination}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
						)}
					</Paper>
				</>
			</Stack>
		</>
	);
};
export default HcaDonationsList;
