import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import { ArrowDropDown, Cancel } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

export const fieldName = (field: string) => {
	const result = field.replace(/([A-Z])/g, ' $1');
	const newColName = result.charAt(0).toUpperCase() + result.slice(1);
	return newColName;
};

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 250,
		px: 2,
		color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			},
		},
	},
}));

interface Props {
	filterPayload: any[];
	setFilterPayload: React.Dispatch<React.SetStateAction<any[]>>;
	setIsReload?: React.Dispatch<React.SetStateAction<boolean>>;
	reload?: boolean;
	setPage?: React.Dispatch<React.SetStateAction<number>>;
	page?: number;
}

const FilterListButton = ({ filterPayload, setFilterPayload, setIsReload, reload, page, setPage }: Props) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleFilterChange = () => {
		if (page === 0) {
			setIsReload?.(!reload);
		} else {
			setPage?.(0);
		}
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClear = () => {
		setFilterPayload([]);
		setAnchorEl(null);
		handleFilterChange();
	};
	const handleCancel = () => {
		setAnchorEl(null);
	};
	const removeFilter = (id: any) => {
		let tempPayload = filterPayload;

		tempPayload = tempPayload.filter((ele) => ele.field !== id);
		setFilterPayload(tempPayload);
		setAnchorEl(null);
		handleFilterChange();
	};

	const output = (
		<>
			{filterPayload.length > 0 && (
				<Button
					endIcon={<ArrowDropDown />}
					color="primary"
					variant="contained"
					sx={{ textTransform: 'none', mr: 2, ml: 2 }}
					onClick={handleClick}
				>
					Filters
				</Button>
			)}

			<StyledMenu
				id="demo-customized-menu"
				MenuListProps={{
					'aria-labelledby': 'demo-customized-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<Stack sx={{ mt: 2 }}>
					{filterPayload.map((ele) => (
						<Stack
							key={ele.field}
							direction="row"
							justifyContent="space-between"
							alignItems={'center'}
							sx={{ p: 1 }}
						>
							<Box sx={{ fontWeight: 600, textTransform: 'capitalize' }}>{fieldName(ele.field)}</Box>
							<Button onClick={() => removeFilter(ele.field)}>
								<Cancel />
							</Button>
						</Stack>
					))}
				</Stack>

				<Divider sx={{ mb: 0.5, mt: 3 }} />
				<Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
					<Button variant="contained" onClick={handleClear} sx={{ fontSize: 12 }}>
						Clear
					</Button>
					<Button onClick={handleCancel} sx={{ fontSize: 12, color: 'black', fontWeight: 600 }}>
						Cancel
					</Button>
				</Stack>
			</StyledMenu>
		</>
	);

	return <>{output}</>;
};

export default FilterListButton;
