import React, { useState } from 'react';
import { Stack, Button, Typography, Box } from '@mui/material';
import { Add } from '@mui/icons-material';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import InterestRateTable, { interestRateHead } from './InterestRateTable';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import CreateEditInterestRate from 'Components/Annuities/InterestRate/CreateEditInterestRate';
import useInterestRateActions, { InterestRateData } from 'hooks/useInterestRateActions';
import useUserActions from 'hooks/useUserActions';

const InterestRate = () => {
	const { handleRoles } = useUserActions();
	const { deleteInterestRate } = useInterestRateActions();
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [filterPayload, setFilterPayload] = useState<Array<any>>([]);
	const [visible, setVisible] = useState(false);
	const [selectedData, setSelectedData] = useState<InterestRateData>();
	const [isDelete, setIsDelete] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [reload, setReload] = useState(false);
	const [page, setPage] = useState(0);

	const handleCloseEdit = () => {
		setVisible(false);
		setSelectedData(undefined);
	};

	const handleOpenEdit = (data: InterestRateData) => {
		setVisible(true);
		setSelectedData(data);
	};

	const handleOpenDelete = (data: InterestRateData) => {
		setIsDelete(true);
		setSelectedData(data);
	};

	const handleCloseDelete = () => {
		setVisible(false);
		setSelectedData(undefined);
	};

	const handleCloseConfirmation = () => {
		setIsDelete(false);
	};

	const handleDelete = async () => {
		if (selectedData) {
			setDeleteLoading(true);
			const isSucess = await deleteInterestRate(selectedData.id);
			setDeleteLoading(false);
			handleCloseDelete();
			handleCloseConfirmation();
			if (isSucess) {
				setReload(!reload);
			}
		}
	};

	return (
		<Stack>
			<Stack
				alignItems="center"
				direction={{ xs: 'column', sm: 'row' }}
				justifyContent={'space-between'}
				spacing={1}
				mb={5}
			>
				<Stack direction="column">
					<Stack direction="row" spacing={4} alignItems="center">
						<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
							Interest Rate
						</Typography>
					</Stack>
					<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
						Create and manage interest rate here.
					</Typography>
				</Stack>
				<Stack sx={{ mb: 3 }} direction="row" alignItems={'center'} justifyContent="flex-end">
					<TrimTables
						name="interestRateHead"
						list={interestRateHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
					{handleRoles('annuities-actions') && (
						<Button
							onClick={() => setVisible(true)}
							sx={{ textTransform: 'none', mr: 2 }}
							startIcon={<Add />}
							variant="contained"
						>
							Add Interest Rate
						</Button>
					)}
					<FilterListButton
						reload={reload}
						setIsReload={setReload}
						setPage={setPage}
						page={page}
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
					/>
				</Stack>
			</Stack>
			<InterestRateTable
				trimList={trimList}
				setTrimList={setTrimList}
				filterPayload={filterPayload}
				setFilterPayload={setFilterPayload}
				handleOpenEdit={handleOpenEdit}
				reload={reload}
				setReload={setReload}
				setPage={setPage}
				page={page}
			/>
			{visible && (
				<BasicModal
					onClose={handleCloseEdit}
					visible={visible}
					title={selectedData ? 'Edit interest rate' : 'Add a interest rate'}
					data={selectedData || undefined}
					handleOpenDelete={handleOpenDelete}
					isClose={false}
					noDelete={handleRoles('annuities-actions')}
				>
					<CreateEditInterestRate
						selectedEditData={selectedData}
						handleClose={handleCloseEdit}
						reload={reload}
						setReload={setReload}
					/>
				</BasicModal>
			)}
			{isDelete && (
				<ConfirmationModal
					handleClose={handleCloseConfirmation}
					message={'Are you sure you want to delete this intrest rate?'}
					open={isDelete}
					handleConfirmation={handleDelete}
					handleCancel={handleCloseConfirmation}
					loading={deleteLoading}
				/>
			)}
		</Stack>
	);
};

export default InterestRate;
