import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Title } from './CreateDeposit.style';
import { DonationFormValues } from './Types';
import { Grid, MenuItem, Select, Stack, TextField, Autocomplete, Box } from '@mui/material';
import { useGlobalStyles } from 'Components/Common/global/global';
import { ConfirmationNumber, Paid, PausePresentation } from '@mui/icons-material';
import { GlobalContexts } from 'contexts/GlobalContext';
import useSocietyActions from 'hooks/useSocietyAction';
import usePaymentmethodsActions from 'hooks/usePaymentMethodAction';
import useAppealActions from 'hooks/useAppealActions';
import useDonationTypesActions from 'hooks/useDonationTypesActions';
import { Appeal, DonationTypes, PaymentMethod, Society, StateType } from 'contexts/intialstates/Types';
import useDebounce from 'Components/Reusable/hooks/debounce';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			<Title>{children}</Title>
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

interface DonationModalProps {
	open: boolean;
	closeModal: () => void;
	saveDonation: (donation: DonationFormValues) => void;
	donation?: DonationFormValues;
	donations?: Array<DonationFormValues>;
	triggerRefresh: boolean;
	setTriggerRefresh: React.Dispatch<React.SetStateAction<boolean>>;
	isDisabled: (isHCA?: string) => boolean;
}

const DonationModal = ({
	triggerRefresh,
	setTriggerRefresh,
	open,
	closeModal,
	donation,
	saveDonation,
	donations,
	isDisabled,
}: DonationModalProps) => {
	const { setSimpleErrorSnack } = useContext(GlobalContexts);

	const [formErrors, setFormErrors] = useState<string[]>([]);
	const { getSocieties } = useSocietyActions();
	const { getPaymentMethods } = usePaymentmethodsActions();
	const { getAllAppeals } = useAppealActions();
	const { getDonationTypeBySearch } = useDonationTypesActions();
	const [allSociety, setAllSoceity] = React.useState<StateType<Society[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [allPM, setPM] = React.useState<StateType<PaymentMethod[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [allAppeals, setAllAppeals] = useState<StateType<Appeal[]>>({
		data: null,
		loading: false,
		error: null,
	});

	const global = useGlobalStyles();
	const [formData, setFormData] = useState<DonationFormValues>();
	const [donationTypeSearchTerm, setDonationTypeSearchTerm] = useState('');
	const debouncedDonationSearchTerm = useDebounce(donationTypeSearchTerm, 1000);
	const [donationType, setDonationType] = useState<StateType<DonationTypes[]>>({
		data: null,
		loading: false,
		error: null,
	});

	useEffect(() => {
		if (!debouncedDonationSearchTerm) return;
		getDonationTypeBySearch({ donationType, setDonationType, searchTerm: debouncedDonationSearchTerm });
	}, [debouncedDonationSearchTerm]);

	useEffect(() => {
		setFormData(donation ?? {});
	}, [donation, open]);

	console.log('---dona', donation);

	useEffect(() => {
		getSocieties({ allSociety, setAllSoceity });
		getPaymentMethods({ allPM, setPM });
		getAllAppeals({ setAllAppeals, allAppeals });
	}, []);

	const allSocieties = allSociety?.data;
	const allAppeal = allAppeals?.data;
	const [societyIds, setSocietyIds] = React.useState<Array<number | undefined>>([]);

	const updateFormValue = (key: string) => (e: any) => {
		const temp = { ...formData, [key]: e.target.value };
		setFormData(temp);
		if (formErrors.includes(key)) {
			setFormErrors(formErrors.filter((item) => item !== key));
		}
	};

	const updateDonationTypeFormValue = (val: any) => {
		const temp = { ...formData, donationType: val };
		setFormData(temp);
		if (formErrors.includes('donationType')) {
			setFormErrors(formErrors.filter((item) => item !== 'donationType'));
		}
	};

	React.useEffect(() => {
		let societyIdArr: Array<number | undefined> = [];
		donations?.forEach((donation) => {
			societyIdArr = [...societyIdArr, donation?.societyId];
		});
		setSocietyIds(societyIdArr);
	}, [donations?.length, donations, triggerRefresh]);
	const handleSubmit = () => {
		if (!formData) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'Please fill out the fields.',
			});
			return;
		}
		const errors = [];
		if (!formData.donationType) {
			errors.push('donationType');
		}
		if (!formData.contribution) {
			errors.push('contribution');
		}
		if (!formData.societyId) {
			errors.push('societyId');
		}

		setFormErrors(errors);
		if (errors.length) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'Please fill all the required fields.',
			});
			return;
		}
		setTriggerRefresh(!triggerRefresh);
		saveDonation(formData);
	};
	return (
		<div style={{ padding: '0 15px' }}>
			<BootstrapDialog onClose={closeModal} aria-labelledby="customized-dialog-title" fullWidth open={open}>
				<BootstrapDialogTitle id="customized-dialog-title" onClose={closeModal}>
					{donation?.id && donation?.id >= 0 ? 'Update Donation' : 'Create Donation'}
				</BootstrapDialogTitle>
				<DialogContent sx={{ px: 8 }}>
					<Stack direction="row" justifyContent="space-between" py={2}>
						<Stack>
							<Grid container>
								<Grid item>
									<Paid className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Contribution Amount
									</Typography>
								</Grid>
							</Grid>
							<TextField
								sx={{ minWidth: 250 }}
								value={formData?.contribution}
								onChange={updateFormValue('contribution')}
								disabled={isDisabled()}
							/>
							{formErrors.includes('contribution') && (
								<span className={global.errorText}>Contribution Amount is required</span>
							)}
						</Stack>
						<Stack mb={2}>
							<Grid container>
								<Grid item>
									<ConfirmationNumber className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Society
									</Typography>
								</Grid>
							</Grid>
							{allSocieties && (
								<>
									<Select
										disabled={isDisabled()}
										sx={{ minWidth: '250px' }}
										placeholder="Society"
										value={formData?.societyId}
										onChange={updateFormValue('societyId')}
									>
										{(allSocieties || [])
											?.filter((curr) => curr.code !== 'AN')
											.map((x: { id: number; code: string }) => (
												<MenuItem disabled={societyIds.includes(x.id)} key={x.id} value={x.id}>
													{x.code}
												</MenuItem>
											))}
									</Select>
									{formErrors.includes('societyId') && (
										<span className={global.errorText}>Society is required</span>
									)}
								</>
							)}
						</Stack>
					</Stack>
					<Stack direction="column" my={2} py={2}>
						<Stack direction="row" flexWrap="wrap" justifyContent="space-between">
							<Stack>
								<Grid container>
									<Grid item>
										<ConfirmationNumber className={global.formLabelIcon} />
									</Grid>
									<Grid item>
										<Typography variant="body2" className={global.formLabelText}>
											Appeal Code
										</Typography>
									</Grid>
								</Grid>
								{allAppeal && (
									<>
										<Select
											disabled={isDisabled()}
											sx={{ minWidth: '250px' }}
											placeholder="Appeal Code"
											value={formData?.appealId}
											onChange={updateFormValue('appealId')}
										>
											{(allAppeal || []).map((x: { id: number; code: string }) => (
												<MenuItem key={x.id} value={x.id}>
													{x.code}
												</MenuItem>
											))}
										</Select>
										{formErrors.includes('appealId') && (
											<span className={global.errorText}>Appeal Code is required</span>
										)}
									</>
								)}
							</Stack>
							<Stack>
								<Grid container>
									<Grid item>
										<ConfirmationNumber className={global.formLabelIcon} />
									</Grid>
									<Grid item>
										<Typography variant="body2" className={global.formLabelText}>
											Donation Type
										</Typography>
									</Grid>
								</Grid>
								<>
									<Autocomplete
										freeSolo
										value={formData?.donationType?.label || ''}
										loading={donationType.loading}
										loadingText="loading..."
										disabled={isDisabled()}
										onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
											updateDonationTypeFormValue(val);
										}}
										fullWidth
										options={(donationType?.data || []).map((donation: DonationTypes) => ({
											id: donation.id,
											label: `${donation.type} - ${donation.description}`,
										}))}
										sx={{ width: '250px' }}
										renderOption={(props, option) => (
											<Box component="li" {...props} key={option.id}>
												{option.label}
											</Box>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label=""
												InputProps={{
													...params.InputProps,
												}}
												onChange={(event: any) => {
													setDonationTypeSearchTerm(event.target.value);
												}}
												placeholder={'Search donation type'}
											/>
										)}
									/>
									{formErrors.includes('donationType') && (
										<span className={global.errorText}>Donation Type is required</span>
									)}
								</>
							</Stack>
						</Stack>
					</Stack>
					<Stack direction="column" my={2} py={2}>
						<Stack direction="row" flexWrap="wrap" justifyContent="space-between">
							<Stack>
								<Grid container>
									<Grid item>
										<ConfirmationNumber className={global.formLabelIcon} />
									</Grid>
									<Grid item>
										<Typography variant="body2" className={global.formLabelText}>
											Receipt Number
										</Typography>
									</Grid>
								</Grid>
								<TextField sx={{ minWidth: 250 }} value={donation?.receiptNum || 'N/A'} disabled />
							</Stack>
						</Stack>
					</Stack>
					<Stack direction="column" my={2} py={2} justifyContent="space-between" width="100%">
						<Stack>
							<Grid container>
								<Grid item>
									<PausePresentation className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Remarks
									</Typography>
								</Grid>
							</Grid>
							<TextField
								value={formData?.remark || ''}
								multiline
								rows={4}
								onChange={updateFormValue('remark')}
							/>
						</Stack>
					</Stack>

					<Button fullWidth type="submit" color="primary" variant="contained" onClick={() => handleSubmit()}>
						{donation?.id && donation?.id >= 0 ? 'Update Donation' : 'Create Donation'}
					</Button>
				</DialogContent>
			</BootstrapDialog>
		</div>
	);
};

export default DonationModal;
