import { useContext } from 'react';
import {
	ResponseError,
	StateType,
	TimeStamp,
	TimeStampInfo,
	FilterPayload,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';
import { GiftTypeValues } from 'Components/AdminPanel/GiftType/CreateEditGiftType';

export interface ActivitiesType {
	totalRecords: number;
	body: Acitvity[];
}

export interface GetActiviesProps {
	activities: StateType<ActivitiesType>;
	setActivities: React.Dispatch<React.SetStateAction<StateType<ActivitiesType>>>;
	page: number;
	pageSize: number;
	orderBy?: string;
	filterPayload?: FilterPayload[];
}

export interface Acitvity {
	activityId: number;
	originalRecordId: number;
	date: string;
	activityType: string;
	recordType: string;
	user: {
		id: number;
		name: string;
		created: string;
		updated: string;
	};
	changes: {
		fieldName: string;
		oldValue: string;
		newValue: string;
	}[];
}

export interface GiftTypeData extends TimeStamp, TimeStampInfo {
	id: number;
	type: string;
	description: string;
	archived: boolean;
}

interface GetDonationTypeSearch {
	giftTypeSearch: StateType<GiftTypeData[]>;
	setGiftTypeSearch: React.Dispatch<React.SetStateAction<StateType<GiftTypeData[]>>>;
	searchTerm: string;
}

const useActivityLogActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = useContext(GlobalContexts);

	const getActivityLogs = async ({
		activities,
		setActivities,
		page,
		pageSize,
		orderBy,
		filterPayload,
	}: GetActiviesProps) => {
		try {
			setActivities({
				...activities,
				loading: true,
			});
			let response;
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});

			if (page) {
				response = await api.get(
					`activity/list?PageNumber=${page + 1}&PageSize=${pageSize}${filter}${
						orderBy || '&SortBy=updated%20desc'
					}`,
				);
			} else {
				response = await api.get(
					`activity/list?&PageSize=${pageSize}${filter}${orderBy || '&SortBy=updated%20desc'}`,
				);
			}

			setActivities({
				...activities,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || activities.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setActivities({
					...activities,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
		}
	};

	const createGiftType = async (payload: GiftTypeValues): Promise<boolean> => {
		try {
			const createdData = await api.post(`gifttype/create`, {
				...payload,
			});
			if (createdData?.data?.result) {
				setSimpleErrorSnack({
					message: 'Gift type created successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const updateGiftType = async (payload: GiftTypeValues, id: number): Promise<boolean> => {
		try {
			const updatedData = await api.put(`gifttype/${id}/update`, {
				...payload,
				id,
			});
			if (updatedData?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Gift type updated successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deleteGiftType = async (id: number): Promise<boolean> => {
		try {
			const deletedData = await api.remove(`gifttype/${id}/archive`);
			if (deletedData?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Gift type deleted successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const getGiftTypeBySearch = async ({
		giftTypeSearch,
		setGiftTypeSearch,
		searchTerm,
	}: GetDonationTypeSearch) => {
		try {
			setGiftTypeSearch({
				...giftTypeSearch,
				loading: true,
			});
			const giftData = await api.get(`giftType/search?giftTypeQuery=${searchTerm}`);

			setGiftTypeSearch({
				...giftTypeSearch,
				loading: false,
				data: giftData?.data?.result,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setGiftTypeSearch({
					...giftTypeSearch,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	return {
		getActivityLogs,
		createGiftType,
		updateGiftType,
		deleteGiftType,
		getGiftTypeBySearch,
	};
};

export default useActivityLogActions;
