import * as React from 'react';
import { Stack, Box, Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useUserActions from 'hooks/useUserActions';
import { Routes } from 'utils/routes';

interface PanelProps {
	cardArrays: CardArrays[];
}
interface CardArrays {
	name: string;
	Icon: any;
	link: string;
}

const SubPanel = ({ cardArrays }: PanelProps) => {
	const { handleRoles } = useUserActions();
	const navigate = useNavigate();
	return (
		<Paper sx={{ py: 6, px: 2, minHeight: '80vh' }}>
			<Box sx={{ px: { xs: 1 } }}>
				<Grid container spacing={{ xs: 2, md: 3 }}>
					{cardArrays.map((card) => {
						const { name, Icon, link } = card;
						if (link === 'year-end-receipt') {
							if (handleRoles('bookkeeping-actions'))
								return (
									<Grid key={name} onClick={() => navigate(`${link}`)} item xs={12} sm={4} md={3}>
										<Box
											sx={{
												mx: 2,
												background: 'var(--mainColor)',
												height: '150px',
												cursor: 'pointer',
												borderRadius: '15px',
												px: 1,
												'&:hover': { transform: 'scale(0.97, 0.97)', background: '#126ac2' },
											}}
										>
											<Stack
												justifyContent="center"
												alignItems="center"
												className="receipt__icon"
												sx={{ height: '60%' }}
											>
												<Icon sx={{ color: 'white', fontSize: { xs: 30, sm: 40, md: 50 } }} />
											</Stack>
											<Stack
												justifyContent="center"
												alignItems="center"
												className="receipt__text"
												sx={{
													color: 'white',
													wordBreak: 'break-all',
													fontWeight: '600',
													fontSize: { xs: 12, sm: 13, md: 15 },
													wordWrap: 'break-word',
													textAlign: 'center',
												}}
											>
												{name}
											</Stack>
										</Box>
									</Grid>
								);
							return;
						}
						if (link === 'users') {
							if (handleRoles('user-list'))
								return (
									<Grid key={name} onClick={() => navigate(`${link}`)} item xs={12} sm={4} md={3}>
										<Box
											sx={{
												mx: 2,
												background: 'var(--mainColor)',
												height: '150px',
												cursor: 'pointer',
												borderRadius: '15px',
												px: 1,
												'&:hover': { transform: 'scale(0.97, 0.97)', background: '#126ac2' },
											}}
										>
											<Stack
												justifyContent="center"
												alignItems="center"
												className="receipt__icon"
												sx={{ height: '60%' }}
											>
												<Icon sx={{ color: 'white', fontSize: { xs: 30, sm: 40, md: 50 } }} />
											</Stack>
											<Stack
												justifyContent="center"
												alignItems="center"
												className="receipt__text"
												sx={{
													color: 'white',
													wordBreak: 'break-all',
													fontWeight: '600',
													fontSize: { xs: 12, sm: 13, md: 15 },
													wordWrap: 'break-word',
													textAlign: 'center',
												}}
											>
												{name}
											</Stack>
										</Box>
									</Grid>
								);
							return;
						}
						if (
							link === 'hca-orders' ||
							link === 'walkathon' ||
							link === 'hca-materials' ||
							link === 'hca-donations' ||
							link === 'school-boards' ||
							link === 'schools'
						) {
							if (handleRoles('hca'))
								return (
									<Grid key={name} onClick={() => navigate(`${link}`)} item xs={12} sm={4} md={3}>
										<Box
											sx={{
												mx: 2,
												background: 'var(--mainColor)',
												height: '150px',
												cursor: 'pointer',
												borderRadius: '15px',
												px: 1,
												'&:hover': { transform: 'scale(0.97, 0.97)', background: '#126ac2' },
											}}
										>
											<Stack
												justifyContent="center"
												alignItems="center"
												className="receipt__icon"
												sx={{ height: '60%' }}
											>
												<Icon sx={{ color: 'white', fontSize: { xs: 30, sm: 40, md: 50 } }} />
											</Stack>
											<Stack
												justifyContent="center"
												alignItems="center"
												className="receipt__text"
												sx={{
													color: 'white',
													wordBreak: 'break-all',
													fontWeight: '600',
													fontSize: { xs: 12, sm: 13, md: 15 },
													wordWrap: 'break-word',
													textAlign: 'center',
												}}
											>
												{name}
											</Stack>
										</Box>
									</Grid>
								);
							return;
						}
						if (
							link === 'campaign' ||
							link === Routes.MissionSundayMaterial ||
							link === Routes.MissionSundayOrder
						) {
							if (handleRoles('mission-sunday'))
								return (
									<Grid key={name} onClick={() => navigate(`${link}`)} item xs={12} sm={4} md={3}>
										<Box
											sx={{
												mx: 2,
												background: 'var(--mainColor)',
												height: '150px',
												cursor: 'pointer',
												borderRadius: '15px',
												px: 1,
												'&:hover': { transform: 'scale(0.97, 0.97)', background: '#126ac2' },
											}}
										>
											<Stack
												justifyContent="center"
												alignItems="center"
												className="receipt__icon"
												sx={{ height: '60%' }}
											>
												<Icon sx={{ color: 'white', fontSize: { xs: 30, sm: 40, md: 50 } }} />
											</Stack>
											<Stack
												justifyContent="center"
												alignItems="center"
												className="receipt__text"
												sx={{
													color: 'white',
													wordBreak: 'break-all',
													fontWeight: '600',
													fontSize: { xs: 12, sm: 13, md: 15 },
													wordWrap: 'break-word',
													textAlign: 'center',
												}}
											>
												{name}
											</Stack>
										</Box>
									</Grid>
								);
							return;
						}
						return (
							<Grid key={name} onClick={() => navigate(`${link}`)} item xs={12} sm={4} md={3}>
								<Box
									sx={{
										mx: 2,
										background: 'var(--mainColor)',
										height: '150px',
										cursor: 'pointer',
										borderRadius: '15px',
										px: 1,
										'&:hover': { transform: 'scale(0.97, 0.97)', background: '#126ac2' },
									}}
								>
									<Stack
										justifyContent="center"
										alignItems="center"
										className="receipt__icon"
										sx={{ height: '60%' }}
									>
										<Icon sx={{ color: 'white', fontSize: { xs: 30, sm: 40, md: 50 } }} />
									</Stack>
									<Stack
										justifyContent="center"
										alignItems="center"
										className="receipt__text"
										sx={{
											color: 'white',
											wordWrap: 'break-word',
											textAlign: 'center',
											fontWeight: '600',
											fontSize: { xs: 12, sm: 13, md: 15 },
										}}
									>
										{name}
									</Stack>
								</Box>
							</Grid>
						);
					})}
				</Grid>
			</Box>
		</Paper>
	);
};

export default SubPanel;
