import React, { useState, useEffect } from 'react';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { Paper, Chip, TablePagination, Tooltip } from '@mui/material';
import { pagination } from 'Components/Common/Tables/Tables';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import useAnnuitiesActions, { DonorAnnuities, DonorAnnuitiesData } from 'hooks/useAnnuitiesActions';
import { StateType, OrderType, FilterPayload } from 'contexts/intialstates/Types';
import { convertDate } from 'helpers/dayAndTime';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import { sortTable } from 'Components/Common/Tables/sortTable';
import { useNavigate } from 'react-router-dom';
import useUserActions from 'hooks/useUserActions';
import { GlobalContexts } from 'contexts/GlobalContext';

interface P {
	trimList: ColumnType[];
	setTrimList: React.Dispatch<React.SetStateAction<ColumnType[]>>;
	donorId: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	page: number;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	reload: boolean;
	filterPayload: FilterPayload[];
	setFilterPayload: React.Dispatch<React.SetStateAction<FilterPayload[]>>;
}

const AnnuitiesTable = ({
	trimList,
	setTrimList,
	donorId,
	page,
	setPage,
	reload,
	setReload,
	setFilterPayload,
	filterPayload,
}: P) => {
	const { handleRoles } = useUserActions();
	const navigate = useNavigate();
	const { getDonorAnnuities } = useAnnuitiesActions();
	const [donorAnnuities, setDonorAnnuities] = useState<StateType<DonorAnnuities>>({
		loading: false,
		error: null,
		data: null,
	});
	const [pageSize, setPageSize] = useState(10);
	const [orderBy, setOrderBy] = useState<string>('');
	const [orderType, setOrderType] = useState<OrderType>('asc');
	const [label, setLabel] = useState<string>('');

	const { setDonorAnn } = React.useContext(GlobalContexts);

	useEffect(() => {
		setDonorAnn(donorAnnuities);
	}, [donorAnnuities]);

	useEffect(() => {
		if (!donorId) return;
		getDonorAnnuities({ donorAnnuities, setDonorAnnuities, page, pageSize, orderBy, filterPayload, donorId });
	}, [orderBy, page, pageSize, reload]);

	const handleRowClick = (row: any) => {
		if (!handleRoles('donor-anuities')) return;
		navigate(`/annuities/agreement/${row.id}`);
	};

	const getTableData = () => {
		return donorAnnuities?.data?.body.map((row: DonorAnnuitiesData, index: number) => {
			return {
				's/n': page * pageSize + index + 1,
				id: row.id,
				role: row.role?.role || 'N/A',
				agreementNumber: row.agreementNumber,
				receivedAmount: row.receivedAmount,
				aggrementType: row.agreementType.type,
				status: row.status.status ? (
					<Chip label={row.status.status} color="primary" variant="outlined" />
				) : (
					'N/A'
				),
				created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
				createdBy: row?.createdBy?.name || 'N/A',
				updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
				updatedBy: row?.updatedBy?.name || 'N/A',
			};
		});
	};
	const getColumnData = () => {
		return trimList.map((column) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
							<span
								style={{
									cursor: 'pointer',
									margin: '10px',
								}}
								onClick={() => {
									sortTable({
										column: column.id,
										setLabel,
										setOderBy: setOrderBy,
										setOrderType,
										orderType,
										label,
									});
								}}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							reload={reload}
							setIsReload={setReload}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							setPage={setPage}
							page={page}
						/>
					</>
				),
			};
		});
	};
	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};
	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};
	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<CustomTable
				loading={donorAnnuities?.loading}
				columnData={getColumnData()}
				tableData={getTableData()}
				handleDrag={handleDrag}
				handleResize={handleResize}
				emptyMessage={'No data available'}
				handleRowClick={handleRowClick}
			/>
			<TablePagination
				rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
				component="div"
				count={donorAnnuities?.data?.totalRecords || 0}
				rowsPerPage={pageSize}
				page={page}
				sx={pagination}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
};
export default AnnuitiesTable;
