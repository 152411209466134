import React, { useState, useEffect } from 'react';
import { Checkbox, CircularProgress, TablePagination, Tooltip, Stack } from '@mui/material';
import { useStyles } from '../../Annuities.style';
import { convertDate } from 'helpers/dayAndTime';
import useAnnuitiesActions, { Payment } from 'hooks/useAnnuitiesActions';
import { StateType, OrderType } from 'contexts/intialstates/Types';
import { Delete } from '@mui/icons-material';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import { sortTable } from 'Components/Common/Tables/sortTable';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { pagination } from 'Components/Common/Tables/Tables';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';

export const paymentHistoryHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'paymentDate',
		label: 'Payment Date',
		minWidth: 220,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'annuity',
		label: 'Annuity',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'expense',
		label: 'Expense',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'interest',
		label: 'Interest',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'balance',
		label: 'Balance',
		minWidth: 220,
		align: 'center',
		filterType: 'value',
	},
	{
		id: 'overPayment',
		label: 'Overpayment',
		minWidth: 220,
		align: 'center',
	},
];

interface P {
	id?: number;
}

const PaymentHistory = ({ id }: P) => {
	const classes = useStyles();
	const { getPaymentHistory, updateOverpayment, deleteAgreementPayment } = useAnnuitiesActions();
	const [payment, setPayment] = useState<StateType<Payment>>({
		data: null,
		loading: false,
		error: null,
	});
	const [reload, setReload] = useState(false);
	const [loading, setLoading] = useState<number[]>([]);
	const [openDelete, setOpenDelete] = useState<{
		id?: number;
		open: boolean;
	}>({
		id: undefined,
		open: false,
	});
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [filterPayload, setFilterPayload] = React.useState<Array<any>>([]);
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [orderBy, setOrderBy] = useState<string>('');
	const [orderType, setOrderType] = useState<OrderType>('asc');
	const [label, setLabel] = useState<string>('');

	useEffect(() => {
		if (!id) return;
		getPaymentHistory({ payment, setPayment, page, pageSize, agreementId: id, filterPayload, orderBy });
	}, [id, reload, page, pageSize, orderBy]);

	const handleCheck = async (paymentId: number, checked: boolean) => {
		if (!id) return;
		const loadData = [...loading];
		loadData.push(paymentId);
		setLoading(loadData);
		const isSuccess = await updateOverpayment(
			{
				updates: [
					{
						agreementPaymentId: paymentId,
						isOverPayment: checked,
					},
				],
			},
			id,
		);
		setLoading(loading.filter((item) => item !== paymentId));
		if (isSuccess) {
			setReload(!reload);
		}
	};

	const handleDelete = async () => {
		if (!openDelete.id || !id) return;
		setDeleteLoading(true);
		const isSuccess = await deleteAgreementPayment(openDelete.id, id);
		if (isSuccess) {
			setReload(!reload);
		}
		setDeleteLoading(false);
		handleToggleDelete();
	};

	const handleToggleDelete = (paymentId?: number) => {
		setOpenDelete({
			id: paymentId,
			open: !openDelete.open,
		});
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};
	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};
	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const getColumnData = () => {
		return trimList.map((column) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'isOverPayment' ? '' : `Sort by ${column.label}`}>
							<span
								style={{
									cursor: 'pointer',
									margin: '10px',
								}}
								onClick={() => {
									sortTable({
										column: column.id,
										setLabel,
										setOderBy: setOrderBy,
										setOrderType,
										orderType,
										label,
									});
								}}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							reload={reload}
							setIsReload={setReload}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							setPage={setPage}
							page={page}
						/>
					</>
				),
			};
		});
	};

	const getTableData = () => {
		return payment?.data?.body.map((row: any, index: number) => {
			return {
				's/n': page * pageSize + index + 1,
				id: row.id,
				paymentDate: row.paymentDate ? convertDate(row.paymentDate) : 'N/A',
				annuity: row.annuity,
				expense: row.expense,
				interest: row.interest,
				balance: row.balance,
				overPayment: (
					<Stack alignItems="center" direction={'row'} justifyContent="center" spacing={1}>
						{loading.includes(row.id) ? (
							<CircularProgress />
						) : (
							<Checkbox
								checked={row.isOverPayment}
								onChange={(e) => handleCheck(row.id, e.target.checked)}
								disabled={row.agreement.status.status !== 'Expired'}
							/>
						)}
						{row.isOverPayment && (
							<Delete className={classes.iconColor} onClick={() => handleToggleDelete(row.id)} />
						)}
					</Stack>
				),
			};
		});
	};

	return (
		<Stack>
			<Stack direction={'column'} alignItems={'center'}>
				<Stack direction={'row'} justifyContent={'flex-end'} width="100%" alignItems="center" mb={2}>
					<TrimTables
						name={'paymentHistoryHead'}
						list={paymentHistoryHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
					<FilterListButton
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
						reload={reload}
						setIsReload={setReload}
						setPage={setPage}
						page={page}
					/>
				</Stack>
				<CustomTable
					loading={payment?.loading}
					columnData={getColumnData()}
					tableData={getTableData()}
					handleDrag={handleDrag}
					handleResize={handleResize}
					emptyMessage={'No data available'}
				/>
				<TablePagination
					rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
					component="div"
					count={payment?.data?.totalRecords || 0}
					rowsPerPage={pageSize}
					page={page}
					sx={pagination}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Stack>
			{openDelete.open && (
				<ConfirmationModal
					handleClose={handleToggleDelete}
					message={'Are you sure you want to delete payment ?'}
					open={openDelete.open}
					handleConfirmation={handleDelete}
					handleCancel={handleToggleDelete}
					loading={deleteLoading}
				/>
			)}
		</Stack>
	);
};

export default PaymentHistory;
