import React, { useState, useEffect } from 'react';
import { StateType } from 'contexts/intialstates/Types';
import useAction, { DonorNotesData } from 'hooks/useNotesActions';
import NotesContent from 'Components/NotesLayout';

interface P {
	schoolBoardId?: number;
}

const NotesSchoolBoard = ({ schoolBoardId }: P) => {
	const [notes, setNotes] = useState<StateType<DonorNotesData>>({
		error: null,
		loading: false,
		data: null,
	});
	const [isReload, setIsReload] = useState(false);
	const { createNotes, deleteNotes, getNotes, updateNotes } = useAction();

	useEffect(() => {
		if (!schoolBoardId) return;
		getNotes({ id: schoolBoardId, notes, setNotes, type: 'school-board' });
	}, [isReload, schoolBoardId]);

	const handleDelete = async (id: number): Promise<boolean> => {
		return await deleteNotes(id);
	};

	const handleSubmit = async (note: string, id?: number): Promise<boolean> => {
		if (!schoolBoardId) return false;
		let isSucess;
		if (id) {
			isSucess = await updateNotes(id, note);
		} else {
			isSucess = await createNotes(schoolBoardId, note, 'SchoolBoard');
		}
		return isSucess;
	};

	return (
		<NotesContent
			notes={notes}
			handleDeleteNotes={handleDelete}
			handleSubmitNotes={handleSubmit}
			setIsReload={setIsReload}
			isReload={isReload}
		/>
	);
};

export default NotesSchoolBoard;
