import { AllParishStateType, SingleParishStateType } from './Types';

export const allParishInitialState: AllParishStateType = {
	loading: false,
	error: null,
	data: null,
};

export const singleParishInitialState: SingleParishStateType = {
	loading: false,
	error: null,
	data: null,
};
