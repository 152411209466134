import React, { useState, useEffect } from 'react';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import { Grid, Stack, Typography, Button, IconButton, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGlobalStyles } from 'Components/Common/global/global';
import { TaskAlt, Close, PersonOutline, Search } from '@mui/icons-material';
import useRelativesActions from 'hooks/useRelativesActions';
import DonorSearch from 'Components/Reports/DonorReports/DonorSearch';
import { Donor } from 'contexts/intialstates/Types';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';

interface P {
	visible: boolean;
	handleClose: () => void;
	donorId: number;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	reload: boolean;
	selectedData?: any;
}

interface FormValues {
	firstName: string;
	lastName: string;
	relation: string;
	donorId: number;
	relatedDonorId?: number;
	email: string;
	address: string;
	phoneNumber: string;
}

const CreateEditRelatives = ({ visible, handleClose, donorId, setReload, reload, selectedData }: P) => {
	const global = useGlobalStyles();
	const { createRelatives, updateRelatives, deleteRelatives } = useRelativesActions();

	const [loading, setLoading] = useState(false);
	const [visibleSearch, setVisibleSearch] = useState(false);
	const [formValues, setFormValues] = useState<FormValues>({
		firstName: '',
		lastName: '',
		relation: '',
		donorId,
		email: '',
		address: '',
		phoneNumber: '',
	});
	const [formErrors, setFormErrors] = useState<string[]>([]);
	const [isDelete, setIsDelete] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);

	useEffect(() => {
		if (!selectedData) return;
		setFormValues({
			...formValues,
			firstName: selectedData.firstName ?? '',
			lastName: selectedData.lastName ?? '',
			relatedDonorId: selectedData.relatedDonorId && parseInt(selectedData.relatedDonorId),
			relation: selectedData?.relation,
			email: selectedData.email ?? '',
			address: selectedData.address ?? '',
			phoneNumber: selectedData.phoneNumber ?? '',
		});
	}, [selectedData]);

	const handleSubmit = async () => {
		const errors = [];
		if (!formValues.firstName) {
			errors.push('firstName');
		}
		if (!formValues.lastName) {
			errors.push('lastName');
		}
		if (!formValues.relation) {
			errors.push('relation');
		}
		if (errors.length) {
			setFormErrors(errors);
			return;
		}
		setLoading(true);
		let isSuccess;
		if (selectedData?.id) {
			isSuccess = await updateRelatives(formValues, selectedData.id);
		} else {
			isSuccess = await createRelatives(formValues);
		}

		if (isSuccess) {
			handleClose();
			setReload(!reload);
		}
		setLoading(false);
	};

	const handleCloseDelete = () => {
		setIsDelete(false);
	};

	const handleDelete = async () => {
		if (selectedData) {
			setDeleteLoading(true);
			const isSucess = await deleteRelatives(selectedData.id);
			setDeleteLoading(false);
			handleCloseDelete();
			if (isSucess) {
				setReload(!reload);
				setIsDelete(false);
				handleClose();
			}
		}
	};

	const handleVisibleSearch = () => {
		setVisibleSearch(!visibleSearch);
	};

	const handleRowClickDonor = (donor: Donor) => {
		handleVisibleSearch();
		setFormValues({
			...formValues,
			firstName: donor.firstName ?? '',
			lastName: donor.lastName ?? '',
			relatedDonorId: parseInt(donor.id),
			email: donor.email ?? '',
			address:
				`${donor.postalCode ? `${donor.postalCode} ` : ''}${
					donor.streetline1
						? `${donor.streetline1} `
						: '' || donor.streetline2
						? `${donor.streetline2} `
						: '' || ''
				}${donor.city ? `${donor.city} ` : '' || ''}${
					donor.province?.provinceName ? `${donor.province?.provinceName} ` : '' || ''
				}${donor.country ? `${donor.country} ` : '' || ''} ` ?? '',
			phoneNumber: donor.phone ?? '',
		});
	};

	const handleChange = (name: string, value: string) => {
		setFormValues({
			...formValues,
			[name]: value,
		});
		if (formErrors.includes(name)) {
			setFormErrors(formErrors.filter((item) => item !== name));
		}
	};

	const handleClear = () => {
		setFormValues({
			...formValues,
			firstName: '',
			lastName: '',
			relatedDonorId: undefined,
			email: '',
			address: '',
			phoneNumber: '',
		});
	};

	const handleOpenDelete = () => {
		setIsDelete(true);
	};

	return (
		<BasicModal
			onClose={handleClose}
			visible={visible}
			title={'Add a Relative'}
			data={selectedData || undefined}
			handleOpenDelete={handleOpenDelete}
		>
			<Stack>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
					<Grid item xs={12} md={6} mb={2}>
						<Grid container>
							<Grid item>
								<PersonOutline className={global.formLabelIcon} />
							</Grid>
							<Grid item>
								<Typography variant="body2" className={global.formLabelText}>
									First Name
								</Typography>
							</Grid>
						</Grid>
						<Stack direction="row">
							<Stack>
								<TextField
									fullWidth
									size="small"
									variant="outlined"
									placeholder="First Name"
									onChange={(e) => handleChange('firstName', e.target.value)}
									value={formValues.firstName}
									disabled={!!formValues.relatedDonorId}
									className={!!formValues.relatedDonorId ? global.disabledInput : ''}
								/>
								{formErrors.includes('firstName') && (
									<span className={global.errorText}>First name is required</span>
								)}
							</Stack>
							<IconButton
								size="small"
								onClick={() => handleVisibleSearch()}
								sx={{ borderRadius: '5px', border: '1px solid var(--mainColor)', marginLeft: '5px' }}
							>
								<Search color="primary" />
							</IconButton>
							{!!formValues.relatedDonorId && (
								<IconButton
									size="small"
									onClick={handleClear}
									sx={{ borderRadius: '5px', border: '1px solid var(--mainColor)', marginLeft: '5px' }}
								>
									<Close color="primary" />
								</IconButton>
							)}
						</Stack>
					</Grid>
					<Grid item xs={12} md={6} mb={2}>
						<Grid container>
							<Grid item>
								<PersonOutline className={global.formLabelIcon} />
							</Grid>
							<Grid item>
								<Typography variant="body2" className={global.formLabelText}>
									Last Name
								</Typography>
							</Grid>
						</Grid>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							placeholder="Last Name"
							onChange={(e) => handleChange('lastName', e.target.value)}
							value={formValues.lastName}
							disabled={!!formValues.relatedDonorId}
							className={!!formValues.relatedDonorId ? global.disabledInput : ''}
						/>
						{formErrors.includes('lastName') && (
							<span className={global.errorText}>Last name is required</span>
						)}
					</Grid>
					<Grid item xs={12} md={6} mb={2}>
						<Grid container>
							<Grid item>
								<PersonOutline className={global.formLabelIcon} />
							</Grid>
							<Grid item>
								<Typography variant="body2" className={global.formLabelText}>
									Relation
								</Typography>
							</Grid>
						</Grid>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							placeholder="Relation"
							onChange={(e) => handleChange('relation', e.target.value)}
							value={formValues.relation}
						/>
						{formErrors.includes('relation') && (
							<span className={global.errorText}>Relation is required</span>
						)}
					</Grid>
					<Grid item xs={12} md={6} mb={2}>
						<Grid container>
							<Grid item>
								<PersonOutline className={global.formLabelIcon} />
							</Grid>
							<Grid item>
								<Typography variant="body2" className={global.formLabelText}>
									Email
								</Typography>
							</Grid>
						</Grid>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							placeholder="Email"
							onChange={(e) => handleChange('email', e.target.value)}
							value={formValues.email}
						/>
						{formErrors.includes('email') && <span className={global.errorText}>Invalid email</span>}
					</Grid>
					<Grid item xs={12} md={6} mb={2}>
						<Grid container>
							<Grid item>
								<PersonOutline className={global.formLabelIcon} />
							</Grid>
							<Grid item>
								<Typography variant="body2" className={global.formLabelText}>
									Phone Number
								</Typography>
							</Grid>
						</Grid>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							placeholder="Phone Number"
							onChange={(e) => handleChange('phoneNumber', e.target.value)}
							value={formValues.phoneNumber}
						/>
					</Grid>
					<Grid item xs={12} md={6} mb={2}>
						<Grid container>
							<Grid item>
								<PersonOutline className={global.formLabelIcon} />
							</Grid>
							<Grid item>
								<Typography variant="body2" className={global.formLabelText}>
									Address
								</Typography>
							</Grid>
						</Grid>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							placeholder="Address"
							onChange={(e) => handleChange('address', e.target.value)}
							value={formValues.address}
						/>
					</Grid>
				</Grid>
				<Stack direction="row" my={3} justifyContent={'center'} spacing={2} alignItems="center">
					<LoadingButton
						color="primary"
						variant="contained"
						sx={{ width: 'fit-content', textTransform: 'capitalize' }}
						loading={loading}
						startIcon={<TaskAlt />}
						loadingPosition="start"
						onClick={handleSubmit}
					>
						Save
					</LoadingButton>
					<Button
						variant="outlined"
						sx={{ width: 'fit-content', textTransform: 'capitalize' }}
						onClick={handleClose}
						startIcon={<Close />}
					>
						Cancel
					</Button>
				</Stack>
			</Stack>
			{visibleSearch && (
				<DonorSearch
					handleVisibleSearch={handleVisibleSearch}
					visibleSearch={visibleSearch}
					onRowClick={handleRowClickDonor}
					donorId={donorId}
				/>
			)}
			{isDelete && (
				<ConfirmationModal
					handleClose={() => setIsDelete(false)}
					message={'Are you sure you want to delete relative ?'}
					open={isDelete}
					handleConfirmation={handleDelete}
					handleCancel={() => setIsDelete(false)}
					loading={deleteLoading}
				/>
			)}
		</BasicModal>
	);
};

export default CreateEditRelatives;
