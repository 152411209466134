import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	title: {
		color: 'var(--mainColor)',
		fontSize: '32px',
		fontWeight: 700,
	},
	subTitle: {
		border: '1px solid var(--mainColor)',
		borderRadius: '4px',
		padding: '5px',
		background: 'var(--white)',
		color: 'var(--mainColor)',
		fontSize: '12px',
	},
	backIconWrapper: {
		background: 'var(--white)',
		borderRadius: '4px',
		padding: '5px',
		color: 'var(--mainColor)',
		cursor: 'pointer',
	},
	paperWrap: {
		padding: '20px',
	},
	IconBtn: {
		cursor: 'pointer',
		margin: '0 5px',
		border: '1px solid var(--mainColor)',
		borderRadius: '6px',
		padding: '3px 5px',
		display: 'flex',
		background: 'var(--white)',
		'& svg': {
			color: 'var(--mainColor)',
		},
	},
	disabledIcon: {
		margin: '0 5px',
		border: '1px solid var(--disabled)',
		borderRadius: '6px',
		padding: '3px 5px',
		display: 'flex',
		background: 'var(--white)',
		'& svg': {
			color: 'var(--disabled)',
		},
	},
	schoolHead: {
		fontWeight: 600,
		fontSize: '14px',
		width: '200px',
	},
	schoolHead1: {
		fontWeight: 600,
		fontSize: '14px',
	},
	donorHead: {
		width: '170px',
		textAlign: 'center',
		borderRight: '1px solid',
		fontWeight: 400,
		fontSize: '14px',
	},
	donorNameHead: {
		width: '270px',
		textAlign: 'center',
		borderRight: '1px solid',
		fontWeight: 400,
		fontSize: '14px',
	},
	donorConetent: {
		width: '170px',
	},
	donorNameConetent: {
		width: '270px',
	},
	searchFieldIcon: {
		fontSize: '18px',
		marginRight: '5px',
		color: 'var(--mainColor)',
	},
	whiteBtn: {
		backgroundColor: 'var(--white) !important',
		color: 'var(--mainColor) !important',
	},
	disabledInput: {
		background: 'var(--invalidBackground)',
	},
});
