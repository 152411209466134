import { useContext } from 'react';
import { ResponseError, StateType, Annuitant } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface AnnuitantGender {
	id: number;
	name: string;
}

export interface GetAnnuitantGender {
	annuitantGender: StateType<AnnuitantGender[]>;
	setAnnuitantGender: React.Dispatch<React.SetStateAction<StateType<AnnuitantGender[]>>>;
}

export interface MaritalStatus {
	id: number;
	status: string;
}

export interface GetMaritalStatus {
	maritalStatus: StateType<MaritalStatus[]>;
	setMaritalStatus: React.Dispatch<React.SetStateAction<StateType<MaritalStatus[]>>>;
}

export interface GetAnnuitantById {
	annuitantId: number;
	annuitant: StateType<Annuitant>;
	setAnnuitant: React.Dispatch<React.SetStateAction<StateType<Annuitant>>>;
}

interface CreatePayload {
	genderId?: number;
	maritalStatusId: number;
	statusId?: number;
	dateOfBirth: string;
	sin: string;
}

const useAnnuitiesActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = useContext(GlobalContexts);

	const getAnnuitantGender = async ({ annuitantGender, setAnnuitantGender }: GetAnnuitantGender) => {
		try {
			setAnnuitantGender({
				...annuitantGender,
				loading: true,
			});
			const response = await api.get(`annuitant/gender/list`);

			setAnnuitantGender({
				...annuitantGender,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAnnuitantGender({
					...annuitantGender,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const getMaritalStatus = async ({ maritalStatus, setMaritalStatus }: GetMaritalStatus) => {
		try {
			setMaritalStatus({
				...maritalStatus,
				loading: true,
			});
			const response = await api.get(`annuitant/maritalStatus/list`);

			setMaritalStatus({
				...maritalStatus,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setMaritalStatus({
					...maritalStatus,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const getAnnuitantById = async ({ annuitant, setAnnuitant, annuitantId }: GetAnnuitantById) => {
		try {
			setAnnuitant({
				...annuitant,
				loading: true,
			});
			const response = await api.get(`/annuitant/${annuitantId}`);

			setAnnuitant({
				...annuitant,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAnnuitant({
					...annuitant,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const updateAnnuitant = async (payload: CreatePayload, id: number): Promise<boolean | number> => {
		try {
			const updateData = await api.put(`/annuitant/${id}/update`, { ...payload });
			if (updateData?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Annuitant updated successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};


	return {
		getAnnuitantGender,
		getMaritalStatus,
		getAnnuitantById,
		updateAnnuitant,
	};
};

export default useAnnuitiesActions;
