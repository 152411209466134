import React, { useState, useEffect } from 'react';
import { Grid, Stack, Typography, Button, TextField, FormControlLabel, Checkbox } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGlobalStyles } from 'Components/Common/global/global';
import { Close, AddTask } from '@mui/icons-material';
import useInterestRateActions from 'hooks/useInterestRateActions';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import useUserActions from 'hooks/useUserActions';

export interface InterestRateValues {
	id?: number;
	dateFrom?: string;
	dateTo?: string;
	isCurrent?: boolean;
	yieldNumber?: number;
	intCredit?: number;
}

interface P {
	selectedEditData?: InterestRateValues;
	handleClose: () => void;
	reload?: boolean;
	setReload?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateEditInterestRate = ({ selectedEditData, handleClose, reload, setReload }: P) => {
	const { handleRoles } = useUserActions();
	const global = useGlobalStyles();
	const { createInterestRate, updateInterestRate } = useInterestRateActions();
	const [formValues, setFormValues] = useState<InterestRateValues>({});
	const [saveLoading, setSaveLoading] = useState(false);
	const [formErrors, setFormErrors] = useState<string[]>([]);

	useEffect(() => {
		if (!selectedEditData) {
			setFormValues({});
		} else {
			const { dateFrom, dateTo, yieldNumber, intCredit, isCurrent } = selectedEditData;
			setFormValues({
				dateFrom,
				dateTo,
				yieldNumber,
				intCredit,
				isCurrent,
			});
		}
	}, [selectedEditData]);

	const handleSubmit = async () => {
		const errors = [...formErrors];
		if (!formValues.dateTo) {
			errors.push('dateTo');
		}
		if (!formValues.dateFrom) {
			errors.push('dateFrom');
		}
		if (!formValues.intCredit) {
			errors.push('intCredit');
		}
		setFormErrors(errors);
		if (errors.length) return;

		setSaveLoading(true);
		const { dateFrom, dateTo, yieldNumber, intCredit, isCurrent } = formValues;
		const payload = {
			dateFrom,
			dateTo,
			yield: yieldNumber,
			intCredit,
			isCurrent,
		};
		let isSucess;
		if (selectedEditData?.id) {
			isSucess = await updateInterestRate(payload, selectedEditData.id);
		} else {
			isSucess = await createInterestRate(payload);
		}
		setSaveLoading(false);
		handleClose();
		if (isSucess) {
			setReload?.(!reload);
		}
	};

	const handleChange = (name: string, value: string | boolean) => {
		if ((name === 'yieldNumber' || name === 'intCredit') && typeof value === 'string') {
			setFormValues({
				...formValues,
				[name]: parseFloat(value),
			});
		} else {
			setFormValues({
				...formValues,
				[name]: value,
			});
		}
		if (formErrors.includes(name)) {
			setFormErrors(formErrors.filter((item) => item !== name));
		}
	};

	return (
		<Stack mt={2}>
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
				<Grid item xs={12} md={6} mb={2}>
					<Grid container>
						<Typography variant="body2" className={global.formLabelText}>
							Date From
						</Typography>
					</Grid>
					<BasicDatePicker
						size="small"
						value={formValues.dateFrom}
						setValue={(e) => handleChange('dateFrom', e.target.value)}
					/>
					{formErrors.includes('dateFrom') && <span className={global.errorText}>Date From is required</span>}
				</Grid>
				<Grid item xs={12} md={6} mb={2}>
					<Grid container>
						<Typography variant="body2" className={global.formLabelText}>
							Date To
						</Typography>
					</Grid>
					<BasicDatePicker
						size="small"
						value={formValues.dateTo}
						setValue={(e) => handleChange('dateTo', e.target.value)}
					/>
					{formErrors.includes('dateTo') && <span className={global.errorText}>Date To is required</span>}
				</Grid>
				<Grid item xs={12} md={6} mb={2}>
					<Grid container>
						<Typography variant="body2" className={global.formLabelText}>
							Yield
						</Typography>
					</Grid>
					<TextField
						size="small"
						type="number"
						value={formValues.yieldNumber}
						onChange={(e) => handleChange('yieldNumber', e.target.value)}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} md={6} mb={2}>
					<Grid container>
						<Typography variant="body2" className={global.formLabelText}>
							Int. Credit
						</Typography>
					</Grid>
					<TextField
						size="small"
						type="number"
						value={formValues.intCredit}
						onChange={(e) => handleChange('intCredit', e.target.value)}
						fullWidth
					/>
					{formErrors.includes('intCredit') && (
						<span className={global.errorText}>Int. Credit is required</span>
					)}
				</Grid>
				<Grid item xs={12} md={6} mb={2}>
					<FormControlLabel
						value="top"
						control={
							<Checkbox
								checked={formValues.isCurrent ?? false}
								onChange={(e) => handleChange('isCurrent', e.target.checked)}
							/>
						}
						label="Current"
					/>
				</Grid>
			</Grid>
			{handleRoles('annuities-actions') && (
				<Stack direction="row" justifyContent="center" py={3} spacing={2}>
					<LoadingButton
						loadingPosition="start"
						loading={saveLoading}
						startIcon={<AddTask />}
						sx={{ textTransform: 'none' }}
						onClick={handleSubmit}
						variant="contained"
					>
						Save
					</LoadingButton>
					<Button
						sx={{ textTransform: 'none' }}
						startIcon={<Close />}
						onClick={handleClose}
						variant="outlined"
						className={global.whiteBtn}
					>
						Cancel
					</Button>
				</Stack>
			)}
		</Stack>
	);
};

export default CreateEditInterestRate;
