import moments from 'moment';

export const convertDate = (date: any) => {
	return date && moments(date).format('MMM Do, YYYY');
};
export const currentDate = () => {
	return moments().format('Do MMMM YYYY');
};
export const fullMonthYear =(date:string) => {
	return date && moments(date).format('MMMM YYYY');
};