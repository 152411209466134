import React, { useEffect, useState, useContext } from 'react';
import { Box } from '@mui/system';
import { Button, Stack, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { GlobalContexts } from 'contexts/GlobalContext';
import DonationTypeList from './List';
import { DonationTypeHead, ColumnType } from 'Components/Common/Tables/TableHeaders';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import {
	DonationTypes,
	DonationTypesData,
	FilterPayload,
	OrderType,
	StateType,
} from 'contexts/intialstates/Types';
import useDonationTypeAction from 'hooks/useDonationTypesActions';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import CreditEditDonationType, { DonationTypeValues } from './CreateEdit';
import useUserActions from 'hooks/useUserActions';

const DonationType = () => {
	const { handleRoles } = useUserActions();
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const { getDonationTypes, deleteDonationType, updateDonationType, createDonationType } =
		useDonationTypeAction();
	const [reload, setReload] = React.useState(false);
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [visible, setVisible] = React.useState(false);
	const [isDelete, setIsDelete] = React.useState(false);
	const [selectedData, setSelectedData] = React.useState<DonationTypes>();
	const [saveLoading, setSaveLoading] = useState(false);
	const { setErrorSnack } = useContext(GlobalContexts);

	const [donationTypes, setDonationTypes] = React.useState<StateType<DonationTypesData>>({
		data: null,
		error: null,
		loading: false,
	});

	useEffect(() => {
		getDonationTypes({ setDonationTypes, donationTypes, pageSize, page, orderBy, filterPayload });
	}, [page, pageSize, reload, orderBy]);

	const handleOpenEdit = (data: DonationTypes) => {
		setVisible(true);
		setSelectedData(data);
	};

	const handleOpenDelete = (data: DonationTypes) => {
		setIsDelete(true);
		setSelectedData(data);
	};

	const handleCloseEdit = () => {
		setVisible(false);
		setSelectedData(undefined);
	};

	const handleCloseDelete = () => {
		setIsDelete(false);
		setSelectedData(undefined);
	};

	const handleDelete = async () => {
		if (selectedData) {
			setSaveLoading(true);
			const isSucess = await deleteDonationType(selectedData.id);
			setSaveLoading(false);
			handleCloseDelete();
			if (isSucess) {
				setReload(!reload);
				setVisible(false);
				setErrorSnack(false);
			} else {
				setErrorSnack(true);
			}
		}
	};
	const handleSubmit = async (payload: DonationTypeValues) => {
		setSaveLoading(true);
		let isSucess;
		if (selectedData) {
			isSucess = await updateDonationType(payload, selectedData.id);
		} else {
			isSucess = await createDonationType(payload);
		}
		setSaveLoading(false);
		handleCloseEdit();
		if (isSucess) {
			setReload(!reload);
		} else {
			setErrorSnack(true);
		}
	};

	return (
		<>
			<Box>
				<Stack
					alignItems="center"
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent={'space-between'}
					spacing={1}
					mb={5}
				>
					<Stack direction="column">
						<Stack direction="row" spacing={4} alignItems="center">
							<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
								Donation Types
							</Typography>
						</Stack>
						<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
							Create and manage Donation Types here.
						</Typography>
					</Stack>
					<Stack direction={'row'} justifyContent={'center'} spacing={{ xs: 1, md: 2 }} alignItems="center">
						<TrimTables
							name="DonationTypeHead"
							list={DonationTypeHead}
							trimList={trimList}
							setTrimList={setTrimList}
						/>
						{handleRoles('admin-actions') && (
							<Button
								sx={{ textTransform: 'capitalize' }}
								variant="contained"
								disableElevation
								startIcon={<Add />}
								onClick={() => setVisible(true)}
							>
								Add Donation Type
							</Button>
						)}
						<FilterListButton
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							setIsReload={setReload}
							reload={reload}
							setPage={setPage}
							page={page}
						/>
					</Stack>
				</Stack>
				<Stack direction="row">
					<DonationTypeList
						handleOpenEdit={handleOpenEdit}
						trimList={trimList}
						setTrimList={setTrimList}
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
						reload={reload}
						setReload={setReload}
						page={page}
						setPage={setPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						orderType={orderType}
						setOrderType={setOrderType}
						label={label}
						setLabel={setLabel}
						orderBy={orderBy}
						setOderBy={setOderBy}
						donationTypes={donationTypes}
					/>
				</Stack>
				<BasicModal
					visible={visible}
					title={selectedData ? 'Edit a Donation Type' : 'Add a Donation Type'}
					data={selectedData || undefined}
					handleOpenDelete={handleOpenDelete}
					noDelete={handleRoles('admin-actions')}
				>
					<CreditEditDonationType
						saveLoading={saveLoading}
						handleSubmit={handleSubmit}
						selectedEditData={selectedData}
						setSelectedData={setSelectedData}
						setVisible={setVisible}
					/>
				</BasicModal>
				<ConfirmationModal
					handleClose={() => setIsDelete(false)}
					message={'Are you sure you want to delete donation type ?'}
					open={isDelete}
					handleConfirmation={handleDelete}
					handleCancel={() => setIsDelete(false)}
					loading={saveLoading}
				/>
			</Box>
		</>
	);
};

export default DonationType;
