import React from 'react';
import { Button, Paper, Stack, TablePagination, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { pagination } from 'Components/Common/Tables/Tables';
import { ColumnType, postDatedNotificationsHead } from 'Components/Common/Tables/TableHeaders';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import {
	Donor,
	FilterPayload,
	OrderType,
	PostDatedCheques,
	PostDatedChequesData,
	StateType,
} from 'contexts/intialstates/Types';
import { convertDate } from 'helpers/dayAndTime';
import useNotificationActions from 'hooks/useNotificationActions';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import { sortTable } from 'Components/Common/Tables/sortTable';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import FormatCurrency from 'helpers/FormatCurrency';
import TableFiltering, { DonorFiltering } from 'Components/Reusable/TableFiltering/TableFiltering';
import useUserActions from 'hooks/useUserActions';
import useDebounce from 'Components/Reusable/hooks/debounce';

const PostDatedNotifications = () => {
	const { handleRoles } = useUserActions();
	const navigate = useNavigate();

	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [postDated, setPostDated] = React.useState<StateType<PostDatedChequesData>>({
		data: null,
		error: null,
		loading: false,
	});

	const [donorQuery, setDonorQuery] = React.useState<string>('');
	const debouncedSearchTerm = useDebounce(donorQuery, 1000);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);
	const [reload, setIsReload] = React.useState(false);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const { getPostDatedCheques } = useNotificationActions();

	React.useEffect(() => {
		getPostDatedCheques({
			postDated,
			setPostDated,
			orderBy,
			filterPayload,
			page,
			pageSize,
			donorQuery,
		});
	}, [orderBy, page, pageSize, reload, debouncedSearchTerm]);

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const editReceiptTemplate = (id: number) => {
		navigate(`/bookkeeping/post-dated-cheques/${id}`);
	};

	const donorName = (donor: Donor) => {
		if (donor?.firstName && donor?.lastName) return `${donor?.firstName} ${donor?.lastName}`;
		if (donor?.firstName) return `${donor?.firstName}`;
		if (donor?.lastName) return ` ${donor?.lastName}`;
		if (donor?.orgName) return donor?.orgName;
		return 'Anonymous';
	};

	const getTableData = () => {
		return postDated?.data?.body.length
			? postDated?.data?.body.map((row: PostDatedCheques, index: number) => {
					return {
						id: row.id,
						's/n': page * pageSize + index + 1,
						donor: donorName(row?.donor),
						cheque: row?.cheque || 'N/A',
						amount: row?.amount ? <FormatCurrency value={row?.amount} /> : 'N/A',
						dueDate: row?.dueDate ? `${convertDate(row?.dueDate)}` : 'N/A',
						blank: handleRoles('bookkeeping-actions') && (
							<Stack direction="row" spacing={2}>
								<Button variant="outlined" sx={{ textTransform: 'capitalize' }}>
									Archive
								</Button>
								<Button variant="contained" sx={{ textTransform: 'capitalize' }}>
									Deposit
								</Button>
							</Stack>
						),
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' || column.id == 'remark' ? '' : `Sort by ${column.label}`}>
							<span
								style={{ cursor: 'pointer' }}
								onClick={() =>
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									})
								}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<DonorFiltering
							searchDonor={donorQuery}
							setSearchDonor={setDonorQuery}
							filterType={column.filterType}
							setPage={setPage}
						/>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							reload={reload}
							setIsReload={setIsReload}
							setPage={setPage}
							page={page}
						/>
					</>
				),
			};
		});
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	return (
		<>
			<Stack
				alignItems="center"
				direction={{ xs: 'column', sm: 'row' }}
				justifyContent={'space-between'}
				spacing={1}
				mb={5}
			>
				<Stack direction="column">
					<Stack direction="row" spacing={4} alignItems="center">
						<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
							Post-Dated Cheques
						</Typography>
					</Stack>
					<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
						View and manage all post-dated cheques here.
					</Typography>
				</Stack>
				<Stack sx={{ mb: 3 }} direction="row" alignItems={'center'} justifyContent="flex-end">
					<TrimTables
						name={'postDatedNotificationsHead'}
						list={postDatedNotificationsHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
					<FilterListButton
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
						reload={reload}
						setIsReload={setIsReload}
						setPage={setPage}
						page={page}
					/>
				</Stack>
			</Stack>

			<Stack justifyContent="center" alignItems="center">
				<Paper sx={{ width: '100%', overflow: 'hidden' }}>
					<CustomTable
						loading={postDated?.loading}
						columnData={getColumnData()}
						tableData={getTableData()}
						handleDrag={handleDrag}
						handleResize={handleResize}
						emptyMessage={'No receipt template available'}
						handleRowClick={(row) => editReceiptTemplate(row.id as number)}
					/>
					{(postDated?.data?.body.length as number) > 0 && (
						<TablePagination
							rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
							component="div"
							count={postDated?.data?.totalRecords as number}
							rowsPerPage={pageSize}
							page={page}
							sx={pagination}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					)}
				</Paper>
			</Stack>
		</>
	);
};

export default PostDatedNotifications;
