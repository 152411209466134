import React, { useRef } from 'react';
import {
	Stack,
	Table,
	Box,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import Report from '../Report';
import { convertDate } from 'helpers/dayAndTime';
import { useStyles } from 'Components/Reports/DonorReports/DonorReport.style';
import FormatCurrency from 'helpers/FormatCurrency';
import { useReactToPrint } from 'react-to-print';
import Logo from '../../../assets/images/logo.png';
import { currentDate } from 'helpers/dayAndTime';
import { FormValues } from './DonorReportForm';
import useReportActions from 'hooks/useReportAction';
import { IDonorReportData } from './DetailedReportView';
import moment from 'moment';

interface P {
	donorReportData: IDonorReportData[];
	formData: FormValues;
}

const DonorReportView = ({ donorReportData, formData }: P) => {
	const classes = useStyles();
	const componentRef = useRef(null);
	const { exportCSV, getDonorReportPDF } = useReportActions();

	const getTotal = () => {
		return donorReportData?.map((item) => item.totalDonationAmount).reduce((prev, curr) => prev + curr, 0);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const splitter = () => {
		if (!donorReportData?.length) return;
		const donorReport: any = [];
		const data = [];
		donorReportData?.map((row) => {
			donorReport.push({
				donorName: row.donorName || '-',
				donorId: row.donorId || '-',
				totalDonationAmount: row.totalDonationAmount ? (
					<FormatCurrency value={row.totalDonationAmount} />
				) : (
					'-'
				),
			});
		});
		for (let i = 0; i < donorReport.length; i += 15) {
			data.push(donorReport.slice(i, i + 15));
		}
		return data;
	};

	const printData = () => {
		const splitData = splitter();

		return (
			<TableContainer>
				{splitData?.map((data, index) => {
					return (
						<div className={classes.pageBreak} key={index}>
							<Table sx={{ minWidth: 700 }} aria-label="spanning table">
								<TableHead className={classes.tableHead}>
									<TableRow>
										<TableCell align="center">Donor #</TableCell>
										<TableCell align="center">Donor</TableCell>
										<TableCell align="center">Donation Amount</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map((row: any, index: number) => (
										<TableRow key={index} className={classes.tableRowWrap}>
											<TableCell align="center">{row.donorId}</TableCell>
											<TableCell align="center">{row.donorName}</TableCell>
											<TableCell align="center">{row.totalDonationAmount}</TableCell>
										</TableRow>
									))}
									{index === splitData.length - 1 && (
										<TableRow className={classes.tableTotal}>
											<TableCell align="center" colSpan={2}>
												<Stack
													py={1}
													sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
												>
													<Stack
														direction="row"
														justifyContent={'center'}
														sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
													>
														Grand Total
													</Stack>
												</Stack>
											</TableCell>
											<TableCell align="center" colSpan={1}>
												<Stack
													py={1}
													sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
												>
													<Stack
														direction="row"
														justifyContent={'center'}
														sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
													>
														<FormatCurrency value={getTotal()} />
													</Stack>
												</Stack>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</div>
					);
				})}
			</TableContainer>
		);
	};
	const handleExport = async (isPdf?: boolean) => {
		const payload = {
			start: moment(formData?.start).format('YYYY-MM-DD'),
			end: moment(formData?.end).format('YYYY-MM-DD'),
			minAmount: formData.minAmount ?? '',
			maxAmount: formData.maxAmount ?? '',
			receiptFrequency: formData.receiptFrequency.join(','),
			donationType: formData.donationType?.map((item: any) => item?.id).join(','),
			society: formData.society.join(','),
			preferredSociety: formData.preferredSociety.join(','),
			paymentMethod: formData.paymentMethod.join(','),
			donors: formData.donors?.map((item) => item.id).join(','),
			noMail: formData?.communication?.includes('noMail'),
			excludeFromParishList: formData?.communication?.includes('excludeFromParishList'),
			returnMail: formData?.communication?.includes('returnMail'),
			deceased: formData?.communication?.includes('deceased'),
			isCommunicationNone: formData?.communication?.includes('isCommunicationNone'),
			magazine: formData?.magazine as boolean,
			faithPropagation: formData?.preferredSociety?.includes('faithPropagation'),
			stPeter: formData?.preferredSociety?.includes('stPeter'),
			holyChildhood: formData?.preferredSociety?.includes('holyChildhood'),
			noFollowAppeal: formData?.noFollowAppeal as boolean,
			isPrefSocietyNone: formData?.preferredSociety?.includes('isPrefSocietyNone'),
			isdetailed: formData?.isdetailed,
		};
		if (isPdf === true) {
			await getDonorReportPDF(payload);
		} else {
			await exportCSV(payload);
		}
	};

	const printContent = () => (
		<TableContainer>
			<Table sx={{ minWidth: 700 }} aria-label="spanning table">
				<TableHead className={classes.tableHead}>
					<TableRow>
						<TableCell align="center">Donor #</TableCell>
						<TableCell align="center">Donor</TableCell>
						<TableCell align="center">Donation Amount</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{donorReportData?.map((row, index) => (
						<TableRow key={index} className={classes.tableRowWrap}>
							<TableCell align="center">{row.donorId || '-'}</TableCell>
							<TableCell align="center">{row.donorName || '-'}</TableCell>
							<TableCell align="center">
								{row.totalDonationAmount ? <FormatCurrency value={row.totalDonationAmount} /> : '-'}
							</TableCell>
						</TableRow>
					))}
					<TableRow className={classes.tableTotal}>
						<TableCell align="center" colSpan={2}>
							<Stack py={1} sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}>
								<Stack
									direction="row"
									justifyContent={'center'}
									sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
								>
									Grand Total
								</Stack>
							</Stack>
						</TableCell>
						<TableCell align="center" colSpan={1}>
							<Stack py={1} sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}>
								<Stack
									direction="row"
									justifyContent={'center'}
									sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
								>
									<FormatCurrency value={getTotal()} />
								</Stack>
							</Stack>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);

	return (
		<div>
			<Report
				title="Donor Summary Report"
				subTitle={`From ${convertDate(formData.start)} To ${convertDate(formData.end)}`}
				handlePrint={() => handleExport(true)}
				handleExport={handleExport}
			>
				<Box width="100%">{printContent()}</Box>
			</Report>
			<div style={{ display: 'none' }}>
				<div style={{ padding: 16 }} ref={componentRef}>
					<Stack sx={{ my: 2, px: 1 }}>
						<>
							<Stack width="100%" direction="row">
								<Stack flex={0.5} alignItems="flex-start" direction="column">
									<img src={Logo} alt="logo" width={40} height={50} />
								</Stack>

								<Stack sx={{ mb: 2, textAlign: 'center' }} alignItems="center" direction="column">
									<Typography sx={{ color: 'var(--mainColor)', fontSize: 20, fontWeight: 700 }}>
										Donor Summary Report
									</Typography>
									<Typography sx={{ color: 'var(--fontColorHeaderSubTitle)', fontSize: 18, fontWeight: 400 }}>
										{`From ${convertDate(formData.start)} To ${convertDate(formData.end)}`}
									</Typography>
								</Stack>
							</Stack>
							<Stack
								direction={'row'}
								justifyContent="flex-end"
								mb={3}
								sx={{ color: 'var(--fontColorHeaderSubTitle)', fontSize: 16, fontWeight: 400 }}
							>
								{currentDate()}
							</Stack>
						</>

						<Box width="100%">{printData()}</Box>
					</Stack>
				</div>
			</div>
		</div>
	);
};

export default DonorReportView;
