import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	menuItems: {
		flexDirection: 'column',
		alignItems: 'center',
		color: '#fff',
		'&.active': {
			background: '#499BFB14',
			'& .MuiListItemIcon-root': {
				color: '#fff',
			},
		},
	},
});

export const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		backgroundColor: '#44b700',
		color: '#44b700',
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		'&::after': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			borderRadius: '50%',
			animation: 'ripple 1.2s infinite ease-in-out',
			border: '1px solid currentColor',
			content: '""',
		},
	},
	'@keyframes ripple': {
		'0%': {
			transform: 'scale(.8)',
			opacity: 1,
		},
		'100%': {
			transform: 'scale(2.4)',
			opacity: 0,
		},
	},
}));

export const SmallAvatar = styled(Avatar)(({ theme }) => ({
	width: 22,
	height: 22,
	border: `2px solid ${theme.palette.background.paper}`,
}));

export const styles = {
	csvBtn: { background: 'white', textTransform: 'capitalize' },
	title: { color: 'var(--mainColor)' },
	addDonorBtn: { textTransform: 'none', mr: 2 },
	mb24: { mb: 3 },
};
