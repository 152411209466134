import React, { useEffect } from 'react';
import { TablePagination, Paper, Tooltip } from '@mui/material';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import { pagination } from 'Components/Common/Tables/Tables';
import { convertDate } from 'helpers/dayAndTime';
import useAppealActions from 'hooks/useAppealActions';
import { AppealData, StateType, Appeal } from 'contexts/intialstates/Types';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';

import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import { sortTable } from 'Components/Common/Tables/sortTable';
import { OrderType } from 'contexts/intialstates/Types';
interface P {
	reload: boolean;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	handleOpenEdit?: (editData: Appeal) => void;
	trimList: ColumnType[];
	setTrimList: React.Dispatch<React.SetStateAction<ColumnType[]>>;
	filterPayload: any;
	setFilterPayload: any;
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
}

const AppealTable = ({
	trimList,
	reload,
	setReload,
	handleOpenEdit,
	setTrimList,
	filterPayload,
	setFilterPayload,
	page,
	setPage,
}: P) => {
	const { getAppeals } = useAppealActions();
	const [pageSize, setPageSize] = React.useState(10);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [appeal, setAppeal] = React.useState<StateType<AppealData>>({
		data: null,
		error: null,
		loading: false,
	});

	useEffect(() => {
		getAppeals({ setAppeal, appeal, pageSize, page, orderBy, filterPayload });
	}, [page, pageSize, reload, orderBy]);

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleEdit = (id: number) => {
		const data = appeal?.data?.body.find((item) => item.id === id);
		data && handleOpenEdit?.(data);
	};

	const getTableData = () => {
		return appeal?.data?.body?.length
			? appeal?.data?.body.map((row: any, index: number) => {
					return {
						id: row.id,
						's/n': page * pageSize + index + 1,
						code: row?.code || 'N/A',
						description: row?.description || 'N/A',
						progress: row?.progress || 'N/A',
						createdBy: row?.createdBy?.name || 'N/A',
						updatedBy: row?.updatedBy?.name || 'N/A',
						created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
						updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType, index: any) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
							<span
								style={{ cursor: 'pointer' }}
								onClick={() =>
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									})
								}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<>
							<TableFiltering
								field={column.id}
								filterType={column.filterType}
								list={column.list}
								reload={reload}
								setIsReload={setReload}
								setFilterPayload={setFilterPayload}
								filterPayload={filterPayload}
								setPage={setPage}
								page={page}
							/>
						</>
					</>
				),
			};
		});
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<CustomTable
				loading={appeal?.loading}
				columnData={getColumnData()}
				tableData={getTableData()}
				handleDrag={handleDrag}
				handleResize={handleResize}
				emptyMessage={'No Appeal available'}
				handleRowClick={(row) => handleEdit(row.id as number)}
			/>
			{(appeal?.data?.body?.length as number) > 0 && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
					component="div"
					count={appeal?.data?.totalRecords as number}
					rowsPerPage={pageSize}
					page={page}
					sx={pagination}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</Paper>
	);
};

export default AppealTable;
