import { ResponseError, StateType } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { useContext } from 'react';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface IDownload {
	id: number;
	name: string;
	isReady: boolean;
	dateRequested: string;
	dateCompleted: string;
}

export interface DownloadData {
	totalRecords: number;
	body: IDownload[];
}

export interface GetDownloads {
	downloads: StateType<DownloadData>;
	setDownloads: React.Dispatch<React.SetStateAction<StateType<DownloadData>>>;
	pageNumber?: number;
	pageSize?: number;
}
export interface GetDownloadAlert {
	downloadAlert: StateType<boolean>;
	setDownloadAlert: React.Dispatch<React.SetStateAction<StateType<boolean>>>;
}

const useDownloads = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack, setPendingFiles, setPendingFilesAlert } = useContext(GlobalContexts);

	const getPendingFileAlert = async () => {
		try {
			const response = await api.get(`file/pending-downloads`);

			setPendingFilesAlert(!!response?.data?.result);
		} catch (err) {
			return null;
		}
	};
	const getFiles = async ({ downloads, setDownloads, pageNumber, pageSize }: GetDownloads) => {
		try {
			setDownloads({
				...downloads,
				loading: true,
			});
			let response;
			if (pageNumber) {
				response = await api.get(`file/list?pageNumber=${pageNumber + 1}&pageSize=${pageSize}`);
			} else {
				response = await api.get(`file/list?pageSize=${pageSize}`);
			}

			setDownloads({
				...downloads,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || downloads.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDownloads({
					...downloads,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};
	const getFile = async (fileId: number) => {
		try {
			const response = await api.get(`file/${fileId}`);

			const file: IDownload = response?.data?.result;
			if (file?.isReady) {
				setSimpleErrorSnack({
					message: `${file?.name?.toLowerCase()} is ready for download`,
					severity: 'success',
					show: true,
				});
				setPendingFiles((prev) => prev.filter((pId) => pId !== fileId));
				return true;
			}
			return null;
		} catch (err) {
			return null;
		}
	};
	const downloadFile = async (fileId: number, filename: string) => {
		try {
			const response = await api.get(`file/${fileId}/download`, {
				responseType: 'blob',
			});
			const url = window.URL.createObjectURL(new Blob([response?.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${filename}`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			await getPendingFileAlert();
			return true;
		} catch (err) {
			setSimpleErrorSnack({
				message: `Error downloading ${filename}}`,
				severity: 'error',
				show: true,
			});
		}
	};
	const deleteFile = async (fileId: number): Promise<boolean> => {
		try {
			const response = await api.remove(`file/${fileId}`);
			if (response?.data?.statusCode === 0) {
				await getPendingFileAlert();
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	return {
		getPendingFileAlert,
		getFiles,
		getFile,
		downloadFile,
		deleteFile,
	};
};

export default useDownloads;
