import React, { useEffect } from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';

const Accordion = styled((props: AccordionProps) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	borderRadius: '10px 10px 0px 0px',
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&:before': {
		display: 'none',
	},
}));

interface AccordionSummaryPropValues extends AccordionSummaryProps {
	isReport?: boolean;
	isIconEnd?: boolean;
}

const AccordionSummary = styled((props: AccordionSummaryPropValues) => <MuiAccordionSummary {...props} />)(
	({ theme, isReport, isIconEnd }) => ({
		backgroundColor: 'var(--mainBackground)',
		borderRadius: '10px 10px 0px 0px',
		flexDirection: !isIconEnd ? 'row-reverse' : 'row',
		'& .MuiAccordionSummary-expandIconWrapper': {
			backgroundColor: isReport || isIconEnd ? 'initial' : 'var(--white)',
		},
		'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
			transform: 'rotate(90deg)',
		},
		'& .MuiAccordionSummary-content': {
			marginLeft: theme.spacing(1),
		},
	}),
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: '8px 34px 34px',
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface P {
	header: any;
	content?: any;
	schoolKey?: string;
	handleToggleExpand?: (key: string) => void;
	expandValue?: boolean;
	isReport?: boolean;
	isIconEnd?: boolean;
}
const Accordian = ({
	isReport,
	header,
	content,
	schoolKey,
	handleToggleExpand,
	expandValue,
	isIconEnd,
}: P) => {
	const [expand, setExpand] = React.useState(false);

	useEffect(() => {
		if (expandValue !== undefined) setExpand(expandValue);
	}, [expandValue]);

	const toggleAcordion = () => {
		setExpand((prev) => !prev);
		schoolKey && handleToggleExpand?.(schoolKey);
	};

	return (
		<Accordion expanded={expand}>
			<AccordionSummary
				isReport={isReport}
				isIconEnd={isIconEnd}
				expandIcon={
					<ArrowForwardIosSharpIcon
						sx={{ fontSize: `${isReport ? '0.9rem' : '18px'}` }}
						onClick={() => toggleAcordion()}
					/>
				}
				onClick={(event) => {
					event.stopPropagation();
					event.preventDefault();
				}}
			>
				<div style={{ width: '100%' }}>{header}</div>
			</AccordionSummary>
			<AccordionDetails>{content}</AccordionDetails>
		</Accordion>
	);
};

export default Accordian;
