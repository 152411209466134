import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import { Search, MoreVert } from '@mui/icons-material';
import { Checkbox, FormControlLabel, FormGroup, Stack, TextField, InputAdornment } from '@mui/material';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import { Box } from '@mui/system';
import { FilterPayload } from 'contexts/intialstates/Types';
import { makeStyles } from '@material-ui/core';

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 250,
		px: 2,
		color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			},
		},
	},
}));

const useStyles = makeStyles({
	iconMoreVert: {
		position: 'relative',
		top: '4px',
		cursor: 'pointer',
	},
});

interface TableFilteringProps {
	filterType: any;
	list?: any;
	field: string;
	setIsReload: React.Dispatch<React.SetStateAction<boolean>>;
	reload: boolean;
	filterPayload: any[];
	setFilterPayload: React.Dispatch<React.SetStateAction<any[]>>;
	setPage?: React.Dispatch<React.SetStateAction<number>>;
	page?: number;
}

interface UpdateFormValueProps {
	key: string;
	formField: string;
	list?: boolean;
	date?: boolean;
}

const TableFiltering = ({
	filterType,
	list,
	field,
	setIsReload,
	reload,
	filterPayload,
	setFilterPayload,
	setPage,
	page,
}: TableFilteringProps) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const classes = useStyles();
	const [checkList, setCheckList] = React.useState<any>([]);
	const [formData, setFormData] = React.useState<FilterPayload>({
		value: null,
		field: null,
		to: null,
		from: null,
		query: null,
	});
	const [listValues, setListValues] = React.useState<Array<string>>([]);
	const [disableRange, setDisableRange] = React.useState<boolean>(false);
	const [disableValues, setDisableValues] = React.useState<boolean>(false);

	const handleFilterChange = () => {
		if (page === 0) {
			setIsReload(!reload);
		} else {
			setPage?.(0);
		}
	};

	React.useEffect(() => {
		if (!list) return;
		const data = list.map((listItem: any) => {
			return {
				name: listItem.name,
				isChecked: false,
				label: listItem?.label,
			};
		});
		setCheckList(data);
	}, []);

	const updateFormValue =
		({ key, formField, list, date }: UpdateFormValueProps) =>
		(e: any) => {
			let temp;

			if (list && key === 'value') {
				const tempCheckList = checkList;

				const index = checkList.findIndex((listItem: any) => listItem.name === e.target.value);
				tempCheckList[index].isChecked = !tempCheckList[index]?.isChecked;

				let arrList = listValues;
				if (index > -1 && tempCheckList[index].isChecked) {
					arrList.push(e.target.value);
				}
				if (index > -1 && tempCheckList[index].isChecked === false) {
					arrList = arrList.filter((ele: any) => ele !== e.target.value);
				}
				setCheckList(tempCheckList);
				setListValues(arrList);
				if (arrList.length === 0) {
					temp = { ...formData, [key]: null, field: formField };
					setFormData(temp as FilterPayload);
					return;
				}
				temp = { ...formData, [key]: arrList, field: formField };
				setFormData(temp as FilterPayload);
				return;
			}

			if (date && key === 'value') {
				if (e.target.value) {
					setDisableRange(true);
					temp = {
						...formData,
						[key]: e.target.value,
						field: formField,
						type: 'date',
					};
					setFormData(temp as FilterPayload);
				} else {
					setDisableRange(false);
				}
				return;
			}
			if (key === 'value') {
				if (e.target.value.length === 0) {
					setDisableRange(false);
					temp = { ...formData, [key]: null, field: formField };
					setFormData(temp as FilterPayload);
				} else {
					setDisableRange(true);
					temp = { ...formData, [key]: [e.target.value], field: formField };
					setFormData(temp as FilterPayload);
				}

				return;
			}

			if (date) {
				if (e.target.value) {
					setDisableValues(true);
					temp = {
						...formData,
						[key]: e.target.value,
						field: formField,
						type: 'date',
					};
					setFormData(temp as FilterPayload);
				} else {
					setDisableValues(false);
				}
				return;
			}

			if (e.target.value === '') {
				setDisableValues(false);
				temp = { ...formData, [key]: null, field: formField };
				setFormData(temp as FilterPayload);
			} else {
				setDisableValues(true);
				temp = { ...formData, [key]: e.target.value, field: formField };
				setFormData(temp as FilterPayload);
			}
		};

	const submitFilter = (payload: any) => {
		if (payload) {
			const newPayload = filterPayload;

			const arrIndex = newPayload.findIndex((ele) => {
				return ele.field == payload.field;
			});

			if (arrIndex > -1) {
				if (filterType === 'list') {
					delete newPayload[arrIndex]['value'];
				}
				newPayload[arrIndex] = { ...newPayload[arrIndex], ...payload };

				const dateIndex = newPayload.findIndex((ele) => {
					return ele.type === 'date';
				});
				if (dateIndex > -1) {
					let to = newPayload[dateIndex].to ? new Date(newPayload[dateIndex].to) : null;
					let value = newPayload[dateIndex].value ? new Date(newPayload[dateIndex].value) : null;
					let from = newPayload[dateIndex].from ? new Date(newPayload[dateIndex].from) : null;

					if (value || to || from) {
						if (value && isNaN(value.getFullYear())) {
							value = null;
						}
						if (from && isNaN(from.getFullYear())) {
							from = null;
						}
						if (to && isNaN(to.getFullYear())) {
							to = null;
						}
					}
					newPayload[dateIndex] = {
						...newPayload[arrIndex],
						...payload,
						to: to ? `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}` : null,
						from: from ? `${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}` : null,
						value: value ? [`${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`] : null,
					};
					delete newPayload[dateIndex].type;
				}
				const nullIndex = newPayload.findIndex(
					(ele: FilterPayload) =>
						ele.value === null && ele.from === null && ele.to === null && ele.query === null,
				);
				if (nullIndex > -1) {
					newPayload.splice(nullIndex, 1);
				}
			} else {
				newPayload.push(payload);

				const dateIndex = newPayload.findIndex((ele) => {
					return ele.type === 'date';
				});
				if (dateIndex > -1) {
					let to = newPayload[dateIndex].to ? new Date(newPayload[dateIndex].to) : null;
					let value = newPayload[dateIndex].value ? new Date(newPayload[dateIndex].value) : null;
					let from = newPayload[dateIndex].from ? new Date(newPayload[dateIndex].from) : null;

					if (value || to || from) {
						if (value && isNaN(value.getFullYear())) {
							value = null;
						}
						if (from && isNaN(from.getFullYear())) {
							from = null;
						}
						if (to && isNaN(to.getFullYear())) {
							to = null;
						}
					}
					newPayload[dateIndex] = {
						...newPayload[arrIndex],
						...payload,
						to: to ? `${to.getFullYear()}-${to.getMonth() + 1}-${to.getDate()}` : null,
						from: from ? `${from.getFullYear()}-${from.getMonth() + 1}-${from.getDate()}` : null,
						value: value ? [`${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`] : null,
					};
					delete newPayload[dateIndex].type;
				}
				const nullIndex = newPayload.findIndex(
					(ele: FilterPayload) =>
						ele.value === null && ele.from === null && ele.to === null && ele.query === null,
				);
				if (nullIndex > -1) {
					newPayload.splice(nullIndex, 1);
				}
			}
			setFilterPayload([...newPayload]);
			setIsReload(!reload);
		}
		handleClear();
		handleFilterChange();
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClear = () => {
		setAnchorEl(null);
	};

	let output;

	if (filterType == 'date') {
		output = (
			<>
				<span
					id="demo-customized-button"
					aria-controls={open ? 'demo-customized-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}
				>
					<MoreVert className={classes.iconMoreVert} fontSize={'small'} />
				</span>
				<StyledMenu
					id="demo-customized-menu"
					MenuListProps={{
						'aria-labelledby': 'demo-customized-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
				>
					<Box sx={{ mt: 2 }}>
						<Box sx={{ mx: 2, mb: 3 }}>
							<span>Date:</span>
							<Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }} spacing={2}>
								<Box sx={{ width: 200 }}>
									<BasicDatePicker
										disabled={disableValues}
										value={formData?.value}
										setValue={updateFormValue({ key: 'value', formField: field, date: true })}
										helperText={'Empty the range fields to set this'}
									/>
								</Box>
							</Stack>
						</Box>
						<Box sx={{ mx: 2, mb: 3 }}>
							<span>Date From:</span>
							<Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }} spacing={2}>
								<Box sx={{ width: 200 }}>
									<BasicDatePicker
										value={formData?.from}
										disabled={disableRange}
										setValue={updateFormValue({ key: 'from', formField: field, date: true })}
										helperText={'Empty the value field to set this'}
									/>
								</Box>
							</Stack>
						</Box>

						<Box sx={{ mx: 2 }}>
							<span>Date To:</span>
							<Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }} spacing={2}>
								<Box sx={{ width: 200 }}>
									<BasicDatePicker
										disabled={disableRange}
										value={formData?.to}
										setValue={updateFormValue({ key: 'to', formField: field, date: true })}
										helperText={'Empty the value field to set this'}
									/>
								</Box>
							</Stack>
						</Box>
					</Box>

					<Divider sx={{ mb: 0.5, mt: 3 }} />
					<Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
						<Button variant="contained" onClick={() => submitFilter(formData)} sx={{ fontSize: 12 }}>
							Filter
						</Button>
						<Button onClick={handleClear} sx={{ fontSize: 12, color: 'black', fontWeight: 600 }}>
							Cancel
						</Button>
					</Stack>
				</StyledMenu>
			</>
		);
	}
	if (filterType === 'list') {
		if (list.length > 0) {
			output = (
				<>
					<span
						id="demo-customized-button"
						aria-controls={open ? 'demo-customized-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
					>
						<MoreVert className={classes.iconMoreVert} fontSize={'small'} />
					</span>
					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{
							'aria-labelledby': 'demo-customized-button',
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
					>
						<Stack alignItems="center" justifyContent="center" sx={{ my: 4 }}>
							<Box sx={{ width: 200 }}>
								{checkList.map((ele: any) => {
									return (
										<FormGroup key={ele.name}>
											<FormControlLabel
												sx={{
													textTransform: `${
														field === 'society' || field === 'societies' ? 'uppercase' : 'capitalize'
													}`,
												}}
												control={
													<Checkbox
														checked={ele.isChecked}
														value={ele.name}
														onChange={updateFormValue({ key: 'value', formField: field, list })}
													/>
												}
												label={ele?.label || ele.name}
											/>
										</FormGroup>
									);
								})}
							</Box>
						</Stack>
						<Divider sx={{ mb: 0.5, mt: 3 }} />
						<Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
							<Button variant="contained" onClick={() => submitFilter(formData)} sx={{ fontSize: 12 }}>
								Filter
							</Button>
							<Button onClick={handleClear} sx={{ fontSize: 12, color: 'black', fontWeight: 600 }}>
								Cancel
							</Button>
						</Stack>
					</StyledMenu>
				</>
			);
		}
	}
	if (filterType === 'value') {
		output = (
			<>
				<span
					id="demo-customized-button"
					aria-controls={open ? 'demo-customized-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}
				>
					<MoreVert className={classes.iconMoreVert} fontSize={'small'} />
				</span>
				<StyledMenu
					id="demo-customized-menu"
					MenuListProps={{
						'aria-labelledby': 'demo-customized-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
				>
					<Stack alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
						<Box sx={{ width: 200 }}>
							<TextField
								id="standard-basic"
								variant="outlined"
								placeholder="Value"
								value={formData?.value}
								onChange={updateFormValue({ key: 'value', formField: field })}
								disabled={disableValues}
								helperText={disableValues && 'Empty the max and min fields to set this'}
							/>
						</Box>
						<Box sx={{ width: 200, mt: 3 }}>
							<TextField
								id="standard-basic"
								variant="outlined"
								placeholder="Min"
								value={formData?.from}
								onChange={updateFormValue({ key: 'from', formField: field })}
								disabled={disableRange}
								helperText={disableRange && 'Empty the value field to set this'}
							/>
						</Box>
						<Box sx={{ width: 200, mt: 3 }}>
							<TextField
								id="standard-basic"
								variant="outlined"
								placeholder="Max"
								value={formData?.to}
								onChange={updateFormValue({ key: 'to', formField: field })}
								disabled={disableRange}
								helperText={disableRange && 'Empty the value field to set this'}
							/>
						</Box>
					</Stack>

					<Divider sx={{ mb: 0.5, mt: 3 }} />
					<Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
						<Button variant="contained" onClick={() => submitFilter(formData)} sx={{ fontSize: 12 }}>
							Filter
						</Button>
						<Button onClick={handleClear} sx={{ fontSize: 12, color: 'black', fontWeight: 600 }}>
							Cancel
						</Button>
					</Stack>
				</StyledMenu>
			</>
		);
	}
	if (filterType === 'text') {
		output = (
			<>
				<span
					id="demo-customized-button"
					aria-controls={open ? 'demo-customized-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}
				>
					<MoreVert className={classes.iconMoreVert} fontSize={'small'} />
				</span>
				<StyledMenu
					id="demo-customized-menu"
					MenuListProps={{
						'aria-labelledby': 'demo-customized-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
				>
					<Stack alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
						<Box sx={{ width: 200 }}>
							<TextField
								variant="outlined"
								value={formData?.query}
								onChange={updateFormValue({ key: 'query', formField: field })}
								disabled={false}
								placeholder="Value"
							/>
						</Box>
					</Stack>

					<Divider sx={{ mb: 0.5, mt: 3 }} />
					<Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
						<Button variant="contained" onClick={() => submitFilter(formData)} sx={{ fontSize: 12 }}>
							Filter
						</Button>
						<Button onClick={handleClear} sx={{ fontSize: 12, color: 'black', fontWeight: 600 }}>
							Cancel
						</Button>
					</Stack>
				</StyledMenu>
			</>
		);
	}

	return <>{output}</>;
};
interface DonorFilteringProps {
	searchDonor?: string;
	filterType?: string;
	setSearchDonor: React.Dispatch<React.SetStateAction<string>>;
	setPage?: React.Dispatch<React.SetStateAction<number>>;
}
export const DonorFiltering = ({ searchDonor, setSearchDonor, filterType, setPage }: DonorFilteringProps) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const classes = useStyles();
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClear = () => {
		setAnchorEl(null);
	};
	const submitFilter = () => {
		setSearchDonor('');
		handleClear();
	};
	return (
		<>
			{' '}
			{filterType === 'search' && (
				<>
					<span
						id="demo-customized-button"
						aria-controls={open ? 'demo-customized-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={handleClick}
					>
						<MoreVert className={classes.iconMoreVert} fontSize={'small'} />
					</span>
					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{
							'aria-labelledby': 'demo-customized-button',
						}}
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
					>
						<Stack alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
							<Box sx={{ width: 200 }}>
								<TextField
									onChange={(e) => {
										setSearchDonor(e.target.value);
										setPage?.(0);
									}}
									value={searchDonor}
									placeholder="Filter by donor"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Search />
											</InputAdornment>
										),
									}}
									sx={{
										background: 'white',
										border: 'none',
										height: 50,
										borderRadius: '4px',
										input: { width: '150px', height: '100%', border: 'none' },
										div: { border: 'none' },
									}}
								/>
							</Box>
						</Stack>

						<Divider sx={{ mb: 0.5, mt: 3 }} />
						<Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
							<Button variant="contained" onClick={() => submitFilter()} sx={{ fontSize: 12 }}>
								Clear
							</Button>
							<Button onClick={handleClear} sx={{ fontSize: 12, color: 'black', fontWeight: 600 }}>
								Cancel
							</Button>
						</Stack>
					</StyledMenu>
				</>
			)}
		</>
	);
};

export default TableFiltering;
