import React, { useEffect, useState } from 'react';
import {
	Typography,
	Divider,
	Grid,
	MenuItem,
	Select,
	Stack,
	FormControlLabel,
	Checkbox,
	TextField,
	Autocomplete,
	IconButton,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGlobalStyles } from 'Components/Common/global/global';
import {
	AssistantDirection,
	AccountCircle,
	Phone,
	Email,
	LocationOn,
	LocationCity,
	BrandingWatermark,
	Numbers,
	Contacts,
	MarkAsUnread,
	AutoGraph,
	Approval,
	Domain,
	Search,
} from '@mui/icons-material';
import { ContentSkeleton } from 'Components/Deposits/CreateEditDepositWithDonations/Content';
import useDioceseActions from 'hooks/useDioceseActions';
import useSchoolBoardActions from 'hooks/useSchoolBoardActions';
import useSchoolStatusActions from 'hooks/useSchoolStatusActions';
import useSchoolActions from 'hooks/useSchoolActions';
import {
	DioceseData,
	StateType,
	School,
	SchoolBoardData,
	SchoolStatus,
	Diocese,
	SchoolBoard,
	OrderType,
} from 'contexts/intialstates/Types';
import ProvinceAutocomplete from 'Components/Province';
import useDebounce from 'Components/Reusable/hooks/debounce';
import AdvancedSearchModal, { Field } from 'Components/Reusable/Modals/AdvancedSearch/AdvancedSearchModal';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { ColumnType, schoolBoardHead, dioceseHead } from 'Components/Common/Tables/TableHeaders';
import { searchDioceseField, serachSchoolBoardField } from 'Components/Reusable/Modals/AdvancedSearch/Mock';
import DioceseTable from 'Components/AdminPanel/Diocese/DioceseTable';
import SchoolBoardTable from 'Components/AdminPanel/SchoolBoard/SchoolBoardTable';

export interface SchoolValues {
	name: string;
	districtCode: string;
	principal: string;
	contact: string;
	seal: string;
	streetline1: string;
	streetline2: string;
	city: string;
	province: string;
	provinceId?: number;
	postalCode: string;
	phone: string;
	fax: string;
	email: string;
	useEmail: boolean;
	useEFile: boolean;
	dioceseId?: number;
	schoolBoardId?: number;
	schoolStatusId?: number;
	code?: string;
	country: string;
}

interface P {
	initial: SchoolValues;
	setInitial: React.Dispatch<React.SetStateAction<SchoolValues>>;
	formErrors: string[];
	setFormErrors: React.Dispatch<React.SetStateAction<string[]>>;
}

const CreateEditSchools = ({ initial, setInitial, formErrors, setFormErrors }: P) => {
	const { getSchoolBoards, getSchoolBoardSearch } = useSchoolBoardActions();

	const [dioceses, setDioceses] = React.useState<StateType<DioceseData>>({
		data: null,
		error: null,
		loading: false,
	});

	const [schoolBoardSearch, setSchoolBoardSearch] = React.useState<StateType<SchoolBoard[]>>({
		data: null,
		error: null,
		loading: false,
	});

	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);

	const [boardSearchTerm, setBoardSearchTerm] = useState('');

	const [boardName, setBoardName] = React.useState<string | undefined>('');
	const boardDebouncedSearchTerm = useDebounce(boardSearchTerm, 1000);

	const [visibleSearchBoard, setVisibleSearchBoard] = useState(false);
	const [searchFieldsBoard, setSearchFieldsBoard] = useState<Field[]>(serachSchoolBoardField);
	const [open, setOpen] = React.useState<any>({ state: false, type: '' });
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');

	const global = useGlobalStyles();
	const { id } = useParams();

	const { getDioceses, getDioceseBySearch } = useDioceseActions();
	const { getAllSchoolBoards } = useSchoolBoardActions();
	const { getSchoolStatus } = useSchoolStatusActions();
	const { getSchoolsByID } = useSchoolActions();

	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [filterPayload, setFilterPayload] = useState<Array<any>>([]);

	const [schoolBoards, setSchoolBoards] = React.useState<StateType<SchoolBoardData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [allSchoolStatus, setAllSchoolStatus] = React.useState<StateType<SchoolStatus[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [school, setSchool] = React.useState<StateType<School>>({
		data: null,
		error: null,
		loading: false,
	});
	const [dioceseName, setDioceseName] = React.useState<string | undefined>('');
	const [searchDiocese, setSearchDiocese] = React.useState<StateType<Diocese[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [dioceseSearchTerm, setDioceseSearchTerm] = useState('');
	const dioceseDebouncedSearchTerm = useDebounce(dioceseSearchTerm, 1000);
	const [visibleSearchDiocese, setVisibleSearchDiocese] = useState(false);
	const [searchFieldsDiocese, setSearchFieldsDiocese] = useState<Field[]>(searchDioceseField);

	useEffect(() => {
		if (!id) return;
		getSchoolsByID({ setSchool, school, id: parseInt(id) });
	}, [id]);

	useEffect(() => {
		if (!school?.data) return;
		const {
			name,
			districtCode,
			principal,
			contact,
			seal,
			streetline1,
			streetline2,
			city,
			province,
			postalCode,
			phone,
			fax,
			email,
			useEmail,
			useEFile,
			diocese,
			schoolBoard,
			schoolStatus,
			code,
			country,
		} = school?.data;
		setInitial({
			name: name ?? '',
			districtCode,
			principal: principal ?? '',
			contact: contact ?? '',
			seal,
			streetline1,
			streetline2,
			city,
			province: province?.provinceName ?? '',
			postalCode,
			phone: phone ?? '',
			fax,
			email: email ?? '',
			useEmail,
			useEFile,
			dioceseId: diocese?.id,
			schoolBoardId: schoolBoard?.id,
			schoolStatusId: schoolStatus?.id,
			code,
			country,
		});
		if (diocese) {
			setDioceseName(diocese.dioceseName);
		}
		if (schoolBoard) {
			setBoardName(schoolBoard.name);
		}
	}, [school]);

	useEffect(() => {
		getAllSchoolBoards({
			setSchoolBoards,
			schoolBoards,
		});
		getSchoolStatus({ allSchoolStatus, setAllSchoolStatus });
	}, []);
	useEffect(() => {
		if (!dioceseDebouncedSearchTerm) return;
		getDioceseBySearch({ searchDiocese, setSearchDiocese, searchTerm: dioceseSearchTerm });
	}, [dioceseDebouncedSearchTerm]);
	useEffect(() => {
		getSchoolBoards({
			archive: '&Filters=field=archived;value=false',
			schoolBoards,
			setSchoolBoards,
			page,
			pageSize,
			orderBy,
			filterPayload,
		});
		getDioceses({
			archive: '&Filters=field=archived;value=false',
			setDioceses,
			dioceses,
			pageSize,
			page,
			orderBy,
			filterPayload,
		});
	}, [page, pageSize, orderBy]);
	useEffect(() => {
		if (!boardDebouncedSearchTerm) return;
		getSchoolBoardSearch({ schoolBoardSearch, setSchoolBoardSearch, searchTerm: boardSearchTerm });
	}, [boardDebouncedSearchTerm]);
	const handleChange = (field: string, value: string | number | boolean) => {
		setInitial({
			...initial,
			[field]: value,
		});
		if (formErrors.includes(field)) {
			setFormErrors(formErrors.filter((item) => item !== field));
		}
	};
	const handleVisibleSearchDiocese = () => {
		setVisibleSearchDiocese(!visibleSearchDiocese);
	};
	const handleSearchDiocese = () => {
		const payload: any = searchFieldsDiocese.map(({ key, value }) => {
			if (value) {
				return {
					field: key,
					query: key !== 'phone' ? value : null,
					value: key === 'phone' ? [value] : null,
					from: null,
					to: null,
				};
			}
		});

		setFilterPayload(payload.filter(Boolean));
	};
	const handleSelectRowDiocese = (row: any) => {
		const temp = { ...initial, dioceseId: parseInt(row.id) };
		setInitial(temp as any);
		setDioceseName(row.dioceseName);
		handleVisibleSearchDiocese();
	};
	const handleVisibleSearchBoard = () => {
		setVisibleSearchBoard(!visibleSearchBoard);
	};
	const handleSearchBoard = () => {
		const payload: any = searchFieldsBoard.map(({ key, value }) => {
			if (value) {
				return {
					field: key,
					query: key !== 'phone' ? value : null,
					value: key === 'phone' ? [value] : null,
					from: null,
					to: null,
				};
			}
		});
		setFilterPayload(payload.filter(Boolean));
	};
	const handleSelectRowBoard = (row: any) => {
		const temp = { ...initial, schoolBoardId: parseInt(row.id) };
		setInitial(temp as any);
		setBoardName(row.name);
		handleVisibleSearchBoard();
	};
	return (
		<Stack>
			{school?.loading ? (
				<ContentSkeleton />
			) : (
				<>
					<Typography sx={{ fontSize: 14, fontWeight: 600, mt: 2 }}>Basic Details</Typography>
					<Divider />
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<BrandingWatermark className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Name
									</Typography>
								</Grid>
							</Grid>
							<Stack>
								<TextField
									size="small"
									fullWidth
									variant="outlined"
									placeholder="Name"
									value={initial.name}
									onChange={(e) => {
										handleChange('name', e.target.value);
									}}
								/>
								{formErrors.includes('name') && <span className={global.errorText}>Name is required</span>}
							</Stack>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<Numbers className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Code
									</Typography>
								</Grid>
							</Grid>
							<Grid>
								<TextField
									size="small"
									fullWidth
									variant="outlined"
									placeholder="Code"
									disabled={true}
									value={initial.code}
									className={global.disabledInput}
								/>
								<div className={global.formLabelText}>This is auto generated code</div>
							</Grid>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<Contacts className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Principal
									</Typography>
								</Grid>
							</Grid>
							<Stack>
								<TextField
									size="small"
									fullWidth
									variant="outlined"
									placeholder="Principal"
									value={initial.principal}
									onChange={(e) => {
										handleChange('principal', e.target.value);
									}}
								/>
								{formErrors.includes('principal') && (
									<span className={global.errorText}>Principal is required</span>
								)}
							</Stack>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<Domain className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										School Board
									</Typography>
								</Grid>
							</Grid>
							<Stack alignItems="center" direction="row" spacing={1}>
								<Autocomplete
									freeSolo
									value={boardName ?? ''}
									loading={schoolBoardSearch.loading}
									loadingText="loading..."
									fullWidth
									size="small"
									onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
										handleChange('schoolBoardId', val?.id);
										setBoardName(val?.label);
									}}
									options={(schoolBoardSearch?.data || []).map((s: SchoolBoard) => ({
										id: s.id,
										label: `${s.name}`,
									}))}
									renderInput={(params) => (
										<Stack alignItems="center" direction="row" spacing={1}>
											<TextField
												{...params}
												variant="outlined"
												label=""
												InputProps={{
													...params.InputProps,
												}}
												onChange={(event: any) => {
													setBoardSearchTerm(event.target.value);
												}}
											/>
											<IconButton
												size="small"
												onClick={() => handleVisibleSearchBoard()}
												sx={{ borderRadius: '5px', border: '1px solid var(--mainColor)' }}
											>
												<Search color="primary" />
											</IconButton>
										</Stack>
									)}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<AutoGraph className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										School Status
									</Typography>
								</Grid>
							</Grid>
							<Stack>
								<Select
									fullWidth
									size="small"
									onChange={(e) => {
										handleChange('schoolStatusId', e.target.value);
									}}
									value={initial.schoolStatusId ? initial.schoolStatusId : ''}
								>
									{allSchoolStatus?.data?.map(({ id, status }) => (
										<MenuItem key={id} value={id}>
											{status}
										</MenuItem>
									))}
								</Select>
								{formErrors.includes('schoolStatusId') && (
									<span className={global.errorText}>School Status is required</span>
								)}
							</Stack>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<Domain className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Diocese
									</Typography>
								</Grid>
							</Grid>
							<Stack alignItems="center" direction="row" spacing={1}>
								<Autocomplete
									freeSolo
									value={dioceseName ?? ''}
									loading={searchDiocese.loading}
									loadingText="loading..."
									size="small"
									fullWidth
									onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
										handleChange('dioceseId', val?.id);
										setDioceseName(val?.label);
									}}
									options={(searchDiocese?.data || []).map((d: Diocese) => ({
										id: d.id,
										label: `${d.dioceseName}`,
									}))}
									renderInput={(params) => (
										<Stack alignItems="center" direction="row" spacing={1}>
											<TextField
												{...params}
												variant="outlined"
												label=""
												InputProps={{
													...params.InputProps,
												}}
												onChange={(event: any) => {
													setDioceseSearchTerm(event.target.value);
												}}
											/>

											<IconButton
												size="small"
												onClick={() => handleVisibleSearchDiocese()}
												sx={{ borderRadius: '5px', border: '1px solid var(--mainColor)' }}
											>
												<Search color="primary" />
											</IconButton>
										</Stack>
									)}
								/>
							</Stack>
							{formErrors.includes('dioceseId') && (
								<span className={global.errorText}>Diocese is required</span>
							)}
						</Grid>

						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<AssistantDirection className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										District Code
									</Typography>
								</Grid>
							</Grid>
							<TextField
								size="small"
								fullWidth
								variant="outlined"
								placeholder="District Code"
								value={initial.districtCode}
								onChange={(e) => {
									handleChange('districtCode', e.target.value);
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<Approval className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Seal
									</Typography>
								</Grid>
							</Grid>
							<TextField
								size="small"
								fullWidth
								variant="outlined"
								placeholder="Seal"
								value={initial.seal}
								onChange={(e) => {
									handleChange('seal', e.target.value);
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<FormControlLabel
								name="useEFile"
								value="top"
								control={
									<Checkbox
										checked={initial.useEFile}
										onChange={(e) => handleChange('useEFile', e.target.checked)}
									/>
								}
								label="Use EFile"
							/>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<FormControlLabel
								name="useEmail"
								value="top"
								control={
									<Checkbox
										checked={initial.useEmail}
										onChange={(e) => handleChange('useEmail', e.target.checked)}
									/>
								}
								label="Use Email"
							/>
						</Grid>
					</Grid>
					<Typography sx={{ fontSize: 14, fontWeight: 600, mt: 2 }}>Contact Details</Typography>
					<Divider />
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<AccountCircle className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Contact
									</Typography>
								</Grid>
							</Grid>
							<Stack>
								<TextField
									size="small"
									fullWidth
									variant="outlined"
									placeholder="Contact"
									value={initial.contact}
									onChange={(e) => {
										handleChange('contact', e.target.value);
									}}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<Phone className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Phone
									</Typography>
								</Grid>
							</Grid>
							<TextField
								size="small"
								fullWidth
								variant="outlined"
								placeholder="Phone"
								type="number"
								value={initial.phone}
								onChange={(e) => {
									handleChange('phone', e.target.value);
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<Phone className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Fax
									</Typography>
								</Grid>
							</Grid>
							<TextField
								size="small"
								fullWidth
								variant="outlined"
								placeholder="Fax"
								value={initial.fax}
								onChange={(e) => {
									handleChange('fax', e.target.value);
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<Email className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Email
									</Typography>
								</Grid>
							</Grid>
							<TextField
								size="small"
								fullWidth
								variant="outlined"
								placeholder="Email"
								value={initial.email}
								onChange={(e) => {
									handleChange('email', e.target.value);
								}}
							/>
						</Grid>
					</Grid>
					<Typography sx={{ fontSize: 14, fontWeight: 600, mt: 2 }}>Address Details</Typography>
					<Divider />
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<LocationOn className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Streetline 1
									</Typography>
								</Grid>
							</Grid>
							<TextField
								size="small"
								fullWidth
								variant="outlined"
								placeholder="Streetline 1"
								value={initial.streetline1}
								onChange={(e) => {
									handleChange('streetline1', e.target.value);
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<LocationOn className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Streetline 2
									</Typography>
								</Grid>
							</Grid>
							<TextField
								size="small"
								fullWidth
								variant="outlined"
								placeholder="Streetline 2"
								value={initial.streetline2}
								onChange={(e) => {
									handleChange('streetline2', e.target.value);
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<LocationCity className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										City
									</Typography>
								</Grid>
							</Grid>
							<TextField
								size="small"
								fullWidth
								variant="outlined"
								placeholder="City"
								value={initial.city}
								onChange={(e) => {
									handleChange('city', e.target.value);
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<AssistantDirection className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Province
									</Typography>
								</Grid>
							</Grid>
							<ProvinceAutocomplete
								value={initial.province}
								handleChange={(val) => handleChange('provinceId', parseInt(val.id))}
							/>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<MarkAsUnread className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Postal Code
									</Typography>
								</Grid>
							</Grid>
							<TextField
								size="small"
								fullWidth
								variant="outlined"
								placeholder="Postal Code"
								value={initial.postalCode}
								onChange={(e) => {
									handleChange('postalCode', e.target.value);
								}}
							/>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Grid container>
								<Grid item>
									<AssistantDirection className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Country
									</Typography>
								</Grid>
							</Grid>
							<TextField
								size="small"
								fullWidth
								variant="outlined"
								placeholder="Country"
								value={initial.country}
								onChange={(e) => {
									handleChange('country', e.target.value);
								}}
							/>
						</Grid>
					</Grid>
				</>
			)}
			{visibleSearchDiocese && (
				<AdvancedSearchModal
					visible={visibleSearchDiocese}
					handleClose={handleVisibleSearchDiocese}
					field={searchFieldsDiocese}
					setField={setSearchFieldsDiocese}
					title={'Search Diocese'}
					handleSearch={handleSearchDiocese}
				>
					<>
						<Stack alignItems={'flex-end'}>
							<TrimTables
								name="dioceseHead"
								list={dioceseHead}
								trimList={trimList}
								setTrimList={setTrimList}
							/>
						</Stack>
						<DioceseTable
							trimList={trimList}
							setTrimList={setTrimList}
							page={page}
							pageSize={pageSize}
							setPage={setPage}
							setPageSize={setPageSize}
							label={label}
							orderType={orderType}
							setLabel={setLabel}
							setOderBy={setOderBy}
							setOrderType={setOrderType}
							dioceses={dioceses}
							hcaOrgList
							open={open}
							setOpen={setOpen}
							handleOpenEdit={handleSelectRowDiocese}
						/>
					</>
				</AdvancedSearchModal>
			)}
			{visibleSearchBoard && (
				<AdvancedSearchModal
					visible={visibleSearchBoard}
					handleClose={handleVisibleSearchBoard}
					field={searchFieldsBoard}
					setField={setSearchFieldsBoard}
					title={'Search School Board'}
					handleSearch={handleSearchBoard}
				>
					<>
						<Stack alignItems={'flex-end'}>
							<TrimTables
								name="schoolBoardHead"
								list={schoolBoardHead}
								trimList={trimList}
								setTrimList={setTrimList}
							/>
						</Stack>
						<SchoolBoardTable
							trimList={trimList}
							setTrimList={setTrimList}
							page={page}
							pageSize={pageSize}
							setPage={setPage}
							setPageSize={setPageSize}
							label={label}
							orderType={orderType}
							setLabel={setLabel}
							setOderBy={setOderBy}
							setOrderType={setOrderType}
							schoolBoards={schoolBoards}
							hcaOrgList
							open={open}
							setOpen={setOpen}
							handleOpenEdit={handleSelectRowBoard}
						/>
					</>
				</AdvancedSearchModal>
			)}
		</Stack>
	);
};

export default CreateEditSchools;
