import { ResponseError } from 'contexts/intialstates/Types';

export const errorCodes = (response: ResponseError) => {
	if (response?.StatusCode === 0) return { StatusCode: 1, Message: 'Success' };
	if (response?.StatusCode === 500) return { StatusCode: 500, Message: 'Internal server error' };
	if (response?.StatusCode === 1)
		return { StatusCode: 1, Message: 'Payload in request is bad or not formatted properly' };
	if (response?.StatusCode === 2) return { StatusCode: 2, Message: 'Appeal code is required' };
	if (response?.StatusCode === 3) return { StatusCode: 3, Message: 'Appeal does not exist' };
	if (response?.StatusCode === 4)
		return { StatusCode: 4, Message: 'Payload in request is bad or not formatted properly' };
	if (response?.StatusCode === 5) return { StatusCode: 5, Message: 'Batch does not exist' };
	if (response?.StatusCode === 6) return { StatusCode: 6, Message: 'Batch file is Empty' };
	if (response?.StatusCode === 7) return { StatusCode: 7, Message: 'File is not a csv' };
	if (response?.StatusCode === 8)
		return { StatusCode: 8, Message: 'Payload is bad or not properly formatted' };
	if (response?.StatusCode === 9) return { StatusCode: 9, Message: 'Checkout Donor does not exist' };
	if (response?.StatusCode === 10) return { StatusCode: 10, Message: 'Checkout invalid amount' };
	if (response?.StatusCode === 11)
		return { StatusCode: 11, Message: 'Payload in request is bad or not formatted properly' };
	if (response?.StatusCode === 12) return { StatusCode: 12, Message: 'Donor does not exist' };
	if (response?.StatusCode === 13) return { StatusCode: 13, Message: 'Payment method is required ' };
	if (response?.StatusCode === 14) return { StatusCode: 14, Message: 'Payment method does not exist' };
	if (response?.StatusCode === 15) return { StatusCode: 15, Message: 'Donations is empty' };
	if (response?.StatusCode === 16)
		return { StatusCode: 16, Message: 'Donation contributions not equal to credit amount' };
	if (response?.StatusCode === 17) return { StatusCode: 17, Message: 'Multiple donations made to a society' };
	if (response?.StatusCode === 18)
		return { StatusCode: 18, Message: 'Deposit does not exist or is in archive' };
	if (response?.StatusCode === 19) return { StatusCode: 19, Message: 'Updating a locked deposit' };
	if (response?.StatusCode === 20) return { StatusCode: 20, Message: 'Donation has no society' };
	if (response?.StatusCode === 21) return { StatusCode: 21, Message: 'Donation society does not exist' };
	if (response?.StatusCode === 22) return { StatusCode: 22, Message: 'Donation appeal does not exist' };
	if (response?.StatusCode === 23) return { StatusCode: 23, Message: 'DonationType does not exist' };
	if (response?.StatusCode === 24) return { StatusCode: 24, Message: 'Deposit template does not exist' };
	if (response?.StatusCode === 25) return { StatusCode: 25, Message: 'Failed to cancel deposit in converge' };
	if (response?.StatusCode === 26) return { StatusCode: 26, Message: 'Failed to update deposit in converge' };
	if (response?.StatusCode === 27) return { StatusCode: 27, Message: 'Diocese Bad payload' };
	if (response?.StatusCode === 28) return { StatusCode: 28, Message: 'Diocese does not exist' };
	if (response?.StatusCode === 29) return { StatusCode: 29, Message: 'DonationType bad payload' };
	if (response?.StatusCode === 30) return { StatusCode: 30, Message: 'DonationType Type is empty' };
	if (response?.StatusCode === 31) return { StatusCode: 31, Message: 'DonationType does not exist' };
	if (response?.StatusCode === 32) return { StatusCode: 32, Message: 'Donor bad payload' };
	if (response?.StatusCode === 33) return { StatusCode: 33, Message: 'Donor parish does not exist' };
	if (response?.StatusCode === 34) return { StatusCode: 34, Message: 'Donor does not exist' };
	if (response?.StatusCode === 35) return { StatusCode: 35, Message: 'Note bad payload' };
	if (response?.StatusCode === 36) return { StatusCode: 36, Message: 'Note not linked to a donor' };
	if (response?.StatusCode === 37) return { StatusCode: 37, Message: 'Note donor does not exist' };
	if (response?.StatusCode === 38) return { StatusCode: 38, Message: 'Note text is required' };
	if (response?.StatusCode === 39) return { StatusCode: 39, Message: 'Note does not exist' };
	if (response?.StatusCode === 40) return { StatusCode: 40, Message: 'Parish bad payload' };
	if (response?.StatusCode === 41) return { StatusCode: 41, Message: 'Parish diocese does not exist' };
	if (response?.StatusCode === 42) return { StatusCode: 42, Message: 'Parish code is required' };
	if (response?.StatusCode === 43) return { StatusCode: 43, Message: 'Parish does not exist' };
	if (response?.StatusCode === 44) return { StatusCode: 44, Message: 'Receipt template bad payload' };
	if (response?.StatusCode === 45) return { StatusCode: 45, Message: 'Receipt template society is empty' };
	if (response?.StatusCode === 46)
		return { StatusCode: 46, Message: 'Receipt template society does not exist' };
	if (response?.StatusCode === 47)
		return { StatusCode: 47, Message: 'Receipt template appeal does not exist' };
	if (response?.StatusCode === 48) return { StatusCode: 48, Message: 'Receipt template year is empty' };
	if (response?.StatusCode === 49) return { StatusCode: 49, Message: 'Receipt template month is empty' };
	if (response?.StatusCode === 50) return { StatusCode: 50, Message: 'Receipt template text is empty' };
	if (response?.StatusCode === 51) return { StatusCode: 51, Message: 'Receipt template does not exist' };
	if (response?.StatusCode === 52) return { StatusCode: 52, Message: 'Receipt template already exists' };
	if (response?.StatusCode === 53) return { StatusCode: 53, Message: 'Society bad payload' };
	if (response?.StatusCode === 54) return { StatusCode: 54, Message: 'Society code is empty' };
	if (response?.StatusCode === 55) return { StatusCode: 55, Message: 'Society already exists' };
	if (response?.StatusCode === 56) return { StatusCode: 56, Message: 'Society does not exist' };
	if (response?.StatusCode === 57) return { StatusCode: 57, Message: 'VoidType bad payload' };
	if (response?.StatusCode === 58) return { StatusCode: 58, Message: 'VoidType type is empty' };
	if (response?.StatusCode === 59) return { StatusCode: 59, Message: 'VoidType read is empty' };
	if (response?.StatusCode === 60) return { StatusCode: 60, Message: 'VoidType does not exist' };
	if (response?.StatusCode === 61) return { StatusCode: 61, Message: 'Checkout Credit card has been used' };
	if (response?.StatusCode === 62) return { StatusCode: 62, Message: 'Checkout transaction not handled' };
	if (response?.StatusCode === 63) return { StatusCode: 63, Message: 'Error parsing CSV file' };
	if (response?.StatusCode === 64) return { StatusCode: 64, Message: 'Donation receipt  does not exist' };
	if (response?.StatusCode === 65) return { StatusCode: 65, Message: 'Receipt for anonymous donation' };
	if (response?.StatusCode === 66) return { StatusCode: 66, Message: 'Deposit receipt is incomplete' };
	if (response?.StatusCode === 67) return { StatusCode: 67, Message: 'Donation type is required' };
	if (response?.StatusCode === 68) return { StatusCode: 68, Message: 'Donation type is required' };
	if (response?.StatusCode === 69) return { StatusCode: 69, Message: 'Donation type does not exist' };
	if (response?.StatusCode === 70)
		return { StatusCode: 70, Message: 'Cannot generate receipt for this donation type' };
	if (response?.StatusCode === 71)
		return { StatusCode: 71, Message: 'This batch contains a posted donation' };
	if (response?.StatusCode === 72) return { StatusCode: 72, Message: 'Cannot delete a PAP card batch' };
	if (response?.StatusCode === 73) return { StatusCode: 73, Message: 'Cannot delete a PAP bank batch' };
	if (response?.StatusCode === 74) return { StatusCode: 74, Message: 'Donor bank number is required' };
	if (response?.StatusCode === 75) return { StatusCode: 75, Message: 'Donor barnch number is required' };
	if (response?.StatusCode === 76) return { StatusCode: 76, Message: 'Account number is required' };
	if (response?.StatusCode === 77) return { StatusCode: 77, Message: 'This batch type cannot be posted' };
	if (response?.StatusCode === 78) return { StatusCode: 78, Message: 'Donor is required' };
	if (response?.StatusCode === 79) return { StatusCode: 79, Message: 'Select a dornor to merge from' };
	if (response?.StatusCode === 80) return { StatusCode: 80, Message: 'Select a donor to merge into' };
	if (response?.StatusCode === 81)
		return { StatusCode: 81, Message: 'The ID of the donor to be merged from is required' };
	if (response?.StatusCode === 82)
		return { StatusCode: 82, Message: 'The ID of the donor to be merged into is required' };
	if (response?.StatusCode === 83) return { StatusCode: 83, Message: 'School board does not exist' };
	if (response?.StatusCode === 84) return { StatusCode: 84, Message: 'Diocese does not exist' };
	if (response?.StatusCode === 85)
		return { StatusCode: 85, Message: 'Failed. Ensure all feilds are entered correctly' };
	if (response?.StatusCode === 86) return { StatusCode: 86, Message: 'Code is required' };
	if (response?.StatusCode === 87) return { StatusCode: 87, Message: 'Name is required' };
	if (response?.StatusCode === 88) return { StatusCode: 88, Message: 'Diocese is required' };
	if (response?.StatusCode === 89) return { StatusCode: 89, Message: 'School does not exist' };
	if (response?.StatusCode === 90)
		return { StatusCode: 90, Message: 'Failed. Ensure all feilds are entered correctly' };
	if (response?.StatusCode === 91) return { StatusCode: 91, Message: 'Name is required' };
	if (response?.StatusCode === 92) return { StatusCode: 92, Message: 'Diocese is required' };
	if (response?.StatusCode === 93) return { StatusCode: 93, Message: 'Diocese does not exist' };
	if (response?.StatusCode === 94) return { StatusCode: 94, Message: 'Principal is required' };
	if (response?.StatusCode === 95) return { StatusCode: 95, Message: 'Contact is required' };
	if (response?.StatusCode === 96) return { StatusCode: 96, Message: 'School status is required' };
	if (response?.StatusCode === 97) return { StatusCode: 97, Message: 'School status does not exist' };
	if (response?.StatusCode === 98) return { StatusCode: 98, Message: 'School board is required' };
	if (response?.StatusCode === 99) return { StatusCode: 99, Message: 'Material does not exist' };
	if (response?.StatusCode === 100)
		return { StatusCode: 100, Message: 'Failed. Ensure all feilds are entered correctly' };
	if (response?.StatusCode === 101) return { StatusCode: 101, Message: 'Item code is required' };
	return '';
};
