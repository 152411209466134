export const style = {
	paper: {
		background: '#c7cfd4',
		mt: 1,
	},
	archiveBtn: {
		background: 'var(--darkDivider)',
		color: 'var(--grayText)',
		textTransform: 'capitalize',
		fontSize: { xs: 11, sm: 13, md: 15 },
		'&:hover': { background: 'var(--darkDivider)', color: 'var(--grayText)' },
	},
	archiveLabel: { color: 'black' },
};
