import React, { useState, useEffect, useContext } from 'react';
import { Formik, FormikProps, Form, Field } from 'formik';
import { Grid, Stack, Typography, Divider, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormTextField } from 'Components/Common/TextField';
import { useGlobalStyles } from 'Components/Common/global/global';
import { Donor } from 'contexts/intialstates/Types';
import {
	AssignmentInd,
	Notes,
	AccountBox,
	AssistantDirection,
	Email,
	LocationCity,
	LocationOn,
	Markunread,
	MyLocation,
	Phone,
	PhoneIphone,
	Subtitles,
	SupportAgent,
	Terrain,
	AccountCircle,
	CorporateFare,
} from '@mui/icons-material';
import useParishesActions from 'hooks/useParishesActions';
import useDonorActions from 'hooks/useDonorActions';
import { GlobalContexts } from 'contexts/GlobalContext';
import FormAutocomplete from 'Components/Common/FormAutoComplete';
import * as yup from 'yup';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import { useParams, useNavigate } from 'react-router-dom';
import DonorCheckBoxes from './DonorInfo/DonorCheckBoxes';
import { getDonorEdges } from './donor.util';
import _ from 'lodash';
import ProvinceAutocomplete from 'Components/Province';

const validationCreateDonor = yup.object().shape({
	firstName: yup.string().required('First name field is required'),
	lastName: yup.string().required('Last name field is required'),
	email: yup.string().email('Invalid Email').nullable(),
	phone: yup.number().typeError('Invalid Phone number'),
});

export interface DonorFormValues {
	isOrganization?: boolean;
	salutation?: string;
	lastName?: string;
	firstName?: string;
	orgName?: string;
	careOf?: string;
	parish?: {
		id?: number;
		parishName?: string;
	};
	donorType?: any;
	streetline1?: string;
	streetline2?: string;
	city?: string;
	province?: string;
	postalCode?: string;
	country?: string;
	phone?: string;
	mobile?: string;
	email?: string;
	noMail?: boolean;
	returnMail?: boolean;
	deceased?: boolean;
	remark?: string;
	donorId?: number | string;
	magazine?: boolean;
	faithPropagation?: boolean;
	stPeter?: boolean;
	holyChildhood?: boolean;
	noFollowAppeal?: boolean;
	excludeFromParishList?: boolean;
	returnedMail?: boolean;
	principal?: boolean;
	member?: boolean;
	pap?: boolean;
	annuity?: boolean;
	estate?: boolean;
	changedFields?: string[];
}

interface CreateEditProp {
	selectedEditData?: Donor;
	visible: boolean;
	handleCloseEdit: () => void;
	handleRelaod?: () => void;
	isDonor?: boolean;
}

export const initalValues = {
	donorId: '',
	salutation: '',
	lastName: '',
	firstName: '',
	orgName: '',
	careOf: '',
	parishCode: '',
	donorType: '',
	streetline1: '',
	streetline2: '',
	city: '',
	province: '',
	postalCode: '',
	country: '',
	phone: '',
	mobile: '',
	email: '',
	deceased: false,
	remark: '',
	isOrganization: false,
};

const CreateEditDonor = ({
	selectedEditData,
	visible,
	handleCloseEdit,
	handleRelaod,
	isDonor,
}: CreateEditProp) => {
	const global = useGlobalStyles();
	const [initial, setInitial] = useState<DonorFormValues>(initalValues);
	const { getAllParishes } = useParishesActions();
	const { createSingleDonor, updateSingleDonor } = useDonorActions();
	const { id } = useParams();
	const navigate = useNavigate();
	const { allParishStateDispatch, allParishStateState, setErrorSnack } = useContext(GlobalContexts);
	const [saveLoading, setSaveLoading] = useState(false);

	useEffect(() => {
		getAllParishes()(allParishStateDispatch);
	}, []);

	useEffect(() => {
		if (!selectedEditData) {
			setInitial(initalValues);
		} else {
			const { id, parish, isOrganization } = selectedEditData;

			setInitial({
				...selectedEditData,
				parish: parish
					? {
							id: parish.id,
							parishName: parish.parishName,
					  }
					: undefined,
				donorId: id,
				isOrganization,
			} as any);
		}
	}, [selectedEditData]);
	const handleSubmit = async (values: DonorFormValues) => {
		const pickables = [
			'email',
			'province',
			'firstName',
			'lastName',
			'city',
			'careOf',
			'salutation',
			'deceased',
			'orgName',
			'streetline1',
			'streetline2',
			'remark',
			'mobile',
			'country',
			'phone',
			'postalCode',
			'isOrganization',
			'faithPropagation',
			'stPeter',
			'holyChildhood',
			'noFollowAppeal',
			'excludeFromParishList',
			'principal',
			'member',
			'pap',
			'annuity',
			'estate',
			'noMail',
			'magazine',
			'returnMail',
			'provinceId',
		];
		const payload: Partial<DonorFormValues> = _.pick(values, pickables);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		payload.parishId = values.parish?.id;

		setSaveLoading(true);
		let isSuccess;
		if (selectedEditData) {
			isSuccess = await updateSingleDonor(
				{
					...payload,
					firstName: payload.firstName?.replace(' ', ''),
					lastName: payload.lastName?.replace(' ', ''),
				},
				parseInt(selectedEditData?.id as string),
			);
		} else {
			isSuccess = await createSingleDonor({
				...payload,
				firstName: payload.firstName?.replace(/ /g, ''),
				lastName: payload.lastName?.replace(/ /g, ''),
			});
		}
		setSaveLoading(false);
		handleCloseEdit();
		if (isSuccess) {
			if (!selectedEditData && id && isDonor) {
				navigate(`/donors/${isSuccess}`);
			} else {
				handleRelaod?.();
			}
		} else {
			setErrorSnack(true);
		}
	};

	const { appealsEdges, communicationsEdges, familyEdges, specialEdges } = getDonorEdges(initial);

	return (
		<BasicModal
			onClose={handleCloseEdit}
			visible={visible}
			title={selectedEditData ? 'Update Donor' : 'Create New Donor'}
		>
			<Formik
				enableReinitialize
				onSubmit={handleSubmit}
				validationSchema={validationCreateDonor}
				isInitialValid={false}
				initialValues={initial}
			>
				{({ setFieldValue, values }: FormikProps<DonorFormValues>) => (
					<Form noValidate autoComplete="off">
						<>
							<Typography sx={{ fontSize: 14, fontWeight: 600 }}>Personal</Typography>
							<Divider />
							<Stack direction="column" my={2} py={2}>
								<Stack direction="row" justifyContent="space-between">
									<Stack sx={{ width: '44%', mb: 2, mx: 2 }}>
										<Grid container>
											<Grid item>
												<AccountBox className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													Salutation
												</Typography>
											</Grid>
										</Grid>
										<Field
											fullWidth
											name="salutation"
											size="small"
											variant="outlined"
											placeholder="Salutation"
											component={FormTextField}
										/>
									</Stack>
								</Stack>
								<Stack direction="row" justifyContent="space-between">
									<Stack sx={{ width: '50%', mb: 2, mx: 2 }}>
										<Grid container>
											<Grid item>
												<AccountCircle className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													First Name
												</Typography>
											</Grid>
										</Grid>
										<Field
											fullWidth
											name="firstName"
											size="small"
											variant="outlined"
											placeholder="First Name"
											component={FormTextField}
										/>
									</Stack>
									<Stack sx={{ width: '50%', mb: 2, mx: 2 }}>
										<Grid container>
											<Grid item>
												<AssignmentInd className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													Last Name
												</Typography>
											</Grid>
										</Grid>
										<Field
											fullWidth
											name="lastName"
											size="small"
											variant="outlined"
											placeholder="Last Name"
											component={FormTextField}
										/>
									</Stack>
								</Stack>
								<Stack direction="row" justifyContent="space-between">
									<Stack sx={{ width: '50%', mb: 2, mx: 2 }}>
										<Grid container>
											<Grid item>
												<LocationCity className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													Organization
												</Typography>
											</Grid>
										</Grid>
										<Field
											fullWidth
											name="orgName"
											size="small"
											variant="outlined"
											placeholder="Organization"
											disabled={!values.isOrganization}
											component={FormTextField}
										/>
									</Stack>
									<Stack sx={{ width: '50%', mb: 2, mx: 2 }}>
										<Grid container sx={{ width: '100%' }}>
											<Grid item>
												<Subtitles className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													Parish
												</Typography>
											</Grid>
											{allParishStateState?.data?.body && (
												<Field
													fullWidth
													name="parish"
													size="small"
													enableReinitialize
													variant="outlined"
													placeholder="Parish"
													textFieldProps={{
														variant: 'outlined',
														placeholder: 'Parish',
													}}
													options={allParishStateState?.data?.body?.map(({ id, parishName }: any) => {
														return {
															id,
															parishName,
														};
													})}
													classes={{
														noOptions: global.selectNoOption,
													}}
													getOptionLabel={(option: any) => option?.parishName?.toLowerCase()}
													component={FormAutocomplete}
												/>
											)}
										</Grid>
									</Stack>
								</Stack>
								<Stack direction="row" justifyContent="space-between">
									<Stack sx={{ width: '50%', mb: 2, mx: 2 }}>
										<Grid container>
											<Grid item>
												<AccountCircle className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													Deceased
												</Typography>
											</Grid>
										</Grid>
										<Field
											fullWidth
											name="deceased"
											size="small"
											variant="outlined"
											component={() => (
												<FormControlLabel
													name="deceased"
													sx={{ marginLeft: 1 }}
													control={
														<RadioGroup
															value={values.deceased}
															name="deceased"
															onChange={(e) => setFieldValue('deceased', e.target.value === 'true')}
														>
															<FormControlLabel value={true} control={<Radio />} label="Yes" />
															<FormControlLabel value={false} control={<Radio />} label="No" />
														</RadioGroup>
													}
													label=""
												/>
											)}
										/>
									</Stack>
									<Stack sx={{ width: '50%', mb: 2, mx: 2 }}>
										<Grid container>
											<Grid item>
												<CorporateFare className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													Is Organization ?
												</Typography>
											</Grid>
										</Grid>
										<Field
											fullWidth
											name="isOrganization"
											size="small"
											variant="outlined"
											component={() => (
												<RadioGroup
													value={values.isOrganization}
													name="is-organization"
													onChange={(e) => {
														setFieldValue('isOrganization', e.target.value === 'true');
														if (e.target.value === 'false') {
															setFieldValue('orgName', '');
														}
													}}
												>
													<FormControlLabel value={true} control={<Radio />} label="Yes" />
													<FormControlLabel value={false} control={<Radio />} label="No" />
												</RadioGroup>
											)}
										/>
									</Stack>
								</Stack>
							</Stack>

							<Typography sx={{ fontSize: 14, fontWeight: 600 }}>Contact</Typography>
							<Divider />
							<Stack direction="column" my={2} py={2}>
								<Stack direction="row" justifyContent="space-between">
									<Stack sx={{ width: '50%', mb: 2, mx: 2 }}>
										<Grid container>
											<Grid item>
												<Phone className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													Phone
												</Typography>
											</Grid>
										</Grid>
										<Field
											fullWidth
											name="phone"
											size="small"
											variant="outlined"
											placeholder="phone"
											component={FormTextField}
										/>
									</Stack>

									<Stack sx={{ width: '50%', mb: 2, mx: 2 }}>
										<Grid container>
											<Grid item>
												<PhoneIphone className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													Mobile
												</Typography>
											</Grid>
										</Grid>
										<Field
											fullWidth
											name="mobile"
											size="small"
											variant="outlined"
											placeholder="Mobile"
											component={FormTextField}
										/>
									</Stack>
								</Stack>
								<Stack direction="row" justifyContent="space-between">
									<Stack sx={{ width: '44%', mb: 2, mx: 2 }}>
										<Grid container>
											<Grid item>
												<Email className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													Email
												</Typography>
											</Grid>
										</Grid>
										<Field
											fullWidth
											name="email"
											size="small"
											variant="outlined"
											placeholder="Email"
											component={FormTextField}
											type="email"
										/>
									</Stack>
								</Stack>
							</Stack>
							<Typography sx={{ fontSize: 14, fontWeight: 600 }}>Location</Typography>
							<Divider />
							<Stack direction="column" my={2} py={2}>
								<Stack direction="row" justifyContent="space-between">
									<Stack sx={{ width: '50%', mb: 2, mx: 2 }}>
										<Grid container>
											<Grid item>
												<LocationOn className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													Street Line 1
												</Typography>
											</Grid>
										</Grid>
										<Field
											fullWidth
											name="streetline1"
											size="small"
											variant="outlined"
											placeholder="Street Line 1"
											component={FormTextField}
										/>
									</Stack>
									<Stack sx={{ width: '50%', mb: 2, mx: 2 }}>
										<Grid container>
											<Grid item>
												<MyLocation className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													Street Line 2
												</Typography>
											</Grid>
										</Grid>
										<Field
											fullWidth
											name="streetline2"
											size="small"
											variant="outlined"
											placeholder="street Line 2"
											component={FormTextField}
										/>
									</Stack>
								</Stack>
								<Stack direction="row" justifyContent="space-between">
									<Stack sx={{ width: '50%', mb: 2, mx: 2 }}>
										<Grid container>
											<Grid item>
												<LocationCity className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													City
												</Typography>
											</Grid>
										</Grid>
										<Field
											fullWidth
											name="city"
											size="small"
											variant="outlined"
											placeholder="City"
											component={FormTextField}
										/>
									</Stack>
									<Stack sx={{ width: '50%', mb: 2, mx: 2 }}>
										<Grid container>
											<Grid item>
												<Terrain className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													Country
												</Typography>
											</Grid>
										</Grid>
										<Field
											fullWidth
											name="country"
											size="small"
											variant="outlined"
											placeholder="Country"
											component={FormTextField}
										/>
									</Stack>
								</Stack>

								<Stack direction="row" justifyContent="space-between">
									<Stack sx={{ width: '50%', mb: 2, mx: 2 }}>
										<Grid container>
											<Grid item>
												<Markunread className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													Postal Code
												</Typography>
											</Grid>
										</Grid>
										<Field
											fullWidth
											name="postalCode"
											size="small"
											variant="outlined"
											placeholder="Postal Code"
											component={FormTextField}
										/>
									</Stack>
									<Stack sx={{ width: '50%', mb: 2, mx: 2 }}>
										<Grid container>
											<Grid item>
												<AssistantDirection className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													Province
												</Typography>
											</Grid>
										</Grid>
										<ProvinceAutocomplete
											disabled={!!id}
											handleChange={(val) => setFieldValue('provinceId', parseInt(val.id))}
											value={values?.province || ''}
										/>
									</Stack>
								</Stack>
								<Stack direction="row" justifyContent="space-between">
									<Stack sx={{ width: '50%', mb: 2, mx: 2 }}>
										<Grid container>
											<Grid item>
												<SupportAgent className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													Care Of
												</Typography>
											</Grid>
										</Grid>
										<Field
											fullWidth
											name="careOf"
											size="small"
											variant="outlined"
											placeholder="Care Of"
											component={FormTextField}
										/>
									</Stack>
								</Stack>
							</Stack>
							<Typography sx={{ fontSize: 14, fontWeight: 600 }}>Others</Typography>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<DonorCheckBoxes setFieldValue={setFieldValue} edges={appealsEdges} title="Appeals" />
								</Grid>
								<Grid item xs={6}>
									<DonorCheckBoxes
										setFieldValue={setFieldValue}
										edges={communicationsEdges}
										title="Communication"
									/>
								</Grid>
								<Grid item xs={6}>
									<DonorCheckBoxes setFieldValue={setFieldValue} edges={specialEdges} title="Special Donor" />
								</Grid>
								<Grid item xs={6}>
									<DonorCheckBoxes setFieldValue={setFieldValue} edges={familyEdges} title="Family" />
								</Grid>
							</Grid>

							<Divider />
							<Stack direction="column" my={2} py={2}>
								<Stack direction="row" justifyContent="space-between">
									<Stack my={2} sx={{ width: '100%' }}>
										<Grid container>
											<Grid item>
												<Notes className={global.formLabelIcon} />
											</Grid>
											<Grid item>
												<Typography variant="body2" className={global.formLabelText}>
													Remarks
												</Typography>
											</Grid>
										</Grid>
										<Field
											fullWidth
											multiline
											minRows={3}
											name="remark"
											size="small"
											variant="outlined"
											placeholder="Remarks"
											component={FormTextField}
										/>
									</Stack>
								</Stack>
							</Stack>
							<LoadingButton
								fullWidth
								type="submit"
								color="primary"
								variant="contained"
								loading={saveLoading}
								loadingPosition="start"
							>
								{selectedEditData?.id ? 'Update Donor' : 'Create Donor'}
							</LoadingButton>
						</>
					</Form>
				)}
			</Formik>
		</BasicModal>
	);
};

export default CreateEditDonor;
