import React, { useState, useEffect } from 'react';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import { Grid, Stack, Typography, Button, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGlobalStyles } from 'Components/Common/global/global';
import { TaskAlt, Close, PersonOutline } from '@mui/icons-material';
import useMissionSundayMaterialActions from 'hooks/useMissionSundayMaterial';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';

interface P {
	visible: boolean;
	handleClose: () => void;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	reload: boolean;
	selectedData?: any;
}

interface FormValues {
	itemCode: string;
	description: string;
}

const CreateEditMissionMaterial = ({ visible, handleClose, setReload, reload, selectedData }: P) => {
	const global = useGlobalStyles();
	const { createMissionMaterial, updateMissionMaterial, deleteMissionMaterial } =
		useMissionSundayMaterialActions();

	const [loading, setLoading] = useState(false);
	const [formValues, setFormValues] = useState<FormValues>({
		itemCode: '',
		description: '',
	});
	const [formErrors, setFormErrors] = useState<string[]>([]);
	const [isDelete, setIsDelete] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);

	useEffect(() => {
		if (!selectedData) return;
		setFormValues({
			...formValues,
			itemCode: selectedData.itemCode ?? '',
			description: selectedData.description ?? '',
		});
	}, [selectedData]);

	const handleSubmit = async () => {
		const errors = [];
		if (!formValues.itemCode) {
			errors.push('itemCode');
		}
		if (errors.length) {
			setFormErrors(errors);
			return;
		}
		setLoading(true);
		let isSuccess;
		if (selectedData?.id) {
			isSuccess = await updateMissionMaterial(formValues, selectedData.id);
		} else {
			isSuccess = await createMissionMaterial(formValues);
		}

		if (isSuccess) {
			handleClose();
			setReload(!reload);
		}
		setLoading(false);
	};

	const handleCloseDelete = () => {
		setIsDelete(false);
	};

	const handleDelete = async () => {
		if (selectedData) {
			setDeleteLoading(true);
			const isSucess = await deleteMissionMaterial(selectedData.id);
			setDeleteLoading(false);
			handleCloseDelete();
			if (isSucess) {
				setReload(!reload);
				setIsDelete(false);
				handleClose();
			}
		}
	};

	const handleChange = (name: string, value: string) => {
		setFormValues({
			...formValues,
			[name]: value,
		});
		if (formErrors.includes(name)) {
			setFormErrors(formErrors.filter((item) => item !== name));
		}
	};

	const handleOpenDelete = () => {
		setIsDelete(true);
	};

	return (
		<BasicModal
			onClose={handleClose}
			visible={visible}
			title={'Add a Mission Sunday Material'}
			data={selectedData || undefined}
			handleOpenDelete={handleOpenDelete}
		>
			<Stack>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
					<Grid item xs={12} mb={2}>
						<Grid container>
							<Grid item>
								<PersonOutline className={global.formLabelIcon} />
							</Grid>
							<Grid item>
								<Typography variant="body2" className={global.formLabelText}>
									Item Code
								</Typography>
							</Grid>
						</Grid>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							placeholder="Item Code"
							onChange={(e) => handleChange('itemCode', e.target.value)}
							value={formValues.itemCode}
						/>
						{formErrors.includes('itemCode') && (
							<span className={global.errorText}>Item Code is required</span>
						)}
					</Grid>
					<Grid item xs={12} mb={2}>
						<Grid container>
							<Grid item>
								<PersonOutline className={global.formLabelIcon} />
							</Grid>
							<Grid item>
								<Typography variant="body2" className={global.formLabelText}>
									Description
								</Typography>
							</Grid>
						</Grid>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							placeholder="Description"
							onChange={(e) => handleChange('description', e.target.value)}
							value={formValues.description}
							multiline
							rows={4}
						/>
					</Grid>
				</Grid>
				<Stack direction="row" my={3} justifyContent={'center'} spacing={2} alignItems="center">
					<LoadingButton
						color="primary"
						variant="contained"
						sx={{ width: 'fit-content', textTransform: 'capitalize' }}
						loading={loading}
						startIcon={<TaskAlt />}
						loadingPosition="start"
						onClick={handleSubmit}
					>
						Save
					</LoadingButton>
					<Button
						variant="outlined"
						sx={{ width: 'fit-content', textTransform: 'capitalize' }}
						onClick={handleClose}
						startIcon={<Close />}
					>
						Cancel
					</Button>
				</Stack>
			</Stack>

			{isDelete && (
				<ConfirmationModal
					handleClose={() => setIsDelete(false)}
					message={'Are you sure you want to delete mission sunday material ?'}
					open={isDelete}
					handleConfirmation={handleDelete}
					handleCancel={() => setIsDelete(false)}
					loading={deleteLoading}
				/>
			)}
		</BasicModal>
	);
};

export default CreateEditMissionMaterial;
