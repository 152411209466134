import React, { useState } from 'react';
import { Stack, Button, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { useNavigate } from 'react-router-dom';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import { nonCashDonationHead } from './NonCashDonationTable';
import NonCashDonationTable from './NonCashDonationTable';
import useUserActions from 'hooks/useUserActions';

const NonCashDonation = () => {
	const { handleRoles } = useUserActions();
	const navigate = useNavigate();
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [filterPayload, setFilterPayload] = useState<Array<any>>([]);
	const [page, setPage] = useState(0);
	const [reload, setReload] = useState(false);

	return (
		<Stack>
			<Stack
				alignItems="center"
				direction={{ xs: 'column', sm: 'row' }}
				justifyContent={'space-between'}
				spacing={1}
				mb={5}
			>
				<Stack direction="column">
					<Stack direction="row" spacing={4} alignItems="center">
						<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
							Non Cash Donation
						</Typography>
					</Stack>
					<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
						Create and manage non cash donations here.
					</Typography>
				</Stack>
				<Stack sx={{ mb: 3 }} direction="row" alignItems={'center'} justifyContent="flex-end">
					<TrimTables
						name="nonCashDonationHead"
						list={nonCashDonationHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
					{handleRoles('bookkeeping-actions') && (
						<Button
							onClick={() => navigate('/bookkeeping/create-non-cash-donation')}
							sx={{ textTransform: 'none', mr: 2 }}
							startIcon={<Add />}
							variant="contained"
						>
							Add Non Cash Donation
						</Button>
					)}
					<FilterListButton
						page={page}
						setPage={setPage}
						reload={reload}
						setIsReload={setReload}
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
					/>
				</Stack>
			</Stack>
			<NonCashDonationTable
				trimList={trimList}
				setTrimList={setTrimList}
				filterPayload={filterPayload}
				setFilterPayload={setFilterPayload}
				page={page}
				setPage={setPage}
				reload={reload}
				setReload={setReload}
			/>
		</Stack>
	);
};

export default NonCashDonation;
