import React from 'react';
import {
	Donor,
	FilterPayload,
	ResponseError,
	StateType,
	TimeStamp,
	TimeStampInfoType,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface RelativeData {
	pageNumber: number;
	pageSize: number;
	totalPages: number;
	totalRecords: number;
	body: Relative[];
}

export interface Relative extends TimeStamp, TimeStampInfoType {
	id: number;
	firstName: string;
	lastName: string;
	relation: string;
	email: string;
	address: string;
	phoneNumber: string;
	donorId: number;
	donor: Donor;
	relatedDonorId: number;
	relatedDonor: Donor;
}

export interface GetRelativesTypes {
	setRelatives: React.Dispatch<React.SetStateAction<StateType<RelativeData>>>;
	relatives: StateType<RelativeData>;
	page: number;
	pageSize: number;
	filterPayload?: FilterPayload[];
	orderBy?: string;
	donorId: number;
}

export interface RelativePayload {
	firstName: string;
	lastName: string;
	relation: string;
	donorId: number;
	relatedDonorId?: number;
	email: string;
	address: string;
	phoneNumber: string;
}

const useRelativesActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const getRelatives = async ({
		setRelatives,
		relatives,
		page,
		pageSize,
		filterPayload,
		orderBy,
		donorId,
	}: GetRelativesTypes) => {
		try {
			setRelatives({
				...relatives,
				loading: true,
				error: null,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`donor-relationship/${donorId}/list?&PageNumber=${page + 1}&PageSize=${pageSize}${filter}${
						orderBy || ''
					}`,
				);
			} else {
				response = await api.get(
					`donor-relationship/${donorId}/list?&PageSize=${pageSize}${filter}${orderBy || ''}`,
				);
			}

			setRelatives({
				...relatives,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || relatives.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setRelatives({
					...relatives,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const createRelatives = async (payload: RelativePayload): Promise<boolean> => {
		try {
			const response = await api.post(`donor-relationship/create`, {
				...payload,
			});
			if (response?.data?.result) {
				setSimpleErrorSnack({
					message: 'Relative created succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const updateRelatives = async (payload: RelativePayload, donorRelationshipId: number): Promise<boolean> => {
		try {
			const response = await api.put(`donor-relationship/${donorRelationshipId}/update`, {
				...payload,
			});
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Relatives updated sucesssfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deleteRelatives = async (donorRelationId: number): Promise<boolean> => {
		try {
			const response = await api.remove(`donor-relationship/${donorRelationId}/archive`);
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Relative deleted succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	return {
		getRelatives,
		createRelatives,
		updateRelatives,
		deleteRelatives,
	};
};

export default useRelativesActions;
