import React, { useState, useContext, useEffect } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, Stack, Paper, Button, Divider } from '@mui/material';
import { Archive, ArrowBackIosNew, RestoreFromTrash, Save } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useStyles } from '../Parish.style';
import useParishesActions from 'hooks/useParishesActions';
import CreateEditParishForm, { ParishValues } from './CreateEditParishForm';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import { GlobalContexts } from 'contexts/GlobalContext';
import DonationTable, { DonationType } from 'Components/DonationTable';
import LoadingButton from '@mui/lab/LoadingButton';
import { Parish, StateType } from 'contexts/intialstates/Types';
import { styles } from 'Components/Donors/DonorInfo/DonorInfo.style';
import NotesParish from '../NotesParish';
import useUserActions from 'hooks/useUserActions';

const CreateEditParishTab = () => {
	const { handleRoles } = useUserActions();
	const classes = useStyles();
	const navigate = useNavigate();
	const { id } = useParams();
	const [tabValue, setTabValue] = useState('overview');
	const [isDelete, setIsDelete] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [formErrors, setFormErrors] = useState<string[]>([]);
	const [parish, setParish] = useState<StateType<Parish>>({
		data: null,
		error: null,
		loading: false,
	});
	const [formData, setFormData] = useState<ParishValues>({
		parishCode: '',
		parishName: '',
		pastor: '',
		contactName: '',
		streetline1: '',
		streetline2: '',
		city: '',
		province: '',
		postalCode: '',
		country: '',
		phone: '',
		fax: '',
		email: '',
		secondLanguage: '',
		useEmail: false,
		useEFile: false,
		noParishList: false,
		magazine: false,
		status: 'Active',
		dioceseId: undefined,
	});
	const { deleteParish, restoreParish, createParish, updateParish, getParishesByID } = useParishesActions();
	const { setErrorSnack } = useContext(GlobalContexts);
	const [reload, setReload] = useState(false);
	const handleChange = (event: any, value: string) => {
		setTabValue(value);
	};

	const handleToggleDelete = () => {
		setIsDelete(false);
	};

	const handleDelete = async () => {
		if (!id) return;

		if (parish.data?.archived) {
			setDeleteLoading(true);
			const isSucess = await restoreParish(parseInt(id));
			setDeleteLoading(false);
			handleToggleDelete();
			if (isSucess) {
				setReload(!reload);
				setErrorSnack(false);
			} else {
				setErrorSnack(true);
			}
			return;
		}
		setDeleteLoading(true);
		const isSucess = await deleteParish(parseInt(id));
		setDeleteLoading(false);
		handleToggleDelete();
		if (isSucess) {
			navigate('/admin/parishes');
			setErrorSnack(false);
		} else {
			setErrorSnack(true);
		}
	};

	const handleSubmit = async () => {
		const errors = [];
		if (!formData.parishName) {
			errors.push('parishName');
		}
		if (!formData.dioceseId) {
			errors.push('dioceseId');
		}
		setFormErrors(errors);
		if (errors.length) return;

		let isSucess;
		setLoading(true);
		if (id) {
			isSucess = await updateParish(formData, parseInt(id));
		} else {
			isSucess = await createParish(formData);
		}
		setLoading(false);
		if (isSucess) {
			navigate('/admin/parishes');
		}
	};
	useEffect(() => {
		if (!id) return;
		getParishesByID({ setParish, parish, id: parseInt(id) });
	}, [id, reload]);

	return (
		<Stack spacing={3}>
			<Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap={'wrap'}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={4}
					marginBottom={2}
				>
					<Stack className={classes.backIconWrapper}>
						<ArrowBackIosNew onClick={() => navigate(-1)} />
					</Stack>
					<Stack alignItems={'flex-start'}>
						<span className={classes.title}>Parish</span>
						<span className={classes.subTitle}></span>
						{id && (
							<Button sx={styles.statusBtn} disableElevation disableRipple variant="outlined">
								{parish.data?.archived ? 'Archived' : 'Active'}
							</Button>
						)}
					</Stack>
				</Stack>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems={'center'}
					spacing={2}
					marginBottom={2}
				>
					{id && handleRoles('admin-actions') && (
						<Button
							sx={{ textTransform: 'none' }}
							startIcon={parish.data?.archived ? <RestoreFromTrash /> : <Archive />}
							variant="contained"
							onClick={() => setIsDelete(true)}
						>
							{parish.data?.archived ? 'Unarchive' : 'Archive'}
						</Button>
					)}
					{tabValue === 'overview' && handleRoles('admin-actions') && (
						<LoadingButton
							sx={{ textTransform: 'none' }}
							startIcon={<Save />}
							variant="contained"
							loading={loading}
							loadingPosition="start"
							onClick={handleSubmit}
						>
							Save
						</LoadingButton>
					)}
				</Stack>
			</Stack>
			<Paper sx={{ padding: 2 }}>
				<TabContext value={tabValue}>
					<TabList onChange={handleChange}>
						<Tab label="Details" value="overview" />
						{id && <Tab label="Donation" value="donation" />}
						{id && <Tab label="Notes" value="notes" />}
					</TabList>
					<Divider />
					<TabPanel value="overview">
						<CreateEditParishForm
							initial={formData}
							setInitial={setFormData}
							formErrors={formErrors}
							setFormErrors={setFormErrors}
							reload={reload}
						/>
					</TabPanel>
					{id && (
						<TabPanel value="donation">
							<DonationTable id={parseInt(id)} type={DonationType.Parish} />
						</TabPanel>
					)}
					{id && (
						<TabPanel value="notes">
							<NotesParish parishId={parseInt(id)} />
						</TabPanel>
					)}
				</TabContext>
			</Paper>
			{isDelete && (
				<ConfirmationModal
					handleClose={handleToggleDelete}
					message={
						parish.data?.archived
							? 'Do you want to unarchive this Parish ?'
							: 'Are you sure you want to archive this Parish ?'
					}
					open={isDelete}
					handleConfirmation={handleDelete}
					handleCancel={handleToggleDelete}
					loading={deleteLoading}
				/>
			)}
		</Stack>
	);
};

export default CreateEditParishTab;
