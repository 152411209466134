export const getChangedFields = (obj1: any, obj2: any) => {
	const diffFields = [];
	for (const key in obj1) {
		if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
			if (obj1[key] !== obj2[key]) {
				diffFields.push(key);
			}
		}
	}
	return diffFields;
};
