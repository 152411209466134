import * as React from 'react';
import { Button, Stack } from '@mui/material';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import { useStyles } from './ActionModal.style';
import LoadingButton from '@mui/lab/LoadingButton';
import { AddTask, Close } from '@mui/icons-material';

interface P {
	open: boolean;
	description?: string;
	subTitle?: string;
	title: string;
	submitText?: string;
	closeText?: string;
	handleClose?: () => void;
	handleSubmit?: () => void;
	loading?: boolean;
}

const ActionModal = ({
	handleClose,
	description,
	title,
	open,
	handleSubmit,
	submitText,
	closeText,
	loading,
	subTitle,
}: P) => {
	const classes = useStyles();
	return (
		<div>
			<BasicModal onClose={handleClose} visible={open}>
				<Stack alignItems={'center'}>
					<span className={classes.title}>{title}</span>
					<span className={classes.subTitle}>{subTitle}</span>
					<span className={classes.description}>{description}</span>
					<Stack direction="row" justifyContent="center" py={3} spacing={2}>
						{submitText && (
							<LoadingButton
								loadingPosition="start"
								loading={loading}
								startIcon={<AddTask />}
								sx={{ textTransform: 'none' }}
								onClick={handleSubmit}
								variant="contained"
							>
								{submitText}
							</LoadingButton>
						)}
						{closeText && (
							<Button
								sx={{ textTransform: 'none' }}
								startIcon={<Close />}
								onClick={handleClose}
								variant="contained"
							>
								{closeText}
							</Button>
						)}
					</Stack>
				</Stack>
			</BasicModal>
		</div>
	);
};

export default ActionModal;
