import React from 'react';
import { TablePagination, Paper, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import { pagination } from 'Components/Common/Tables/Tables';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { sortTable } from 'Components/Common/Tables/sortTable';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import { convertDate } from 'helpers/dayAndTime';

const ParishesTable = ({
	trimList,
	setTrimList,
	pageSize,
	setPageSize,
	page,
	setPage,
	label,
	orderType,
	setLabel,
	setOrderType,
	setOderBy,
	parishes,
	hcaOrgList,
	open,
	setOpen,
	reload,
	setReload,
	filterPayload,
	setFilterPayload,
	handleOpenEdit,
}: any) => {
	const navigate = useNavigate();

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleEdit = (row: any) => {
		if (hcaOrgList && row) {
			setOpen({
				...open,
				state: false,
				type: '',
				data: {
					...open.data,
					organizationId: row?.id,
					organizationName: row?.parishName,
					address: `${row?.streetline1 && row?.streetline1 !== 'N/A' ? row?.streetline1 + ', ' : ''}${
						row?.streetline2 && row?.streetline2 !== 'N/A' ? row?.streetline2 + ' ' : ''
					}${row?.city && row?.city !== 'N/A' ? row?.city + ' ' : ''}${
						row?.province && row?.province !== 'N/A' ? row?.province + ' ' : ''
					}${row?.postalCode && row?.postalCode !== 'N/A' ? row?.postalCode + ' ' : ''}${
						row?.country && row?.country !== 'N/A' ? row?.country + '' : ''
					}`,
					type: open.type,
				},
			});
			return;
		}
		navigate(`/admin/create-edit-parish/${row.id}`);
	};

	const getTableData = () => {
		return parishes?.data?.body?.length
			? parishes?.data?.body.map((row: any, index: number) => {
					return {
						id: row.id,
						's/n': page * pageSize + index + 1,
						parishName: row?.parishName || 'Anonymous',
						parishCode: row?.parishCode || 'N/A',
						archived: row?.archived ? 'Archived' : 'Active',
						pastor: row?.pastor || 'N/A',
						phone: row?.phone || 'N/A',
						fax: row?.fax || 'N/A',
						email: row?.email || 'N/A',
						streetline1: row?.streetline1,
						streetline2: row?.streetline2,
						country: row?.country,
						city: row?.city || 'N/A',
						postalCode: row?.postalCode || 'N/A',
						province: row?.province?.provinceName,
						provinceId: row?.provinceId ? parseInt(row?.provinceId) : undefined,
						created: `${row?.created ? convertDate(row?.created) : 'N/A'}`,
						createdBy: row?.createdBy?.name || 'N/A',
						updated: `${row?.updated ? convertDate(row?.updated) : 'N/A'}`,
						updatedBy: row?.updatedBy?.name || 'N/A',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
							<span
								style={{
									cursor: 'pointer',
									margin: '10px',
								}}
								onClick={() => {
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									});
								}}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<>
							<TableFiltering
								field={column.id}
								filterType={column.filterType}
								list={column.list}
								reload={reload}
								setIsReload={setReload}
								setFilterPayload={setFilterPayload}
								filterPayload={filterPayload}
								setPage={setPage}
								page={page}
							/>
						</>
					</>
				),
			};
		});
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		reOrderedData && setTrimList?.(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		resizedData && setTrimList?.(resizedData);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<CustomTable
				loading={parishes?.loading}
				columnData={getColumnData()}
				tableData={getTableData()}
				handleDrag={handleDrag}
				handleResize={handleResize}
				emptyMessage={'No Parishes available'}
				handleRowClick={(row) => {
					handleOpenEdit?.(row as any);

					handleEdit(row as any);
				}}
			/>
			{(parishes?.data?.body?.length as number) > 0 && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
					component="div"
					count={parishes?.data?.totalRecords as number}
					rowsPerPage={pageSize}
					page={page}
					sx={pagination}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</Paper>
	);
};

export default ParishesTable;
