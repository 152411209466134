import {
	VolunteerActivism,
	LibraryBooks,
	Settings,
	PieChart,
	Domain,
	Reorder,
	ChangeHistory,
} from '@mui/icons-material';
import { Routes } from 'utils/routes';

export const sideMenuItems = [
	{
		name: 'Donors',
		link: Routes.Donors,
		Icon: VolunteerActivism,
	},
	{
		name: 'Bookkeeping',
		link: Routes.Bookkeping,
		Icon: LibraryBooks,
	},
	{
		name: 'Reports',
		link: Routes.Reports,
		Icon: PieChart,
	},
	{
		name: 'Operations',
		link: Routes.HCA,
		Icon: Domain,
	},
	{
		name: 'Annuities',
		link: Routes.Annuities,
		Icon: Reorder,
	},
	{
		name: 'Admin',
		link: Routes.AdminPanel,
		Icon: Settings,
	},

	// {
	//   name: 'Nested Pages',
	//   Icon: IconLibraryBooks,
	//   items: [
	//     {
	//       name: 'Level 2',
	//     },
	//     {
	//       name: 'Level 2',
	//       items: [
	//         {
	//           name: 'Level 3',
	//         },
	//         {
	//           name: 'Level 3',
	//         },
	//       ],
	//     },
	//   ],
	// },
];
