import React from 'react';
import { Add } from '@mui/icons-material';
import { Button, Paper, Stack, TablePagination, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { pagination } from 'Components/Common/Tables/Tables';
import { ColumnType, receiptTemplateHead } from 'Components/Common/Tables/TableHeaders';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { OrderType, StateType } from 'contexts/intialstates/Types';
import { GlobalContexts } from 'contexts/GlobalContext';
import { convertDate } from 'helpers/dayAndTime';
import useReceiptTemplateActions from 'hooks/useReceiptTemplateAction';
import { Months } from 'Components/Reusable/DatePicker';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import { sortTable } from 'Components/Common/Tables/sortTable';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import useUserActions from 'hooks/useUserActions';

const ReceiptTemplateTable = () => {
	const navigate = useNavigate();
	const { setErrorSnack } = React.useContext(GlobalContexts);
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [allReceipt, setAllReceipt] = React.useState<StateType<any>>({
		data: null,
		error: null,
		loading: false,
	});

	const { handleRoles } = useUserActions();
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [filterPayload, setFilterPayload] = React.useState<Array<any>>([]);
	const [reload, setIsReload] = React.useState(false);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const { getAllReceiptTemplate } = useReceiptTemplateActions();
	React.useEffect(() => {
		getAllReceiptTemplate({
			pageNumber: page,
			sizeOfPage: pageSize,
			allReceipt,
			setAllReceipt,
			setErrorSnack,
			orderBy,
			filterPayload,
		});
	}, [page, pageSize, orderBy, reload]);

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const editReceiptTemplate = (id: any) => {
		navigate(`${id}`);
	};

	const getTableData = () => {
		return allReceipt?.data?.body?.length
			? allReceipt?.data?.body.map((row: any, index: number) => {
					return {
						id: row.id,
						's/n': page * pageSize + index + 1,
						name: row?.name || 'Anonymous',
						donationType: row?.donationType?.description || 'N/A',
						society: row?.society?.code || 'N/A',
						appeal: row?.appeal?.code || 'N/A',
						month: row?.month ? row.month && Months.find((month) => month.id === row.month)?.name : 'N/A',
						year: row?.year || 'N/A',
						created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
						createdBy: row?.createdBy?.name || 'N/A',
						updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
						updatedBy: row?.updatedBy?.name || 'N/A',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column: any) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
							<span
								style={{
									cursor: 'pointer',
									margin: '10px',
								}}
								onClick={() => {
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									});
								}}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							reload={reload}
							setIsReload={setIsReload}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							setPage={setPage}
							page={page}
						/>
					</>
				),
			};
		});
	};
	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	return (
		<>
			<Stack
				alignItems="center"
				direction={{ xs: 'column', sm: 'row' }}
				justifyContent={'space-between'}
				spacing={1}
				mb={5}
			>
				<Stack direction="column">
					<Stack direction="row" spacing={4} alignItems="center">
						<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
							Receipt Letter Template
						</Typography>
					</Stack>
					<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
						Create and manage receipt letter templates here.
					</Typography>
				</Stack>
				<Stack sx={{ mb: 3 }} direction="row" alignItems={'center'} justifyContent="flex-end">
					<TrimTables
						name={'receiptTemplateHead'}
						list={receiptTemplateHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
					{handleRoles('admin-actions') && (
						<Button
							onClick={() => navigate('create')}
							sx={{ textTransform: 'none', mr: 2 }}
							startIcon={<Add />}
							variant="contained"
						>
							Add New Template
						</Button>
					)}
					<FilterListButton
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
						reload={reload}
						setIsReload={setIsReload}
						setPage={setPage}
						page={page}
					/>
				</Stack>
			</Stack>

			<Stack justifyContent="center" alignItems="center">
				<Paper sx={{ width: '100%', overflow: 'hidden' }}>
					<CustomTable
						loading={allReceipt?.loading}
						columnData={getColumnData()}
						tableData={getTableData()}
						handleDrag={handleDrag}
						handleResize={handleResize}
						emptyMessage={'No receipt template available'}
						handleRowClick={(row) => editReceiptTemplate(row.id as number)}
					/>
					{(allReceipt?.data?.body?.length as number) > 0 && (
						<TablePagination
							rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
							component="div"
							count={allReceipt?.data?.totalRecords as number}
							rowsPerPage={pageSize}
							page={page}
							sx={pagination}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					)}
				</Paper>
			</Stack>
		</>
	);
};

export default ReceiptTemplateTable;
