import React from 'react';
import { TableBody, TableContainer, TableRow, TableCell, Table, Box, Stack } from '@mui/material';
import { tableRow } from 'Components/Common/Tables/Tables';
import CustomTableHead from 'Components/Common/Tables/CustomTable/CustomTableHead';
import TableSkeletonContent from 'Components/Common/Tables/TableSkeleton';
import TableEmptyLayout from 'Components/Common/Tables/TableEmptyLayout';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';

interface P {
	loading?: boolean;
	tableData: any;
	columnData: ColumnType[];
	emptyMessage?: string;
	handleDrag: (columnData: ColumnType[]) => void;
	handleResize: (columnData: ColumnType[]) => void;
	handleRowClick?: (row: any) => void;
	unPaginated?: boolean;
	unPaginatedTitle?: string;
	headerBg?: string;
}

const CustomTable = ({
	loading,
	tableData,
	columnData,
	handleDrag,
	handleResize,
	emptyMessage,
	handleRowClick,
	unPaginated,
	unPaginatedTitle,
	headerBg,
}: P) => {
	return (
		<>
			{unPaginatedTitle && (
				<Box width="100%">
					<Stack
						justifyContent={'center'}
						sx={{
							height: 40,
							background: 'var(--mainBackground)',
							color: 'var(--mainColor)',
							fontSize: 16,
							fontWeight: 600,
							px: 3,
						}}
					>
						{unPaginatedTitle}
					</Stack>
				</Box>
			)}
			<TableContainer>
				<Table stickyHeader aria-label="sticky table">
					<CustomTableHead
						unPaginated={unPaginated}
						columnData={columnData}
						handleDrag={handleDrag}
						handleResize={handleResize}
						headerBg={headerBg}
					/>
					<TableBody>
						{loading ? (
							<TableSkeletonContent
								columnCount={columnData.length}
								columnMinWidth={170}
								isSN
								columnData={columnData}
							/>
						) : tableData?.length ? (
							tableData?.map((row: any) => {
								return (
									<TableRow sx={tableRow} tabIndex={-1} key={row.id} onClick={() => handleRowClick?.(row)}>
										<tr>
											{columnData?.length &&
												columnData
													?.filter(({ isChecked }: ColumnType) => isChecked)
													.map((item: ColumnType, index: number) => (
														<TableCell
															key={index}
															align="center"
															sx={{
																width: `${item.minWidth}px` || '100px',
																position: `${item.id === 'blank' ? 'sticky' : 'initial'}`,
																right: `${item.id === 'blank' ? 0 : 'initial'}`,
																background: `${item.id === 'blank' ? 'white' : 'initial'}`,
															}}
														>
															<div
																style={{
																	width: `${item.minWidth}px` || '100px',
																	whiteSpace: 'nowrap',
																	overflow: 'hidden',
																	textOverflow: 'ellipsis',
																	paddingLeft: 15,
																	paddingRight: 15,
																	color: `${
																		unPaginated && item.id === 's/n' ? 'var(--mainColor)' : 'initial'
																	}`,
																}}
															>
																{row[item.id]}
															</div>
														</TableCell>
													))}
										</tr>
									</TableRow>
								);
							})
						) : (
							tableData?.length === 0 && (
								<TableEmptyLayout
									emptyMessage={emptyMessage || 'No Data available'}
									columnCount={columnData.length}
								/>
							)
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default CustomTable;
