import React from 'react';
import { Outlet } from 'react-router';

export const SingleDepositLayout = () => {
	return (
		<>
			<Outlet />
		</>
	);
};

export default SingleDepositLayout;
