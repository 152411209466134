import React, { FC, useState } from 'react';
import { TablePagination, Tooltip, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { pagination } from 'Components/Common/Tables/Tables';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import { OrderType, TemplateDeposits } from 'contexts/intialstates/Types';
import FormatCurrency from 'helpers/FormatCurrency';
import { convertDate } from 'helpers/dayAndTime';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import { sortTable } from 'Components/Common/Tables/sortTable';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import TableFiltering, { DonorFiltering } from 'Components/Reusable/TableFiltering/TableFiltering';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';

interface TemplateDepositTableProp {
	templateDeposit?: TemplateDeposits[];
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	pageSize: number;
	setPageSize: React.Dispatch<React.SetStateAction<number>>;
	totalRecords: number;
	handleChangePage: (newPage: number) => void;
	handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
	deleteTemplateDepositById: (id: number) => void;
	loading?: boolean;
	batchId?: number;
	label: string;
	orderType: OrderType;
	setLabel: React.Dispatch<React.SetStateAction<string>>;
	setOrderType: React.Dispatch<React.SetStateAction<OrderType>>;
	setOderBy: React.Dispatch<React.SetStateAction<string>>;
	setIsReload: React.Dispatch<React.SetStateAction<boolean>>;
	reload: boolean;
	filterPayload: any[];
	setFilterPayload: React.Dispatch<React.SetStateAction<any[]>>;
	searchDonor?: string;
	setSearchDonor: React.Dispatch<React.SetStateAction<string>>;
	type: string;
	trimList: ColumnType[];
	setTrimList: React.Dispatch<React.SetStateAction<ColumnType[]>>;
}

const TemplateDepositTable: FC<TemplateDepositTableProp> = ({
	templateDeposit,
	page,
	pageSize,
	totalRecords,
	handleChangePage,
	handleChangeRowsPerPage,
	deleteTemplateDepositById,
	loading,
	batchId,
	orderType,
	label,
	setLabel,
	setOrderType,
	setOderBy,
	setIsReload,
	reload,
	filterPayload,
	setFilterPayload,
	setSearchDonor,
	searchDonor,
	type,
	trimList,
	setTrimList,
	setPage,
}) => {
	const navigate = useNavigate();

	const [deleteModal, setDeleteModal] = useState<{ id?: number; open: boolean }>({ open: false });
	const [deleteLoading, setDeleteLoading] = useState(false);

	const handleClose = () => setDeleteModal({ open: false });

	const handleDelete = async () => {
		if (!deleteModal.id) return;
		setDeleteLoading(true);
		await deleteTemplateDepositById(deleteModal.id);
		setDeleteLoading(false);
		handleClose();
	};

	const handleEdit = (id: number) => {
		if (batchId) {
			navigate(`/bookkeeping/template/deposits/${id}/batch/${batchId}?tab=${type}`);
		} else {
			navigate(`/bookkeeping/template/deposits/${id}?tab=${type}`);
		}
	};

	const getTableData = () => {
		return templateDeposit && templateDeposit?.length
			? templateDeposit.map((row, index) => {
					return {
						id: row.id,
						's/n': page * pageSize + index + 1,
						donor: row?.donor?.orgName
							? row?.donor?.orgName
							: row?.donor?.firstName && row?.donor?.lastName
							? `${row.donor.firstName} ${row.donor.lastName}`
							: 'Anonymous',
						referencenumber: row?.donor?.referenceNumber || 'N/A',
						creditAmount: row.creditAmount ? <FormatCurrency value={row.creditAmount} /> : 'N/A',
						paymentMethod: row.paymentMethod?.name ? row.paymentMethod.name : 'N/A',
						itemStatus: row.itemStatus ? row.itemStatus?.status : 'N/A',
						isWalkathon: `${row?.isWalkathon}` ?? 'N/A',
						creditDate: row?.creditDate ? convertDate(row?.creditDate) : 'N/A',
						postDate: row?.postDate ? convertDate(row?.postDate) : 'N/A',
						batchNumber: row?.batch?.batchNumber ? row.batch.batchNumber : 'N/A',
						chequeNumber: row.chequeNumber ? row.chequeNumber : 'N/A',
						accountNumber: row.accountNumber ? row.accountNumber : 'N/A',
						societies: row?.societies?.join(', ') || 'N/A',
						created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
						createdBy: row?.createdBy?.name || 'N/A',
						updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
						updatedBy: row?.updatedBy?.name || 'N/A',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
							<span
								style={{
									cursor: 'pointer',
								}}
								onClick={() => {
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									});
								}}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<>
							<DonorFiltering
								searchDonor={searchDonor}
								setSearchDonor={setSearchDonor}
								filterType={column.filterType}
								setPage={setPage}
							/>
							<TableFiltering
								field={column.id}
								filterType={column.filterType}
								list={column.list}
								reload={reload}
								setIsReload={setIsReload}
								setFilterPayload={setFilterPayload}
								filterPayload={filterPayload}
								setPage={setPage}
								page={page}
							/>
						</>
					</>
				),
			};
		});
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	return (
		<>
			<ConfirmationModal
				handleClose={handleClose}
				message={'Are you sure you want to delete this Template deposit?'}
				open={deleteModal?.open}
				handleConfirmation={handleDelete}
				handleCancel={handleClose}
				loading={deleteLoading}
			/>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<CustomTable
					loading={loading}
					columnData={getColumnData()}
					tableData={getTableData()}
					handleDrag={handleDrag}
					handleResize={handleResize}
					emptyMessage={'No template deposit available'}
					handleRowClick={(row) => handleEdit(row.id as number)}
				/>
				{templateDeposit && templateDeposit?.length > 0 && (
					<TablePagination
						rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
						component="div"
						count={totalRecords}
						rowsPerPage={pageSize}
						page={page}
						sx={pagination}
						onPageChange={(event, newPage) => handleChangePage(newPage)}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
			</Paper>
		</>
	);
};

export default TemplateDepositTable;
