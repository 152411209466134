import { useContext } from 'react';
import {
	ResponseError,
	StateType,
	TimeStamp,
	TimeStampInfo,
	Donor,
	DonationTypes,
	Donations,
	FilterPayload,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface BurseChalice {
	pageNumber: number;
	pageSize: number;
	totalPages: number;
	totalRecords: number;
	body: BurseChaliceData[];
	statusInfo: {
		inactive: number;
		active: number;
		stopped: number;
		completed: number;
	};
}

export interface BurseChaliceData extends TimeStamp, TimeStampInfo {
	id: number;
	contributionType: string;
	status: string;
	stopped: boolean;
	targetAmount: number;
	contributionAmount: number;
	startDate: string;
	endDate: string;
	archived: boolean;
	donorId: number;
	donor: Donor;
	donationTypeId: number;
	donationType: DonationTypes;
}

export interface GetBurseChaliceProps {
	burseChalice: StateType<BurseChalice>;
	setBurseChalice: React.Dispatch<React.SetStateAction<StateType<BurseChalice>>>;
	donorId: number;
	page: number;
	pageSize: number;
	orderBy?: string;
	filterPayload: FilterPayload[];
}

interface CreatePayload {
	contributionType: string;
	targetAmount: number;
	donationTypeId: number;
	donorId: number;
}

interface BurseChaliceDonation {
	id: number;
	amount: number;
	donation: Donations;
}

export interface BurseChaliceById extends BurseChaliceData {
	burseChaliceDonationMappings: BurseChaliceDonation[];
}

interface GetBurseChaliceByIdProps {
	burseChalice: StateType<BurseChaliceById>;
	setBurseChalice: React.Dispatch<React.SetStateAction<StateType<BurseChaliceById>>>;
	id: number;
}

export interface BurseChaliceStatus {
	id: number;
	status: string;
}

export interface GetBurseChaliceStatus {
	burseChaliceStatus: StateType<BurseChaliceStatus[]>;
	setBurseChaliceStatus: React.Dispatch<React.SetStateAction<StateType<BurseChaliceStatus[]>>>;
}

const useBurseChaliceActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = useContext(GlobalContexts);

	const getBurseChalice = async ({
		burseChalice,
		setBurseChalice,
		donorId,
		page,
		pageSize,
		orderBy,
		filterPayload,
	}: GetBurseChaliceProps) => {
		try {
			setBurseChalice({
				...burseChalice,
				loading: true,
			});
			let filter = '';
			filterPayload.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`bursechalice/${donorId}/list?PageNumber=${page + 1}&PageSize=${pageSize}${filter}${orderBy || ''}`,
				);
			} else {
				response = await api.get(
					`bursechalice/${donorId}/list?&PageSize=${pageSize}${filter}${orderBy || ''}`,
				);
			}

			setBurseChalice({
				...burseChalice,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || burseChalice.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setBurseChalice({
					...burseChalice,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const getBurseChaliceById = async ({ burseChalice, setBurseChalice, id }: GetBurseChaliceByIdProps) => {
		try {
			setBurseChalice({
				...burseChalice,
				loading: true,
			});
			const response = await api.get(`bursechalice/${id}`);

			setBurseChalice({
				...burseChalice,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setBurseChalice({
					...burseChalice,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const createBurseChalice = async (payload: CreatePayload): Promise<boolean> => {
		try {
			const createdData = await api.post(`/bursechalice/create`, { ...payload });
			if (createdData?.data?.result) {
				setSimpleErrorSnack({
					message: 'Burce/Chalice created successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const updateBurseChaliceState = async (id: number, isStopped: boolean): Promise<boolean> => {
		try {
			const updateBurseChalice = await api.post(
				`/bursechalice/${id}/update-state/isStopped?isStopped=${isStopped}`,
				{},
			);
			if (updateBurseChalice?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Bruce/Chalice updated successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deleteBurseChalice = async (id: number): Promise<boolean> => {
		try {
			const deleteBurseChalice = await api.remove(`/bursechalice/${id}/archive`);
			if (deleteBurseChalice?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Burse/Chalice deleted succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const getBurseChaliceStatus = async ({
		burseChaliceStatus,
		setBurseChaliceStatus,
	}: GetBurseChaliceStatus) => {
		try {
			setBurseChaliceStatus({
				...burseChaliceStatus,
				loading: true,
			});
			const response = await api.get(`burseChaliceStatus/list`);

			setBurseChaliceStatus({
				...burseChaliceStatus,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setBurseChaliceStatus({
					...burseChaliceStatus,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			alert('An error occurred');
		}
	};

	return {
		getBurseChalice,
		createBurseChalice,
		getBurseChaliceById,
		deleteBurseChalice,
		updateBurseChaliceState,
		getBurseChaliceStatus,
	};
};

export default useBurseChaliceActions;
