import React, { useState, useContext } from 'react';
import useDonorActions from 'hooks/useDonorActions';
import { Stack, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Donor } from 'contexts/intialstates/Types';
import { GlobalContexts } from 'contexts/GlobalContext';
import ErrorSnack from 'Components/Common/ErrorSnack/ErrorSnack';
import DonorTable from 'Components/Donors/DonorListTable';
import CreateEditDonor from 'Components/Donors/CreateEditDonorModal';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { ColumnType, donorHead } from 'Components/Common/Tables/TableHeaders';

const MergeDonor = () => {
	const [visible, setVisible] = useState(false);
	const { deleteSingleDonor } = useDonorActions();
	const [saveLoading, setSaveLoading] = useState(false);
	const [selectedData, setSelectedData] = useState<Donor>();
	const { setErrorSnack } = useContext(GlobalContexts);
	const [isDelete, setIsDelete] = useState(false);
	const [reload, setReload] = React.useState(false);
	const [filterPayload, setFilterPayload] = React.useState<Array<any>>([]);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const [page, setPage] = React.useState(0);

	const handleOpenEdit = (data: Donor) => {
		setSelectedData(data);
		setVisible(true);
	};

	const handleOpenDelete = (data: Donor) => {
		setIsDelete(true);
		setSelectedData(data);
	};

	const handleCloseEdit = () => {
		setVisible(false);
		setSelectedData(undefined);
	};

	const handleCloseDelete = () => {
		setIsDelete(false);
		setSelectedData(undefined);
	};

	const handleDelete = async () => {
		if (selectedData) {
			setSaveLoading(true);
			const isSucess = await deleteSingleDonor(parseInt(selectedData.id));
			setSaveLoading(false);
			handleCloseDelete();
			if (isSucess) {
				setReload(!reload);
				setErrorSnack(false);
			} else {
				setErrorSnack(true);
			}
		}
	};

	return (
		<div>
			<Stack>
				<Stack sx={{ mb: 3 }} direction="row" justifyContent="flex-end" alignItems={'center'}>
					<TrimTables name="donorHead" list={donorHead} trimList={trimList} setTrimList={setTrimList} />

					<FilterListButton setFilterPayload={setFilterPayload} filterPayload={filterPayload} />
				</Stack>
				<DonorTable
					setFilterPayload={setFilterPayload}
					filterPayload={filterPayload}
					reload={reload}
					setReload={setReload}
					handleOpenEdit={handleOpenEdit}
					handleOpenDelete={handleOpenDelete}
					trimList={trimList}
					isMerge
					setTrimList={setTrimList}
					setPage={setPage}
					page={page}
				/>
				<CreateEditDonor
					selectedEditData={selectedData}
					handleCloseEdit={handleCloseEdit}
					visible={visible}
					handleRelaod={() => setReload(!reload)}
				/>
				<ConfirmationModal
					handleClose={() => setIsDelete(false)}
					message={'Are you sure you want to delete donor ?'}
					open={isDelete}
					handleConfirmation={handleDelete}
					handleCancel={() => setIsDelete(false)}
					loading={saveLoading}
				/>
			</Stack>
			<ErrorSnack />
		</div>
	);
};

export default MergeDonor;
