import {
	PARISHES_LOADING,
	PARISHES_LOAD_SUCCESS,
	PARISHES_LOAD_ERROR,
	PARISH_LOADING,
	PARISH_LOAD_SUCCESS,
	PARISH_LOAD_ERROR,
} from 'contexts/actionTypes';
import {
	ResponseError,
	Action,
	AllParishStateType,
	ParishData,
	Parish,
	SingleParishStateType,
} from 'contexts/intialstates/Types';

export const allParish = (
	state: AllParishStateType,
	{ payload, type }: Action<ParishData>,
): AllParishStateType => {
	switch (type) {
		case PARISHES_LOADING: {
			return {
				...state,
				loading: true,
			};
		}

		case PARISHES_LOAD_SUCCESS: {
			return {
				...state,
				loading: false,
				error: null,
				data: payload as ParishData,
			};
		}
		case PARISHES_LOAD_ERROR: {
			return {
				...state,
				loading: false,
				error: payload as ResponseError,
			};
		}
		default:
			return state;
	}
};

export const singleParish = (
	state: SingleParishStateType,
	{ payload, type }: Action<Parish>,
): SingleParishStateType => {
	switch (type) {
		case PARISH_LOADING: {
			return {
				...state,
				loading: true,
				error: null,
			};
		}

		case PARISH_LOAD_SUCCESS: {
			return {
				...state,
				loading: false,
				error: null,
				data: payload as Parish,
			};
		}
		case PARISH_LOAD_ERROR: {
			return {
				...state,
				loading: false,
				error: payload as ResponseError,
			};
		}
		default:
			return state;
	}
};
