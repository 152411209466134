import { Paper, TablePagination, Tooltip } from '@mui/material';
import StatusButton from 'Components/Common/Buttons/StatusButton';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import { pagination } from 'Components/Common/Tables/Tables';
import { User, OrderType } from 'contexts/intialstates/Types';
import React, { useState, useEffect } from 'react';
import { sortTable } from 'Components/Common/Tables/sortTable';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import _ from 'lodash';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import useUserActions from 'hooks/useUserActions';

const UserList = ({
	trimList,
	setTrimList,
	page,
	setPage,
	pageSize,
	setPageSize,
	users,
	handleOpenEdit,
	setUsers,
	filterPayload,
	setFilterPayload,
	userAllData,
}: any) => {
	const { handleRoles } = useUserActions();
	const [orderType, setOrderType] = useState<OrderType>('asc');
	const [label, setLabel] = useState<string>('');
	const [orderBy, setOderBy] = useState<string>('');
	const [reload, setReload] = useState(false);

	useEffect(() => {
		if (!userAllData) return;
		const data = _.orderBy(userAllData, [label], [orderType]);
		setUsers({
			...users,
			data,
		});
	}, [orderBy]);

	useEffect(() => {
		const userData = _.cloneDeep(userAllData);

		if (!filterPayload.length) {
			setUsers({
				...users,
				data: userData,
			});
		} else {
			const flatField = filterPayload.flatMap((item: any) => item.field);
			const data = userData
				.map((item: any) => {
					if (
						_.lowerCase(item['firstName']).includes(
							_.lowerCase(
								filterPayload.find(
									(payload: any) => payload.field === 'firstName' && flatField.includes('firstName'),
								)?.query,
							),
						) &&
						_.lowerCase(item['groupName']).includes(
							_.lowerCase(
								filterPayload.find(
									(payload: any) => payload.field === 'groupName' && flatField.includes('groupName'),
								)?.query,
							),
						) &&
						_.lowerCase(item['email']).includes(
							_.lowerCase(
								filterPayload.find((payload: any) => payload.field === 'email' && flatField.includes('email'))
									?.query,
							),
						)
					) {
						return item;
					}
				})
				.filter(Boolean);

			setUsers({
				...users,
				data,
			});
		}
	}, [filterPayload, reload]);

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleEdit = (id: number) => {
		if (!handleRoles('user-actions')) return;
		const data = users?.data?.find((item: any) => item.id === id);
		data && handleOpenEdit?.(data);
	};

	const printName = (row: any) => {
		if (row?.firstName && row?.lastName) return `${row?.firstName} ${row?.lastName}`;
		if (row?.firstName) return `${row?.firstName}`;
		if (row?.lastName) return ` ${row?.lastName}`;
		return 'Anonymous';
	};

	const getTableData = () => {
		let userArr: User[];
		if (pageSize > 0) {
			userArr = users?.data?.slice(page * pageSize, page * pageSize + pageSize);
		} else {
			userArr = users.data;
		}
		return userArr?.length
			? userArr?.map((row: any, index: any) => {
					return {
						id: row?.id,
						's/n': page * pageSize + index + 1,
						firstName: printName(row),
						groupName: row?.groupName ? <StatusButton status={`${row?.groupName}`} /> : 'N/A',
						email: row?.email || 'N/A',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={`Sort by ${column.label}`}>
							<span
								style={{ cursor: 'pointer' }}
								onClick={() =>
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									})
								}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							reload={reload}
							setIsReload={setReload}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
						/>
					</>
				),
			};
		});
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		reOrderedData && setTrimList?.(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		resizedData && setTrimList?.(resizedData);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<CustomTable
				loading={users?.loading}
				columnData={getColumnData()}
				tableData={getTableData()}
				handleDrag={handleDrag}
				handleResize={handleResize}
				emptyMessage={'No available User'}
				handleRowClick={(row) => handleEdit(row.id as number)}
			/>
			{(users?.data?.length as number) > 0 && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
					component="div"
					count={users?.data?.length as number}
					rowsPerPage={pageSize}
					page={page}
					sx={pagination}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</Paper>
	);
};

export default UserList;
