import React, { useState, useEffect } from 'react';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import { Grid, Stack, Typography, Button, Select, MenuItem, TextField, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGlobalStyles } from 'Components/Common/global/global';
import { TaskAlt, Close, Visibility } from '@mui/icons-material';
import { StateType, Annuitant } from 'contexts/intialstates/Types';
import useAnnuitantActions, { MaritalStatus } from 'hooks/useAnnuitantActions';
import BasicDatePicker from 'Components/Reusable/DatePicker';

interface P {
	visible: boolean;
	handleClose: () => void;
	annuitantId: number;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	reload: boolean;
	annuitant: StateType<Annuitant>;
}

interface FormValues {
	firstName?: string;
	lastName?: string;
	genderId?: number;
	maritalStatusId?: number;
	statusId?: number;
	dateOfBirth?: string;
	sin?: string;
}

const UpdateAnnuitantDetail = ({ visible, handleClose, annuitantId, setReload, reload, annuitant }: P) => {
	const global = useGlobalStyles();
	const { getMaritalStatus, updateAnnuitant } = useAnnuitantActions();

	const [loading, setLoading] = useState(false);
	const [formValues, setFormValues] = useState<FormValues>({});
	const [maritalStatus, setMaritalStatus] = useState<StateType<MaritalStatus[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [formErrors, setFormErrors] = useState<string[]>([]);
	const [sinType, setSinType] = useState('password');

	useEffect(() => {
		getMaritalStatus({ maritalStatus, setMaritalStatus });
	}, []);

	useEffect(() => {
		if (!annuitant?.data) return;
		const data = annuitant?.data;
		setFormValues({
			firstName: data.donor?.firstName ?? '',
			lastName: data?.donor?.lastName ?? '',
			maritalStatusId: data.maritalStatus?.id,
			dateOfBirth: data.dateOfBirth,
			sin: data.sin,
			genderId: data.gender?.id,
			statusId: data.status?.id,
		});
	}, [annuitant?.data]);

	const handleSubmit = async () => {
		const errors = [];
		if (!formValues.maritalStatusId) {
			errors.push('maritalStatusId');
		}
		if (!formValues.dateOfBirth) {
			errors.push('dateOfBirth');
		}
		if (!formValues.sin) {
			errors.push('sin');
		}
		setFormErrors(errors);
		if (errors.length) return;

		if (!formValues?.maritalStatusId || !formValues?.dateOfBirth || !formValues.sin) return;
		const payload = {
			maritalStatusId: formValues.maritalStatusId,
			dateOfBirth: formValues.dateOfBirth,
			sin: formValues.sin,
			genderId: formValues.genderId,
			statusId: formValues.statusId,
		};
		setLoading(true);
		const isSuccess = await updateAnnuitant(payload, annuitantId);
		if (isSuccess) {
			handleClose();
			setReload(!reload);
		}
		setLoading(false);
	};

	const handleChange = (name: string, value: string | number) => {
		setFormValues({
			...formValues,
			[name]: value,
		});
		if (formErrors.includes(name)) {
			setFormErrors(formErrors.filter((item: any) => item !== name));
		}
	};

	const handleToggleSinType = () => {
		setSinType(sinType === 'password' ? 'text' : 'password');
	};

	return (
		<BasicModal onClose={handleClose} visible={visible} title={'Update Annuitant Dtails'}>
			<Stack>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
					<Grid item xs={12} md={6} mb={2}>
						<Grid container>
							<Typography variant="body2" className={global.formLabelText}>
								First Name
							</Typography>
						</Grid>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							placeholder="First Name"
							disabled
							value={formValues.firstName ?? ''}
							className={global.disabledInput}
						/>
					</Grid>
					<Grid item xs={12} md={6} mb={2}>
						<Grid container>
							<Typography variant="body2" className={global.formLabelText}>
								Last Name
							</Typography>
						</Grid>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							placeholder="Last Name"
							disabled
							value={formValues.lastName ?? ''}
							className={global.disabledInput}
						/>
					</Grid>
					<Grid item xs={12} md={6} mb={2}>
						<Grid container>
							<Typography variant="body2" className={global.formLabelText}>
								Marital Status
							</Typography>
						</Grid>
						<Select
							fullWidth
							size={'small'}
							value={formValues.maritalStatusId ?? ''}
							onChange={(e) => handleChange('maritalStatusId', e.target.value)}
						>
							{maritalStatus?.data?.map((x) => (
								<MenuItem key={x.id} value={x.id}>
									{x.status}
								</MenuItem>
							))}
						</Select>
						{formErrors.includes('maritalStatusId') && (
							<span className={global.errorText}>Marital Status is required</span>
						)}
					</Grid>
					<Grid item xs={12} md={6} mb={2}>
						<Grid container>
							<Typography variant="body2" className={global.formLabelText}>
								SIN
							</Typography>
						</Grid>
						<TextField
							fullWidth
							size="small"
							variant="outlined"
							placeholder="SIN"
							type={sinType}
							value={formValues.sin}
							onChange={(e) => handleChange('sin', e.target.value)}
							InputProps={{
								endAdornment: (
									<IconButton onClick={handleToggleSinType}>
										<Visibility />
									</IconButton>
								),
							}}
						/>
						{formErrors.includes('sin') && <span className={global.errorText}>SIN is required</span>}
					</Grid>
				</Grid>
				<Stack direction="row" my={3} justifyContent={'center'} spacing={2} alignItems="center">
					<LoadingButton
						color="primary"
						variant="contained"
						loading={loading}
						startIcon={<TaskAlt />}
						loadingPosition="start"
						onClick={() => handleSubmit()}
						disabled={!annuitant?.data}
					>
						Save
					</LoadingButton>
					<Button variant="outlined" onClick={handleClose} startIcon={<Close />}>
						Cancel
					</Button>
				</Stack>
			</Stack>
		</BasicModal>
	);
};

export default UpdateAnnuitantDetail;
