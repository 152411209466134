import * as React from 'react';
import {
	Button,
	Stack,
	TableHead,
	TableBody,
	TableContainer,
	TableRow,
	TableCell,
	Table,
	Paper,
	Tooltip,
} from '@mui/material';
import { tableHead, tableRow } from 'Components/Common/Tables/Tables';
import { Archive, Edit } from '@mui/icons-material';
import { convertDate } from 'helpers/dayAndTime';
import FormatCurrency from 'helpers/FormatCurrency';
import { DonationFormValues } from './CreateDeposit/Types';
import TableEmptyLayout from 'Components/Common/Tables/TableEmptyLayout';
import TableSkeletonContent from 'Components/Common/Tables/TableSkeleton';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import { DefaultState } from 'Components/Batch/Batch';
import { Appeal, Donations, DonationTypes, Society, StateType } from 'contexts/intialstates/Types';
import useDonationTypesActions from 'hooks/useDonationTypesActions';
import useAppealActions from 'hooks/useAppealActions';
import useSocietyAction from 'hooks/useSocietyAction';

interface DepositDonationTableProps {
	openDonationForm: (donationId: number) => void;
	donations: Array<DonationFormValues>;
	donorId?: number;
	loading?: boolean;
	triggerRefresh: boolean;
	setTriggerRefresh: React.Dispatch<React.SetStateAction<boolean>>;
	isDisabled: (isHCA?: string) => boolean;
	depositData?: any;
}

const DepositDonationTable = ({
	openDonationForm,
	triggerRefresh,
	setTriggerRefresh,
	donations,
	loading,
	isDisabled,
	depositData,
}: DepositDonationTableProps) => {
	const tableHeadColumns: Array<string> = [
		'Actions',
		'Society',
		'Contribution Amount',
		'Donation Type',
		'Appeal Code',
		'Receipt Number',
		'Issue Date',
		'Remark',
	];

	const [defaultstate, setDefaultstate] = React.useState<DefaultState>({ id: '', open: false });
	const { getAllDonationTypes } = useDonationTypesActions();
	const { getAllAppeals } = useAppealActions();
	const { getSocieties } = useSocietyAction();

	const handleOpenClose = (donationId: number) => {
		setDefaultstate({ ...defaultstate, id: donationId, open: true });
	};
	const handleClose = () => setDefaultstate({ ...defaultstate, open: false });
	const [donationTypes, setDonationTypes] = React.useState<StateType<DonationTypes[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [allSociety, setAllSoceity] = React.useState<StateType<Society[]>>({
		data: null,
		error: null,
		loading: false,
	});

	const [allAppeals, setAllAppeals] = React.useState<StateType<Appeal[]>>({
		data: null,
		loading: false,
		error: null,
	});
	React.useEffect(() => {
		getSocieties({ allSociety, setAllSoceity });
		getAllDonationTypes({ donationTypes, setDonationTypes });
		getAllAppeals({ setAllAppeals, allAppeals });
	}, []);

	const cancelDeleteDepositById = () => {
		setDefaultstate({ ...defaultstate, id: '', open: false });
	};

	const getSocietyName = (row: DonationFormValues) => {
		if (!row?.societyId) {
			return 'N/A';
		}

		const body = allSociety?.data;
		if (!body) {
			return 'N/A';
		}

		const currDonation: Donations = depositData?.donations.find((x: any) => x.id === row?.id);
		const society = body.find((x: any) => x.id === row?.societyId);
		if (society) {
			return society.code;
		}
		if (currDonation?.society?.code) {
			return currDonation?.society?.code;
		}

		return 'N/A';
	};
	const getDonationType = (row: DonationFormValues) => {
		if (!row?.donationType?.id) {
			return 'N/A';
		}

		const body = donationTypes?.data;
		if (!body) {
			return 'N/A';
		}

		const currDonation: Donations = depositData?.donations.find((x: any) => x.id === row?.id);
		const donationType = body.find((x: any) => x.id === row?.donationType?.id);

		if (donationType) {
			return `${donationType.type} - ${donationType.description}`;
		}
		if (currDonation?.donationType.description) {
			currDonation?.donationType.description;
		}

		return 'N/A';
	};

	const getAppealCode = (row: DonationFormValues) => {
		const appealData = allAppeals?.data;
		if (!appealData) {
			return 'N/A';
		}

		const currDonation: Donations = depositData?.donations.find((x: any) => x.id === row?.id);
		const appeal = appealData.find((x: any) => x.id === row?.appealId);
		if (appeal) {
			return appeal.code;
		}
		if (currDonation?.appeal?.code) {
			return currDonation?.appeal?.code;
		}
		return 'N/A';
	};

	return (
		<>
			<ConfirmationModal
				handleClose={handleClose}
				message={'Are you sure you want to delete this Deposit?'}
				open={defaultstate?.open}
				handleConfirmation={() => {
					if (donations.length !== 0) {
						donations.splice(
							donations.findIndex((x) => x.id == defaultstate?.id),
							1,
						);
					}
					setTriggerRefresh(!triggerRefresh);
					setDefaultstate({
						...defaultstate,
						open: false,
					});
				}}
				handleCancel={cancelDeleteDepositById}
			/>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				{
					<TableContainer>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<TableCell sx={tableHead}>S/N</TableCell>
									{tableHeadColumns.map((column: string) => (
										<TableCell sx={tableHead} key={column} align="center" style={{ minWidth: 170 }}>
											{column}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{loading ? (
									<TableSkeletonContent columnCount={tableHeadColumns.length} columnMinWidth={170} isSN />
								) : donations?.length ? (
									donations?.map((row: DonationFormValues, index: number) => {
										return (
											<TableRow role="checkbox" sx={tableRow} tabIndex={-1} key={row.id || index}>
												<TableCell align="center">{index + 1}</TableCell>
												<TableCell align="center">
													<Stack
														direction="row"
														zIndex={-999}
														justifyContent="center"
														alignItems="center"
														spacing={2}
													>
														<Tooltip title="Edit">
															<Button
																variant="text"
																size="medium"
																color="inherit"
																onClick={() => row.id && openDonationForm(row.id)}
															>
																<Edit />
															</Button>
														</Tooltip>
														{!row.receiptNum && (
															<Tooltip title={isDisabled() ? 'Cannot delete psoted donations' : 'Delete'}>
																<span>
																	<Button
																		variant="text"
																		size="medium"
																		color="inherit"
																		onClick={() => row.id && handleOpenClose(row.id)}
																		disabled={isDisabled()}
																	>
																		<Archive />
																	</Button>
																</span>
															</Tooltip>
														)}
													</Stack>
												</TableCell>
												<TableCell align="center">{getSocietyName(row)}</TableCell>
												<TableCell align="center">
													{row.contribution ? <FormatCurrency value={row.contribution} /> : 'N/A'}{' '}
												</TableCell>
												<TableCell align="center">{getDonationType(row)}</TableCell>
												<TableCell align="center">{getAppealCode(row)}</TableCell>
												<TableCell align="center">{row.receiptNum ? row.receiptNum : 'N/A'}</TableCell>
												<TableCell align="center">
													{row?.issueDate ? `${convertDate(row?.issueDate)}` : 'N/A'}
												</TableCell>
												<TableCell align="center">{row.remark ? row.remark : 'N/A'}</TableCell>
											</TableRow>
										);
									})
								) : (
									donations && (
										<TableEmptyLayout
											emptyMessage="No donations yet"
											columnCount={tableHeadColumns.length}
											isSN
										/>
									)
								)}
							</TableBody>
						</Table>
					</TableContainer>
				}
			</Paper>
		</>
	);
};

export default DepositDonationTable;
