import React, { useRef } from 'react';
import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { convertDate } from 'helpers/dayAndTime';
import { fieldName } from 'Components/Reusable/FilterList/FilterList';
import Logo from './../../../assets/images/logo.png';
import { currentDate } from 'helpers/dayAndTime';
import { Typography } from '@mui/material';
import Report from '../Report';
import { useStyles } from 'Components/Reports/DonorReports/DonorReport.style';
import { useReactToPrint } from 'react-to-print';
import { INonCashDonationsReport } from 'contexts/intialstates/Types';
import { FormData } from '.';
import { formatCurrencyFunc } from 'helpers/FormatCurrency';

interface Props {
	data: INonCashDonationsReport[];
	formData: FormData;
	handlePdfDownload: () => void;
}

const SummarizedReport = ({ data, formData, handlePdfDownload }: Props) => {
	const classes = useStyles();
	const componentRef = useRef(null);

	const splitter = () => {
		if (!data?.length) return;
		const summarizedData: any = [];
		const finalData = [];
		data.map((summary, i: number) => {
			summarizedData.push({
				groupName: summary.groupName || '-',
				name: `Donations for ${summary.categories[0].name || '-'}`,
				total: summary.categories[0].total ? formatCurrencyFunc(summary.categories[0].total) : '-',
			});
			if (summary.categories.length > 1) {
				summary.categories.slice(1, summary.categories.length).map((item) => {
					summarizedData.push({
						groupName: '',
						name: `Donations for ${item.name || '-'}`,
						total: item.total ? formatCurrencyFunc(item.total) : '-',
					});
				});
			}
			summarizedData.push({
				groupTotal: summary.groupTotal ? formatCurrencyFunc(summary.groupTotal) : '-',
				groupName: `Total From Orders from ${summary.groupName || 'Anonymous'}`,
			});
			summarizedData.push(
				{
					groupName: '',
					name: '',
					total: '',
				},
				{
					groupName: '',
					name: '',
					total: '',
				},
			);
		});
		for (let i = 0; i < summarizedData.length; i += 15) {
			finalData.push(summarizedData.slice(i, i + 15));
		}
		return finalData;
	};

	const printContent = () => (
		<Box width="100%">
			<Table sx={{ minWidth: 700 }} aria-label="spanning table">
				<TableHead className={classes.tableHead}>
					<TableRow>
						<TableCell align="center">{fieldName(formData.groupby)}</TableCell>
						<TableCell align="center">Description</TableCell>
						<TableCell align="center">Donation Amount</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{splitter()
						?.flatMap((item: any) => item)
						.map((row: any, index: number) => (
							<>
								{row?.groupTotal ? (
									<TableRow key={index} className={classes.tableRowWrap}>
										<TableCell
											align="center"
											colSpan={2}
											sx={{
												color: '#445f6f',
												fontSize: 18,
												textTransform: 'capitalize',
												fontWeight: 600,
											}}
										>
											{row.groupName}
										</TableCell>
										<TableCell
											align="center"
											sx={{
												color: '#445f6f',
												fontSize: 18,
												textTransform: 'capitalize',
												fontWeight: 600,
												borderTop: '1px solid #ABAEB0',
											}}
										>
											{row.groupTotal}
										</TableCell>
									</TableRow>
								) : (
									<TableRow key={index} className={classes.tableRowWrap}>
										<TableCell
											align="center"
											sx={{
												color: '#445f6f',
												fontSize: 18,
												textTransform: 'capitalize',
												fontWeight: 600,
											}}
										>
											{row.groupName || ''}
										</TableCell>
										<TableCell align="center">{row.name || ''}</TableCell>
										<TableCell align="center">{row.total || ''}</TableCell>
									</TableRow>
								)}
							</>
						))}
					<TableRow className={classes.tableTotal}>
						<TableCell align="center" colSpan={2}>
							<Stack py={1} sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}>
								<Stack
									direction="row"
									justifyContent={'center'}
									sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
								>
									Grand Total
								</Stack>
							</Stack>
						</TableCell>
						<TableCell colSpan={1} align="center">
							<Stack py={1} sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}>
								<Stack
									direction="row"
									justifyContent={'center'}
									sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
								>
									{data.reduce((total, curr) => total + curr.groupTotal, 0)
										? formatCurrencyFunc(data.reduce((total, curr) => total + curr.groupTotal, 0))
										: '-'}
								</Stack>
							</Stack>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Box>
	);

	const printData = () => {
		const splitData = splitter();
		return (
			<TableContainer>
				{splitData?.map((dataSplit, index) => {
					return (
						<div className={classes.pageBreak} key={index}>
							<Table sx={{ minWidth: 700 }} key={index} aria-label="spanning table">
								<TableHead className={classes.tableHead}>
									<TableRow>
										<TableCell align="center">{fieldName(formData.groupby)}</TableCell>
										<TableCell align="center">Description</TableCell>
										<TableCell align="center">Donation Amount</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{dataSplit.map((row: any, index: number) => (
										<>
											{row?.groupTotal ? (
												<TableRow key={index} className={classes.tableRowWrap}>
													<TableCell
														align="center"
														colSpan={2}
														sx={{
															color: '#445f6f',
															fontSize: 18,
															textTransform: 'capitalize',
															fontWeight: 600,
														}}
													>
														{row.groupName}
													</TableCell>
													<TableCell
														align="center"
														sx={{
															color: '#445f6f',
															fontSize: 18,
															textTransform: 'capitalize',
															fontWeight: 600,
															borderTop: '1px solid #ABAEB0',
														}}
													>
														{row.groupTotal}
													</TableCell>
												</TableRow>
											) : (
												<TableRow key={index} className={classes.tableRowWrap}>
													<TableCell
														align="center"
														sx={{
															color: '#445f6f',
															fontSize: 18,
															textTransform: 'capitalize',
															fontWeight: 600,
														}}
													>
														{row.groupName || ''}
													</TableCell>
													<TableCell align="center">{row.name || ''}</TableCell>
													<TableCell align="center">{row.total || ''}</TableCell>
												</TableRow>
											)}
										</>
									))}
									{index === splitData.length - 1 && (
										<TableRow className={classes.tableTotal}>
											<TableCell align="center" colSpan={2}>
												<Stack
													py={1}
													sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
												>
													<Stack
														direction="row"
														justifyContent={'center'}
														sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
													>
														Grand Total
													</Stack>
												</Stack>
											</TableCell>
											<TableCell colSpan={1} align="center">
												<Stack
													py={1}
													sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
												>
													<Stack
														direction="row"
														justifyContent={'center'}
														sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
													>
														{data.reduce((total, curr) => total + curr.groupTotal, 0)
															? formatCurrencyFunc(data.reduce((total, curr) => total + curr.groupTotal, 0))
															: '-'}
													</Stack>
												</Stack>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</div>
					);
				})}
			</TableContainer>
		);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<>
			<Report
				title="Non-Cash Donations Summary Report"
				subTitle={`From ${convertDate(formData.start)} To ${convertDate(formData.end)}`}
				handlePrint={handlePdfDownload}
				category={formData?.category}
			>
				{printContent()}
			</Report>
			<div style={{ display: 'none' }}>
				<div style={{ padding: 16 }} ref={componentRef}>
					<Stack sx={{ my: 2, px: 1 }}>
						<>
							<Stack width="100%" direction="row">
								<Stack flex={0.5} alignItems="flex-start" direction="column">
									<img src={Logo} alt="logo" width={40} height={50} />
								</Stack>

								<Stack sx={{ mb: 2, textAlign: 'center' }} alignItems="center" direction="column">
									<Typography sx={{ color: '#1976d2', fontSize: 20, fontWeight: 700 }}>
										Non-Cash Donations Summary Report
									</Typography>
									<Typography sx={{ color: '#445f6f', fontSize: 18, fontWeight: 400 }}>
										{`From ${convertDate(formData.start)} To ${convertDate(formData.end)}`}
									</Typography>
								</Stack>
							</Stack>
							<Stack
								direction={'row'}
								justifyContent="flex-end"
								mb={3}
								sx={{ color: '#445f6f', fontSize: 16, fontWeight: 400 }}
							>
								{currentDate()}
							</Stack>
						</>

						<Box width="100%">{printData()}</Box>
					</Stack>
				</div>
			</div>
		</>
	);
};

export default SummarizedReport;
