import React, { useContext, useState, useEffect } from 'react';
import { Button, Stack, Paper } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Delete, SwapHoriz, ArrowBackIosNew, Add, Edit, RestoreFromTrash } from '@mui/icons-material';
import DonorTabs from './DonorTabs';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import ErrorSnack from 'Components/Common/ErrorSnack/ErrorSnack';
import { Tooltip } from '@mui/material';
import { GlobalContexts } from 'contexts/GlobalContext';
import { Donor, StateType } from 'contexts/intialstates/Types';
import useActions from '../../../hooks/useDonorActions';
import useDonorActions from 'hooks/useDonorActions';
import { styles, useStyles } from './DonorInfo.style';
import { Formik, FormikProps, Form } from 'formik';
import * as yup from 'yup';
import useUserActions from 'hooks/useUserActions';
import RecordDateOfDeathModal from './Annuities/RecordDateOfDeathModal';
import HistoryButton from 'Components/Common/Buttons/HistoryButton';
import { getChangedFields } from 'helpers/getChangedFields';

interface DonorFormValues {
	isOrganization: string;
	salutation: string;
	customReceiptSalutation: string;
	lastName: string;
	firstName: string;
	orgName: string;
	careOf: string;
	parish: {
		id?: number;
		parishName: string;
	};
	donorType: string;
	streetline1: string;
	streetline2: string;
	city: string;
	province: string;
	provinceId?: string;
	postalCode: string;
	country: string;
	phone: string;
	mobile: string;
	email: string;
	fax: string;
	remark: string;
	donorId?: number;
	receiptScheduleId?: number;
	preferredSociety: string[];
	communication: string[];
	specialDonor: string[];
	magazine?: boolean;
	noFollowAppeal?: boolean;
	dateOfBirth?: string;
}

const initalValues = {
	isOrganization: '',
	salutation: '',
	customReceiptSalutation: '',
	lastName: '',
	firstName: '',
	orgName: '',
	careOf: '',
	parish: {
		parishName: '',
	},
	donorType: '',
	streetline1: '',
	streetline2: '',
	city: '',
	province: '',
	postalCode: '',
	country: '',
	phone: '',
	mobile: '',
	dateOfBirth: '',
	email: '',
	fax: '',
	remark: '',
	preferredSociety: [],
	communication: [],
	specialDonor: [],
};

const validationCreateDonor = yup.object().shape({
	firstName: yup.string().typeError('Invalid First name').required('First name is required'),
	lastName: yup.string().typeError('Invalid Last name').required('Last name is required'),
	email: yup.string().email('Invalid Email').nullable(),
	phone: yup.number().typeError('Invalid Phone number'),
});
const validationCreateDonor2 = yup.object().shape({
	email: yup.string().email('Invalid Email').nullable(),
	phone: yup.number().typeError('Invalid Phone number'),
});

const DonorInfo = () => {
	const { handleRoles } = useUserActions();
	const classes = useStyles();
	const { setErrorSnack } = useContext(GlobalContexts);
	const { createSingleDonor, updateSingleDonor } = useDonorActions();
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const querySearch = new URLSearchParams(location.search);
	const tab = querySearch.get('tab');
	const activityId = querySearch.get('activityId');

	const [open, setOpen] = useState(false);
	const [isDobEmpty, setIsDobEmpty] = useState(false);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [singleDonor, setSingleDonor] = useState<StateType<Donor>>({
		data: null,
		loading: false,
		error: null,
	});
	const [initial, setInitial] = useState<DonorFormValues>(initalValues);
	const [saveLoading, setSaveLoading] = useState(false);
	const [reload, setIsReload] = useState(false);
	const [openRecordDateOfDeath, setOpenRecordDateOfDeath] = useState(false);

	const donorInformation = singleDonor?.data;
	const { getSingleDonor, deleteSingleDonor, restoreSingleDonor } = useActions();
	const [isOrg, setIsOrg] = useState();

	useEffect(() => {
		if (singleDonor?.data) {
			const donor = singleDonor?.data;
			const {
				magazine,
				faithPropagation,
				stPeter,
				holyChildhood,
				noFollowAppeal,
				noMail,
				returnedMail,
				deceased,
				excludeFromParishList,
				pap,
				annuity,
				estate,
			} = donor;
			const preferredSociety = [
				...(faithPropagation ? ['faithPropagation'] : []),
				...(stPeter ? ['stPeter'] : []),
				...(holyChildhood ? ['holyChildhood'] : []),
			];
			const communication = [
				...(noMail ? ['noMail'] : []),
				...(returnedMail ? ['returnMail'] : []),
				...(deceased ? ['deceased'] : []),
				...(excludeFromParishList ? ['excludeFromParishList'] : []),
			];
			const specialDonor = [
				...(pap ? ['pap'] : []),
				...(annuity ? ['annuity'] : []),
				...(estate ? ['estate'] : []),
			];

			setInitial({
				isOrganization: donor.isOrganization ? 'Yes' : 'No',
				salutation: donor.salutation || '',
				customReceiptSalutation: donor?.customReceiptSalutation || '',
				lastName: donor.lastName,
				firstName: donor.firstName,
				dateOfBirth: donor.dateOfBirth,
				orgName: donor.orgName || '',
				careOf: donor.careOf || '',
				parish: donor?.parish
					? {
							id: donor?.parishId,
							parishName: donor?.parish?.parishName,
					  }
					: {
							parishName: '',
					  },
				donorType: donor.donorType || '',
				streetline1: donor.streetline1 || '',
				streetline2: donor.streetline2 || '',
				city: donor.city || '',
				province: donor.province?.provinceName || '',
				postalCode: donor.postalCode || '',
				country: donor.country || '',
				phone: donor.phone || '',
				mobile: donor.mobile || '',
				email: donor.email || '',
				fax: donor.fax || '',
				remark: donor.remark || '',
				preferredSociety,
				communication,
				specialDonor,
				receiptScheduleId: donor?.receiptSchedule?.id,
				magazine,
				noFollowAppeal,
			});
		}
	}, [singleDonor]);

	useEffect(() => {
		if (id) {
			getSingleDonor({ donorId: id, singleDonor, setSingleDonor, setErrorSnack });
			setIsEdit(true);
		}
	}, [id, reload]);

	useEffect(() => {
		if (id && singleDonor.data && activityId) {
			setIsEdit(false);
		}
	}, [id, singleDonor.data, activityId]);

	const handleOpenClose = () => setOpen(!open);

	const cancelDeleteDonorByID = () => {
		setOpen(false);
	};

	const handleDelete = async () => {
		if (!id) return;
		if (singleDonor.data?.archived) {
			setDeleteLoading(true);
			const isSucess = await restoreSingleDonor(parseInt(id));
			setDeleteLoading(false);
			cancelDeleteDonorByID();
			if (isSucess) {
				setErrorSnack(false);
				setIsReload(!reload);
			} else {
				setErrorSnack(true);
			}
			return;
		}
		setDeleteLoading(true);
		const isSucess = await deleteSingleDonor(parseInt(id));
		setDeleteLoading(false);
		cancelDeleteDonorByID();
		if (isSucess) {
			navigate('/donors');
			setErrorSnack(false);
		} else {
			setErrorSnack(true);
		}
	};

	if (singleDonor?.error) {
		setErrorSnack(true);
	}

	const handleSubmit = async (values: DonorFormValues) => {
		if (!values.dateOfBirth && id && donorInformation?.isAnnuitant) {
			setIsDobEmpty(true);
			return;
		}
		const payload = {
			salutation: values.salutation,
			customReceiptSalutation: values.customReceiptSalutation,
			lastName: values.lastName,
			firstName: values.firstName,
			dateOfBirth: values.dateOfBirth || null,
			orgName: values.orgName,
			careOf: values.careOf,
			parishId: values.parish.id,
			streetline1: values.streetline1,
			streetline2: values.streetline2,
			city: values.city,
			province: values.province,
			provinceId: values.provinceId,
			postalCode: values.postalCode,
			country: values.country,
			phone: values.phone,
			mobile: values.mobile,
			email: values.email,
			fax: values.fax,
			isOrganization: values.isOrganization === 'Yes',
			noMail: values.communication.includes('noMail'),
			returnedMail: values.communication.includes('returnMail'),
			deceased: values.communication.includes('deceased'),
			excludeFromParishList: values.communication.includes('excludeFromParishList'),
			magazine: values.magazine,
			faithPropagation: values.preferredSociety.includes('faithPropagation'),
			stPeter: values.preferredSociety.includes('stPeter'),
			holyChildhood: values.preferredSociety.includes('holyChildhood'),
			noFollowAppeal: values.noFollowAppeal,
			pap: values.specialDonor.includes('pap'),
			annuity: values.specialDonor.includes('annuity'),
			estate: values.specialDonor.includes('estate'),
			receiptScheduleId: values.receiptScheduleId,
			donorType: values.donorType,
		};

		setSaveLoading(true);
		let isSuccess;
		if (id) {
			console.log(payload.isOrganization, initial.isOrganization === 'Yes');
			isSuccess = await updateSingleDonor(
				{
					...payload,
					changedFields: getChangedFields(payload, {
						...initial,
						isOrganization: initial?.isOrganization === 'Yes',
					}),
				},
				parseInt(id),
			);
		} else {
			const id = await createSingleDonor(payload);
			window.close();
			if (id) {
				navigate(`/donors/${id}`);
			}
		}
		if (isSuccess) {
			setIsReload(!reload);
		}
		setSaveLoading(false);
	};

	const handleToggleRecord = () => {
		setOpenRecordDateOfDeath(!openRecordDateOfDeath);
	};

	return (
		<>
			<>
				<ConfirmationModal
					handleClose={handleOpenClose}
					message={
						singleDonor.data?.archived
							? 'Do you want to unarchive this Donor?'
							: 'Are you sure you want to archive this Donor?'
					}
					open={open}
					handleConfirmation={handleDelete}
					handleCancel={cancelDeleteDonorByID}
					loading={deleteLoading}
				/>
				<Formik
					enableReinitialize
					onSubmit={handleSubmit}
					validationSchema={isOrg === 'Yes' ? validationCreateDonor2 : validationCreateDonor}
					initialValues={initial}
				>
					{({ setFieldValue, values }: FormikProps<DonorFormValues>) => (
						<Form noValidate autoComplete="off">
							<Stack spacing={3}>
								<Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap={'wrap'}>
									<Stack
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										spacing={4}
										marginBottom={2}
									>
										<Stack className={classes.backIconWrapper}>
											<ArrowBackIosNew onClick={() => navigate('/donors')} />
										</Stack>
										<Stack direction="column" spacing={1}>
											{' '}
											<Stack alignItems={'flex-start'}>
												<span className={classes.title}>
													{donorInformation?.orgName
														? donorInformation?.orgName || ''
														: donorInformation?.firstName || donorInformation?.lastName
														? `${donorInformation?.firstName || ''}  ${donorInformation?.lastName || ''}`
														: '-'}
												</span>
												<span className={classes.subTitle}>Donor ID: {donorInformation?.id ?? 'xxxx'}</span>
											</Stack>
											{id && (
												<Button sx={styles.statusBtn} disableElevation disableRipple variant="outlined">
													{donorInformation?.archived ? 'Archived' : 'Active'}
												</Button>
											)}
										</Stack>
									</Stack>
									{handleRoles('donor-actions') && (
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems={'center'}
											spacing={2}
											marginBottom={2}
										>
											{/* <HistoryButton /> */}
											{!isEdit && !activityId && (
												<LoadingButton
													loadingPosition="start"
													loading={saveLoading}
													startIcon={<Add />}
													size={'small'}
													variant="contained"
													type={'submit'}
													disabled={!!activityId}
												>
													Save
												</LoadingButton>
											)}
											{isEdit && (
												<Button
													size={'small'}
													onClick={() => navigate(`/donors/merge/${id}`)}
													variant="contained"
													startIcon={<SwapHoriz />}
												>
													Merge Donor
												</Button>
											)}
											{isEdit && (
												<Button
													variant="contained"
													size="small"
													startIcon={<Edit />}
													disabled={
														!singleDonor?.data?.annuitant || !!singleDonor?.data?.annuitant?.dateOfDeath
													}
													onClick={handleToggleRecord}
												>
													Record Date of Death
												</Button>
											)}
											{isEdit && singleDonor.data?.donations.length === 0 ? (
												<Tooltip title="Delete">
													<Button
														startIcon={<Delete />}
														variant="contained"
														size="small"
														onClick={handleOpenClose}
													>
														Delete
													</Button>
												</Tooltip>
											) : (
												isEdit && (
													<Tooltip title="Archive">
														<Button
															variant="contained"
															startIcon={singleDonor.data?.archived ? <RestoreFromTrash /> : <Delete />}
															size="small"
															onClick={handleOpenClose}
														>
															{singleDonor.data?.archived ? 'Unarchive' : 'Archive'}
														</Button>
													</Tooltip>
												)
											)}
											{isEdit && (
												<Button
													variant="contained"
													startIcon={<Edit />}
													size="small"
													onClick={() => setIsEdit(false)}
												>
													Edit Overview
												</Button>
											)}
										</Stack>
									)}
								</Stack>
								<Paper className={classes.paperWrap}>
									<DonorTabs
										donorInformation={donorInformation ?? undefined}
										loading={singleDonor?.loading}
										isEdit={!isEdit}
										setFieldValue={setFieldValue}
										setIsOrg={setIsOrg}
										values={values}
										tabValue={tab ? parseInt(tab) : 0}
										isDobEmpty={isDobEmpty}
										setIsDobEmpty={setIsDobEmpty}
									/>
								</Paper>
							</Stack>
						</Form>
					)}
				</Formik>
			</>
			<ErrorSnack message={JSON.stringify(singleDonor?.error?.Message)} />
			{openRecordDateOfDeath && (
				<RecordDateOfDeathModal
					visible={openRecordDateOfDeath}
					handleClose={handleToggleRecord}
					donorId={id ? parseInt(id) : undefined}
					reload={reload}
					setReload={setIsReload}
					donor={singleDonor?.data ?? undefined}
				/>
			)}
		</>
	);
};

export default DonorInfo;
