import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { GlobalContexts } from 'contexts/GlobalContext';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ISnackBar } from 'contexts/intialstates/Types';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ErrorSnack({ message }: ISnackBar) {
	const { errorSnack } = React.useContext(GlobalContexts);

	return (
		<Stack spacing={2} sx={{ width: '100%' }}>
			<Snackbar open={errorSnack}>
				<Alert severity="error" sx={{ width: '100%' }}>
					{message ? message : 'An erorr occurred'}
				</Alert>
			</Snackbar>
		</Stack>
	);
}

export const SimpleSnackbar = () => {
	const { simpleErrorSnack, setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setSimpleErrorSnack({ ...simpleErrorSnack, show: false });
	};

	const action = (
		<>
			<IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		</>
	);

	return (
		<div>
			<Snackbar
				open={simpleErrorSnack.show}
				autoHideDuration={simpleErrorSnack.hide || 4000}
				onClose={handleClose}
				action={action}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert
					onClose={handleClose}
					variant="outlined"
					severity={simpleErrorSnack.severity}
					sx={{ width: '100%', background: 'white' }}
				>
					{simpleErrorSnack.message}
				</Alert>
			</Snackbar>
		</div>
	);
};
