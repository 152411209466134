import * as React from 'react';
import { Stack, Paper } from '@mui/material';
import { ColumnType, eoyReceiptHead } from 'Components/Common/Tables/TableHeaders';
import { convertDate } from 'helpers/dayAndTime';
import ErrorSnack from 'Components/Common/ErrorSnack/ErrorSnack';
import FormatCurrency from 'helpers/FormatCurrency';
import useReceiptActions from 'hooks/useReceiptActions';
import { EOYReceipt, StateType } from 'contexts/intialstates/Types';
import { GenerateEOYReceipt } from 'Components/Reusable/GenerateReceipt/GenerateReceipt';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';

interface EOYReceiptProps {
	id: number;
}

const EOYReceiptTable = ({ id }: EOYReceiptProps) => {
	const [allEOYReceipt, setAllEOYReceipt] = React.useState<StateType<EOYReceipt[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const { getAllEOYReceipt } = useReceiptActions();
	React.useEffect(() => {
		getAllEOYReceipt({
			donorId: id,
			allEOYReceipt,
			setAllEOYReceipt,
		});
	}, [id]);

	const data = allEOYReceipt?.data;

	const getTableData = () => {
		return data?.length
			? data.map((row: any, index: number) => {
					return {
						's/n': index + 1,
						actions: (
							<Stack direction="row" zIndex={-999} justifyContent="center" alignItems="center" spacing={2}>
								<GenerateEOYReceipt id={row.id as number} />
							</Stack>
						),
						amount: row?.amount ? <FormatCurrency value={row?.amount} /> : 'N/A',
						receiptNumber: row?.receiptNumber || 'N/A',
						society: row?.society?.code || 'N/A',
						recordCount: row?.recordCount || 'N/A',
						issueDate: row?.issueDate ? `${convertDate(row?.issueDate)}` : 'N/A',
						receiptYear: row?.receiptYear || 'N/A',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList;
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	return (
		<>
			<Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }}>
				<TrimTables
					name="eoyReceiptHead"
					list={eoyReceiptHead}
					trimList={trimList}
					setTrimList={setTrimList}
				/>
			</Stack>
			<Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }}></Stack>

			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<CustomTable
					loading={allEOYReceipt?.loading}
					columnData={getColumnData()}
					tableData={getTableData()}
					handleDrag={handleDrag}
					handleResize={handleResize}
					emptyMessage={'No EOY reciepts available'}
				/>
			</Paper>
		</>
	);
};
<ErrorSnack />;

export default EOYReceiptTable;
