import { Skeleton, Stack, Typography, Tab, Tabs, Box } from '@mui/material';
import DonorTemplateDonation from '../DonorTemplateDeposit';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import DonorTable from '../DonorTable/DonorTable';
import { Wrapper } from '../DonorTable/DonorTable.style';
import { Donor } from 'contexts/intialstates/Types';
import DonorNotes from '../DonorNotes';
import EOYReceiptTable from '../EOYReceiptTable/EOYReceiptTable';
import OverviewDisplay from '../Overview/OverviewDisplay';
import CreateEditOverview from '../Overview/CreateEditOverview';
import BurseChalise from '../BurseChalice';
import { useNavigate } from 'react-router-dom';
import Relatives from '../Relatives';
import Annuities from '../Annuities';

export interface IDonorInformation {
	donorInformation: IDonorData;
}

export interface IDonorData {
	address?: string | undefined;
	company?: string | undefined;
	firstName?: string | undefined;
	lastName?: string | undefined;
	orgName?: string | undefined;
	city?: string | undefined;
	country?: string | undefined;
	email?: string | undefined;
	id?: number | undefined;
	name?: string | undefined;
	phone?: string | undefined;
	username?: string | undefined;
	website?: string | undefined;
	archived?: boolean | undefined;
	careOf?: string | undefined;
	deceased?: boolean | undefined;
	donorType?: string | undefined;
	fax?: number | undefined;
	followUp?: boolean | undefined;
	hcAppeal?: boolean | undefined;
	indOrGrp?: string | undefined;
	magazine?: boolean | undefined;
	mobile?: number | undefined;
	noMail?: boolean | undefined;
	operator?: string | undefined;
	parishCode?: number | undefined;
	pfAppeal?: boolean | undefined;
	postalCode?: number | undefined;
	principalId?: string | undefined;
	province?: string | undefined;
	receiptCode?: number | undefined;
	remark?: string | undefined;
	returnMail?: boolean | undefined;
	salutation?: string | undefined;
	spAppeal?: boolean | undefined;
	streetline1?: string | undefined;
	streetline2?: string | undefined;
	xParish?: boolean | undefined;
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

interface DonorTabsProps {
	donorInformation?: Donor;
	loading?: boolean;
	isEdit?: boolean;
	values?: any;
	setFieldValue?: any;
	setIsOrg: any;
	tabValue?: number;
	setIsDobEmpty?: Dispatch<SetStateAction<boolean>>;
	isDobEmpty?: boolean;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			style={{ marginBottom: '2rem' }}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={{ xs: 1, sm: 1, md: 1, lg: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const GeneralTabSkeleton = () => {
	return (
		<>
			{[...Array.from({ length: 13 })].map((i, index) => (
				<Stack direction="column" key={index}>
					<Skeleton variant="text" width={130} />
					<Skeleton variant="text" width={100} />
				</Stack>
			))}
		</>
	);
};

const DonorTabs = ({
	donorInformation,
	loading,
	isEdit,
	setFieldValue,
	values,
	tabValue,
	setIsDobEmpty,
	isDobEmpty,
	setIsOrg,
}: DonorTabsProps) => {
	const id = donorInformation?.id;

	const navigate = useNavigate();
	const querySearch = new URLSearchParams(location.search);
	const activityId = querySearch.get('activityId');

	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
		navigate(`/donors/${id}?tab=${newValue}`);
	};

	useEffect(() => {
		if (isEdit && id && activityId) {
			setValue(0);
			navigate(`/donors/${id}?tab=0&activityId=${activityId}`);
		} else if (isEdit && id) {
			setValue(0);
			navigate(`/donors/${id}?tab=0`);
		}
	}, [isEdit]);

	useEffect(() => {
		if (!id) return;
		if (tabValue) {
			setValue(tabValue);
			navigate(`/donors/${id}?tab=${tabValue}`);
			return;
		}
		setValue(0);
	}, [tabValue, id]);

	useEffect(() => {
		setIsOrg(values.isOrganization);
	}, [values.isOrganization]);

	return (
		<Wrapper>
			<Box sx={{ width: '100%' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
						<Tab label="Overview" {...a11yProps(0)} />
						{!isEdit && <Tab label="Donations" {...a11yProps(1)} />}
						{!isEdit && <Tab label="EOY Receipt" {...a11yProps(2)} />}
						{!isEdit && <Tab label="Recurring(CC)" {...a11yProps(3)} />}
						{!isEdit && <Tab label="Notes" {...a11yProps(4)} />}
						{!isEdit && <Tab label="Burse/Chalice" {...a11yProps(5)} />}
						{!isEdit && <Tab label="Relatives" {...a11yProps(6)} />}
						{!isEdit && <Tab label="Annuities" {...a11yProps(7)} />}
					</Tabs>
				</Box>
				<TabPanel value={value} index={0}>
					<Stack direction="column" spacing={4}>
						{loading ? (
							<GeneralTabSkeleton />
						) : !isEdit ? (
							<OverviewDisplay donorInformation={donorInformation} />
						) : (
							<CreateEditOverview
								setFieldValue={setFieldValue}
								values={values}
								id={id}
								isDobEmpty={isDobEmpty}
								setIsDobEmpty={setIsDobEmpty}
							/>
						)}
					</Stack>
				</TabPanel>
				<TabPanel value={value} index={1}>
					{id && <DonorTable id={parseInt(id)} donorInformation={donorInformation} />}
				</TabPanel>
				<TabPanel value={value} index={2}>
					{id && <EOYReceiptTable id={parseInt(id)} />}
				</TabPanel>
				<TabPanel value={value} index={3}>
					{id && <DonorTemplateDonation id={parseInt(id)} />}
				</TabPanel>
				<TabPanel value={value} index={4}>
					{id && <DonorNotes donorId={parseInt(id)} />}
				</TabPanel>
				<TabPanel value={value} index={5}>
					{id && <BurseChalise donorId={parseInt(id)} />}
				</TabPanel>
				<TabPanel value={value} index={6}>
					{id && <Relatives donorId={parseInt(id)} />}
				</TabPanel>
				<TabPanel value={value} index={7}>
					{id && <Annuities donorId={parseInt(id)} annuitantId={donorInformation?.annuitant?.id} />}
				</TabPanel>
			</Box>
		</Wrapper>
	);
};

export default DonorTabs;
