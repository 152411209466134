import React, { useEffect, useState } from 'react';
import { Stack, IconButton, Grid, Typography, Button, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AddTask, ArrowBackIosNew, Clear, FilterAltOutlined } from '@mui/icons-material';
import useReportAction, { ResidualPostingReport } from 'hooks/useReportAction';
import { StateType } from 'contexts/intialstates/Types';
import { MiniCard } from 'Components/Reports/Card';
import BasicDatePicker, { Months } from 'Components/Reusable/DatePicker';
import { LoadingButton } from '@mui/lab';
import { useGlobalStyles } from 'Components/Common/global/global';
import { useStyles } from '../style';
import Report from './Report';

interface FormValues {
	year?: number;
	month?: number;
}

const Residual = () => {
	const navigate = useNavigate();
	const classes = useStyles();
	const global = useGlobalStyles();
	const [residual, setResidual] = useState<StateType<ResidualPostingReport>>({
		data: null,
		loading: false,
		error: null,
	});

	const { getResidual, getResidualCSV, getResidualPDF } = useReportAction();

	const [isEdit, setIsEdit] = useState(false);
	const [isShowFilter, setIsShowFilter] = useState(true);

	const [formData, setFormData] = useState<FormValues>({
		month: undefined,
		year: undefined,
	});
	const [formErrors, setFormErrors] = useState<string[]>([]);

	const handleCancel = () => {
		setFormData({
			year: undefined,
			month: undefined,
		});
		setResidual({
			data: null,
			loading: false,
			error: null,
		});
	};

	const handleChange = (field: string, value: string) => {
		if (formErrors.includes(field)) {
			setFormErrors(formErrors.filter((item) => item !== field));
		}
		if (field === 'year') {
			setFormData({
				...formData,
				[field]: new Date(value).getFullYear(),
			});

			return;
		}
		setFormData({
			...formData,
			[field]: value,
		});
	};

	const handleGetReport = async () => {
		await getResidual({ residual, setResidual, year: formData?.year, month: formData?.month });
	};
	const handleGetReportCSV = async () => {
		await getResidualCSV(formData?.month, formData?.year);
	};
	const handleGetReportPDF = async () => {
		await getResidualPDF(formData?.month, formData?.year);
	};

	useEffect(() => {
		if (residual.data) {
			setIsEdit(true);
			setIsShowFilter(false);
		}
	}, [residual.data]);

	return (
		<div>
			<Stack direction="row" marginBottom={2} alignItems={'center'} justifyContent="space-between">
				<Stack direction="row" alignItems={'center'} spacing={4}>
					<Stack className={classes.backIconWrapper}>
						<ArrowBackIosNew onClick={() => navigate('/reports')} />
					</Stack>
					<Stack>
						<span className={classes.title}>Residual Posting Report</span>
					</Stack>
				</Stack>
				{isEdit && (
					<IconButton
						sx={{
							background: 'white',
							borderRadius: '6px',
							width: 40,
							height: 30,
							border: '1px solid var(--mainColor)',
						}}
						onClick={() => setIsShowFilter(!isShowFilter)}
					>
						<FilterAltOutlined sx={{ fontSize: 16, color: 'var(--mainColor)' }} />
					</IconButton>
				)}
			</Stack>
			{isShowFilter && (
				<MiniCard title="Filters">
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
						<Grid item xs={12} md={4} sm={6} mb={3}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Year
							</Typography>
							<Stack>
								<BasicDatePicker
									size="small"
									year
									value={formData?.year && `01/01/${formData?.year}`}
									setValue={(e: any) => handleChange('year', e.target.value)}
								/>
								{formErrors.includes('year') && <span className={global.errorText}>Year is required</span>}
							</Stack>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Month
							</Typography>
							<Stack>
								<Select
									size="small"
									fullWidth
									placeholder="Month"
									value={formData?.month}
									onChange={(e: any) => handleChange('month', e.target.value)}
								>
									{Months.map((x: { id: number; name: string }) => (
										<MenuItem key={x.id} value={x.id}>
											{x.name}
										</MenuItem>
									))}
								</Select>
								{formErrors.includes('month') && <span className={global.errorText}>Month is required</span>}
							</Stack>
						</Grid>

						{isEdit && (
							<Grid item xs={12} md={4} sm={6} mb={3}>
								<Stack direction="row" spacing={2} height="100%" alignItems="flex-end" p={1}>
									<LoadingButton
										startIcon={<AddTask />}
										variant="contained"
										onClick={handleGetReport}
										type="submit"
										loading={residual.loading}
										loadingPosition="start"
										size="small"
									>
										Apply
									</LoadingButton>

									<Button
										startIcon={<Clear />}
										variant="outlined"
										className={global.whiteBtn}
										onClick={handleCancel}
										size="small"
									>
										Cancel
									</Button>
								</Stack>
							</Grid>
						)}
					</Grid>
				</MiniCard>
			)}
			{!isEdit && (
				<Stack m={3} direction="row">
					<LoadingButton
						startIcon={<AddTask />}
						variant="contained"
						onClick={handleGetReport}
						loading={residual.loading}
						loadingPosition="start"
						size="small"
					>
						Generate Report
					</LoadingButton>
				</Stack>
			)}
			{!residual.loading && residual?.data?.details?.length ? (
				<Report
					handleGetReportCSV={handleGetReportCSV}
					handleGetReportPDF={handleGetReportPDF}
					residual={residual}
				/>
			) : null}
		</div>
	);
};

export default Residual;
