import * as React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import { Cancel, TaskAlt } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

export type OrganizeBy = 'true' | 'false';

interface CustomModal {
	open: boolean;
	message: string;
	handleClose: () => void;
	handleConfirmation: () => void;
	handleCancel: () => void;
	loading?: boolean;
	bySociety?: boolean;
	value?: boolean;
	setValue?: React.Dispatch<React.SetStateAction<boolean>>;
	confirmation?: boolean;
	title?: string;
	sendEmail?: boolean;
	setSendEmail?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmationModal = ({
	handleClose,
	message,
	open,
	handleConfirmation,
	handleCancel,
	loading,
	bySociety,
	value,
	setValue,
	title,
	sendEmail,
	setSendEmail,
}: CustomModal) => {
	const handleChange = (event: any) => {
		if (bySociety && setValue) {
			setValue((event.target as HTMLInputElement).value === 'true');
			return;
		}
	};
	const handleEmail = (event: any) => {
		if (!setSendEmail) return;

		setSendEmail((event.target as HTMLInputElement).value === 'true');
		return;
	};
	return (
		<div>
			<BasicModal confirmation onClose={handleClose} visible={open} title={title || 'Confirmation'}>
				<Typography sx={{ fontWeight: 400, textAlign: 'center' }}> {message}</Typography>
				<Stack direction={'row'} justifyContent="space-between">
					{setSendEmail && (
						<FormControl sx={{ mt: 2 }}>
							<RadioGroup
								aria-labelledby="demo-controlled-radio-buttons-group"
								name="controlled-radio-buttons-group"
								value={sendEmail}
								onClick={handleEmail}
							>
								<FormControlLabel value="false" control={<Radio />} label="Do Not Send Mail" />
								<FormControlLabel value="true" control={<Radio />} label="Send Email" />
							</RadioGroup>
						</FormControl>
					)}{' '}
					{bySociety && (
						<FormControl sx={{ mt: 2 }}>
							<RadioGroup
								aria-labelledby="demo-controlled-radio-buttons-group"
								name="controlled-radio-buttons-group"
								value={value}
								onClick={handleChange}
							>
								<FormControlLabel value="true" control={<Radio />} label="Organize By Society" />
								<FormControlLabel value="false" control={<Radio />} label="Organize By Donor" />
							</RadioGroup>
						</FormControl>
					)}
				</Stack>

				<Stack direction="row" justifyContent="center" py={3} spacing={2}>
					<Button
						sx={{ textTransform: 'none' }}
						onClick={handleCancel}
						variant="outlined"
						style={{ marginTop: 4 }}
						startIcon={<Cancel />}
					>
						Cancel
					</Button>
					<LoadingButton
						sx={{ textTransform: 'none' }}
						onClick={handleConfirmation}
						variant="contained"
						style={{ marginTop: 4 }}
						startIcon={<TaskAlt />}
						loading={loading}
						loadingPosition="start"
					>
						Confirm
					</LoadingButton>
				</Stack>
			</BasicModal>
		</div>
	);
};

export default ConfirmationModal;
