import React, { useState, useEffect } from 'react';
import { Paper } from '@mui/material';
import FormFieldLayout, { FieldLayout } from './FormFieldLayout';
import { useStyles } from '../../Annuities.style';
import { formFieldData, hideFields } from './CreateFieldsForm';
import useDebounce from 'Components/Reusable/hooks/debounce';
import useDonorAction from 'hooks/useDonorActions';
import useAnnuitiesActions, { AgreementType, PaymentFrequency } from 'hooks/useAnnuitiesActions';
import useAnnuitantActions, { AnnuitantGender, MaritalStatus } from 'hooks/useAnnuitantActions';
import { StateType, Donor, PaymentMethod, Society } from 'contexts/intialstates/Types';
import DonorSearch from 'Components/Reports/DonorReports/DonorSearch';
import usePaymentmethodsActions from 'hooks/usePaymentMethodAction';
import useSocietyActions from 'hooks/useSocietyAction';
import { Error } from 'Components/Annuities/CreateEdit/CreateEditAnnuities/FormFieldLayout';

export interface FormValues {
	agreementNumber?: string;
	agreementTypeId?: number;
	agreementTypeName?: string;
	provinceOfRegistration?: {
		id: number;
		name: string;
	};
	paymentMethodId?: number;
	donor?: {
		id: number;
		name: string;
		altName: string;
	};
	donorId?: number;
	salutation?: string;
	sin?: string;
	genderId?: number;
	maritalStatusId?: number;
	dateOfBirth?: string;
	dateOfDeath?: string;
	coSin?: string;
	coDonor?: {
		id: number;
		name: string;
		altName: string;
	};
	coDonorId?: number;
	coSalutation?: string;
	coGenderId?: number;
	coMaritalStatusId?: number;
	coDateOfBirth?: string;
	coDateOfDeath?: string;
	streetline1?: string;
	streetline2?: string;
	city?: string;
	province?: string;
	postalCode?: string;
	country?: string;
	bankName?: string;
	bankAddress?: string;
	bankNumber?: string;
	bankBranchNumber?: string;
	bankAccountNumber?: string;
	bankPayableTo?: string;
	receivedAmount?: string;
	receiptedAmount?: string;
	dateReceived?: string;
	paymentFrequencyId?: number;
	firstPaymentDate?: string;
	firstAnnuityPayment?: string;
	periodicPayment?: string;
	annualPayment?: string;
	annualRate?: string;
	taxPortionAnnuity?: string;
	firstPaymentTaxableAmount?: string;
	annualPaymentTaxableAmount?: string;
	beneficiarySocietyId?: number;
	comment?: string;
	accountBalance?: number;
	accountDate?: string;
	acturialValue?: number;
	valuationDate?: string;
	lifeExpectancy?: number;
	firstPaymentNonTaxablePortion?: number;
	annualPaymentNonTaxablePortion?: number;
}

interface P {
	id?: number;
	values: FormValues;
	setValues: React.Dispatch<React.SetStateAction<FormValues>>;
	errors: Error[];
	setErrors: React.Dispatch<React.SetStateAction<Error[]>>;
	disabled: boolean;
}

const CreateAnnuities = ({ id, values, setValues, errors, setErrors, disabled }: P) => {
	const classes = useStyles();
	const { getSearchDonors } = useDonorAction();
	const { getAgreementTypes, getPaymentFrequency } = useAnnuitiesActions();
	const { getAnnuitantGender, getMaritalStatus } = useAnnuitantActions();
	const [allSociety, setAllSoceity] = React.useState<StateType<Society[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [allPM, setPM] = React.useState<StateType<PaymentMethod[]>>({
		data: null,
		error: null,
		loading: false,
	});

	const [formField, setFormField] = useState<FieldLayout[]>(formFieldData);
	const [visibleSearch, setVisibleSearch] = useState({
		open: false,
		type: '',
	});
	const [donorSearchTerm, setDonorSearchTerm] = useState('');
	const debouncedDonorSearchTerm = useDebounce(donorSearchTerm, 1000);
	const [coDonorSearchTerm, setCoDonorSearchTerm] = useState('');
	const debouncedCoDonorSearchTerm = useDebounce(coDonorSearchTerm, 1000);
	const [agreementType, setAgreementType] = useState<StateType<AgreementType[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [paymentFrequency, setPaymentFrequency] = useState<StateType<PaymentFrequency[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [annuitantGender, setAnnuitantGender] = useState<StateType<AnnuitantGender[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [maritalStatus, setMaritalStatus] = useState<StateType<MaritalStatus[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const { getSocieties } = useSocietyActions();
	const { getPaymentMethods } = usePaymentmethodsActions();
	useEffect(() => {
		if (id) {
			handleUnHideFields(false);
			disabledAnnuitantFields(true, 'donor');
			disabledAnnuitantFields(true, 'coDonor');
		} else {
			handleUnHideFields(true);
		}
	}, [id]);

	useEffect(() => {
		const formData = [...formField];
		const formNewData = formData.map((item) => {
			item.field = item.field.map((field) => {
				if (field.id !== 'comment' && disabled) {
					field.disabled = true;
				} else {
					if (
						field.id === 'donorId' ||
						field.id === 'salutation' ||
						field.id === 'dateOfDeath' ||
						field.id === 'streetline1' ||
						field.id === 'streetline2' ||
						field.id === 'city' ||
						field.id === 'province' ||
						field.id === 'postalCode' ||
						field.id === 'coDonorId' ||
						field.id === 'coDateOfDeath'
					)
						return field;
					field.disabled = false;
				}
				return field;
			});
			return item;
		});
		setFormField(formNewData);
	}, [disabled]);

	const handleUnHideFields = (hide: boolean) => {
		const formData = [...formField];
		const formNewData = formData.map((item) => {
			if (item.hide && item?.id !== 'coAnnuitant') {
				item.hide = hide;
			}
			if (item.hide && item?.id === 'coAnnuitant' && values.agreementTypeName === 'Joint') {
				item.hide = false;
			}
			item.field = item.field.map((field) => {
				if (hideFields.includes(field.id)) {
					field.hide = hide;
				}
				return field;
			});
			return item;
		});
		setFormField(formNewData);
	};

	const [searchDonors, setSearchDonors] = useState<StateType<Donor[]>>({
		data: null,
		loading: false,
		error: null,
	});

	const [searchCoDonors, setSearchCoDonors] = useState<StateType<Donor[]>>({
		data: null,
		loading: false,
		error: null,
	});

	useEffect(() => {
		getSocieties({ allSociety, setAllSoceity });
		getPaymentMethods({ allPM, setPM });
	}, []);

	useEffect(() => {
		getAgreementTypes({ agreementType, setAgreementType });
		getPaymentFrequency({ paymentFrequency, setPaymentFrequency });
		getAnnuitantGender({ annuitantGender, setAnnuitantGender });
		getMaritalStatus({ maritalStatus, setMaritalStatus });
	}, []);

	useEffect(() => {
		if (!debouncedDonorSearchTerm) return;
		getSearchDonors({ searchDonors, setSearchDonors, searchTerm: debouncedDonorSearchTerm });
	}, [debouncedDonorSearchTerm]);

	useEffect(() => {
		if (!debouncedCoDonorSearchTerm) return;
		getSearchDonors({
			searchDonors: searchCoDonors,
			setSearchDonors: setSearchCoDonors,
			searchTerm: debouncedCoDonorSearchTerm,
		});
	}, [debouncedCoDonorSearchTerm]);

	useEffect(() => {
		if (!searchDonors?.data || !searchDonors?.data?.length) return;
		handleMenuChange('Annuitant Details', 'donor', searchDonors.data);
	}, [searchDonors?.data]);

	useEffect(() => {
		if (!searchCoDonors?.data || !searchCoDonors?.data?.length) return;
		handleMenuChange('Co-Annuitant Details', 'coDonor', searchCoDonors.data);
	}, [searchCoDonors?.data]);

	useEffect(() => {
		if (!agreementType?.data?.length) return;
		const formFieldsData = formField.map((item) => {
			item.field = item.field.map((fieldItem) => {
				if (fieldItem.id === 'agreementTypeId') {
					fieldItem.menu = agreementType?.data?.map(({ id, type }) => {
						return {
							id,
							name: type,
						};
					});
				}
				return fieldItem;
			});
			return item;
		});
		setFormField(formFieldsData);
	}, [agreementType?.data]);

	useEffect(() => {
		const allSocieties = allSociety?.data;
		if (!allSocieties?.length) return;
		const formFieldsData = formField.map((item) => {
			item.field = item.field.map((fieldItem) => {
				if (fieldItem.id === 'beneficiarySocietyId') {
					fieldItem.menu = allSocieties
						?.filter((curr) => curr.code !== 'AN')
						?.map(({ id, code }) => {
							return {
								id,
								name: code,
							};
						});
				}
				return fieldItem;
			});
			return item;
		});
		setFormField(formFieldsData);
	}, [allSociety?.data]);

	useEffect(() => {
		const allPaymentMethods = allPM?.data;
		if (!allPaymentMethods?.length) return;
		const formFieldsData = formField.map((item) => {
			item.field = item.field.map((fieldItem) => {
				if (fieldItem.id === 'paymentMethodId') {
					fieldItem.menu = allPaymentMethods
						?.filter((method) => method.name === 'Direct Debit' || method.name === 'Cheque')
						?.map(({ id, name }) => {
							return {
								id,
								name,
							};
						});
				}
				return fieldItem;
			});
			return item;
		});
		setFormField(formFieldsData);
	}, [allPM?.data]);

	useEffect(() => {
		if (!paymentFrequency?.data?.length) return;
		const formFieldsData = formField.map((item) => {
			item.field = item.field.map((fieldItem) => {
				if (fieldItem.id === 'paymentFrequencyId') {
					fieldItem.menu = paymentFrequency?.data?.map(({ id, frequency }) => {
						return {
							id,
							name: frequency,
						};
					});
				}
				return fieldItem;
			});
			return item;
		});
		setFormField(formFieldsData);
	}, [paymentFrequency?.data]);

	useEffect(() => {
		if (!annuitantGender?.data?.length) return;
		const formFieldsData = formField.map((item) => {
			item.field = item.field.map((fieldItem) => {
				if (fieldItem.id === 'genderId' || fieldItem.id === 'coGenderId') {
					fieldItem.menu = annuitantGender?.data?.map(({ id, name }) => {
						return {
							id,
							name,
						};
					});
				}
				return fieldItem;
			});
			return item;
		});
		setFormField(formFieldsData);
	}, [annuitantGender?.data]);

	useEffect(() => {
		if (!maritalStatus?.data?.length) return;
		const formFieldsData = formField.map((item) => {
			item.field = item.field.map((fieldItem) => {
				if (fieldItem.id === 'maritalStatusId' || fieldItem.id === 'coMaritalStatusId') {
					fieldItem.menu = maritalStatus?.data?.map(({ id, status }) => {
						return {
							id,
							name: status,
						};
					});
				}
				return fieldItem;
			});
			return item;
		});
		setFormField(formFieldsData);
	}, [maritalStatus?.data]);

	const handleUnsetError = (name: string) => {
		setErrors(errors.filter((item) => item.name !== name));
	};

	const handleMenuChange = (title: string, fieldId: string, donorData: any) => {
		const formData = [...formField];
		const formNewData = formData.map((item) => {
			if (item.title === title) {
				item.field = item.field?.map((field) => {
					if (field.id === fieldId) {
						field.menu = donorData?.map((donor: any) => {
							return {
								id: parseInt(donor.id),
								name: `${donor.firstName} ${donor.lastName}`,
								altName: `${donor.firstName} ${donor.lastName}`,
							};
						});
					}
					return field;
				});
				return item;
			}
			return item;
		});
		setFormField(formNewData);
	};

	const handleChange = (name: string, value: string | number) => {
		if (name === 'agreementTypeId') {
			const typeName = agreementType?.data?.find((item) => item.id === value)?.type;
			const formData = [...formField];
			const formNewData = formData.map((item) => {
				if (item?.id === 'coAnnuitant') {
					item.hide = typeName !== 'Joint';
				}
				return item;
			});
			setFormField(formNewData);
			setValues({
				...values,
				[name]: value as number,
				agreementTypeName: typeName,
			});
		} else {
			setValues({
				...values,
				[name]: value,
			});
		}
		handleUnsetError(name);
	};

	const handleAutocompleteChange = (name: string, value: any) => {
		if (!value) return;

		if (name === 'donor' || name === 'coDonor') {
			const donor =
				name === 'donor'
					? searchDonors?.data?.find((item) => parseInt(item.id) === value.id)
					: searchCoDonors?.data?.find((item) => parseInt(item.id) === value.id);
			setValues((prev) => {
				return Object.assign(
					{
						...prev,
						[name]: {
							id: value.id,
							name: value.label,
						},
					},
					name === 'donor'
						? {
								sin: donor?.annuitant?.sin ?? '',
								donorId: donor?.id,
								salutation: donor?.salutation,
								genderId: donor?.annuitant?.gender?.id ?? undefined,
								maritalStatusId: donor?.annuitant?.maritalStatus?.id ?? undefined,
								dateOfBirth: donor?.annuitant?.dateOfBirth ?? '',
								dateOfDeath: donor?.annuitant?.dateOfDeath ?? '',
								streetline1: donor?.streetline1,
								streetline2: donor?.streetline2,
								city: donor?.city !== 'N/A' ? donor?.city : '',
								province: donor?.province?.id,
								postalCode: donor?.postalCode !== 'N/A' ? donor?.postalCode : '',
								country: donor?.country !== 'N/A' ? donor?.country : '',
						  }
						: {
								coSin: donor?.annuitant?.sin ?? '',
								coDonorId: donor?.id,
								coSalutation: donor?.salutation,
								coGenderId: donor?.annuitant?.gender?.id ?? undefined,
								coMaritalStatusId: donor?.annuitant?.maritalStatus?.id ?? undefined,
								coDateOfBirth: donor?.annuitant?.dateOfBirth ?? '',
								coDateOfDeath: donor?.annuitant?.dateOfDeath ?? '',
						  },
				);
			});
			disabledAnnuitantFields(donor?.annuitant ? true : false, name);
		} else {
			setValues({
				...values,
				[name]: {
					id: value.id,
					name: value.label,
				},
			});
		}

		handleUnsetError(name);
	};

	const handleAutocompleteSearchChange = (name: string, value: string) => {
		if (name === 'donor') setDonorSearchTerm(value);
		else setCoDonorSearchTerm(value);
	};

	const handleSearch = (name: string) => {
		handleVisibleSearch(name);
	};

	const handleVisibleSearch = (type?: string) => {
		setVisibleSearch({ open: !visibleSearch.open, type: type ?? '' });
	};

	const handleAddDonor = (donor: any) => {
		setValues((prev) => {
			return Object.assign(
				{
					...prev,
					[visibleSearch.type]: {
						id: donor.id,
						name: donor.firstname,
						altName: donor.firstname,
					},
				},
				visibleSearch.type === 'donor'
					? {
							sin: donor?.annuitant?.sin ?? '',
							donorId: donor?.id,
							salutation: donor?.salutation,
							genderId: donor?.annuitant?.gender?.id ?? undefined,
							maritalStatusId: donor?.annuitant?.maritalStatus?.id ?? undefined,
							dateOfBirth: donor?.dateOfBirth ?? '',
							dateOfDeath: donor?.annuitant?.dateOfDeath ?? '',
							streetline1: donor.streetline1,
							streetline2: donor.streetline2,
							city: donor.city !== 'N/A' ? donor.city : '',
							province: donor?.province,
							postalCode: donor?.postalcode !== 'N/A' ? donor?.postalcode : '',
							country: donor.country !== 'N/A' ? donor.country : '',
					  }
					: {
							coSin: donor?.annuitant?.sin ?? '',
							coDonorId: donor?.id,
							coSalutation: donor?.salutation,
							coGenderId: donor?.annuitant?.gender?.id ?? undefined,
							coMaritalStatusId: donor?.annuitant?.maritalStatus?.id ?? undefined,
							coDateOfBirth: donor?.annuitant?.dateOfBirth ?? '',
							coDateOfDeath: donor?.annuitant?.dateOfDeath ?? '',
					  },
			);
		});

		disabledAnnuitantFields(donor.annuitant ? true : false, visibleSearch.type);

		handleUnsetError(visibleSearch.type);
		handleVisibleSearch();
	};

	const disabledAnnuitantFields = (isDisabled: boolean, type: string) => {
		const formData = [...formField];
		const formNewData = formData.map((item) => {
			item.field = item.field.map((field) => {
				if (type === 'donor' && ['sin', 'genderId', 'maritalStatusId', 'dateOfBirth'].includes(field.id)) {
					field.disabled = isDisabled;
				} else if (
					type === 'coDonor' &&
					['coSin', 'coGenderId', 'coMaritalStatusId', 'coDateOfBirth'].includes(field.id)
				) {
					field.disabled = isDisabled;
				}
				return field;
			});
			return item;
		});
		setFormField(formNewData);
	};
	useEffect(() => {
		const formData = [...formField];
		const formNewData = formData.map((item) => {
			item.field = item.field.map((field) => {
				if (field.id === 'donor') {
					field.isDonorAutocompleteSearch = searchDonors.loading;
				} else {
					if (field.id === 'coDonor') {
						field.isCoAutocompleteSearch = searchCoDonors.loading;
					}
				}
				return field;
			});
			return item;
		});
		setFormField(formNewData);
	}, [searchCoDonors.loading, searchDonors.loading]);

	return (
		<Paper className={classes.paperWrap}>
			<FormFieldLayout
				fields={formField}
				values={values}
				handleChange={handleChange}
				handleAutocompleteSearchChange={handleAutocompleteSearchChange}
				handleAutocompleteChange={handleAutocompleteChange}
				handleSearch={handleSearch}
				errors={errors}
			/>
			{visibleSearch.open && (
				<DonorSearch
					handleVisibleSearch={handleVisibleSearch}
					visibleSearch={visibleSearch.open}
					onRowClick={handleAddDonor}
				/>
			)}
		</Paper>
	);
};

export default CreateAnnuities;
