export enum Routes {
	Welcome = '/',
	Home = '/home',
	Donors = '/donors',
	DonorsProfile = '/donors/:id',
	DonorCreate = '/donors/create',
	MergeDonor = '/donors/merge/:id',
	EditDeposit = 'deposits/:id',
	Donations = '/donations',
	EditDonations = '/donations/:id',
	Deposits = 'deposits',
	AddDeposit = 'deposits/create-deposit',
	Batch = 'batch',
	CreateDonation = '/donations/create-donation',
	EditBatch = 'batch/:id',
	NewBatch = 'batch/new-batch',
	TemplateDeposits = 'template/deposits',
	AdminPanel = '/admin',
	Reports = '/reports',
	Walkathon = '/hca/walkathon',
	HCA = '/hca',
	Bookkeping = '/bookkeeping',
	MissionSunday = 'missionsunday',
	MissionSundayMaterial = 'missionsunday-material',
	MissionSundayOrder = 'missionsunday-order',
	Annuities = '/annuities',
}
