import React, { useEffect, useState } from 'react';
import BatchTable from './BatchTable';
import { Box, Tab, Stack, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel, LoadingButton } from '@mui/lab';
import { Add } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import useBatchAction from 'hooks/useBatchActions';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { batchesTableHead, ColumnType } from 'Components/Common/Tables/TableHeaders';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import { FilterPayload } from 'contexts/intialstates/Types';
import useUserActions from 'hooks/useUserActions';

interface TabContentProp {
	type: string;
}
const TabContent = ({ type }: TabContentProp) => {
	const [page, setPage] = React.useState(0);
	const { handleRoles } = useUserActions();
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);
	const navigate = useNavigate();
	const { createPapBank } = useBatchAction();
	const [reload, setReload] = useState(false);
	const [loading, setLoading] = useState(false);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);

	const handleAdd = async () => {
		if (type === 'cheque') {
			navigate('/bookkeeping/batch/new-batch');
		} else {
			setLoading(true);
			const isSucess = await createPapBank();
			setLoading(false);
			isSucess && setReload(!reload);
		}
	};

	const getTitle = () => {
		return type === 'cheque' ? 'One Time' : type === 'papcard' ? 'PAP Credit Card' : 'PAP Bank';
	};

	const getDescription = () => {
		return type === 'cheque'
			? 'Import and manage one-time batch donations here.'
			: type === 'papcard'
			? 'Record and manage PAP Credit Card donations here.'
			: 'Record and manage PAP Bank donations here.';
	};

	return (
		<>
			<Stack
				alignItems="center"
				direction={{ xs: 'column', sm: 'row' }}
				justifyContent={'space-between'}
				mb={3}
			>
				<Stack direction={'column'}>
					<Typography variant="h6" sx={{ color: 'var(--mainColor)' }}>
						{getTitle()}
					</Typography>
					<Stack sx={{ color: 'var(--grayText)', fontSize: 14, wordWrap: 'wrap' }}>{getDescription()}</Stack>
				</Stack>
				<Stack direction="row" alignItems={'center'} justifyContent="flex-end">
					<TrimTables
						name="batchesTableHead"
						list={batchesTableHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
					{handleRoles('bookkeeping-actions') && type !== 'papcard' && (
						<LoadingButton
							loading={loading}
							loadingPosition="start"
							sx={{ textTransform: 'none' }}
							onClick={handleAdd}
							variant="contained"
							startIcon={<Add />}
						>
							{type === 'cheque' ? 'Add New Batch' : 'Add New PAP Bank'}
						</LoadingButton>
					)}
					<FilterListButton
						setPage={setPage}
						page={page}
						setIsReload={setReload}
						reload={reload}
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
					/>
				</Stack>
			</Stack>

			<BatchTable
				setFilterPayload={setFilterPayload}
				filterPayload={filterPayload}
				type={type}
				reload={reload}
				trimList={trimList}
				setReload={setReload}
				setTrimList={setTrimList}
				setPage={setPage}
				page={page}
			/>
		</>
	);
};

const ListOfBatches = () => {
	const location = useLocation();
	const querySearch = new URLSearchParams(location.search);
	const type = querySearch.get('tab');
	const [tabValue, setTabValue] = React.useState('cheque');
	const navigate = useNavigate();

	useEffect(() => {
		if (type) {
			setTabValue(type);
			navigate(`/bookkeeping/batch?tab=${type}`);
			return;
		}
		setTabValue('creditcard');
		navigate(`/bookkeeping/batch?tab=cheque`);
	}, [type]);

	const handleChange = (event: any, value: string) => {
		setTabValue(value);
		navigate(`/bookkeeping/batch?tab=${value}`);
	};

	return (
		<Box sx={{ width: '100%', typography: 'body1' }}>
			<TabContext value={tabValue}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleChange}>
						<Tab label="One-Time Donation" value="cheque" />
						<Tab label="PAP Credit Card" value="papcard" />
						<Tab label="PAP Bank" value="papbank" />
					</TabList>
				</Box>
				<TabPanel value="cheque">
					<TabContent type={tabValue} />
				</TabPanel>
				<TabPanel value="papcard">
					<TabContent type={tabValue} />
				</TabPanel>
				<TabPanel value="papbank">
					<TabContent type={tabValue} />
				</TabPanel>
			</TabContext>
		</Box>
	);
};

export default ListOfBatches;
