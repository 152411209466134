import React, { useState } from 'react';
import { Stack, Typography, Paper, Grid, Box, TextField } from '@mui/material';
import { ArrowBackIosNew } from '@mui/icons-material';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import AnnuitiesTable from './List';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import useAnnuitiesActions, { IPostingLog } from 'hooks/useAnnuitiesActions';
import { StateType } from 'contexts/intialstates/Types';
import { currentDate } from 'helpers/dayAndTime';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useStyles } from '../Annuities.style';

export const postingLogDetailsHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'agreementNumber',
		label: 'Agreement',
		minWidth: 170,
		align: 'center',
	},
	{
		id: 'agreementType',
		label: 'Type',
		minWidth: 200,
		align: 'center',
	},
	{
		id: 'status',
		label: 'Status',
		minWidth: 200,
		align: 'center',
	},
	{
		id: 'annuitant',
		label: 'Annuitant',
		minWidth: 220,
		align: 'center',
	},
	{
		id: 'residualBeginning',
		label: 'Residual Beginning',
		minWidth: 170,
		align: 'center',
	},
	{
		id: 'newGift',
		label: 'New Gift',
		minWidth: 170,
		align: 'center',
	},
	{
		id: 'annuityPayment',
		label: 'Annuity Payment',
		minWidth: 170,
		align: 'center',
	},
];

const PostingLog = () => {
	const { id } = useParams();
	const location = useLocation();
	const isMonthly = location.pathname.includes('monthly-posting');
	const classes = useStyles();
	const navigate = useNavigate();
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [postingLogs, setPostingLogs] = useState<StateType<IPostingLog>>({
		loading: false,
		error: null,
		data: null,
	});
	const { annuitiesPosting } = useAnnuitiesActions();
	const [reload, setReload] = useState(false);

	const handlePosting = async () => {
		setLoading(true);
		const response = await annuitiesPosting('annual');
		if (response?.postingLog.id) {
			navigate(response?.postingLog.id);
		}
		setLoading(false);
		setOpen(false);
	};
	return (
		<Stack>
			<Stack direction={'row'} justifyContent={'space-between'} mb={3}>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={4}
					marginBottom={2}
				>
					<Stack className={classes.backIconWrapper}>
						<ArrowBackIosNew onClick={() => navigate('../')} />
					</Stack>
					<Stack alignItems={'flex-start'}>
						<Typography variant="h5" sx={{ color: '#1976D2' }}>
							{isMonthly ? 'Monthly' : 'Annual'} Posting
						</Typography>
					</Stack>
				</Stack>
				<Stack direction={'row'} alignItems={'center'}>
					<TrimTables
						name={'postingLogDetailsHead'}
						list={postingLogDetailsHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
				</Stack>
			</Stack>
			<Paper sx={{ px: 3, mb: 3 }}>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
					<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
						<Stack direction="column" spacing={2}>
							<Box>Posting #</Box>
							<Box>
								<TextField fullWidth size="small" disabled value={postingLogs.data?.postingNumber || null} />
							</Box>
						</Stack>
					</Grid>
					<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
						<Stack direction="column" spacing={2}>
							<Box>Posting Period</Box>
							<Box>
								<BasicDatePicker size="small" value={postingLogs.data?.postingPeriod || null} disabled />
							</Box>
						</Stack>
					</Grid>
					<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
						<Stack direction="column" spacing={2}>
							<Box>Posting Date</Box>
							<Box>
								<BasicDatePicker size="small" value={postingLogs.data?.postingDate || null} disabled />
							</Box>
						</Stack>
					</Grid>
				</Grid>
			</Paper>
			<AnnuitiesTable
				trimList={trimList}
				setTrimList={setTrimList}
				reload={reload}
				setReload={setReload}
				id={id as string}
				postingLogs={postingLogs}
				setPostingLogs={setPostingLogs}
			/>
			<ConfirmationModal
				handleClose={() => setOpen(false)}
				message={`Are you sure you want to create a new posting for ${currentDate()}?`}
				open={open}
				handleConfirmation={handlePosting}
				handleCancel={() => setOpen(false)}
				loading={loading}
				title="Add New Posting"
			/>
		</Stack>
	);
};

export default PostingLog;
