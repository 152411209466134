import React, { useRef } from 'react';
import {
	Stack,
	Box,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import Report from '../Report';
import { convertDate } from 'helpers/dayAndTime';
import { style, useStyles } from 'Components/Reports/DonorReports/DonorReport.style';
import FormatCurrency, { formatCurrencyFunc } from 'helpers/FormatCurrency';
import { useReactToPrint } from 'react-to-print';
import Logo from '../../../assets/images/logo.png';
import { currentDate } from 'helpers/dayAndTime';
import { FormValues } from './DonorReportForm';
import useReportActions from 'hooks/useReportAction';
import moment from 'moment';

export interface IDonorReportData {
	donorId: string;
	donorName: string;
	totalDonationAmount: number;
	donations: {
		dateCredited: string;
		receiptNo: number;
		society: string;
		donationType: string;
		donationAmount: number;
		bankAccount: string;
		depositId: number;
	}[];
}

interface P {
	donorReportData: IDonorReportData[];
	formData: FormValues;
}

interface DerivedDonorReportData {
	donorId: string;
	donorName: string;
	totalDonationAmount: number;
	deposits: {
		dateCredited: string;
		receiptNo: number;
		society: string;
		donationType: string;
		donationAmount: number;
		bankAccount: string;
		depositId: number;
	}[][];
}

const DetailedReportView = ({ donorReportData, formData }: P) => {
	const classes = useStyles();
	const componentRef = useRef(null);
	const { exportCSV, getDonorReportPDF } = useReportActions();

	const getTotal = () => {
		return donorReportData?.map((item) => item.totalDonationAmount).reduce((prev, curr) => prev + curr, 0);
	};

	const groupBy = (array: any, key: any) => {
		return array?.reduce((result: any, currentValue: any) => {
			(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
			return result;
		}, {});
	};

	const splitter = () => {
		if (!donorReportData?.length) return;
		const detailedData: any = [];
		const finalData = [];

		donorReportData?.map((detail, index) => {
			const data = groupBy(detail?.donations, 'depositId');
			const depositKeys = Object?.keys(data);
			const deposits = depositKeys?.map((key) => data[key]);

			const newDetail: DerivedDonorReportData = {
				donorId: detail?.donorId,
				donorName: detail?.donorName,
				totalDonationAmount: detail?.totalDonationAmount,
				deposits,
			};

			const initialDonationDetail = newDetail?.deposits?.[0];
			const initialTotalDonations = newDetail?.deposits?.[0]?.reduce(
				(total, curr) => total + curr?.donationAmount,
				0,
			);
			const depositDetail = newDetail?.deposits;

			detailedData.push({
				donorId: newDetail?.donorId || '-',
				donor: newDetail?.donorName || '-',
				dateCreated: initialDonationDetail?.[0]?.dateCredited
					? convertDate(initialDonationDetail?.[0]?.dateCredited)
					: '-',
				receiptId: initialDonationDetail?.[0]?.receiptNo || '-',
				society: initialDonationDetail?.[0]?.society || '-',
				donationType: initialDonationDetail?.[0]?.donationType
					? formatCurrencyFunc(initialDonationDetail?.[0]?.donationType)
					: '-',
				donationAmount: initialDonationDetail?.[0]?.donationAmount
					? formatCurrencyFunc(initialDonationDetail?.[0]?.donationAmount)
					: '-',
			});

			if (initialDonationDetail?.length > 1) {
				initialDonationDetail.slice(1, initialDonationDetail?.length).map((item) => {
					detailedData.push({
						donorId: '',
						donor: '',
						dateCreated: item?.dateCredited ? convertDate(item?.dateCredited) : '-',
						receiptId: item?.receiptNo || '-',
						society: item?.society || '-',
						donationType: item?.donationType ? formatCurrencyFunc(item?.donationType) : '-',
						donationAmount: item?.donationAmount ? formatCurrencyFunc(item?.donationAmount) : '-',
					});
				});
			}

			detailedData.push({
				totalDonationAmount: initialTotalDonations ? formatCurrencyFunc(initialTotalDonations) : '-',
				bankAccount: initialDonationDetail?.[0]?.bankAccount,
				donationDeposited: `DEPOSIT TOTAL`,
			});

			if (depositDetail?.length > 1) {
				depositDetail.slice(1, depositDetail?.length).map((item) => {
					const subsequentDonationsTotal = item?.reduce((total, curr) => total + curr?.donationAmount, 0);
					const subsequentDonationDetail = item;
					detailedData.push({
						donorId: '',
						donor: '',
						dateCreated: subsequentDonationDetail?.[0]?.dateCredited
							? convertDate(subsequentDonationDetail?.[0]?.dateCredited)
							: '-',
						receiptId: subsequentDonationDetail?.[0]?.receiptNo || '-',
						society: subsequentDonationDetail?.[0]?.society || '-',
						donationType: subsequentDonationDetail?.[0]?.donationType
							? formatCurrencyFunc(subsequentDonationDetail?.[0]?.donationType)
							: '-',
						donationAmount: subsequentDonationDetail?.[0]?.donationAmount
							? formatCurrencyFunc(subsequentDonationDetail?.[0]?.donationAmount)
							: '-',
					});

					if (subsequentDonationDetail?.length > 1) {
						subsequentDonationDetail.slice(1, subsequentDonationDetail?.length).map((item) => {
							detailedData.push({
								donorId: '',
								donor: '',
								dateCreated: item?.dateCredited ? convertDate(item?.dateCredited) : '-',
								receiptId: item?.receiptNo || '-',
								society: item?.society || '-',
								donationType: item?.donationType ? formatCurrencyFunc(item?.donationType) : '-',
								donationAmount: item?.donationAmount ? formatCurrencyFunc(item?.donationAmount) : '-',
							});
						});
					}

					detailedData.push({
						totalDonationAmount: subsequentDonationsTotal
							? formatCurrencyFunc(subsequentDonationsTotal)
							: '-',
						bankAccount: subsequentDonationDetail?.[0]?.bankAccount,
						donationDeposited: `DEPOSIT TOTAL`,
					});
				});
			}

			const allDepositTotal = newDetail?.deposits?.reduce(
				(total, curr) =>
					total + curr?.reduce((grandTotal, currDonation) => grandTotal + currDonation.donationAmount, 0),
				0,
			);

			detailedData.push({
				totalDepositAmount: allDepositTotal ? formatCurrencyFunc(allDepositTotal) : '-',
				deposited: ` TOTAL`,
			});
		});
		for (let i = 0; i < detailedData.length; i += 15) {
			finalData.push(detailedData.slice(i, i + 15));
		}
		return finalData;
	};

	const handleExport = async (isPdf?: boolean) => {
		const payload = {
			start: moment(formData?.start).format('YYYY-MM-DD'),
			end: moment(formData?.end).format('YYYY-MM-DD'),
			minAmount: formData.minAmount ?? '',
			maxAmount: formData.maxAmount ?? '',
			receiptFrequency: formData.receiptFrequency.join(','),
			donationType: formData.donationType?.map((item: any) => item?.id).join(','),
			society: formData.society.join(','),
			paymentMethod: formData.paymentMethod.join(','),
			donors: formData.donors?.map((item) => item.id).join(','),
			noMail: formData?.communication?.includes('noMail'),
			excludeFromParishList: formData?.communication?.includes('excludeFromParishList'),
			returnMail: formData?.communication?.includes('returnMail'),
			deceased: formData?.communication?.includes('deceased'),
			isCommunicationNone: formData?.communication?.includes('isCommunicationNone'),
			magazine: formData?.magazine as boolean,
			faithPropagation: formData?.preferredSociety?.includes('faithPropagation'),
			stPeter: formData?.preferredSociety?.includes('stPeter'),
			holyChildhood: formData?.preferredSociety?.includes('holyChildhood'),
			noFollowAppeal: formData?.noFollowAppeal as boolean,
			isPrefSocietyNone: formData?.preferredSociety?.includes('isPrefSocietyNone'),
			isdetailed: formData?.isdetailed,
		};
		if (isPdf === true) {
			await getDonorReportPDF(payload);
		} else {
			await exportCSV(payload);
		}
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const printData = () => {
		const splitData = splitter();
		return (
			<TableContainer>
				{splitData?.map((dataSplit, index) => {
					return (
						<div className={classes.pageBreak} key={index}>
							<Table sx={{ minWidth: 700 }} key={index} aria-label="spanning table">
								<TableHead className={classes.tableHead}>
									<TableRow>
										<TableCell align="center">Donor #</TableCell>
										<TableCell align="center">Donor</TableCell>
										<TableCell align="center">Date Credited</TableCell>
										<TableCell align="center">Receipt #</TableCell>
										<TableCell align="center">Society</TableCell>
										<TableCell align="center">Donation Type</TableCell>
										<TableCell align="center">Donation Amount</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{dataSplit.map((row: any, index: number) => (
										<>
											{'totalDonationAmount' in row ? (
												<>
													<TableRow key={index} className={classes.tableRowWrap}>
														<TableCell
															align="right"
															colSpan={5}
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
															}}
														>
															{row.donationDeposited}
														</TableCell>

														<TableCell
															align="center"
															colSpan={1}
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
															}}
														>
															{row.bankAccount ? <span>({row.bankAccount})</span> : '-'}
														</TableCell>

														<TableCell
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
															}}
														>
															{row.totalDonationAmount}
														</TableCell>
													</TableRow>
													<TableRow key={index} className={classes.tableRowWrap}>
														<TableCell
															colSpan={10}
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
																borderTop: '1px solid #ABAEB0',
															}}
														>
															{/* {row.totalDepositAmount} */}
														</TableCell>
													</TableRow>
												</>
											) : 'totalDepositAmount' in row ? (
												<>
													<TableRow key={index} className={classes.tableRowWrap}>
														<TableCell
															colSpan={10}
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
															}}
														>
															{/* {row.totalDepositAmount} */}
														</TableCell>
													</TableRow>
													<TableRow
														key={index}
														sx={{ bgcolor: '#F9FCFF', py: 10 }}
														className={classes.tableRowWrap}
													>
														<TableCell
															colSpan={6}
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
																textAlign: 'center',
																textIndent: 150,
																py: 10,
															}}
														></TableCell>
														<TableCell
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
																py: 10,
															}}
														></TableCell>
													</TableRow>
													<TableRow
														key={index}
														sx={{ bgcolor: '#F9FCFF', py: 10 }}
														className={classes.tableRowWrap}
													>
														<TableCell
															colSpan={6}
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
																textAlign: 'center',
																textIndent: 150,
																py: 10,
															}}
														>
															{row.deposited}
														</TableCell>
														<TableCell
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
																py: 10,
															}}
														>
															{row.totalDepositAmount}
														</TableCell>
													</TableRow>
													<TableRow
														key={index}
														sx={{ bgcolor: '#F9FCFF', py: 10 }}
														className={classes.tableRowWrap}
													>
														<TableCell
															colSpan={6}
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
																textAlign: 'center',
																textIndent: 150,
																py: 10,
															}}
														></TableCell>
														<TableCell
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
																py: 10,
															}}
														></TableCell>
													</TableRow>
													<TableRow key={index} className={classes.tableRowWrap}>
														<TableCell
															colSpan={10}
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
																// borderTop: '1px solid #445f6f',
															}}
														>
															{/* {row.totalDepositAmount} */}
														</TableCell>
													</TableRow>
												</>
											) : (
												<TableRow key={index} className={classes.tableRowWrap}>
													<TableCell align="center">{row.donorId}</TableCell>
													<TableCell align="center">{row.donor}</TableCell>
													<TableCell align="center">{row.dateCreated}</TableCell>
													<TableCell align="center">{row.receiptId}</TableCell>
													<TableCell align="center">{row.society}</TableCell>
													<TableCell align="center">{row.donationType}</TableCell>
													<TableCell align="center">{row.donationAmount}</TableCell>
												</TableRow>
											)}
										</>
									))}
									{index === splitData.length - 1 && (
										<TableRow className={classes.tableTotal}>
											<TableCell align="center" colSpan={6}>
												<Stack
													py={1}
													sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
												>
													<Stack
														direction="row"
														justifyContent={'center'}
														sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
													>
														Grand Total
													</Stack>
												</Stack>
											</TableCell>
											<TableCell colSpan={1} align="center">
												<Stack
													py={1}
													sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
												>
													<Stack
														direction="row"
														justifyContent={'center'}
														sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
													>
														<FormatCurrency value={getTotal()} />
													</Stack>
												</Stack>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</div>
					);
				})}
			</TableContainer>
		);
	};

	return (
		<div>
			<Report
				title="Donor Detailed Report"
				subTitle={`From ${convertDate(formData.start)} To ${convertDate(formData.end)}`}
				handlePrint={() => handleExport(true)}
				handleExport={handleExport}
			>
				<Box sx={style.wrapper}>
					<TableContainer>
						<Table sx={{ minWidth: 700 }} aria-label="spanning table">
							<TableHead className={classes.tableHead}>
								<TableRow>
									<TableCell align="center">Donor #</TableCell>
									<TableCell align="center">Donor</TableCell>
									<TableCell align="center">Date Credited</TableCell>
									<TableCell align="center">Receipt #</TableCell>
									<TableCell align="center">Society</TableCell>
									<TableCell align="center">Donation Type</TableCell>
									<TableCell align="center">Donation Amount</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{splitter()
									?.flatMap((item: any) => item)
									.map((row: any, index: number) => (
										<>
											{'totalDonationAmount' in row ? (
												<>
													<TableRow key={index} className={classes.tableRowWrap}>
														<TableCell
															align="right"
															colSpan={5}
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
															}}
														>
															{row.donationDeposited}
														</TableCell>

														<TableCell
															align="center"
															colSpan={1}
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
															}}
														>
															{row.bankAccount ? <span>({row.bankAccount})</span> : '-'}
														</TableCell>

														<TableCell
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
															}}
														>
															{row.totalDonationAmount}
														</TableCell>
													</TableRow>
													<TableRow key={index} className={classes.tableRowWrap}>
														<TableCell
															colSpan={10}
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
																borderTop: '1px solid #ABAEB0',
															}}
														>
															{/* {row.totalDepositAmount} */}
														</TableCell>
													</TableRow>
												</>
											) : 'totalDepositAmount' in row ? (
												<>
													<TableRow key={index} className={classes.tableRowWrap}>
														<TableCell
															colSpan={10}
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
															}}
														>
															{/* {row.totalDepositAmount} */}
														</TableCell>
													</TableRow>
													<TableRow
														key={index}
														sx={{ bgcolor: '#F9FCFF', py: 10 }}
														className={classes.tableRowWrap}
													>
														<TableCell
															colSpan={6}
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
																textAlign: 'center',
																textIndent: 150,
																py: 10,
															}}
														></TableCell>
														<TableCell
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
																py: 10,
															}}
														></TableCell>
													</TableRow>
													<TableRow
														key={index}
														sx={{ bgcolor: '#F9FCFF', py: 10 }}
														className={classes.tableRowWrap}
													>
														<TableCell
															colSpan={6}
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
																textAlign: 'center',
																textIndent: 150,
																py: 10,
															}}
														>
															{row.deposited}
														</TableCell>
														<TableCell
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
																py: 10,
															}}
														>
															{row.totalDepositAmount}
														</TableCell>
													</TableRow>
													<TableRow
														key={index}
														sx={{ bgcolor: '#F9FCFF', py: 10 }}
														className={classes.tableRowWrap}
													>
														<TableCell
															colSpan={6}
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
																textAlign: 'center',
																textIndent: 150,
																py: 10,
															}}
														></TableCell>
														<TableCell
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
																py: 10,
															}}
														></TableCell>
													</TableRow>
													<TableRow key={index} className={classes.tableRowWrap}>
														<TableCell
															colSpan={10}
															align="center"
															sx={{
																color: '#445f6f',
																fontSize: 18,
																textTransform: 'capitalize',
																fontWeight: 600,
																// borderTop: '1px solid #445f6f',
															}}
														>
															{/* {row.totalDepositAmount} */}
														</TableCell>
													</TableRow>
												</>
											) : (
												<TableRow key={index} className={classes.tableRowWrap}>
													<TableCell align="center">{row.donorId}</TableCell>
													<TableCell align="center">{row.donor}</TableCell>
													<TableCell align="center">{row.dateCreated}</TableCell>
													<TableCell align="center">{row.receiptId}</TableCell>
													<TableCell align="center">{row.society}</TableCell>
													<TableCell align="center">{row.donationType}</TableCell>
													<TableCell align="center">{row.donationAmount}</TableCell>
												</TableRow>
											)}
										</>
									))}
								<TableRow className={classes.tableTotal}>
									<TableCell align="center" colSpan={6}>
										<Stack py={1} sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}>
											<Stack
												direction="row"
												justifyContent={'center'}
												sx={{ borderBottom: '1px solid #ABAEB0', width: '100%', textIndent: 190 }}
											>
												Grand Total
											</Stack>
										</Stack>
									</TableCell>
									<TableCell colSpan={1} align="center">
										<Stack py={1} sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}>
											<Stack
												direction="row"
												justifyContent={'center'}
												sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
											>
												<FormatCurrency value={getTotal()} />
											</Stack>
										</Stack>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Report>
			<div style={{ display: 'none' }}>
				<div style={{ padding: 16 }} ref={componentRef}>
					<Stack sx={{ my: 2, px: 1 }}>
						<>
							<Stack width="100%" direction="row">
								<Stack flex={0.5} alignItems="flex-start" direction="column">
									<img src={Logo} alt="logo" width={40} height={50} />
								</Stack>

								<Stack sx={{ mb: 2, textAlign: 'left' }} alignItems="left" direction="column">
									<Typography sx={{ color: '#1976d2', fontSize: 20, fontWeight: 700 }}>
										Donor Detailed Report
									</Typography>
									<Typography sx={{ color: 'var(--fontColorHeaderSubTitle)', fontSize: 18, fontWeight: 400 }}>
										{`From ${convertDate(formData.start)} To ${convertDate(formData.end)}`}
									</Typography>
								</Stack>
							</Stack>
							<Stack
								direction={'row'}
								justifyContent="flex-end"
								mb={3}
								sx={{ color: 'var(--fontColorHeaderSubTitle)', fontSize: 16, fontWeight: 400 }}
							>
								{currentDate()}
							</Stack>
						</>

						<Box width="100%">{printData()}</Box>
					</Stack>
				</div>
			</div>
		</div>
	);
};

export default DetailedReportView;
