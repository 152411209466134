import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import { ListItemIcon, ListItemText } from '@mui/material';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import SideMenuItemComponent from './SideMenuItemComponent';
import { Box } from '@mui/system';
import { useStyles } from './SideMenu.style';
import { SideMenuItemPropTypes } from './Types';

// TypeScript compile-time props type, infered from propTypes
// https://dev.to/busypeoples/notes-on-typescript-inferring-react-proptypes-1g88
type AppMenuItemPropTypes = PropTypes.InferProps<typeof SideMenuItemPropTypes>;
type SideMenuItemPropsWithoutItems = Omit<AppMenuItemPropTypes, 'items'>;

// Improve child items declaration
export type SideMenuItemProps = SideMenuItemPropsWithoutItems & {
	items?: SideMenuItemProps[];
};

const SideMenuItem: React.FC<SideMenuItemProps> = (props) => {
	const { name, link, Icon, items = [] } = props;
	const classes = useStyles();
	const isExpandable = items && items.length > 0;
	const [open, setOpen] = React.useState(false);

	function handleClick() {
		setOpen(!open);
	}

	const MenuItemRoot = (
		<SideMenuItemComponent className={classes.menuItem} link={link} onClick={handleClick}>
			{/* Display an icon if any */}
			<Box className={classes.boxItemBG} sx={{ width: { xs: 30, sm: 40 }, height: { xs: 30, sm: 40 } }}>
				{!!Icon && (
					<ListItemIcon
						sx={{ color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
						className={classes.menuItemIcon}
					>
						<Icon />
					</ListItemIcon>
				)}
			</Box>
			<ListItemText
				primary={name}
				inset={!Icon}
				primaryTypographyProps={{ fontSize: { xs: 10, sm: 11, md: 12 }, color: '#1976D2' }}
			/>
			{/* Display the expand menu if the item has children */}
			{isExpandable && !open && <IconExpandMore />}
			{isExpandable && open && <IconExpandLess />}
		</SideMenuItemComponent>
	);

	const MenuItemChildren = isExpandable ? (
		<Collapse in={open} timeout="auto" unmountOnExit>
			<Divider />
			<List component="div" disablePadding>
				{items.map((item, index) => (
					<SideMenuItem {...item} key={index} />
				))}
			</List>
		</Collapse>
	) : null;

	return (
		<>
			{MenuItemRoot}
			{MenuItemChildren}
		</>
	);
};

export default SideMenuItem;
