import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
	Stack,
	Grid,
	Select,
	MenuItem,
	Typography,
	TextField,
	Button,
	Autocomplete,
	Box,
} from '@mui/material';
import { MiniCard } from '../Card';
import { useGlobalStyles } from 'Components/Common/global/global';
import useDonationTypesActions from 'hooks/useDonationTypesActions';
import { StateType, Donor, DonationTypes } from 'contexts/intialstates/Types';
import { Search, AddTask, Clear } from '@mui/icons-material';
import DonorSearch from '../DonorReports/DonorSearch';
import LoadingButton from '@mui/lab/LoadingButton';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import useBurseChaliceActions, { BurseChaliceStatus } from 'hooks/useBurseChaliceActions';
import ActionModal from 'Components/Reusable/Modals/ActionModal/ActionModal';
import useDebounce from 'Components/Reusable/hooks/debounce';

interface DonationType {
	id: number;
	label: string;
}

export interface FormValues {
	start: string;
	end: string;
	minAmount?: string;
	maxAmount?: string;
	donors: Donor[];
	donationType: DonationType[];
	status: string[];
	contributionType: string[];
}

interface P {
	handleGetReport: (values: FormValues, isGenerate?: boolean) => void;
	loading: boolean;
	onCancel: () => void;
	isEdit: boolean;
	isShowFilter: boolean;
	formData: FormValues;
	setFormData: Dispatch<SetStateAction<FormValues>>;
}

const BurseChaliceForm = ({
	handleGetReport,
	loading,
	onCancel,
	isEdit,
	isShowFilter,
	formData,
	setFormData,
}: P) => {
	const global = useGlobalStyles();
	const { getDonationTypeBySearch } = useDonationTypesActions();
	const { getBurseChaliceStatus } = useBurseChaliceActions();
	const [openRequiredModal, setOpenRequiredModal] = useState(false);
	const [visibleSearch, setVisibleSearch] = useState(false);

	const [burseChaliceStatus, setBurseChaliceStatus] = useState<StateType<BurseChaliceStatus[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [donationTypeSearchTerm, setDonationTypeSearchTerm] = useState('');
	const debouncedDonationSearchTerm = useDebounce(donationTypeSearchTerm, 1000);
	const [donationType, setDonationType] = useState<StateType<DonationTypes[]>>({
		data: null,
		loading: false,
		error: null,
	});

	useEffect(() => {
		getBurseChaliceStatus({ burseChaliceStatus, setBurseChaliceStatus });
	}, []);

	useEffect(() => {
		if (!debouncedDonationSearchTerm) return;
		getDonationTypeBySearch({ donationType, setDonationType, searchTerm: debouncedDonationSearchTerm });
	}, [debouncedDonationSearchTerm]);

	const handleVisibleSearch = () => {
		setVisibleSearch(!visibleSearch);
	};

	const handleAddDonor = (donors: Donor[]) => {
		setFormData({
			...formData,
			donors,
		});
		handleVisibleSearch();
	};

	const handleSelectChange = (field: string, value: string | string[]) => {
		setFormData({
			...formData,
			[field]: typeof value === 'string' ? value.split(',') : value,
		});
	};

	const handleDoanationSelectChange = (field: string, value: DonationType[]) => {
		setFormData({
			...formData,
			[field]: value,
		});
	};

	const handleChange = (field: string, value: string) => {
		setFormData({
			...formData,
			[field]: value,
		});
	};

	const handleGenerate = async (isGenerate?: boolean) => {
		if (!formData.start || !formData.end) {
			setOpenRequiredModal(true);
			return;
		}
		await handleGetReport(formData, isGenerate);
	};

	const handleCancel = () => {
		setFormData({
			start: '',
			end: '',
			minAmount: '',
			maxAmount: '',
			donors: [],
			donationType: [],
			status: [],
			contributionType: [],
		});
		onCancel();
	};

	return (
		<Stack>
			{isShowFilter && (
				<MiniCard title="Filters">
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
						<Grid item xs={12} md={4} sm={6} mb={3}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Burse/Chalice Created Date Range
							</Typography>
							<Stack direction="row" pr={{ lg: 5, md: 0 }}>
								<BasicDatePicker
									size="small"
									value={formData.start}
									setValue={(e) => handleChange('start', e.target.value)}
								/>
								<Stack m={1}>To</Stack>
								<BasicDatePicker
									size="small"
									value={formData.end}
									setValue={(e) => handleChange('end', e.target.value)}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Contribution Type
							</Typography>
							<Select
								size="small"
								fullWidth
								multiple
								onChange={(e) => {
									handleSelectChange('contributionType', e.target.value);
								}}
								value={formData.contributionType}
							>
								<MenuItem value={'Burse'}>Burse</MenuItem>
								<MenuItem value={'Chalice'}>Chalice</MenuItem>
							</Select>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Status
							</Typography>
							<Select
								size="small"
								fullWidth
								multiple
								onChange={(e) => {
									handleSelectChange('status', e.target.value);
								}}
								value={formData.status}
							>
								{burseChaliceStatus?.data?.map(({ id, status }) => (
									<MenuItem key={id} value={id}>
										{status}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Donation Type
							</Typography>
							<Autocomplete
								freeSolo
								value={formData.donationType}
								loading={donationType.loading}
								loadingText="loading..."
								onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
									handleDoanationSelectChange('donationType', val);
								}}
								fullWidth
								options={(donationType?.data || []).map((donation: DonationTypes) => ({
									id: donation.id,
									label: `${donation.type} - ${donation.description}`,
								}))}
								renderOption={(props, option) => (
									<Box component="li" {...props} key={option.id}>
										{option.label}
									</Box>
								)}
								multiple
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										label=""
										InputProps={{
											...params.InputProps,
										}}
										onChange={(event: any) => {
											setDonationTypeSearchTerm(event.target.value);
										}}
										size={'small'}
										placeholder={'Search donation type'}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Target Amount
							</Typography>
							<Stack direction="row" alignItems="center">
								<TextField
									size={'small'}
									fullWidth
									placeholder={'Min'}
									value={formData.minAmount}
									onChange={(e) => handleChange('minAmount', e.target.value)}
								/>
								<Stack sx={{ margin: 1 }}>-</Stack>
								<TextField
									size={'small'}
									fullWidth
									placeholder={'Max'}
									value={formData.maxAmount}
									onChange={(e) => handleChange('maxAmount', e.target.value)}
								/>
							</Stack>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Donor
							</Typography>
							<Stack direction="row" alignItems={'center'}>
								<TextField
									size={'small'}
									value={formData.donors
										.map((item) => {
											return `${item.firstName} ${item.lastName}`;
										})
										.join(', ')}
									placeholder={'Search donors'}
									disabled
									fullWidth
								/>
								<Stack>
									<Button
										onClick={() => handleVisibleSearch()}
										startIcon={<Search />}
										variant="outlined"
										size={'small'}
										className={global.whiteBtn}
										sx={{ margin: '5px' }}
									>
										Search
									</Button>
								</Stack>
							</Stack>
						</Grid>
						{isEdit && (
							<Grid item xs={12} md={4} sm={6} mb={3}>
								<Stack direction="row" spacing={2} height="100%" alignItems="flex-end" p={1}>
									<LoadingButton
										startIcon={<AddTask />}
										variant="contained"
										onClick={() => handleGenerate()}
										type="submit"
										loading={loading}
										loadingPosition="start"
										size="small"
									>
										Apply
									</LoadingButton>

									<Button
										startIcon={<Clear />}
										variant="outlined"
										className={global.whiteBtn}
										onClick={handleCancel}
										size="small"
									>
										Cancel
									</Button>
								</Stack>
							</Grid>
						)}
					</Grid>
				</MiniCard>
			)}
			{!isEdit && (
				<Stack m={3} direction="row">
					<LoadingButton
						startIcon={<AddTask />}
						variant="contained"
						onClick={() => handleGenerate(true)}
						loading={loading}
						loadingPosition="start"
						size="small"
					>
						Generate Report
					</LoadingButton>
				</Stack>
			)}
			{visibleSearch && (
				<DonorSearch
					handleVisibleSearch={handleVisibleSearch}
					visibleSearch={visibleSearch}
					handleAdd={handleAddDonor}
				/>
			)}
			{openRequiredModal && (
				<ActionModal
					open={openRequiredModal}
					title={'Required field'}
					subTitle={'Please fill Burse/Chalice created date range field'}
					closeText={'Close'}
					handleClose={() => setOpenRequiredModal(false)}
				/>
			)}
		</Stack>
	);
};

export default BurseChaliceForm;
