import React, { useEffect } from 'react';
import { TablePagination, Paper, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import { pagination } from 'Components/Common/Tables/Tables';
import useHcaOrdersActions from 'hooks/useHcaOrdersActions';
import { HcaOrdersData, OrderType, StateType } from 'contexts/intialstates/Types';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import StatusButton from '../../Common/Buttons/StatusButton';
import { convertDate } from 'helpers/dayAndTime';
import { GlobalContexts } from 'contexts/GlobalContext';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import { sortTable } from 'Components/Common/Tables/sortTable';

interface P {
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	reload: boolean;
	filterPayload: any[];
	setFilterPayload: React.Dispatch<React.SetStateAction<any[]>>;
	trimList: ColumnType[];
	setTrimList: React.Dispatch<React.SetStateAction<ColumnType[]>>;
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	category?: string;
	currentRoute?: string;
}

const OrderList = ({
	trimList,
	reload,
	setReload,
	filterPayload,
	setFilterPayload,
	setTrimList,
	category,
	currentRoute,
	page,
	setPage,
}: P) => {
	const { setRouteHistory } = React.useContext(GlobalContexts);
	const { getHcaOrders } = useHcaOrdersActions();
	const navigate = useNavigate();
	const [pageSize, setPageSize] = React.useState(10);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');

	const [hcaOrders, setHcaOrders] = React.useState<StateType<HcaOrdersData>>({
		data: null,
		error: null,
		loading: false,
	});

	useEffect(() => {
		if (category === 'nil') return;
		if (category) {
			getHcaOrders({ setHcaOrders, hcaOrders, pageSize, page, category, orderBy, filterPayload });
		} else {
			getHcaOrders({ setHcaOrders, hcaOrders, pageSize, page, orderBy, filterPayload });
		}
	}, [page, pageSize, reload, orderBy, category]);

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleEdit = (row: any) => {
		if (currentRoute === 'Board') {
			setRouteHistory('Board');
		} else {
			setRouteHistory('');
		}
		navigate(`/hca/create-edit-hca-orders/${row?.id}`);
	};

	const getTableData = () => {
		return hcaOrders?.data?.body?.length
			? hcaOrders?.data?.body.map((row: any, index: number) => {
					return {
						id: row?.id,
						orderId: row?.id || 'N/A',
						organizationType: row?.organizationType || 'N/A',
						organizationName: row?.organizationName || 'N/A',
						address: row?.address || 'N/A',
						receivedDate: `${row?.receivedDate ? convertDate(row?.receivedDate) : 'N/A'}`,
						status: row?.status ? <StatusButton status={`${row?.status}`} /> : 'N/A',
						created: `${row?.created ? convertDate(row?.created) : 'N/A'}`,
						createdBy: row?.createdBy?.name || 'N/A',
						updated: `${row?.updated ? convertDate(row?.updated) : 'N/A'}`,
						updatedBy: row?.updatedBy?.name || 'N/A',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType, index: any) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
							<span
								style={{ cursor: 'pointer' }}
								onClick={() =>
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									})
								}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<>
							<TableFiltering
								field={column.id}
								filterType={column.filterType}
								list={column.list}
								reload={reload}
								setIsReload={setReload}
								setPage={setPage}
								page={page}
								setFilterPayload={setFilterPayload}
								filterPayload={filterPayload}
							/>
						</>
					</>
				),
			};
		});
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		reOrderedData && setTrimList?.(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		resizedData && setTrimList?.(resizedData);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<CustomTable
				loading={hcaOrders?.loading}
				columnData={getColumnData()}
				tableData={getTableData()}
				handleDrag={handleDrag}
				handleResize={handleResize}
				emptyMessage={'No Orders available'}
				handleRowClick={(row) => handleEdit(row as any)}
			/>
			{(hcaOrders?.data?.body?.length as number) > 0 && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
					component="div"
					count={hcaOrders?.data?.totalRecords as number}
					rowsPerPage={pageSize}
					page={page}
					sx={pagination}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</Paper>
	);
};

export default OrderList;
