import React from 'react';
import { Deposits, DepositData, StateType, OrderType, FilterPayload } from 'contexts/intialstates/Types';
import DepositTable from './DepositTable/DepositTable';
import { Button, Skeleton, Stack, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import SingleCreditCardDeposit from './CreateDeposit/SingleCreditCardDeposit';
import GenerateReceipt from 'Components/Reusable/GenerateReceipt/GenerateReceipt';
import { GlobalContexts } from 'contexts/GlobalContext';
import useActions from '../../hooks/useDepositActions';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { ColumnType, DepositsTableHead } from 'Components/Common/Tables/TableHeaders';
import useUserActions from 'hooks/useUserActions';
import useDebounce from 'Components/Reusable/hooks/debounce';

export const ButtonSkeleton = ({ length }: { length: number }) => {
	return (
		<>
			<Stack direction="row">
				{[...Array.from({ length })].map((i, index) => (
					<Stack mr={index === 2 ? 0 : 2} key={index}>
						<Skeleton width={89} height={60} />
					</Stack>
				))}
			</Stack>
		</>
	);
};

export const Deposit = () => {
	const { handleRoles } = useUserActions();
	const [allDeposit, setAllDeposit] = React.useState<StateType<DepositData>>({
		loading: false,
		error: null,
		data: null,
	});
	const { setErrorSnack } = React.useContext(GlobalContexts);
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);
	const [searchDonor, setSearchDonor] = React.useState<string>('');
	const debouncedSearchTerm = useDebounce(searchDonor, 1000);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [reload, setIsReload] = React.useState(false);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const navigate = useNavigate();
	const { getAllDeposit } = useActions();
	const handleBtnClick = () => {
		navigate('/bookkeeping/deposits/create-deposit');
	};
	React.useEffect(() => {
		getAllDeposit({
			pageNumber: page,
			sizeOfPage: pageSize,
			donorQuery: searchDonor,
			setAllDeposit,
			allDeposit,
			setErrorSnack,
			orderBy,
			filterPayload,
		});
	}, [debouncedSearchTerm, page, pageSize, orderBy, reload]);
	const validDeposits = allDeposit?.data?.body.findIndex(
		(deposit: Deposits) => deposit?.itemStatus?.status === 'Incomplete',
	);

	return (
		<>
			<>
				<Stack
					alignItems="center"
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent={'space-between'}
					spacing={1}
					mb={5}
				>
					<Stack direction="column">
						<Stack direction="row" spacing={4} alignItems="center">
							<Typography variant="h6" sx={{ color: 'var(--mainColor)' }}>
								Donations
							</Typography>
						</Stack>
						<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
							Add and manage Donations here
						</Typography>
					</Stack>
					<Stack sx={{ mb: 3 }} direction="row" justifyContent="flex-end">
						<TrimTables
							name={'DepositsTableHead'}
							list={DepositsTableHead}
							trimList={trimList}
							setTrimList={setTrimList}
						/>
						{Number.isInteger(validDeposits) ? (
							<Stack direction="row-reverse" spacing={1} alignItems="center">
								<FilterListButton
									setFilterPayload={setFilterPayload}
									filterPayload={filterPayload}
									setIsReload={setIsReload}
									reload={reload}
									setPage={setPage}
									page={page}
								/>
								{handleRoles('bookkeeping-list') && (
									<GenerateReceipt
										validDeposits={validDeposits}
										deposits={allDeposit?.data?.body as Deposits[]}
									/>
								)}
								{handleRoles('bookkeeping-actions') && (
									<>
										<SingleCreditCardDeposit />

										<Button
											onClick={handleBtnClick}
											sx={{ textTransform: 'none' }}
											startIcon={<Add />}
											variant="contained"
										>
											Add Donation
										</Button>
									</>
								)}
							</Stack>
						) : (
							<ButtonSkeleton length={3} />
						)}
					</Stack>
				</Stack>
				<DepositTable
					depoInfo={allDeposit}
					page={page}
					pageSize={pageSize}
					setPage={setPage}
					setPageSize={setPageSize}
					loading={allDeposit?.loading}
					label={label}
					orderType={orderType}
					setLabel={setLabel}
					setOderBy={setOderBy}
					setOrderType={setOrderType}
					setIsReload={setIsReload}
					reload={reload}
					setFilterPayload={setFilterPayload}
					filterPayload={filterPayload}
					searchDonor={searchDonor}
					setSearchDonor={setSearchDonor}
					trimList={trimList}
					setTrimList={setTrimList}
				/>
			</>
		</>
	);
};

export default Deposit;
