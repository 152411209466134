import { Province, ResponseError, StateType } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';

export interface GetProvince {
	province: StateType<Province[]>;
	setProvince: React.Dispatch<React.SetStateAction<StateType<Province[]>>>;
}

export interface GetProvinceBySearch {
	provinceSearch: StateType<Province[]>;
	setProvinceSearch: React.Dispatch<React.SetStateAction<StateType<Province[]>>>;
	searchTerm: string;
}

const useProvinceActions = () => {
	const { api } = useApi();

	const getProvince = async ({ province, setProvince }: GetProvince) => {
		try {
			setProvince({
				...province,
				loading: true,
			});
			const response = await api.get(`province/list`);

			setProvince({
				...province,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setProvince({
					...province,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const getProvinceBySearch = async ({
		provinceSearch,
		setProvinceSearch,
		searchTerm,
	}: GetProvinceBySearch) => {
		try {
			setProvinceSearch({
				...provinceSearch,
				loading: true,
			});
			const provinceData = await api.get(`province/search?provinceQuery=${searchTerm}`);

			setProvinceSearch({
				...provinceSearch,
				loading: false,
				data: provinceData?.data?.result,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setProvinceSearch({
					...provinceSearch,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	return {
		getProvince,
		getProvinceBySearch
	};
};

export default useProvinceActions;
