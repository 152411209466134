import {
	DonationSummaryReport,
	IHCAOrderReport,
	INonCashDonationsReport,
	WalkathonReport,
} from 'contexts/intialstates/Types';

export const detailedReportArr = (r: DonationSummaryReport[]) =>
	r
		?.map((report) =>
			report.categories?.map((x) =>
				x.detail?.map((y) => ({
					...y,
					groupCode: report.groupCode,
					entity: x.name,
					groupName: report.groupName,
				})),
			),
		)
		?.flat(2);

export const hcaOrderReportAlt = (r: IHCAOrderReport) =>
	r.groups.map((group) => ({
		...group,
		groupArr: group.categories
			.map((category) =>
				category.detail.map((d) => ({
					...d,
					categoryName: category.name,
				})),
			)
			?.flat(1),
	}));

export const walkathonalt = (r?: WalkathonReport[]) =>
	r
		?.map((report) =>
			report.walkathonSchoolItems?.map((walkathonSchoolItems) => ({
				...walkathonSchoolItems,
				walkathonId: report.walkathonId,
				SBschoolBoardId: report.schoolBoardId,
				schoolBoardName: report.schoolBoardName,
				SBaddress: report.address,
				SBamount: report.amount,
			})),
		)
		?.flat();
export const nonCashDonationsAlt = (r: INonCashDonationsReport[]) =>
	r?.map((group) => ({
		...group,
		categoryArr: group.categories
			.map((category) =>
				category.detail.map((x) => ({
					...x,
					categoryName: category.name,
					catgoryTotal: category.total,
				})),
			)
			?.flat(1),
	}));

export const organizationType = [
	{
		label: 'School',
		id: 'School ',
	},
	{
		label: 'School Board',
		id: 'Board',
	},
	{
		label: 'Diocese',
		id: 'Diocese ',
	},
	{
		label: 'Parish',
		id: 'Parish ',
	},
];
export const groupList = [
	{
		label: 'By Society and Payment Type',
		id: 'society,paymentMethod',
	},
	{
		label: 'By Donation Type and Receipt Freqency',
		id: 'donationType,receiptFrequency',
	},
	{
		label: 'By Society and Receipt Freqency ',
		id: 'society,receiptFrequency',
	},
	{
		label: 'By Society and Donation Type ',
		id: 'society,donationType',
	},
	{
		label: 'By Society and Organization Type ',
		id: 'society,organizationType',
	},
	{
		label: 'By Province and Society',
		id: 'province,society',
	},
];

export const orderStatus = ['Pending', 'Printed', 'Confirmed'];
export const hcaOrderGroups = [
	{
		label: 'Organization Type',
		id: 'organizationType',
	},
	{
		label: 'HCA Material',
		id: 'hcaMaterial',
	},
];

export const nonCashdoantionGroups = [
	{
		label: 'By Society and Gift Type',
		id: 'society,giftType',
	},
	{
		label: 'By Donation Type and Gift Type',
		id: 'donationType,giftType',
	},
];
