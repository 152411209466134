import {
	CreateDepositProps,
	CreditCardDepositProps,
	DeleteDepositProps,
	GetAllDepositProps,
	GetDepositProps,
	UpdateDepositProps,
} from '../Components/Deposits/Types';
import { ResponseError } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import React from 'react';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';

const useDepositActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const getAllDeposit = async ({
		pageNumber,
		sizeOfPage,
		donorQuery,
		batchID,
		allDeposit,
		setAllDeposit,
		setErrorSnack,
		orderBy,
		filterPayload,
	}: GetAllDepositProps) => {
		try {
			setAllDeposit({
				...allDeposit,
				loading: true,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (pageNumber) {
				response = await api.get(
					`deposit/list?PageNumber=${pageNumber + 1}&PageSize=${sizeOfPage}${
						donorQuery ? '&donorQuery=' + donorQuery : ''
					}${filter}${batchID ? '&batchId=' + batchID : ''}${orderBy || '&SortBy=created%20desc'}`,
				);
			} else {
				response = await api.get(
					`deposit/list?${donorQuery ? '&donorQuery=' + donorQuery : ''}&PageSize=${sizeOfPage}${filter}${
						batchID ? '&batchId=' + batchID : ''
					}${orderBy || '&SortBy=created%20desc'}`,
				);
			}

			setAllDeposit({
				...allDeposit,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || allDeposit.data?.totalRecords,
				},
				error: null,
			});
			setErrorSnack(false);
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAllDeposit({
					...allDeposit,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
			setErrorSnack(true);
		}
	};

	const getDeposit = async ({ depositId, deposit, setDeposit, setErrorSnack }: GetDepositProps) => {
		try {
			setDeposit({
				...deposit,
				loading: true,
			});
			const getDeposit = await api.get(`deposit/${depositId}`);
			setDeposit({
				...deposit,
				loading: false,
				data: getDeposit?.data?.result,
				error: null,
			});
			setErrorSnack(false);
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDeposit({
					...deposit,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
			setErrorSnack(true);
		}
	};

	const createSingleDeposit = async ({
		payload,
		createDeposit,
		setCreateDeposit,
		setErrorSnack,
	}: CreateDepositProps) => {
		try {
			setCreateDeposit({
				...createDeposit,
				loading: true,
			});
			const createDeposits = await api.post(`deposit/create`, { ...payload });
			setCreateDeposit({
				...createDeposit,
				loading: false,
				data: createDeposits?.data?.result,
				error: null,
			});
			setErrorSnack(false);
		} catch (err: any) {
			if (err.response?.data?.StatusCode === 737) {
				setSimpleErrorSnack({
					message: 'Invalid batch number',
					severity: 'error',
					show: true,
				});
				return false;
			}
			if (Axios.isAxiosError(err)) {
				setCreateDeposit({
					...createDeposit,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});

				setSimpleErrorSnack({
					message: 'Failed to create donation',
					severity: 'error',
					show: true,
				});
			}
			setErrorSnack(true);
		}
	};

	const creditCardDeposit = async ({
		payload,
	}: // creditCardDeposit,
	// setCreditCardDeposit,
	// setErrorSnack,
	CreditCardDepositProps) => {
		try {
			// setCreditCardDeposit({
			//     ...creditCardDeposit,
			//     loading: true,
			// });
			const response = await api.post(`checkout`, { ...payload });
			if (response.data && response.data.result) {
				window.location.href = response.data.result.redirectUrl;
			} else {
				setSimpleErrorSnack({
					message: 'Failed to create credit card donation',
					severity: 'error',
					show: true,
				});
				if (payload.donorId) {
					location.replace(`/bookkeeping/donors/${payload.donorId}`);
				} else {
					location.replace(`/bookkeeping/deposits`);
				}
				return false;
			}
		} catch (error) {
			setSimpleErrorSnack({
				severity: 'error',
				message: 'An error occurred',
				show: true,
			});
		}
		return false;
	};

	const updateSingleDeposit = async ({
		payload,
		depositId,
		updateDeposit,
		setUpdateDeposit,
		setErrorSnack,
		posted,
	}: UpdateDepositProps) => {
		try {
			setUpdateDeposit({
				...updateDeposit,
				loading: true,
			});
			if (posted) {
				const updateDepositResponse = await api.put(`deposit/${depositId}/updatePosted`, {
					dioceseId: payload?.dioceseId,
					schoolBoardId: payload?.schoolBoardId,
					schoolId: payload?.schoolId,
					parishId: payload?.parishId,
					donations: payload?.donations?.map((donation) => ({
						donationId: donation?.id,
						remarks: donation?.remark,
					})),
				});
				setUpdateDeposit({
					...updateDeposit,
					loading: false,
					data: updateDepositResponse?.data?.result,
					error: null,
				});
				setSimpleErrorSnack({
					message: 'Donation updated successfully',
					severity: 'success',
					show: true,
				});
				return;
			}
			const updateDepositResponse = await api.put(`deposit/${depositId}/update`, { ...payload });

			setUpdateDeposit({
				...updateDeposit,
				loading: false,
				data: updateDepositResponse?.data?.result,
				error: null,
			});
			setSimpleErrorSnack({
				message: 'Donation updated successfully',
				severity: 'success',
				show: true,
			});
		} catch (err: any) {
			if (err.response?.data?.StatusCode === 737) {
				setSimpleErrorSnack({
					message: 'Invalid batch number',
					severity: 'error',
					show: true,
				});
				return false;
			}
			if (Axios.isAxiosError(err)) {
				setUpdateDeposit({
					...updateDeposit,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'Failed to update donation',
					severity: 'error',
					show: true,
				});
			}
			setErrorSnack(true);
		}
	};

	const deleteSingleDeposit = async ({
		depositId,
		deleteDeposit,
		setDeleteDeposit,
		setErrorSnack,
	}: DeleteDepositProps) => {
		if (depositId) {
			try {
				setDeleteDeposit({
					...deleteDeposit,
					loading: true,
				});
				const deleteDepositResponse = await api.remove(`deposit/${depositId}/archive`);

				setDeleteDeposit({
					...deleteDeposit,
					loading: false,
					data: deleteDepositResponse?.data?.result,
					error: null,
				});
				setSimpleErrorSnack({
					message: 'Donation deleted successfully',
					severity: 'success',
					show: true,
				});
			} catch (err) {
				if (Axios.isAxiosError(err)) {
					setDeleteDeposit({
						...deleteDeposit,
						loading: false,
						error: errorCodes(err?.response?.data) as ResponseError,
					});
				}
				setSimpleErrorSnack({
					message: 'Failed to delete donation',
					severity: 'error',
					show: true,
				});
			}
		}
	};
	const postDeposit = async (id: number) => {
		try {
			const response = await api.post(`deposit/${id}/post`);
			if (response?.data?.result) {
				return true;
			}

			return false;
		} catch {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	return {
		getAllDeposit,
		getDeposit,
		createSingleDeposit,
		creditCardDeposit,
		updateSingleDeposit,
		deleteSingleDeposit,
		postDeposit,
	};
};

export default useDepositActions;
