import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/system';

export const MainTable = styled(Table)`
	background: #e5e5e5;
	display: block;
	margin-top: 30px;
	position: relative;
	height: 400px;
	overflow: auto;
`;

export const TableCells = styled(TableCell)`
	width: 300px;
	border: none;

	span {
		color: #499bfb;
		margin-right: 12px;
	}
`;
export const TableRows = styled(TableRow)`
	background: white;
	display: block !important;
	margin-bottom: 10px;
	border: none;
	border-radius: 7px;
	width: 100%;
	padding: 0 10px;

	&:hover {
		background: white !important;
	}

	&.active::after {
		content: '';
		width: 1%;
		position: absolute;
		top: 0;
		left: 0;
		background: #499bfb;
		height: 100%;
	}
`;
export const Pagination = styled(TablePagination)`
	display: flex;
	justify-content: center;
	background: white;
	width: 100%;
	border-radius: 10px;
	position: relative;
`;
export const TableHeader = styled(TableHead)`
	margin-bottom: 20px;
	display: inline-block;
	border-radius: 7px;
	padding: 0 10px;
	width: 100%;
	background: white;
	position: sticky;
	top: 0;
	left: 0;
	tr {
		th:not(:last-child) {
			font-weight: 600;
			border-right: 1px solid #cacaca !important;
		}
		td {
			width: 200px;
			font-weight: 600;
		}
		th {
			width: 300px;
			font-weight: 600;
		}
	}
`;
export const Wrapper = styled('div')`
	div {
		// overflow: visible !important;
	}
`;
