import React, { FC, createContext, useReducer, useState } from 'react';
import { appeal } from './reducers/appealReducer';
import { itemStatus } from './reducers/itemStatusReducer';
import { donationType } from './reducers/donationTypeReducer';
import { setAction } from './reducers/messagesReducer';
import { allParish, singleParish } from './reducers/parishReducer';
import { allPaymentmethods, singlePaymentMethod } from './reducers/paymentMethodReducer';
import { societies } from './reducers/societyReducer';
import { allVoidTypes } from './reducers/voidTypeReducer';
import { allAppealInitialState } from 'contexts/intialstates/appealInitialState';
import { allItemStatusInitialState } from 'contexts/intialstates/itemStatusInitialState';
import { allDonationTypesInitialState } from 'contexts/intialstates/donationTypesInitialState';
import { messagesInitialState } from 'contexts/intialstates/messages';
import { allParishInitialState, singleParishInitialState } from 'contexts/intialstates/parishInitialState';
import {
	allPaymentMethodInitialState,
	singlePaymentMethodInitialState,
} from 'contexts/intialstates/paymentMethodInitialState';
import { allSocietyInitialState } from 'contexts/intialstates/societyInitialState';
import { allVoidTypeInitialState } from 'contexts/intialstates/voidTypeInitialState';
import {
	Action,
	AllAppealStateType,
	AllItemStatusStateType,
	MessageStateType,
	AllParishStateType,
	AllPaymentMethodStateType,
	AllSocietyStateType,
	AllVoidTypeStateType,
	AppealData,
	ItemStatusData,
	MessagesPayload,
	ParishData,
	PaymentMethodData,
	SocietyData,
	VoidTypeData,
	SingleParishStateType,
	Parish,
	PaymentMethod,
	SinglePaymentMethodStateType,
	Severity,
	User,
	StateType,
	Donor,
} from 'contexts/intialstates/Types';
import { DonorAnnuities } from 'hooks/useAnnuitiesActions';
import { adhocDonor } from 'hooks/useCampaignActions';

export interface DonorDialogState {
	state: boolean;
	id?: string | number;
	type: string;
}

export interface ISimpleSnackBar {
	message: string;
	severity: Severity;
	show: boolean;
	hide?: number;
}

interface createDonorDialogProps {
	donorDialogState: DonorDialogState;
	setCreateDonorDialog: React.Dispatch<React.SetStateAction<DonorDialogState>>;
	isDonorSelected: boolean;
	setIsDonorSelected: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface GlobalContextType {
	// allAppealState?: AllAppealStateType;
	// allAppealDispatch: React.Dispatch<Action<AppealData>>;

	messageState?: MessageStateType;
	messageDispatch: React.Dispatch<Action<MessagesPayload>>;

	allItemStatusState?: AllItemStatusStateType;
	allItemStatusDispatch: React.Dispatch<Action<ItemStatusData>>;

	allParishStateState?: AllParishStateType;
	allParishStateDispatch: React.Dispatch<Action<ParishData>>;

	// allPaymentMethodState?: AllPaymentMethodStateType;
	// allPaymentMethodDispatch: React.Dispatch<Action<PaymentMethodData>>;

	// allSocietyState?: AllSocietyStateType;
	// allSocietyDispatch: React.Dispatch<Action<SocietyData>>;

	allVoidTypeState?: AllVoidTypeStateType;
	allVoidTypeDispatch: React.Dispatch<Action<VoidTypeData>>;

	singleParishState?: SingleParishStateType;
	singleParishDispatch: React.Dispatch<Action<Parish>>;

	singlePaymentMethodState?: SinglePaymentMethodStateType;
	singlePaymentMethodDispatch: React.Dispatch<Action<PaymentMethod>>;

	simpleErrorSnack: ISimpleSnackBar;
	setSimpleErrorSnack: React.Dispatch<React.SetStateAction<ISimpleSnackBar>>;

	routeHistory: string;
	setRouteHistory: React.Dispatch<React.SetStateAction<string>>;

	donorAnn: StateType<DonorAnnuities>;
	setDonorAnn: React.Dispatch<React.SetStateAction<StateType<DonorAnnuities>>>;

	disabled: boolean;
	setDisabled: React.Dispatch<React.SetStateAction<boolean>>;

	checkedDonorx: adhocDonor[];
	setCheckedDonorx: React.Dispatch<React.SetStateAction<adhocDonor[]>>;

	currentUser: StateType<User>;
	setCurrentUser: React.Dispatch<React.SetStateAction<StateType<User>>>;

	errorSnack: boolean;
	setErrorSnack: React.Dispatch<React.SetStateAction<boolean>>;

	apiToken: string;
	setToken: React.Dispatch<React.SetStateAction<string>>;

	pendingFiles: number[];
	setPendingFiles: React.Dispatch<React.SetStateAction<number[]>>;

	pendingFilesAlert: boolean;
	setPendingFilesAlert: React.Dispatch<React.SetStateAction<boolean>>;

	createDonorDialog: createDonorDialogProps;
}

export const GlobalContexts = createContext<GlobalContextType>({} as any);

export const GlobalContextsProvider: FC = ({ children }) => {
	const [allAppealState, allAppealDispatch] = useReducer(appeal, allAppealInitialState);

	const [messageState, messageDispatch] = useReducer(setAction, messagesInitialState);

	const [allItemStatusState, allItemStatusDispatch] = useReducer(itemStatus, allItemStatusInitialState);

	const [allDonationTypeState, allDonationTypeDispatch] = useReducer(
		donationType,
		allDonationTypesInitialState,
	);

	const [allParishStateState, allParishStateDispatch] = useReducer(allParish, allParishInitialState);

	const [allPaymentMethodState, allPaymentMethodDispatch] = useReducer(
		allPaymentmethods,
		allPaymentMethodInitialState,
	);

	const [allSocietyState, allSocietyDispatch] = useReducer(societies, allSocietyInitialState);

	const [allVoidTypeState, allVoidTypeDispatch] = useReducer(allVoidTypes, allVoidTypeInitialState);

	const [singleParishState, singleParishDispatch] = useReducer(singleParish, singleParishInitialState);

	const [singlePaymentMethodState, singlePaymentMethodDispatch] = useReducer(
		singlePaymentMethod,
		singlePaymentMethodInitialState,
	);
	const [donorAnn, setDonorAnn] = useState<StateType<DonorAnnuities>>({
		loading: false,
		error: null,
		data: null,
	});

	const [checkedDonorx, setCheckedDonorx] = useState<adhocDonor[]>([]);
	const [disabled, setDisabled] = useState(false);
	const [errorSnack, setErrorSnack] = React.useState<boolean>(false);
	const [simpleErrorSnack, setSimpleErrorSnack] = React.useState<ISimpleSnackBar>({
		show: false,
		message: '',
		severity: 'info',
	});
	const [currentUser, setCurrentUser] = useState<StateType<User>>({
		loading: false,
		data: null,
		error: null,
	});
	const [routeHistory, setRouteHistory] = React.useState<string>('');
	const [apiToken, setToken] = React.useState<string>('');

	const [donorDialogState, setCreateDonorDialog] = React.useState<DonorDialogState>({
		state: false,
		id: '',
		type: '',
	});

	const [isDonorSelected, setIsDonorSelected] = React.useState<boolean>(false);
	const [pendingFiles, setPendingFiles] = React.useState<number[]>([]);
	const [pendingFilesAlert, setPendingFilesAlert] = React.useState<boolean>(false);

	const contextValue = {
		errorSnack,
		setErrorSnack,

		simpleErrorSnack,
		setSimpleErrorSnack,

		routeHistory,
		setRouteHistory,

		apiToken,
		setToken,

		donorAnn,
		setDonorAnn,

		disabled,
		setDisabled,

		checkedDonorx,
		setCheckedDonorx,

		createDonorDialog: {
			donorDialogState,
			setCreateDonorDialog,
			isDonorSelected,
			setIsDonorSelected,
		},

		// allAppealState,
		// allAppealDispatch,

		allParishStateState,
		allParishStateDispatch,

		messageState,
		messageDispatch,

		allItemStatusState,
		allItemStatusDispatch,

		allDonationTypeState,
		allDonationTypeDispatch,

		// allPaymentMethodState,
		// allPaymentMethodDispatch,

		// allSocietyState,
		// allSocietyDispatch,

		allVoidTypeState,
		allVoidTypeDispatch,

		singleParishState,
		singleParishDispatch,

		singlePaymentMethodState,
		singlePaymentMethodDispatch,

		currentUser,
		setCurrentUser,

		pendingFiles,
		setPendingFiles,

		pendingFilesAlert,
		setPendingFilesAlert,
	};

	return <GlobalContexts.Provider value={contextValue}>{children}</GlobalContexts.Provider>;
};
