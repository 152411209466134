import React, { useRef } from 'react';
import { CloudDownload } from '@mui/icons-material';
import {
	Button,
	ClickAwayListener,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Stack,
	Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { currentDate } from 'helpers/dayAndTime';
import Logo from './../../assets/images/logo.png';
import { fieldName } from 'Components/Reusable/FilterList/FilterList';

interface Props {
	title: string;
	children: any;
	subTitle: any;
	category?: string;
	handlePrint?: () => void;
	handleExport?: () => void;
}

const Report = ({ title, children, subTitle, category, handlePrint, handleExport }: Props) => {
	const componentRef = useRef(null);
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLButtonElement>(null);

	const handleClose = (event: Event | React.SyntheticEvent) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}

		setOpen(false);
	};

	function handleListKeyDown(event: React.KeyboardEvent) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === 'Escape') {
			setOpen(false);
		}
	}

	const btn = document.getElementById('composition-button')?.clientWidth;

	const handleClick = () => {
		if (handleExport) {
			setOpen((prevOpen) => !prevOpen);
		} else {
			handlePrint?.();
		}
	};

	return (
		<Paper sx={{ border: '1px solid #e0dcdc', mt: 5, position: 'relative' }}>
			<Button
				ref={anchorRef}
				id="composition-button"
				aria-controls={open ? 'composition-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleClick}
				variant="outlined"
				disableElevation
				sx={{ textTransform: 'none', position: 'absolute', top: 20, right: 20 }}
			>
				{<CloudDownload sx={{ color: 'var(--mainColor)', fontSize: 30 }} />}
			</Button>

			<Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									autoFocusItem={open}
									id="composition-menu"
									aria-labelledby="composition-button"
									onKeyDown={handleListKeyDown}
									sx={{ width: btn, py: { xs: 0, sm: 'initial' } }}
								>
									<MenuItem onClick={handleExport}>CSV</MenuItem>
									<MenuItem onClick={handlePrint}>PDF</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
			<div id="target" ref={componentRef}>
				<Stack sx={{ my: 2, px: 1 }}>
					<>
						<Stack width="100%" direction="row">
							<Stack flex={0.5} alignItems="flex-start" direction="column">
								<img src={Logo} alt="logo" width={40} height={50} />
							</Stack>

							<Stack sx={{ mb: 2, textAlign: 'center' }} alignItems="center" direction="column">
								<Typography sx={{ color: 'var(--mainColor)', fontSize: 20, fontWeight: 700 }}>
									{title}
								</Typography>
								{category && (
									<Typography
										sx={{
											color: 'var(--fontColorHeaderSubTitle)',
											fontSize: 20,
											fontWeight: 600,
										}}
									>
										{fieldName(category)}
									</Typography>
								)}
								<Typography sx={{ color: 'var(--fontColorHeaderSubTitle)', fontSize: 18, fontWeight: 400 }}>
									{subTitle}
								</Typography>
							</Stack>
						</Stack>
						<Stack
							direction={'row'}
							justifyContent="flex-end"
							mb={3}
							sx={{ color: 'var(--fontColorHeaderSubTitle)', fontSize: 16, fontWeight: 400 }}
						>
							{currentDate()}
						</Stack>
					</>

					<Box width="100%">{children}</Box>
				</Stack>
			</div>
		</Paper>
	);
};

export default Report;
