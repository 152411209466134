import {
	ReportData,
	ReportPayload,
	StateType,
	ResponseError,
	WalkathonReportData,
	DonationSummaryReport,
	IHCAOrderReport,
	INonCashDonationsReport,
	DonationDetailedReport,
} from 'contexts/intialstates/Types';
import React, { Dispatch, SetStateAction } from 'react';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { DonationsSummaryPayload } from 'Components/Reports/DonationsSummary';
import { GlobalContexts } from 'contexts/GlobalContext';
import { BurseChaliceData } from 'Components/Reports/BurseChalice/BurseChaliceReportView';
import { HCAOrderReportPayload } from 'Components/Reports/HCAOrder';
import { NonCashDonationsReportPayload } from 'Components/Reports/NonCashReport';
import moment from 'moment';
import { AnnuitiesData } from 'Components/Reports/Annuities/AnnuitiesReportView';
import { IDonorReportData } from 'Components/Reports/DonorReports/DetailedReportView';

interface GetReportProps {
	payload: ReportPayload;
	report: StateType<ReportData>;
	setReport: React.Dispatch<React.SetStateAction<StateType<ReportData>>>;
}

interface GetWalkathonReportProps {
	year: number;
	walkathonReport: StateType<WalkathonReportData>;
	setWalkathonReport: React.Dispatch<React.SetStateAction<StateType<WalkathonReportData>>>;
}
interface GetDonationsSummaryReportProps {
	payload: DonationsSummaryPayload;
	donationsSummaryReport: StateType<DonationSummaryReport[]>;
	setDonationsSummaryReport: React.Dispatch<React.SetStateAction<StateType<DonationSummaryReport[]>>>;
}
interface GetDonationsDetailedReportProps {
	payload: DonationsSummaryPayload;
	donationsDetailedReport: StateType<DonationDetailedReport>;
	setDonationsDetailedReport: React.Dispatch<React.SetStateAction<StateType<DonationDetailedReport>>>;
}
interface GetHCAOrderReportProps {
	payload: HCAOrderReportPayload;
	hcaOrderReport: StateType<IHCAOrderReport>;
	setHcaOrderReport: React.Dispatch<React.SetStateAction<StateType<IHCAOrderReport>>>;
}

export interface DonorReportPayload {
	start: string;
	end: string;
	minAmount?: string;
	maxAmount?: string;
	donors: string;
	donationType: string;
	receiptFrequency: string;
	society: string;
	paymentMethod: string;
	noMail?: boolean;
	excludeFromParishList?: boolean;
	returnMail?: boolean;
	deceased?: boolean;
	isdetailed?: boolean;
	isCommunicationNone?: boolean;
	magazine?: boolean;
	faithPropagation?: boolean;
	stPeter?: boolean;
	holyChildhood?: boolean;
	noFollowAppeal?: boolean;
	isPrefSocietyNone?: boolean;
}

interface BurseChalicePayload {
	start: string;
	end: string;
	minAmount?: string;
	maxAmount?: string;
	donorIds: string;
	donationTypeIds: string;
	statusIds: string;
	contributionType: string;
}

export interface DonorReportProp {
	payload: DonorReportPayload;
	setDonorReportData: React.Dispatch<React.SetStateAction<StateType<IDonorReportData[]>>>;
}

interface BurseChaliceReportProp {
	payload: BurseChalicePayload;
	setBurseChaliceReportData: React.Dispatch<React.SetStateAction<StateType<BurseChaliceData>>>;
}
interface GetNonCashDonationsReportProps {
	payload: NonCashDonationsReportPayload;
	nonCashDonationsReport: StateType<INonCashDonationsReport[]>;
	setNonCashDonationReport: React.Dispatch<React.SetStateAction<StateType<INonCashDonationsReport[]>>>;
}

interface AnnuitiesPayload {
	startDate: string;
	endDate: string;
	agreementTypeIds: string;
	frequencyIds: string;
	paymentMethodIds: string;
	agreementStatusIds: string;
	minReceivedAmount?: string;
	maxReceivedAmount?: string;
	minOverPaymentAmount?: string;
	maxOverPaymentAmount?: string;
	provinceIds: string;
	beneficiaryIds: string;
	donorIds: string;
}

interface AnnuitiesReportProp {
	payload: AnnuitiesPayload;
	setAnnuitiesReportData: React.Dispatch<React.SetStateAction<StateType<AnnuitiesData[]>>>;
}

export interface DeathBenefitReport {
	annuitant: string;
	dateOfDeath: string;
	aggreements: {
		agreementNumber: string;
		donorId: number;
		annuitantName: string;
		annuitantSIN: string;
		annuitantDateOfDeath: string;
		coAnnuitantName: string;
		coAnnuitantSIN: string;
		coAnnuitantDateOfDeath: string;
		oldStatus: string;
		newStatus: string;
		dbAmount: number;
	}[];
}

export interface DeathBenefitProps {
	deathBenefit: StateType<DeathBenefitReport>;
	setDeathBenefit: Dispatch<SetStateAction<StateType<DeathBenefitReport>>>;
	id: number;
}

export interface PaymentListReport {
	agreementNumber: string;
	agreementStatus: string;
	agreementType: string;
	annuitantDonorId: number;
	annuitantName: string;
	annuitantSIN: string;
	annuitantDateOfDeath: string;
	coAnnuitantDonorId: number;
	coAnnuitantName: string;
	coAnnuitantSIN: string;
	coAnnuitantDateOfDeath: string;
	dateOfPayment: string;
	bankName: string;
	bankBranch: string;
	accountNumber: string;
	transit: string;
	annuityAmount: number;
	taxableAmount: number;
}
export interface PaymentListProps {
	paymentList: StateType<PaymentListReport[]>;
	setPaymentList: Dispatch<SetStateAction<StateType<PaymentListReport[]>>>;
}
export interface PaymentListFilterProps {
	paymentList: StateType<PaymentListReport[]>;
	setPaymentList: Dispatch<SetStateAction<StateType<PaymentListReport[]>>>;
	year: number;
	month: number;
}
export interface PaymentListFilterPdfProps {
	year: number;
	month: number;
}

export interface ResidualPostingReport {
	month: number;
	year: number;
	details: {
		agreementNumber: string;
		annuitant: string;
		sin: string;
		residualBegining: number;
		newGift: number;
		annuityPayment: number;
		expense: number;
		interestCredit: number;
		residualEnd: number;
	}[];
}
export interface ResidualPostingProps {
	residual: StateType<ResidualPostingReport>;
	setResidual: Dispatch<SetStateAction<StateType<ResidualPostingReport>>>;
	month?: number;
	year?: number;
}

const useReportActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack, setPendingFiles } = React.useContext(GlobalContexts);

	const getReport = async ({ payload, report, setReport }: GetReportProps) => {
		try {
			setReport({
				...report,
				loading: true,
			});
			const response = await api.post(`/report/deposit`, payload);
			setReport({
				...report,
				data: response?.data?.result,
				loading: false,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setReport({
					...report,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};
	const getWalkathonReport = async ({
		year,
		walkathonReport,
		setWalkathonReport,
	}: GetWalkathonReportProps) => {
		try {
			setWalkathonReport({
				...walkathonReport,
				loading: true,
			});
			const response = await api.get(`/report/walkathon?Year=${year}`);
			if (response?.data?.result && response.data.result?.total === 0) {
				setSimpleErrorSnack({
					message: 'No record found',
					severity: 'info',
					show: true,
				});
				setWalkathonReport({
					data: null,
					loading: false,
					error: null,
				});
				return;
			}
			setWalkathonReport({
				...walkathonReport,
				data: response?.data?.result,
				loading: false,
				error: null,
			});
			if (response?.data?.result && response.data.result.targetAmountGrandTotal === 0) {
				setSimpleErrorSnack({
					message: 'No record found',
					severity: 'info',
					show: true,
				});
			}
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSimpleErrorSnack({
					message: 'An error occurred',
					severity: 'error',
					show: true,
				});
				setWalkathonReport({
					...walkathonReport,
					loading: false,
					error: null,
				});
			}
		}
	};
	const getWalkathonReportPdf = async (year: number) => {
		try {
			const response = await api.get(`/report/walkathon-pdf?Year=${year}`);

			if (response?.data?.statusCode === 0 && response?.data?.result?.id) {
				setPendingFiles((prev) => [...prev, response?.data?.result?.id]);
				setSimpleErrorSnack({
					message: `Download is in progress, we will inform you when it is ready`,
					severity: 'info',
					show: true,
				});
				return true;
			}
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSimpleErrorSnack({
					message: 'PDF download failed',
					severity: 'error',
					show: true,
				});
			}
		}
	};
	const getDonationsSummaryReport = async ({
		payload,
		donationsSummaryReport,
		setDonationsSummaryReport,
	}: GetDonationsSummaryReportProps) => {
		try {
			setDonationsSummaryReport({
				...donationsSummaryReport,
				loading: true,
			});
			const response = await api.get(
				`/report/generalDonations?start=${payload.start}&end=${payload.end}&groupby=${
					payload.groupby
				}&category=${payload.category}&isdetailed=${payload.isdetailed}${
					payload.provinceids ? `&provinceids=${payload.provinceids}` : ''
				}${payload.paymentmethodids ? `&paymentmethodids=${payload.paymentmethodids}` : ''}${
					payload.donationtypeids ? `&donationtypeids=${payload.donationtypeids}` : ''
				}${payload.organizationtypes ? `&organizationtypes=${payload.organizationtypes}` : ''}${
					payload.societyids ? `&societyids=${payload.societyids}` : ''
				}${payload.receiptFrequency ? `&receipttypeids=${payload.receiptFrequency}` : ''}${
					payload.batchno ? `&batchno=${payload.batchno}` : ''
				}`,
			);
			if (response?.data?.result && response.data.result.length === 0) {
				setSimpleErrorSnack({
					message: 'No record found',
					severity: 'info',
					show: true,
				});
				setDonationsSummaryReport({
					data: null,
					loading: false,
					error: null,
				});
				return;
			}
			setDonationsSummaryReport({
				...donationsSummaryReport,
				data: response?.data?.result,
				loading: false,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSimpleErrorSnack({
					message: 'An error occurred',
					show: true,
					severity: 'error',
				});
				setDonationsSummaryReport({
					data: null,
					loading: false,
					error: null,
				});
			}
		}
	};
	const getDonationsSummaryCSV = async (payload: DonationsSummaryPayload): Promise<boolean> => {
		try {
			setSimpleErrorSnack({
				message: 'Exporting...',
				severity: 'info',
				show: true,
			});
			const response = await api.get(
				`/report/generalDonations-csv?start=${payload.start}&end=${payload.end}&groupby=${
					payload.groupby
				}&category=${payload.category}&isdetailed=${payload.isdetailed}${
					payload.provinceids ? `&provinceids=${payload.provinceids}` : ''
				}${payload.paymentmethodids ? `&paymentmethodids=${payload.paymentmethodids}` : ''}${
					payload.donationtypeids ? `&donationtypeids=${payload.donationtypeids}` : ''
				}${payload.organizationtypes ? `&organizationtypes=${payload.organizationtypes}` : ''}${
					payload.societyids ? `&societyids=${payload.societyids}` : ''
				}${payload.receiptFrequency ? `&receipttypeids=${payload.receiptFrequency}` : ''}${
					payload.batchno ? `&batchno=${payload.batchno}` : ''
				}`,
			);

			setSimpleErrorSnack({
				message: 'Exporting...',
				severity: 'info',
				show: false,
			});

			const url = window.URL.createObjectURL(new Blob([response?.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Donations_Summary_Export_${moment().format('yyMM')}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			return true;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'Exporting failed',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};
	const getDonationsSummaryPDF = async (payload: DonationsSummaryPayload): Promise<boolean> => {
		try {
			const response = await api.get(
				`/report/generalDonations-pdf?start=${payload.start}&end=${payload.end}&groupby=${
					payload.groupby
				}&category=${payload.category}&isdetailed=${payload.isdetailed}${
					payload.provinceids ? `&provinceids=${payload.provinceids}` : ''
				}${payload.paymentmethodids ? `&paymentmethodids=${payload.paymentmethodids}` : ''}${
					payload.donationtypeids ? `&donationtypeids=${payload.donationtypeids}` : ''
				}${payload.organizationtypes ? `&organizationtypes=${payload.organizationtypes}` : ''}${
					payload.societyids ? `&societyids=${payload.societyids}` : ''
				}${payload.receiptFrequency ? `&receipttypeids=${payload.receiptFrequency}` : ''}${
					payload.batchno ? `&batchno=${payload.batchno}` : ''
				}`,
			);

			if (response?.data?.statusCode === 0 && response?.data?.result?.id) {
				setPendingFiles((prev) => [...prev, response?.data?.result?.id]);
				setSimpleErrorSnack({
					message: `Download is in progress, we will inform you when it is ready`,
					severity: 'info',
					show: true,
				});
				return true;
			}
			return true;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'PDF Download failed',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};
	const getDonationsDetailedReport = async ({
		payload,
		donationsDetailedReport,
		setDonationsDetailedReport,
	}: GetDonationsDetailedReportProps) => {
		try {
			setDonationsDetailedReport({
				...donationsDetailedReport,
				loading: true,
			});
			let response;
			if (donationsDetailedReport.data) {
				response = await api.get(
					`/report/generalDonations-detail?start=${payload.start}&end=${payload.end}&groupby=${
						payload.groupby
					}&category=${payload.category}&isdetailed=${payload.isdetailed}${
						payload.provinceids ? `&provinceids=${payload.provinceids}` : ''
					}${payload.paymentmethodids ? `&paymentmethodids=${payload.paymentmethodids}` : ''}${
						payload.donationtypeids ? `&donationtypeids=${payload.donationtypeids}` : ''
					}${payload.organizationtypes ? `&organizationtypes=${payload.organizationtypes}` : ''}${
						payload.societyids ? `&societyids=${payload.societyids}` : ''
					}${payload.receiptFrequency ? `&receipttypeids=${payload.receiptFrequency}` : ''}${
						payload.batchno ? `&batchno=${payload.batchno}` : ''
					}${payload.pageNumber ? `&pageNumber=${payload.pageNumber + 1}` : ''}${
						payload.pageSize ? `&pageSize=${payload.pageSize}` : ''
					}`,
				);
			} else {
				response = await api.get(
					`/report/generalDonations-detail?start=${payload.start}&end=${payload.end}&groupby=${
						payload.groupby
					}&category=${payload.category}&isdetailed=${payload.isdetailed}${
						payload.provinceids ? `&provinceids=${payload.provinceids}` : ''
					}${payload.paymentmethodids ? `&paymentmethodids=${payload.paymentmethodids}` : ''}${
						payload.donationtypeids ? `&donationtypeids=${payload.donationtypeids}` : ''
					}${payload.organizationtypes ? `&organizationtypes=${payload.organizationtypes}` : ''}${
						payload.societyids ? `&societyids=${payload.societyids}` : ''
					}${payload.receiptFrequency ? `&receipttypeids=${payload.receiptFrequency}` : ''}${
						payload.batchno ? `&batchno=${payload.batchno}` : ''
					}`,
				);
			}

			if (response?.data?.result && response.data.result.length === 0) {
				setSimpleErrorSnack({
					message: 'No record found',
					severity: 'info',
					show: true,
				});
				setDonationsDetailedReport({
					data: null,
					loading: false,
					error: null,
				});
				return;
			}
			setDonationsDetailedReport({
				...donationsDetailedReport,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || donationsDetailedReport.data?.totalRecords,
					grandTotal: response?.data?.result?.grandTotal || donationsDetailedReport.data?.grandTotal,
				},
				loading: false,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSimpleErrorSnack({
					message: 'An error occurred',
					show: true,
					severity: 'error',
				});
				setDonationsDetailedReport({
					data: null,
					loading: false,
					error: null,
				});
			}
		}
	};
	const getDonationsDetailedCSV = async (payload: DonationsSummaryPayload): Promise<boolean> => {
		try {
			setSimpleErrorSnack({
				message: 'Exporting...',
				severity: 'info',
				show: true,
			});
			const response = await api.get(
				`/report/generalDonations-detail-csv?start=${payload.start}&end=${payload.end}&groupby=${
					payload.groupby
				}&category=${payload.category}&isdetailed=${payload.isdetailed}${
					payload.provinceids ? `&provinceids=${payload.provinceids}` : ''
				}${payload.paymentmethodids ? `&paymentmethodids=${payload.paymentmethodids}` : ''}${
					payload.donationtypeids ? `&donationtypeids=${payload.donationtypeids}` : ''
				}${payload.organizationtypes ? `&organizationtypes=${payload.organizationtypes}` : ''}${
					payload.societyids ? `&societyids=${payload.societyids}` : ''
				}${payload.receiptFrequency ? `&receipttypeids=${payload.receiptFrequency}` : ''}${
					payload.batchno ? `&batchno=${payload.batchno}` : ''
				}`,
			);
			setSimpleErrorSnack({
				message: 'Exporting...',
				severity: 'info',
				show: false,
			});

			const url = window.URL.createObjectURL(new Blob([response?.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Donations_Detailed_Export_${moment().format('yyMMDD')}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			return true;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'Exporting failed',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};
	const getDonationsDetailedPDF = async (payload: DonationsSummaryPayload): Promise<boolean> => {
		try {
			const response = await api.get(
				`/report/generalDonations-detail-pdf?start=${payload.start}&end=${payload.end}&groupby=${
					payload.groupby
				}&category=${payload.category}&isdetailed=${payload.isdetailed}${
					payload.provinceids ? `&provinceids=${payload.provinceids}` : ''
				}${payload.paymentmethodids ? `&paymentmethodids=${payload.paymentmethodids}` : ''}${
					payload.donationtypeids ? `&donationtypeids=${payload.donationtypeids}` : ''
				}${payload.organizationtypes ? `&organizationtypes=${payload.organizationtypes}` : ''}${
					payload.societyids ? `&societyids=${payload.societyids}` : ''
				}${payload.receiptFrequency ? `&receipttypeids=${payload.receiptFrequency}` : ''}${
					payload.batchno ? `&batchno=${payload.batchno}` : ''
				}`,
			);
			if (response?.data?.statusCode === 0 && response?.data?.result?.id) {
				setPendingFiles((prev) => [...prev, response?.data?.result?.id]);
				setSimpleErrorSnack({
					message: `Download is in progress, we will inform you when it is ready`,
					severity: 'info',
					show: true,
				});
				return true;
			}

			return true;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'PDF Download failed',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};
	const getHCAOrderReport = async ({
		payload,
		hcaOrderReport,
		setHcaOrderReport,
	}: GetHCAOrderReportProps) => {
		try {
			setHcaOrderReport({
				...hcaOrderReport,
				loading: true,
			});
			const response = await api.get(
				`/report/hcaOrderActivity?start=${payload.start}&end=${payload.end}&groupby=${
					payload.groupby
				}&isdetailed=${payload.isdetailed}${
					payload.organizationtypes ? `&organizationtypes=${payload.organizationtypes}` : ''
				}${payload.hcamaterialids ? `&hcamaterialids=${payload.hcamaterialids}` : ''}${
					payload.hcaorderstatusids ? `&hcaorderstatusids=${payload.hcaorderstatusids}` : ''
				}`,
			);
			if (
				response?.data?.result &&
				response.data.result.grandTotalOrders === 0 &&
				response.data.result.grandTotalQuantity === 0
			) {
				setSimpleErrorSnack({
					message: 'No record found',
					severity: 'info',
					show: true,
				});
				setHcaOrderReport({
					data: null,
					loading: false,
					error: null,
				});
				return;
			}
			setHcaOrderReport({
				...hcaOrderReport,
				data: response?.data?.result,
				loading: false,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSimpleErrorSnack({
					message: 'An error occurred',
					show: true,
					severity: 'error',
				});
				setHcaOrderReport({
					data: null,
					loading: false,
					error: null,
				});
			}
		}
	};
	const getHCAOrderReportPdf = async (payload: HCAOrderReportPayload) => {
		try {
			const response = await api.get(
				`/report/hcaOrderActivity-pdf?start=${payload.start}&end=${payload.end}&groupby=${
					payload.groupby
				}&isdetailed=${payload.isdetailed}${
					payload.organizationtypes ? `&organizationtypes=${payload.organizationtypes}` : ''
				}${payload.hcamaterialids ? `&hcamaterialids=${payload.hcamaterialids}` : ''}${
					payload.hcaorderstatusids ? `&hcaorderstatusids=${payload.hcaorderstatusids}` : ''
				}`,
			);
			if (response?.data?.statusCode === 0 && response?.data?.result?.id) {
				setPendingFiles((prev) => [...prev, response?.data?.result?.id]);
				setSimpleErrorSnack({
					message: `Download is in progress, we will inform you when it is ready`,
					severity: 'info',
					show: true,
				});

				return true;
			} else {
				setSimpleErrorSnack({
					message: 'An error occured while processing report, please try again later',
					severity: 'error',
					show: true,
				});
				return false;
			}
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSimpleErrorSnack({
					message: 'PDF download failed',
					show: true,
					severity: 'error',
				});
			}
		}
	};

	const getDonorReport = async ({ payload, setDonorReportData }: DonorReportProp) => {
		try {
			setDonorReportData({
				data: null,
				loading: true,
				error: null,
			});

			const response = await api.get(`report/donor?start=${payload.start}
			&end=${payload.end}&isdetailed=${payload.isdetailed}${
				payload.minAmount ? `&minAmount=${payload.minAmount}` : ''
			}${payload.maxAmount ? `&maxAmount=${payload.maxAmount}` : ''}
			&receiptfrequencyids=${payload.receiptFrequency}
			&donationtypeids=${payload.donationType}&societyids=${payload.society}&paymentmethodids=${
				payload.paymentMethod
			}&donorids=${payload.donors}${payload.noMail ? `&noMail=${payload.noMail}` : ''}${
				payload.excludeFromParishList ? `&excludeFromParishList=${payload.excludeFromParishList}` : ''
			}${payload.returnMail ? `&returnMail=${payload.returnMail}` : ''}${
				payload.deceased ? `&deceased=${payload.deceased}` : ''
			}${payload.isCommunicationNone ? `&isCommunicationNone=${payload.isCommunicationNone}` : ''}${
				payload.magazine ? `&magazine=${payload.magazine}` : ''
			}${payload.faithPropagation ? `&faithPropagation=${payload.faithPropagation}` : ''}${
				payload.stPeter ? `&stPeter=${payload.stPeter}` : ''
			}${payload.holyChildhood ? `&holyChildhood=${payload.holyChildhood}` : ''}${
				payload.noFollowAppeal ? `&noFollowAppeal=${payload.noFollowAppeal}` : ''
			}${payload.isPrefSocietyNone ? `&isPrefSocietyNone=${payload.isPrefSocietyNone}` : ''}`);

			if (response?.data?.result && response.data.result.length === 0) {
				setSimpleErrorSnack({
					message: 'No record found',
					severity: 'info',
					show: true,
				});
				setDonorReportData({
					data: null,
					loading: false,
					error: null,
				});
				return;
			}
			if (response?.data?.statusCode === 0) {
				setDonorReportData({
					data: response?.data?.result,
					loading: false,
					error: null,
				});
			} else {
				setDonorReportData({
					data: null,
					loading: false,
					error: null,
				});
			}
		} catch (error) {
			setDonorReportData({
				data: null,
				loading: false,
				error: null,
			});
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
		}
	};

	const exportCSV = async (payload: DonorReportPayload): Promise<boolean> => {
		console.log('export');
		try {
			const response = await api.get(`report/donor-export-csv?start=${payload.start}
			&end=${payload.end}&isdetailed=${payload.isdetailed}${
				payload.minAmount ? `&minAmount=${payload.minAmount}` : ''
			} 
			${payload.maxAmount ? `&maxAmount=${payload.maxAmount}` : ''}
			&receiptfrequencyids=${payload.receiptFrequency}
			&donationtypeids=${payload.donationType}&societyids=${payload.society}&paymentmethodids=${
				payload.paymentMethod
			}&donorids=${payload.donors}${payload.noMail ? `&noMail=${payload.noMail}` : ''}${
				payload.excludeFromParishList ? `&excludeFromParishList=${payload.excludeFromParishList}` : ''
			}${payload.returnMail ? `&returnMail=${payload.returnMail}` : ''}${
				payload.deceased ? `&deceased=${payload.deceased}` : ''
			}${payload.isCommunicationNone ? `&isCommunicationNone=${payload.isCommunicationNone}` : ''}${
				payload.magazine ? `&magazine=${payload.magazine}` : ''
			}${payload.faithPropagation ? `&faithPropagation=${payload.faithPropagation}` : ''}${
				payload.stPeter ? `&stPeter=${payload.stPeter}` : ''
			}${payload.holyChildhood ? `&holyChildhood=${payload.holyChildhood}` : ''}${
				payload.noFollowAppeal ? `&noFollowAppeal=${payload.noFollowAppeal}` : ''
			}${payload.isPrefSocietyNone ? `&isPrefSocietyNone=${payload.isPrefSocietyNone}` : ''}`);

			const url = window.URL.createObjectURL(new Blob([response?.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Donor_Export_${moment().format('yyMM')}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			return true;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'Exporting failed',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};
	const getDonorReportPDF = async (payload: DonorReportPayload): Promise<boolean> => {
		try {
			const response = await api.get(`report/donor-export-pdf?start=${payload.start}
			&end=${payload.end}&isdetailed=${payload.isdetailed}${
				payload.minAmount ? `&minAmount=${payload.minAmount}` : ''
			} 
			${payload.maxAmount ? `&maxAmount=${payload.maxAmount}` : ''}
			&receiptfrequencyids=${payload.receiptFrequency}
			&donationtypeids=${payload.donationType}&societyids=${payload.society}&paymentmethodids=${
				payload.paymentMethod
			}&donorids=${payload.donors}${payload.noMail ? `&noMail=${payload.noMail}` : ''}${
				payload.excludeFromParishList ? `&excludeFromParishList=${payload.excludeFromParishList}` : ''
			}${payload.returnMail ? `&returnMail=${payload.returnMail}` : ''}${
				payload.deceased ? `&deceased=${payload.deceased}` : ''
			}${payload.isCommunicationNone ? `&isCommunicationNone=${payload.isCommunicationNone}` : ''}${
				payload.magazine ? `&magazine=${payload.magazine}` : ''
			}${payload.faithPropagation ? `&faithPropagation=${payload.faithPropagation}` : ''}${
				payload.stPeter ? `&stPeter=${payload.stPeter}` : ''
			}${payload.holyChildhood ? `&holyChildhood=${payload.holyChildhood}` : ''}${
				payload.noFollowAppeal ? `&noFollowAppeal=${payload.noFollowAppeal}` : ''
			}${payload.isPrefSocietyNone ? `&isPrefSocietyNone=${payload.isPrefSocietyNone}` : ''}`);

			if (response?.data?.statusCode === 0 && response?.data?.result?.id) {
				setPendingFiles((prev) => [...prev, response?.data?.result?.id]);
				setSimpleErrorSnack({
					message: `Download is in progress, we will inform you when it is ready`,
					severity: 'info',
					show: true,
				});
				return true;
			}
			return true;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'PDF Download failed',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};
	const exportConvergeCSV = async (start: string, end: string): Promise<boolean> => {
		try {
			const response = await api.get(`report/cc-transactions?start=${start}&end=${end}`);

			const url = window.URL.createObjectURL(new Blob([response?.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Converge_Export_${moment().format('yyMM')}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			return true;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'Exporting failed',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};
	const exportMisceCsv = async (
		selectionCriteria: string,
		start?: string,
		end?: string,
	): Promise<boolean> => {
		try {
			const response = await api.get(
				`report/annuitant-report-csv?${start ? `&startDate=${start}` : ''}${
					end ? `&endDate=${end}` : ''
				}&${selectionCriteria}=true`,
			);

			const url = window.URL.createObjectURL(new Blob([response?.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Annuitant_Report_${moment().format('yyMM')}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			return true;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'Exporting failed',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const getBurseChaliceReport = async ({ payload, setBurseChaliceReportData }: BurseChaliceReportProp) => {
		try {
			setBurseChaliceReportData({
				data: null,
				loading: true,
				error: null,
			});

			const response = await api.get(`report/burseChaliceDonations?start=${payload.start}
			&end=${payload.end}${payload.minAmount ? `&minAmount=${payload.minAmount}` : ''} 
			${payload.maxAmount ? `&maxAmount=${payload.maxAmount}` : ''}
			&donationtypeids=${payload.donationTypeIds}&donorids=${payload.donorIds}
			&statusids=${payload.statusIds}&contributionTypes=${payload.contributionType}`);

			if (
				response?.data?.result &&
				response.data.result.targetAmountGrandTotal === 0 &&
				response.data.result.contributionAmountGrandTotal === 0
			) {
				setSimpleErrorSnack({
					message: 'No record found',
					severity: 'info',
					show: true,
				});
				setBurseChaliceReportData({
					data: null,
					loading: false,
					error: null,
				});
				return;
			}

			if (response?.data?.statusCode === 0) {
				setBurseChaliceReportData({
					data: response?.data?.result,
					loading: false,
					error: null,
				});
			} else {
				setBurseChaliceReportData({
					data: null,
					loading: false,
					error: null,
				});
			}
		} catch (error) {
			setBurseChaliceReportData({
				data: null,
				loading: false,
				error: null,
			});
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
		}
	};
	const getBurseChaliceReportCSV = async (payload: BurseChalicePayload): Promise<boolean> => {
		try {
			setSimpleErrorSnack({
				message: 'Exporting...',
				severity: 'info',
				show: true,
			});
			const response = await api.get(`report/burseChaliceDonations-csv?start=${payload.start}
			&end=${payload.end}${payload.minAmount ? `&minAmount=${payload.minAmount}` : ''} 
			${payload.maxAmount ? `&maxAmount=${payload.maxAmount}` : ''}
			&donationtypeids=${payload.donationTypeIds}&donorids=${payload.donorIds}
			&statusids=${payload.statusIds}&contributionTypes=${payload.contributionType}`);

			setSimpleErrorSnack({
				message: 'Exporting...',
				severity: 'info',
				show: false,
			});

			const url = window.URL.createObjectURL(new Blob([response?.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Burse_Chalice_Export_${moment().format('yyMMDD')}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			return true;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'Exporting failed',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};
	const getBurseChaliceReportPDF = async (payload: BurseChalicePayload): Promise<boolean> => {
		try {
			const response = await api.get(`report/burseChaliceDonations-pdf?start=${payload.start}
			&end=${payload.end}${payload.minAmount ? `&minAmount=${payload.minAmount}` : ''} 
			${payload.maxAmount ? `&maxAmount=${payload.maxAmount}` : ''}
			&donationtypeids=${payload.donationTypeIds}&donorids=${payload.donorIds}
			&statusids=${payload.statusIds}&contributionTypes=${payload.contributionType}`);

			if (response?.data?.statusCode === 0 && response?.data?.result?.id) {
				setPendingFiles((prev) => [...prev, response?.data?.result?.id]);
				setSimpleErrorSnack({
					message: `Download is in progress, we will inform you when it is ready`,
					severity: 'info',
					show: true,
				});
				return true;
			}
			return true;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'PDF download failed',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const getAnnuitiesReport = async ({ payload, setAnnuitiesReportData }: AnnuitiesReportProp) => {
		try {
			setAnnuitiesReportData({
				data: null,
				loading: true,
				error: null,
			});

			const response = await api.get(`report/agreement?startDate=${payload.startDate}&endDate=${
				payload.endDate
			}${payload.agreementTypeIds ? `&agreementTypeIds=${payload.agreementTypeIds}` : ''} ${
				payload.frequencyIds ? `&frequencyIds=${payload.frequencyIds}` : ''
			}${payload.paymentMethodIds ? `&paymentMethodIds=${payload.paymentMethodIds}` : ''}${
				payload.provinceIds ? `&provinceIds=${payload.provinceIds}` : ''
			}${payload.beneficiaryIds ? `&beneficiaryIds=${payload.beneficiaryIds}` : ''}${
				payload.donorIds ? `&donorIds=${payload.donorIds}` : ''
			}${payload.minReceivedAmount ? `&minReceivedAmount=${payload.minReceivedAmount}` : ''}${
				payload.maxReceivedAmount ? `&maxReceivedAmount=${payload.maxReceivedAmount}` : ''
			}${payload.minOverPaymentAmount ? `&minOverPaymentAmount=${payload.minOverPaymentAmount}` : ''}${
				payload.maxOverPaymentAmount ? `&maxOverPaymentAmount=${payload.maxOverPaymentAmount}` : ''
			}
			`);
			if (response?.data?.result && response.data.result.length === 0) {
				setSimpleErrorSnack({
					message: 'No record found',
					severity: 'info',
					show: true,
				});
				setAnnuitiesReportData({
					data: null,
					loading: false,
					error: null,
				});
				return;
			}

			if (response?.data?.statusCode === 0) {
				setAnnuitiesReportData({
					data: response?.data?.result,
					loading: false,
					error: null,
				});
			} else {
				setAnnuitiesReportData({
					data: null,
					loading: false,
					error: null,
				});
			}
		} catch (error) {
			setAnnuitiesReportData({
				data: null,
				loading: false,
				error: null,
			});
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
		}
	};
	const exportAgreementCSV = async (payload: AnnuitiesPayload) => {
		try {
			const response = await api.get(`report/agreement-csv?startDate=${payload.startDate}&endDate=${
				payload.endDate
			}${payload.agreementTypeIds ? `&agreementTypeIds=${payload.agreementTypeIds}` : ''} ${
				payload.frequencyIds ? `&frequencyIds=${payload.frequencyIds}` : ''
			}${payload.paymentMethodIds ? `&paymentMethodIds=${payload.paymentMethodIds}` : ''}${
				payload.provinceIds ? `&provinceIds=${payload.provinceIds}` : ''
			}${payload.beneficiaryIds ? `&beneficiaryIds=${payload.beneficiaryIds}` : ''}${
				payload.donorIds ? `&donorIds=${payload.donorIds}` : ''
			}${payload.minReceivedAmount ? `&minReceivedAmount=${payload.minReceivedAmount}` : ``}${
				payload.maxReceivedAmount ? `&maxReceivedAmount=${payload.maxReceivedAmount}` : ''
			}${payload.minOverPaymentAmount ? `&minOverPaymentAmount=${payload.minOverPaymentAmount}` : ''}${
				payload.maxOverPaymentAmount ? `&maxOverPaymentAmount=${payload.maxOverPaymentAmount}` : ''
			}
			`);
			const url = window.URL.createObjectURL(new Blob([response?.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Agreement_Report_${moment().format('yyMM')}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			return true;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'Exporting failed',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};
	const downloadAgreementPDF = async (payload: AnnuitiesPayload) => {
		try {
			const response = await api.get(`report/agreement-pdf?startDate=${payload.startDate}&endDate=${
				payload.endDate
			}${payload.agreementTypeIds ? `&agreementTypeIds=${payload.agreementTypeIds}` : ''} ${
				payload.frequencyIds ? `&frequencyIds=${payload.frequencyIds}` : ''
			}${payload.paymentMethodIds ? `&paymentMethodIds=${payload.paymentMethodIds}` : ''}${
				payload.provinceIds ? `&provinceIds=${payload.provinceIds}` : ''
			}${payload.beneficiaryIds ? `&beneficiaryIds=${payload.beneficiaryIds}` : ''}${
				payload.donorIds ? `&donorIds=${payload.donorIds}` : ''
			}${payload.minReceivedAmount ? `&minReceivedAmount=${payload.minReceivedAmount}` : ``}${
				payload.maxReceivedAmount ? `&maxReceivedAmount=${payload.maxReceivedAmount}` : ''
			}${payload.minOverPaymentAmount ? `&minOverPaymentAmount=${payload.minOverPaymentAmount}` : ''}${
				payload.maxOverPaymentAmount ? `&maxOverPaymentAmount=${payload.maxOverPaymentAmount}` : ''
			}
			`);
			if (response?.data?.statusCode === 0 && response?.data?.result?.id) {
				setPendingFiles((prev) => [...prev, response?.data?.result?.id]);
				setSimpleErrorSnack({
					message: `Download is in progress, we will inform you when it is ready`,
					severity: 'info',
					show: true,
				});
				return true;
			}
			return true;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'PDF Download failed',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};
	const getNonCashDonationsPdf = async (payload: NonCashDonationsReportPayload) => {
		try {
			const response = await api.get(
				`/report/nonCashDonations-pdf?start=${payload.start}&end=${payload.end}&groupby=${
					payload.groupby
				}&category=${payload.category}&isdetailed=${payload.isdetailed}${
					payload.donationtypeids ? `&donationtypeids=${payload.donationtypeids}` : ''
				}${payload.gifttypeids ? `&gifttypeids=${payload.gifttypeids}` : ''}${
					payload.societyids ? `&societyids=${payload.societyids}` : ''
				}`,
			);
			if (response?.data?.statusCode === 0 && response?.data?.result?.id) {
				setPendingFiles((prev) => [...prev, response?.data?.result?.id]);
				setSimpleErrorSnack({
					message: `Download is in progress, we will inform you when it is ready`,
					severity: 'info',
					show: true,
				});
				return true;
			}
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSimpleErrorSnack({
					message: 'PDF Download failed',
					show: true,
					severity: 'error',
				});
			}
		}
	};
	const getNonCashDonationsReport = async ({
		payload,
		nonCashDonationsReport,
		setNonCashDonationReport,
	}: GetNonCashDonationsReportProps) => {
		try {
			setNonCashDonationReport({
				...nonCashDonationsReport,
				loading: true,
			});
			const response = await api.get(
				`/report/nonCashDonations?start=${payload.start}&end=${payload.end}&groupby=${
					payload.groupby
				}&category=${payload.category}&isdetailed=${payload.isdetailed}${
					payload.donationtypeids ? `&donationtypeids=${payload.donationtypeids}` : ''
				}${payload.gifttypeids ? `&gifttypeids=${payload.gifttypeids}` : ''}${
					payload.societyids ? `&societyids=${payload.societyids}` : ''
				}`,
			);
			if (response?.data?.result && response.data.result.length === 0) {
				setSimpleErrorSnack({
					message: 'No record found',
					severity: 'info',
					show: true,
				});
				setNonCashDonationReport({
					data: null,
					loading: false,
					error: null,
				});
				return;
			}
			setNonCashDonationReport({
				...nonCashDonationsReport,
				data: response?.data?.result,
				loading: false,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSimpleErrorSnack({
					message: 'An error occurred',
					show: true,
					severity: 'error',
				});
				setNonCashDonationReport({
					data: null,
					loading: false,
					error: null,
				});
			}
		}
	};
	const getDeathBenefitReport = async ({ deathBenefit, setDeathBenefit, id }: DeathBenefitProps) => {
		try {
			setDeathBenefit({
				...deathBenefit,
				loading: true,
			});
			const response = await api.get(`/report/annuitant-death-benefit/${id}`);
			if (response?.data?.result && response.data.result?.aggreements?.length === 0) {
				setSimpleErrorSnack({
					message: 'No record found',
					severity: 'info',
					show: true,
				});
				setDeathBenefit({
					data: null,
					loading: false,
					error: null,
				});
				return;
			}
			setDeathBenefit({
				...deathBenefit,
				data: response?.data?.result,
				loading: false,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSimpleErrorSnack({
					message: 'An error occurred',
					show: true,
					severity: 'error',
				});
				setDeathBenefit({
					data: null,
					loading: false,
					error: null,
				});
			}
		}
	};
	const getPaymentList = async ({ paymentList, setPaymentList }: PaymentListProps) => {
		try {
			setPaymentList({
				...paymentList,
				loading: true,
			});
			const response = await api.get(`/report/annuity-payment-list`);
			if (response?.data?.result && response.data.result?.payments?.length === 0) {
				setSimpleErrorSnack({
					message: 'No record found',
					severity: 'info',
					show: true,
				});
				setPaymentList({
					data: null,
					loading: false,
					error: null,
				});
				return;
			}
			setPaymentList({
				...paymentList,
				data: response?.data?.result?.payments,
				loading: false,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSimpleErrorSnack({
					message: 'An error occurred',
					show: true,
					severity: 'error',
				});
				setPaymentList({
					data: null,
					loading: false,
					error: null,
				});
			}
		}
	};
	const getPaymentListFilter = async ({
		paymentList,
		setPaymentList,
		month,
		year,
	}: PaymentListFilterProps) => {
		try {
			setPaymentList({
				...paymentList,
				loading: true,
			});
			const response = await api.get(`/report/annuity-payment-list-dateFilter?year=${year}&month=${month}`);
			if (response?.data?.result && response.data.result?.payments?.length === 0) {
				setSimpleErrorSnack({
					message: 'No record found',
					severity: 'info',
					show: true,
				});
				setPaymentList({
					data: null,
					loading: false,
					error: null,
				});
				return;
			}
			setPaymentList({
				...paymentList,
				data: response?.data?.result?.payments,
				loading: false,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSimpleErrorSnack({
					message: 'An error occurred',
					show: true,
					severity: 'error',
				});
				setPaymentList({
					data: null,
					loading: false,
					error: null,
				});
			}
		}
	};
	const getPaymentListFilterPDF = async ({ month, year }: PaymentListFilterPdfProps) => {
		try {
			const response = await api.get(
				`/report/annuity-payment-list-dateFilter-pdf?year=${year}&month=${month}`,
			);
			if (response?.data?.statusCode === 0 && response?.data?.result?.id) {
				setPendingFiles((prev) => [...prev, response?.data?.result?.id]);
				setSimpleErrorSnack({
					message: `Download is in progress, we will inform you when it is ready`,
					severity: 'info',
					show: true,
				});
				return true;
			}
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSimpleErrorSnack({
					message: 'PDF download failed',
					show: true,
					severity: 'error',
				});
			}
		}
	};

	const getResidual = async ({ residual, setResidual, month, year }: ResidualPostingProps) => {
		try {
			setResidual({
				...residual,
				loading: true,
			});
			const response = await api.get(
				`/report/annuity-final-posting-list${year || month ? '?' : ''}${year ? `&year=${year}` : ''}${
					month ? `&month=${month}` : ''
				}`,
			);
			if (response?.data?.result && response.data.result?.details?.length === 0) {
				setSimpleErrorSnack({
					message: 'No record found',
					severity: 'info',
					show: true,
				});
				setResidual({
					data: null,
					loading: false,
					error: null,
				});
				return;
			}
			setResidual({
				...residual,
				data: response?.data?.result,
				loading: false,
				error: null,
			});
		} catch (err: any) {
			if (err.response?.data?.StatusCode === 738) {
				setSimpleErrorSnack({
					message: 'No posting has been done for the selected date',
					severity: 'error',
					show: true,
				});
				setResidual({
					...residual,
					loading: false,
				});
				return false;
			}

			if (Axios.isAxiosError(err)) {
				setSimpleErrorSnack({
					message: 'An error occurred',
					show: true,
					severity: 'error',
				});
				setResidual({
					data: null,
					loading: false,
					error: null,
				});
			}
		}
	};

	const getResidualCSV = async (month?: number, year?: number): Promise<boolean> => {
		try {
			const response = await api.get(
				`report/annuity-final-posting-csv${year || month ? '?' : ''}${year ? `&year=${year}` : ''}${
					month ? `&month=${month}` : ''
				}`,
			);

			const url = window.URL.createObjectURL(new Blob([response?.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Residual_Report_Export_${moment().format('yyMM')}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			return true;
		} catch (error: any) {
			if (error.response?.data?.StatusCode === 738) {
				setSimpleErrorSnack({
					message: 'No posting has been done for the selected date',
					severity: 'error',
					show: true,
				});

				return false;
			}
			setSimpleErrorSnack({
				message: 'Exporting failed',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};
	const getResidualPDF = async (month?: number, year?: number): Promise<boolean> => {
		try {
			const response = await api.get(
				`report/annuity-final-posting-list-pdf${year || month ? '?' : ''}${year ? `&year=${year}` : ''}${
					month ? `&month=${month}` : ''
				}`,
			);

			if (response?.data?.statusCode === 0 && response?.data?.result?.id) {
				setPendingFiles((prev) => [...prev, response?.data?.result?.id]);
				setSimpleErrorSnack({
					message: `Download is in progress, we will inform you when it is ready`,
					severity: 'info',
					show: true,
				});
				return true;
			}
			return true;
		} catch (error: any) {
			setSimpleErrorSnack({
				message: 'PDF download failed',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};
	const getSplitDonationsCSV = async (date: string): Promise<boolean> => {
		try {
			const response = await api.get(`report/split-donation-report-csv?Date=${date}`);

			const url = window.URL.createObjectURL(new Blob([response?.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Split_Donation_Export_${moment().format('yyMMDD')}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			return true;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'Exporting failed',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	return {
		getReport,
		getWalkathonReport,
		getDonationsSummaryReport,
		getDonorReport,
		getHCAOrderReport,
		getBurseChaliceReport,
		getNonCashDonationsReport,
		exportCSV,
		exportConvergeCSV,
		getAnnuitiesReport,
		getDonationsDetailedReport,
		exportMisceCsv,
		exportAgreementCSV,
		getDeathBenefitReport,
		getPaymentList,
		getResidual,
		getResidualCSV,
		getResidualPDF,
		getDonationsSummaryCSV,
		getDonationsDetailedCSV,
		getBurseChaliceReportCSV,
		getSplitDonationsCSV,
		getPaymentListFilter,
		downloadAgreementPDF,
		getDonationsDetailedPDF,
		getDonationsSummaryPDF,
		getDonorReportPDF,
		getBurseChaliceReportPDF,
		getNonCashDonationsPdf,
		getWalkathonReportPdf,
		getHCAOrderReportPdf,
		getPaymentListFilterPDF,
	};
};

export default useReportActions;
