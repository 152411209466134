import React, { useEffect, useState } from 'react';
import { Stack, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIosNew, FilterAltOutlined } from '@mui/icons-material';
import DonorReportForm, { FormValues } from './DonorReportForm';
import DonorReportView from './DonorReportView';
import useReportAction from 'hooks/useReportAction';
import { StateType } from 'contexts/intialstates/Types';
import { useStyles } from './DonorReport.style';
import DetailedReportView, { IDonorReportData } from './DetailedReportView';
import moment from 'moment';

const DonorReport = () => {
	const navigate = useNavigate();
	const classes = useStyles();
	const [donorReportData, setDonorReportData] = useState<StateType<IDonorReportData[]>>({
		data: null,
		loading: false,
		error: null,
	});

	const [formData, setFormData] = useState<FormValues>({
		start: '',
		end: '',
		donors: [],
		donationType: [],
		preferredSociety: [],
		receiptFrequency: [],
		society: [],
		paymentMethod: [],
		communication: [],
		isdetailed: false,
	});
	const [isEdit, setIsEdit] = useState(false);
	const [isShowFilter, setIsShowFilter] = useState(true);
	const { getDonorReport } = useReportAction();

	const handleGetDonors = async (formValues: FormValues, isGenerate?: boolean) => {
		const payload = {
			start: moment(formValues?.start).format('YYYY-MM-DD'),
			end: moment(formValues?.end).format('YYYY-MM-DD'),
			minAmount: formValues.minAmount ?? '',
			maxAmount: formValues.maxAmount ?? '',
			receiptFrequency: formValues.receiptFrequency.join(','),
			donationType: formValues.donationType.map((item: any) => item.id).join(','),
			society: formValues.society.join(','),
			paymentMethod: formValues.paymentMethod.join(','),
			donors: formValues.donors.map((item) => item.id).join(','),
			noMail: formValues?.communication?.includes('noMail'),
			excludeFromParishList: formValues?.communication?.includes('excludeFromParishList'),
			returnMail: formValues?.communication?.includes('returnMail'),
			deceased: formValues?.communication?.includes('deceased'),
			isCommunicationNone: formValues?.communication?.includes('isCommunicationNone'),
			magazine: formValues?.magazine as boolean,
			faithPropagation: formValues?.preferredSociety?.includes('faithPropagation'),
			stPeter: formValues?.preferredSociety?.includes('stPeter'),
			holyChildhood: formValues?.preferredSociety?.includes('holyChildhood'),
			noFollowAppeal: formValues?.noFollowAppeal as boolean,
			isPrefSocietyNone: formValues?.preferredSociety?.includes('isPrefSocietyNone'),
			isdetailed: formValues?.isdetailed,
		};
		await getDonorReport({ payload, setDonorReportData });
		if (isGenerate) {
		}
		setFormData(formValues);
	};

	const handleCancel = () => {
		setDonorReportData({
			data: null,
			loading: false,
			error: null,
		});
	};
	useEffect(() => {
		if (donorReportData.data) {
			setIsEdit(true);
		}
	}, [donorReportData.data]);

	const currentReport = () => {
		if (formData?.isdetailed) {
			return !donorReportData.loading && donorReportData?.data?.length ? (
				<DetailedReportView donorReportData={donorReportData?.data || []} formData={formData} />
			) : null;
		} else {
			return !donorReportData.loading && donorReportData?.data?.length ? (
				<DonorReportView donorReportData={donorReportData?.data || []} formData={formData} />
			) : null;
		}
	};
	useEffect(() => {
		if (donorReportData?.data?.length) {
			setIsShowFilter(false);
		} else {
			setIsShowFilter(true);
		}
	}, [donorReportData?.data?.length]);
	return (
		<Stack>
			<Stack direction="row" marginBottom={2} alignItems={'center'} justifyContent="space-between">
				<Stack direction="row" alignItems={'center'} spacing={4}>
					<Stack className={classes.backIconWrapper}>
						<ArrowBackIosNew onClick={() => navigate(-1)} />
					</Stack>
					<Stack>
						<span className={classes.title}>Donors Summary</span>
					</Stack>
				</Stack>
				{isEdit && (
					<IconButton
						sx={{
							background: 'white',
							borderRadius: '6px',
							width: 40,
							height: 30,
							border: '1px solid var(--mainColor)',
						}}
						onClick={() => setIsShowFilter(!isShowFilter)}
					>
						<FilterAltOutlined sx={{ fontSize: 16, color: 'var(--mainColor)' }} />
					</IconButton>
				)}
			</Stack>
			<DonorReportForm
				handleGetDonors={handleGetDonors}
				loading={donorReportData.loading}
				onCancel={handleCancel}
				isEdit={isEdit}
				isShowFilter={isShowFilter}
			/>
			{currentReport()}
		</Stack>
	);
};

export default DonorReport;
