import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Button, Typography } from '@mui/material';
import { Add, Download } from '@mui/icons-material';
import ParishesTable from './ParishesTable';
import ErrorSnack from 'Components/Common/ErrorSnack/ErrorSnack';
import { parishHead, ColumnType } from 'Components/Common/Tables/TableHeaders';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { FilterPayload, OrderType, ParishData, StateType } from 'contexts/intialstates/Types';
import useParishesActions from 'hooks/useParishesActions';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import Archive, { IArchiveStatus } from 'Components/Reusable/Archive';
import { styles } from 'Components/Donors/donors.style';
import { LoadingButton } from '@mui/lab';
import useUserActions from 'hooks/useUserActions';

const Parishes = () => {
	const { handleRoles } = useUserActions();
	const navigate = useNavigate();
	const { getParishes, exportParishCSV } = useParishesActions();
	const [parishes, setParishes] = React.useState<StateType<ParishData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [parishExport, setParishExport] = React.useState<StateType<ParishData>>({
		data: null,
		error: null,
		loading: false,
	});

	const [archive, setArchive] = useState<IArchiveStatus>({
		label: 'All',
		filter: '',
	});
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [reload, setReload] = React.useState(false);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);

	React.useEffect(() => {
		getParishes({
			parishes,
			setParishes,
			page,
			pageSize,
			orderBy,
			filterPayload,
			archive: archive.filter,
		});
	}, [page, pageSize, orderBy, reload, archive]);
	const handleExport = () => {
		exportParishCSV({
			parishExport,
			setParishExport,
			pageSize,
			page,
			orderBy,
			filterPayload,
			archive: archive.filter,
		});
	};
	return (
		<div>
			<Stack>
				<Stack
					alignItems="center"
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent={'space-between'}
					spacing={1}
					mb={5}
				>
					<Stack direction={'column'} spacing={3}>
						<Stack direction="column">
							<Stack direction="row" spacing={4} alignItems="center">
								<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
									Parishes
								</Typography>
							</Stack>
							<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
								Add and manage Parishes here
							</Typography>
						</Stack>
						<Stack direction="row" spacing={2}>
							<Archive archive={archive} setArchive={setArchive} />
							<LoadingButton
								loading={parishExport.loading}
								loadingPosition="start"
								onClick={handleExport}
								startIcon={<Download />}
								variant="outlined"
								sx={styles.csvBtn}
							>
								Download CSV
							</LoadingButton>
						</Stack>
					</Stack>
					<Stack sx={{ mb: 3 }} direction="row" alignItems={'center'} justifyContent="flex-end">
						<TrimTables name="parishHead" list={parishHead} trimList={trimList} setTrimList={setTrimList} />
						{handleRoles('admin-actions') && (
							<Button
								onClick={() => navigate(`/admin/create-edit-parish`)}
								sx={{ textTransform: 'none', mr: 2 }}
								startIcon={<Add />}
								variant="contained"
							>
								Add New Parish
							</Button>
						)}
						<FilterListButton
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							setIsReload={setReload}
							reload={reload}
							setPage={setPage}
							page={page}
						/>
					</Stack>
				</Stack>
				<ParishesTable
					trimList={trimList}
					setTrimList={setTrimList}
					page={page}
					pageSize={pageSize}
					setPage={setPage}
					setPageSize={setPageSize}
					label={label}
					orderType={orderType}
					setLabel={setLabel}
					setOderBy={setOderBy}
					setOrderType={setOrderType}
					parishes={parishes}
					setFilterPayload={setFilterPayload}
					filterPayload={filterPayload}
					reload={reload}
					setReload={setReload}
				/>
			</Stack>
			<ErrorSnack />
		</div>
	);
};

export default Parishes;
