import React, { useCallback, useMemo } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, Slate, useSlate, ReactEditor } from 'slate-react';
import { createEditor, Editor, Transforms, BaseEditor } from 'slate';
import { withHistory } from 'slate-history';

import ToggleButton from '@mui/lab/ToggleButton';
import { Divider, Box, Stack } from '@mui/material';
import { FormatBold, FormatItalic, FormatUnderlined, FormatQuote } from '@mui/icons-material';

type CustomElement = { type: 'paragraph'; children: CustomText[] };
type CustomText = { text: string };

declare module 'slate' {
	interface CustomTypes {
		Editor: BaseEditor & ReactEditor;
		Element: CustomElement;
		Text: CustomText;
	}
}

const HOTKEYS: any = {
	'mod+b': 'bold',
	'mod+i': 'italic',
	'mod+u': 'underline',
};

const RichEditor = ({ value, setValue, updateFormValue, formDataValue, error }: any) => {
	const renderElement = useCallback((props) => <Element {...props} />, []);
	const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
	const editor = useMemo(() => withHistory(withReact(createEditor())), []);
	return (
		<Box
			sx={{ width: '100%', p: 3, border: `${error ? '1px solid red' : '1px solid black'}` }}
			borderRadius={1}
		>
			<Slate
				editor={editor}
				value={value}
				onChange={(value) => {
					setValue(value);
					updateFormValue('text')(value);
				}}
			>
				<Toolbar>
					<Stack direction="row" justifyContent="flex-start" alignItems="center" flexWrap="wrap">
						<MarkButton format="bold">
							<FormatBold />
						</MarkButton>
						<MarkButton format="italic">
							<FormatItalic />
						</MarkButton>
						<MarkButton format="underline">
							<FormatUnderlined />
						</MarkButton>
						<BlockButton format="block-quote">
							<FormatQuote />
						</BlockButton>
					</Stack>
					<span>{error && 'Maximum length exceeded'}</span>
				</Toolbar>
				<Box pl={1} mt={2}>
					<Editable
						renderElement={renderElement}
						renderLeaf={renderLeaf}
						placeholder="Text goes in here..."
						spellCheck
						onKeyDown={(event) => {
							for (const hotkey in HOTKEYS) {
								if (isHotkey(hotkey, event)) {
									event.preventDefault();
									const mark: any = HOTKEYS[hotkey];
									toggleMark(editor, mark);
								}
							}
						}}
					/>
				</Box>
			</Slate>
		</Box>
	);
};

export const Element = ({ attributes, children, element }: any) => {
	switch (element.type) {
		case 'block-quote':
			return <blockquote {...attributes}>{children}</blockquote>;
		case 'bulleted-list':
			return <ul {...attributes}>{children}</ul>;
		case 'heading-one':
			return <h1 {...attributes}>{children}</h1>;
		case 'heading-two':
			return <h2 {...attributes}>{children}</h2>;
		case 'list-item':
			return <li {...attributes}>{children}</li>;
		case 'numbered-list':
			return <ol {...attributes}>{children}</ol>;
		default:
			return <p {...attributes}>{children}</p>;
	}
};

export const Leaf = ({ attributes, children, leaf }: any) => {
	if (leaf.bold) {
		children = <strong>{children}</strong>;
	}

	if (leaf.code) {
		children = <code>{children}</code>;
	}

	if (leaf.italic) {
		children = <em>{children}</em>;
	}

	if (leaf.underline) {
		children = <u>{children}</u>;
	}

	return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, children }: any) => {
	const editor = useSlate();
	return (
		<Box ml={1} mt={1}>
			<ToggleButton
				value={format}
				selected={isBlockActive(editor, format)}
				onMouseDown={(event) => {
					event.preventDefault();
					toggleBlock(editor, format);
				}}
				style={{ lineHeight: 1 }}
			>
				{children}
			</ToggleButton>
		</Box>
	);
};

const MarkButton = ({ format, children }: any) => {
	const editor = useSlate();
	return (
		<Box ml={1} mt={1}>
			<ToggleButton
				value={format}
				selected={isMarkActive(editor, format)}
				onMouseDown={(event) => {
					event.preventDefault();
					toggleMark(editor, format);
				}}
				style={{ lineHeight: 1 }}
			>
				{children}
			</ToggleButton>
		</Box>
	);
};

const Menu = React.forwardRef(({ children, ...props }, ref) => (
	<>
		<Stack direction="row" justifyContent="space-between">
			{children}
		</Stack>
		<Box pt={2}>
			<Divider variant="middle" />
		</Box>
	</>
));

const Toolbar = React.forwardRef(({ className, ...props }: any, ref) => <Menu {...props} ref={ref} />);

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const isBlockActive = (editor: any, format: any) => {
	const [match]: any = Editor.nodes(editor, {
		match: (n: any) => n.type === format,
	});
	return !!match;
};

const isMarkActive = (editor: any, format: any) => {
	const marks: any = Editor.marks(editor);
	return marks ? marks[format] === true : false;
};

const toggleBlock = (editor: any, format: any) => {
	const isActive = isBlockActive(editor, format);
	const isList = LIST_TYPES.includes(format);

	Transforms.unwrapNodes(editor, {
		match: (n: any) => LIST_TYPES.includes(n.type),
		split: true,
	});

	Transforms.setNodes(editor, {
		type: isActive ? 'paragraph' : isList ? 'list-item' : format,
	});

	if (!isActive && isList) {
		const block = { type: format, children: [] };
		Transforms.wrapNodes(editor, block);
	}
};

const toggleMark = (editor: any, format: any) => {
	const isActive = isMarkActive(editor, format);

	if (isActive) {
		Editor.removeMark(editor, format);
	} else {
		Editor.addMark(editor, format, true);
	}
};

export default RichEditor;
