import React, { useEffect, useRef, useState } from 'react';
import { Stack, Table, Box, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { formatCurrencyFunc } from 'helpers/FormatCurrency';
import { ResidualPostingReport } from 'hooks/useReportAction';
import { StateType } from 'contexts/intialstates/Types';
import Logo from 'assets/images/logo.png';
import { useReactToPrint } from 'react-to-print';
import { useStyles } from '../style';
import useAnnuitiesActions, { IPostingPeriods } from 'hooks/useAnnuitiesActions';
import Report from 'Components/Reports/Report';

interface Props {
	residual: StateType<ResidualPostingReport>;
	setResidual?: React.Dispatch<React.SetStateAction<StateType<ResidualPostingReport>>>;
	handleGetReportCSV: () => void;
	handleGetReportPDF: () => void;
}

const CurrentResidual = ({ residual, handleGetReportCSV, handleGetReportPDF }: Props) => {
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	const { getPostingPeriods } = useAnnuitiesActions();
	const classes = useStyles();
	const componentRef = useRef(null);
	const [annuitiesPostingPeriods, setAnnuitiesPostingPeriods] = useState<StateType<IPostingPeriods>>({
		loading: false,
		error: null,
		data: null,
	});

	const splitter = () => {
		if (!residual.data?.details.length) return;
		const newResidual: any = [];
		const data = [];
		residual.data?.details?.map((row) => {
			newResidual.push({
				agreementNumber: row.agreementNumber || '-',
				annuitant: row.annuitant || '-',
				sin: row.sin || '-',
				residualBegining: row.residualBegining || '-',
				newGift: row.newGift || '-',
				annuityPayment: row.annuityPayment || '-',
				expense: row.expense || '-',
				interestCredit: row.interestCredit || '-',
				residualEnd: row.residualEnd || '-',
			});
		});
		for (let i = 0; i < newResidual.length; i += 15) {
			data.push(newResidual.slice(i, i + 15));
		}
		return data;
	};

	const printData = () => {
		const splitData = splitter();
		return (
			<TableContainer>
				{splitData?.map((data, index) => {
					return (
						<div className={classes.pageBreak} key={index}>
							<Table sx={{ minWidth: 700 }} key={index} aria-label="spanning table">
								<TableHead className={classes.tableHead1}>
									<TableRow sx={{ borderBottom: 'none' }}>
										<TableCell align="center"></TableCell>
										<TableCell align="center"></TableCell>
										<TableCell align="center"></TableCell>
										<TableCell align="center">Residual Amount </TableCell>
										<TableCell align="center" colSpan={4}>
											<Box sx={{ width: '100%', borderBottom: '1px solid black', p: 0, m: 0 }}>
												During Month
											</Box>
										</TableCell>
										<TableCell align="center">Residual Amount</TableCell>
									</TableRow>
								</TableHead>
								<TableHead className={classes.tableHeadx}>
									<TableRow>
										<TableCell align="center">Agreement #</TableCell>
										<TableCell align="center">Name of Annuitant</TableCell>
										<TableCell align="center">S.I.N </TableCell>
										<TableCell align="center">Begining of Month </TableCell>
										<TableCell align="center">New Gift</TableCell>
										<TableCell align="center">Annuity Payment</TableCell>
										<TableCell align="center">Expense</TableCell>
										<TableCell align="center">Interest credit</TableCell>
										<TableCell align="center">End of Month</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map((row: any, index: number) => (
										<>
											{
												<TableRow key={index} className={classes.tableRowWrap}>
													<TableCell align="center">{row?.agreementNumber || '-'}</TableCell>
													<TableCell align="center">{row?.annuitant || '-'}</TableCell>
													<TableCell align="center">{row?.sin || '-'}</TableCell>
													<TableCell align="center">{row?.residualBegining || '-'}</TableCell>
													<TableCell align="center">{row?.newGift || '-'}</TableCell>
													<TableCell align="center">{row?.annuityPayment || '-'}</TableCell>
													<TableCell align="center">{row?.expense || '-'}</TableCell>
													<TableCell align="center">{row?.interestCredit || '-'}</TableCell>
													<TableCell align="center">{row?.residualEnd || '-'}</TableCell>
												</TableRow>
											}
										</>
									))}
									{index === splitData.length - 1 && (
										<TableRow className={classes.tableTotal}>
											<TableCell align="center"></TableCell>
											<TableCell align="center"></TableCell>
											<TableCell align="center"></TableCell>
											<TableCell align="center">
												<Box sx={{ borderBottom: '2px solid black', pb: 0.5 }}>
													<Box sx={{ borderBottom: '2px solid black' }}>
														{formatCurrencyFunc(
															residual.data?.details.reduce(
																(total, curr) => curr.residualBegining + total,
																0,
															),
														) || '-'}
													</Box>
												</Box>
											</TableCell>
											<TableCell align="center"></TableCell>
											<TableCell align="center">
												<Box sx={{ borderBottom: '2px solid black', pb: 0.5 }}>
													<Box sx={{ borderBottom: '2px solid black' }}>
														{formatCurrencyFunc(
															residual.data?.details.reduce((total, curr) => curr.annuityPayment + total, 0),
														) || '-'}
													</Box>
												</Box>
											</TableCell>
											<TableCell align="center"></TableCell>
											<TableCell align="center">
												<Box sx={{ borderBottom: '2px solid black', pb: 0.5 }}>
													<Box sx={{ borderBottom: '2px solid black' }}>
														{formatCurrencyFunc(
															residual.data?.details.reduce((total, curr) => curr.interestCredit + total, 0),
														) || '-'}
													</Box>
												</Box>
											</TableCell>
											<TableCell align="center">
												<Box sx={{ borderBottom: '2px solid black', pb: 0.5 }}>
													<Box sx={{ borderBottom: '2px solid black' }}>
														{formatCurrencyFunc(
															residual.data?.details.reduce((total, curr) => curr.residualEnd + total, 0),
														) || '-'}
													</Box>
												</Box>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</div>
					);
				})}
			</TableContainer>
		);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	useEffect(() => {
		getPostingPeriods({ annuitiesPostingPeriods, setAnnuitiesPostingPeriods, type: 'monthly' });
	}, []);

	const getReport = async () => {
		if (!residual.data?.details.length) return;
		handlePrint();
	};

	return (
		<div>
			<Report
				title="Residual Posting Report"
				subTitle={`Final Posting of Monthly Annuity Payments/Interest accural for residual amount for the month
				ending ${residual.data?.month ? months[residual.data?.month - 1] : ''}
				${residual.data?.month && ','} ${residual.data?.year || ''}`}
				handlePrint={handleGetReportPDF}
				handleExport={handleGetReportCSV}
			>
				<TableContainer>
					<Table sx={{ minWidth: 700 }} aria-label="spanning table">
						<TableHead className={classes.tableHeadView1}>
							<TableRow sx={{ borderBottom: 'none' }}>
								<TableCell align="center"></TableCell>
								<TableCell align="center"></TableCell>
								<TableCell align="center"></TableCell>
								<TableCell align="center">Residual Amount </TableCell>
								<TableCell align="center" colSpan={4}>
									<Box sx={{ width: '100%', borderBottom: '1px solid black', p: 0, m: 0 }}>During Month</Box>
								</TableCell>
								<TableCell align="center">Residual Amount</TableCell>
							</TableRow>
						</TableHead>
						<TableHead className={classes.tableHeadViewx}>
							<TableRow>
								<TableCell align="center">Agreement #</TableCell>
								<TableCell align="center">Name of Annuitant</TableCell>
								<TableCell align="center">S.I.N </TableCell>
								<TableCell align="center">Begining of Month </TableCell>
								<TableCell align="center">New Gift</TableCell>
								<TableCell align="center">Annuity Payment</TableCell>
								<TableCell align="center">Expense</TableCell>
								<TableCell align="center">Interest credit</TableCell>
								<TableCell align="center">End of Month</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{residual?.data?.details?.map((row: any, index: number) => (
								<>
									{
										<TableRow key={index}>
											{/* <TableRow key={index} className={classes.tableRowWrap}> */}
											<TableCell align="center">{row?.agreementNumber || '-'}</TableCell>
											<TableCell align="center">{row?.annuitant || '-'}</TableCell>
											<TableCell align="center">{row?.sin || '-'}</TableCell>
											<TableCell align="center">{row?.residualBegining || '-'}</TableCell>
											<TableCell align="center">{row?.newGift || '-'}</TableCell>
											<TableCell align="center">{row?.annuityPayment || '-'}</TableCell>
											<TableCell align="center">{row?.expense || '-'}</TableCell>
											<TableCell align="center">{row?.interestCredit || '-'}</TableCell>
											<TableCell align="center">{row?.residualEnd || '-'}</TableCell>
										</TableRow>
									}
								</>
							))}
							<TableRow className={classes.tableTotalView}>
								<TableCell align="center"></TableCell>
								<TableCell align="center"></TableCell>
								<TableCell align="center"></TableCell>
								<TableCell align="center">
									<Box sx={{ borderBottom: '2px solid #1976d2', pb: 0.25 }}>
										<Box sx={{ borderBottom: '2px solid #1976d2' }}>
											{formatCurrencyFunc(
												residual.data?.details.reduce((total, curr) => curr.residualBegining + total, 0),
											) || '-'}
										</Box>
									</Box>
								</TableCell>
								<TableCell align="center"></TableCell>
								<TableCell align="center">
									<Box sx={{ borderBottom: '2px solid #1976d2', pb: 0.25 }}>
										<Box sx={{ borderBottom: '2px solid #1976d2' }}>
											{formatCurrencyFunc(
												residual.data?.details.reduce((total, curr) => curr.annuityPayment + total, 0),
											) || '-'}
										</Box>
									</Box>
								</TableCell>
								<TableCell align="center"></TableCell>
								<TableCell align="center">
									<Box sx={{ borderBottom: '2px solid #1976d2', pb: 0.25 }}>
										<Box sx={{ borderBottom: '2px solid #1976d2' }}>
											{formatCurrencyFunc(
												residual.data?.details.reduce((total, curr) => curr.interestCredit + total, 0),
											) || '-'}
										</Box>
									</Box>
								</TableCell>
								<TableCell align="center">
									<Box sx={{ borderBottom: '2px solid #1976d2', pb: 0.25 }}>
										<Box sx={{ borderBottom: '2px solid #1976d2' }}>
											{formatCurrencyFunc(
												residual.data?.details.reduce((total, curr) => curr.residualEnd + total, 0),
											) || '-'}
										</Box>
									</Box>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Report>
			<div style={{ display: 'none' }}>
				<div style={{ padding: 16 }} ref={componentRef}>
					<Stack sx={{ mt: 2, px: 1 }}>
						<>
							<Stack width="100%" direction="row">
								<Stack flex={0.5} alignItems="flex-start" direction="column">
									<img src={Logo} alt="logo" width={40} height={50} />
								</Stack>
							</Stack>
							<Stack
								direction="row"
								justifyContent="center"
								sx={{ fontWeight: 600, fontSize: 17, textAlign: 'center' }}
							>
								Final Posting of Monthly Annuity Payments/Interest accural for residual amount for the month
								ending {residual.data?.month ? months[residual.data?.month - 1] : ''}
								{residual.data?.month && ','} {residual.data?.year || ''}
							</Stack>
						</>

						<Box width="100%">{printData()}</Box>
					</Stack>
				</div>
			</div>
		</div>
	);
};

export default CurrentResidual;
