import React, { useState } from 'react';
import { Paper, Tab, Box } from '@mui/material';
import AnnuitiesHeader from './AnnuitiesHeader';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import AnnutitiesOverview from './AnnuitiesOverview';
import PaymentHistory from './PaymentHistroy';
import { StateType } from 'contexts/intialstates/Types';
import { Agreement } from 'hooks/useAnnuitiesActions';

interface P {
	agreement: StateType<Agreement>;
}

const ViewAnnuities = ({ agreement }: P) => {
	const [value, setValue] = useState('overview');

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	return (
		<div>
			<AnnuitiesHeader agreement={agreement} />
			<Paper sx={{ marginTop: '20px', padding: '20px' }}>
				<Box sx={{ width: '100%', typography: 'body1' }}>
					<TabContext value={value}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList onChange={handleChange}>
								<Tab label="Overview" value="overview" />
								<Tab label="Payment History" value="paymentHistory" />
							</TabList>
						</Box>
						<TabPanel value="overview">
							<AnnutitiesOverview agreement={agreement} />
						</TabPanel>
						<TabPanel value="paymentHistory">
							<PaymentHistory id={agreement.data?.agreementId}/>
						</TabPanel>
					</TabContext>
				</Box>
			</Paper>
		</div>
	);
};

export default ViewAnnuities;
