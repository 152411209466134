import React, { useState, useEffect } from 'react';
import { Stack, Box, TextField, Autocomplete, IconButton, InputAdornment } from '@mui/material';
import { DeleteOutline, Search, Add } from '@mui/icons-material';
import useDebounce from 'Components/Reusable/hooks/debounce';
import useDonorAction from 'hooks/useDonorActions';
import { Donor, StateType } from 'contexts/intialstates/Types';
import { ColumnType, donorHead } from 'Components/Common/Tables/TableHeaders';
import { DonorType } from './CreateWalkathon';
import { useStyles } from '../Walkathon.style';
import WalkathonAdvancedSearch, {
	Field,
} from 'Components/Reusable/Modals/AdvancedSearch/AdvancedSearchModal';
import { serachDonorField } from '../../Reusable/Modals/AdvancedSearch/Mock';
import DonorTable from 'Components/Donors/DonorListTable';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { GenerateSingleReceipt } from 'Components/Reusable/GenerateReceipt/GenerateReceipt';
import CreateEditDonor from 'Components/Donors/CreateEditDonorModal';

interface P {
	donorData: DonorType;
	schoolKey: string;
	handleChangeDonor: (
		schoolKey: string,
		donorKey: string,
		control: string,
		value: number,
		name?: string,
	) => void;
	handleDonorDelete: (schoolKey: string, donorKey: string) => void;
	isDisabled: boolean;
}

const WalkathonAccordianContent = ({
	donorData,
	schoolKey,
	handleChangeDonor,
	handleDonorDelete,
	isDisabled,
}: P) => {
	const [page, setPage] = React.useState(0);

	const classes = useStyles();
	const [searchTerm, setSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce(searchTerm, 1000);
	const { getSearchDonors } = useDonorAction();
	const [searchDonors, setSearchDonors] = useState<StateType<Donor[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [visibleSearch, setVisibleSearch] = useState(false);
	const [searchFields, setSearchFields] = useState<Field[]>(serachDonorField);

	const [reload, setReload] = React.useState(false);
	const [filterPayload, setFilterPayload] = React.useState<Array<any>>([]);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const [visibleDonorModal, setVisibleDonorModal] = useState(false);

	useEffect(() => {
		if (!debouncedSearchTerm) return;
		getSearchDonors({ searchDonors, setSearchDonors, searchTerm });
	}, [debouncedSearchTerm]);

	const handleVisibleSearch = () => {
		setVisibleSearch(!visibleSearch);
	};

	const handleSearch = () => {
		const payload: any = searchFields.map(({ key, value }) => {
			if (value) {
				return {
					field: key,
					query: key !== 'phone' ? value : null,
					value: key === 'phone' ? [value] : null,
					from: null,
					to: null,
				};
			}
		});
		setFilterPayload(payload.filter(Boolean));
	};

	const handleSelectRow = (row: any) => {
		handleChangeDonor(schoolKey, donorData.key, 'donorId', row.id, row.firstname);
		handleVisibleSearch();
	};

	return (
		<>
			<Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
				<Stack flexDirection={'row'} alignItems={'center'}>
					<Stack flexDirection={'row'} alignItems={'center'} className={classes.donorNameConetent}>
						<Autocomplete
							freeSolo
							disabled={isDisabled}
							loadingText="loading..."
							loading={searchDonors.loading}
							value={donorData?.donorName ? donorData?.donorName : ''}
							sx={{ width: 200 }}
							onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
								handleChangeDonor(schoolKey, donorData.key, 'donorId', parseInt(val?.id), val?.label);
							}}
							options={(searchDonors?.data || []).map((donor: Donor) => ({
								id: donor.id,
								label: `${donor.firstName ?? ''} ${donor.lastName ?? ''}`,
							}))}
							renderOption={(props, option) => (
								<Box component="li" {...props} key={option.id}>
									{option.label}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label=""
									InputProps={{
										...params.InputProps,
									}}
									onChange={(event: any) => {
										setSearchTerm(event.target.value);
									}}
									size={'small'}
									placeholder={'Search donor'}
									className={isDisabled ? classes.disabledInput : ''}
								/>
							)}
						/>
						<div
							className={isDisabled ? classes.disabledIcon : classes.IconBtn}
							onClick={() => !isDisabled && handleVisibleSearch()}
						>
							<Search />
						</div>
						<div
							className={isDisabled ? classes.disabledIcon : classes.IconBtn}
							onClick={() => !isDisabled && window.open('/donors/create')}
						>
							<Add />
						</div>
					</Stack>
					<Stack className={classes.donorConetent} alignItems={'center'}>
						<TextField
							InputProps={{
								startAdornment: <InputAdornment position="start">$</InputAdornment>,
							}}
							variant="outlined"
							label=""
							value={donorData?.donorAmount ? donorData?.donorAmount : ''}
							onChange={(event: any) => {
								handleChangeDonor(schoolKey, donorData.key, 'donorAmount', event.target.value);
							}}
							size={'small'}
							sx={{ width: 150 }}
							placeholder={'Enter amount'}
							disabled={isDisabled}
							className={isDisabled ? classes.disabledInput : ''}
						/>
					</Stack>
					<Stack className={classes.donorConetent} alignItems={'center'}>
						{donorData?.donorRecieptNumber || '-'}
					</Stack>
				</Stack>
				<Stack flexDirection={'row'} alignItems={'center'}>
					<GenerateSingleReceipt id={donorData?.depositId as number} isDisabled={!donorData?.depositId} />
					<IconButton disabled={isDisabled} onClick={() => handleDonorDelete(schoolKey, donorData.key)}>
						<DeleteOutline />
					</IconButton>
				</Stack>
			</Stack>
			{visibleSearch && (
				<WalkathonAdvancedSearch
					visible={visibleSearch}
					handleClose={handleVisibleSearch}
					field={searchFields}
					setField={setSearchFields}
					title={'Search Donor'}
					handleSearch={handleSearch}
				>
					<>
						<Stack alignItems={'flex-end'}>
							<TrimTables name="donorHead" list={donorHead} trimList={trimList} setTrimList={setTrimList} />
						</Stack>
						<DonorTable
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							reload={reload}
							setReload={setReload}
							trimList={trimList}
							setTrimList={setTrimList}
							handleOpenEdit={handleSelectRow}
							isSearch
							setPage={setPage}
							page={page}
						/>
					</>
				</WalkathonAdvancedSearch>
			)}
			{visibleDonorModal && (
				<CreateEditDonor handleCloseEdit={() => setVisibleDonorModal(false)} visible={visibleDonorModal} />
			)}
		</>
	);
};

export default WalkathonAccordianContent;
