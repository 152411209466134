import React, { useRef } from 'react';
import { convertDate } from 'helpers/dayAndTime';
import FormatCurrency from 'helpers/FormatCurrency';
import Logo from './../../../assets/images/logo.png';
import { currentDate } from 'helpers/dayAndTime';
import { Typography } from '@mui/material';
import Report from '../Report';
import { useStyles } from 'Components/Reports/DonorReports/DonorReport.style';
import { useReactToPrint } from 'react-to-print';
import { Stack, Table, Box, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import useReportActions from 'hooks/useReportAction';
import { FormValues } from './BurseChaliceForm';
import moment from 'moment';

interface BurseChalice {
	contributionType: string;
	status: string;
	startDate: string;
	endDate: string;
	donorName: string;
	targetAmount: number;
	contributionAmount: number;
}

export interface BurseChaliceData {
	burseChaliceList: BurseChalice[];
	contributionAmountGrandTotal: number;
	targetAmountGrandTotal: number;
}

interface P {
	burseChaliceData?: BurseChaliceData;
	start: string;
	end: string;
	formValues: FormValues;
}

const BurseChaliceReportView = ({ burseChaliceData, start, end, formValues }: P) => {
	const classes = useStyles();
	const componentRef = useRef(null);

	const { getBurseChaliceReportCSV, getBurseChaliceReportPDF } = useReportActions();

	const handleExport = async (isPdf?: boolean) => {
		const payload = {
			start: formValues.start ? moment(formValues?.start).format('YYYY-MM-DD') : '',
			end: formValues.end ? moment(formValues?.end).format('YYYY-MM-DD') : '',
			minAmount: formValues.minAmount,
			maxAmount: formValues.maxAmount,
			contributionType: formValues.contributionType.join(','),
			donationTypeIds: formValues.donationType.map((item: any) => item.id).join(','),
			statusIds: formValues.status.join(','),
			donorIds: formValues.donors.map((item: any) => item.id).join(','),
		};
		if (isPdf === true) {
			await getBurseChaliceReportPDF(payload);
		} else {
			await getBurseChaliceReportCSV(payload);
		}
	};

	const splitter = () => {
		if (!burseChaliceData?.burseChaliceList.length) return;
		const burseChalice: any = [];
		const data = [];
		burseChaliceData?.burseChaliceList?.map((row) => {
			burseChalice.push({
				donorName: row.donorName || 'N/A',
				contributionType: row.contributionType || 'N/A',
				status: row.status || 'N/A',
				startDate: row.startDate ? convertDate(row.startDate) : '-',
				endDate: row.endDate ? convertDate(row.startDate) : '-',
				targetAmount: row.targetAmount ? <FormatCurrency value={row.targetAmount} /> : 'N/A',
				contributionAmount: row.contributionAmount ? (
					<FormatCurrency value={row.contributionAmount} />
				) : (
					'N/A'
				),
			});
		});
		for (let i = 0; i < burseChalice.length; i += 12) {
			data.push(burseChalice.slice(i, i + 12));
		}
		return data;
	};

	const printContent = () => (
		<TableContainer>
			<Table sx={{ minWidth: 700 }} aria-label="spanning table">
				<TableHead className={classes.tableHead}>
					<TableRow>
						<TableCell align="center">Donor</TableCell>
						<TableCell align="center">Contribution Type</TableCell>
						<TableCell align="center">Status</TableCell>
						<TableCell align="center">Start Date</TableCell>
						<TableCell align="center">End Date</TableCell>
						<TableCell align="center">Target</TableCell>
						<TableCell align="center">Current Contribution</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{burseChaliceData?.burseChaliceList?.map((row, index) => (
						<TableRow key={index} className={classes.tableRowWrap}>
							<TableCell align="center">{row.donorName}</TableCell>
							<TableCell align="center">{row.contributionType || 'N/A'}</TableCell>
							<TableCell align="center">{row.status || 'N/A'}</TableCell>
							<TableCell align="center">{row.startDate ? convertDate(row.startDate) : '-'}</TableCell>
							<TableCell align="center">{row.endDate ? convertDate(row.startDate) : '-'}</TableCell>
							<TableCell align="center">
								{row.targetAmount ? <FormatCurrency value={row.targetAmount} /> : 'N/A'}
							</TableCell>
							<TableCell align="center">
								{row.contributionAmount ? <FormatCurrency value={row.contributionAmount} /> : 'N/A'}
							</TableCell>
						</TableRow>
					))}
					<TableRow className={classes.tableTotal}>
						<TableCell align="center" colSpan={5}>
							<Stack py={1} sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}>
								<Stack
									direction="row"
									justifyContent={'center'}
									sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
								>
									Grand Total
								</Stack>
							</Stack>
						</TableCell>
						<TableCell colSpan={1} align="center">
							<Stack py={1} sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}>
								<Stack
									direction="row"
									justifyContent={'center'}
									sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
								>
									{burseChaliceData ? <FormatCurrency value={burseChaliceData.targetAmountGrandTotal} /> : 0}
								</Stack>
							</Stack>
						</TableCell>
						<TableCell align="center" colSpan={1}>
							<Stack py={1} sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}>
								<Stack
									direction="row"
									justifyContent={'center'}
									sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
								>
									{burseChaliceData ? (
										<FormatCurrency value={burseChaliceData.contributionAmountGrandTotal} />
									) : (
										0
									)}
								</Stack>
							</Stack>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
	const printData = () => {
		const splitData = splitter();

		return (
			<TableContainer>
				{splitData?.map((data, index) => {
					return (
						<div className={classes.pageBreak} key={index}>
							<Table sx={{ minWidth: 700 }} aria-label="spanning table">
								<TableHead className={classes.tableHead}>
									<TableRow>
										<TableCell align="center">Donor</TableCell>
										<TableCell align="center">Contribution Type</TableCell>
										<TableCell align="center">Status</TableCell>
										<TableCell align="center">Start Date</TableCell>
										<TableCell align="center">End Date</TableCell>
										<TableCell align="center">Target</TableCell>
										<TableCell align="center">Current Contribution</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map((row: any, index: number) => (
										<TableRow key={index} className={classes.tableRowWrap}>
											<TableCell align="center">{row.donorName}</TableCell>
											<TableCell align="center">{row.contributionType}</TableCell>
											<TableCell align="center">{row.status}</TableCell>
											<TableCell align="center">{row.startDate}</TableCell>
											<TableCell align="center">{row.endDate}</TableCell>
											<TableCell align="center">{row.targetAmount}</TableCell>
											<TableCell align="center">{row.contributionAmount}</TableCell>
										</TableRow>
									))}
									{index === splitData.length - 1 && (
										<TableRow className={classes.tableTotal}>
											<TableCell align="center" colSpan={5}>
												<Stack
													py={1}
													sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
												>
													<Stack
														direction="row"
														justifyContent={'center'}
														sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
													>
														Grand Total
													</Stack>
												</Stack>
											</TableCell>
											<TableCell colSpan={1} align="center">
												<Stack
													py={1}
													sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
												>
													<Stack
														direction="row"
														justifyContent={'center'}
														sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
													>
														{burseChaliceData ? (
															<FormatCurrency value={burseChaliceData.targetAmountGrandTotal} />
														) : (
															0
														)}
													</Stack>
												</Stack>
											</TableCell>
											<TableCell align="center" colSpan={1}>
												<Stack
													py={1}
													sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
												>
													<Stack
														direction="row"
														justifyContent={'center'}
														sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
													>
														{burseChaliceData ? (
															<FormatCurrency value={burseChaliceData.contributionAmountGrandTotal} />
														) : (
															0
														)}
													</Stack>
												</Stack>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</div>
					);
				})}
			</TableContainer>
		);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<>
			<Report
				title="Burse Chalice Report"
				handlePrint={() => handleExport(true)}
				subTitle={`From ${convertDate(start)} To ${convertDate(end)}`}
				handleExport={handleExport}
			>
				{printContent()}
			</Report>
			<div style={{ display: 'none' }}>
				<div style={{ padding: 16 }} ref={componentRef}>
					<Stack sx={{ my: 2, px: 1 }}>
						<>
							<Stack width="100%" direction="row">
								<Stack flex={0.5} alignItems="flex-start" direction="column">
									<img src={Logo} alt="logo" width={40} height={50} />
								</Stack>

								<Stack sx={{ mb: 2, textAlign: 'center' }} alignItems="center" direction="column">
									<Typography sx={{ color: '#1976d2', fontSize: 20, fontWeight: 700 }}>
										Burse Chalice Report
									</Typography>
									<Typography sx={{ color: '#445f6f', fontSize: 18, fontWeight: 400 }}>
										{`From ${convertDate(start)} To ${convertDate(end)}`}
									</Typography>
								</Stack>
							</Stack>
							<Stack
								direction={'row'}
								justifyContent="flex-end"
								mb={3}
								sx={{ color: '#445f6f', fontSize: 16, fontWeight: 400 }}
							>
								{currentDate()}
							</Stack>
						</>

						<Box width="100%">{printData()}</Box>
					</Stack>
				</div>
			</div>
		</>
	);
};

export default BurseChaliceReportView;
