import React from 'react';
import Donor from 'Components/Donors/DonorInfo/DonorInfo';
import NotFound from 'Components/NotFound/NotFound';
import { Routes as Switch, Route } from 'react-router-dom';
import { Routes } from 'utils/routes';
import Layout from 'Components/Layout/Layout';
import Donations from 'Components/Donations/Donations';
import DonationsLayout from 'Components/Donations/DonationsLayout';
import Deposit from 'Components/Deposits/Deposit';
import ListOfBatches from 'Components/Batch/ListOfBatches';
import CreateEditBatch from 'Components/Batch/CreateEditBatch';
import CreateEditDepositsWithDonations from 'Components/Deposits/CreateEditDepositWithDonations/CreateEditDepositsWithDonations';
import TemplateDeposit from 'Components/Deposits/TemplateDeposit';
import { TemplateDepositContextProvider } from '../contexts/TemplateDepositContext';
import { GlobalContextsProvider } from 'contexts/GlobalContext';
import { DonorsContextsProvider } from 'contexts/DonorsContext';
import AdminPanel from 'Components/AdminPanel';
import ReceiptTemplateTable from 'Components/AdminPanel/ReceiptTemplateTable';
import Appeal from 'Components/AdminPanel/Appeal';
import CreateEditReceiptTemplate from 'Components/AdminPanel/CreateEditReceiptTemplate';
import RedirectionPayment from 'Components/Common/RedirectionPayment/RedirectionPayment';
import Parishes from 'Components/AdminPanel/Parishes';
import CreateEditParish from 'Components/AdminPanel/Parishes/CreateEditParish';
import Diocese from 'Components/AdminPanel/Diocese';
import CreateEditDiocese from 'Components/AdminPanel/Diocese/CreateEditDiocese';
import Donors from 'Components/Donors';
import Reports from 'Components/Reports';
import YearEndReceipt from 'Components/AdminPanel/YearEndReceipt/YearEndReceipt';
import MergeDonor from 'Components/Donors/MergeDonor/MergeDonor';
import CreateEditSchoolBoard from 'Components/AdminPanel/SchoolBoard/CreatEditSchoolBoard';
import SchoolBoard from 'Components/AdminPanel/SchoolBoard';
import CreateEditSchools from 'Components/AdminPanel/School/CreateEditSchool';
import Schools from 'Components/AdminPanel/School';
import HcaMaterial from 'Components/AdminPanel/HCAMaterials';
import WalkathonList from 'Components/Walkthon/WalkthonList';
import CreateWalkathon from 'Components/Walkthon/CreateWalkathon/CreateWalkathon';
import HCA from 'Components/HCA/HCA';
import CreateEditOrders from 'Components/HCA/Orders/CreateEditOrders';
import Orders from 'Components/HCA/Orders/Index';
import Bookkeeping from 'Components/Bookkeeping';
import SingleDepositLayout from 'Components/Deposits/SingleDepositLayout';
import PostDatedCheques from 'Components/Bookkeeping/PostDatedCheques';
import CreateEditPostDatedCheque from 'Components/Bookkeeping/PostDatedCheques/createEdit';
import DepositSlip from 'Components/DepositSlip/DepositSlipList';
import CreateDepositSlip from 'Components/DepositSlip/CreateDepositSlip/CreateDepositSlip';
import DonationType from 'Components/AdminPanel/DonationType';
import DonorReports from 'Components/Reports/DonorReports';
import AnnuitiesReport from 'Components/Reports/Annuities';
import Configure from 'Components/Reports/Configure';
import GiftType from 'Components/AdminPanel/GiftType';
import NonCashDonations from 'Components/AdminPanel/NonCashDonation';
import CreateEditNonCashDonations from 'Components/AdminPanel/NonCashDonation/CreateEditNonCashDonation';
import UserManageMent from 'Components/AdminPanel/User';
import Notifications from 'Components/Notifications';
import FailedTransactionsNotifications from 'Components/Notifications/FailedTransactions';
import ExpiringCCNotifications from 'Components/Notifications/ExpiringCC';
import PostDatedNotifications from 'Components/Notifications/PostDated';
import BatchNotifications from 'Components/Notifications/Batch';
import BurseChaliceReport from 'Components/Reports/BurseChalice';
import Campaign from 'Components/AdminPanel/Campaign';
import ManageCampaign from 'Components/AdminPanel/Campaign/Manage';
import MissionSundayMaterial from 'Components/MissionSunday/MissionSundayMaterial';
import MissionSundayOrder from 'Components/MissionSunday/MissionSundayOrder';
import CreateEditMissionOrder from 'Components/MissionSunday/MissionSundayOrder/CreateEditMissionOrder';
import HCADonation from 'Components/HCA/Donations';
import MissionSundayReport from 'Components/Reports/MissionSunday';
import Annuities from 'Components/Annuities';
import CreateEditAnnuities from 'Components/Annuities/CreateEdit';
import ConvergeReport from 'Components/Reports/ConvergeReport';
import AnnuitiesModule from 'Components/Annuities/ModuleItems';
import MonthlyPosting from 'Components/Annuities/MonthlyPosting';
import AnnualPosting from 'Components/Annuities/AnnualPosting';
import EFTGenerate from 'Components/Annuities/EFTGenerate';
import LifeExpectancy from 'Components/Annuities/LifeExpectancy';
import InterestRate from 'Components/Annuities/InterestRate';
import PostingLog from 'Components/Annuities/PostingLogs';
import DonationsSummary from 'Components/Reports/DonationsSummary';
import AnnuityMisce from 'Components/Reports/AnnuityMisce';
import Residual from 'Components/Reports/ExtraReports/Residual';
import SplitDonations from 'Components/Reports/ExtraReports/SplitDonations';
import PaymentList from 'Components/Reports/ExtraReports/PaymentList';
import Downloads from 'Components/Downloads';
import ActivityLog from 'Components/AdminPanel/ActivityLog';

const AppRouter = () => {
	return (
		<GlobalContextsProvider>
			<Layout>
				<Switch>
					<Route path={Routes.Donors}>
						<Route
							index
							element={
								<DonorsContextsProvider>
									<Donors />
								</DonorsContextsProvider>
							}
						/>
						<Route path={Routes.DonorsProfile} element={<Donor />} />
						<Route path={Routes.DonorCreate} element={<Donor />} />
						<Route path={Routes.MergeDonor} element={<MergeDonor />} />
					</Route>
					<Route path={Routes.Donations} element={<DonationsLayout />}>
						<Route index element={<Donations />} />
					</Route>

					<Route path={Routes.AdminPanel}>
						<Route index element={<AdminPanel />} />

						<Route path={'receipt-template'}>
							<Route index element={<ReceiptTemplateTable />} />
							<Route path="create" element={<CreateEditReceiptTemplate />} />
							<Route path=":receptId" element={<CreateEditReceiptTemplate />} />
							<Route path="clone/:cloneId" element={<CreateEditReceiptTemplate />} />
						</Route>
						<Route path="appeal" element={<Appeal />} />
						<Route path="donation-type" element={<DonationType />} />
						<Route path="users" element={<UserManageMent />} />
						<Route path="activity-log" element={<ActivityLog />} />
						<Route path="parishes" element={<Parishes />} />
						<Route path="create-edit-parish/:id" element={<CreateEditParish />} />
						<Route path="create-edit-parish" element={<CreateEditParish />} />
						<Route path="diocese" element={<Diocese />} />
						<Route path="diocese/:id" element={<CreateEditDiocese />} />
						<Route path="create-edit-diocese" element={<CreateEditDiocese />} />
						<Route path="gift-type" element={<GiftType />} />
					</Route>
					<Route path={Routes.HCA}>
						<Route index element={<HCA />} />
						<Route path="hca-materials" element={<HcaMaterial />} />
						<Route path="hca-orders" element={<Orders />} />
						<Route path="create-edit-hca-orders/:id" element={<CreateEditOrders />} />
						<Route path="create-edit-hca-orders" element={<CreateEditOrders />} />
						<Route path={Routes.Walkathon} element={<WalkathonList />} />
						<Route path={`${Routes.Walkathon}/create`} element={<CreateWalkathon />} />
						<Route path={`${Routes.Walkathon}/:id`} element={<CreateWalkathon />} />
						<Route path={'hca-donations'}>
							<Route index element={<HCADonation />} />
							<Route path=":id" element={<CreateEditDepositsWithDonations />} />
						</Route>
						<Route path="school-boards" element={<SchoolBoard />} />
						<Route path="create-edit-school-boards/:id" element={<CreateEditSchoolBoard />} />
						<Route path="create-edit-school-boards" element={<CreateEditSchoolBoard />} />
						<Route path="schools" element={<Schools />} />
						<Route path="create-edit-schools/:id" element={<CreateEditSchools />} />
						<Route path="create-edit-schools" element={<CreateEditSchools />} />

						<Route path={Routes.MissionSundayMaterial} element={<MissionSundayMaterial />} />
						<Route path={Routes.MissionSundayOrder}>
							<Route index element={<MissionSundayOrder />} />
							<Route path={'create'} element={<CreateEditMissionOrder />} />
							<Route path={':id'} element={<CreateEditMissionOrder />} />
						</Route>
						<Route path={'campaign'}>
							<Route index element={<Campaign />} />
							<Route path="create" element={<ManageCampaign />} />
							<Route path=":id" element={<ManageCampaign />} />
						</Route>
					</Route>
					<Route path={Routes.Bookkeping}>
						<Route index element={<Bookkeeping />} />
						<Route path={Routes.Batch} element={<ListOfBatches />} />
						<Route path={Routes.NewBatch} element={<CreateEditBatch />} />
						<Route path={Routes.EditBatch} element={<CreateEditBatch />} />
						<Route path={Routes.TemplateDeposits}>
							<Route
								index
								element={
									<TemplateDepositContextProvider>
										<TemplateDeposit />
									</TemplateDepositContextProvider>
								}
							/>
							<Route
								path=":id"
								element={
									<TemplateDepositContextProvider>
										<CreateEditDepositsWithDonations />
									</TemplateDepositContextProvider>
								}
							/>
							<Route
								path=":id/batch/:batchId"
								element={
									<TemplateDepositContextProvider>
										<CreateEditDepositsWithDonations />
									</TemplateDepositContextProvider>
								}
							/>
							<Route
								path=":id/donor/:donorId"
								element={
									<TemplateDepositContextProvider>
										<CreateEditDepositsWithDonations />
									</TemplateDepositContextProvider>
								}
							/>
							<Route
								path="create-template-deposit"
								element={
									<TemplateDepositContextProvider>
										<CreateEditDepositsWithDonations />
									</TemplateDepositContextProvider>
								}
							/>
						</Route>
						<Route path={Routes.Deposits} element={<SingleDepositLayout />}>
							<Route index element={<Deposit />} />
							<Route path=":id" element={<CreateEditDepositsWithDonations />} />
							<Route path=":id/donor/:donorId" element={<CreateEditDepositsWithDonations />} />
							<Route path=":id/batch/:batchId" element={<CreateEditDepositsWithDonations />} />
							<Route path="create/batch/:batchId" element={<CreateEditDepositsWithDonations />} />
							<Route path="create/donor/:donorId" element={<CreateEditDepositsWithDonations />} />
							<Route path="create-deposit" element={<CreateEditDepositsWithDonations />} />
						</Route>
						<Route path={'post-dated-cheques'}>
							<Route index element={<PostDatedCheques />} />
							<Route path=":id" element={<CreateEditPostDatedCheque />} />
							<Route path="create" element={<CreateEditPostDatedCheque />} />
						</Route>
						<Route path={'deposit-slip'}>
							<Route index element={<DepositSlip />} />
							<Route path="create" element={<CreateDepositSlip />} />
							<Route path=":id" element={<CreateDepositSlip />} />
						</Route>

						<Route path="year-end-receipt" element={<YearEndReceipt />} />
						<Route path="non-cash-donations" element={<NonCashDonations />} />
						<Route path="create-non-cash-donation" element={<CreateEditNonCashDonations />} />
						<Route path=":id" element={<CreateEditNonCashDonations />} />
					</Route>
					<Route path={Routes.Reports}>
						<Route index element={<Reports />} />
						<Route path=":reportId" element={<Configure />} />
						<Route path={'annuities-reports'} element={<AnnuitiesReport />} />
						<Route path={'donor-reports'} element={<DonorReports />} />
						<Route path={'burse-chalice-reports'} element={<BurseChaliceReport />} />
						<Route path={'missionsunday-reports'} element={<MissionSundayReport />} />
						<Route path={'converge-reports'} element={<ConvergeReport />} />
						<Route path={'annuity-miscellaneous-reports'} element={<AnnuityMisce />} />
						<Route path={'split-donations-reports'} element={<SplitDonations />} />
						<Route path={'residual-reports'} element={<Residual />} />
						<Route path={'payment-list-reports'} element={<PaymentList />} />
						<Route path={'donations-summary'} element={<DonationsSummary />} />
					</Route>
					<Route path={'notifications'}>
						<Route index element={<Notifications />} />
						<Route path="failed-transactions" element={<FailedTransactionsNotifications />} />
						<Route
							path="pap"
							element={
								<TemplateDepositContextProvider>
									<FailedTransactionsNotifications />
								</TemplateDepositContextProvider>
							}
						/>
						<Route path="expiring-cc" element={<ExpiringCCNotifications />} />
						<Route path="post-dated-cheques" element={<PostDatedNotifications />} />
						<Route path="batch" element={<BatchNotifications />} />
					</Route>
					<Route path={'files'}>
						<Route index element={<Downloads />} />
					</Route>
					<Route path={Routes.Donations} element={<Donations />} />
					<Route element={<NotFound />} />
					<Route path={'/cctransactionStatus'} element={<RedirectionPayment />} />
					<Route path={'/annuities'}>
						<Route index element={<AnnuitiesModule />} />
						<Route path="agreement">
							<Route index element={<Annuities />} />
							<Route path={'create'} element={<CreateEditAnnuities />} />
							<Route path={':id'} element={<CreateEditAnnuities />} />
						</Route>
						<Route path="monthly-posting">
							<Route index element={<MonthlyPosting />} />
							<Route path={':id'} element={<PostingLog />} />
						</Route>
						<Route path="annual-posting">
							<Route index element={<AnnualPosting />} />
							<Route path={':id'} element={<PostingLog />} />
						</Route>
						<Route path="generate-eft" element={<EFTGenerate />} />
						<Route path="life-expectancy-adjustment" element={<LifeExpectancy />} />
						<Route path="interest-rate" element={<InterestRate />} />
					</Route>
				</Switch>
			</Layout>
		</GlobalContextsProvider>
	);
};

export default AppRouter;
