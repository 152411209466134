import React, { useEffect, useState } from 'react';
import {
	Stack,
	Grid,
	TextField,
	Autocomplete,
	Box,
	MenuItem,
	Select,
	Checkbox,
	IconButton,
} from '@mui/material';
import { Search, Delete } from '@mui/icons-material';
import { useStyles } from '../DepositSlip.style';
import useDebounce from 'Components/Reusable/hooks/debounce';
import useDonorAction from 'hooks/useDonorActions';
import { Donor, StateType, DonationTypes } from 'contexts/intialstates/Types';
import AdvancedSearch, { Field } from 'Components/Reusable/Modals/AdvancedSearch/AdvancedSearchModal';
import { serachDonorField } from 'Components/Reusable/Modals/AdvancedSearch/Mock';
import DonorTable from 'Components/Donors/DonorListTable';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { ColumnType, donorHead } from 'Components/Common/Tables/TableHeaders';
import useDonationTypeAction from 'hooks/useDonationTypesActions';
import useDepositSlipActions, { Organization } from 'hooks/useDepositSlipAction';
import OrdersModal from 'Components/HCA/Orders/OrdersModal';

export interface Contribution {
	id?: number;
	donorId?: number;
	donorName: string;
	paymentMethodId?: number;
	paymentMethodName?: string;
	amount: number;
	chequeNumber: string;
	donationTypeId?: number;
	donationTypeName: string;
	appealId?: number;
	isWalkathon: boolean;
	organizationType: string;
	organizationId?: number;
	organizationName: string;
	statusId?: number;
	statusName: string;
	remark: string;
	error?: string[];
}

interface Option {
	id: number;
	label: string;
}

interface P {
	paymentMethods: Option[];
	appealCode: Option[];
	depositSlipStatus: Option[];
	contribution: Contribution;
	handleChangeContribution: (data: {
		[key: string]: string | number | boolean | string[] | undefined;
	}) => void;
	isDisabled: boolean;
	handleDelete: () => void;
}

const Deposists = ({
	paymentMethods,
	appealCode,
	depositSlipStatus,
	contribution,
	handleChangeContribution,
	isDisabled,
	handleDelete,
}: P) => {
	const [page, setPage] = React.useState(0);
	const classes = useStyles();
	const { getDonationTypeBySearch } = useDonationTypeAction();
	const { getSearchDonors } = useDonorAction();
	const { getOrganizationSearch } = useDepositSlipActions();
	const [searchTerm, setSearchTerm] = useState('');
	const [donationTypeSearchTerm, setDonationTypeSearchTerm] = useState('');
	const [organizationSearchTerm, setOrganizationSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce(searchTerm, 1000);
	const debouncedDonationSearchTerm = useDebounce(donationTypeSearchTerm, 1000);
	const debouncedOrganizationSearchTerm = useDebounce(organizationSearchTerm, 1000);
	const [searchDonors, setSearchDonors] = useState<StateType<Donor[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [donationType, setDonationType] = useState<StateType<DonationTypes[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [visibleSearch, setVisibleSearch] = useState(false);
	const [searchFields, setSearchFields] = useState<Field[]>(serachDonorField);
	const [reload, setReload] = useState(false);
	const [filterPayload, setFilterPayload] = useState<Array<any>>([]);
	const [donorSearchParam, setDonorSearchParam] = useState('');
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [open, setOpen] = React.useState<any>({ state: false, type: 'School' });
	const [organization, setOrganization] = useState<Organization[]>([]);

	useEffect(() => {
		if (!open.state && open.data) {
			handleChangeContribution({
				organizationName: open.data.organizationName,
				organizationId: open.data.organizationId,
			});
		}
	}, [open.state]);

	useEffect(() => {
		if (!debouncedSearchTerm) return;
		getSearchDonors({ searchDonors, setSearchDonors, searchTerm });
	}, [debouncedSearchTerm]);

	useEffect(() => {
		if (!debouncedDonationSearchTerm) return;
		getDonationTypeBySearch({ donationType, setDonationType, searchTerm: debouncedDonationSearchTerm });
	}, [debouncedDonationSearchTerm]);

	useEffect(() => {
		if (!debouncedOrganizationSearchTerm) return;
		getOrganizationSearch({
			type: contribution?.organizationType,
			searchTerm: debouncedOrganizationSearchTerm,
			setOrganization,
		});
	}, [debouncedOrganizationSearchTerm]);

	const handleVisibleSearch = () => {
		setVisibleSearch(!visibleSearch);
		setFilterPayload([]);
	};

	const handleSearch = () => {
		const payload: any = searchFields.map(({ key, value }) => {
			if (key === 'name') {
				setDonorSearchParam(value);
				return;
			}
			if (value) {
				return {
					field: key,
					query: key !== 'phone' ? value : null,
					value: key === 'phone' ? [value] : null,
					from: null,
					to: null,
				};
			}
		});
		setFilterPayload(payload.filter(Boolean));
	};

	const handleSelectRow = (row: any) => {
		handleChangeContribution({ donorId: parseInt(row.id), donorName: row.firstname });
		handleVisibleSearch();
	};

	return (
		<>
			<Stack className={classes.formFieldWrap}>
				<IconButton
					className={classes.deleteWrap}
					sx={{
						position: 'absolute !important',
					}}
					onClick={() => handleDelete()}
				>
					<Delete />
				</IconButton>
				<Grid container columnSpacing={10}>
					<Grid item mb={2} className={classes.gridWrap}>
						<span className={classes.formLabelText}>Donor</span>
						<Autocomplete
							freeSolo
							value={contribution?.donorName || ''}
							sx={{ width: 220 }}
							loadingText="loading..."
							loading={searchDonors.loading}
							onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
								handleChangeContribution({ donorId: parseInt(val?.id), donorName: val?.label });
							}}
							options={(searchDonors?.data || []).map((donor: Donor) => ({
								id: donor.id,
								label: `${donor.firstName} ${donor.lastName}`,
							}))}
							renderOption={(props, option) => (
								<Box component="li" {...props} key={option.id}>
									{option.label}
								</Box>
							)}
							disabled={isDisabled}
							className={isDisabled ? classes.disabledInput : ''}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label=""
									InputProps={{
										...params.InputProps,
									}}
									onChange={(event: any) => {
										setSearchTerm(event.target.value);
									}}
									size={'small'}
									placeholder={'Search donor'}
									sx={{ width: 220 }}
								/>
							)}
						/>
						<div
							className={isDisabled ? classes.disabledIcon : classes.IconBtn}
							onClick={() => !isDisabled && handleVisibleSearch()}
						>
							<Search />
						</div>
					</Grid>
					<Grid item mb={2} className={classes.gridWrap}>
						<span className={classes.formLabelText}>Type</span>
						<Stack>
							<Select
								sx={{ minWidth: 220 }}
								size={'small'}
								value={contribution?.paymentMethodId}
								onChange={(e) =>
									handleChangeContribution({
										paymentMethodId: e.target.value,
										paymentMethodName:
											paymentMethods?.find((item) => item.id === e.target.value)?.label ?? '',
										error: contribution?.error?.filter((item) => item !== 'paymentMethodId') || [],
									})
								}
								disabled={isDisabled}
								className={isDisabled ? classes.disabledInput : ''}
							>
								{paymentMethods.map((x) => (
									<MenuItem key={x.id} value={x.id}>
										{x.label}
									</MenuItem>
								))}
							</Select>
							{contribution?.error?.includes('paymentMethodId') && (
								<span className={classes.errorText}>Type is required</span>
							)}
						</Stack>
					</Grid>
					<Grid item mb={2} className={classes.gridWrap}>
						<span className={classes.formLabelText}>Amount</span>
						<Stack>
							<TextField
								sx={{ minWidth: 200 }}
								label=""
								variant="outlined"
								size="small"
								value={contribution?.amount ? contribution?.amount : ''}
								placeholder={'Enter Amount'}
								onChange={(e) => {
									handleChangeContribution({
										amount: parseInt(e.target.value),
										error: contribution?.error?.filter((item) => item !== 'amount') || [],
									});
								}}
								disabled={isDisabled}
								className={isDisabled ? classes.disabledInput : ''}
							/>
							{contribution?.error?.includes('amount') && (
								<span className={classes.errorText}>Amount is required</span>
							)}
						</Stack>
					</Grid>
					<Grid item mb={2} className={classes.gridWrap}>
						<span className={classes.formLabelText}>Cheque #</span>
						<TextField
							sx={{ minWidth: 200 }}
							label=""
							variant="outlined"
							size="small"
							value={contribution?.paymentMethodName === 'Cheque' ? contribution?.chequeNumber : ''}
							placeholder={'Enter Cheque'}
							onChange={(e) => handleChangeContribution({ chequeNumber: e.target.value })}
							disabled={contribution?.paymentMethodName !== 'Cheque' || isDisabled}
							className={
								contribution?.paymentMethodName !== 'Cheque' || isDisabled ? classes.disabledInput : ''
							}
						/>
					</Grid>
					<Grid item mb={2} className={classes.gridWrap}>
						<span className={classes.formLabelText}>Donation Type</span>
						<Stack>
							<Autocomplete
								freeSolo
								className={isDisabled ? classes.disabledInput : ''}
								value={contribution?.donationTypeName}
								sx={{ width: 220 }}
								loading={donationType.loading}
								loadingText="loading..."
								onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
									handleChangeContribution({
										donationTypeId: parseInt(val?.id),
										donationTypeName: val?.label,
										error: contribution?.error?.filter((item) => item !== 'donationTypeId') || [],
									});
								}}
								options={(donationType?.data || []).map((donation: DonationTypes) => ({
									id: donation.id,
									label: `${donation.type} - ${donation.description}`,
								}))}
								renderOption={(props, option) => (
									<Box component="li" {...props} key={option.id}>
										{option.label}
									</Box>
								)}
								disabled={isDisabled}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										label=""
										InputProps={{
											...params.InputProps,
										}}
										onChange={(event: any) => {
											setDonationTypeSearchTerm(event.target.value);
										}}
										size={'small'}
										placeholder={'Search donation type'}
										sx={{ width: 220 }}
									/>
								)}
							/>
							{contribution?.error?.includes('donationTypeId') && (
								<span className={classes.errorText}>Donation Type is required</span>
							)}
						</Stack>
					</Grid>
					<Grid item mb={2} className={classes.gridWrap}>
						<span className={classes.formLabelText}>Appeal Code</span>
						<Select
							disabled={isDisabled}
							className={isDisabled ? classes.disabledInput : ''}
							sx={{ minWidth: 220 }}
							size={'small'}
							value={contribution?.appealId}
							onChange={(e) => handleChangeContribution({ appealId: e.target.value })}
						>
							{appealCode.map((x) => (
								<MenuItem key={x.id} value={x.id}>
									{x.label}
								</MenuItem>
							))}
						</Select>
					</Grid>
					<Grid item mb={2} className={classes.gridWrap}>
						<span className={classes.formLabelText}>Walkathon</span>
						<Checkbox
							checked={contribution.isWalkathon}
							onChange={(e) => {
								handleChangeContribution({
									isWalkathon: e.target.checked,
									organizationType: 'Board',
									organizationId: undefined,
									organizationName: '',
								});
								setOrganization([]);
							}}
							disabled={isDisabled}
							className={isDisabled ? classes.disabledInput : ''}
						/>
					</Grid>
					<Grid item mb={2} className={classes.gridWrap}>
						<span className={classes.formLabelText}>Organization</span>
						<Select
							sx={{ minWidth: 220 }}
							size={'small'}
							value={contribution?.organizationType}
							onChange={(e) => {
								handleChangeContribution({
									organizationType: e.target.value,
									organizationId: undefined,
									organizationName: '',
								});
								setOrganization([]);
							}}
							disabled={contribution?.isWalkathon || isDisabled}
							className={contribution?.isWalkathon || isDisabled ? classes.disabledInput : ''}
						>
							<MenuItem value={'Board'}>School Board</MenuItem>
							<MenuItem value={'School'}>School</MenuItem>
							<MenuItem value={'Diocese'}>Diocese</MenuItem>
							<MenuItem value={'Parish'}>Parishes</MenuItem>
						</Select>
					</Grid>
					<Grid item mb={2} className={classes.gridWrap}>
						<span className={classes.formLabelText}>Organization Name</span>
						<Autocomplete
							freeSolo
							value={contribution?.organizationName}
							sx={{ width: 220 }}
							onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
								handleChangeContribution({ organizationId: parseInt(val?.id), organizationName: val?.label });
							}}
							disabled={!contribution?.organizationType || isDisabled}
							className={!contribution?.organizationType || isDisabled ? classes.disabledInput : ''}
							options={(organization || []).map((item: Organization) => ({
								id: item.id,
								label: item.name,
							}))}
							renderOption={(props, option) => (
								<Box component="li" {...props} key={option.id}>
									{option.label}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label=""
									InputProps={{
										...params.InputProps,
									}}
									onChange={(event: any) => {
										setOrganizationSearchTerm(event.target.value);
									}}
									size={'small'}
									placeholder={'Search Organization'}
									sx={{ width: 220 }}
								/>
							)}
						/>
						<div
							className={contribution?.organizationType ? classes.IconBtn : classes.disabledIcon}
							onClick={() => {
								if (contribution?.organizationType) {
									setOpen({ ...open, state: true, type: contribution?.organizationType });
								}
							}}
						>
							<Search />
						</div>
					</Grid>
					<Grid item mb={2} className={classes.gridWrap}>
						<span className={classes.formLabelText}>Status</span>
						<Stack>
							<Select
								sx={{ minWidth: 220 }}
								size={'small'}
								value={contribution?.statusId}
								onChange={(e) =>
									handleChangeContribution({
										statusId: e.target.value,
										statusName: depositSlipStatus?.find((item) => item.id === e.target.value)?.label ?? '',
										error: contribution?.error?.filter((item) => item !== 'status') || [],
									})
								}
								disabled={isDisabled}
								className={isDisabled ? classes.disabledInput : ''}
							>
								{depositSlipStatus.map((x) => (
									<MenuItem key={x.id} value={x.id}>
										{x.label}
									</MenuItem>
								))}
							</Select>
							{contribution?.error?.includes('status') && (
								<span className={classes.errorText}>Status is required</span>
							)}
						</Stack>
					</Grid>
					<Grid item mb={2} className={classes.gridWrap}>
						<span className={classes.formLabelText}>Remark</span>
						<TextField
							sx={{ minWidth: 200 }}
							label=""
							variant="outlined"
							size="small"
							value={contribution?.remark}
							placeholder={'Enter Remark'}
							onChange={(e) => handleChangeContribution({ remark: e.target.value })}
							multiline
							rows={2}
							disabled={isDisabled}
							className={isDisabled ? classes.disabledInput : ''}
						/>
					</Grid>
				</Grid>
			</Stack>
			{visibleSearch && (
				<AdvancedSearch
					visible={visibleSearch}
					handleClose={handleVisibleSearch}
					field={searchFields}
					setField={setSearchFields}
					title={'Search Donor'}
					handleSearch={handleSearch}
				>
					<>
						<Stack alignItems={'flex-end'}>
							<TrimTables name="donorHead" list={donorHead} trimList={trimList} setTrimList={setTrimList} />
						</Stack>
						<DonorTable
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							reload={reload}
							setReload={setReload}
							trimList={trimList}
							setTrimList={setTrimList}
							handleOpenEdit={handleSelectRow}
							isSearch
							donorSearchParam={donorSearchParam}
							page={page}
							setPage={setPage}
						/>
					</>
				</AdvancedSearch>
			)}
			{open.state && <OrdersModal open={open} setOpen={setOpen} />}
		</>
	);
};

export default Deposists;
