import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    title: {
        color: '#1976d2',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '26px',
        textAlign: 'center',
        marginBottom: '15px'
    },
    subTitle: {
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '26px',
        textAlign: 'center',
        marginBottom: '10px'
    },
    description: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        color: '#7697AB',
        textAlign: 'center'
    }
});
