import { 
	TemplateDepositType,
	TemplateDepositDeleteType, 
	TemplateDepositUpdateType,
	TemplateDepositByIdType
} from './Types'

export const templateDepositsInitialState: TemplateDepositType = {
	loading: false,
	error: null,
	data: null,
}

export const templateDepositDeleteInitialState: TemplateDepositDeleteType = {
	loading: false,
	error: null,
	data: null,
}

export const templateDepositUpdateInitialState: TemplateDepositUpdateType = {
	loading: false,
	error: null,
	data: null,
}

export const templateDepositByIdInitialState: TemplateDepositByIdType = {
	loading: false,
	error: null,
	data: null,
}