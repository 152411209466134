import { FilterList } from '@mui/icons-material';
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { style } from './style';

export interface IArchiveStatus {
	label: string;
	filter: string;
}

interface P {
	archive: IArchiveStatus;
	setArchive: Dispatch<SetStateAction<IArchiveStatus>>;
}
const Archive = ({ archive, setArchive }: P) => {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLButtonElement>(null);
	// Handle Toggle Popper
	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};
	// Close Popper
	const handleClose = (event: Event | React.SyntheticEvent) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}

		setOpen(false);
	};
	// change archived status
	const handleArchivedStatus = (status: string) => {
		if (status === 'active') {
			setOpen(false);
			return setArchive({ label: 'Active', filter: '&Filters=field=archived;value=false' });
		}
		if (status === 'archived') {
			setOpen(false);
			return setArchive({ label: 'Archived', filter: '&Filters=field=archived;value=true' });
		}
		setOpen(false);
		return setArchive({ label: 'All', filter: '' });
	};
	// Close popper on certain keydown
	function handleListKeyDown(event: React.KeyboardEvent) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === 'Escape') {
			setOpen(false);
		}
	}

	const btn = document.getElementById('composition-button')?.clientWidth;

	return (
		<div>
			<Button
				ref={anchorRef}
				id="composition-button"
				aria-controls={open ? 'composition-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
				variant="contained"
				sx={style.archiveBtn}
				disableElevation
				startIcon={<FilterList />}
				disableRipple
			>
				Archive Status: <span style={style.archiveLabel}> {archive.label}</span>
			</Button>
			<Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
						}}
					>
						<Paper sx={style.paper}>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									autoFocusItem={open}
									id="composition-menu"
									aria-labelledby="composition-button"
									onKeyDown={handleListKeyDown}
									sx={{ width: btn, py: { xs: 0, sm: 'initial' } }}
								>
									<MenuItem onClick={() => handleArchivedStatus('all')}>All</MenuItem>
									<MenuItem onClick={() => handleArchivedStatus('active')}>Active</MenuItem>
									<MenuItem onClick={() => handleArchivedStatus('archived')}>Archived</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
};

export default Archive;
