import React, { useState, useEffect } from 'react';
import { Stack, Autocomplete, Button, Paper, Grid, Typography, TextField, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ArrowBackIosNew, Search, Add, Delete, Numbers, Edit } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useStyles } from './MissionOrder.style';
import { useGlobalStyles } from 'Components/Common/global/global';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import AdvancedSearch, { Field } from 'Components/Reusable/Modals/AdvancedSearch/AdvancedSearchModal';
import { searchParishField } from 'Components/Reusable/Modals/AdvancedSearch/Mock';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { ColumnType, parishHead } from 'Components/Common/Tables/TableHeaders';
import { OrderType, ParishData, StateType, Parish } from 'contexts/intialstates/Types';
import ParishesTable from 'Components/AdminPanel/Parishes/ParishesTable';
import useParishesActions from 'hooks/useParishesActions';
import useMissionSundayOrderActions, { MissionOrderById } from 'hooks/useMissionSundayOrder';
import useDebounce from 'Components/Reusable/hooks/debounce';
import Address, { AddressValues } from './Address';
import useMissionSundayMaterialActions, { MissionMaterialData } from 'hooks/useMissionSundayMaterial';
import OrderMissionMaterial from './OrderMissionMaterial';
import { ContentSkeleton } from 'Components/Deposits/CreateEditDepositWithDonations/Content';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface Material {
	materialId: number;
	name: string;
	description: string;
	enQuantity?: number;
	frQuantity?: number;
}

interface FormValues {
	year: number;
	parishId?: number;
	streetline1: string;
	streetline2: string;
	country: string;
	city: string;
	province: string;
	provinceId?: number;
	postalCode: string;
	receivedDate: string;
	materials: Material[];
}

const CreateEditMissionOrder = () => {
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);
	const navigate = useNavigate();
	const classes = useStyles();
	const { id } = useParams();
	const global = useGlobalStyles();
	const { getMissionMaterial } = useMissionSundayMaterialActions();
	const { createMissionOrder, updateMissionOrder, deleteMissionOrder, getMissionOrderById } =
		useMissionSundayOrderActions();
	const { getParishes, getParishesBySearch } = useParishesActions();

	const [parishName, setParishName] = React.useState<string | undefined>('');
	const [visibleSearchParish, setVisibleSearchParish] = useState(false);
	const [searchFieldsParish, setSearchFieldsParish] = useState<Field[]>(searchParishField);
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [open, setOpen] = React.useState<any>({ state: false, type: '' });
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [reload, setReload] = useState(false);
	const [filterPayload, setFilterPayload] = useState<Array<any>>([]);
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [parishes, setParishes] = React.useState<StateType<ParishData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [searchParishes, setSearchParishes] = React.useState<StateType<Parish[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [parishSearchTerm, setParishSearchTerm] = useState('');
	const parishDebouncedSearchTerm = useDebounce(parishSearchTerm, 1000);
	const [missionMaterial, setMissionMaterial] = useState<StateType<MissionMaterialData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [formData, setFormData] = useState<FormValues>({
		year: new Date().getFullYear(),
		streetline1: '',
		streetline2: '',
		country: '',
		city: '',
		province: '',
		postalCode: '',
		receivedDate: '',
		materials: [],
	});
	const [visibleAddress, setVisibleAddress] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [missionOrderById, setMissionOrderById] = useState<StateType<MissionOrderById>>({
		data: null,
		error: null,
		loading: false,
	});
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [isDelete, setIsDelete] = useState(false);
	const [formErrors, setFormErrors] = useState<string[]>([]);

	useEffect(() => {
		getMissionMaterial({ missionMaterial, setMissionMaterial, page: 0, pageSize: 1000 });
	}, []);

	useEffect(() => {
		if (!id) return;
		getMissionOrderById({ missionOrderById, setMissionOrderById, id: parseInt(id) });
	}, [id]);

	useEffect(() => {
		if (!missionMaterial.data) return;
		const data = missionMaterial.data?.body.map((material: any) => {
			return {
				materialId: material.id,
				name: material.itemCode,
				description: material.description,
				enQuantity: 0,
				frQuantity: 0,
			};
		});
		setFormData({
			...formData,
			materials: data,
		});
	}, [missionMaterial.data?.body.length]);

	useEffect(() => {
		if (!missionOrderById?.data || !id || !missionMaterial.data) return;
		const { year, receivedDate, parish, materials } = missionOrderById?.data;
		let materialData: Material[] = [];
		if (materials.length && missionMaterial.data?.body.length) {
			materialData = missionMaterial.data.body.map((material) => {
				const findData = materials.find((item) => item.missionSundayMaterialId === material.id);
				if (findData) {
					return {
						materialId: findData.material.id,
						name: findData.material.itemCode,
						description: findData.material.description,
						enQuantity: findData.enQuantity,
						frQuantity: findData.frQuantity,
					};
				} else {
					return {
						materialId: material.id,
						name: material.itemCode,
						description: material.description,
						enQuantity: 0,
						frQuantity: 0,
					};
				}
			});
		}
		setFormData({
			year,
			receivedDate,
			city: parish?.city ?? '',
			country: parish?.country ?? '',
			postalCode: parish?.postalCode ?? '',
			streetline1: parish?.streetline1 ?? '',
			streetline2: parish?.streetline2 ?? '',
			province: parish?.province?.provinceName,
			provinceId: parish?.province?.id,
			parishId: parish?.id,
			materials: materialData,
		});
		setParishName(parish?.parishName);
	}, [missionOrderById?.data, missionMaterial?.data]);

	useEffect(() => {
		getParishes({
			archive: '&Filters=field=archived;value=false',
			parishes,
			setParishes,
			page,
			pageSize,
			orderBy,
			filterPayload,
		});
	}, [page, pageSize, orderBy, reload, filterPayload.length]);

	useEffect(() => {
		if (!parishDebouncedSearchTerm) return;
		getParishesBySearch({ searchParishes, setSearchParishes, searchTerm: parishSearchTerm });
	}, [parishDebouncedSearchTerm]);

	const handleVisibleSearchParish = () => {
		setVisibleSearchParish(!visibleSearchParish);
	};

	const handleSearchParish = () => {
		const payload: any = searchFieldsParish.map(({ key, value }) => {
			if (value) {
				return {
					field: key,
					query: key !== 'phone' ? value : null,
					value: key === 'phone' ? [value] : null,
					from: null,
					to: null,
				};
			}
		});
		setFilterPayload(payload.filter(Boolean));
	};

	const handleSelectRowParish = (row: any) => {
		setFormData({
			...formData,
			parishId: parseInt(row.id),
			streetline1: row.streetline1,
			streetline2: row.streetline2,
			country: row.country,
			city: row.city !== 'N/A' ? row.city : '',
			province: row.province,
			postalCode: row.postalCode !== 'N/A' ? row.postalCode : '',
			provinceId: row.provinceId ? parseInt(row.provinceId) : undefined,
		});
		setParishName(row.parishName);
		handleVisibleSearchParish();
		setFormErrors(formErrors.filter((item) => item !== 'parishId'));
	};

	const handleParishChange = (id: number) => {
		const parish = searchParishes?.data?.find((item) => item.id === id);
		if (parish) {
			setFormData({
				...formData,
				parishId: parish.id,
				streetline1: parish.streetline1,
				streetline2: parish.streetline2,
				country: parish.country,
				city: parish.city !== 'N/A' ? parish.city : '',
				province: parish.province?.provinceName,
				postalCode: parish.postalCode !== 'N/A' ? parish.postalCode : '',
				provinceId: parish.province?.id ? parish.province?.id : undefined,
			});
		}
		setFormErrors(formErrors.filter((item) => item !== 'parishId'));
	};

	const getAddress = () => {
		const { streetline1, streetline2, province, city, postalCode, country } = formData;
		return !streetline1 && !streetline2 && !province && !city && !postalCode && !country
			? ''
			: `${streetline1 ?? ''} ${streetline2 ?? ''} ${province ?? ''} ${city ?? ''} ${postalCode ?? ''} ${
					country ?? ''
			  }`;
	};

	const handleChange = (name: string, value: string) => {
		setFormData({
			...formData,
			[name]: value,
		});
		if (formErrors.includes(name)) {
			setFormErrors(formErrors.filter((item) => item !== name));
		}
	};

	const handleChangeMaterial = (id: number, value: string, name: string) => {
		const materials = [...formData.materials].map((item: Material) => {
			if (item.materialId === id) {
				if (name === 'enQuantity') item.enQuantity = value ? parseInt(value) : undefined;
				else item.frQuantity = value ? parseInt(value) : undefined;
			}
			return item;
		});

		setFormData({
			...formData,
			materials,
		});
	};

	const handleToggle = () => {
		setVisibleAddress(!visibleAddress);
	};

	const handleAddressChange = (values: AddressValues) => {
		const { streetline1, streetline2, country, city, province, provinceId, postalCode } = values;
		setFormData({
			...formData,
			streetline1,
			streetline2,
			country,
			city,
			province,
			postalCode,
			provinceId: provinceId ? parseInt(provinceId) : undefined,
		});
		handleToggle();
	};

	const handleSave = async () => {
		const errors = [];
		if (!formData.parishId) {
			errors.push('parishId');
		}
		if (!formData.receivedDate) {
			errors.push('receivedDate');
		}
		let count = 0;
		for (const data of formData.materials) {
			if (data.enQuantity === 0 && data.frQuantity === 0) {
				count++;
			}
		}
		if (count === formData.materials.length) {
			setSimpleErrorSnack({
				severity: 'error',
				message: 'You need to add at least one material',
				show: true,
			});
		}
		if (errors.length || count === formData.materials.length) {
			setFormErrors(errors);
			return;
		}
		setSaveLoading(true);
		const payload = {
			year: formData.year,
			parishId: formData.parishId ?? 0,
			streetline1: formData.streetline1,
			streetline2: formData.streetline2,
			country: formData.country,
			city: formData.city,
			provinceId: formData.provinceId,
			postalCode: formData.postalCode,
			receivedDate: formData.receivedDate,
			materials: formData.materials.map((item) => {
				return {
					materialId: item.materialId,
					enQuantity: item.enQuantity ?? 0,
					frQuantity: item.frQuantity ?? 0,
				};
			}),
		};
		if (!id) {
			const idSucess = await createMissionOrder(payload);
			if (idSucess) {
				navigate(`../${idSucess}`);
			}
		} else {
			await updateMissionOrder(payload, parseInt(id));
		}
		setSaveLoading(false);
	};

	const handleDelete = async () => {
		if (!id) return;
		setDeleteLoading(true);
		const isSucess = await deleteMissionOrder(parseInt(id));
		setDeleteLoading(false);
		setIsDelete(false);
		if (isSucess) {
			navigate('../');
		}
	};

	return (
		<div>
			{missionOrderById?.loading || missionMaterial?.loading ? (
				<ContentSkeleton />
			) : (
				<Stack spacing={3}>
					<Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap={'wrap'}>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							spacing={4}
							marginBottom={2}
						>
							<Stack className={classes.backIconWrapper}>
								<ArrowBackIosNew onClick={() => navigate('../')} />
							</Stack>
							<Stack alignItems={'flex-start'}>
								<span className={classes.title}>Order</span>
							</Stack>
						</Stack>
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems={'center'}
							spacing={2}
							marginBottom={2}
						>
							{id && (
								<Button
									startIcon={<Delete />}
									size={'small'}
									variant="outlined"
									className={classes.whiteBtn}
									onClick={() => setIsDelete(true)}
								>
									Delete
								</Button>
							)}
							<LoadingButton
								loadingPosition="start"
								loading={saveLoading}
								startIcon={<Add />}
								size={'small'}
								variant="contained"
								onClick={handleSave}
							>
								Save
							</LoadingButton>
						</Stack>
					</Stack>
					<Paper sx={{ padding: '40px' }}>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
							<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
								<Grid container>
									<Grid item>
										<Numbers className={global.formLabelIcon} />
									</Grid>
									<Grid item>
										<Typography variant="body2" className={global.formLabelText}>
											Order ID
										</Typography>
									</Grid>
								</Grid>
								<TextField
									label=""
									fullWidth
									variant="outlined"
									size="small"
									value={missionOrderById?.data?.id ?? ''}
									placeholder={'Order ID'}
									disabled={true}
									className={global.disabledInput}
								/>
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
								<Grid container>
									<Grid item>
										<Numbers className={global.formLabelIcon} />
									</Grid>
									<Grid item>
										<Typography variant="body2" className={global.formLabelText}>
											Year
										</Typography>
									</Grid>
								</Grid>
								<TextField
									fullWidth
									label=""
									variant="outlined"
									size="small"
									value={formData.year}
									placeholder={'Year'}
									disabled={true}
									className={global.disabledInput}
								/>
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
								<Grid container>
									<Grid item>
										<Numbers className={global.formLabelIcon} />
									</Grid>
									<Grid item>
										<Typography variant="body2" className={global.formLabelText}>
											Order Received Date
										</Typography>
									</Grid>
								</Grid>
								<BasicDatePicker
									value={formData.receivedDate}
									setValue={(e) => {
										handleChange('receivedDate', e.target.value);
									}}
								/>
								{formErrors.includes('receivedDate') && (
									<span className={global.errorText}>Received Date is required</span>
								)}
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
								<Grid container>
									<Grid item>
										<Numbers className={global.formLabelIcon} />
									</Grid>
									<Grid item>
										<Typography variant="body2" className={global.formLabelText}>
											Parish
										</Typography>
									</Grid>
								</Grid>
								<Stack>
									<Stack flexDirection={'row'} alignItems={'center'} width={'100%'}>
										<Autocomplete
											freeSolo
											value={parishName ?? ''}
											fullWidth
											size="small"
											onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
												handleParishChange(val?.id);
												setParishName(val?.label);
											}}
											options={(searchParishes?.data || []).map((p: Parish) => ({
												id: p.id,
												label: `${p.parishName}`,
											}))}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													label=""
													InputProps={{
														...params.InputProps,
													}}
													onChange={(event: any) => {
														setParishSearchTerm(event.target.value);
														setFormErrors(formErrors.filter((item) => item !== 'parishId'));
													}}
												/>
											)}
										/>
										<IconButton
											size="small"
											onClick={() => handleVisibleSearchParish()}
											sx={{ borderRadius: '5px', border: '1px solid var(--mainColor)', ml: '5px' }}
										>
											<Search color="primary" />
										</IconButton>
									</Stack>
									{formErrors.includes('parishId') && (
										<span className={global.errorText}>Parish is required</span>
									)}
								</Stack>
							</Grid>
							<Grid item xs={12} md={8} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
								<Grid container>
									<Grid item>
										<Numbers className={global.formLabelIcon} />
									</Grid>
									<Grid item>
										<Typography variant="body2" className={global.formLabelText}>
											Address
										</Typography>
									</Grid>
								</Grid>
								<Stack alignItems="center" direction="row" spacing={1}>
									<TextField
										fullWidth
										label=""
										variant="outlined"
										size="small"
										value={getAddress()}
										placeholder={'Address'}
										disabled={true}
										className={global.disabledInput}
									/>
									<IconButton
										size="small"
										onClick={handleToggle}
										sx={{ borderRadius: '5px', border: '1px solid var(--mainColor)' }}
										disabled={!formData.parishId}
									>
										<Edit color="primary" />
									</IconButton>
								</Stack>
							</Grid>
						</Grid>
						<OrderMissionMaterial material={formData.materials} handleChange={handleChangeMaterial} />
					</Paper>
				</Stack>
			)}
			{visibleSearchParish && (
				<AdvancedSearch
					visible={visibleSearchParish}
					handleClose={handleVisibleSearchParish}
					field={searchFieldsParish}
					setField={setSearchFieldsParish}
					title={'Search Parish'}
					handleSearch={handleSearchParish}
				>
					<>
						<Stack alignItems={'flex-end'}>
							<TrimTables name="parishHead" list={parishHead} trimList={trimList} setTrimList={setTrimList} />
						</Stack>
						<ParishesTable
							trimList={trimList}
							setTrimList={setTrimList}
							page={page}
							pageSize={pageSize}
							setPage={setPage}
							setPageSize={setPageSize}
							label={label}
							orderType={orderType}
							setLabel={setLabel}
							setOderBy={setOderBy}
							setOrderType={setOrderType}
							parishes={parishes}
							hcaOrgList
							open={open}
							setOpen={setOpen}
							handleOpenEdit={handleSelectRowParish}
						/>
					</>
				</AdvancedSearch>
			)}
			{visibleAddress && (
				<Address
					selectedData={formData}
					visible={visibleAddress}
					handleClose={handleToggle}
					handleSubmit={handleAddressChange}
				/>
			)}
			<ConfirmationModal
				handleClose={() => setIsDelete(false)}
				message={'Are you sure you want to delete this Mission sunday order?'}
				open={isDelete}
				handleConfirmation={handleDelete}
				handleCancel={() => setIsDelete(false)}
				loading={deleteLoading}
			/>
		</div>
	);
};

export default CreateEditMissionOrder;
