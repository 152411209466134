import React from 'react';
import { Stack } from '@mui/material';
import { useStyles } from '../DepositSlip.style';
import { TotalCount } from './CreateDepositSlip';

interface P {
	totalData: TotalCount;
}
const DepositSlipHeader = ({ totalData }: P) => {
	const classes = useStyles();
	return (
		<Stack flexDirection="row" justifyContent="space-between" className={classes.headerWrap}>
			<Stack
				flexDirection="row"
				width={'100%'}
				justifyContent="space-around"
				className={classes.headerContent}
			>
				<Stack alignItems="center">
					<Stack className={classes.titleHeader}>Total #</Stack>
					<Stack className={classes.subTitleHeader}>{totalData.total}</Stack>
				</Stack>
				<Stack alignItems="center">
					<Stack className={classes.titleHeader}>Total</Stack>
					<Stack className={classes.subTitleHeader}>$ {totalData.totalAmount}</Stack>
				</Stack>
			</Stack>
			<Stack
				flexDirection="row"
				width={'100%'}
				justifyContent="space-around"
				className={classes.headerContent}
			>
				<Stack alignItems="center">
					<Stack className={classes.titleHeader}>Deposited #</Stack>
					<Stack className={classes.subTitleHeader}>{totalData.deposited}</Stack>
				</Stack>
				<Stack alignItems="center">
					<Stack className={classes.titleHeader}>Deposited</Stack>
					<Stack className={classes.subTitleHeader}>$ {totalData.depositedAmount}</Stack>
				</Stack>
			</Stack>
			<Stack
				flexDirection="row"
				width={'100%'}
				justifyContent="space-around"
				className={classes.headerContent}
			>
				<Stack alignItems="center">
					<Stack className={classes.titleHeader}>Cash #</Stack>
					<Stack className={classes.subTitleHeader}>{totalData.cash}</Stack>
				</Stack>
				<Stack alignItems="center">
					<Stack className={classes.titleHeader}>Cash</Stack>
					<Stack className={classes.subTitleHeader}>$ {totalData.cashAmount}</Stack>
				</Stack>
			</Stack>
			<Stack flexDirection="row" width={'100%'} justifyContent="space-around">
				<Stack alignItems="center">
					<Stack className={classes.titleHeader}>Cheque #</Stack>
					<Stack className={classes.subTitleHeader}>{totalData.cheque}</Stack>
				</Stack>
				<Stack alignItems="center">
					<Stack className={classes.titleHeader}>Cheque</Stack>
					<Stack className={classes.subTitleHeader}>$ {totalData.chequeAmount}</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};

export default DepositSlipHeader;
