import React from 'react';
export const formatNumberFunc = (value: any) => {
	if (!value) {
		return value;
	}

	const numberValue = parseFloat(value);
	if (!numberValue) {
		return value;
	}

	return `${numberValue.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const formatCurrencyFunc = (value: any) => {
	if (!value) {
		return value;
	}

	const numberValue = parseFloat(value);
	if (!numberValue) {
		return value;
	}

	return `$${numberValue.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export const FormatNumbers = ({ value }: any) => {
	if (!value) {
		return value;
	}

	const numberValue = parseFloat(value);
	if (!numberValue) {
		return value;
	}

	return `${numberValue.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

const FormatCurrency = ({ value }: any) => {
	if (!value) {
		return value;
	}

	const numberValue = parseFloat(value);
	if (!numberValue) {
		return value;
	}

	return `$${numberValue.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
};

export default FormatCurrency;
