import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Button, Typography } from '@mui/material';
import { Add, Download } from '@mui/icons-material';
import DioceseTable from './DioceseTable';
import ErrorSnack from 'Components/Common/ErrorSnack/ErrorSnack';
import { dioceseHead, ColumnType } from 'Components/Common/Tables/TableHeaders';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { StateType, DioceseData, OrderType, FilterPayload } from 'contexts/intialstates/Types';
import useDioceseActions from 'hooks/useDioceseActions';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import Archive, { IArchiveStatus } from 'Components/Reusable/Archive';
import { styles } from 'Components/Donors/donors.style';
import { LoadingButton } from '@mui/lab';
import { dioceseStyles } from './Diocese.style';
import useUserActions from 'hooks/useUserActions';

const Parishes = () => {
	const { handleRoles } = useUserActions();
	const navigate = useNavigate();
	const { getDioceses, exportDiocesesCSV } = useDioceseActions();
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [orderType, setOrderType] = useState<OrderType>('asc');
	const [label, setLabel] = useState<string>('');
	const [orderBy, setOderBy] = useState<string>('');
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [reload, setReload] = useState(false);
	const [filterPayload, setFilterPayload] = useState<Array<FilterPayload>>([]);
	const [dioceses, setDioceses] = useState<StateType<DioceseData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [dioceseExport, setDioceseExport] = useState<StateType<DioceseData>>({
		data: null,
		error: null,
		loading: false,
	});

	const [archive, setArchive] = useState<IArchiveStatus>({
		label: 'All',
		filter: '',
	});
	const handleExport = () => {
		exportDiocesesCSV({
			dioceseExport,
			setDioceseExport,
			pageSize,
			page,
			orderBy,
			filterPayload,
			archive: archive?.filter,
		});
	};
	useEffect(() => {
		getDioceses({
			setDioceses,
			dioceses,
			pageSize,
			page,
			orderBy,
			filterPayload,
			archive: archive?.filter,
		});
	}, [pageSize, page, orderBy, reload, archive]);

	return (
		<div>
			<Stack>
				<Stack
					alignItems="center"
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent={'space-between'}
					spacing={1}
					mb={5}
				>
					<Stack direction={'column'} spacing={3}>
						<Stack direction="column">
							<Stack direction="row" spacing={4} alignItems="center">
								<Typography variant="h5" sx={dioceseStyles.title}>
									Diocese
								</Typography>
							</Stack>
							<Typography component={'span'} sx={dioceseStyles.subtitle}>
								Create and manage Diocese here.
							</Typography>
						</Stack>
						<Stack direction="row" spacing={2}>
							<Archive archive={archive} setArchive={setArchive} />
							<LoadingButton
								loading={dioceseExport.loading}
								loadingPosition="start"
								onClick={handleExport}
								startIcon={<Download />}
								variant="outlined"
								sx={styles.csvBtn}
							>
								Download CSV
							</LoadingButton>
						</Stack>
					</Stack>
					<Stack sx={dioceseStyles.mb24} direction="row" alignItems={'center'} justifyContent="flex-end">
						<TrimTables name="dioceseHead" list={dioceseHead} trimList={trimList} setTrimList={setTrimList} />
						{handleRoles('admin-actions') && (
							<Button
								onClick={() => navigate(`/admin/create-edit-diocese`)}
								sx={dioceseStyles.addNewBtn}
								startIcon={<Add />}
								variant="contained"
							>
								Add New Diocese
							</Button>
						)}
						<FilterListButton
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							setIsReload={setReload}
							reload={reload}
							setPage={setPage}
							page={page}
						/>
					</Stack>
				</Stack>
				<DioceseTable
					trimList={trimList}
					setTrimList={setTrimList}
					page={page}
					pageSize={pageSize}
					setPage={setPage}
					setPageSize={setPageSize}
					label={label}
					orderType={orderType}
					setLabel={setLabel}
					setOderBy={setOderBy}
					setOrderType={setOrderType}
					dioceses={dioceses}
					setFilterPayload={setFilterPayload}
					filterPayload={filterPayload}
					reload={reload}
					setReload={setReload}
					isFilter
				/>
			</Stack>
			<ErrorSnack />
		</div>
	);
};

export default Parishes;
