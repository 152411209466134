import { ResponseError, Action, AllSocietyStateType, SocietyData } from 'contexts/intialstates/Types';
import { SOCIETIES_LOADING, SOCIETIES_LOAD_SUCCESS, SOCIETIES_LOAD_ERROR } from 'contexts/actionTypes';

export const societies = (
	state: AllSocietyStateType,
	{ payload, type }: Action<SocietyData>,
): AllSocietyStateType => {
	switch (type) {
		case SOCIETIES_LOADING: {
			return {
				...state,
				error: null,
				loading: true,
			};
		}

		case SOCIETIES_LOAD_SUCCESS: {
			return {
				...state,
				loading: false,
				error: null,
				data: payload as SocietyData,
			};
		}
		case SOCIETIES_LOAD_ERROR: {
			return {
				...state,
				loading: false,
				error: payload as ResponseError,
			};
		}
		default:
			return state;
	}
};
