import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	title: {
		color: 'var(--mainColor)',
		fontSize: '32px',
		fontWeight: 700,
	},
	subTitle: {
		border: '1px solid var(--mainColor)',
		borderRadius: '4px',
		padding: '5px',
		background: 'var(--white)',
		color: 'var(--mainColor)',
		fontSize: '12px',
	},
	backIconWrapper: {
		background: 'var(--white)',
		borderRadius: '4px',
		padding: '5px',
		color: 'var(--mainColor)',
		cursor: 'pointer'
	},
	paperWrap: {
		padding: '20px',
	},
	IconBtn: {
		cursor: 'pointer',
		margin: '0 12px',
		border: '1px solid var(--mainColor)',
		borderRadius: '6px',
		padding: '3px 10px',
		display: 'flex',
		background: 'var(--white)',
		'& svg': {
			color: 'var(--mainColor)',
		},
	},
	disabledIcon: {
		margin: '0 12px',
		border: '1px solid var(--disabled)',
		borderRadius: '6px',
		padding: '3px 10px',
		display: 'flex',
		background: 'var(--white)',
		'& svg': {
			color: 'var(--disabled)',
		},
	},
	disabledInput: {
		background: 'var(--invalidBackground)'
	},
	errorText: {
		color: 'red',
	},
});
