import React, { useRef } from 'react';
import { TaskAlt } from '@mui/icons-material';
import {
	Stack,
	Table,
	Box,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	Button,
} from '@mui/material';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import FormatCurrency, { formatNumberFunc } from 'helpers/FormatCurrency';
import useReportActions from 'hooks/useReportAction';
import { StateType, WalkathonReportData } from 'contexts/intialstates/Types';
import { GlobalContexts } from 'contexts/GlobalContext';
import { MiniCard } from '../Card';
import { FilterProps } from '../Configure';
import Logo from './../../../assets/images/logo.png';
import { currentDate } from 'helpers/dayAndTime';
import ReportPdf from '../Report';
import { useReactToPrint } from 'react-to-print';
import { useStyles } from 'Components/Reports/DonorReports/DonorReport.style';

interface Props {
	walkathonReport: StateType<WalkathonReportData>;
	setWalkathonReport: React.Dispatch<React.SetStateAction<StateType<WalkathonReportData>>>;
	filterContols: FilterProps;
	setFilterControls?: React.Dispatch<React.SetStateAction<FilterProps>>;
}

const Report = ({ walkathonReport, setWalkathonReport, filterContols }: Props) => {
	const { getWalkathonReport, getWalkathonReportPdf } = useReportActions();
	const { setSimpleErrorSnack, simpleErrorSnack } = React.useContext(GlobalContexts);
	const [output, setOutput] = React.useState<any>();

	const [payload, setPayload] = React.useState<{ year: number | null }>({
		year: null,
	});

	const updateFormValue = (key: string) => (e: any) => {
		const temp = { ...payload, [key]: new Date(e.target.value).getFullYear() };
		setPayload(temp);
	};
	const classes = useStyles();
	const componentRef = useRef(null);

	const splitter = () => {
		if (!walkathonReport.data?.walkathons.length) return;
		const walkathonData: any = [];
		const data = [];
		walkathonReport.data?.walkathons.map((row) => {
			const schoolBoard = row.schoolBoardName.split(',');
			walkathonData.push({
				walkathonId: row.walkathonId,
				schoolBoardId: row.schoolBoardId,
				name: schoolBoard.length ? schoolBoard[0] : row.schoolBoardName,
				schoolId: '',
				schoolName: '',
				donationAmount: <FormatCurrency value={row.amount} />,
			});
			if (schoolBoard.length > 1) {
				schoolBoard.slice(1, schoolBoard.length).map((boardName) => {
					walkathonData.push({
						walkathonId: '',
						schoolBoardId: '',
						name: boardName,
						schoolId: '',
						schoolName: '',
						donationAmount: '',
					});
				});
			}
			if (row.walkathonSchoolItems.length > 1) {
				row.walkathonSchoolItems.slice(1, row.walkathonSchoolItems.length).map((item) => {
					walkathonData.push({
						walkathonId: '',
						schoolBoardId: '',
						name: '',
						schoolId: item.schoolId,
						schoolName: item.schoolName,
						donationAmount: <FormatCurrency value={item.amount} />,
					});
				});
			}
			walkathonData.push({
				totalFor: `Total for ${row.schoolBoardName}`,
				total: <FormatCurrency value={row.totalAmount} />,
			});
		});
		for (let i = 0; i < walkathonData.length; i += 12) {
			data.push(walkathonData.slice(i, i + 12));
		}
		return data;
	};

	const printContent = () => (
		<Box width="100%" sx={{ px: 3 }}>
			<Table sx={{ minWidth: 700 }} aria-label="spanning table">
				<TableHead className={classes.tableHead}>
					<TableRow>
						<TableCell align="center">Walkathon #</TableCell>
						<TableCell align="center">SchoolBoard #</TableCell>
						<TableCell align="center">Name</TableCell>
						<TableCell align="center">School #</TableCell>
						<TableCell align="center">School Name</TableCell>
						<TableCell align="center">Donation Amount</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{splitter()
						?.flatMap((item: any) => item)
						.map((row: any, index: number) => (
							<>
								{row?.totalFor ? (
									<TableRow key={index} className={classes.tableRowWrap}>
										<TableCell
											align="center"
											colSpan={5}
											sx={{
												color: 'var(--fontColorHeaderSubTitle)',
												fontSize: 20,
												textTransform: 'capitalize',
												fontWeight: 600,
												textIndent: 100,
											}}
										>
											{row.totalFor || ''}
										</TableCell>
										<TableCell
											align="center"
											sx={{
												color: 'var(--mainColor)',
												fontSize: 20,
												textTransform: 'capitalize',
												fontWeight: 600,
											}}
										>
											{row.total || ''}
										</TableCell>
									</TableRow>
								) : (
									<TableRow key={index} className={classes.tableRowWrap}>
										<TableCell align="center">{row.walkathonId || ''}</TableCell>
										<TableCell align="center">{row.schoolBoardId || ''}</TableCell>
										<TableCell align="center">{row.name || ''}</TableCell>
										<TableCell align="center">{row.schoolId || ''}</TableCell>
										<TableCell align="center">{row.schoolName}</TableCell>
										<TableCell align="center">{row.donationAmount}</TableCell>
									</TableRow>
								)}
							</>
						))}
				</TableBody>
			</Table>
			<Stack sx={{ color: '#1976d2', fontSize: 19, fontWeight: 700 }} mt={5} direction="row" width="100%">
				<Stack width="40%">
					Count: {walkathonReport.data?.count ? formatNumberFunc(walkathonReport.data?.count) : '-'}
				</Stack>
				<Stack width="60%" sx={{ borderBottom: '1px solid #ABAEB0' }}>
					<Stack
						width="100%"
						direction="row"
						mb={0.5}
						py={1}
						sx={{ px: 2, borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
					>
						<Box width={'90%'}>Grand Total</Box>
						<Box>
							{walkathonReport.data?.total ? <FormatCurrency value={walkathonReport.data?.total} /> : '-'}
						</Box>
					</Stack>
				</Stack>
			</Stack>
		</Box>
	);

	const printData = () => {
		const splitData = splitter();
		return (
			<TableContainer>
				{splitData?.map((data, index) => {
					return (
						<div className={classes.pageBreak} key={index}>
							<Table sx={{ minWidth: 700 }} key={index} aria-label="spanning table">
								<TableHead className={classes.tableHead}>
									<TableRow>
										<TableCell align="center">Walkathon #</TableCell>
										<TableCell align="center">SchoolBoard #</TableCell>
										<TableCell align="center">Name</TableCell>
										<TableCell align="center">School #</TableCell>
										<TableCell align="center">School Name</TableCell>
										<TableCell align="center">Donation Amount</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map((row: any, index: number) => (
										<>
											{row?.totalFor ? (
												<TableRow key={index} className={classes.tableRowWrap}>
													<TableCell align="center" colSpan={5}>
														{row.totalFor || ''}
													</TableCell>
													<TableCell align="center">{row.total || ''}</TableCell>
												</TableRow>
											) : (
												<TableRow key={index} className={classes.tableRowWrap}>
													<TableCell align="center">{row.walkathonId || ''}</TableCell>
													<TableCell align="center">{row.schoolBoardId || ''}</TableCell>
													<TableCell align="center">{row.name || ''}</TableCell>
													<TableCell align="center">{row.schoolId || ''}</TableCell>
													<TableCell align="center">{row.schoolName}</TableCell>
													<TableCell align="center">{row.donationAmount}</TableCell>
												</TableRow>
											)}
										</>
									))}
									{index === splitData.length - 1 && (
										<TableRow className={classes.tableTotal}>
											<TableCell colSpan={6}>
												<Stack
													sx={{ color: '#1976d2', fontSize: 19, fontWeight: 700 }}
													mt={5}
													direction="row"
													width="100%"
												>
													<Stack width="40%">
														Count:{' '}
														{walkathonReport.data?.count
															? formatNumberFunc(walkathonReport.data?.count)
															: '-'}
													</Stack>
													<Stack width="60%" sx={{ borderBottom: '1px solid #ABAEB0' }}>
														<Stack
															width="100%"
															direction="row"
															mb={0.5}
															py={1}
															sx={{
																px: 2,
																borderTop: '1px solid #ABAEB0',
																borderBottom: '1px solid #ABAEB0',
															}}
														>
															<Box width={'75%'}>Grand Total</Box>
															<Box width={'25%'}>
																{walkathonReport.data?.total ? (
																	<FormatCurrency value={walkathonReport.data?.total} />
																) : (
																	'-'
																)}
															</Box>
														</Stack>
													</Stack>
												</Stack>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</div>
					);
				})}
			</TableContainer>
		);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});
	React.useEffect(() => {
		const reportOutput = () => {
			if (walkathonReport.loading) {
				setSimpleErrorSnack({
					message: 'processing...',
					severity: 'info',
					show: true,
					hide: 20000,
				});
				return;
			}

			if (walkathonReport.data?.total === 0) {
				setSimpleErrorSnack({
					message: `No Walkathon donation found for ${payload.year}`,
					severity: 'error',
					show: true,
					hide: 4000,
				});
				return;
			}
			if (walkathonReport.data) {
				setOutput(content);
				setSimpleErrorSnack({
					...simpleErrorSnack,
					show: false,
					hide: 4000,
				});
			}
		};
		reportOutput();
	}, [walkathonReport.loading, walkathonReport.data]);

	const handleWalkathonReport = () => {
		if (!payload.year) {
			setSimpleErrorSnack({
				severity: 'error',
				message: 'invalid year',
				show: true,
			});
			return;
		}
		getWalkathonReport({ setWalkathonReport, walkathonReport, year: payload.year });
	};
	const handleWalkathonReportPdf = async () => {
		if (!payload.year) return;
		await getWalkathonReportPdf(payload.year);
	};
	const content = (
		<>
			<ReportPdf
				title="Walkathon Report"
				subTitle={`Year: ${walkathonReport.data?.year}`}
				handlePrint={handleWalkathonReportPdf}
			>
				{printContent()}
			</ReportPdf>
			<div style={{ display: 'none' }}>
				<div style={{ padding: 16 }} ref={componentRef}>
					<Stack sx={{ my: 2, px: 1 }}>
						<>
							<Stack width="100%" direction="row">
								<Stack flex={0.5} alignItems="flex-start" direction="column">
									<img src={Logo} alt="logo" width={40} height={50} />
								</Stack>

								<Stack sx={{ mb: 2, textAlign: 'center' }} alignItems="center" direction="column">
									<Typography sx={{ color: '#1976d2', fontSize: 20, fontWeight: 700 }}>
										Walkathon Report
									</Typography>
									<Typography sx={{ color: '#445f6f', fontSize: 18, fontWeight: 400 }}>
										{`Year: ${walkathonReport.data?.year}`}
									</Typography>
								</Stack>
							</Stack>
							<Stack
								direction={'row'}
								justifyContent="flex-end"
								mb={3}
								sx={{ color: '#445f6f', fontSize: 16, fontWeight: 400 }}
							>
								{currentDate()}
							</Stack>
						</>

						<Box width="100%">{printData()}</Box>
					</Stack>
				</div>
			</div>
		</>
	);

	return (
		<>
			{filterContols.configure && (
				<Box mb={2}>
					<MiniCard title="Configure">
						<>
							<Stack sx={{ mb: 3 }}>
								<Stack direction="row" sx={{ mb: 3 }} spacing={5} alignItems="center">
									<Stack direction="column" spacing={2}>
										<Typography sx={{ color: 'var(--formLabelColor)', fontSize: 14, fontWeight: 600 }}>
											Year
										</Typography>

										<BasicDatePicker
											label="Select Year"
											value={payload.year && `01/01/${payload.year}`}
											setValue={updateFormValue('year')}
											year
										/>
									</Stack>
									<Stack direction="row" sx={{ transform: 'translateY(20px)' }} spacing={3}>
										<Button
											onClick={handleWalkathonReport}
											sx={{ textTransform: 'none', height: 40 }}
											startIcon={<TaskAlt />}
											variant="contained"
										>
											Generate
										</Button>
									</Stack>
								</Stack>
							</Stack>
						</>
					</MiniCard>
				</Box>
			)}
			{output}
		</>
	);
};

export default Report;
