import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import DownloadsTable from './Table';

const Downloads = () => {
	return (
		<>
			<Box>
				<Stack direction={'row'} justifyContent={'space-between'} mb={3}>
					<Stack direction={'column'}>
						<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
							Files
						</Typography>
					</Stack>
				</Stack>
				<DownloadsTable />
			</Box>
		</>
	);
};

export default Downloads;
