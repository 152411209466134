import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	TextFieldProps,
} from '@mui/material';
import React, { FC } from 'react';
import { Field, useField } from 'formik';

import { FieldProps, getIn } from 'formik';

export const FormTextField: FC<FieldProps & TextFieldProps> = (props) => {
	const { error, helperText, field, form, ...rest } = props;

	const isTouched: boolean = getIn(form.touched, field.name);
	const errorMessage: string = getIn(form.errors, field.name);

	return (
		<TextField
			variant="outlined"
			size="small"
			className={props.className}
			error={error ?? Boolean(isTouched && errorMessage)}
			helperText={helperText ?? (isTouched && errorMessage ? errorMessage : null)}
			fullWidth
			{...rest}
			{...field}
			sx={{
				background: `${props.disabled ? 'var(--invalidBackground)' : 'initial'}`,
				fontSize: 5,
			}}
		/>
	);
};

export const TextFields = ({ placeholder, ...props }: any) => {
	const [field, meta] = useField(props);
	return (
		<>
			<Field
				fullWidth
				as={TextField}
				id="outlined-basic"
				placeholder={placeholder}
				variant="outlined"
				{...field}
				{...props}
				error={meta.touched && Boolean(meta.error)}
				helperText={meta.touched && meta.error}
			/>
		</>
	);
};

export const SelectFields = ({ label, list, ...props }: any) => {
	const [field, meta] = useField(props);

	return (
		<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
			<InputLabel id="demo-simple-select-label">{label}</InputLabel>
			<Select
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				label="Age"
				error={meta.touched && Boolean(meta.error)}
				{...field}
				{...props}
			>
				{list.map((item: any, i: any) => (
					<MenuItem key={item.id} value={item.id}>
						{item.firstName ? item.firstName : item.name ? item.name : item.id}
					</MenuItem>
				))}
			</Select>
			<FormHelperText>{meta.touched && meta.error}</FormHelperText>
		</FormControl>
	);
};
