import {
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
	Backdrop,
} from '@mui/material';
import { Box } from '@mui/system';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import React from 'react';
import { MiniCard } from '../Card';
import { organizationType, hcaOrderGroups, orderStatus } from '../mock';
import { useStyles } from '../report.style';
import useHCAMaterialsActions from 'hooks/useHCAMaterialsActions';
import { GlobalContexts } from 'contexts/GlobalContext';
import { IHCAOrderReport, StateType, HCAMaterials } from 'contexts/intialstates/Types';
import { Close, TaskAlt } from '@mui/icons-material';
import useReportActions from 'hooks/useReportAction';
import SummarizedReport from './SummarizedReport';
import DetailedReport from './DetailedReport';
import { FilterProps } from '../Configure';
import { ContentSkeleton } from '../DonationsSummary';

export interface HCAOrderReportPayload {
	start: any;
	end: any;
	groupby: string;
	isdetailed: boolean;
	organizationtypes?: string;
	hcamaterialids?: number | string;
	hcaorderstatusids?: string;
}
export interface FormData {
	start: any;
	end: any;
	groupby: string;
	isdetailed: boolean;
	organizationtypes?: string[];
	hcamaterialids?: string[];
	hcaorderstatusids?: string[];
}
interface Props {
	hcaOrderReport: StateType<IHCAOrderReport>;
	setHcaOrderReport: React.Dispatch<React.SetStateAction<StateType<IHCAOrderReport>>>;
	filterContols: FilterProps;
	setFilterControls: React.Dispatch<React.SetStateAction<FilterProps>>;
}
const HCAOrderReport = ({ hcaOrderReport, setHcaOrderReport, filterContols, setFilterControls }: Props) => {
	const classes = useStyles();
	const { setSimpleErrorSnack, simpleErrorSnack } = React.useContext(GlobalContexts);

	const { getHCAOrderReport, getHCAOrderReportPdf } = useReportActions();
	const { getAllHCAMaterials } = useHCAMaterialsActions();

	const [hcaMaterials, setHcaMaterials] = React.useState<StateType<HCAMaterials[]>>({
		data: null,
		loading: false,
		error: null,
	});

	const [output, setOutput] = React.useState<any>();
	const [loading, setLoading] = React.useState(true);

	const [formData, setFormData] = React.useState<FormData>({
		start: '',
		end: '',
		groupby: '',
		isdetailed: false,
		organizationtypes: [],
		hcamaterialids: [],
		hcaorderstatusids: [],
	});

	React.useEffect(() => {
		getAllHCAMaterials({ hcaMaterials, setHcaMaterials });
	}, []);
	React.useEffect(() => {
		if (hcaMaterials.loading) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [hcaMaterials.loading]);

	const handleSubmit = () => {
		if (!formData) return;

		if (!formData.groupby) {
			setSimpleErrorSnack({
				message: 'Group report by is required',
				show: true,
				severity: 'error',
			});
			return;
		}
		if (!formData.start) {
			setSimpleErrorSnack({
				message: 'Start date is required',
				show: true,
				severity: 'error',
			});
			return;
		}
		if (!formData.end) {
			setSimpleErrorSnack({
				message: 'End date is required',
				show: true,
				severity: 'error',
			});
			return;
		}
		getHCAOrderReport({
			hcaOrderReport,
			setHcaOrderReport,
			payload: {
				...formData,
				start: `${formData.start.getFullYear()}-${formData.start.getMonth() + 1}-${formData.start.getDate()}`,
				end: `${formData.end.getFullYear()}-${formData.end.getMonth() + 1}-${formData.end.getDate()}`,
				organizationtypes: formData.organizationtypes?.toString(),
				hcamaterialids: formData.hcamaterialids?.toString(),
				hcaorderstatusids: formData.hcaorderstatusids?.toString(),
			},
		});
	};
	const handlePdfDownload = async () => {
		const payload = {
			...formData,
			start: `${formData.start.getFullYear()}-${formData.start.getMonth() + 1}-${formData.start.getDate()}`,
			end: `${formData.end.getFullYear()}-${formData.end.getMonth() + 1}-${formData.end.getDate()}`,
			organizationtypes: formData.organizationtypes?.toString(),
			hcamaterialids: formData.hcamaterialids?.toString(),
			hcaorderstatusids: formData.hcaorderstatusids?.toString(),
		};
		await getHCAOrderReportPdf(payload);
	};

	React.useEffect(() => {
		const reportOutput = () => {
			if (hcaOrderReport.loading) {
				setSimpleErrorSnack({
					message: 'processing...',
					severity: 'info',
					show: true,
					hide: 20000,
				});
				return;
			}
			if (hcaOrderReport.data?.organizationCount === 0) {
				setSimpleErrorSnack({
					message: 'No Order activity found',
					severity: 'error',
					show: true,
					hide: 4000,
				});
				return;
			}

			if (!formData.isdetailed && hcaOrderReport.data) {
				setOutput(
					<SummarizedReport
						getHCAOrderReportPdf={handlePdfDownload}
						data={hcaOrderReport.data}
						formData={formData}
					/>,
				);
				setSimpleErrorSnack({
					...simpleErrorSnack,
					show: false,
					hide: 4000,
				});
				return;
			}
			if (formData.isdetailed && hcaOrderReport.data) {
				setOutput(
					<DetailedReport
						getHCAOrderReportPdf={handlePdfDownload}
						data={hcaOrderReport.data}
						formData={formData}
					/>,
				);
				setSimpleErrorSnack({
					...simpleErrorSnack,
					show: false,
					hide: 4000,
				});
				return;
			}
		};
		reportOutput();
	}, [hcaOrderReport]);

	const handleChange = (event: any, current: any) => {
		const {
			target: { value },
		} = event;
		setFormData({ ...formData, [current]: typeof value === 'string' ? value.split(',') : value });
	};

	return (
		<>
			{loading ? (
				<>
					{filterContols.configure && (
						<Box mb={2}>
							<MiniCard title="Configure">
								<ContentSkeleton length={3} />
							</MiniCard>
						</Box>
					)}
					{filterContols.filter && (
						<Box mb={2}>
							<MiniCard title="Filters">
								<ContentSkeleton length={3} />
							</MiniCard>
						</Box>
					)}
				</>
			) : (
				<>
					<>
						{filterContols.configure && (
							<Box mb={2}>
								<MiniCard title="Configure">
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack direction="column" spacing={2}>
												<Box className={classes.formLabelText}>Group Report by *</Box>
												<Select
													fullWidth
													size={'small'}
													value={formData?.groupby}
													onChange={(e) =>
														setFormData({
															...formData,
															groupby: e.target.value,
														})
													}
												>
													{hcaOrderGroups.map((x) => (
														<MenuItem key={x.id} value={x.id}>
															{x.label}
														</MenuItem>
													))}
												</Select>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack direction="column" spacing={2}>
												<Box className={classes.formLabelText}>Start Date *</Box>
												<Box>
													<BasicDatePicker
														size="small"
														value={formData?.start || null}
														setValue={(e) =>
															setFormData({
																...formData,
																start: e.target.value,
															})
														}
													/>
												</Box>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack direction="column" spacing={2}>
												<Box className={classes.formLabelText}>End Date *</Box>
												<Box>
													<BasicDatePicker
														size="small"
														value={formData?.end || null}
														setValue={(e) =>
															setFormData({
																...formData,
																end: e.target.value,
															})
														}
													/>
												</Box>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<FormControl sx={{ '& .css-ahj2mt-MuiTypography-root': { fontSize: 14 } }}>
												<RadioGroup
													aria-labelledby="demo-controlled-radio-buttons-group"
													name="controlled-radio-buttons-group"
													value={formData?.isdetailed}
													onChange={(e) => {
														setFormData({ ...formData, isdetailed: e.target.value === 'true' });
													}}
												>
													<FormControlLabel value={false} control={<Radio />} label="Summary Report" />
													<FormControlLabel value={true} control={<Radio />} label="Detailed Report" />
												</RadioGroup>
											</FormControl>
										</Grid>
										{filterContols.editConfigure && (
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Stack direction="row" mt={3} spacing={1} alignItems="center">
													<Button
														onClick={handleSubmit}
														sx={{ textTransform: 'none', height: 40 }}
														startIcon={<TaskAlt />}
														variant="contained"
													>
														Apply
													</Button>
													<Button
														onClick={() => setFilterControls({ ...filterContols, configure: false })}
														sx={{ textTransform: 'none', height: 40 }}
														variant="outlined"
														startIcon={<Close />}
													>
														Cancel
													</Button>
												</Stack>
											</Grid>
										)}
									</Grid>
								</MiniCard>
							</Box>
						)}

						{filterContols.filter && (
							<Box mb={2}>
								<MiniCard title="Filters">
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack className={classes.fieldGridItem} direction="column" spacing={2}>
												<Box className={classes.formLabelText}>Organization Type</Box>
												<FormControl>
													<Select
														fullWidth
														size="small"
														multiple
														value={formData?.organizationtypes}
														onChange={(e) => handleChange(e, 'organizationtypes')}
													>
														{organizationType?.map((name: any) => (
															<MenuItem key={name.id} value={name.id}>
																{name.label}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack className={classes.fieldGridItem} direction="column" spacing={2}>
												<Box className={classes.formLabelText}>HCA Material</Box>
												<FormControl>
													<Select
														fullWidth
														size="small"
														multiple
														value={formData?.hcamaterialids}
														onChange={(e) => handleChange(e, 'hcamaterialids')}
													>
														{hcaMaterials?.data?.map((name: any) => (
															<MenuItem key={name.id} value={name.id}>
																{name.description}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack className={classes.fieldGridItem} direction="column" spacing={2}>
												<Box className={classes.formLabelText}>HCA Order Status</Box>
												<FormControl>
													<Select
														fullWidth
														size="small"
														multiple
														value={formData?.hcaorderstatusids}
														onChange={(e) => handleChange(e, 'hcaorderstatusids')}
													>
														{orderStatus?.map((name: any) => (
															<MenuItem key={name} value={name}>
																{name}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Stack>
										</Grid>
										{filterContols.editFilter && (
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Stack direction="row" alignItems="center" mt={3} spacing={1}>
													<Button
														onClick={handleSubmit}
														sx={{ textTransform: 'none', height: 40 }}
														startIcon={<TaskAlt />}
														variant="contained"
													>
														Apply
													</Button>
													<Button
														onClick={() => setFilterControls({ ...filterContols, filter: false })}
														sx={{ textTransform: 'none', height: 40 }}
														startIcon={<Close />}
														variant="outlined"
													>
														Cancel
													</Button>
												</Stack>
											</Grid>
										)}
									</Grid>
								</MiniCard>
							</Box>
						)}

						{!hcaOrderReport.data && (
							<Stack direction="row" ml={5} mt={2}>
								<Button
									onClick={handleSubmit}
									sx={{ textTransform: 'none', height: 40 }}
									startIcon={<TaskAlt />}
									variant="contained"
								>
									Generate
								</Button>
							</Stack>
						)}
					</>

					{output}
				</>
			)}
		</>
	);
};

export default HCAOrderReport;
