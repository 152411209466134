import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    title: {
        color: 'var(--mainColor)',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
    },
    whiteBtn: {
        backgroundColor: 'var(--white) !important',
        color: 'var(--mainColor) !important',
    },
    disabledInput: {
        background: 'var(--invalidBackground)'
    },
    tableHead: {
        '& .MuiTableRow-head': {
            '& .MuiTableCell-head': {
                color: '#1976D2',
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '16px',
                borderBottom: '1px solid #DDE5EA',
            },
        },
    },
    tableRowWrap: {
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            fontSize: '16px',
            lineHeight: '26px',
            padding: '5px',
        },
    },
});
