import {
	Button,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Skeleton,
	Stack,
	TablePagination,
	TextField,
	Autocomplete,
} from '@mui/material';
import { Box } from '@mui/system';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import React, { useEffect, useState } from 'react';
import { MiniCard } from '../Card';
import { organizationType, groupList } from '../mock';
import { useStyles } from '../report.style';
import usePaymentmethodsActions from 'hooks/usePaymentMethodAction';
import useSocietyActions from 'hooks/useSocietyAction';
import useDonationTypesActions from 'hooks/useDonationTypesActions';
import useProvinceActions from 'hooks/useProvinceActions';
import useReceiptScheduleActions, { ReceiptSchedule } from 'hooks/useReceiptScheduleActions';
import { GlobalContexts } from 'contexts/GlobalContext';
import {
	DonationDetailedReport,
	DonationSummaryReport,
	DonationTypes,
	PaymentMethod,
	Province,
	Society,
	StateType,
} from 'contexts/intialstates/Types';
import { Close, FilterAltOutlined, KeyboardArrowLeft, Settings, TaskAlt } from '@mui/icons-material';
import useReportActions from 'hooks/useReportAction';
import SummarizedReport from './SummarizedReport';
import DetailedReport from './DetailedReport';
import { FilterProps } from '../Configure';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import useDebounce from 'Components/Reusable/hooks/debounce';

export interface FormDataPayload {
	start: any;
	end: any;
	groupby: string;
	category: string;
	isdetailed: boolean;
	receiptFrequency?: string[];
	organizationtypes?: string[];
	societyids?: string[];
	donationtype?: {
		id: number;
		label: string;
	}[];
	paymentmethodids?: string[];
	provinceids?: string[];
	batchno?: number;
	pageSize?: number;
	pageNumber?: number;
}
export interface DonationsSummaryPayload {
	start: any;
	end: any;
	groupby: string;
	category: string;
	isdetailed: boolean;
	receiptFrequency?: string;
	organizationtypes?: string;
	societyids?: string;
	donationtypeids?: string;
	paymentmethodids?: string;
	provinceids?: string;
	batchno?: number;
	pageNumber?: number;
	pageSize?: number;
}
export const ContentSkeleton = ({ length }: any) => {
	const arr = new Array(length).fill('blank');
	return (
		<>
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
				{arr.map((ele: any, index: number) => (
					<Grid key={index} item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
						<Skeleton variant="text" width={'40%'} height={20} />
						<Skeleton variant="rectangular" width={'100%'} height={30} />
					</Grid>
				))}
			</Grid>
		</>
	);
};

const DonationsSummary = () => {
	const [allSociety, setAllSoceity] = React.useState<StateType<Society[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [allPM, setPM] = React.useState<StateType<PaymentMethod[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const classes = useStyles();
	const { setSimpleErrorSnack, simpleErrorSnack } = React.useContext(GlobalContexts);
	const navigate = useNavigate();

	const { getPaymentMethods } = usePaymentmethodsActions();
	const { getDonationTypeBySearch } = useDonationTypesActions();
	const { getReceiptSchedule } = useReceiptScheduleActions();
	const { getProvince } = useProvinceActions();
	const { getSocieties } = useSocietyActions();
	const { getDonationsSummaryReport, getDonationsDetailedReport } = useReportActions();
	const [panelControls, setPanelControls] = React.useState<{
		configure: boolean;
		filter: boolean;
	}>({ configure: false, filter: false });
	const [donationsSummaryReport, setDonationsSummaryReport] = React.useState<
		StateType<DonationSummaryReport[]>
	>({
		data: null,
		loading: false,
		error: null,
	});
	const [donationsDetailedReport, setDonationsDetailedReport] = React.useState<
		StateType<DonationDetailedReport>
	>({
		data: null,
		loading: false,
		error: null,
	});
	const [filterContols, setFilterControls] = React.useState<FilterProps>({
		configure: true,
		filter: true,
		editFilter: false,
		editConfigure: false,
	});
	const [province, setProvince] = React.useState<StateType<Province[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [receiptSchedule, setReceiptSchedule] = React.useState<StateType<ReceiptSchedule[]>>({
		data: null,
		loading: false,
		error: null,
	});

	const [loading, setLoading] = React.useState(true);
	const [shouldPaginate, setShouldPaginate] = React.useState(false);

	const [output, setOutput] = React.useState<any>();
	const [pageSize, setPageSize] = React.useState<number>(10);
	const [pageNumber, setPageNumber] = React.useState<number>(0);

	const handleChange = (event: any, current: any) => {
		const {
			target: { value },
		} = event;
		setFormData({ ...formData, [current]: typeof value === 'string' ? value.split(',') : value });
	};
	const [formData, setFormData] = React.useState<FormDataPayload>({
		start: '',
		end: '',
		groupby: '',
		category: '',
		isdetailed: false,
		receiptFrequency: [],
		organizationtypes: [],
		societyids: [],
		donationtype: [],
		paymentmethodids: [],
		provinceids: [],
	});
	const [donationTypeSearchTerm, setDonationTypeSearchTerm] = useState('');
	const debouncedDonationSearchTerm = useDebounce(donationTypeSearchTerm, 1000);
	const [donationType, setDonationType] = useState<StateType<DonationTypes[]>>({
		data: null,
		loading: false,
		error: null,
	});

	useEffect(() => {
		if (!debouncedDonationSearchTerm) return;
		getDonationTypeBySearch({ donationType, setDonationType, searchTerm: debouncedDonationSearchTerm });
	}, [debouncedDonationSearchTerm]);

	React.useEffect(() => {
		if (donationsSummaryReport.loading || donationsDetailedReport.loading) return;
		if (
			donationsSummaryReport.data ||
			donationsSummaryReport.error ||
			donationsDetailedReport.data ||
			donationsDetailedReport.error
		) {
			setPanelControls({
				configure: true,
				filter: true,
			});
			setFilterControls({
				...filterContols,
				editFilter: true,
				editConfigure: true,
			});
		}

		if (
			(donationsSummaryReport.data && donationsSummaryReport.data.length !== 0) ||
			(donationsDetailedReport.data && donationsDetailedReport.data.body.length !== 0)
		)
			setFilterControls({
				configure: false,
				filter: false,
				editFilter: true,
				editConfigure: true,
			});
	}, [donationsSummaryReport, donationsDetailedReport]);

	React.useEffect(() => {
		getSocieties({ allSociety, setAllSoceity });
		getPaymentMethods({ allPM, setPM });
		getReceiptSchedule({ receiptSchedule, setReceiptSchedule });
		getProvince({ province, setProvince });
	}, []);

	React.useEffect(() => {
		if (
			allSociety?.loading ||
			donationType?.loading ||
			province?.loading ||
			receiptSchedule?.loading ||
			allPM?.loading
		) {
			setLoading(true);
		} else {
			setLoading(false);
		}
	}, [allSociety?.loading, province?.loading, receiptSchedule?.loading, allPM?.loading]);

	const handleSubmit = async () => {
		if (!formData) return;

		if (!formData.groupby) {
			setSimpleErrorSnack({
				message: 'Group report by is required',
				show: true,
				severity: 'error',
			});
			return;
		}
		if (!formData.start) {
			setSimpleErrorSnack({
				message: 'Start date is required',
				show: true,
				severity: 'error',
			});
			return;
		}
		if (!formData.end) {
			setSimpleErrorSnack({
				message: 'end date is required',
				show: true,
				severity: 'error',
			});
			return;
		}
		if (formData.isdetailed) {
			await getDonationsDetailedReport({
				donationsDetailedReport,
				setDonationsDetailedReport,
				payload: {
					...formData,
					start: `${formData.start.getFullYear()}-${
						formData.start.getMonth() + 1
					}-${formData.start.getDate()}`,
					end: `${formData.end.getFullYear()}-${formData.end.getMonth() + 1}-${formData.end.getDate()}`,
					organizationtypes: formData.organizationtypes?.toString(),
					receiptFrequency: formData.receiptFrequency?.toString(),
					societyids: formData.societyids?.toString(),
					provinceids: formData.provinceids?.toString(),
					paymentmethodids: formData.paymentmethodids?.toString(),
					donationtypeids: formData?.donationtype?.map((item) => item.id).join(','),
					batchno: formData?.batchno,
					pageNumber: pageNumber,
					pageSize: pageSize,
				},
			});
			return;
		}
		await getDonationsSummaryReport({
			donationsSummaryReport,
			setDonationsSummaryReport,
			payload: {
				...formData,
				start: `${formData.start.getFullYear()}-${formData.start.getMonth() + 1}-${formData.start.getDate()}`,
				end: `${formData.end.getFullYear()}-${formData.end.getMonth() + 1}-${formData.end.getDate()}`,
				organizationtypes: formData.organizationtypes?.toString(),
				receiptFrequency: formData.receiptFrequency?.toString(),
				societyids: formData.societyids?.toString(),
				provinceids: formData.provinceids?.toString(),
				paymentmethodids: formData.paymentmethodids?.toString(),
				donationtypeids: formData.donationtype?.map((item) => item.id).join(','),
				batchno: formData?.batchno,
			},
		});
	};

	React.useEffect(() => {
		const reportOutput = () => {
			if (donationsSummaryReport.loading) {
				return;
			}
			if (donationsSummaryReport.data?.length === 0) {
				setSimpleErrorSnack({
					message: 'No record found',
					severity: 'info',
					show: true,
					hide: 4000,
				});
				return;
			}

			if (!formData.isdetailed && donationsSummaryReport.data) {
				setOutput(<SummarizedReport data={donationsSummaryReport.data} formData={formData} />);
				setSimpleErrorSnack({
					...simpleErrorSnack,
					show: false,
					hide: 4000,
				});
				return;
			}
		};
		reportOutput();
	}, [donationsSummaryReport.loading, donationsSummaryReport.data]);
	React.useEffect(() => {
		const reportOutput = () => {
			if (donationsDetailedReport.loading) {
				return;
			}
			if (donationsDetailedReport.data?.body.length === 0) {
				setSimpleErrorSnack({
					message: 'No record found',
					severity: 'info',
					show: true,
					hide: 4000,
				});
				return;
			}

			if (formData.isdetailed && donationsDetailedReport.data) {
				setOutput(
					<DetailedReport
						data={donationsDetailedReport.data}
						loading={donationsDetailedReport.loading}
						formData={formData}
					/>,
				);
				setSimpleErrorSnack({
					...simpleErrorSnack,
					show: false,
					hide: 4000,
				});
				return;
			}
		};
		reportOutput();
	}, [donationsDetailedReport.loading, donationsDetailedReport.data]);
	useEffect(() => {
		setShouldPaginate(true);
		if (!shouldPaginate) return;
		if (!formData.isdetailed) return;
		handleSubmit();
	}, [pageNumber, pageSize]);
	const grouping = (value: string) => {
		return value.split(',');
	};
	const handleChangePage = (event: unknown, newPage: number) => {
		setPageNumber(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPageNumber(0);
	};

	const handleDoanationSelectChange = (field: string, value: any) => {
		setFormData({
			...formData,
			[field]: value,
		});
	};

	return (
		<Box sx={{ background: 'transparent' }}>
			<Stack
				direction={{ xs: 'row', sm: 'column' }}
				alignItems={{ xs: 'center', sm: 'initial' }}
				justifyContent={{ xs: 'center', sm: 'initial' }}
				spacing={{ xs: 3, sm: 0 }}
				sx={{ mb: 3 }}
			>
				<Stack
					alignItems={'center'}
					justifyContent={'center'}
					sx={{ display: { xs: 'flex', sm: 'none' }, background: '#fff' }}
				>
					<IconButton
						id="demo-customized-button"
						aria-haspopup="true"
						onClick={() => {
							navigate('../');
						}}
					>
						<KeyboardArrowLeft color="primary" />
					</IconButton>
				</Stack>
				<Stack
					alignItems="center"
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent={'space-between'}
					spacing={1}
					width="100%"
				>
					<Stack
						alignItems={'center'}
						direction="row"
						justifyContent={'flex-start'}
						spacing={{ xs: 0, sm: 4 }}
						mb={{ xs: 1, sm: 0 }}
						width="100%"
					>
						<Stack
							alignItems={'center'}
							justifyContent={'center'}
							sx={{ display: { xs: 'none', sm: 'flex' }, background: '#fff' }}
						>
							<IconButton
								id="demo-customized-button"
								aria-haspopup="true"
								onClick={() => {
									navigate('../');
								}}
							>
								<KeyboardArrowLeft color="primary" />
							</IconButton>
						</Stack>
						<Stack direction="row" justifyContent={'space-between'} width="100%">
							<Box
								sx={{
									fontSize: '32px',
									fontWeight: 700,
									color: 'var(--mainColor)',
									wordWrap: 'normal',
								}}
							>
								Donations Report
							</Box>
							<Stack direction="row" alignItems="center" spacing={1}>
								{panelControls.filter && (
									<IconButton
										id="demo-customized-button"
										sx={{
											background: 'white',
											borderRadius: '6px',
											width: 40,
											height: 30,
											border: '1px solid var(--mainColor)',
										}}
										aria-haspopup="true"
										onClick={() =>
											setFilterControls({
												...filterContols,
												filter: !filterContols.filter,
												configure: false,
												editFilter: true,
											})
										}
									>
										<FilterAltOutlined sx={{ fontSize: 16, color: 'var(--mainColor)' }} />
									</IconButton>
								)}
								{panelControls.configure && (
									<IconButton
										id="demo-customized-button"
										sx={{ background: 'var(--mainColor)', borderRadius: '6px', width: 40, height: 30 }}
										aria-haspopup="true"
										onClick={() =>
											setFilterControls({
												...filterContols,
												configure: !filterContols.configure,
												filter: false,
												editConfigure: true,
											})
										}
									>
										<Settings sx={{ fontSize: 16, color: 'white' }} />
									</IconButton>
								)}
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</Stack>

			<>
				{loading ? (
					<>
						{filterContols.configure && (
							<Box mb={2}>
								<MiniCard title="Configure">
									<ContentSkeleton length={3} />
								</MiniCard>
							</Box>
						)}
						{filterContols.filter && (
							<Box mb={2}>
								<MiniCard title="Filters">
									<ContentSkeleton length={6} />
								</MiniCard>
							</Box>
						)}
					</>
				) : (
					<>
						{filterContols.configure && (
							<Box mb={2}>
								<MiniCard title="Configure">
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack direction="column" spacing={2}>
												<Box className={classes.formLabelText}>Group Report by *</Box>
												<Select
													size={'small'}
													fullWidth
													value={`${formData?.groupby},${formData?.category}`}
													onChange={(e) =>
														setFormData({
															...formData,
															groupby: grouping(e.target.value)[0],
															category: grouping(e.target.value)[1],
														})
													}
												>
													{groupList.map((x) => (
														<MenuItem key={x.id} value={x.id}>
															{x.label}
														</MenuItem>
													))}
												</Select>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3}>
											<Stack direction="column" spacing={2}>
												<Box className={classes.formLabelText}>Date Range *</Box>
												<Stack direction="row" pr={{ lg: 5, md: 0 }}>
													<BasicDatePicker
														size="small"
														value={formData.start}
														setValue={(e) =>
															setFormData({
																...formData,
																start: e.target.value,
															})
														}
													/>
													<Stack m={1}>To</Stack>
													<BasicDatePicker
														size="small"
														value={formData.end}
														setValue={(e) =>
															setFormData({
																...formData,
																end: e.target.value,
															})
														}
													/>
												</Stack>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<FormControl sx={{ '& .css-ahj2mt-MuiTypography-root': { fontSize: 14 } }}>
												<RadioGroup
													aria-labelledby="demo-controlled-radio-buttons-group"
													name="controlled-radio-buttons-group"
													value={formData?.isdetailed}
													onChange={(e) => {
														setFormData({ ...formData, isdetailed: e.target.value === 'true' });
													}}
												>
													<FormControlLabel value={false} control={<Radio />} label="Summary Report" />
													<FormControlLabel value={true} control={<Radio />} label="Detailed Report" />
												</RadioGroup>
											</FormControl>
										</Grid>
										{filterContols.editConfigure && (
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Stack direction="row" mt={3} spacing={1} alignItems="center">
													<LoadingButton
														onClick={handleSubmit}
														sx={{ textTransform: 'none', height: 40 }}
														startIcon={<TaskAlt />}
														variant="contained"
														loadingPosition="start"
														loading={
															donationsSummaryReport.loading === true ||
															donationsDetailedReport.loading === true
														}
													>
														Apply
													</LoadingButton>
													<Button
														onClick={() => setFilterControls({ ...filterContols, configure: false })}
														sx={{ textTransform: 'none', height: 40 }}
														variant="outlined"
														startIcon={<Close />}
													>
														Cancel
													</Button>
												</Stack>
											</Grid>
										)}
									</Grid>
								</MiniCard>
							</Box>
						)}

						{filterContols.filter && (
							<Box mb={2}>
								<MiniCard title="Filters">
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }} mt={2}>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack className={classes.fieldGridItem} direction="column" spacing={2}>
												<Box className={classes.formLabelText}>Receipt Frequency</Box>
												<FormControl>
													<Select
														fullWidth
														size="small"
														labelId="demo-multiple-name-label"
														id="demo-multiple-name"
														multiple
														value={formData?.receiptFrequency}
														onChange={(e) => handleChange(e, 'receiptFrequency')}
													>
														{receiptSchedule.data?.map((name: any) => (
															<MenuItem key={name.id} value={name.id}>
																{name.schedule === 'Yearly' ? 'Year-End' : name.schedule}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack className={classes.fieldGridItem} direction="column" spacing={2}>
												<Box className={classes.formLabelText}>Organization Type</Box>
												<FormControl>
													<Select
														fullWidth
														size="small"
														labelId="demo-multiple-name-label"
														id="demo-multiple-name"
														multiple
														value={formData.organizationtypes}
														onChange={(e) => handleChange(e, 'organizationtypes')}
													>
														{organizationType.map((name: any) => (
															<MenuItem key={name.id} value={name.id}>
																{name.label}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack className={classes.fieldGridItem} direction="column" spacing={2}>
												<Box className={classes.formLabelText}>Society</Box>
												<FormControl>
													<Select
														fullWidth
														size="small"
														labelId="demo-multiple-name-label"
														id="demo-multiple-name"
														multiple
														value={formData?.societyids}
														onChange={(e) => handleChange(e, 'societyids')}
													>
														{allSociety?.data
															?.filter((curr) => curr.code !== 'AN')
															?.map((name: any) => (
																<MenuItem key={name.id} value={name.id}>
																	{name.code}
																</MenuItem>
															))}
													</Select>
												</FormControl>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack className={classes.fieldGridItem} direction="column" spacing={2}>
												<Box className={classes.formLabelText}>Donation Type</Box>
												<Autocomplete
													freeSolo
													value={formData.donationtype}
													loading={donationType.loading}
													loadingText="loading..."
													onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
														handleDoanationSelectChange('donationtype', val);
													}}
													fullWidth
													options={(donationType?.data || []).map((donation: DonationTypes) => ({
														id: donation.id,
														label: `${donation.type} - ${donation.description}`,
													}))}
													renderOption={(props, option) => (
														<Box component="li" {...props} key={option.id}>
															{option.label}
														</Box>
													)}
													multiple
													renderInput={(params) => (
														<TextField
															{...params}
															variant="outlined"
															label=""
															InputProps={{
																...params.InputProps,
															}}
															onChange={(event: any) => {
																setDonationTypeSearchTerm(event.target.value);
															}}
															size={'small'}
															placeholder={'Search donation type'}
														/>
													)}
												/>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack className={classes.fieldGridItem} direction="column" spacing={2}>
												<Box className={classes.formLabelText}>Payment Method</Box>
												<FormControl>
													<Select
														fullWidth
														size="small"
														labelId="demo-multiple-name-label"
														id="demo-multiple-name"
														multiple
														value={formData?.paymentmethodids}
														onChange={(e) => handleChange(e, 'paymentmethodids')}
													>
														{allPM?.data?.map((name: any) => (
															<MenuItem key={name.id} value={name.id}>
																{name.name}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack className={classes.fieldGridItem} direction="column" spacing={2}>
												<Box className={classes.formLabelText}>Province</Box>
												<FormControl>
													<Select
														fullWidth
														size="small"
														labelId="demo-multiple-name-label"
														id="demo-multiple-name"
														multiple
														value={formData?.provinceids}
														onChange={(e) => handleChange(e, 'provinceids')}
													>
														{province.data?.map((name: any) => (
															<MenuItem key={name.id} value={name.id}>
																{name.provinceName}
															</MenuItem>
														))}
													</Select>
												</FormControl>
											</Stack>
										</Grid>
										<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
											<Stack className={classes.fieldGridItem} direction="column" spacing={2}>
												<Box className={classes.formLabelText}>Batch Number</Box>
												<TextField
													size={'small'}
													fullWidth
													placeholder={'Batch Number'}
													value={formData.batchno}
													onChange={(e) => handleChange(e, `batchno`)}
												/>
											</Stack>
										</Grid>

										{filterContols.editFilter && (
											<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
												<Stack direction="row" alignItems="center" mt={3} spacing={1}>
													<LoadingButton
														onClick={handleSubmit}
														sx={{ textTransform: 'none', height: 40 }}
														startIcon={<TaskAlt />}
														variant="contained"
														loadingPosition="start"
														loading={
															donationsSummaryReport.loading === true ||
															donationsDetailedReport.loading === true
														}
													>
														Apply
													</LoadingButton>
													<Button
														onClick={() => setFilterControls({ ...filterContols, filter: false })}
														sx={{ textTransform: 'none', height: 40 }}
														startIcon={<Close />}
														variant="outlined"
													>
														Cancel
													</Button>
												</Stack>
											</Grid>
										)}
									</Grid>
								</MiniCard>
							</Box>
						)}

						{!donationsSummaryReport.data &&
						!donationsSummaryReport.error &&
						!donationsDetailedReport.data &&
						!donationsDetailedReport.error ? (
							<Stack direction="row" ml={5} mt={2}>
								<LoadingButton
									onClick={handleSubmit}
									sx={{ textTransform: 'none', height: 40 }}
									startIcon={<TaskAlt />}
									variant="contained"
									loadingPosition="start"
									loading={
										donationsSummaryReport.loading === true || donationsDetailedReport.loading === true
									}
								>
									Generate
								</LoadingButton>
							</Stack>
						) : null}

						{output}
						{filterContols.editFilter && formData?.isdetailed && donationsDetailedReport?.data && (
							<Stack direction={'row'} alignItems="center" justifyContent={'flex-end'} spacing={3}>
								{donationsDetailedReport.loading && <span>Processing...</span>}
								<TablePagination
									component="div"
									count={donationsDetailedReport?.data?.totalRecords as number}
									page={pageNumber as number}
									onPageChange={handleChangePage}
									rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
									rowsPerPage={pageSize as number}
									onRowsPerPageChange={handleChangeRowsPerPage}
								/>
							</Stack>
						)}
					</>
				)}
			</>
		</Box>
	);
};

export default DonationsSummary;
