import React from 'react';
import SubPanel from 'Components/Common/SubPanel/SubPanel';
import {
	Receipt,
	ConfirmationNumber,
	Subtitles,
	SupportAgent,
	CardGiftcard,
	People,
	History,
} from '@mui/icons-material';

const cardArray = [
	{
		name: 'Receipt Letter',
		Icon: Receipt,
		link: 'receipt-template',
	},
	{
		name: 'Appeal',
		Icon: ConfirmationNumber,
		link: 'appeal',
	},
	{
		name: 'Parishes',
		Icon: Subtitles,
		link: 'parishes',
	},
	{
		name: 'Diocese',
		Icon: SupportAgent,
		link: 'diocese',
	},
	{
		name: 'Donation Type',
		Icon: CardGiftcard,
		link: 'donation-type',
	},
	{
		name: 'Gift Type',
		Icon: CardGiftcard,
		link: 'gift-type',
	},
	{
		name: 'User Management',
		Icon: People,
		link: 'users',
	},
	// {
	// 	name: 'Activity Log',
	// 	Icon: History,
	// 	link: 'activity-log',
	// },
];

const AdminPanel = () => {
	return (
		<>
			<SubPanel cardArrays={cardArray} />
		</>
	);
};

export default AdminPanel;
