import * as React from 'react';
import { MonetizationOn, Loop, PostAdd, Book, CalendarToday } from '@mui/icons-material';
import SubPanel from 'Components/Common/SubPanel/SubPanel';

const cardArray = [
	{
		name: 'Donations',
		Icon: MonetizationOn,
		link: 'deposits',
	},
	{
		name: 'Batch',
		Icon: PostAdd,
		link: 'batch',
	},
	{
		name: 'Recurring',
		Icon: Loop,
		link: 'template/deposits',
	},
	{
		name: 'Post-Dated Cheques',
		Icon: Book,
		link: 'post-dated-cheques',
	},
	{
		name: 'Deposit Slip',
		Icon: Book,
		link: 'deposit-slip',
	},
	{
		name: 'Non Cash Donations',
		Icon: MonetizationOn,
		link: 'non-cash-donations',
	},
	{
		name: 'Year End Receipts',
		Icon: CalendarToday,
		link: 'year-end-receipt',
	},
];

const Bookkeeping = () => {
	return (
		<>
			<SubPanel cardArrays={cardArray} />
		</>
	);
};

export default Bookkeeping;
