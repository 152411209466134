import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Fab, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDropzone, FileWithPath } from 'react-dropzone';
import { UploadImageSection } from 'Components/Common/Upload/ImageUpload';
import { LinearProgressWithLabel } from 'Components/Reusable/ProgressBar';
import { Delete, Save, Receipt, Subtitles, Add, KeyboardArrowLeft } from '@mui/icons-material';
import { green } from '@mui/material/colors';
import BatchHeader from './BatchHeader';
import DepositTable from 'Components/Deposits/DepositTable/DepositTable';
import { Batch, BatchUploadResponse, DefaultState, ProgressEvent } from './Batch';
import { GlobalContexts } from 'contexts/GlobalContext';
import { OrderType, StateType, Deposits, FilterPayload } from 'contexts/intialstates/Types';
import useActions from '../../hooks/useBatchActions';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import GenerateReceipt from 'Components/Reusable/GenerateReceipt/GenerateReceipt';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import LoadingButton from '@mui/lab/LoadingButton';
import useApi from 'hooks/useApi';
import moment from 'moment';
import { ButtonSkeleton } from 'Components/Deposits/Deposit';
import {
	DepositsTableHead,
	ColumnType,
	BatchDonationsTableHead,
} from 'Components/Common/Tables/TableHeaders';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import useUserActions from 'hooks/useUserActions';
import useDebounce from 'Components/Reusable/hooks/debounce';

const CreateEditBatch = () => {
	const { handleRoles } = useUserActions();
	const location = useLocation();
	const querySearch = new URLSearchParams(location.search);
	const type = querySearch.get('tab');
	const [isDeleted, setIsDeleted] = React.useState(false);
	const [isUpdating, setIsUpdating] = React.useState(false);
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const { id } = useParams();
	const navigate = useNavigate();
	const [progress, setProgress] = useState(0);
	const [triggerUpload, setTriggerUpload] = useState(false);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [reload, setIsReload] = React.useState(false);
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);
	const [searchDonor, setSearchDonor] = React.useState<string>('');
	const debouncedSearchTerm = useDebounce(searchDonor, 1000);
	const [defaultState, setDefaultState] = React.useState<DefaultState>({ id: null, open: false });
	const [EFTLoading, setEFTLoading] = useState(false);
	const [postLoading, setPostLoading] = useState(false);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const [papBankPayload, setPapBankPayload] = React.useState<{ status: number; depositId: number }[]>([]);
	const [batchUpload, setBatchUpload] = React.useState<StateType<BatchUploadResponse>>({
		loading: false,
		data: null,
		error: null,
	});
	const [singleBatch, setSingleBatch] = React.useState<StateType<Batch>>({
		loading: false,
		error: null,
		data: null,
	});

	const [canPostBatch, setCanPostBatch] = useState({ state: true, message: '' });
	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		maxFiles: 1,
		accept:
			'.csv, text/csv, text/plain, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
	});

	const { messageDispatch, messageState } = React.useContext(GlobalContexts);
	const { getSingleBatch, uploadFile, deleteBatchById, updateBatch, batchPost } = useActions();
	const { api } = useApi();

	useEffect(() => {
		if (!id) return;
		getSingleBatch({
			batchId: id,
			setSingleBatch,
			singleBatch,
			page,
			pageSize,
			filterPayload,
			orderBy,
			donorQuery: searchDonor,
		});
	}, [id, reload, filterPayload.length, orderBy, debouncedSearchTerm]);

	useEffect(() => {
		batchUploadSuccess();
	}, [batchUpload?.data?.statusCode, messageState?.action]);

	const batchUploadSuccess = () => {
		if (batchUpload?.data?.statusCode === 0 && messageState?.action) {
			setTriggerUpload(false);
			return navigate(`/bookkeeping/batch/${batchUpload?.data?.result?.batchId}?tab=cheque`);
		}
	};
	const upload = () => {
		if (files.length != 0)
			uploadFile({ setTriggerUpload, acceptedFiles, options, messageDispatch, setBatchUpload, batchUpload });
	};

	const files = acceptedFiles.map((file: FileWithPath) => {
		return (
			<UploadImageSection key={file.path}>
				{file.path} - {file.size} bytes
			</UploadImageSection>
		);
	});
	const batchData = singleBatch?.data;
	const validDeposits = singleBatch?.data?.deposits.findIndex(
		(deposit: Deposits) => deposit.itemStatus.status === 'Incomplete',
	);

	React.useEffect(() => {
		upload();
	}, [files.length]);
	const options = {
		onUploadProgress: (progressEvent: ProgressEvent) => {
			const { loaded, total } = progressEvent;
			const percent = Math.floor((loaded * 100) / total);
			setProgress(percent);
		},
	};

	// useEffect(() => {
	// 	if (type === 'papbank') {
	// 		const isPostable = singleBatch.data?.deposits.findIndex(
	// 			(ele: Deposits) => ele?.papBankDepositStatus?.id !== 1 && ele?.papBankDepositStatus?.id !== 2,
	// 		);
	// 		if (
	// 			(typeof isPostable === 'number' && isPostable > -1) ||
	// 			batchData?.batchStatus !== 'Completed' ||
	// 			singleBatch.data?.deposits.length === 0
	// 		) {
	// 			setCanPostBatch(true);
	// 		} else {
	// 			setCanPostBatch(false);
	// 		}
	// 	}
	useEffect(() => {
		const shouldPostBatch = () => {
			if (type === 'papbank') {
				const isPostable = singleBatch.data?.deposits.findIndex(
					(ele: Deposits) => ele?.papBankDepositStatus?.id !== 1 && ele?.papBankDepositStatus?.id !== 2,
				);
				if (typeof isPostable === 'number' && isPostable > -1)
					return setCanPostBatch({ state: true, message: 'All Donations must have a pass or fail status' });
				if (batchData?.batchStatus !== 'Completed')
					return setCanPostBatch({
						state: true,
						message: 'Only a Batch with status of "Completed" can be posted ',
					});
				if (singleBatch.data?.deposits.length === 0)
					return setCanPostBatch({ state: true, message: 'Cannot post an empty batch ' });
				return setCanPostBatch({ state: false, message: '' });
			}
		};
		shouldPostBatch();
	}, [singleBatch.data?.deposits, singleBatch.data?.deposits.length, batchData?.batchStatus]);

	const buttonSx = {
		...(progress && {
			bgcolor: green[500],
			'&:hover': {
				bgcolor: green[700],
			},
		}),
	};

	const cancelDeleteBatchById = () => {
		setDefaultState({ ...defaultState, id: null, open: false });
	};
	const handleOpenClose = (batchId: number) => {
		setDefaultState({ ...defaultState, id: batchId, open: true });
	};
	const handleClose = () => setDefaultState({ ...defaultState, open: false });

	const handleDelete = async () => {
		if (!defaultState?.id) return;
		setIsDeleted(true);
		const isSucess = await deleteBatchById(defaultState.id);
		if (isSucess) {
			navigate(`/bookkeeping/batch?tab=${type}`);
		}
		handleClose();
		setIsDeleted(false);
	};
	const handleUpdate = async () => {
		if (!singleBatch.data?.id) return;
		setIsUpdating(true);
		const isSucess = await updateBatch(singleBatch.data?.id, papBankPayload);
		if (isSucess) {
			setIsReload(!reload);
		}
		setIsUpdating(false);
	};

	const handleGenrateEFT = async () => {
		setEFTLoading(true);
		const response = await api.get(`batch/papbank/${id}/eftfile`);
		const url = window.URL.createObjectURL(new Blob([response?.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `PMS_${moment().format('yyMM')}.eft`);
		document.body.appendChild(link);
		link.click();
		link.remove();
		setEFTLoading(false);
	};

	const handlePost = async () => {
		if (!id) return;
		setPostLoading(true);
		await batchPost(parseInt(id));
		setPostLoading(false);
		setIsReload(!reload);
	};

	return (
		<>
			<ConfirmationModal
				handleClose={handleClose}
				message={'Are you sure you want to delete this Batch?'}
				open={defaultState?.open}
				handleConfirmation={handleDelete}
				handleCancel={cancelDeleteBatchById}
				loading={isDeleted}
			/>
			{id ? (
				<>
					<Stack direction="row" justifyContent={'space-between'}>
						<Stack
							alignItems={'center'}
							direction="row"
							justifyContent={'flex-start'}
							spacing={{ xs: 0, sm: 4 }}
							mb={{ xs: 1, sm: 0 }}
						>
							<Stack
								alignItems={'center'}
								justifyContent={'center'}
								sx={{ display: { xs: 'none', sm: 'flex' }, background: '#fff' }}
							>
								<IconButton
									id="demo-customized-button"
									aria-haspopup="true"
									onClick={() => {
										navigate(-1);
									}}
								>
									<KeyboardArrowLeft color="primary" />
								</IconButton>
							</Stack>
							<Stack direction="column">
								<Box
									sx={{
										fontSize: '20px',
										fontWeight: 700,
										color: 'var(--mainColor)',
										wordWrap: 'normal',
									}}
								>
									Batch Number: {singleBatch.data?.batchNumber || '**'}
								</Box>
								{singleBatch.data?.id && (
									<Button
										sx={{
											cursor: 'initial',
											width: 100,
											p: '4px 8px',
											borderRadius: '6px',
											fontSize: 12,
											textTransform: 'capitalize',
											background: '#fff',
											'&:hover': { background: '#fff', color: '#1976D2' },
											color: '#1976D2',
										}}
										disableElevation
										disableRipple
										variant="outlined"
									>
										{singleBatch.data?.batchStatus || '-'}
									</Button>
								)}
							</Stack>
						</Stack>
						<Stack ml={3} direction="row" alignItems="center">
							{singleBatch?.data?.hasWalkathon && (
								<Chip color="warning" label="This batch has walkathon donations" />
							)}
						</Stack>
						<Stack direction="row-reverse" alignItems="center" sx={{ mb: 4 }}>
							{Number.isInteger(validDeposits) ? (
								<>
									<FilterListButton
										setPage={setPage}
										page={page}
										setIsReload={setIsReload}
										reload={reload}
										setFilterPayload={setFilterPayload}
										filterPayload={filterPayload}
									/>
									{handleRoles('bookkeeping-list') && id && type !== 'papbank' && type !== 'papcard' && (
										<GenerateReceipt
											deposits={singleBatch?.data?.deposits as Deposits[]}
											validDeposits={validDeposits}
										/>
									)}
									{handleRoles('bookkeeping-actions') && id && (type === 'papbank' || type === 'papcard') && (
										<>
											{type === 'papbank' && (
												<LoadingButton
													sx={{ textTransform: 'none', marginRight: '10px' }}
													startIcon={<Receipt />}
													onClick={handleGenrateEFT}
													variant="contained"
													loading={EFTLoading}
													loadingPosition="start"
												>
													Generate EFT
												</LoadingButton>
											)}
											{type === 'papbank' ? (
												<Tooltip title={canPostBatch.message}>
													<span>
														<LoadingButton
															disabled={canPostBatch.state}
															sx={{ textTransform: 'none', marginRight: '10px', bgcolor: 'white' }}
															variant="outlined"
															onClick={handlePost}
															loading={postLoading}
															loadingPosition="start"
															startIcon={<Subtitles />}
														>
															Post
														</LoadingButton>
													</span>
												</Tooltip>
											) : (
												<LoadingButton
													disabled={batchData?.batchStatus !== 'Completed'}
													sx={{ textTransform: 'none', marginRight: '10px', bgcolor: 'white' }}
													variant="outlined"
													onClick={handlePost}
													loading={postLoading}
													loadingPosition="start"
													startIcon={<Subtitles />}
												>
													Post
												</LoadingButton>
											)}
											{type === 'papbank' && (
												<Tooltip
													title={
														papBankPayload === undefined
															? 'At least one Donation must have a status of Pass or Fail'
															: ''
													}
												>
													<span>
														<LoadingButton
															sx={{ textTransform: 'none', marginRight: '10px' }}
															startIcon={<Add />}
															onClick={handleUpdate}
															variant="contained"
															loading={isUpdating}
															loadingPosition="start"
															disabled={papBankPayload === undefined || batchData?.batchStatus === 'Posted'}
														>
															Save
														</LoadingButton>
													</span>
												</Tooltip>
											)}
										</>
									)}
									{handleRoles('bookkeeping-actions') && id && type === 'cheque' && (
										<Tooltip
											title={
												batchData?.batchStatus === 'Posted'
													? 'Cannot Delete a posted batch'
													: singleBatch?.data?.deposits.some(
															(deposit) => deposit.itemStatus.status === 'Posted',
													  )
													? 'Cannot delete batch with posted donations'
													: ''
											}
										>
											<span>
												<Button
													onClick={() => handleOpenClose(parseInt(id))}
													sx={{ textTransform: 'none', marginRight: '10px', bgcolor: 'white' }}
													startIcon={<Delete />}
													variant={batchData?.batchStatus === 'Posted' ? 'contained' : 'outlined'}
													disabled={
														batchData?.batchStatus === 'Posted' ||
														singleBatch?.data?.deposits.some(
															(deposit) => deposit.itemStatus.status === 'Posted',
														)
													}
												>
													Delete Batch
												</Button>
											</span>
										</Tooltip>
									)}
									<TrimTables
										name={type === 'papbank' ? 'BatchDonationsTableHead' : 'DepositsTableHead'}
										list={type === 'papbank' ? BatchDonationsTableHead : DepositsTableHead}
										trimList={trimList}
										setTrimList={setTrimList}
									/>
								</>
							) : (
								<ButtonSkeleton length={2} />
							)}
						</Stack>
					</Stack>
					<BatchHeader data={batchData as Batch} />
				</>
			) : (
				<Stack justifyContent="center">
					<BatchHeader data={batchData as Batch} />
					<Paper
						elevation={10}
						sx={{
							width: '350px',
							border: 'none',
							margin: '2rem auto',
							background: 'var(--mainColor)',
						}}
					>
						<Stack direction="row" justifyContent="center" py={2} px={2}>
							{files.length != 0 ? (
								<Stack direction="column" justifyContent="space-between">
									{triggerUpload ? (
										<UploadImageSection sx={{ width: '320px' }}>
											{progress <= 99 ? (
												<Fab aria-label="save" color="primary" sx={buttonSx}>
													<Save />
												</Fab>
											) : (
												<span>Please wait ...</span>
											)}
										</UploadImageSection>
									) : (
										<UploadImageSection>{files}</UploadImageSection>
									)}
									<LinearProgressWithLabel value={progress} />
								</Stack>
							) : (
								<Stack
									direction="column"
									justifyContent="center"
									alignItems="center"
									alignContent="center"
									{...getRootProps({ className: 'dropzone' })}
								>
									<UploadImageSection>
										<input {...getInputProps()} />
										<Typography sx={{ fontWeight: 200, color: 'var(--mainColor)', textAlign: 'center' }}>
											Drag and drop your csv file here, or{' '}
											<Typography color="var(--mainColor)" sx={{ fontWeight: 600, textAlign: 'center' }}>
												Click to select files
											</Typography>
										</Typography>
									</UploadImageSection>
								</Stack>
							)}
						</Stack>
					</Paper>
					<Typography sx={{ fontSize: 12, fontWeight: 200, py: 2, color: '#868998', textAlign: 'center' }}>
						Supports CSV only{' '}
					</Typography>
				</Stack>
			)}
			{id && (
				<DepositTable
					depoInfo={singleBatch?.data?.deposits}
					page={page}
					pageSize={pageSize}
					setPage={setPage}
					setPageSize={setPageSize}
					batchId={id ? parseInt(id) : undefined}
					loading={singleBatch?.loading}
					label={label}
					orderType={orderType}
					setLabel={setLabel}
					setOderBy={setOderBy}
					setOrderType={setOrderType}
					setIsReload={setIsReload}
					reload={reload}
					setFilterPayload={setFilterPayload}
					filterPayload={filterPayload}
					searchDonor={searchDonor}
					setSearchDonor={setSearchDonor}
					type={type}
					trimList={trimList}
					setTrimList={setTrimList}
					papBankPayload={papBankPayload}
					setPapBankPayload={setPapBankPayload}
					batchStatus={batchData?.batchStatus}
				/>
			)}
		</>
	);
};

export default CreateEditBatch;
