import React, { useState } from 'react';
import { ArrowBackIosNew, TaskAlt } from '@mui/icons-material';
import { Grid, MenuItem, Select, Stack, Typography } from '@mui/material';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import useReportActions from 'hooks/useReportAction';
import Box from '@mui/material/Box';
import { GlobalContexts } from 'contexts/GlobalContext';
import { MiniCard } from '../Card';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { useStyles } from '../Annuities/Annuities.style';
import { useNavigate } from 'react-router-dom';
import { useGlobalStyles } from 'Components/Common/global/global';

interface Payload {
	start: string;
	end: string;
	selectionCriteria: string;
}

const selectionCriteriaList = [
	{ label: 'Birthday in Date Range', id: 'isBirthday' },
	{ label: 'Date of Death in Date Range', id: 'isDOD' },
	{ label: 'All Active Annuitant/Co-Annuitant', id: 'isAllActive' },
];

const AnnuityMisce = () => {
	const global = useGlobalStyles();
	const navigate = useNavigate();
	const classes = useStyles();
	const { exportMisceCsv } = useReportActions();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const [payload, setPayload] = React.useState<Payload>({
		start: '',
		end: '',
		selectionCriteria: '',
	});
	const [formErrors, setFormErrors] = useState<string[]>([]);

	const updateFormValue = (key: string, value: string) => {
		const temp = { ...payload, [key]: value };
		setPayload(temp);
		if (formErrors.includes(key)) {
			setFormErrors(formErrors.filter((item) => item !== key));
		}
	};
	const [loading, setLoading] = useState(false);

	const handleSubmit = async () => {
		let start = payload.start;
		let end = payload.end;

		const errors = [];
		if (!start && payload.selectionCriteria !== 'isAllActive') {
			errors.push('start');
		}
		if (!end && payload.selectionCriteria !== 'isAllActive') {
			errors.push('start');
		}
		if (!payload.selectionCriteria) {
			errors.push('selectionCriteria');
		}

		if (moment(end).isBefore(start, 'days')) {
			setPayload({
				...payload,
				start: end,
				end: start,
			});
			start = payload.end;
			end = payload.start;
		}

		setFormErrors(errors);
		if (errors.length) {
			setSimpleErrorSnack({
				severity: 'error',
				show: true,
				message: 'Please fill all the required fields.',
			});
			return;
		}

		setLoading(true);
		if (payload.selectionCriteria !== 'isAllActive') {
			await exportMisceCsv(
				payload.selectionCriteria,
				moment(start).format('YYYY-MM-DD'),
				moment(end).format('YYYY-MM-DD'),
			);
		} else {
			await exportMisceCsv(payload.selectionCriteria, undefined, undefined);
		}

		setLoading(false);
	};

	return (
		<>
			<Box mb={2}>
				<Stack direction="row" marginBottom={2} alignItems={'center'} justifyContent="space-between">
					<Stack direction="row" alignItems={'center'} spacing={4}>
						<Stack className={classes.backIconWrapper}>
							<ArrowBackIosNew onClick={() => navigate(-1)} />
						</Stack>
						<Stack>
							<span className={classes.title}>Annuitant / Co-Annuitant </span>
						</Stack>
					</Stack>
				</Stack>
				<MiniCard title="Configure">
					<>
						<Stack sx={{ mb: 3 }}>
							<Stack direction="row" sx={{ mb: 3 }} spacing={5} alignItems="center">
								<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
									<Grid item xs={12} md={4} sm={6} mb={3}>
										<Stack direction="column" spacing={2}>
											<Typography sx={{ color: 'var(--formLabelColor)', fontSize: 14, fontWeight: 600 }}>
												Selection Criteria
											</Typography>
											<Select
												size="small"
												onChange={(e) => {
													updateFormValue('selectionCriteria', e.target.value);
												}}
												value={payload.selectionCriteria}
											>
												{selectionCriteriaList.map((x) => (
													<MenuItem key={x.id} value={x.id}>
														{x.label}
													</MenuItem>
												))}
											</Select>
										</Stack>
										{formErrors.includes('selectionCriteria') && (
											<span className={global.errorText}>Selection Criteria is required </span>
										)}
									</Grid>
									<Grid item xs={12} md={4} sm={6} mb={3}>
										<Stack direction="column" spacing={2}>
											<Typography sx={{ color: 'var(--formLabelColor)', fontSize: 14, fontWeight: 600 }}>
												Date Range
											</Typography>
											<Stack direction="row" pr={{ lg: 5, md: 0 }}>
												<BasicDatePicker
													size="small"
													value={payload.start}
													setValue={(e) => updateFormValue('start', e.target.value)}
													disabled={payload.selectionCriteria === 'isAllActive'}
													minDate={
														payload.selectionCriteria === 'isBirthday'
															? new Date(`${moment().format('YYYY')}-01-01`)
															: null
													}
													maxDate={
														payload.selectionCriteria === 'isBirthday'
															? new Date(`${moment().format('YYYY')}-12-31`)
															: null
													}
												/>
												<Stack m={1}>To</Stack>
												<BasicDatePicker
													size="small"
													value={payload.end}
													setValue={(e) => updateFormValue('end', e.target.value)}
													disabled={payload.selectionCriteria === 'isAllActive'}
													minDate={
														payload.selectionCriteria === 'isBirthday'
															? new Date(`${moment().format('YYYY')}-01-01`)
															: null
													}
													maxDate={
														payload.selectionCriteria === 'isBirthday'
															? new Date(`${moment().format('YYYY')}-12-31`)
															: null
													}
												/>
											</Stack>
										</Stack>

										{(formErrors.includes('start') || formErrors.includes('end')) &&
										payload.selectionCriteria !== 'isAllActive' ? (
											<span className={global.errorText}>Date range fields are required</span>
										) : null}
									</Grid>
								</Grid>
							</Stack>
						</Stack>
					</>
				</MiniCard>
				<LoadingButton
					onClick={handleSubmit}
					sx={{ textTransform: 'none', height: 40, mt: 3, ml: 3 }}
					startIcon={<TaskAlt />}
					variant="contained"
					loading={loading}
					loadingPosition="start"
				>
					Generate
				</LoadingButton>
			</Box>
		</>
	);
};

export default AnnuityMisce;
