import { ListItem } from '@mui/material';
import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import { SideMenuItemComponentProps } from './Types';

const SideMenuItemComponent: React.FC<SideMenuItemComponentProps> = (props) => {
	const { className, onClick, link, children } = props;

	// If link is not set return the orinary ListItem
	if (!link || typeof link !== 'string') {
		return (
			<ListItem button className={className} onClick={onClick}>
				{children}
			</ListItem>
		);
	}

	// Return a LitItem with a link component
	return (
		<ListItem
			button
			className={className}
			component={forwardRef((props: any, ref: any) => {
				return <NavLink {...props} innerRef={ref} />;
			})}
			to={link}
		>
			{children}
		</ListItem>
	);
};

export default SideMenuItemComponent;
