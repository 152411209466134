import React from 'react';
import { Home, Tag } from '@mui/icons-material';

export const serachSchoolField = [
	{
		key: 'name',
		control: 'text',
		label: 'School Name',
		icon: <Home />,
		value: '',
	},
	{
		key: 'city',
		control: 'text',
		label: 'City',
		icon: <Home />,
		value: '',
	},
	{
		key: 'postalCode',
		control: 'text',
		label: 'Postal Code',
		icon: <Tag />,
		value: '',
	},
	{
		key: 'phone',
		control: 'text',
		label: 'Phone',
		icon: <Tag />,
		value: '',
	},
	{
		key: 'principle',
		control: 'text',
		label: 'Principle',
		icon: <Tag />,
		value: '',
	},
];

export const serachDonorField = [
	{
		key: 'name',
		control: 'text',
		label: 'Donor Name',
		icon: <Home />,
		value: '',
	},
	{
		key: 'city',
		control: 'text',
		label: 'City',
		icon: <Home />,
		value: '',
	},
	{
		key: 'postalCode',
		control: 'text',
		label: 'Postal Code',
		icon: <Tag />,
		value: '',
	},
	{
		key: 'phone',
		control: 'text',
		label: 'Phone',
		icon: <Tag />,
		value: '',
	},
];

export const serachSchoolBoardField = [
	{
		key: 'name',
		control: 'text',
		label: 'School Board Name',
		icon: <Home />,
		value: '',
	},
	{
		key: 'city',
		control: 'text',
		label: 'City',
		icon: <Home />,
		value: '',
	},
	{
		key: 'postalCode',
		control: 'text',
		label: 'Postal Code',
		icon: <Tag />,
		value: '',
	},
	{
		key: 'phone',
		control: 'text',
		label: 'Phone',
		icon: <Tag />,
		value: '',
	},
	{
		key: 'director',
		control: 'text',
		label: 'Director',
		icon: <Tag />,
		value: '',
	},
];

export const searchParishField = [
	{
		key: 'parishName',
		control: 'text',
		label: 'Parish Name',
		icon: <Home />,
		value: '',
	},
	{
		key: 'city',
		control: 'text',
		label: 'City',
		icon: <Home />,
		value: '',
	},
	{
		key: 'postalCode',
		control: 'text',
		label: 'Postal Code',
		icon: <Tag />,
		value: '',
	},
	{
		key: 'phone',
		control: 'text',
		label: 'Phone',
		icon: <Tag />,
		value: '',
	},
	{
		key: 'director',
		control: 'text',
		label: 'Director',
		icon: <Tag />,
		value: '',
	},
];

export const searchDioceseField = [
	{
		key: 'dioceseName',
		control: 'text',
		label: 'Diocese Name',
		icon: <Home />,
		value: '',
	},
	{
		key: 'city',
		control: 'text',
		label: 'City',
		icon: <Home />,
		value: '',
	},
	{
		key: 'postalCode',
		control: 'text',
		label: 'Postal Code',
		icon: <Tag />,
		value: '',
	},
	{
		key: 'phone',
		control: 'text',
		label: 'Phone',
		icon: <Tag />,
		value: '',
	},
	{
		key: 'director',
		control: 'text',
		label: 'Director',
		icon: <Tag />,
		value: '',
	},
];
