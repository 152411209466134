import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    title: {
        color: 'var(--mainColor)',
        fontSize: '32px',
        fontWeight: 700,
    },
    subTitle: {
		color: 'var(--grayText)',
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '16px',
	},
    backIconWrapper: {
        background: 'var(--white)',
        borderRadius: '4px',
        padding: '5px',
        color: 'var(--mainColor)',
        cursor: 'pointer'
    },
})