import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
	title: {
		color: 'var(--mainColor)',
		fontSize: '32px',
		fontWeight: 700,
	},
	subTitle: {
		color: 'var(--grayText)',
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '16px',
	},
	backIconWrapper: {
		background: 'var(--white)',
		borderRadius: '4px',
		padding: '5px',
		color: 'var(--mainColor)',
		cursor: 'pointer',
	},
});
export const dioceseStyles = {
	tablePaper: { width: '100%', overflow: 'hidden' },
	subtitle: { color: '#7697ab', fontSize: 14, wordWrap: 'wrap' },
	title: { color: 'var(--mainColor)' },
	mb24: { mb: 3 },
	addNewBtn: { textTransform: 'none', mr: 2 },
};
