import * as React from 'react';
import { Button, Stack, TablePagination, Tooltip, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { pagination } from 'Components/Common/Tables/Tables';
import { ColumnType, donationsTableHead } from 'Components/Common/Tables/TableHeaders';
import { convertDate } from 'helpers/dayAndTime';
import { Add } from '@mui/icons-material';
import FormatCurrency from 'helpers/FormatCurrency';
import SingleCreditCardDeposit from 'Components/Deposits/CreateDeposit/SingleCreditCardDeposit';
import useDonationActions from 'hooks/useDonationActions';
import { GlobalContexts } from 'contexts/GlobalContext';
import { DonationsData, Donor, OrderType, StateType } from 'contexts/intialstates/Types';
import { sortTable } from 'Components/Common/Tables/sortTable';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import useUserActions from 'hooks/useUserActions';

interface DonorTableProps {
	id: number;
	donorInformation?: Donor;
}

const DonorTable = ({ id, donorInformation }: DonorTableProps) => {
	const { handleRoles } = useUserActions();
	const navigate = useNavigate();
	const { setErrorSnack } = React.useContext(GlobalContexts);
	const [page, setPage] = React.useState<number>(0);
	const [pageSize, setPageSize] = React.useState<number>(5);
	const [allDonationsByDonor, setAllDonationsByDonor] = React.useState<StateType<DonationsData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [isReload, setIsReload] = React.useState(false);
	const [filterPayload, setFilterPayload] = React.useState<Array<any>>([]);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const { getAllDonationByDonor } = useDonationActions();
	React.useEffect(() => {
		getAllDonationByDonor({
			page,
			pageSize,
			donorId: id,
			allDonationsByDonor,
			setAllDonationsByDonor,
			setErrorSnack,
			orderBy,
			filterPayload,
		});
	}, [page, pageSize, id, orderBy, isReload]);

	const data = allDonationsByDonor?.data;

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleClickOpen = () => {
		navigate(`/bookkeeping/deposits/create/donor/${id}`);
	};

	const handleEditDonation = (row: any) => {
		navigate(`/bookkeeping/deposits/${row.depositId}/donor/${id}`);
	};

	const getTableData = () => {
		return data?.body?.length
			? data.body.map((row: any, index: number) => {
					return {
						's/n': page * pageSize + index + 1,
						depositId: row.depositId,
						receiptNum: row?.receiptNum || 'N/A',
						contribution: <FormatCurrency value={row?.contribution ? row?.contribution : 'N/A'} />,
						issueDate: row?.issueDate ? `${convertDate(row?.issueDate)}` : 'N/A',
						society: row?.society?.code ? row?.society?.code : 'N/A',
						creditDate: row?.deposit?.creditDate ? `${convertDate(row?.deposit?.creditDate)}` : 'N/A',
						created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
						updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
						remark: row?.remark || 'N/A',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType) => {
			return {
				...column,
				data: (
					<>
						<Tooltip
							key={column.id}
							title={column.id == 'actions' || column.id == 'remark' ? '' : `Sort by ${column.label}`}
						>
							<span
								style={{ cursor: 'pointer' }}
								onClick={() =>
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									})
								}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							reload={isReload}
							setIsReload={setIsReload}
							setPage={setPage}
							page={page}
						/>
					</>
				),
			};
		});
	};
	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	return (
		<>
			<Stack sx={{ mb: 3 }} direction="row" alignItems={'center'} justifyContent="flex-end">
				<TrimTables
					name="donationsTableHead"
					list={donationsTableHead}
					trimList={trimList}
					setTrimList={setTrimList}
				/>

				{handleRoles('donor-bookkeepings') && (
					<>
						{' '}
						<Tooltip
							title={donorInformation?.archived ? 'Donation cannot be created for archived donors' : ''}
						>
							<span>
								<Button
									sx={{ textTransform: 'none', mr: 2 }}
									onClick={handleClickOpen}
									variant="contained"
									startIcon={<Add />}
									disabled={donorInformation?.archived}
								>
									Add Donations
								</Button>
							</span>
						</Tooltip>
						<SingleCreditCardDeposit donorId={id} donorInformation={donorInformation} />
					</>
				)}
				<FilterListButton
					setFilterPayload={setFilterPayload}
					filterPayload={filterPayload}
					reload={isReload}
					setIsReload={setIsReload}
					setPage={setPage}
					page={page}
				/>
			</Stack>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<CustomTable
					loading={allDonationsByDonor?.loading}
					columnData={getColumnData()}
					tableData={getTableData()}
					handleDrag={handleDrag}
					handleResize={handleResize}
					emptyMessage={'No donations available'}
					handleRowClick={(row) => handleEditDonation(row as any)}
				/>
				{(data?.body.length as number) > 0 && (
					<TablePagination
						rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
						count={data?.totalRecords as number}
						rowsPerPage={pageSize}
						page={page}
						sx={pagination}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
			</Paper>
		</>
	);
};

export default DonorTable;
