import {
	GET_ALL_DONATION_TYPES_LOADING,
	GET_ALL_DONATION_TYPES_ERROR,
	GET_ALL_DONATION_TYPES_LOAD_SUCCESS,
} from 'contexts/actionTypes';
import { ResponseError, Action } from 'contexts/intialstates/Types';

export const donationType = (state: any, { payload, type }: Action<any>): any => {
	switch (type) {
		case GET_ALL_DONATION_TYPES_LOADING: {
			return {
				...state,
				error: null,
				loading: true,
			};
		}

		case GET_ALL_DONATION_TYPES_LOAD_SUCCESS: {
			return {
				...state,
				loading: false,
				error: null,
				data: payload as any,
			};
		}
		case GET_ALL_DONATION_TYPES_ERROR: {
			return {
				...state,
				loading: false,
				error: payload as ResponseError,
			};
		}
		default:
			return state;
	}
};
