import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppRouter from 'routes/AppRouter';
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType, IPublicClientApplication, EventMessage, EventType, AuthenticationResult } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";

interface AppProps {
	msalInstance: IPublicClientApplication;
}

function App({ msalInstance }: AppProps) {
	const navigate = useNavigate();

	useEffect(() => {
		msalInstance.addEventCallback((event: EventMessage) => {
			if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
				const payload = event.payload as AuthenticationResult
				const account = payload.account
				msalInstance.setActiveAccount(account)
				navigate('/donors')
			}
			if (event.eventType === EventType.LOGIN_FAILURE ) {
				msalInstance.loginRedirect()
			}
		})
    }, []);

	const authRequest = {
	  ...loginRequest,
	};
  
	return (
		<>
		 <MsalProvider instance={msalInstance}>
			<MsalAuthenticationTemplate
			  interactionType={InteractionType.Redirect}
			  authenticationRequest={authRequest}
			>
				<AppRouter />
			</MsalAuthenticationTemplate>
         </MsalProvider> 		
		</>
	);
}

export default App;
