import React, { useState } from 'react';
import { ArrowBackIosNew, TaskAlt } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import useReportActions from 'hooks/useReportAction';
import Box from '@mui/material/Box';
import { GlobalContexts } from 'contexts/GlobalContext';
import { MiniCard } from '../Card';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { useStyles } from '../Annuities/Annuities.style';
import { useNavigate } from 'react-router-dom';

interface Payload {
	start: string;
	end: string;
}

const ConvergeReport = () => {
	const navigate = useNavigate();
	const classes = useStyles();
	const { exportConvergeCSV } = useReportActions();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const [payload, setPayload] = React.useState<Payload>({
		start: '',
		end: '',
	});

	const updateFormValue = (key: string, value: string) => {
		const temp = { ...payload, [key]: value };
		setPayload(temp);
	};
	const [loading, setLoading] = useState(false);

	const handleWalkathonReport = async () => {
		let start = payload.start;
		let end = payload.end;
		if (!start || !end) {
			setSimpleErrorSnack({
				severity: 'error',
				message: 'Date range is required',
				show: true,
			});
			return;
		}
		if (moment(end).isBefore(start, 'days')) {
			setPayload({
				...payload,
				start: end,
				end: start,
			});
			start = payload.end;
			end = payload.start;
		}
		if (moment(end).diff(start, 'days') > 31) {
			setSimpleErrorSnack({
				severity: 'error',
				message: 'Dates cannot be more than 1 month apart',
				show: true,
			});
			return;
		}

		setLoading(true);
		await exportConvergeCSV(moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD'));
		setLoading(false);
	};

	return (
		<>
			<Box mb={2}>
				<Stack direction="row" marginBottom={2} alignItems={'center'} justifyContent="space-between">
					<Stack direction="row" alignItems={'center'} spacing={4}>
						<Stack className={classes.backIconWrapper}>
							<ArrowBackIosNew onClick={() => navigate(-1)} />
						</Stack>
						<Stack>
							<span className={classes.title}>Converge Reports</span>
						</Stack>
					</Stack>
				</Stack>
				<MiniCard title="Configure">
					<>
						<Stack sx={{ mb: 3 }}>
							<Stack direction="row" sx={{ mb: 3 }} spacing={5} alignItems="center">
								<Stack direction="column" spacing={2}>
									<Typography sx={{ color: 'var(--formLabelColor)', fontSize: 14, fontWeight: 600 }}>
										Date Range
									</Typography>
									<Stack direction="row" pr={{ lg: 5, md: 0 }}>
										<BasicDatePicker
											size="small"
											value={payload.start}
											setValue={(e) => updateFormValue('start', e.target.value)}
										/>
										<Stack m={1}>To</Stack>
										<BasicDatePicker
											size="small"
											value={payload.end}
											setValue={(e) => updateFormValue('end', e.target.value)}
										/>
									</Stack>
								</Stack>
								<Stack direction="row" sx={{ transform: 'translateY(20px)' }} spacing={3}>
									<LoadingButton
										onClick={handleWalkathonReport}
										sx={{ textTransform: 'none', height: 40 }}
										startIcon={<TaskAlt />}
										variant="contained"
										loading={loading}
										loadingPosition="start"
									>
										Generate
									</LoadingButton>
								</Stack>
							</Stack>
						</Stack>
					</>
				</MiniCard>
			</Box>
		</>
	);
};

export default ConvergeReport;
