import {
	ResponseError,
	StateType,
	NotificationSummary,
	BatchNotificationData,
	FailedTransactionNotificationsData,
	expiringCCNotificationsData,
	PostDatedChequesData,
	FilterPayload,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';

export interface GetNotifications {
	notifications: StateType<NotificationSummary>;
	setNotifications: React.Dispatch<React.SetStateAction<StateType<NotificationSummary>>>;
}
export interface GetNotificationAlert {
	notificationAlert: StateType<boolean>;
	setNotificationAlert: React.Dispatch<React.SetStateAction<StateType<boolean>>>;
}
export interface GetFailedTransactions {
	failedTransactions: StateType<FailedTransactionNotificationsData>;
	setFailedTransactions: React.Dispatch<React.SetStateAction<StateType<FailedTransactionNotificationsData>>>;
	page: number;
	pageSize: number;
	orderBy?: string;
	donorQuery?: string;
	filterPayload: FilterPayload[];
}
export interface GetPap {
	pap: StateType<FailedTransactionNotificationsData>;
	setPap: React.Dispatch<React.SetStateAction<StateType<FailedTransactionNotificationsData>>>;
	page: number;
	pageSize: number;
	orderBy?: string;
	filterPayload: FilterPayload[];
}
export interface GetExpiringCC {
	expiringCC: StateType<expiringCCNotificationsData>;
	setExpiringCC: React.Dispatch<React.SetStateAction<StateType<expiringCCNotificationsData>>>;
}
export interface GetPostDatedCheques {
	postDated: StateType<PostDatedChequesData>;
	setPostDated: React.Dispatch<React.SetStateAction<StateType<PostDatedChequesData>>>;
	page: number;
	pageSize: number;
	orderBy?: string;
	filterPayload: FilterPayload[];
	donorQuery?: string;
}
export interface GetBatch {
	batch: StateType<BatchNotificationData[]>;
	setBatch: React.Dispatch<React.SetStateAction<StateType<BatchNotificationData[]>>>;
}

const useNotifications = () => {
	const { api } = useApi();

	const getNotificationAlert = async ({ notificationAlert, setNotificationAlert }: GetNotificationAlert) => {
		try {
			setNotificationAlert({
				...notificationAlert,
				loading: true,
			});
			const response = await api.get(`notification/pending-exists`);

			setNotificationAlert({
				...notificationAlert,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setNotificationAlert({
					...notificationAlert,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const getNotifications = async ({ notifications, setNotifications }: GetNotifications) => {
		try {
			setNotifications({
				...notifications,
				loading: true,
			});
			const response = await api.get(`notification/summary`);

			setNotifications({
				...notifications,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setNotifications({
					...notifications,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const getFailedTransactions = async ({
		failedTransactions,
		setFailedTransactions,
		page,
		pageSize,
		filterPayload,
		orderBy,
		donorQuery,
	}: GetFailedTransactions) => {
		try {
			setFailedTransactions({
				...failedTransactions,
				loading: true,
			});

			let filter = '';
			filterPayload.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (failedTransactions.data) {
				response = await api.get(
					`notification/list/failed-transactions?PageNumber=${page + 1}&PageSize=${pageSize}${
						donorQuery ? '&donorQuery=' + donorQuery : ''
					}${filter || ''}${orderBy || ''}`,
				);
			} else {
				response = await api.get(`notification/list/failed-transactions`);
			}

			setFailedTransactions({
				...failedTransactions,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || failedTransactions.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setFailedTransactions({
					...failedTransactions,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};
	const getPap = async ({
		failedTransactions,
		setFailedTransactions,
		page,
		pageSize,
		filterPayload,
		orderBy,
	}: GetFailedTransactions) => {
		try {
			setFailedTransactions({
				...failedTransactions,
				loading: true,
			});

			let filter = '';
			filterPayload.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (failedTransactions.data) {
				response = await api.get(
					`notification/list/failed-pap-transactions?PageNumber=${page + 1}&PageSize=${pageSize}${
						filter || ''
					}${orderBy || ''}`,
				);
			} else {
				response = await api.get(`notification/list/failed-pap-transactions`);
			}

			setFailedTransactions({
				...failedTransactions,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || failedTransactions.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setFailedTransactions({
					...failedTransactions,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};
	const getExpiringCC = async ({ expiringCC, setExpiringCC }: GetExpiringCC) => {
		try {
			setExpiringCC({
				...expiringCC,
				loading: true,
			});
			const response = await api.get(`notification/expiring-credit-cards`);

			setExpiringCC({
				...expiringCC,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setExpiringCC({
					...expiringCC,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};
	const getPostDatedCheques = async ({
		postDated,
		setPostDated,
		orderBy,
		filterPayload,
		page,
		pageSize,
		donorQuery,
	}: GetPostDatedCheques) => {
		try {
			setPostDated({
				...postDated,
				loading: true,
			});
			let filter = '';
			filterPayload.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`notification/list/post-dated-cheques?PageNumber=${page + 1}&PageSize=${pageSize}${
						donorQuery ? '&donorQuery=' + donorQuery : ''
					}${filter}${orderBy || ''}`,
				);
			} else {
				response = await api.get(
					`notification/list/post-dated-cheques?&PageSize=${pageSize}${
						donorQuery ? '&donorQuery=' + donorQuery : ''
					}${filter}${orderBy || ''}`,
				);
			}

			setPostDated({
				...postDated,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || postDated.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setPostDated({
					...postDated,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const getBatch = async ({ batch, setBatch }: GetBatch) => {
		try {
			setBatch({
				...batch,
				loading: true,
			});
			const response = await api.get(`notification/non-posted-batches`);

			setBatch({
				...batch,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setBatch({
					...batch,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const resolveFailedTransaction = async (id: number): Promise<boolean> => {
		try {
			const response = await api.post(`notification/${id}/resolve`);
			if (response?.data?.statusCode === 0) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	const archiveFailedTransaction = async (id: number): Promise<boolean> => {
		try {
			const response = await api.remove(`notification/${id}/archive`);
			if (response?.data?.statusCode === 0) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	return {
		getNotifications,
		getPap,
		getExpiringCC,
		getPostDatedCheques,
		getBatch,
		getFailedTransactions,
		getNotificationAlert,
		resolveFailedTransaction,
		archiveFailedTransaction,
	};
};

export default useNotifications;
