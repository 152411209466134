import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import React from 'react';
import enLocale from 'date-fns/locale/en-GB';

export default function BasicDatePicker(props: {
	label?: string;
	value?: any;
	year?: boolean;
	maxDate?: any;
	minDate?: any;
	setValue?: (val: any) => void;
	variant?: boolean;
	disabled?: boolean;
	helperText?: string;
	size?: 'small' | 'medium';
}) {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
			<DatePicker
				// allowSameDateSelection
				maxDate={props.maxDate}
				minDate={props.minDate}
				views={props.year ? ['year'] : ['year', 'month', 'day']}
				label={props.year ? 'yyyy' : props.label || 'dd/MM/yyyy'}
				openTo="year"
				value={props.value || null}
				onChange={(newValue: any) => {
					props.setValue?.({ target: { value: newValue } });
				}}
				disabled={props.disabled}
				renderInput={(params: any) =>
					props.variant ? (
						<TextField
							id="standard-basic"
							variant="standard"
							{...params}
							disabled={props.disabled}
							helperText={props.helperText}
							fullWidth
							size="small"
							sx={{
								background: `${props.disabled ? 'var(--invalidBackground)' : 'initial'}`,
								fontSize: 5,
							}}
						/>
					) : (
						<TextField
							{...params}
							disabled={props.disabled}
							helperText={props.disabled && props.helperText}
							size={'small'}
							fullWidth
							sx={{
								background: `${props.disabled ? 'var(--invalidBackground)' : 'initial'}`,
								fontSize: 5,
							}}
						/>
					)
				}
			/>
		</LocalizationProvider>
	);
}

export const Months = [
	{
		name: 'January',
		id: 1,
	},
	{
		name: 'February',
		id: 2,
	},
	{
		name: 'March',
		id: 3,
	},
	{
		name: 'April',
		id: 4,
	},
	{
		name: 'May',
		id: 5,
	},
	{
		name: 'June',
		id: 6,
	},
	{
		name: 'July',
		id: 7,
	},
	{
		name: 'August',
		id: 8,
	},
	{
		name: 'September',
		id: 9,
	},
	{
		name: 'October',
		id: 10,
	},
	{
		name: 'November',
		id: 11,
	},
	{
		name: 'December',
		id: 12,
	},
];
