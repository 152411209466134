import React, { useEffect, useContext } from 'react';
import { TablePagination, Tooltip, Paper } from '@mui/material';
import { pagination } from 'Components/Common/Tables/Tables';
import useDonorActions from 'hooks/useDonorActions';
import { DonorData, StateType, Donor, OrderType } from 'contexts/intialstates/Types';
import { GlobalContexts } from 'contexts/GlobalContext';
import { useNavigate, useParams } from 'react-router-dom';
import { sortTable } from 'Components/Common/Tables/sortTable';
import TableFiltering, { DonorFiltering } from 'Components/Reusable/TableFiltering/TableFiltering';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { convertDate } from 'helpers/dayAndTime';
import { Download } from '@mui/icons-material';
import { styles } from './donors.style';
import { LoadingButton } from '@mui/lab';
import useDebounce from 'Components/Reusable/hooks/debounce';

interface P {
	handleOpenEdit?: (editData: Donor) => void;
	handleOpenDelete?: (deleteData: Donor) => void;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	reload: boolean;
	setExportBtn?: React.Dispatch<React.SetStateAction<any>>;
	filterPayload: any[];
	setFilterPayload: React.Dispatch<React.SetStateAction<any[]>>;
	trimList: ColumnType[];
	isMerge?: boolean;
	setTrimList: React.Dispatch<React.SetStateAction<ColumnType[]>>;
	isSearch?: boolean;
	donorSearchParam?: string;
	archive?: string;
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
}

const DonorListTable = ({
	handleOpenEdit,
	reload,
	setReload,
	filterPayload,
	setFilterPayload,
	trimList,
	isMerge,
	setTrimList,
	isSearch,
	donorSearchParam,
	archive,
	setExportBtn,
	page,
	setPage,
}: P) => {
	const { setErrorSnack, setSimpleErrorSnack } = useContext(GlobalContexts);
	const { getAllDonors, mergeDonors, exportDonorCSV } = useDonorActions();
	const [pageSize, setPageSize] = React.useState(10);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [searchDonor, setSearchDonor] = React.useState<string>('');
	const debouncedSearchTerm = useDebounce(searchDonor, 1000);
	const [orderBy, setOderBy] = React.useState<string>('');
	const [donors, setDonors] = React.useState<StateType<DonorData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [exportDonor, setExportDonor] = React.useState<StateType<DonorData>>({
		data: null,
		error: null,
		loading: false,
	});
	const [mergeLoading, setMergeLoading] = React.useState(false);
	const [isOpen, setIsOpen] = React.useState({
		id: '',
		open: false,
	});
	const navigate = useNavigate();
	const { id } = useParams();

	const handleExport = () => {
		exportDonorCSV({
			exportDonor,
			setExportDonor,
			pageSize,
			page,
			setErrorSnack,
			orderBy,
			filterPayload,
			donorQuery: searchDonor,
			archive,
		});
	};

	useEffect(() => {
		if (donorSearchParam !== undefined) setSearchDonor(donorSearchParam);
	}, [donorSearchParam]);

	useEffect(() => {
		if (!setExportBtn) return;
		setExportBtn(
			<LoadingButton
				loading={exportDonor.loading}
				loadingPosition="start"
				onClick={handleExport}
				startIcon={<Download />}
				variant="outlined"
				sx={styles.csvBtn}
			>
				Download CSV
			</LoadingButton>,
		);
	}, [page, pageSize, reload, orderBy, filterPayload, searchDonor, archive]);
	useEffect(() => {
		getAllDonors({
			setDonors,
			donors,
			pageSize,
			page,
			setErrorSnack,
			orderBy,
			filterPayload,
			donorQuery: searchDonor,
			archive,
		});
	}, [page, pageSize, reload, orderBy, filterPayload, debouncedSearchTerm, archive]);

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleEdit = (data: Donor) => {
		if (isSearch) handleOpenEdit?.(data);
		else if (isMerge) setIsOpen({ id: data.id, open: true });
		else navigate(`/donors/${data.id}`);
	};

	const handleMerge = async (mergeFromId: string) => {
		if (id) {
			setMergeLoading(true);
			const isSucess = await mergeDonors({
				mergeFromId: parseInt(mergeFromId),
				mergeIntoId: parseInt(id as string),
			});
			setMergeLoading(false);
			setIsOpen({ ...isOpen, open: false });
			if (isSucess) {
				setReload(!reload);
				setSimpleErrorSnack({
					show: true,
					message: 'Merge Successful',
					severity: 'success',
				});
			} else {
				setSimpleErrorSnack({
					show: true,
					message: 'Merge Failed',
					severity: 'error',
				});
			}
		}
	};

	const getTableData = () => {
		return donors?.data?.body?.length
			? donors?.data?.body.map((row, index) => {
					return {
						's/n': page * pageSize + index + 1,
						id: parseInt(row.id),
						salutation: row?.salutation || '-',
						firstname: `${row?.firstName || ''} ${row?.lastName || ''}` || '-',
						firstName: row?.firstName || '-',
						lastName: row?.lastName || '-',
						isAnnuitant: row?.isAnnuitant ? 'Yes' : 'No',
						dateOfDeath: row?.dateOfDeath ? convertDate(row.dateOfDeath) : '-',
						deathReported: row?.deathReported ? convertDate(row.deathReported) : '-',
						archived: row?.archived ? 'Archived' : 'Active',
						referencenumber: row?.referenceNumber || '-',
						orgname: row?.orgName || '-',
						parish: row?.parish?.parishName || '-',
						phone: row?.phone || '-',
						email: row?.email || '-',
						mobile: row?.mobile || '-',
						careof: row?.careOf || '-',
						deceased: row?.deceased ? 'Yes' : 'No',
						streetline1: row?.streetline1 || '-',
						streetline2: row?.streetline2 || '-',
						province: row?.province?.provinceName || '-',
						city: row?.city || '-',
						postalcode: row?.postalCode || '-',
						country: row?.country || '-',
						createdBy: row?.createdBy?.name || '-',
						updatedBy: row?.updatedBy?.name || '-',
						created: row?.created ? `${convertDate(row?.created)}` : '-',
						updated: row?.updated ? `${convertDate(row?.updated)}` : '-',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		const columnData = trimList.map((column: ColumnType) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
							<span
								style={{ cursor: 'pointer' }}
								onClick={() =>
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									})
								}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<>
							<TableFiltering
								field={column.id}
								filterType={column.filterType}
								list={column.list}
								reload={reload}
								setIsReload={setReload}
								setFilterPayload={setFilterPayload}
								filterPayload={filterPayload}
								setPage={setPage}
								page={page}
							/>
							<DonorFiltering
								searchDonor={searchDonor}
								setSearchDonor={setSearchDonor}
								filterType={column.filterType}
								setPage={setPage}
							/>
						</>
					</>
				),
			};
		});
		return columnData;
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<ConfirmationModal
				handleClose={() => setIsOpen({ ...isOpen, open: false })}
				message={'Are you sure you want to merge with this Donor?'}
				open={isOpen.open}
				handleConfirmation={() => isOpen.id && handleMerge(isOpen.id)}
				handleCancel={() => setIsOpen({ ...isOpen, open: false })}
				loading={mergeLoading}
			/>
			<CustomTable
				loading={donors?.loading}
				columnData={getColumnData()}
				tableData={getTableData()}
				handleDrag={handleDrag}
				handleResize={handleResize}
				emptyMessage={'No donors available'}
				handleRowClick={handleEdit}
			/>
			{(donors?.data?.body?.length as number) > 0 && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
					component="div"
					count={donors?.data?.totalRecords as number}
					rowsPerPage={pageSize}
					page={page}
					sx={pagination}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</Paper>
	);
};

export default DonorListTable;
