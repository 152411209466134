import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Button, Typography } from '@mui/material';
import { Add, Download } from '@mui/icons-material';
import SchoolBoardTable from './SchoolBoardTable';
import { schoolBoardHead, ColumnType } from 'Components/Common/Tables/TableHeaders';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import useSchoolBoardActions from 'hooks/useSchoolBoardActions';
import { StateType, OrderType, SchoolBoardData, FilterPayload } from 'contexts/intialstates/Types';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import Archive, { IArchiveStatus } from 'Components/Reusable/Archive';
import { styles } from 'Components/Donors/donors.style';
import { LoadingButton } from '@mui/lab';

const SchoolBoard = () => {
	const navigate = useNavigate();
	const { getSchoolBoards, exportBoardCSV } = useSchoolBoardActions();
	const [schoolBoards, setSchoolBoards] = React.useState<StateType<SchoolBoardData>>({
		data: null,
		error: null,
		loading: false,
	});

	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const [filterPayload, setFilterPayload] = useState<Array<FilterPayload>>([]);
	const [reload, setReload] = useState(false);
	const [boardExport, setBoardExport] = useState<StateType<SchoolBoardData>>({
		data: null,
		error: null,
		loading: false,
	});

	const [archive, setArchive] = useState<IArchiveStatus>({
		label: 'All',
		filter: '',
	});
	const handleExport = () => {
		exportBoardCSV({
			boardExport,
			setBoardExport,
			pageSize,
			page,
			orderBy,
			filterPayload,
			archive: archive.filter,
		});
	};
	React.useEffect(() => {
		getSchoolBoards({
			archive: archive.filter,
			schoolBoards,
			setSchoolBoards,
			filterPayload,
			page,
			pageSize,
			orderBy,
		});
	}, [page, pageSize, archive, orderBy, reload]);
	return (
		<div>
			<Stack>
				<Stack
					alignItems="center"
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent={'space-between'}
					spacing={1}
					mb={5}
				>
					<Stack direction={'column'} spacing={3}>
						<Stack direction="column">
							<Stack direction="row" spacing={4} alignItems="center">
								<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
									School Board
								</Typography>
							</Stack>
							<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
								Add and manage School Boards here.
							</Typography>
						</Stack>
						<Stack direction="row" spacing={2}>
							<Archive archive={archive} setArchive={setArchive} />
							<LoadingButton
								loading={boardExport.loading}
								loadingPosition="start"
								onClick={handleExport}
								startIcon={<Download />}
								variant="outlined"
								sx={styles.csvBtn}
							>
								Download CSV
							</LoadingButton>
						</Stack>
					</Stack>
					<Stack sx={{ mb: 3 }} direction="row" alignItems={'center'} justifyContent="flex-end">
						<TrimTables
							name="schoolBoardHead"
							list={schoolBoardHead}
							trimList={trimList}
							setTrimList={setTrimList}
						/>
						<Button
							onClick={() => navigate(`/hca/create-edit-school-boards`)}
							sx={{ textTransform: 'none', mr: 2 }}
							startIcon={<Add />}
							variant="contained"
						>
							Add New School Board
						</Button>
						<FilterListButton
							reload={reload}
							setIsReload={setReload}
							page={page}
							setPage={setPage}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
						/>
					</Stack>
				</Stack>
				<SchoolBoardTable
					trimList={trimList}
					setTrimList={setTrimList}
					reload={reload}
					setReload={setReload}
					page={page}
					setPage={setPage}
					pageSize={pageSize}
					setPageSize={setPageSize}
					label={label}
					orderType={orderType}
					setLabel={setLabel}
					setOderBy={setOderBy}
					setOrderType={setOrderType}
					schoolBoards={schoolBoards}
					filterPayload={filterPayload}
					setFilterPayload={setFilterPayload}
				/>
			</Stack>
		</div>
	);
};

export default SchoolBoard;
