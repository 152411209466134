import React, { useRef } from 'react';
import { Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { convertDate } from 'helpers/dayAndTime';
import { FormatNumbers } from 'helpers/FormatCurrency';
import { fieldName } from 'Components/Reusable/FilterList/FilterList';
import Logo from './../../../assets/images/logo.png';
import { currentDate } from 'helpers/dayAndTime';
import { Typography } from '@mui/material';
import Report from '../Report';
import { useStyles } from 'Components/Reports/DonorReports/DonorReport.style';
import { useReactToPrint } from 'react-to-print';
import { IHCAOrderReport } from 'contexts/intialstates/Types';
import { FormData } from '.';

interface Props {
	data: IHCAOrderReport;
	formData: FormData;
	getHCAOrderReportPdf: () => void;
}

const SummarizedReport = ({ data, formData, getHCAOrderReportPdf }: Props) => {
	const classes = useStyles();
	const componentRef = useRef(null);

	const splitter = () => {
		if (!data?.groups?.length) return;
		const summarizedData: any = [];
		const finalData = [];
		data.groups?.map((summary, i: number) => {
			summarizedData?.push({
				groupName: summary?.groupName || '-',
				name: summary?.categories[0]?.name,
				orders: summary?.categories[0]?.orders,
				quantity: summary?.categories[0]?.quantity,
			});
			if (summary?.categories.length > 1) {
				summary?.categories.slice(1, summary?.categories.length).map((item) => {
					summarizedData.push({
						groupName: '',
						name: item?.name,
						orders: item?.orders,
						quantity: item?.quantity,
					});
				});
			}
			summarizedData.push({
				groupTotalOrder: summary?.totalOrders ? <FormatNumbers value={summary?.totalOrders} /> : '-',
				groupTotalQuantity: summary?.totalQuantity ? <FormatNumbers value={summary?.totalQuantity} /> : '-',
				groupName: `Total Order, ${summary?.groupName}`,
			});
			summarizedData.push(
				{
					groupName: '',
					name: '',
					orders: '',
					quantity: '',
				},
				{
					groupName: '',
					name: '',
					orders: '',
					quantity: '',
				},
			);
		});
		for (let i = 0; i < summarizedData.length; i += 18) {
			finalData.push(summarizedData.slice(i, i + 18));
		}
		return finalData;
	};

	const printContent = () => (
		<Box width="100%">
			<Table sx={{ minWidth: 700 }} aria-label="spanning table">
				<TableHead className={classes.tableHead}>
					<TableRow>
						<TableCell align="center">{fieldName(formData.groupby)}</TableCell>
						<TableCell align="center">Description</TableCell>
						<TableCell align="center">Orders #</TableCell>
						<TableCell align="center">Quantity</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{splitter()
						?.flatMap((item: any) => item)
						.map((row: any, index: number) => (
							<>
								{'groupTotalOrder' in row ? (
									<TableRow key={index} className={classes.tableRowWrap}>
										<TableCell
											align="center"
											colSpan={2}
											sx={{
												color: '#445f6f',
												fontSize: 18,
												textTransform: 'capitalize',
												fontWeight: 600,
											}}
										>
											{row.groupName}
										</TableCell>
										<TableCell
											align="center"
											sx={{
												color: '#445f6f',
												fontSize: 18,
												textTransform: 'capitalize',
												fontWeight: 600,
												borderTop: '1px solid #ABAEB0',
											}}
										>
											{row.groupTotalOrder}
										</TableCell>
										<TableCell
											align="center"
											sx={{
												color: '#445f6f',
												fontSize: 18,
												textTransform: 'capitalize',
												fontWeight: 600,
												borderTop: '1px solid #ABAEB0',
											}}
										>
											{row.groupTotalQuantity}
										</TableCell>
									</TableRow>
								) : (
									<TableRow key={index} className={classes.tableRowWrap}>
										<TableCell
											align="center"
											sx={{
												color: '#445f6f',
												fontSize: 18,
												textTransform: 'capitalize',
												fontWeight: 600,
											}}
										>
											{row.groupName || ''}
										</TableCell>
										<TableCell align="center">{row.name || ''}</TableCell>
										<TableCell align="center">{row.orders || ''}</TableCell>
										<TableCell align="center">{row.quantity || ''}</TableCell>
									</TableRow>
								)}
							</>
						))}
					<TableRow className={classes.tableTotal}>
						<TableCell align="center" colSpan={2}>
							<Stack py={1} sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}>
								<Stack
									direction="row"
									justifyContent={'center'}
									sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
								>
									Grand Total
								</Stack>
							</Stack>
						</TableCell>
						<TableCell colSpan={1} align="center">
							<Stack py={1} sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}>
								<Stack
									direction="row"
									justifyContent={'center'}
									sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
								>
									{data.grandTotalOrders ? <FormatNumbers value={data.grandTotalOrders} /> : '-'}
								</Stack>
							</Stack>
						</TableCell>
						<TableCell colSpan={1} align="center">
							<Stack py={1} sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}>
								<Stack
									direction="row"
									justifyContent={'center'}
									sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
								>
									{data.grandTotalQuantity ? <FormatNumbers value={data.grandTotalQuantity} /> : '-'}
								</Stack>
							</Stack>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</Box>
	);

	const printData = () => {
		const splitData = splitter();
		return (
			<TableContainer>
				{splitData?.map((dataSplit, index) => {
					return (
						<div className={classes.pageBreak} key={index}>
							<Table sx={{ minWidth: 700 }} key={index} aria-label="spanning table">
								<TableHead className={classes.tableHead}>
									<TableRow>
										<TableCell align="center">{fieldName(formData.groupby)}</TableCell>
										<TableCell align="center">Description</TableCell>
										<TableCell align="center">Orders #</TableCell>
										<TableCell align="center">Quantity</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{dataSplit.map((row: any, index: number) => (
										<>
											{'groupTotalOrder' in row ? (
												<TableRow key={index} className={classes.tableRowWrap}>
													<TableCell
														align="center"
														colSpan={2}
														sx={{
															color: '#445f6f',
															fontSize: 18,
															textTransform: 'capitalize',
															fontWeight: 600,
														}}
													>
														{row.groupName}
													</TableCell>
													<TableCell
														align="center"
														sx={{
															color: '#445f6f',
															fontSize: 18,
															textTransform: 'capitalize',
															fontWeight: 600,
															borderTop: '1px solid #ABAEB0',
														}}
													>
														{row.groupTotalOrder}
													</TableCell>
													<TableCell
														align="center"
														sx={{
															color: '#445f6f',
															fontSize: 18,
															textTransform: 'capitalize',
															fontWeight: 600,
															borderTop: '1px solid #ABAEB0',
														}}
													>
														{row.groupTotalQuantity}
													</TableCell>
												</TableRow>
											) : (
												<TableRow key={index} className={classes.tableRowWrap}>
													<TableCell
														align="center"
														sx={{
															color: '#445f6f',
															fontSize: 18,
															textTransform: 'capitalize',
															fontWeight: 600,
														}}
													>
														{row.groupName || ''}
													</TableCell>
													<TableCell align="center">{row.name || ''}</TableCell>
													<TableCell align="center">{row.orders || ''}</TableCell>
													<TableCell align="center">{row.quantity || ''}</TableCell>
												</TableRow>
											)}
										</>
									))}
									{index === splitData.length - 1 && (
										<TableRow className={classes.tableTotal}>
											<TableCell align="center" colSpan={2}>
												<Stack
													py={1}
													sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
												>
													<Stack
														direction="row"
														justifyContent={'center'}
														sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
													>
														Grand Total
													</Stack>
												</Stack>
											</TableCell>
											<TableCell colSpan={1} align="center">
												<Stack
													py={1}
													sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
												>
													<Stack
														direction="row"
														justifyContent={'center'}
														sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
													>
														{data.grandTotalOrders ? <FormatNumbers value={data.grandTotalOrders} /> : '-'}
													</Stack>
												</Stack>
											</TableCell>
											<TableCell colSpan={1} align="center">
												<Stack
													py={1}
													sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
												>
													<Stack
														direction="row"
														justifyContent={'center'}
														sx={{ borderBottom: '1px solid #ABAEB0', width: '100%' }}
													>
														{data.grandTotalQuantity ? (
															<FormatNumbers value={data.grandTotalQuantity} />
														) : (
															'-'
														)}
													</Stack>
												</Stack>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</div>
					);
				})}
			</TableContainer>
		);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<>
			{' '}
			<Report
				title="HCA Order Activity Summary Report   "
				subTitle={`From ${convertDate(formData.start)} To ${convertDate(formData.end)}`}
				handlePrint={getHCAOrderReportPdf}
			>
				{printContent()}
			</Report>
			<div style={{ display: 'none' }}>
				<div style={{ padding: 16 }} ref={componentRef}>
					<Stack sx={{ my: 2, px: 1 }}>
						<>
							<Stack width="100%" direction="row">
								<Stack flex={0.5} alignItems="flex-start" direction="column">
									<img src={Logo} alt="logo" width={40} height={50} />
								</Stack>

								<Stack sx={{ mb: 2, textAlign: 'left' }} alignItems="left" direction="column">
									<Typography sx={{ color: '#1976d2', fontSize: 20, fontWeight: 700 }}>
										HCA Order Activity Summary Report
									</Typography>
									<Typography sx={{ color: '#445f6f', fontSize: 18, fontWeight: 400 }}>
										{`From ${convertDate(formData.start)} To ${convertDate(formData.end)}`}
									</Typography>
								</Stack>
							</Stack>
							<Stack
								direction={'row'}
								justifyContent="flex-end"
								mb={3}
								sx={{ color: '#445f6f', fontSize: 16, fontWeight: 400 }}
							>
								{currentDate()}
							</Stack>
						</>

						<Box width="100%">{printData()}</Box>
					</Stack>
				</div>
			</div>
		</>
	);
};

export default SummarizedReport;
