import { ITEM_STATUS_LOADING, ITEM_STATUS_LOAD_ERROR, ITEM_STATUS_LOAD_SUCCESS } from 'contexts/actionTypes';
import { ResponseError, Action, AllItemStatusStateType, ItemStatusData } from 'contexts/intialstates/Types';

export const itemStatus = (
	state: AllItemStatusStateType,
	{ payload, type }: Action<ItemStatusData>,
): AllItemStatusStateType => {
	switch (type) {
		case ITEM_STATUS_LOADING: {
			return {
				...state,
				error: null,
				loading: true,
			};
		}

		case ITEM_STATUS_LOAD_SUCCESS: {
			return {
				...state,
				loading: false,
				error: null,
				data: payload as ItemStatusData,
			};
		}
		case ITEM_STATUS_LOAD_ERROR: {
			return {
				...state,
				loading: false,
				error: payload as ResponseError,
			};
		}
		default:
			return state;
	}
};
