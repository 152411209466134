import React, { useEffect, useState } from 'react';
import {
	Typography,
	TextField,
	Divider,
	Stack,
	Grid,
	MenuItem,
	Select,
	Box,
	Autocomplete,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import { Field } from 'formik';
import { FormTextField } from 'Components/Common/TextField';
import { useGlobalStyles } from 'Components/Common/global/global';
import {
	PersonAddAlt,
	CorporateFare,
	LocationCity,
	Subtitles,
	Receipt,
	Handshake,
	MailOutline,
	EmojiFlags,
	Phone,
	PhoneIphone,
	Email,
	PersonOutline,
	LocationOn,
	MyLocation,
	Terrain,
	Markunread,
	AssistantDirection,
	Search,
	CalendarMonth,
} from '@mui/icons-material';
import useParishesAction from 'hooks/useParishesActions';
import useRecieptScheduleAction, { ReceiptSchedule } from 'hooks/useReceiptScheduleActions';
import { Parish, StateType, Donor } from 'contexts/intialstates/Types';
import useDebounce from 'Components/Reusable/hooks/debounce';
import { useStyles } from '../DonorInfo.style';
import OrdersModal from 'Components/HCA/Orders/OrdersModal';
import ProvinceAutocomplete from 'Components/Province';
import BasicDatePicker from 'Components/Reusable/DatePicker';

const CreateEditOverview = ({ setFieldValue, values, id, setIsDobEmpty, isDobEmpty }: any) => {
	const global = useGlobalStyles();
	const classes = useStyles();
	const { getParishesBySearch } = useParishesAction();
	const { getReceiptSchedule } = useRecieptScheduleAction();

	const [parishSearchTerm, setParishSearchTerm] = useState('');
	const [searchParishes, setSearchParishes] = useState<StateType<Parish[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [receiptSchedule, setReceiptSchedule] = useState<StateType<ReceiptSchedule[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [open, setOpen] = React.useState<any>({ state: false, type: 'Parish' });

	const debouncedParishSearchTerm = useDebounce(parishSearchTerm, 1000);

	useEffect(() => {
		getReceiptSchedule({ receiptSchedule, setReceiptSchedule });
	}, []);

	useEffect(() => {
		if (!open.state && open.data) {
			setFieldValue('parish', {
				id: open.data.organizationId,
				parishName: open.data.organizationName,
			});
		}
	}, [open.state]);

	useEffect(() => {
		if (!debouncedParishSearchTerm) return;
		getParishesBySearch({ searchParishes, setSearchParishes, searchTerm: debouncedParishSearchTerm });
	}, [debouncedParishSearchTerm]);

	const handleVisibleSearch = () => {
		setOpen({ ...open, type: 'Parish', state: !open.state });
	};
	const isOrganizationChange = (e: any) => {
		setFieldValue('isOrganization', e.target.value);
		if (e.target.value === 'No') {
			setFieldValue('orgName', '');
		} else {
			const receipt = receiptSchedule.data?.find((item) => item?.schedule === 'Acknowledgement');
			setFieldValue('receiptScheduleId', receipt?.id);
		}
	};
	return (
		<div>
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<PersonAddAlt className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Salutation
							</Typography>
						</Grid>
					</Grid>
					<Field
						name="salutation"
						variant="outlined"
						placeholder="Salutation"
						size="small"
						component={FormTextField}
					/>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<PersonAddAlt className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								First Name
							</Typography>
						</Grid>
					</Grid>
					<Field
						name="firstName"
						variant="outlined"
						placeholder="First Name"
						size="small"
						component={FormTextField}
					/>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<PersonAddAlt className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Last Name
							</Typography>
						</Grid>
					</Grid>
					<Field
						name="lastName"
						variant="outlined"
						placeholder="Last Name"
						size="small"
						component={FormTextField}
					/>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<CalendarMonth className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Date of Birth
							</Typography>
						</Grid>
					</Grid>
					<BasicDatePicker
						value={values?.dateOfBirth || null}
						setValue={(e) => {
							setIsDobEmpty(false);
							setFieldValue('dateOfBirth', e.target.value);
						}}
					/>
					{isDobEmpty && (
						<span style={{ color: 'red', fontSize: 13, marginTop: 1 }}>
							Date of birth is required because the donor is an annuitant.
						</span>
					)}
				</Grid>

				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<CorporateFare className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Is Organization ?
							</Typography>
						</Grid>
					</Grid>
					<Field
						name="isOrganization"
						variant="outlined"
						component={() => (
							<Select
								size="small"
								fullWidth
								placeholder="IsOrganization"
								value={values.isOrganization}
								name="isOrganization"
								onChange={isOrganizationChange}
							>
								<MenuItem value={'Yes'}>Yes</MenuItem>
								<MenuItem value={'No'}>No</MenuItem>
							</Select>
						)}
					/>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<LocationCity className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Organization
							</Typography>
						</Grid>
					</Grid>
					<Field
						name="orgName"
						size="small"
						variant="outlined"
						placeholder="Organization"
						disabled={values.isOrganization !== 'Yes'}
						component={FormTextField}
					/>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<Subtitles className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Parish
							</Typography>
						</Grid>
					</Grid>
					<Stack flexDirection={'row'} alignItems={'center'}>
						<Autocomplete
							freeSolo
							value={values.parish?.parishName}
							fullWidth
							loading={searchParishes.loading}
							loadingText="loading..."
							onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
								setFieldValue('parish', {
									id: val?.id,
									parishName: val?.label,
								});
							}}
							options={(searchParishes?.data || []).map((item: any) => ({
								id: item.id,
								label: item.parishName,
							}))}
							renderOption={(props, option) => (
								<Box component="li" {...props} key={option.id}>
									{option.label}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									variant="outlined"
									label=""
									InputProps={{
										...params.InputProps,
									}}
									onChange={(event: any) => {
										setParishSearchTerm(event.target.value);
									}}
									size={'small'}
									placeholder={'Parish'}
								/>
							)}
						/>
						<div className={classes.IconBtn} onClick={handleVisibleSearch}>
							<Search />
						</div>
					</Stack>
				</Grid>
			</Grid>
			<Typography sx={{ fontSize: 14, fontWeight: 600, mt: 2 }}>Preferences</Typography>
			<Divider />
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2}>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<Receipt className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Receipt Type
							</Typography>
						</Grid>
					</Grid>
					<Field
						name="receiptScheduleId"
						variant="outlined"
						component={() => (
							<Select
								fullWidth
								size="small"
								placeholder="Receipt Type"
								value={values.receiptScheduleId || ''}
								name="receiptScheduleId"
								onChange={(e) => setFieldValue('receiptScheduleId', e.target.value)}
							>
								{receiptSchedule.data?.map(({ id, schedule }) => (
									<MenuItem key={id} value={id}>
										{schedule === 'Yearly' ? 'Year-End' : schedule}
									</MenuItem>
								))}
							</Select>
						)}
					/>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<Handshake className={global.formLabelIcon} />{' '}
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Preferred Society
							</Typography>
						</Grid>
					</Grid>
					<Select
						fullWidth
						size="small"
						placeholder="Receipt Type"
						value={values.preferredSociety}
						name="receiptType"
						multiple
						onChange={(event) => {
							const {
								target: { value },
							} = event;
							setFieldValue('preferredSociety', typeof value === 'string' ? value.split(',') : value);
						}}
					>
						<MenuItem value={'faithPropagation'}>Propagation of Faith</MenuItem>
						<MenuItem value={'holyChildhood'}>Holy Childhood</MenuItem>
						<MenuItem value={'stPeter'}>Saint Peter</MenuItem>
					</Select>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<MailOutline className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Communication
							</Typography>
						</Grid>
					</Grid>
					<Select
						fullWidth
						size="small"
						value={values.communication}
						name="communication"
						multiple
						onChange={(event) => {
							const {
								target: { value },
							} = event;
							setFieldValue('communication', typeof value === 'string' ? value.split(',') : value);
						}}
					>
						<MenuItem value={'noMail'}>Do not send email</MenuItem>
						<MenuItem value={'excludeFromParishList'}>Exclude from Paris List</MenuItem>
						<MenuItem value={'returnMail'}>Returned Mail</MenuItem>
						<MenuItem value={'deceased'}>Deceased</MenuItem>
					</Select>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<EmojiFlags className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Special Donor
							</Typography>
						</Grid>
					</Grid>
					<Select
						fullWidth
						size="small"
						value={values.specialDonor}
						name="SpecialDonor"
						multiple
						onChange={(event) => {
							const {
								target: { value },
							} = event;
							setFieldValue('specialDonor', typeof value === 'string' ? value.split(',') : value);
						}}
					>
						<MenuItem value={'pap'}>PAP</MenuItem>
						<MenuItem value={'annuity'}>Annuity</MenuItem>
						<MenuItem value={'estate'}>Estate</MenuItem>
					</Select>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<LocationCity className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Donor Type
							</Typography>
						</Grid>
					</Grid>
					<Field
						name="donorType"
						size="small"
						variant="outlined"
						placeholder="Donor Type"
						component={FormTextField}
					/>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<PersonAddAlt className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Receipt Salutation
							</Typography>
						</Grid>
					</Grid>
					<Field
						name="customReceiptSalutation"
						variant="outlined"
						placeholder="Receipt Salutation"
						size="small"
						component={FormTextField}
					/>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<FormControlLabel
						value="top"
						control={
							<Checkbox
								checked={values.magazine}
								onChange={(e) => setFieldValue('magazine', e.target.checked)}
							/>
						}
						label="Magazine"
					/>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<FormControlLabel
						value="top"
						control={
							<Checkbox
								checked={values.noFollowAppeal}
								onChange={(e) => setFieldValue('noFollowAppeal', e.target.checked)}
							/>
						}
						label="No Follow up Appeal"
					/>
				</Grid>
			</Grid>
			<Typography sx={{ fontSize: 14, fontWeight: 600, mt: 2 }}>Contact Details</Typography>
			<Divider />
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2}>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<Phone className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Phone
							</Typography>
						</Grid>
					</Grid>
					<Field name="phone" size="small" variant="outlined" placeholder="Phone" component={FormTextField} />
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<PhoneIphone className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Mobile
							</Typography>
						</Grid>
					</Grid>
					<Field
						name="mobile"
						size="small"
						variant="outlined"
						placeholder="Mobile"
						component={FormTextField}
					/>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<Email className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Email
							</Typography>
						</Grid>
					</Grid>
					<Field name="email" size="small" variant="outlined" placeholder="Email" component={FormTextField} />
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<PersonOutline className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Fax
							</Typography>
						</Grid>
					</Grid>
					<Field name="fax" size="small" variant="outlined" placeholder="Fax" component={FormTextField} />
				</Grid>
			</Grid>
			<Typography sx={{ fontSize: 14, fontWeight: 600, mt: 2 }}>Address Details</Typography>
			<Divider />
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2}>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<LocationOn className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Street Line 1
							</Typography>
						</Grid>
					</Grid>
					<Field
						name="streetline1"
						size="small"
						variant="outlined"
						placeholder="Street Line 1"
						component={FormTextField}
					/>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<MyLocation className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Street Line 2
							</Typography>
						</Grid>
					</Grid>
					<Field
						name="streetline2"
						size="small"
						variant="outlined"
						placeholder="Street Line 2"
						component={FormTextField}
					/>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<LocationCity className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								City
							</Typography>
						</Grid>
					</Grid>
					<Field name="city" size="small" variant="outlined" placeholder="City" component={FormTextField} />
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<AssistantDirection className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Province
							</Typography>
						</Grid>
					</Grid>
					<ProvinceAutocomplete
						handleChange={(val) => setFieldValue('provinceId', parseInt(val?.id))}
						value={values?.province || ''}
					/>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<Markunread className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Postal Code
							</Typography>
						</Grid>
					</Grid>
					<Field
						name="postalCode"
						size="small"
						variant="outlined"
						placeholder="Postal Code"
						component={FormTextField}
					/>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<Terrain className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Country
							</Typography>
						</Grid>
					</Grid>
					<Field
						name="country"
						size="small"
						variant="outlined"
						placeholder="Country"
						component={FormTextField}
					/>
				</Grid>
				<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
					<Grid container>
						<Grid item>
							<PersonAddAlt className={global.formLabelIcon} />
						</Grid>
						<Grid item>
							<Typography variant="body2" className={global.formLabelText}>
								Care Of
							</Typography>
						</Grid>
					</Grid>
					<Field
						name="careOf"
						size="small"
						variant="outlined"
						placeholder="Care Of"
						component={FormTextField}
					/>
				</Grid>
			</Grid>
			{open.state && <OrdersModal open={open} setOpen={setOpen} />}
		</div>
	);
};

export default CreateEditOverview;
