import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		pageDivider: {
			borderBottom: '1px solid var(--disabled)',
			marginBottom: '30px',
			fontSize: 17,
			fontWeight: 600,
		},
		pageDividerMini: {
			borderBottom: '1px solid var(--disabled)',
			fontSize: 17,
			fontWeight: 600,
		},
		title: {
			color: 'var(--mainColor)',
			fontSize: '32px',
			fontWeight: 700,
		},
		backIconWrapper: {
			background: 'var(--white)',
			borderRadius: '4px',
			padding: '5px',
			color: 'var(--mainColor)',
			cursor: 'pointer',
		},
		headerFilter: {
			backgroundColor: 'var(--mainBackground) !important',
			padding: '10px',
			borderTopLeftRadius: '4px',
			borderTopRightRadius: '4px',
			'& span': {
				fontWeight: 500,
				fontSize: '20px',
				lineHeight: '28px',
				marginLeft: '20px',
			},
		},
		paperWrap: {
			padding: '30px 30px 30px 40px',
			[theme.breakpoints.down('md')]: {
				padding: '30px',
			},
			borderRadius: '0 !important',
			borderBottomLeftRadius: '4px !important',
			borderBottomRightRadius: '4px !important',
			minHeight: '80vh',
		},
		buttonWrap: {
			margin: '20px',
		},
		tableHead: {
			'& .MuiTableRow-head': {
				'& .MuiTableCell-head': {
					textDecorationLine: 'underline',
					color: '#1976D2',
					fontWeight: 600,
					fontSize: '18px',
					lineHeight: '26px',
					borderBottom: 'none',
				},
			},
		},
		tableRowWrap: {
			'& .MuiTableCell-root': {
				borderBottom: 'none',
				fontSize: '16px',
				lineHeight: '26px',
				padding: '5px',
			},
		},
		tableTotal: {
			'& .MuiTableCell-root': {
				borderBottom: 'none',
				color: '#1976D2',
				fontWeight: 600,
				fontSize: '18px',
				lineHeight: '26px',
				padding: '30px 0',
			},
		},
		exportBtn: {
			textTransform: 'capitalize',
			backgroundColor: 'white',
		},
	}),
);
