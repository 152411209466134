import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { Button, Stack, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

import CampaignList from './List';
import { campaignHead, ColumnType } from 'Components/Common/Tables/TableHeaders';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import { FilterPayload } from 'contexts/intialstates/Types';

const Campaign = () => {
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const navigate = useNavigate();
	const [reload, setReload] = React.useState(false);
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);
	const [page, setPage] = React.useState(0);

	return (
		<>
			<Box>
				<Stack
					alignItems="center"
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent={'space-between'}
					spacing={1}
					mb={5}
				>
					<Stack direction="column">
						<Typography variant="h6" sx={{ color: 'var(--mainColor)' }}>
							Campaign
						</Typography>

						<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
							Create and manage Campaigns here.
						</Typography>
					</Stack>
					<Stack direction={'row'} justifyContent={'center'} spacing={{ xs: 1, md: 2 }} alignItems="center">
						<TrimTables
							name="campaignHead"
							list={campaignHead}
							trimList={trimList}
							setTrimList={setTrimList}
						/>
						<Button
							sx={{ textTransform: 'capitalize' }}
							variant="contained"
							disableElevation
							startIcon={<Add />}
							onClick={() => navigate('create')}
						>
							Add Campaign
						</Button>
						<FilterListButton
							setPage={setPage}
							page={page}
							reload={reload}
							setIsReload={setReload}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
						/>
					</Stack>
				</Stack>
				<Stack direction="row">
					<CampaignList
						trimList={trimList}
						setTrimList={setTrimList}
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
						reload={reload}
						setPage={setPage}
						page={page}
						setReload={setReload}
					/>
				</Stack>
			</Box>
		</>
	);
};

export default Campaign;
