import React from 'react';
import { Button, Paper, Stack, TablePagination, Tooltip, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { pagination } from 'Components/Common/Tables/Tables';
import { ColumnType, failedTransactionsHead } from 'Components/Common/Tables/TableHeaders';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import {
	Donor,
	OrderType,
	StateType,
	FailedTransactionNotificationsData,
	DonationsNotification,
	ApiResponse,
	FilterPayload,
} from 'contexts/intialstates/Types';
import { GlobalContexts } from 'contexts/GlobalContext';
import { convertDate } from 'helpers/dayAndTime';
import useNotificationActions from 'hooks/useNotificationActions';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import { sortTable } from 'Components/Common/Tables/sortTable';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import FormatCurrency from 'helpers/FormatCurrency';
import TableFiltering, { DonorFiltering } from 'Components/Reusable/TableFiltering/TableFiltering';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import useDepositActions from 'hooks/useDepositActions';
import useTemplateDepositActions from 'hooks/useTemplateDepositActions';
import { TemplateDepositContext } from 'contexts/TemplateDepositContext';
import useUserActions from 'hooks/useUserActions';
import useDebounce from 'Components/Reusable/hooks/debounce';
import { CreditCardDepositPayload } from 'Components/Deposits/Types';

const FailedTransactionsNotifications = () => {
	const { handleRoles } = useUserActions();
	const navigate = useNavigate();
	const location = useLocation();

	const { deleteTemplateDepositDispatch, deleteTemplateDepositState } =
		React.useContext(TemplateDepositContext);
	const { setErrorSnack } = React.useContext(GlobalContexts);
	const [isPap, setIsPap] = React.useState(false);
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [failedTransactions, setFailedTransactions] = React.useState<
		StateType<FailedTransactionNotificationsData>
	>({
		data: null,
		error: null,
		loading: false,
	});
	const [isDelete, setIsDelete] = React.useState<{ show: boolean; id: number | string }>({
		show: false,
		id: '',
	});
	const [isResolved, setIsResolved] = React.useState<{ show: boolean; id: number | string }>({
		show: false,
		id: '',
	});
	const [deleteLoading, setDeleteLoading] = React.useState(false);
	const [deleteDeposit, setDeleteDeposit] = React.useState<StateType<ApiResponse>>({
		data: null,
		error: null,
		loading: false,
	});
	const [donorQuery, setDonorQuery] = React.useState<string>('');
	const debouncedSearchTerm = useDebounce(donorQuery, 1000);
	const [orderType, setOrderType] = React.useState<OrderType>('asc');
	const [label, setLabel] = React.useState<string>('');
	const [orderBy, setOderBy] = React.useState<string>('');
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);
	const [reload, setIsReload] = React.useState(false);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const { getFailedTransactions, getPap, resolveFailedTransaction } = useNotificationActions();
	const { deleteSingleDeposit, creditCardDeposit } = useDepositActions();
	const { deleteTemplateDeposit } = useTemplateDepositActions();

	const handleCloseDelete = () => {
		setIsDelete({ id: '', show: false });
	};

	const handleDelete = async () => {
		if (isPap && deleteTemplateDepositDispatch) {
			await deleteTemplateDeposit(isDelete.id as number)(deleteTemplateDepositDispatch);
		} else {
			await deleteSingleDeposit({ depositId: isDelete.id, deleteDeposit, setDeleteDeposit, setErrorSnack });
		}

		setIsReload(!reload);
		handleCloseDelete();
	};
	const handleResolved = async () => {
		setDeleteLoading(true);
		const isSucess = await resolveFailedTransaction(isResolved.id as number);
		setDeleteLoading(false);
		if (isSucess) {
			setIsReload(!reload);
		}
		setIsResolved({ id: '', show: false });
	};

	React.useEffect(() => {
		setFailedTransactions({ ...failedTransactions, data: null });
		if (isPap) {
			getPap({
				failedTransactions,
				setFailedTransactions,
				page,
				pageSize,
				donorQuery,
				filterPayload,
				orderBy,
			});
		} else {
			getFailedTransactions({
				failedTransactions,
				setFailedTransactions: setFailedTransactions,
				page,
				pageSize,
				donorQuery,
				filterPayload,
				orderBy,
			});
		}
	}, [page, pageSize, filterPayload, orderBy, isPap, reload, debouncedSearchTerm]);

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleUpdate = async (row: DonationsNotification) => {
		if (isPap) {
			const payload: CreditCardDepositPayload = {
				amount: row?.deposit?.creditAmount,
				donorId: row?.deposit?.donorId ? row?.deposit?.donorId : undefined,
				remark: '',
				isRecurring: true,
				deleteDepositId: row?.deposit?.id,
			};

			await creditCardDeposit({ payload });
			return;
		}
		navigate(`/bookkeeping/deposits/${row?.deposit?.id}`);
	};

	const donorName = (donor: Donor) => {
		if (donor?.firstName && donor?.lastName) return `${donor?.firstName} ${donor?.lastName}`;
		if (donor?.firstName) return `${donor?.firstName}`;
		if (donor?.lastName) return ` ${donor?.lastName}`;
		if (donor?.orgName) return donor?.orgName;
		return 'Anonymous';
	};

	React.useEffect(() => {
		if (location.pathname.includes('pap')) return setIsPap(true);
		setIsPap(false);
	}, [location.pathname]);

	const getTableData = () => {
		return failedTransactions.data?.body?.length
			? failedTransactions.data?.body?.map((row: DonationsNotification, index: number) => {
					return {
						id: row?.id,
						's/n': page * pageSize + index + 1,
						donor: donorName(row?.deposit?.donor),
						postAmount: row?.deposit?.creditAmount ? (
							<FormatCurrency value={row?.deposit?.creditAmount} />
						) : (
							'N/A'
						),
						transactionDate: row?.transactionDate ? `${convertDate(row?.transactionDate)}` : 'N/A',
						paymentMethod: row?.deposit?.paymentMethod?.name || 'N/A',
						blank: handleRoles('bookkeeping-actions') && (
							<Stack direction="row" spacing={2}>
								{row?.deposit?.archived === false && (
									<Button
										onClick={() => setIsDelete({ show: true, id: row?.deposit?.id })}
										variant="outlined"
										sx={{ textTransform: 'capitalize' }}
									>
										Archive
									</Button>
								)}

								{row?.deposit?.archived === false && (
									<Button
										onClick={() => handleUpdate(row)}
										variant="contained"
										sx={{ textTransform: 'capitalize' }}
									>
										Update
									</Button>
								)}
								<Button
									onClick={() => setIsResolved({ show: true, id: row?.id })}
									variant="outlined"
									sx={{ textTransform: 'capitalize' }}
								>
									Resolved
								</Button>
							</Stack>
						),
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList
			.map((column: ColumnType) => {
				return {
					...column,
					data: (
						<>
							<Tooltip
								title={column.id == 'actions' || column.id == 'remark' ? '' : `Sort by ${column.label}`}
							>
								<span
									style={{ cursor: 'pointer' }}
									onClick={() =>
										sortTable({
											column: column.id,
											setLabel,
											setOderBy,
											setOrderType,
											orderType,
											label,
										})
									}
								>
									{column.label}
									{column.id == label && <OrderTypeArrow orderType={orderType} />}
								</span>
							</Tooltip>
							<DonorFiltering
								searchDonor={donorQuery}
								setSearchDonor={setDonorQuery}
								filterType={column.filterType}
							/>
							<TableFiltering
								field={column.id}
								filterType={column.filterType}
								list={column.list}
								setFilterPayload={setFilterPayload}
								filterPayload={filterPayload}
								reload={reload}
								setIsReload={setIsReload}
							/>
						</>
					),
				};
			})
			.filter((item) => item.id !== 'actions');
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	return (
		<>
			<Stack
				alignItems="center"
				direction={{ xs: 'column', sm: 'row' }}
				justifyContent={'space-between'}
				spacing={1}
				mb={5}
			>
				<Stack direction="column">
					<Stack direction="row" spacing={4} alignItems="center">
						<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
							{isPap ? 'Failed Pre Authorized Payments' : 'Failed Transactions'}
						</Typography>
					</Stack>
					<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
						View and manage all {isPap ? 'failed Pre authorized payments' : 'failed transactions'} here.
					</Typography>
				</Stack>
				<Stack sx={{ mb: 3 }} direction="row" alignItems={'center'} justifyContent="flex-end">
					<TrimTables
						name={'failedTransactionsHead'}
						list={failedTransactionsHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
					<FilterListButton
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
						reload={reload}
						setIsReload={setIsReload}
					/>
				</Stack>
			</Stack>

			<Stack justifyContent="center" alignItems="center">
				<Paper sx={{ width: '100%', overflow: 'hidden' }}>
					<CustomTable
						loading={failedTransactions?.loading}
						columnData={getColumnData()}
						tableData={getTableData()}
						handleDrag={handleDrag}
						handleResize={handleResize}
						emptyMessage={'No receipt template available'}
					/>
					{(failedTransactions.data?.body?.length as number) > 0 && (
						<TablePagination
							rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
							component="div"
							count={failedTransactions.data?.totalRecords as number}
							rowsPerPage={pageSize}
							page={page}
							sx={pagination}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					)}
				</Paper>
			</Stack>

			<ConfirmationModal
				handleClose={() => setIsDelete({ id: '', show: false })}
				message={'Are you sure you want to archive this donation?'}
				open={isDelete.show}
				handleConfirmation={handleDelete}
				handleCancel={() => setIsDelete({ id: '', show: false })}
				loading={deleteDeposit.loading || deleteTemplateDepositState.loading}
			/>
			<ConfirmationModal
				handleClose={() => setIsResolved({ id: '', show: false })}
				message={'Are you sure you want to resolve this transaction'}
				open={isResolved.show}
				handleConfirmation={handleResolved}
				handleCancel={() => setIsResolved({ id: '', show: false })}
				loading={deleteLoading}
			/>
		</>
	);
};

export default FailedTransactionsNotifications;
