import React, { useState } from 'react';
import useHCAMaterialsActions from 'hooks/useHCAMaterialsActions';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import { Stack, Button, Box, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import HCAMaterialsTable from './HCAMaterialsTable';
import CreateEditHCAMaterials from './CreateEditHCAMaterials';
import { HCAMaterialValues } from 'Components/AdminPanel/HCAMaterials/CreateEditHCAMaterials';
import { HCAMaterials } from 'contexts/intialstates/Types';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { hcaMaterialsHead, ColumnType } from 'Components/Common/Tables/TableHeaders';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';

const HcaMaterial = () => {
	const [visible, setVisible] = useState(false);
	const { createHCAMaterial, updateHCAMaterial, deleteHCAMaterial } = useHCAMaterialsActions();
	const [saveLoading, setSaveLoading] = useState(false);
	const [reload, setReload] = useState(false);
	const [selectedData, setSelectedData] = useState<HCAMaterials>();
	const [isDelete, setIsDelete] = useState(false);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const [filterPayload, setFilterPayload] = React.useState<Array<any>>([]);
	const [page, setPage] = React.useState(0);

	const handleSubmit = async (payload: HCAMaterialValues) => {
		setSaveLoading(true);
		let isSucess;
		if (selectedData) {
			isSucess = await updateHCAMaterial(payload, selectedData.id);
		} else {
			isSucess = await createHCAMaterial(payload);
		}
		setSaveLoading(false);
		handleCloseEdit();
		if (isSucess) {
			setReload(!reload);
		}
	};

	const handleOpenEdit = (data: HCAMaterials) => {
		setVisible(true);
		setSelectedData(data);
	};

	const handleOpenDelete = (data: HCAMaterials) => {
		setIsDelete(true);
		setSelectedData(data);
	};

	const handleCloseEdit = () => {
		setVisible(false);
		setSelectedData(undefined);
	};

	const handleCloseDelete = () => {
		setIsDelete(false);
		setSelectedData(undefined);
	};

	const handleDelete = async () => {
		if (selectedData) {
			setSaveLoading(true);
			const isSucess = await deleteHCAMaterial(selectedData.id);
			setSaveLoading(false);
			handleCloseDelete();
			if (isSucess) {
				setReload(!reload);
				setVisible(false);
			}
		}
	};

	return (
		<div>
			<Stack>
				<Stack
					alignItems="center"
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent={'space-between'}
					spacing={1}
					mb={5}
				>
					<Stack direction="column">
						<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
							Materials
						</Typography>
						<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
							Add and manage HCA Materials
						</Typography>
					</Stack>
					<Stack direction={'row'} justifyContent={'center'} spacing={{ xs: 1, md: 2 }} alignItems="center">
						<TrimTables
							name="hcaMaterialsHead"
							list={hcaMaterialsHead}
							trimList={trimList}
							setTrimList={setTrimList}
						/>
						<Button
							onClick={() => setVisible(true)}
							sx={{ textTransform: 'none', mr: 2 }}
							startIcon={<Add />}
							variant="contained"
						>
							Add New Material
						</Button>
						<FilterListButton
							reload={reload}
							setIsReload={setReload}
							page={page}
							setPage={setPage}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
						/>
					</Stack>
				</Stack>
				<HCAMaterialsTable
					handleOpenEdit={handleOpenEdit}
					trimList={trimList}
					setTrimList={setTrimList}
					setFilterPayload={setFilterPayload}
					filterPayload={filterPayload}
					reload={reload}
					setReload={setReload}
					page={page}
					setPage={setPage}
				/>
				<BasicModal
					onClose={handleCloseEdit}
					visible={visible}
					title={selectedData ? 'Edit Material' : 'Create Material'}
					data={selectedData || undefined}
					handleOpenDelete={handleOpenDelete}
				>
					<CreateEditHCAMaterials
						saveLoading={saveLoading}
						handleSubmit={handleSubmit}
						selectedEditData={selectedData}
					/>
				</BasicModal>
				<ConfirmationModal
					handleClose={() => setIsDelete(false)}
					message={'Are you sure you want to delete this Material?'}
					open={isDelete}
					handleConfirmation={handleDelete}
					handleCancel={() => setIsDelete(false)}
					loading={saveLoading}
				/>
			</Stack>
		</div>
	);
};

export default HcaMaterial;
