import React from 'react';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Table } from '@mui/material';
import CustomAccordian from 'Components/Common/Accordian/Accordian';
import { useStyles } from './index.style';
import { TableAccordianSkeletonContent } from 'Components/Common/Tables/TableSkeleton';

interface ColumnData {
	id: string;
	label: any;
	data: any;
}
interface P {
	header: any;
	tableData: any;
	columnData: ColumnData[];
	loading?: boolean;
}

const TableAccordian = ({ header, tableData, columnData, loading }: P) => {
	const classes = useStyles();

	const content = () => {
		return (
			<TableContainer sx={{ padding: '10px' }}>
				<Table sx={{ minWidth: 700 }} aria-label="spanning table">
					<TableHead className={classes.tableHead}>
						<TableRow>
							{columnData.map((item: any, index: number) => (
								<TableCell align="center" key={index}>
									<div className={classes.tableHeadContent}>
										<div>{item.data || item.label}</div>
									</div>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{loading ? (
							<TableAccordianSkeletonContent columnData={columnData} />
						) : tableData ? (
							tableData?.map((row: any, index: number) => (
								<TableRow key={index} className={classes.tableRowWrap}>
									{columnData.map((item: ColumnData, index: number) => (
										<TableCell align="center" key={index}>
											{row[item.id]}
										</TableCell>
									))}
								</TableRow>
							))
						) : null}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	return <CustomAccordian header={header()} content={content()} expandValue={true} isIconEnd />;
};

export default TableAccordian;
