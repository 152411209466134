import { PARISHES_LOADING, PARISHES_LOAD_SUCCESS, PARISHES_LOAD_ERROR } from 'contexts/actionTypes';
import {
	ResponseError,
	Action,
	Parish,
	ParishData,
	StateType,
	FilterPayload,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import type { ParishValues } from 'Components/AdminPanel/Parishes/CreateEditParish/CreateEditParishForm';
import moment from 'moment';

export interface GetAllParishesType {
	setParishes: React.Dispatch<React.SetStateAction<StateType<ParishData>>>;
	parishes: StateType<ParishData>;
	page: number;
	pageSize: number;
	filterPayload?: FilterPayload[];
	orderBy?: string;
	archive?: string;
}
export interface GetExportParishesType {
	setParishExport: React.Dispatch<React.SetStateAction<StateType<ParishData>>>;
	parishExport: StateType<ParishData>;
	page: number;
	pageSize: number;
	filterPayload?: FilterPayload[];
	orderBy?: string;
	archive?: string;
}

export interface GetAllParishType {
	setParish: React.Dispatch<React.SetStateAction<StateType<Parish>>>;
	parish: StateType<Parish>;
	id: number;
}

interface GetParishesSearch {
	searchParishes: StateType<Parish[]>;
	setSearchParishes: React.Dispatch<React.SetStateAction<StateType<Parish[]>>>;
	searchTerm: string;
}

const useParishesActions = () => {
	const { api } = useApi();

	const getAllParishes = () => async (dispatch: (arg0: Action<ParishData>) => void) => {
		try {
			dispatch({
				type: PARISHES_LOADING,
			});

			const getParishes = await api.get('/parish/list?&Filters=field=archived;value=false');

			return dispatch({
				type: PARISHES_LOAD_SUCCESS,
				payload: {
					...getParishes?.data.result,
					body: getParishes?.data.result.body,
				} as ParishData,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				dispatch({
					type: PARISHES_LOAD_ERROR,
					payload: errorCodes(err?.response?.data) as ResponseError as ResponseError,
				});
			}
		}
	};

	const getParishesByID = async ({ setParish, parish, id }: GetAllParishType) => {
		try {
			setParish({
				...parish,
				loading: true,
			});
			const getParishes = await api.get(`parish/${id}`);
			setParish({
				...parish,
				loading: false,
				data: getParishes?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setParish({
					...parish,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const getParishes = async ({
		setParishes,
		parishes,
		page,
		pageSize,
		filterPayload,
		orderBy,
		archive,
	}: GetAllParishesType) => {
		try {
			setParishes({
				...parishes,
				loading: true,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`/parish/list?PageNumber=${page + 1}&PageSize=${pageSize}${archive ? archive : ''}${filter || ''}${orderBy || '&SortBy=updated%20desc'
					}`,
				);
			} else {
				response = await api.get(`/parish/list?${orderBy || 'SortBy=updated%20desc'}&PageSize=${pageSize}${archive ? archive : ''}${filter || ''}
					`);
			}

			setParishes({
				...parishes,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || parishes.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setParishes({
					...parishes,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};
	const exportParishCSV = async ({
		setParishExport,
		parishExport,
		page,
		pageSize,
		filterPayload,
		orderBy,
		archive,
	}: GetExportParishesType) => {
		try {
			setParishExport({
				...parishExport,
				loading: true,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			const response = await api.get(
				`/parish/export-csv?PageNumber=${page + 1}&PageSize=${pageSize}${archive ? archive : ''}${filter || ''
				}${orderBy || ''}`,
			);
			const url = window.URL.createObjectURL(new Blob([response?.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Parish_Export_${moment().format('yyMM')}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			setParishExport({
				...parishExport,
				loading: false,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setParishExport({
					...parishExport,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	const createParish = async (payload: ParishValues): Promise<boolean> => {
		try {
			const createParishData = await api.post(`parish/create`, {
				...payload,
			});
			if (createParishData?.data?.result) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	const updateParish = async (payload: ParishValues, parishId: number): Promise<boolean> => {
		try {
			const updateParishData = await api.put(`parish/${parishId}/update`, {
				...payload,
				id: parishId,
			});
			if (updateParishData?.data?.statusCode === 0) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	const deleteParish = async (parishId: number): Promise<boolean> => {
		try {
			const deleteParish = await api.remove(`parish/${parishId}/archive`);
			if (deleteParish?.data?.statusCode === 0) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};
	const restoreParish = async (parishId: number): Promise<boolean> => {
		try {
			const deleteParish = await api.post(`parish/${parishId}/unarchive`);
			if (deleteParish?.data?.statusCode === 0) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	};

	const getParishesBySearch = async ({
		searchParishes,
		setSearchParishes,
		searchTerm,
	}: GetParishesSearch) => {
		try {
			setSearchParishes({
				...searchParishes,
				loading: true,
			});
			const donationData = await api.get(
				`parish/search?parishQuery=${searchTerm}&Filters=field=archived;value=false`,
			);

			setSearchParishes({
				...searchParishes,
				loading: false,
				data: donationData?.data?.result,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSearchParishes({
					...searchParishes,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	return {
		getAllParishes,
		getParishesByID,
		getParishes,
		deleteParish,
		createParish,
		updateParish,
		getParishesBySearch,
		exportParishCSV,
		restoreParish,
	};
};

export default useParishesActions;
