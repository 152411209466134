import { Clear, KeyboardArrowDown } from '@mui/icons-material';
import {
	Box,
	Button,
	ClickAwayListener,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Skeleton,
	Stack,
	Typography,
} from '@mui/material';
import {
	StateType,
	NotificationSummary,
	ExpiringCardsNotificationSummary,
} from 'contexts/intialstates/Types';
import useNotifications from 'hooks/useNotificationActions';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, PropType } from './Card';

export const ContentSkeleton = ({ length }: { length: number }) => {
	const arr = new Array(length).fill('blank');
	return (
		<>
			{' '}
			{arr.map((ele: string, index: number) => (
				<Paper key={index} elevation={0} sx={{ background: 'var(--lightGray)', p: '5px 10px', mb: 0.5 }}>
					<Stack direction={'row'} justifyContent="space-between">
						<Stack spacing={2} direction={'row'} alignItems="center" justifyContent="flex-start">
							<Skeleton variant="circular" width={40} height={40} />
							<Stack>
								<Skeleton width={700} height={40} variant="text" />
							</Stack>
						</Stack>
						<Stack direction="row" spacing={2} alignItems="center">
							<Stack>
								<Skeleton variant="rectangular" width={100} height={30} />
							</Stack>
							<Skeleton variant="rectangular" width={30} height={30} />
						</Stack>
					</Stack>
				</Paper>
			))}
		</>
	);
};

const notificationArr = [
	{
		label: (
			<span>
				Failed <br /> Transactions
			</span>
		),
		route: 'failed-transactions',
	},
	{
		label: (
			<span>
				Pre Authorized <br /> Payments
			</span>
		),
		route: 'pap',
	},
	{
		label: (
			<span>
				Expiring <br /> Credit Cards
			</span>
		),
		route: 'expiring-cc',
	},
	{
		label: (
			<span>
				Post Dated <br /> Cheques
			</span>
		),
		route: 'post-dated-cheques',
	},
	{ label: <span> Batches</span>, route: 'batch' },
];

const Notifications = () => {
	const { getNotifications } = useNotifications();
	const navigate = useNavigate();
	const anchorRef = React.useRef<HTMLButtonElement>(null);
	const [notifications, setNotifications] = React.useState<StateType<NotificationSummary>>({
		data: null,
		loading: false,
		error: null,
	});
	const [open, setOpen] = React.useState(false);

	const handleClose = (event: Event | React.SyntheticEvent) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}

		setOpen(false);
	};
	function handleListKeyDown(event: React.KeyboardEvent) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === 'Escape') {
			setOpen(false);
		}
	}
	const btn = document.getElementById('composition-button')?.clientWidth;
	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};
	React.useEffect(() => {
		getNotifications({ notifications, setNotifications });
	}, []);
	const handleClearAll = () => {
		setNotifications({
			...notifications,
			data: {
				...notifications.data,
				failedDonationCount: 0,
				failedPAPDonationCount: 0,
				expiredCardCount: 0,
				nonPostedBatchCount: 0,
				postDatedChequeCount: 0,
				expiringCards: notifications.data?.expiringCards.map((each) => ({
					...each,
					cardCount: 0,
				})) as ExpiringCardsNotificationSummary[],
			},
		});
	};

	const handleClear = (type: PropType) => {
		if (type === 'failedTransaction')
			return setNotifications({
				...notifications,
				data: {
					...(notifications.data as NotificationSummary),
					failedDonationCount: 0 as number,
				},
			});
		if (type === 'failedPapTransaction')
			return setNotifications({
				...notifications,
				data: {
					...(notifications.data as NotificationSummary),
					failedPAPDonationCount: 0 as number,
				},
			});
		if (type === 'postDated')
			return setNotifications({
				...notifications,
				data: {
					...(notifications.data as NotificationSummary),
					postDatedChequeCount: 0 as number,
				},
			});
		if (type === 'batch')
			return setNotifications({
				...notifications,
				data: {
					...(notifications.data as NotificationSummary),
					nonPostedBatchCount: 0 as number,
				},
			});
		if (type === 'expired')
			return setNotifications({
				...notifications,
				data: {
					...(notifications.data as NotificationSummary),
					expiredCardCount: 0 as number,
				},
			});
		if (type === 'expiringSoon')
			return setNotifications({
				...notifications,
				data: {
					...(notifications.data as NotificationSummary),
					expiringCards: notifications.data?.expiringCards.map((each, index) => {
						if (index === 0) {
							return { ...each, cardCount: 0 };
						}
						return each;
					}) as ExpiringCardsNotificationSummary[],
				},
			});
		if (type === 'expiring')
			return setNotifications({
				...notifications,
				data: {
					...(notifications.data as NotificationSummary),
					expiringCards: notifications.data?.expiringCards.map((each, index) => {
						if (index === 1) {
							return { ...each, cardCount: 0 };
						}
						return each;
					}) as ExpiringCardsNotificationSummary[],
				},
			});
	};
	return (
		<>
			<Box>
				<Stack direction={'row'} justifyContent={'space-between'} mb={3}>
					<Stack direction={'column'}>
						<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
							Notifications
						</Typography>
					</Stack>
					<Stack sx={{ mb: 3 }} spacing={2} direction="row" alignItems={'center'} justifyContent="flex-end">
						<div>
							<Button
								ref={anchorRef}
								id="composition-button"
								onClick={handleToggle}
								variant="outlined"
								sx={{
									background: 'white',
									textTransform: 'capitalize',
								}}
								disableElevation
								endIcon={<KeyboardArrowDown />}
								disableRipple
							>
								View All
							</Button>
							<Popper
								open={open}
								anchorEl={anchorRef.current}
								role={undefined}
								placement="bottom-start"
								transition
							>
								{({ TransitionProps, placement }) => (
									<Grow
										{...TransitionProps}
										style={{
											transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
										}}
									>
										<Paper sx={{ mt: 0.5, border: '1px solid #1976d2' }} elevation={0}>
											<ClickAwayListener onClickAway={handleClose}>
												<MenuList
													autoFocusItem={open}
													id="composition-menu"
													aria-labelledby="composition-button"
													onKeyDown={handleListKeyDown}
													sx={{ fontSize: 12, width: btn, py: { xs: 0, sm: 'initial' } }}
												>
													{notificationArr.map((curr, index) => (
														<MenuItem key={index} onClick={() => navigate(curr.route)}>
															<Typography
																component={'span'}
																variant="body1"
																sx={{ fontSize: 13, color: '#1976d2' }}
															>
																{curr.label}
															</Typography>
														</MenuItem>
													))}
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</div>
						<Button
							sx={{ textTransform: 'none', mr: 2 }}
							startIcon={<Clear />}
							size="small"
							variant="contained"
							onClick={handleClearAll}
						>
							Clear All
						</Button>
					</Stack>
				</Stack>
				<Paper sx={{ minHeight: '60vh', p: 4, borderRadius: '10px' }}>
					{notifications.loading ? (
						<ContentSkeleton length={7} />
					) : (
						<Box>
							{!!notifications.data?.failedDonationCount && notifications.data?.failedDonationCount > 0 && (
								<Card
									handleClear={handleClear}
									type="failedTransaction"
									count={notifications.data?.failedDonationCount}
								/>
							)}
							{!!notifications.data?.failedPAPDonationCount &&
								notifications.data?.failedPAPDonationCount > 0 && (
									<Card
										handleClear={handleClear}
										type="failedPapTransaction"
										count={notifications.data?.failedPAPDonationCount}
									/>
								)}
							{!!notifications.data?.nonPostedBatchCount && notifications.data?.nonPostedBatchCount > 0 && (
								<Card
									handleClear={handleClear}
									type="batch"
									count={notifications.data?.nonPostedBatchCount}
								/>
							)}
							{!!notifications.data?.postDatedChequeCount && notifications.data?.postDatedChequeCount > 0 && (
								<Card
									handleClear={handleClear}
									type="postDated"
									count={notifications.data?.postDatedChequeCount}
								/>
							)}
							{!!notifications.data?.expiredCardCount && notifications.data?.expiredCardCount > 0 && (
								<Card handleClear={handleClear} type="expired" count={notifications.data?.expiredCardCount} />
							)}
							{!!notifications.data?.expiringCards[0].cardCount &&
								notifications.data?.expiringCards[0].cardCount > 0 && (
									<Card
										handleClear={handleClear}
										type="expiringSoon"
										count={notifications.data?.expiringCards[0].cardCount}
									/>
								)}
							{!!notifications.data?.expiringCards[1].cardCount &&
								notifications.data?.expiringCards[1].cardCount > 0 && (
									<Card
										handleClear={handleClear}
										type="expiring"
										expiryDate={notifications.data?.expiringCards[1].expiryDate}
										count={notifications.data?.expiringCards[1].cardCount}
									/>
								)}
						</Box>
					)}
				</Paper>
			</Box>
		</>
	);
};

export default Notifications;
