import React, { useContext } from 'react';
import {
	TEMPLATE_DEPOSITS_LOADING,
	TEMPLATE_DEPOSITS_LOAD_ERROR,
	TEMPLATE_DEPOSITS_LOAD_SUCCESS,
	DELETE_TEMPLATE_DEPOSIT_LOADING,
	DELETE_TEMPLATE_DEPOSITS_LOAD_SUCCESS,
	DELETE_TEMPLATE_DEPOSITS_LOAD_ERROR,
	UPDATE_TEMPLATE_DEPOSIT_LOADING,
	UPDATE_TEMPLATE_DEPOSITS_LOAD_ERROR,
	UPDATE_TEMPLATE_DEPOSITS_LOAD_SUCCESS,
	TEMPLATE_DEPOSIT_LOADING,
	TEMPLATE_DEPOSIT_LOAD_ERROR,
	TEMPLATE_DEPOSIT_LOAD_SUCCESS,
} from 'contexts/actionTypes';
import {
	ResponseError,
	TemplateDepositsData,
	Action,
	UpdateDeletePayload,
	TemplateDeposits,
	FilterPayload,
	StateType,
	DonationsData,
} from 'contexts/intialstates/Types';
import { DepositFormPayload } from 'Components/Deposits/CreateDeposit/Types';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import useApi from './useApi';
import { CreateDepositProps } from '../Components/Deposits/Types';
import { GlobalContexts } from 'contexts/GlobalContext';
import { TemplateDepositContext } from 'contexts/TemplateDepositContext';

interface GetAllDepositParams {
	pageNumber: number;
	pageSize: number;
	donorQuery?: string;
	orderBy?: string;
	filterPayload?: FilterPayload[];
	type: string;
}

interface GetDonorTemplateDeposit {
	donorsTemplateDeposit: StateType<DonationsData>;
	setDonorsTemplateDeposit: React.Dispatch<React.SetStateAction<StateType<DonationsData>>>;
	setErrorSnack: React.Dispatch<React.SetStateAction<boolean>>;
	page: number;
	pageSize: number;
	orderBy: string;
	donorId: number;
	filterPayload?: FilterPayload[];
}

const useTemplateDepositActions = () => {
	const { templateDepositsState } = useContext(TemplateDepositContext);
	const { api } = useApi();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const getTemplateDepositByID =
		(depositID?: number) => async (dispatch: (arg0: Action<TemplateDeposits>) => void) => {
			try {
				dispatch({
					type: TEMPLATE_DEPOSIT_LOADING,
				});
				const deposit = await api.get(`depositTemplate/${depositID}`);
				return dispatch({
					type: TEMPLATE_DEPOSIT_LOAD_SUCCESS,
					payload: deposit?.data?.result as TemplateDeposits,
				});
			} catch (err) {
				if (Axios.isAxiosError(err)) {
					dispatch({
						type: TEMPLATE_DEPOSIT_LOAD_ERROR,
						payload: errorCodes(err?.response?.data) as ResponseError,
					});
				}

				alert('Delete Deposit failed');
			}
		};

	const updateTemplateDeposit =
		(depositId: number, requestPayload: DepositFormPayload, type: string, posted?: boolean) =>
		async (dispatch: (arg0: Action<UpdateDeletePayload>) => void) => {
			try {
				dispatch({
					type: UPDATE_TEMPLATE_DEPOSIT_LOADING,
				});
				if (posted) {
					const updateDeposit = await api.put(`deposit/${depositId}/updatePosted`, {
						dioceseId: requestPayload?.dioceseId,
						schoolBoardId: requestPayload?.schoolBoardId,
						schoolId: requestPayload?.schoolId,
						parishId: requestPayload?.parishId,
						donations: requestPayload?.donations?.map((donation) => ({
							donationId: donation?.id,
							remarks: donation?.remark,
						})),
					});
					return dispatch({
						type: UPDATE_TEMPLATE_DEPOSITS_LOAD_SUCCESS,
						payload: updateDeposit?.data,
					});
				}
				const updateDeposit = await api.put(`depositTemplate/${type}/${depositId}/update`, {
					...requestPayload,
				});
				return dispatch({
					type: UPDATE_TEMPLATE_DEPOSITS_LOAD_SUCCESS,
					payload: updateDeposit?.data,
				});
			} catch (err) {
				if (Axios.isAxiosError(err)) {
					dispatch({
						type: UPDATE_TEMPLATE_DEPOSITS_LOAD_ERROR,
						payload: errorCodes(err?.response?.data) as ResponseError,
					});
				}
				setSimpleErrorSnack({
					message: 'Update Deposit failed',
					severity: 'error',
					show: true,
				});
			}
		};

	const deleteTemplateDeposit =
		(depositId: number) => async (dispatch: (arg0: Action<UpdateDeletePayload>) => void) => {
			try {
				dispatch({
					type: DELETE_TEMPLATE_DEPOSIT_LOADING,
				});
				const deleteDeposit = await api.remove(`depositTemplate/${depositId}/archive`);
				return dispatch({
					type: DELETE_TEMPLATE_DEPOSITS_LOAD_SUCCESS,
					payload: deleteDeposit?.data,
				});
			} catch (err) {
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
				if (Axios.isAxiosError(err)) {
					dispatch({
						type: DELETE_TEMPLATE_DEPOSITS_LOAD_ERROR,
						payload: errorCodes(err?.response?.data) as ResponseError,
					});
				}
			}
		};

	const getAllTemplateDeposits =
		({ pageNumber, pageSize, donorQuery, orderBy, filterPayload, type }: GetAllDepositParams) =>
		async (dispatch: (arg0: Action<TemplateDepositsData>) => void) => {
			try {
				dispatch({
					type: TEMPLATE_DEPOSITS_LOADING,
				});
				let filter = '';
				filterPayload?.map((item) => {
					filter = filter.concat(
						`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
							item?.query ? `;query=${item.query}` : ''
						}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
					);
				});
				let response;
				if (pageNumber) {
					response = await api.get(
						`depositTemplate/${type}/list?PageNumber=${pageNumber + 1}&PageSize=${pageSize}
					${donorQuery ? '&donorQuery=' + donorQuery : ''}${filter}${orderBy || '&SortBy=updated%20desc'}`,
					);
				} else {
					response = await api.get(
						`depositTemplate/${type}/list?&PageSize=${pageSize}
					${donorQuery ? '&donorQuery=' + donorQuery : ''}${filter}${orderBy || '&SortBy=updated%20desc'}`,
					);
				}

				return dispatch({
					type: TEMPLATE_DEPOSITS_LOAD_SUCCESS,
					payload: {
						...response?.data.result,
						body: response?.data.result.body,
						totalRecords: response?.data?.result?.totalRecords || templateDepositsState.data?.totalRecords,
					} as TemplateDepositsData,
				});
			} catch (err) {
				if (Axios.isAxiosError(err)) {
					dispatch({
						type: TEMPLATE_DEPOSITS_LOAD_ERROR,
						payload: errorCodes(err?.response?.data) as ResponseError,
					});
				}
			}
		};

	const getDonorsTemplateDeposit = async ({
		donorsTemplateDeposit,
		setDonorsTemplateDeposit,
		donorId,
		setErrorSnack,
		page,
		pageSize,
		orderBy,
		filterPayload,
	}: GetDonorTemplateDeposit) => {
		try {
			setDonorsTemplateDeposit({
				...donorsTemplateDeposit,
				loading: true,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`depositTemplate/donation/${donorId}/list?PageNumber=${page + 1}&PageSize=${pageSize}
					${filter}${orderBy || '&SortBy=updated%20desc'}`,
				);
			} else {
				response = await api.get(
					`depositTemplate/donation/${donorId}/list?&PageSize=${pageSize}
					${filter}${orderBy || '&SortBy=updated%20desc'}`,
				);
			}

			setDonorsTemplateDeposit({
				...donorsTemplateDeposit,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || donorsTemplateDeposit.data?.totalRecords,
				},
				error: null,
			});
			setErrorSnack(false);
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDonorsTemplateDeposit({
					...donorsTemplateDeposit,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			setErrorSnack(true);
		}
	};

	const createSingletTemplateDeposit = async ({
		payload,
		createDeposit,
		setCreateDeposit,
		setErrorSnack,
	}: CreateDepositProps) => {
		try {
			setCreateDeposit({
				...createDeposit,
				loading: true,
			});
			const createDeposits = await api.post(`depositTemplate/bank/create`, { ...payload });
			setCreateDeposit({
				...createDeposit,
				loading: false,
				data: createDeposits?.data?.result,
				error: null,
			});
			setErrorSnack(false);
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setCreateDeposit({
					...createDeposit,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			setErrorSnack(true);
		}
	};

	return {
		getTemplateDepositByID,
		updateTemplateDeposit,
		deleteTemplateDeposit,
		getAllTemplateDeposits,
		getDonorsTemplateDeposit,
		createSingletTemplateDeposit,
	};
};

export default useTemplateDepositActions;
