import React, { useContext } from 'react';
import { Logout, Notifications, Task } from '@mui/icons-material';
import { useMsal } from '@azure/msal-react';
import Logo from '../../assets/images/logo.png';
import {
	Stack,
	AppBar,
	IconButton,
	Toolbar,
	Typography,
	Avatar,
	Button,
	ClickAwayListener,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Badge,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { StateType } from 'contexts/intialstates/Types';
import useNotifications from 'hooks/useNotificationActions';
import { GlobalContexts } from 'contexts/GlobalContext';

interface HeaderProps {
	mobileOpen: boolean;
	setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header = ({ mobileOpen, setMobileOpen }: HeaderProps) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { pendingFiles } = useContext(GlobalContexts);

	const { getNotificationAlert } = useNotifications();
	const [notificationAlert, setNotificationAlert] = React.useState<StateType<boolean>>({
		data: false,
		loading: false,
		error: null,
	});

	const { instance, accounts } = useMsal();
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLButtonElement>(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event | React.SyntheticEvent) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}

		setOpen(false);
	};

	function handleListKeyDown(event: React.KeyboardEvent) {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		} else if (event.key === 'Escape') {
			setOpen(false);
		}
	}

	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current!.focus();
		}

		prevOpen.current = open;
	}, [open]);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const btn = document.getElementById('composition-button')?.clientWidth;

	const handleSignOut = () => {
		instance.logoutRedirect().catch((e) => {
			console.error(e);
		});
	};

	React.useEffect(() => {
		getNotificationAlert({ notificationAlert, setNotificationAlert });
	}, [notificationAlert.data]);
	return (
		<>
			<AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
				<Toolbar>
					<Stack
						direction="row"
						justifyContent={'space-between'}
						width="100%"
						height={60}
						pr={{ md: 5 }}
						alignItems="center"
					>
						<Stack direction="row">
							<IconButton
								color="inherit"
								aria-label="open drawer"
								edge="start"
								onClick={handleDrawerToggle}
								sx={{ mr: 2, display: { sm: 'none' } }}
							>
								<MenuIcon />
							</IconButton>
							<Avatar sx={{ bgcolor: 'white', width: 45, height: 45 }}>
								<img style={{ height: 30 }} src={Logo} />
							</Avatar>
						</Stack>
						<Stack direction="row" alignItems={'center'} justifyContent={'flex-end'} spacing={2}>
							<IconButton
								onClick={() => navigate('/notifications')}
								sx={{
									background: `${
										location.pathname.includes('/notifications') ? 'rgba(0, 0, 0, 0.04)' : 'initial'
									}`,
								}}
							>
								<Badge
									color="error"
									variant="dot"
									overlap="circular"
									badgeContent={notificationAlert.data ? 1 : 0}
								>
									<Notifications sx={{ color: 'white' }} />
								</Badge>
							</IconButton>
							<IconButton
								onClick={() => navigate('/files')}
								sx={{
									background: `${location.pathname.includes('/files') ? 'rgba(0, 0, 0, 0.04)' : 'initial'}`,
								}}
							>
								<Badge
									color="error"
									variant="dot"
									overlap="circular"
									badgeContent={pendingFiles.length ? 1 : 0}
								>
									<Task sx={{ color: 'white' }} />
								</Badge>
							</IconButton>

							<div>
								<Button
									ref={anchorRef}
									id="composition-button"
									aria-controls={open ? 'composition-menu' : undefined}
									aria-expanded={open ? 'true' : undefined}
									aria-haspopup="true"
									onClick={handleToggle}
									variant="contained"
									sx={{
										background: 'var(--mainBackground)',
										color: 'var(--mainColor)',
										textTransform: 'capitalize',
										fontSize: { xs: 11, sm: 13, md: 15 },
										'&:hover': { background: 'var(--mainBackground)', color: 'var(--mainColor)' },
									}}
									disableElevation
									endIcon={<KeyboardArrowDown />}
									disableRipple
								>
									<Avatar sx={{ width: 25, height: 25, mr: 1, fontSize: { xs: 12, sm: 15 } }}>
										{accounts.length ? accounts[0].name?.split(' ')[0].charAt(0) : ''}
									</Avatar>{' '}
									{accounts.length ? accounts[0].name?.split(' ')[0] : ''}
								</Button>
								<Popper
									open={open}
									anchorEl={anchorRef.current}
									role={undefined}
									placement="bottom-start"
									transition
									disablePortal
								>
									{({ TransitionProps, placement }) => (
										<Grow
											{...TransitionProps}
											style={{
												transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
											}}
										>
											<Paper sx={{ mt: 1 }}>
												<ClickAwayListener onClickAway={handleClose}>
													<MenuList
														autoFocusItem={open}
														id="composition-menu"
														aria-labelledby="composition-button"
														onKeyDown={handleListKeyDown}
														sx={{ width: btn, py: { xs: 0, sm: 'initial' } }}
													>
														<MenuItem onClick={handleSignOut}>
															<Logout
																sx={{
																	mr: 1,

																	fontSize: { xs: 11, sm: 13, md: 15 },
																}}
															/>{' '}
															<Typography
																component={'span'}
																variant="body1"
																sx={{ fontSize: { xs: 12, sm: 13, md: 15 } }}
															>
																Logout
															</Typography>
														</MenuItem>
													</MenuList>
												</ClickAwayListener>
											</Paper>
										</Grow>
									)}
								</Popper>
							</div>
						</Stack>
					</Stack>
				</Toolbar>
			</AppBar>
		</>
	);
};

export default Header;
