/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid } from '@mui/material';
import { styled, Box } from '@mui/system';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 100;

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		height: 'calc(100% - 60px)',
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		background: '#fff!important',
		color: '#1976D2',
		marginTop: '60px',
	},
	content: {
		background: '#e5e5e5',
		height: 'calc(100% - 60px)',
		flexGrow: 1,
	},
	container: {
		padding: '30px 50px 30px 120px',
	},
}));
