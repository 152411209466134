import React from 'react';
import { ResponseError, StateType, EOYReceipt, Donor, Society } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface GetAllEOYReceiptProps {
	allEOYReceipt: StateType<EOYReceipt[]>;
	setAllEOYReceipt: React.Dispatch<React.SetStateAction<StateType<EOYReceipt[]>>>;
	donorId: number | string | undefined;
}

interface IssueReceiptData {
	id: number;
	receiptYear: string;
	amount: number;
	receiptNumber: number;
	recordCount: number;
	issueDate: string;
	donor: Donor;
	society: Society;
}

export interface IssueReceipt {
	successful: IssueReceiptData[];
	unSuccessful: IssueReceiptData[];
}

export interface GetIssueReceiptProps {
	issueReceipt: StateType<IssueReceipt>;
	setIssueReceipt: React.Dispatch<React.SetStateAction<StateType<IssueReceipt>>>;
	year: number;
	orderBy: string;
}

const useReceiptActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack, setPendingFiles } = React.useContext(GlobalContexts);

	const getAllEOYReceipt = async ({ allEOYReceipt, setAllEOYReceipt, donorId }: GetAllEOYReceiptProps) => {
		try {
			setAllEOYReceipt({
				...allEOYReceipt,
				loading: true,
			});
			const response = await api.get(
				`receipt/${donorId}/yearend
                `,
			);
			setAllEOYReceipt({
				...allEOYReceipt,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAllEOYReceipt({
					...allEOYReceipt,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			alert('Oops! An error occurred');
		}
	};

	const issueAllEOYReceipt = async ({
		year,
		issueReceipt,
		setIssueReceipt,
		orderBy,
	}: GetIssueReceiptProps) => {
		try {
			setIssueReceipt({
				...issueReceipt,
				loading: true,
			});
			const response = await api.post(
				`receipt/yearend/generate?targetYear=${year}&${orderBy || ''}
                `,
			);
			if (response?.data?.statusCode === 0) {
				setIssueReceipt({
					...issueReceipt,
					loading: false,
					data: response?.data?.result,
					error: null,
				});
				if (!response?.data?.result?.successful.length && !response?.data?.result?.unSuccessful.length) {
					setSimpleErrorSnack({
						message: `There are no year-end receipts of the ${year}`,
						severity: 'error',
						show: true,
					});
				}
				return;
			}
			setIssueReceipt({
				...issueReceipt,
				loading: false,
			});
			setSimpleErrorSnack({
				message: `unable to issue receipt`,
				severity: 'error',
				show: true,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setIssueReceipt({
					...issueReceipt,
					error: errorCodes(err?.response?.data) as ResponseError,
					loading: false,
				});
				setSimpleErrorSnack({
					message: `unable to issue receipt`,
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const generateSingleEOY = async (id: string | number, sendEmail?: boolean) => {
		try {
			const response = await api.get(`receipt/download/${id}/yearend${sendEmail ? '?sendEmail=true' : ''}`, {
				responseType: 'blob',
			});
			const url = window.URL.createObjectURL(new Blob([response?.data], { type: 'application/pdf' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'receipt.pdf');
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			setSimpleErrorSnack({
				message: `Unable to generate receipt`,
				severity: 'error',
				show: true,
			});
		}
	};

	return {
		getAllEOYReceipt,
		issueAllEOYReceipt,
		generateSingleEOY,
	};
};

export default useReceiptActions;
