import React, { useEffect, useState } from 'react';
import {
	Stack,
	Typography,
	Paper,
	TextField,
	Autocomplete,
	Box,
	Grid,
	Select,
	MenuItem,
} from '@mui/material';
import {
	Add,
	PersonOutline,
	MonetizationOn,
	CorporateFare,
	CalendarMonth,
	Search,
	CardGiftcard,
} from '@mui/icons-material';
import { useStyles } from 'Components/AdminPanel/NonCashDonation/NonCashDonation.style';
import { useGlobalStyles } from 'Components/Common/global/global';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import useSocietyActions from 'hooks/useSocietyAction';
import { GlobalContexts } from 'contexts/GlobalContext';
import useDonationTypesActions from 'hooks/useDonationTypesActions';
import { StateType, Donor, DonationTypes, Society } from 'contexts/intialstates/Types';
import useDebounce from 'Components/Reusable/hooks/debounce';
import useDonorAction from 'hooks/useDonorActions';
import AdvancedSearch, { Field } from 'Components/Reusable/Modals/AdvancedSearch/AdvancedSearchModal';
import { serachDonorField } from 'Components/Reusable/Modals/AdvancedSearch/Mock';
import DonorTable from 'Components/Donors/DonorListTable';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import CreateEditDonor from 'Components/Donors/CreateEditDonorModal';
import { ColumnType, donorHead } from 'Components/Common/Tables/TableHeaders';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import CreateEditGiftType from 'Components/AdminPanel/GiftType/CreateEditGiftType';
import useGiftTypeActions, { GiftTypeData } from 'hooks/useGiftTypeActions';

export interface NonCashFormValues {
	status?: string;
	donor?: {
		id: number;
		name: string;
	};
	amount?: string;
	societyId?: number;
	donationType?: {
		id?: number;
		label?: string;
	};
	giftType?: {
		id: number;
		type: string;
	};
	receivedDate?: string;
	error?: string[];
}

interface P {
	isDisabled?: boolean;
	formData?: NonCashFormValues;
	setFormData: React.Dispatch<React.SetStateAction<NonCashFormValues>>;
}

const CreateEditNonCashDonations = ({ isDisabled, formData, setFormData }: P) => {
	const [page, setPage] = React.useState(0);
	const classes = useStyles();
	const global = useGlobalStyles();
	const { getSocieties } = useSocietyActions();
	const { getGiftTypeBySearch } = useGiftTypeActions();
	const { getDonationTypeBySearch } = useDonationTypesActions();
	const [giftTypeSearch, setGiftTypeSearch] = useState<StateType<GiftTypeData[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [searchTerm, setSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce(searchTerm, 1000);
	const [giftTypeSearchTerm, setGiftTypeSearchTerm] = useState('');
	const giftDebouncedSearchTerm = useDebounce(giftTypeSearchTerm, 1000);
	const { getSearchDonors } = useDonorAction();
	const [searchDonors, setSearchDonors] = useState<StateType<Donor[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [visibleSearch, setVisibleSearch] = useState(false);
	const [searchFields, setSearchFields] = useState<Field[]>(serachDonorField);
	const [reload, setReload] = useState(false);
	const [filterPayload, setFilterPayload] = useState<Array<any>>([]);
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [visibleDonorModal, setVisibleDonorModal] = useState(false);
	const [visibleGiftType, setVisibleGiftType] = useState(false);
	const [allSociety, setAllSoceity] = React.useState<StateType<Society[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [donationTypeSearchTerm, setDonationTypeSearchTerm] = useState('');
	const debouncedDonationSearchTerm = useDebounce(donationTypeSearchTerm, 1000);
	const [donationType, setDonationType] = useState<StateType<DonationTypes[]>>({
		data: null,
		loading: false,
		error: null,
	});

	useEffect(() => {
		if (!debouncedDonationSearchTerm) return;
		getDonationTypeBySearch({ donationType, setDonationType, searchTerm: debouncedDonationSearchTerm });
	}, [debouncedDonationSearchTerm]);

	useEffect(() => {
		getSocieties({ allSociety, setAllSoceity });
	}, []);

	useEffect(() => {
		if (!debouncedSearchTerm) return;
		getSearchDonors({ searchDonors, setSearchDonors, searchTerm });
	}, [debouncedSearchTerm]);

	useEffect(() => {
		if (!giftDebouncedSearchTerm) return;
		getGiftTypeBySearch({ giftTypeSearch, setGiftTypeSearch, searchTerm: giftTypeSearchTerm });
	}, [giftDebouncedSearchTerm]);

	const handleVisibleSearch = () => {
		setVisibleSearch(!visibleSearch);
	};

	const handleSearch = () => {
		const payload: any = searchFields.map(({ key, value }) => {
			if (value) {
				return {
					field: key,
					query: key !== 'phone' ? value : null,
					value: key === 'phone' ? [value] : null,
					from: null,
					to: null,
				};
			}
		});
		setFilterPayload(payload.filter(Boolean));
	};

	const handleSelectRow = (row: any) => {
		setFormData({
			...formData,
			donor: {
				id: row.id,
				name: row.firstname,
			},
		});
		handleVisibleSearch();
	};

	const updateFormValue = (key: string) => (e: any) => {
		setFormData({
			...formData,
			[key]: e.target.value,
			error: formData?.error?.filter((err) => err !== key),
		});
	};

	const updateDonationFormValue = (val: any) => {
		setFormData({
			...formData,
			donationType: val,
			error: formData?.error?.filter((err) => err !== 'donationType'),
		});
	};

	return (
		<div>
			<Paper className={global.paperFormWrap}>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
					<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
						<Grid container>
							<Grid item>
								<PersonOutline className={global.formLabelIcon} />
							</Grid>
							<Grid item>
								<Typography variant="body2" className={global.formLabelText}>
									Donor
								</Typography>
							</Grid>
						</Grid>
						<Stack flexDirection={'row'} alignItems={'center'}>
							<Autocomplete
								freeSolo
								fullWidth
								disabled={isDisabled}
								loadingText="loading..."
								loading={searchDonors.loading}
								value={formData?.donor ? formData?.donor?.name : ''}
								onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
									setFormData({
										...formData,
										donor: {
											id: parseInt(val?.id),
											name: val?.label,
										},
									});
								}}
								options={(searchDonors?.data || []).map((donor: Donor) => {
									const label = `${donor.firstName} ${donor.lastName}`;
									return { id: donor.id, label };
								})}
								renderOption={(props, option) => (
									<Box component="li" {...props} key={option.id}>
										{option.label}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										label=""
										InputProps={{
											...params.InputProps,
										}}
										onChange={(event: any) => {
											setSearchTerm(event.target.value);
										}}
										size={'small'}
										placeholder={'Search donor'}
										className={isDisabled ? classes.disabledInput : ''}
									/>
								)}
							/>
							<div
								className={isDisabled ? classes.disabledIcon : classes.IconBtn}
								onClick={() => !isDisabled && handleVisibleSearch()}
							>
								<Search />
							</div>
							<div
								className={isDisabled ? classes.disabledIcon : classes.IconBtn}
								onClick={() => !isDisabled && setVisibleDonorModal(true)}
							>
								<Add />
							</div>
						</Stack>
					</Grid>
					<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
						<Grid container>
							<Grid item>
								<MonetizationOn className={global.formLabelIcon} />
							</Grid>
							<Grid item>
								<Typography variant="body2" className={global.formLabelText}>
									Amount
								</Typography>
							</Grid>
						</Grid>
						<Stack>
							<TextField
								size="small"
								fullWidth
								label=""
								variant="outlined"
								value={formData?.amount}
								onChange={updateFormValue('amount')}
								disabled={isDisabled}
								className={isDisabled ? classes.disabledInput : ''}
							/>
							{formData?.error?.includes('amount') && (
								<span className={classes.errorText}>Amount is required</span>
							)}
						</Stack>
					</Grid>
					<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
						<Grid container>
							<Grid item>
								<CorporateFare className={global.formLabelIcon} />
							</Grid>
							<Grid item>
								<Typography variant="body2" className={global.formLabelText}>
									Society
								</Typography>
							</Grid>
						</Grid>
						<Stack>
							<Select
								fullWidth
								size={'small'}
								value={formData?.societyId ? formData.societyId : ''}
								onChange={updateFormValue('societyId')}
								disabled={isDisabled}
								className={isDisabled ? classes.disabledInput : ''}
							>
								{allSociety?.data
									?.filter((curr) => curr.code !== 'AN')
									.map((x) => (
										<MenuItem key={x.id} value={x.id}>
											{x.code}
										</MenuItem>
									))}
							</Select>
							{formData?.error?.includes('societyId') && (
								<span className={classes.errorText}>Society is required</span>
							)}
						</Stack>
					</Grid>
					<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
						<Grid container>
							<Grid item>
								<CorporateFare className={global.formLabelIcon} />
							</Grid>
							<Grid item>
								<Typography variant="body2" className={global.formLabelText}>
									Donation Type
								</Typography>
							</Grid>
						</Grid>
						<Stack>
							<Autocomplete
								freeSolo
								value={formData?.donationType?.label || ''}
								loading={donationType.loading}
								loadingText="loading..."
								onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
									updateDonationFormValue(val);
								}}
								fullWidth
								options={(donationType?.data || []).map((donation: DonationTypes) => ({
									id: donation.id,
									label: `${donation.type} - ${donation.description}`,
								}))}
								renderOption={(props, option) => (
									<Box component="li" {...props} key={option.id}>
										{option.label}
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										label=""
										InputProps={{
											...params.InputProps,
										}}
										onChange={(event: any) => {
											setDonationTypeSearchTerm(event.target.value);
										}}
										size={'small'}
										placeholder={'Search donation type'}
									/>
								)}
							/>
							{formData?.error?.includes('donationType') && (
								<span className={classes.errorText}>Donation Type is required</span>
							)}
						</Stack>
					</Grid>
					<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
						<Grid container>
							<Grid item>
								<CalendarMonth className={global.formLabelIcon} />
							</Grid>
							<Grid item>
								<Typography variant="body2" className={global.formLabelText}>
									Date Received
								</Typography>
							</Grid>
						</Grid>
						<Stack>
							<BasicDatePicker
								disabled={isDisabled}
								value={formData?.receivedDate}
								setValue={updateFormValue('receivedDate')}
							/>
							{formData?.error?.includes('receivedDate') && (
								<span className={classes.errorText}>Received Date is required</span>
							)}
						</Stack>
					</Grid>
					<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
						<Grid container>
							<Grid item>
								<CardGiftcard className={global.formLabelIcon} />
							</Grid>
							<Grid item>
								<Typography variant="body2" className={global.formLabelText}>
									Gift Type
								</Typography>
							</Grid>
						</Grid>
						<Stack>
							<Stack flexDirection={'row'} alignItems={'center'}>
								<Autocomplete
									freeSolo
									fullWidth
									loading={giftTypeSearch.loading}
									loadingText="loading..."
									disabled={isDisabled}
									value={formData?.giftType ? formData?.giftType?.type : ''}
									onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
										setFormData({
											...formData,
											giftType: {
												id: parseInt(val?.id),
												type: val?.label,
											},
											error: formData?.error?.filter((err) => err !== 'giftType'),
										});
									}}
									options={(giftTypeSearch?.data || []).map((gift: any) => ({
										id: gift.id,
										label: gift.type,
									}))}
									renderOption={(props, option) => (
										<Box component="li" {...props} key={option.id}>
											{option.label}
										</Box>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											label=""
											InputProps={{
												...params.InputProps,
											}}
											onChange={(event: any) => {
												setGiftTypeSearchTerm(event.target.value);
											}}
											size={'small'}
											placeholder={'Search Gift Type'}
											className={isDisabled ? classes.disabledInput : ''}
										/>
									)}
								/>
								<div
									className={isDisabled ? classes.disabledIcon : classes.IconBtn}
									onClick={() => !isDisabled && setVisibleGiftType(true)}
								>
									<Add />
								</div>
							</Stack>
							{formData?.error?.includes('giftType') && (
								<span className={classes.errorText}>Gift Type is required</span>
							)}
						</Stack>
					</Grid>
				</Grid>
			</Paper>
			{visibleSearch && (
				<AdvancedSearch
					visible={visibleSearch}
					handleClose={handleVisibleSearch}
					field={searchFields}
					setField={setSearchFields}
					title={'Search Donor'}
					handleSearch={handleSearch}
				>
					<>
						<Stack alignItems={'flex-end'}>
							<TrimTables name="donorHead" list={donorHead} trimList={trimList} setTrimList={setTrimList} />
						</Stack>
						<DonorTable
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							reload={reload}
							setReload={setReload}
							trimList={trimList}
							setTrimList={setTrimList}
							handleOpenEdit={handleSelectRow}
							isSearch
							setPage={setPage}
							page={page}
						/>
					</>
				</AdvancedSearch>
			)}
			{visibleDonorModal && (
				<CreateEditDonor handleCloseEdit={() => setVisibleDonorModal(false)} visible={visibleDonorModal} />
			)}
			{visibleGiftType && (
				<BasicModal
					onClose={() => setVisibleGiftType(false)}
					visible={visibleGiftType}
					title={'Add a Gift Type'}
					data={undefined}
					isClose={false}
				>
					<CreateEditGiftType handleClose={() => setVisibleGiftType(false)} />
				</BasicModal>
			)}
		</div>
	);
};

export default CreateEditNonCashDonations;
