import * as React from 'react';
import { CalendarMonth, Handshake, AutoGraph } from '@mui/icons-material';
import SubPanel from 'Components/Common/SubPanel/SubPanel';

const cardArray = [
	{
		name: 'Agreement',
		Icon: Handshake,
		link: 'agreement',
	},
	{
		name: 'Monthly Posting',
		Icon: CalendarMonth,
		link: 'monthly-posting',
	},
	{
		name: 'Annual Posting',
		Icon: CalendarMonth,
		link: 'annual-posting',
	},
	{
		name: 'Generate Annuities EFT/Cheque File',
		Icon: CalendarMonth,
		link: 'generate-eft',
	},
	{
		name: 'Life Expectancy Adjustment',
		Icon: AutoGraph,
		link: 'life-expectancy-adjustment',
	},
	{
		name: 'Interest Rate',
		Icon: AutoGraph,
		link: 'interest-rate',
	},
];

const AnnuitiesModule = () => {
	return (
		<>
			<SubPanel cardArrays={cardArray} />
		</>
	);
};

export default AnnuitiesModule;
