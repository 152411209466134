import React, { useState, useEffect, useContext } from 'react';
import AdvancedSearch, { Field } from 'Components/Reusable/Modals/AdvancedSearch/AdvancedSearchModal';
import { serachDonorField } from 'Components/Reusable/Modals/AdvancedSearch/Mock';
import { Stack, TablePagination, Checkbox, Button } from '@mui/material';
import { Add } from '@material-ui/icons';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { DonorData, StateType, Donor } from 'contexts/intialstates/Types';
import { ColumnType, donorHead } from 'Components/Common/Tables/TableHeaders';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { convertDate } from 'helpers/dayAndTime';
import { pagination } from 'Components/Common/Tables/Tables';
import useDonorActions from 'hooks/useDonorActions';
import { GlobalContexts } from 'contexts/GlobalContext';
import { useLocation } from 'react-router-dom';
import { adhocDonor } from 'hooks/useCampaignActions';

interface P {
	visibleSearch: boolean;
	handleVisibleSearch: () => void;
	handleAdd?: (donors: Donor[]) => void;
	handleAddAdHoc?: (donors: adhocDonor[]) => void;
	onRowClick?: (donor: Donor) => void;
	isMultiSelect?: boolean;
	donorId?: number;
}

const DonorSearch = ({
	visibleSearch,
	handleVisibleSearch,
	handleAdd,
	isMultiSelect = false,
	onRowClick,
	donorId,
	handleAddAdHoc,
}: P) => {
	const { setErrorSnack, checkedDonorx, setCheckedDonorx } = useContext(GlobalContexts);
	const location = useLocation();
	const [searchFields, setSearchFields] = useState<Field[]>(serachDonorField);
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const { getAllDonors } = useDonorActions();
	const [checkedDonor, setCheckedDonor] = useState<Donor[]>([]);
	const [filterPayload, setFilterPayload] = React.useState<Array<any>>([]);
	const [donors, setDonors] = React.useState<StateType<DonorData>>({
		data: null,
		error: null,
		loading: false,
	});
	const isCampaign = location.pathname.includes('campaign');

	useEffect(() => {
		getAllDonors({
			setDonors,
			donors,
			pageSize,
			page,
			setErrorSnack,
			filterPayload: filterPayload.filter(({ field }) => field !== 'name'),
			donorQuery: filterPayload.find(({ field }) => field === 'name')?.query || '',
			archive: '&Filters=field=archived;value=false',
			donorId,
		});
	}, [page, pageSize, filterPayload]);

	const handleSearch = () => {
		const payload: any = searchFields.map(({ key, value }) => {
			if (value) {
				return {
					field: key,
					query: key !== 'phone' ? value : null,
					value: key === 'phone' ? [value] : null,
					from: null,
					to: null,
				};
			}
		});
		setFilterPayload(payload.filter(Boolean));
	};

	const handleCheck = (checked: boolean, donor: Donor) => {
		const data = [...checkedDonor];
		if (checked) {
			data.push(donor);
			setCheckedDonor(data);
		} else {
			setCheckedDonor(data.filter((item) => parseInt(item.id) !== parseInt(donor.id)));
		}
	};

	const handleCheck2 = (checked: boolean, donor: Donor) => {
		const data = [...checkedDonorx];
		if (checked) {
			data.push({ donorId: parseInt(donor?.id), name: `${donor?.firstName || ''} ${donor?.lastName || ''}` });
			setCheckedDonorx(data);
		} else {
			setCheckedDonorx(data.filter((item) => item?.donorId != parseInt(donor?.id)));
		}
	};

	const handleRowClick = (row: Donor) => {
		if (isMultiSelect) {
			const data = [...checkedDonor];
			if (data.some((item) => parseInt(item.id) === parseInt(row.id))) {
				setCheckedDonor(data.filter((item) => parseInt(item.id) !== parseInt(row.id)));
			} else {
				const findData = donors?.data?.body.find((item) => parseInt(item.id) === parseInt(row.id));
				if (findData) {
					data.push(findData);
					setCheckedDonor(data);
				}
			}
		} else {
			onRowClick?.(row);
		}
	};
	const handleRowClick2 = (row: Donor) => {
		if (isMultiSelect) {
			const data = [...checkedDonorx];
			if (data.some((item) => item?.donorId === parseInt(row.id))) {
				setCheckedDonorx(data.filter((item) => item?.donorId != parseInt(row.id)));
			} else {
				const findData = donors?.data?.body.find((item) => parseInt(item.id) === parseInt(row.id));
				if (findData) {
					data.push({
						donorId: parseInt(findData?.id),
						name: `${findData?.firstName || ''} ${findData?.lastName || ''}`,
					});
					setCheckedDonorx(data);
				}
			}
		} else {
			onRowClick?.(row);
		}
	};

	const getTableData = () => {
		return donors?.data?.body?.length
			? donors?.data?.body.map((row, index) => {
					return {
						's/n': isMultiSelect ? (
							<Checkbox
								checked={checkedDonor.some((item) => parseInt(item.id) === parseInt(row.id))}
								onChange={(e) => handleCheck(e.target.checked, row)}
							/>
						) : (
							page * pageSize + index + 1
						),
						id: parseInt(row.id),
						dateOfBirth: row?.dateOfBirth || '',
						salutation: row?.salutation || 'N/A',
						firstname: `${row?.firstName || ''} ${row?.lastName || ''}` || 'N/A',
						firstName: row?.firstName,
						lastName: row?.lastName,
						archived: row?.archived ? 'Archived' : 'Active',
						referencenumber: row?.referenceNumber || 'N/A',
						orgname: row?.orgName || 'N/A',
						parish: row?.parish?.parishName || 'N/A',
						phone: row?.phone || 'N/A',
						email: row?.email || 'N/A',
						mobile: row?.mobile || 'N/A',
						careof: row?.careOf || 'N/A',
						deceased: row?.deceased ? 'Yes' : 'No',
						province: row?.province?.provinceName || 'N/A',
						streetline1: row?.streetline1 || '',
						streetline2: row?.streetline2 || '',
						city: row?.city || 'N/A',
						postalcode: row?.postalCode || 'N/A',
						country: row?.country || 'N/A',
						createdBy: row?.createdBy?.name || 'N/A',
						updatedBy: row?.updatedBy?.name || 'N/A',
						created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
						updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
						annuitant: row?.annuitant,
					};
			  })
			: [];
	};
	const getTableData2 = () => {
		return donors?.data?.body?.length
			? donors?.data?.body.map((row, index) => {
					return {
						's/n': isMultiSelect ? (
							<Checkbox
								checked={checkedDonorx.some((item) => item?.donorId === parseInt(row.id))}
								onChange={(e) => handleCheck2(e.target.checked, row)}
							/>
						) : (
							page * pageSize + index + 1
						),
						id: parseInt(row.id),
						dateOfBirth: row?.dateOfBirth || '',
						salutation: row?.salutation || 'N/A',
						firstname: `${row?.firstName || ''} ${row?.lastName || ''}` || 'N/A',
						firstName: row?.firstName,
						lastName: row?.lastName,
						archived: row?.archived ? 'Archived' : 'Active',
						referencenumber: row?.referenceNumber || 'N/A',
						orgname: row?.orgName || 'N/A',
						parish: row?.parish?.parishName || 'N/A',
						phone: row?.phone || 'N/A',
						email: row?.email || 'N/A',
						mobile: row?.mobile || 'N/A',
						careof: row?.careOf || 'N/A',
						deceased: row?.deceased ? 'Yes' : 'No',
						province: row?.province?.provinceName || 'N/A',
						streetline1: row?.streetline1 || '',
						streetline2: row?.streetline2 || '',
						city: row?.city || 'N/A',
						postalcode: row?.postalCode || 'N/A',
						country: row?.country || 'N/A',
						createdBy: row?.createdBy?.name || 'N/A',
						updatedBy: row?.updatedBy?.name || 'N/A',
						created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
						updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
						annuitant: row?.annuitant,
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList;
	};

	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	return (
		<AdvancedSearch
			visible={visibleSearch}
			handleClose={handleVisibleSearch}
			field={searchFields}
			setField={setSearchFields}
			title={'Search Donor'}
			handleSearch={handleSearch}
		>
			<>
				<Stack alignItems={'flex-end'}>
					<TrimTables name="donorHead" list={donorHead} trimList={trimList} setTrimList={setTrimList} />
				</Stack>
				<CustomTable
					loading={donors?.loading}
					columnData={getColumnData()}
					tableData={isCampaign ? getTableData2() : getTableData()}
					handleDrag={handleDrag}
					handleResize={handleResize}
					emptyMessage={'No donors available'}
					handleRowClick={isCampaign ? handleRowClick2 : handleRowClick}
				/>
				{(donors?.data?.body?.length as number) > 0 && (
					<TablePagination
						rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
						component="div"
						count={donors?.data?.totalRecords as number}
						rowsPerPage={pageSize}
						page={page}
						sx={pagination}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
				{isCampaign && isMultiSelect && checkedDonorx.length > 0 && (
					<Button
						sx={{ margin: '10px' }}
						variant="contained"
						startIcon={<Add />}
						onClick={() => handleAddAdHoc?.(checkedDonorx)}
					>
						Add
					</Button>
				)}
				{!isCampaign && isMultiSelect && checkedDonor.length > 0 && (
					<Button
						sx={{ margin: '10px' }}
						variant="contained"
						startIcon={<Add />}
						onClick={() => handleAdd?.(checkedDonor)}
					>
						Add
					</Button>
				)}
			</>
		</AdvancedSearch>
	);
};

export default DonorSearch;
