import React, { useState, useContext } from 'react';
import useDonorActions from 'hooks/useDonorActions';
import { Stack, Button, Typography } from '@mui/material';
import { Add, Download } from '@mui/icons-material';
import { Donor, FilterPayload } from 'contexts/intialstates/Types';
import { GlobalContexts } from 'contexts/GlobalContext';
import ErrorSnack from 'Components/Common/ErrorSnack/ErrorSnack';
import DonorTable from 'Components/Donors/DonorListTable';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { ColumnType, donorHead } from 'Components/Common/Tables/TableHeaders';
import { useNavigate } from 'react-router-dom';
import Archive, { IArchiveStatus } from 'Components/Reusable/Archive';
import { styles } from './donors.style';
import useUserActions from 'hooks/useUserActions';

const Index = () => {
	const [page, setPage] = React.useState(0);
	const { handleRoles } = useUserActions();
	const { deleteSingleDonor } = useDonorActions();
	const [saveLoading, setSaveLoading] = useState(false);
	const [selectedData, setSelectedData] = useState<Donor>();
	const { setErrorSnack } = useContext(GlobalContexts);
	const [isDelete, setIsDelete] = useState(false);
	const [reload, setReload] = React.useState(false);
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const [exportBtn, setExportBtn] = useState<any>(
		<Button startIcon={<Download />} variant="outlined" sx={styles.csvBtn}>
			Download CSV
		</Button>,
	);
	const [archive, setArchive] = useState<IArchiveStatus>({
		label: 'All',
		filter: '',
	});
	const navigate = useNavigate();

	const handleOpenDelete = (data: Donor) => {
		setIsDelete(true);
		setSelectedData(data);
	};

	const handleCloseDelete = () => {
		setIsDelete(false);
		setSelectedData(undefined);
	};

	const handleDelete = async () => {
		if (selectedData) {
			setSaveLoading(true);
			const isSucess = await deleteSingleDonor(parseInt(selectedData.id));
			setSaveLoading(false);
			handleCloseDelete();
			if (isSucess) {
				setReload(!reload);
				setErrorSnack(false);
			} else {
				setErrorSnack(true);
			}
		}
	};

	return (
		<div>
			<Stack>
				<Stack direction={'row'} justifyContent={'space-between'} mb={3}>
					<Stack direction={'column'} spacing={3}>
						<Stack direction={'column'}>
							<Typography variant="h5" sx={styles.title}>
								Donors
							</Typography>
							<Stack>Add and manage donors here</Stack>
						</Stack>
						<Stack direction="row" spacing={2}>
							<Archive archive={archive} setArchive={setArchive} />
							{exportBtn}
						</Stack>
					</Stack>

					<Stack sx={styles.mb24} direction="row" alignItems={'center'} justifyContent="flex-end">
						<TrimTables name="donorHead" list={donorHead} trimList={trimList} setTrimList={setTrimList} />
						{handleRoles('donor-actions') && (
							<Button
								onClick={() => navigate('/donors/create')}
								sx={styles.addDonorBtn}
								startIcon={<Add />}
								variant="contained"
							>
								Add Donor
							</Button>
						)}
						<FilterListButton
							setIsReload={setReload}
							reload={reload}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							setPage={setPage}
							page={page}
						/>
					</Stack>
				</Stack>
				<DonorTable
					setFilterPayload={setFilterPayload}
					filterPayload={filterPayload}
					reload={reload}
					setReload={setReload}
					handleOpenDelete={handleOpenDelete}
					trimList={trimList}
					setTrimList={setTrimList}
					archive={archive.filter}
					setExportBtn={setExportBtn}
					page={page}
					setPage={setPage}
				/>
				<ConfirmationModal
					handleClose={() => setIsDelete(false)}
					message={'Are you sure you want to delete donor ?'}
					open={isDelete}
					handleConfirmation={handleDelete}
					handleCancel={() => setIsDelete(false)}
					loading={saveLoading}
				/>
			</Stack>
			<ErrorSnack />
		</div>
	);
};

export default Index;
