import React from 'react';
import {
	ResponseError,
	DonationTypes,
	DonationTypesData,
	StateType,
	FilterPayload,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { DonationTypeValues } from 'Components/AdminPanel/DonationType/CreateEdit';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface GetDonationTypes {
	setDonationTypes: React.Dispatch<React.SetStateAction<StateType<DonationTypesData>>>;
	donationTypes: StateType<DonationTypesData>;
	page?: number;
	pageSize?: number;
	orderBy?: string;
	filterPayload?: FilterPayload[];
}
export interface GetAllDonationTypes {
	setDonationTypes: React.Dispatch<React.SetStateAction<StateType<DonationTypes[]>>>;
	donationTypes: StateType<DonationTypes[]>;
}

export interface GetDonationTypeByIdTypes {
	setDonationTypeById: React.Dispatch<React.SetStateAction<StateType<DonationTypes>>>;
	donationTypeById: StateType<DonationTypes>;
	id: number;
}

interface GetDonationTypeSearch {
	donationType: StateType<DonationTypes[]>;
	setDonationType: React.Dispatch<React.SetStateAction<StateType<DonationTypes[]>>>;
	searchTerm: string;
	receipting?: boolean;
}

const useDonationTypesActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const getDonationTypeByID = async ({
		setDonationTypeById,
		donationTypeById,
		id,
	}: GetDonationTypeByIdTypes) => {
		try {
			setDonationTypeById({
				...donationTypeById,
				loading: true,
				error: null,
			});
			const response = await api.get(`donationtype/${id}`);
			setDonationTypeById({
				...donationTypeById,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDonationTypeById({
					...donationTypeById,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const getDonationTypes = async ({
		setDonationTypes,
		donationTypes,
		page,
		pageSize,
		orderBy,
		filterPayload,
	}: GetDonationTypes) => {
		try {
			setDonationTypes({
				...donationTypes,
				loading: true,
				error: null,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`/donationtype/list?PageNumber=${page && page + 1}&PageSize=${pageSize}${filter || ''}${
						orderBy || '&SortBy=updated%20desc'
					}`,
				);
			} else {
				response = await api.get(
					`/donationtype/list?${orderBy || 'SortBy=updated%20desc'}&PageSize=${pageSize}${filter || ''}`,
				);
			}

			setDonationTypes({
				...donationTypes,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || donationTypes.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDonationTypes({
					...donationTypes,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const getAllDonationTypes = async ({ setDonationTypes, donationTypes }: GetAllDonationTypes) => {
		try {
			setDonationTypes({
				...donationTypes,
				loading: true,
				error: null,
			});
			const response = await api.get(`/donationtype/lookup-list`, {});

			setDonationTypes({
				...donationTypes,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDonationTypes({
					...donationTypes,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};
	const createDonationType = async (payload: DonationTypeValues): Promise<DonationTypes | boolean> => {
		try {
			const response = await api.post(`donationtype/create`, {
				...payload,
			});
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Donation type created succesfully',
					severity: 'success',
					show: true,
				});
				return response?.data?.result;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const updateDonationType = async (payload: DonationTypeValues, id: number): Promise<boolean> => {
		try {
			const response = await api.put(`donationtype/${id}/update`, {
				...payload,
				id,
			});
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Donation type updated succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deleteDonationType = async (id: number): Promise<boolean> => {
		try {
			const response = await api.remove(`donationtype/${id}/archive`);
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Donation type deleted successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const getDonationTypeBySearch = async ({
		donationType,
		setDonationType,
		searchTerm,
		receipting,
	}: GetDonationTypeSearch) => {
		try {
			setDonationType({
				...donationType,
				loading: true,
			});
			let donationData;
			if (receipting) {
				donationData = await api.get(
					`donationtype/receipting/search?donationTypeQuery=${searchTerm}${receipting && '&receipting=true'}`,
				);
			} else {
				donationData = await api.get(`donationtype/search?donationTypeQuery=${searchTerm}`);
			}

			setDonationType({
				...donationType,
				loading: false,
				data: donationData?.data?.result,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDonationType({
					...donationType,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
		}
	};

	return {
		getDonationTypeByID,
		getDonationTypes,
		createDonationType,
		updateDonationType,
		deleteDonationType,
		getDonationTypeBySearch,
		getAllDonationTypes,
	};
};

export default useDonationTypesActions;
