import React, { useState, useEffect } from 'react';
import { useStyles } from './DonorReport.style';
import {
	Stack,
	Button,
	Paper,
	Grid,
	Typography,
	Select,
	MenuItem,
	TextField,
	FormControl,
	RadioGroup,
	Radio,
	FormControlLabel,
	Autocomplete,
	Box,
} from '@mui/material';
import { Search, Clear, AddTask } from '@mui/icons-material';
import { useGlobalStyles } from 'Components/Common/global/global';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import useRecieptScheduleAction, { ReceiptSchedule } from 'hooks/useReceiptScheduleActions';
import { StateType, Donor, Society, PaymentMethod, DonationTypes } from 'contexts/intialstates/Types';
import useSocietyActions from 'hooks/useSocietyAction';
import usePaymentmethodsActions from 'hooks/usePaymentMethodAction';
import useDonationTypesActions from 'hooks/useDonationTypesActions';
import LoadingButton from '@mui/lab/LoadingButton';
import DonorSearch from './DonorSearch';
import ActionModal from 'Components/Reusable/Modals/ActionModal/ActionModal';
import useDebounce from 'Components/Reusable/hooks/debounce';

interface DonationType {
	id: number;
	label: string;
}

export interface FormValues {
	start: string;
	end: string;
	minAmount?: string;
	maxAmount?: string;
	donors: Donor[];
	donationType: DonationType[];
	receiptFrequency: string[];
	society: string[];
	preferredSociety: string[];
	paymentMethod: string[];
	communication: string[];
	isdetailed: boolean;
	noMail?: boolean;
	excludeFromParishList?: boolean;
	returnMail?: boolean;
	deceased?: boolean;
	magazine?: boolean;
	noFollowAppeal?: boolean;
}

interface P {
	handleGetDonors: (values: FormValues, isGenerate?: boolean) => void;
	loading: boolean;
	onCancel: () => void;
	isEdit: boolean;
	isShowFilter: boolean;
}

const DonorReports = ({ handleGetDonors, loading, onCancel, isEdit, isShowFilter }: P) => {
	const [allSociety, setAllSoceity] = React.useState<StateType<Society[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [allPM, setPM] = React.useState<StateType<PaymentMethod[]>>({
		data: null,
		error: null,
		loading: false,
	});

	const classes = useStyles();
	const global = useGlobalStyles();
	const { getReceiptSchedule } = useRecieptScheduleAction();
	const { getSocieties } = useSocietyActions();
	const { getPaymentMethods } = usePaymentmethodsActions();
	const { getDonationTypeBySearch } = useDonationTypesActions();
	const [visibleSearch, setVisibleSearch] = useState(false);
	const [formData, setFormData] = useState<FormValues>({
		start: '',
		end: '',
		donors: [],
		donationType: [],
		receiptFrequency: [],
		society: [],
		preferredSociety: [],
		paymentMethod: [],
		communication: [],
		isdetailed: false,
	});

	useEffect(() => {
		getSocieties({ allSociety, setAllSoceity });
		getPaymentMethods({ allPM, setPM });
	}, []);

	const allSocieties = allSociety?.data;
	const allPaymentMethods = allPM?.data;
	const [openRequiredModal, setOpenRequiredModal] = useState(false);
	const [receiptSchedule, setReceiptSchedule] = useState<StateType<ReceiptSchedule[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [donationTypeSearchTerm, setDonationTypeSearchTerm] = useState('');
	const debouncedDonationSearchTerm = useDebounce(donationTypeSearchTerm, 1000);
	const [donationType, setDonationType] = useState<StateType<DonationTypes[]>>({
		data: null,
		loading: false,
		error: null,
	});

	useEffect(() => {
		if (!debouncedDonationSearchTerm) return;
		getDonationTypeBySearch({ donationType, setDonationType, searchTerm: debouncedDonationSearchTerm });
	}, [debouncedDonationSearchTerm]);

	useEffect(() => {
		getReceiptSchedule({ receiptSchedule, setReceiptSchedule });
	}, []);

	const handleVisibleSearch = () => {
		setVisibleSearch(!visibleSearch);
	};

	const handleAddDonor = (donors: Donor[]) => {
		setFormData({
			...formData,
			donors,
		});
		handleVisibleSearch();
	};

	const handleChange = (field: string, value: string | boolean) => {
		setFormData({
			...formData,
			[field]: value,
		});
	};

	const handleSelectChange = (field: string, value: string | string[] | boolean) => {
		setFormData({
			...formData,
			[field]: typeof value === 'string' ? value.split(',') : value,
		});
	};
	const validationMessage = () => {
		if (!formData?.start || !formData?.end) return 'Please fill out donation recieved date range field';
	};
	const handleGenerate = async (isGenerate?: boolean) => {
		if (!formData.start || !formData.end) {
			setOpenRequiredModal(true);
			return;
		}
		await handleGetDonors(formData, isGenerate);
	};

	const handleCancel = () => {
		setFormData({
			start: '',
			end: '',
			minAmount: '',
			maxAmount: '',
			donors: [],
			donationType: [],
			receiptFrequency: [],
			society: [],
			preferredSociety: [],
			paymentMethod: [],
			communication: [],
			isdetailed: false,
		});
		onCancel();
	};

	const handleDoanationSelectChange = (field: string, value: DonationType[]) => {
		setFormData({
			...formData,
			[field]: value,
		});
	};

	return (
		<Stack>
			{isShowFilter && (
				<>
					<Stack className={classes.headerFilter}>
						<span>Filters</span>
					</Stack>
					<Paper className={classes.paperWrap}>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
							<Grid item xs={12} md={4} sm={6} mb={3}>
								<Typography variant="body2" className={global.formLabelText} mb={1}>
									Donation Received Date Range
								</Typography>
								<Stack direction="row" pr={{ lg: 5, md: 0 }}>
									<BasicDatePicker
										size="small"
										value={formData.start}
										setValue={(e) => handleChange('start', e.target.value)}
									/>
									<Stack m={1}>To</Stack>
									<BasicDatePicker
										size="small"
										value={formData.end}
										setValue={(e) => handleChange('end', e.target.value)}
									/>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3}>
								<Typography variant="body2" className={global.formLabelText} mb={1}>
									Receipt Frequency
								</Typography>
								<Stack pr={{ lg: 5, md: 0 }}>
									<Select
										size="small"
										value={formData.receiptFrequency}
										name="receiptFrequency"
										multiple
										onChange={(event) => {
											handleSelectChange('receiptFrequency', event.target.value);
										}}
									>
										{receiptSchedule.data?.map(({ id, schedule }) => (
											<MenuItem key={id} value={id}>
												{schedule === 'Yearly' ? 'Year-End' : schedule}
											</MenuItem>
										))}
									</Select>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3}>
								<Typography variant="body2" className={global.formLabelText} mb={1}>
									Donation Type
								</Typography>
								<Stack pr={{ lg: 5, md: 0 }}>
									<Autocomplete
										freeSolo
										value={formData.donationType}
										loading={donationType.loading}
										loadingText="loading..."
										onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
											handleDoanationSelectChange('donationType', val);
										}}
										fullWidth
										options={(donationType?.data || []).map((donation: DonationTypes) => ({
											id: donation.id,
											label: `${donation.type} - ${donation.description}`,
										}))}
										renderOption={(props, option) => (
											<Box component="li" {...props} key={option.id}>
												{option.label}
											</Box>
										)}
										multiple
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label=""
												InputProps={{
													...params.InputProps,
												}}
												onChange={(event: any) => {
													setDonationTypeSearchTerm(event.target.value);
												}}
												size={'small'}
												placeholder={'Search donation type'}
											/>
										)}
									/>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3}>
								<Typography variant="body2" className={global.formLabelText} mb={1}>
									Society
								</Typography>
								<Stack pr={{ lg: 5, md: 0 }}>
									<Select
										size="small"
										value={formData.society}
										name="society"
										multiple
										onChange={(event) => {
											handleSelectChange('society', event.target.value);
										}}
									>
										{(allSocieties || [])
											?.filter((curr) => curr.code !== 'AN')
											.map((x: { id: number; code: string }) => (
												<MenuItem key={x.id} value={x.id}>
													{x.code}
												</MenuItem>
											))}
									</Select>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3}>
								<Typography variant="body2" className={global.formLabelText} mb={1}>
									Preferred Society
								</Typography>
								<Stack pr={{ lg: 5, md: 0 }}>
									<Select
										size="small"
										value={formData.preferredSociety}
										name="preferredSociety"
										multiple
										onChange={(event) => {
											handleSelectChange('preferredSociety', event.target.value);
										}}
									>
										<MenuItem value={'faithPropagation'}>Propagation of Faith</MenuItem>
										<MenuItem value={'holyChildhood'}>Holy Childhood</MenuItem>
										<MenuItem value={'stPeter'}>Saint Peter</MenuItem>
									</Select>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3}>
								<Typography variant="body2" className={global.formLabelText} mb={1}>
									Magazine
								</Typography>
								<Stack pr={{ lg: 5, md: 0 }}>
									<Select
										size="small"
										value={formData.magazine}
										name="magazine"
										onChange={(event) => {
											handleSelectChange('magazine', event.target.value.toString() === 'true');
										}}
									>
										<MenuItem value={''}>Clear</MenuItem>
										<MenuItem value={'true'}>True</MenuItem>
										<MenuItem value={'false'}>False</MenuItem>
									</Select>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3}>
								<Typography variant="body2" className={global.formLabelText} mb={1}>
									No Follow up Appeal
								</Typography>
								<Stack pr={{ lg: 5, md: 0 }}>
									<Select
										size="small"
										value={formData.noFollowAppeal}
										name="noFollowAppeal"
										onChange={(event) => {
											handleSelectChange('noFollowAppeal', event.target.value.toString() === 'true');
										}}
									>
										<MenuItem value={''}>Clear</MenuItem>
										<MenuItem value={'true'}>True</MenuItem>
										<MenuItem value={'false'}>False</MenuItem>
									</Select>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3}>
								<Typography variant="body2" className={global.formLabelText} mb={1}>
									Payment Method
								</Typography>
								<Stack pr={{ lg: 5, md: 0 }}>
									<Select
										size="small"
										value={formData.paymentMethod}
										name="paymentMethod"
										multiple
										onChange={(event) => {
											handleSelectChange('paymentMethod', event.target.value);
										}}
									>
										{(allPaymentMethods || []).map((x: { id: number; name: string }) => (
											<MenuItem key={x.id} value={x.id}>
												{x.name}
											</MenuItem>
										))}
									</Select>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3}>
								<Typography variant="body2" className={global.formLabelText} mb={1}>
									Donation Amount Range
								</Typography>
								<Stack direction="row" alignItems="center" pr={{ lg: 5, md: 0 }}>
									<TextField
										size={'small'}
										fullWidth
										placeholder={'Min'}
										value={formData.minAmount}
										onChange={(e) => handleChange('minAmount', e.target.value)}
									/>
									<Stack sx={{ margin: 1 }}>-</Stack>
									<TextField
										size={'small'}
										fullWidth
										placeholder={'Max'}
										value={formData.maxAmount}
										onChange={(e) => handleChange('maxAmount', e.target.value)}
									/>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
								<Grid container>
									<Grid item>
										<Typography variant="body2" className={global.formLabelText}>
											Communication
										</Typography>
									</Grid>
								</Grid>
								<Select
									fullWidth
									size="small"
									value={formData?.communication}
									name="communication"
									multiple
									onChange={(event) => {
										handleSelectChange('communication', event.target.value);
									}}
								>
									<MenuItem value={'isCommunicationNone'}>Select All</MenuItem>
									<MenuItem value={'noMail'}>Do not send email</MenuItem>
									<MenuItem value={'excludeFromParishList'}>Exclude from Paris List</MenuItem>
									<MenuItem value={'returnMail'}>Returned Mail</MenuItem>
									<MenuItem value={'deceased'}>Deceased</MenuItem>
								</Select>
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3}>
								<Typography variant="body2" className={global.formLabelText} mb={1}>
									Donor
								</Typography>
								<Stack direction="row" alignItems={'center'} pr={{ lg: 5, md: 0 }}>
									<TextField
										size={'small'}
										value={formData.donors
											.map((item) => {
												return `${item.firstName} ${item.lastName}`;
											})
											.join(', ')}
										placeholder={'Search donors'}
										disabled
										fullWidth
									/>
									<Stack>
										<Button
											onClick={() => handleVisibleSearch()}
											startIcon={<Search />}
											variant="outlined"
											size={'small'}
											className={global.whiteBtn}
											sx={{ margin: '5px' }}
										>
											Search
										</Button>
									</Stack>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
								<FormControl sx={{ '& .css-ahj2mt-MuiTypography-root': { fontSize: 14 } }}>
									<RadioGroup
										aria-labelledby="demo-controlled-radio-buttons-group"
										name="controlled-radio-buttons-group"
										value={formData?.isdetailed}
										onChange={(e) => {
											handleChange('isdetailed', e.target.value === 'true');
										}}
									>
										<FormControlLabel value={'false'} control={<Radio />} label="Summary Report" />
										<FormControlLabel value={'true'} control={<Radio />} label="Detailed Report" />
									</RadioGroup>
								</FormControl>
							</Grid>
							{isEdit && (
								<Grid item xs={12} md={4} sm={6} mb={3}>
									<Stack direction="row" spacing={2} height="100%" alignItems="flex-end" p={1}>
										<LoadingButton
											startIcon={<AddTask />}
											variant="contained"
											onClick={() => handleGenerate()}
											type="submit"
											loading={loading}
											loadingPosition="start"
											size="small"
										>
											Apply
										</LoadingButton>

										<Button
											startIcon={<Clear />}
											variant="outlined"
											className={global.whiteBtn}
											onClick={handleCancel}
											size="small"
										>
											Cancel
										</Button>
									</Stack>
								</Grid>
							)}
						</Grid>
					</Paper>
				</>
			)}
			{!isEdit && (
				<Stack m={3} direction="row">
					<LoadingButton
						startIcon={<AddTask />}
						variant="contained"
						onClick={() => handleGenerate(true)}
						loading={loading}
						loadingPosition="start"
						size="small"
					>
						Generate Report
					</LoadingButton>
				</Stack>
			)}
			{visibleSearch && (
				<DonorSearch
					handleVisibleSearch={handleVisibleSearch}
					visibleSearch={visibleSearch}
					handleAdd={handleAddDonor}
					isMultiSelect
				/>
			)}
			{openRequiredModal && (
				<ActionModal
					open={openRequiredModal}
					title={'Required field'}
					subTitle={validationMessage()}
					closeText={'Close'}
					handleClose={() => setOpenRequiredModal(false)}
				/>
			)}
		</Stack>
	);
};

export default DonorReports;
