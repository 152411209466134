import React from 'react';
import { Grid, Divider, Paper, Typography, Stack } from '@mui/material';
import { StateType } from 'contexts/intialstates/Types';
import { Agreement } from 'hooks/useAnnuitiesActions';
import { convertDate } from 'helpers/dayAndTime';
import FormatCurrency from 'helpers/FormatCurrency';

export interface AnnuitiesData {
	title: string;
	data: { [key: string]: any };
}

interface P {
	agreement: StateType<Agreement>;
}

const ViewAnnuities = ({ agreement }: P) => {
	const getAnnuities = (): AnnuitiesData[] => {
		const values = agreement.data;
		if (!values) return [];
		return [
			{
				title: 'Details',
				data: {
					'Agreement Type': values.agreementType.type,
					'Province of Registration': values.provinceOfRegistration?.provinceName,
					'Payment Method': values.paymentMethod.name,
					'Life Expectancy': values.lifeExpectancy,
				},
			},
			{
				title: 'Annuitant Details',
				data: {
					'Donor ID': values.annuitant?.donor.id ?? '-',
					Salutation: values.annuitant?.donor.salutation ?? '-',
					Name: `${values.annuitant?.donor?.firstName ?? ''} ${values.annuitant?.donor?.lastName ?? ''}`,
					SIN: values.annuitant?.sin ?? '-',
					Gender: values.annuitant?.gender?.name ?? '-',
					'Marital Status': values.annuitant?.maritalStatus?.status ?? '',
					'Date of Birth': values.annuitant?.dateOfBirth ? convertDate(values.annuitant?.dateOfBirth) : '-',
					'Date of Death': values.annuitant?.dateOfDeath ? convertDate(values.annuitant?.dateOfDeath) : '-',
				},
			},
			{
				title: 'Co-Annuitant Details',
				data: {
					'Donor ID': values.coAnnuitant?.donor.id ?? '-',
					Salutation: values.coAnnuitant?.donor.salutation ?? '-',
					Name: values.coAnnuitant?.donor
						? `${values.coAnnuitant?.donor?.firstName ?? ''} ${values.coAnnuitant?.donor?.lastName ?? ''}`
						: '',
					SIN: values.coAnnuitant?.sin ?? '-',
					Gender: values.coAnnuitant?.gender?.name ?? '-',
					'Marital Status': values.coAnnuitant?.maritalStatus?.status ?? '',
					'Date of Birth': values.coAnnuitant?.dateOfBirth
						? convertDate(values.coAnnuitant?.dateOfBirth)
						: '-',
					'Date of Death': values.coAnnuitant?.dateOfDeath
						? convertDate(values.coAnnuitant?.dateOfDeath)
						: '-',
				},
			},
			{
				title: 'Address Details',
				data: {
					'Streetline 1': values.annuitant.donor.streetline1,
					'Streetline 2': values.annuitant.donor.streetline2,
					City: values.annuitant.donor.city,
					Province: values.annuitant.donor?.province?.provinceName ?? '',
					'Postal Code': values.annuitant.donor.postalCode,
					Country: values.annuitant.donor.country,
				},
			},
			{
				title: 'Bank Details',
				data: {
					Name: values.bankName,
					Address: values.bankAddress,
					'Bank Number': values.bankNumber,
					'Bank Branch Number': values.bankBranchNumber,
					'Bank Account Number': values.bankAccountNumber,
					'Payable To': values.bankPayableTo,
				},
			},
			{
				title: 'Purchase',
				data: {
					'Received Amount': values.receivedAmount ? <FormatCurrency value={values.receivedAmount} /> : '-',
					'Receipt Amount': values.receiptedAmount ? <FormatCurrency value={values.receiptedAmount} /> : '-',
					'Date Received': values.dateReceived ? convertDate(values.dateReceived) : '-',
				},
			},
			{
				title: 'Annuity Payment',
				data: {
					'Payment Frequency': values.paymentFrequency?.frequency ?? '',
					'First Payment Date': values.firstPaymentDate ? convertDate(values.firstPaymentDate) : '-',
					'First Annuity Payment': values.firstAnnuityPayment ? (
						<FormatCurrency value={values.firstAnnuityPayment} />
					) : (
						'-'
					),
					'Periodic Payment': values.periodicPayment ? (
						<FormatCurrency value={values.periodicPayment} />
					) : (
						'-'
					),
					'Annual Payment': values.annualPayment ? <FormatCurrency value={values.annualPayment} /> : '-',
					'Annual Rate': values.annualRate ? `${values.annualRate} %` : '-',
				},
			},
			{
				title: 'Tax Related',
				data: {
					'Tax Portion Annuity': `${values.taxPortionAnnuity} %`,
					'First Payment Taxable Amount': values.firstPaymentTaxableAmount ? (
						<FormatCurrency value={values.firstPaymentTaxableAmount} />
					) : (
						'-'
					),
					'First Payment Non Taxable Portion': (
						<FormatCurrency value={values.firstAnnuityPayment - values.firstPaymentTaxableAmount} />
					),
					'Annual Payment Taxable Amount': values.annualPaymentTaxableAmount ? (
						<FormatCurrency value={values.annualPaymentTaxableAmount} />
					) : (
						'-'
					),
					'Annual Payment Non Taxable Portion': (
						<FormatCurrency value={values.annualPayment - values.annualPaymentTaxableAmount} />
					),
				},
			},
			{
				title: 'Account',
				data: {
					'Account Balance': values.accountBalance,
					'Account Date': values.accountDate ? convertDate(values.accountDate) : '-',
					'Actuarial Value': values.acturialValue,
					'Valuation Date': values.valuationDate ? convertDate(values.valuationDate) : '-',
				},
			},
			{
				title: 'Beneficiary',
				data: {
					'Beneficiary Society': values.beneficiarySociety?.code ?? '',
					Comment: values.comment,
				},
			},
		];
	};

	return (
		<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
			{getAnnuities().map(({ title, data }, index) => (
				<Grid item xs={12} sm={6} key={index}>
					<Paper sx={{ margin: '10px', padding: '10px' }}>
						<Typography sx={{ fontSize: 14, fontWeight: 600, marginBottom: '5px' }}>{title}</Typography>
						<Divider />
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} p={3}>
							{Object.keys(data).map((item, index) => (
								<Grid item xs={12} sm={6} key={index} mb={2}>
									<Stack direction="column">
										<Typography
											sx={{ fontSize: 12, fontWeight: 600, color: 'var(--grayText)', marginBottom: '5px' }}
										>
											{item}
										</Typography>
										<Typography
											sx={{ fontSize: 14, fontWeight: 400, color: 'var(--fontColorHeaderSubTitle)' }}
										>
											{data[item] || '-'}
										</Typography>
									</Stack>
								</Grid>
							))}
						</Grid>
					</Paper>
				</Grid>
			))}
		</Grid>
	);
};

export default ViewAnnuities;
