import React, { useRef } from 'react';
import {
	Stack,
	Table,
	Box,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import Report from '../Report';
import { convertDate } from 'helpers/dayAndTime';
import { useStyles } from 'Components/Reports/DonorReports/DonorReport.style';
import FormatCurrency from 'helpers/FormatCurrency';
import { useReactToPrint } from 'react-to-print';
import Logo from '../../../assets/images/logo.png';
import { currentDate } from 'helpers/dayAndTime';

export interface AnnuitiesData {
	annuitant: string;
	coAnnuitant: string;
	status: string;
	frequency: string;
	dateReceived: string;
	receivedAmount: number;
	periodicPayment: number;
	overPayment: number;
	paymentMethod: string;
}

interface P {
	annuitiesData: AnnuitiesData[];
	start: string;
	end: string;
	handleExport?: (ispdf?: boolean) => void;
}

const AnnuitiesReportView = ({ annuitiesData, start, end, handleExport }: P) => {
	const classes = useStyles();
	const componentRef = useRef(null);

	const getReceivedTotal = () => {
		return annuitiesData.map((item) => item.receivedAmount).reduce((prev, curr) => prev + curr, 0);
	};

	const getPeriodicTotal = () => {
		return annuitiesData.map((item) => item.periodicPayment).reduce((prev, curr) => prev + curr, 0);
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const splitter = () => {
		if (!annuitiesData.length) return;
		const data = [];

		for (let i = 0; i < annuitiesData.length; i += 9) {
			data.push(annuitiesData.slice(i, i + 9));
		}
		return data;
	};

	const printData = () => {
		const splitData = splitter();
		return (
			<TableContainer>
				{splitData?.map((data, index) => {
					return (
						<div className={classes.pageBreak} key={index}>
							<Table sx={{ minWidth: 700 }} key={index}>
								<TableHead className={classes.tableHead}>
									<TableRow>
										<TableCell align="center">Donor</TableCell>
										<TableCell align="center">Status</TableCell>
										<TableCell align="center">Frequency</TableCell>
										<TableCell align="center">Payment Method</TableCell>
										<TableCell align="center">Received Date</TableCell>
										<TableCell align="center">Received Amount</TableCell>
										<TableCell align="center">Periodic Payment</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map((row: any, index: number) => (
										<TableRow key={index} className={classes.tableRowWrap}>
											<TableCell align="center">{`${row.annuitant} ${row.coAnnuitant.trim() ? ', ' : ''}${
												row.coAnnuitant
											}`}</TableCell>
											<TableCell align="center">{row.status || 'N/A'}</TableCell>
											<TableCell align="center">{row.frequency || 'N/A'}</TableCell>
											<TableCell align="center">{row.paymentMethod || 'N/A'}</TableCell>
											<TableCell align="center">
												{row.dateReceived ? convertDate(row.dateReceived) : 'N/A'}
											</TableCell>
											<TableCell align="center">
												{row.receivedAmount ? <FormatCurrency value={row.receivedAmount} /> : 'N/A'}
											</TableCell>
											<TableCell align="center">
												{row.periodicPayment ? <FormatCurrency value={row.periodicPayment} /> : 'N/A'}
											</TableCell>
										</TableRow>
									))}
									{index === splitData.length - 1 && (
										<TableRow className={classes.tableTotal}>
											<TableCell colSpan={7} align="center">
												<Stack pr={{ lg: 5, md: 0 }} mt={2} direction="row" width="100%">
													<Stack direction="row" justifyContent={'flex-end'} width="100%">
														<Stack
															width="50%"
															mb={0.5}
															py={1}
															sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
														>
															<Stack
																direction="row"
																justifyContent={'space-between'}
																sx={{ borderBottom: '1px solid #ABAEB0', width: '100%', px: 2 }}
															>
																<Box>Grand Total</Box>
																<Box>
																	<FormatCurrency value={getReceivedTotal()} />
																</Box>
																<Box mr={3}>
																	<FormatCurrency value={getPeriodicTotal()} />
																</Box>
															</Stack>
														</Stack>
													</Stack>
												</Stack>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</div>
					);
				})}
			</TableContainer>
		);
	};

	return (
		<div>
			<Report
				title="Agreement Report"
				subTitle={`From ${convertDate(start)} To ${convertDate(end)}`}
				handlePrint={() => handleExport?.(true)}
				handleExport={handleExport}
			>
				<TableContainer>
					<Table sx={{ minWidth: 700 }}>
						<TableHead className={classes.tableHead}>
							<TableRow>
								<TableCell align="center">Donor</TableCell>
								<TableCell align="center">Status</TableCell>
								<TableCell align="center">Frequency</TableCell>
								<TableCell align="center">Payment Method</TableCell>
								<TableCell align="center">Received Date</TableCell>
								<TableCell align="center">Received Amount</TableCell>
								<TableCell align="center">Periodic Payment</TableCell>
								{/* <TableCell align="center">Over Payment</TableCell> */}
							</TableRow>
						</TableHead>
						<TableBody>
							{annuitiesData.map((row, index) => (
								<TableRow key={index} className={classes.tableRowWrap}>
									<TableCell align="center">{`${row.annuitant} ${row.coAnnuitant.trim() ? ', ' : ''}${
										row.coAnnuitant
									}`}</TableCell>
									<TableCell align="center">{row.status || 'N/A'}</TableCell>
									<TableCell align="center">{row.frequency || 'N/A'}</TableCell>
									<TableCell align="center">{row.paymentMethod || 'N/A'}</TableCell>
									<TableCell align="center">
										{row.dateReceived ? convertDate(row.dateReceived) : 'N/A'}
									</TableCell>
									<TableCell align="center">
										{row.receivedAmount ? <FormatCurrency value={row.receivedAmount} /> : 'N/A'}
									</TableCell>
									<TableCell align="center">
										{row.periodicPayment ? <FormatCurrency value={row.periodicPayment} /> : 'N/A'}
									</TableCell>
								</TableRow>
							))}
							<TableRow className={classes.tableTotal}>
								<TableCell colSpan={7} align="center">
									<Stack pr={{ lg: 5, md: 0 }} mt={2} direction="row" width="100%">
										<Stack direction="row" justifyContent={'flex-end'} width="100%">
											<Stack
												width="50%"
												mb={0.5}
												py={1}
												sx={{ borderTop: '1px solid #ABAEB0', borderBottom: '1px solid #ABAEB0' }}
											>
												<Stack
													direction="row"
													justifyContent={'space-between'}
													sx={{ borderBottom: '1px solid #ABAEB0', width: '100%', px: 2 }}
												>
													<Box>Grand Total</Box>
													<Box>
														<FormatCurrency value={getReceivedTotal()} />
													</Box>
													<Box mr={3}>
														<FormatCurrency value={getPeriodicTotal()} />
													</Box>
												</Stack>
											</Stack>
										</Stack>
									</Stack>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Report>
			<div style={{ display: 'none' }}>
				<div style={{ padding: 16 }} ref={componentRef}>
					<Stack sx={{ my: 2, px: 1 }}>
						<>
							<Stack width="100%" direction="row">
								<Stack flex={0.5} alignItems="flex-start" direction="column">
									<img src={Logo} alt="logo" width={40} height={50} />
								</Stack>

								<Stack sx={{ mb: 2, textAlign: 'center' }} alignItems="center" direction="column">
									<Typography sx={{ color: 'var(--mainColor)', fontSize: 20, fontWeight: 700 }}>
										Agreement Report
									</Typography>
									<Typography sx={{ color: 'var(--fontColorHeaderSubTitle)', fontSize: 18, fontWeight: 400 }}>
										{`From ${convertDate(start)} To ${convertDate(end)}`}
									</Typography>
								</Stack>
							</Stack>
							<Stack
								direction={'row'}
								justifyContent="flex-end"
								mb={3}
								sx={{ color: 'var(--fontColorHeaderSubTitle)', fontSize: 16, fontWeight: 400 }}
							>
								{currentDate()}
							</Stack>
						</>

						<Box width="100%">{printData()}</Box>
					</Stack>
				</div>
			</div>
		</div>
	);
};

export default AnnuitiesReportView;
