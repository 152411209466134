import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { StarBorder } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface Props {
	title: string;
	lists: {
		label: string;
		link: string;
	}[];
}

interface MiniCardProps {
	children: any;
	title: string;
}

export const MiniCard = ({ children, title }: MiniCardProps) => {
	return (
		<Card sx={{ width: '100%', height: 'fit-content' }}>
			<Stack
				direction="row"
				spacing={2}
				alignItems="center"
				justifyContent="flex-start"
				sx={{
					height: 45,
					background: 'var(--mainBackground)',
					width: '100%',
					color: 'var(--formLabelColor)',
					pl: 3,
				}}
			>
				<Typography sx={{ fontSize: 18, fontWeight: 600, ml: 4 }}>{title}</Typography>
			</Stack>
			<CardContent sx={{ pl: 6.5 }}>{children}</CardContent>
		</Card>
	);
};

const ReportCard = ({ title, lists }: Props) => {
	const navigate = useNavigate();
	return (
		<Card sx={{ width: '100%', height: 'fit-content' }}>
			<Stack
				direction="row"
				spacing={2}
				alignItems="center"
				justifyContent="center"
				sx={{
					height: 55,
					background: 'var(--mainColor)',
					width: '100%',
					color: '#fff',
					pl: 3,
				}}
			>
				<span>
					<StarBorder sx={{ fontSize: 25 }} />
				</span>
				<Typography sx={{ fontSize: 18, fontWeight: 600 }}>{title}</Typography>
			</Stack>
			<CardContent>
				<Stack
					direction="column"
					sx={{
						'& :not(:last-child)': { borderBottom: '1px solid var(--divider)' },
						textAlign: 'center',
					}}
				>
					{lists.map((list) => (
						<Box
							key={list.label}
							onClick={() => navigate(`${list.link}`)}
							sx={{
								'&:hover': {
									background: 'var(--mainBackground)',
									borderBottom: 'none',
									borderRadius: '5px',
									cursor: 'pointer',
								},
								fontSize: 18,
								py: 1,
								lineHeight: 1.3,
								color: 'var(--formLabelColor)',
							}}
						>
							{list.label}
						</Box>
					))}
				</Stack>
			</CardContent>
		</Card>
	);
};

export default ReportCard;
