import React, { useState, useEffect } from 'react';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';
import { Paper, Chip, TablePagination, Tooltip } from '@mui/material';
import { pagination } from 'Components/Common/Tables/Tables';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import useAnnuitiesActions, { Annuities, AnnuitiesData } from 'hooks/useAnnuitiesActions';
import { StateType, OrderType } from 'contexts/intialstates/Types';
import { convertDate } from 'helpers/dayAndTime';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import { sortTable } from 'Components/Common/Tables/sortTable';
import { useNavigate } from 'react-router-dom';
import useUserActions from 'hooks/useUserActions';

interface P {
	trimList: ColumnType[];
	setTrimList: React.Dispatch<React.SetStateAction<ColumnType[]>>;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	reload: boolean;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	page: number;
	filterPayload: any[];
	setFilterPayload: React.Dispatch<React.SetStateAction<any[]>>;
}

const AnnuitiesTable = ({
	trimList,
	setTrimList,
	reload,
	setReload,
	page,
	setPage,
	filterPayload,
	setFilterPayload,
}: P) => {
	const { handleRoles } = useUserActions();
	const navigate = useNavigate();
	const { getAnnuities } = useAnnuitiesActions();
	const [annuities, setAnnuities] = useState<StateType<Annuities>>({
		loading: false,
		error: null,
		data: null,
	});
	const [pageSize, setPageSize] = useState(10);
	const [orderBy, setOrderBy] = useState<string>('');
	const [orderType, setOrderType] = useState<OrderType>('asc');
	const [label, setLabel] = useState<string>('');

	useEffect(() => {
		getAnnuities({ annuities, setAnnuities, page, pageSize, orderBy, filterPayload });
	}, [orderBy, page, pageSize, reload]);

	const handleRowClick = (row: any) => {
		navigate(`${row.id}`);
	};

	const getTableData = () => {
		return annuities?.data?.body.map((row: AnnuitiesData, index: number) => {
			return {
				's/n': page * pageSize + index + 1,
				id: row.id,
				agreementNumber: row.agreementNumber,
				annuitantId: row.annuitant.donor.id,
				annuitant: `${row.annuitant.donor.firstName} ${row.annuitant.donor.lastName}`,
				receivedAmount: row.receivedAmount,
				agreementType: row.agreementType.type,
				status: row.status.status ? (
					<Chip label={row.status.status} color="primary" variant="outlined" />
				) : (
					'N/A'
				),
				state: row.state.state ? <Chip label={row.state.state} color="primary" variant="outlined" /> : 'N/A',
				created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
				createdBy: row?.createdBy?.name || 'N/A',
				updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
				updatedBy: row?.updatedBy?.name || 'N/A',
			};
		});
	};
	const getColumnData = () => {
		return trimList.map((column) => {
			return {
				...column,
				data: (
					<>
						<Tooltip title={column.id == 'actions' ? '' : `Sort by ${column.label}`}>
							<span
								style={{
									cursor: 'pointer',
									margin: '10px',
								}}
								onClick={() => {
									sortTable({
										column: column.id,
										setLabel,
										setOderBy: setOrderBy,
										setOrderType,
										orderType,
										label,
									});
								}}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							reload={reload}
							setIsReload={setReload}
							setPage={setPage}
							page={page}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
						/>
					</>
				),
			};
		});
	};
	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};
	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};
	const handleChangePage = (event: unknown, value: number) => {
		setPage(value);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<CustomTable
				loading={annuities?.loading}
				columnData={getColumnData()}
				tableData={getTableData()}
				handleDrag={handleDrag}
				handleResize={handleResize}
				emptyMessage={'No data available'}
				handleRowClick={handleRowClick}
			/>
			<TablePagination
				rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
				component="div"
				count={annuities?.data?.totalRecords || 0}
				rowsPerPage={pageSize}
				page={page}
				sx={pagination}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
};
export default AnnuitiesTable;
