import { makeStyles } from '@material-ui/core/styles';
// import { StyleSheet, Font } from '@react-pdf/renderer';

export const useStyles = makeStyles({
	title: {
		color: 'var(--mainColor)',
		fontSize: '32px',
		fontWeight: 700,
	},
	subTitle: {
		border: '1px solid var(--mainColor)',
		borderRadius: '4px',
		padding: '5px',
		background: 'var(--white)',
		color: 'var(--mainColor)',
		fontSize: '12px',
	},
	backIconWrapper: {
		background: 'var(--white)',
		borderRadius: '4px',
		padding: '5px',
		color: 'var(--mainColor)',
		cursor: 'pointer',
	},
	paperWrap: {
		padding: '20px',
	},
	IconBtn: {
		cursor: 'pointer',
		margin: '0 12px',
		border: '1px solid var(--mainColor)',
		borderRadius: '6px',
		padding: '3px 10px',
		display: 'flex',
		background: 'var(--white)',
		'& svg': {
			color: 'var(--mainColor)',
		},
	},
	disabledIcon: {
		margin: '0 12px',
		border: '1px solid var(--disabled)',
		borderRadius: '6px',
		padding: '3px 10px',
		display: 'flex',
		background: 'var(--white)',
		'& svg': {
			color: 'var(--disabled)',
		},
	},
	disabledInput: {
		background: 'var(--invalidBackground)',
	},
	searchFieldIcon: {
		fontSize: '18px',
		marginRight: '5px',
		color: 'var(--mainColor)',
	},
	headerWrap: {
		background: 'var(--mainBackground)',
		borderRadius: '10px',
		padding: '13px',
		overflow: 'auto',
	},
	headerContent: {
		borderRight: '2px solid var(--white)',
	},
	titleHeader: {
		color: 'var(--mainColor)',
		fontWeight: 600,
		fontSize: '18px',
		lineHeight: '24px',
	},
	subTitleHeader: {
		fontWeight: 500,
		fontSize: '16px',
		lineHeight: '24px',
		color: 'var(--fontColorHeaderSubTitle)',
	},
	depositsTitle: {
		fontWeight: 600,
		fontSize: '14px',
		lineHeight: '16px',
	},
	formFieldWrap: {
		border: '1px solid #DDE5EA',
		borderRadius: '10px',
		padding: '15px',
		marginTop: '20px',
	},
	gridWrap: {
		minWidth: '470px',
		display: 'flex',
		alignItems: 'flex-start',
	},
	formLabelText: {
		color: 'var(--formLabelColor)',
		fontWeight: 600,
	},
	errorText: {
		color: 'red',
	},
	whiteBtn: {
		backgroundColor: 'var(--white) !important',
		color: 'var(--mainColor) !important',
	},
	deleteWrap: {
		right: '100px',
	},
	fieldGridItem: {
		marginBottom: '20px',
	},
	selectField: {
		maxWidth: 220,
		minWidth: 220,
	},
});
// Font.register({
// 	family: 'Open Sans',
// 	fonts: [
// 		{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
// 		{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 },
// 	],
// });
// export const styles = StyleSheet.create({
// 	body: {
// 		paddingTop: 35,
// 		paddingBottom: 65,
// 		paddingHorizontal: 20,
// 		fontFamily: 'Open Sans',
// 	},
// 	pageNumber: {
// 		position: 'absolute',
// 		fontSize: 12,
// 		bottom: 30,
// 		left: 0,
// 		right: 0,
// 		textAlign: 'center',
// 		color: 'grey',
// 	},
// 	heading: {
// 		color: '#1976d2',
// 		fontSize: 13,
// 		textAlign: 'left',
// 		paddingHorizontal: 16,
// 	},
// 	reportBody: {
// 		color: '#445f6f',
// 		fontSize: 13,
// 		textAlign: 'left',
// 		paddingHorizontal: 16,
// 	},
// 	fullWidthRow: { display: 'flex', flexDirection: 'row', width: '100%' },
// 	fullWidthRowEnd: { display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' },
// 	fullWidthColumn: { display: 'flex', flexDirection: 'column', width: '100%' },
// 	logoContainer: {
// 		display: 'flex',
// 		flexDirection: 'column',
// 		width: '100%',
// 		alignItems: 'flex-start',
// 		flex: 0.5,
// 	},
// 	logo: {
// 		width: 40,
// 		height: 50,
// 	},
// 	pageTop: {
// 		marginBottom: 16,
// 		textAlign: 'center',
// 		display: 'flex',
// 		flexDirection: 'column',
// 		alignItems: 'center',
// 	},
// 	pageTopTitle: {
// 		color: '#1976d2',
// 		fontSize: 17,
// 		fontWeight: 600,
// 		marginBottom: 3,
// 		textAlign: 'center',
// 	},
// 	pageTopCategory: {
// 		color: '#445f6f',
// 		fontSize: 15,
// 		marginBottom: 5,
// 		textAlign: 'center',
// 	},
// 	pageTopCurrentDate: {
// 		display: 'flex',
// 		justifyContent: 'flex-end',
// 		flexDirection: 'row',
// 		marginBottom: 24,
// 		color: '#445f6f',
// 		fontSize: 13,
// 		fontWeight: 400,
// 	},
// 	pageTopSubtitle: {
// 		color: '#445f6f',
// 		fontSize: 13,
// 		fontWeight: 400,
// 		textAlign: 'center',
// 	},
// 	pageHeaders: {
// 		display: 'flex',
// 		flexDirection: 'row',
// 		width: '100%',
// 		marginBottom: 16,
// 	},
// 	totalSectionWrapper: {
// 		color: '#1976d2',
// 		display: 'flex',
// 		flexDirection: 'row',
// 		width: '100%',
// 	},
// 	externalBorder: {
// 		paddingTop: 8,
// 		paddingBottom: 2,
// 		borderTop: '1px solid #ABAEB0',
// 		borderBottom: '1px solid #ABAEB0',
// 	},
// 	internalBorder: {
// 		display: 'flex',
// 		flexDirection: 'row',
// 		width: '100%',
// 		borderBottom: '1px solid #ABAEB0',
// 	},
// 	groupTotal: { display: 'flex', flexDirection: 'row', paddingLeft: 120, marginLeft: 8 },
// 	groupTotalTitle: {
// 		color: '#445f6f',
// 		fontSize: 13,
// 		textTransform: 'capitalize',
// 		textAlign: 'left',
// 		fontWeight: 600,
// 	},
// });
