import * as React from 'react';
import { Stack, TablePagination, Tooltip, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { pagination } from 'Components/Common/Tables/Tables';
import { ColumnType, donorsTableHead } from 'Components/Common/Tables/TableHeaders';
import { convertDate } from 'helpers/dayAndTime';
import FormatCurrency from 'helpers/FormatCurrency';
import { Donations } from 'contexts/intialstates/Types';
import { sortTable } from 'Components/Common/Tables/sortTable';
import OrderTypeArrow from 'Components/Common/Tables/OrderTypeArrow';
import TableFiltering from 'Components/Reusable/TableFiltering/TableFiltering';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import CustomTable from 'Components/Common/Tables/CustomTable/CustomTable';

const DonationsTable = ({
	trimList,
	setTrimList,
	pageSize,
	setPageSize,
	page,
	setPage,
	label,
	orderType,
	setLabel,
	setOrderType,
	setOderBy,
	donations,
	setFilterPayload,
	filterPayload,
	reload,
	setReload,
}: any) => {
	const navigate = useNavigate();

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPageSize(+event.target.value);
		setPage(0);
	};

	const handleEditDonation = (row: Donations) => {
		navigate(`/bookkeeping/deposits/${row.depositId}`);
	};

	const getTableData = () => {
		return donations.data?.body?.length
			? donations.data.body.map((row: any, index: number) => {
					return {
						's/n': page * pageSize + index + 1,
						depositId: row?.depositId,
						receiptNum: row?.receiptNum || 'N/A',
						contribution: <FormatCurrency value={row?.contribution ? row?.contribution : 'N/A'} />,
						issueDate: row?.issueDate ? `${convertDate(row?.issueDate)}` : 'N/A',
						society: row?.society?.code ? row?.society?.code : 'N/A',
						organizationName: row?.organizationName ? row?.organizationName : 'N/A',
						creditDate: row?.deposit?.creditDate ? `${convertDate(row?.deposit?.creditDate)}` : 'N/A',
						created: row?.created ? `${convertDate(row?.created)}` : 'N/A',
						updated: row?.updated ? `${convertDate(row?.updated)}` : 'N/A',
						remark: row?.remark || 'N/A',
					};
			  })
			: [];
	};

	const getColumnData = () => {
		return trimList.map((column: ColumnType) => {
			return {
				...column,
				data: (
					<>
						<Tooltip
							key={column.id}
							title={column.id == 'actions' || column.id == 'remark' ? '' : `Sort by ${column.label}`}
						>
							<span
								style={{ cursor: 'pointer' }}
								onClick={() =>
									sortTable({
										column: column.id,
										setLabel,
										setOderBy,
										setOrderType,
										orderType,
										label,
									})
								}
							>
								{column.label}
								{column.id == label && <OrderTypeArrow orderType={orderType} />}
							</span>
						</Tooltip>
						<TableFiltering
							field={column.id}
							filterType={column.filterType}
							list={column.list}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							reload={reload}
							setIsReload={setReload}
						/>
					</>
				),
			};
		});
	};
	const handleDrag = (reOrderedData: ColumnType[]) => {
		setTrimList(reOrderedData);
	};

	const handleResize = (resizedData: ColumnType[]) => {
		setTrimList(resizedData);
	};

	return (
		<>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<CustomTable
					loading={donations?.loading}
					columnData={getColumnData()}
					tableData={getTableData()}
					handleDrag={handleDrag}
					handleResize={handleResize}
					emptyMessage={'No donations available'}
					handleRowClick={(row) => handleEditDonation(row as Donations)}
				/>
				{(donations.data?.body.length as number) > 0 && (
					<TablePagination
						rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
						count={donations.data?.totalRecords as number}
						rowsPerPage={pageSize}
						page={page}
						sx={pagination}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				)}
			</Paper>
		</>
	);
};

export default DonationsTable;
