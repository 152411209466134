import { useContext } from 'react';
import {
	ResponseError,
	StateType,
	Society,
	TimeStamp,
	TimeStampInfo,
	Donor,
	PaymentMethod,
	Appeal,
	DonationTypes,
	FilterPayload,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface DepositSlips {
	pageNumber: number;
	pageSize: number;
	totalPages: number;
	totalRecords: number;
	body: DepositSlipsData[];
}

export interface DepositSlipsStatus {
	id: number;
	status: string;
}

export interface DepositSlipsData {
	id: number;
	society: Society;
	totalAmount: number;
	bankDepositDate: string;
	status: string;
}

export interface GetDepositSlipProps {
	depositSlips: StateType<DepositSlips>;
	setDepositSlips: React.Dispatch<React.SetStateAction<StateType<DepositSlips>>>;
	page: number;
	pageSize: number;
	orderBy?: string;
	filterPayload?: FilterPayload[];
}

export interface GetDepositSlipStatus {
	depositSlipsStatus: StateType<DepositSlipsStatus[]>;
	setDepositSlipsStatus: React.Dispatch<React.SetStateAction<StateType<DepositSlipsStatus[]>>>;
}

export interface Organization {
	id: number;
	name: string;
}

interface GetOrganizationSearch {
	type: string;
	searchTerm: string;
	setOrganization: React.Dispatch<React.SetStateAction<Organization[]>>;
}

interface ContributionPayload {
	id?: number;
	donorId?: number;
	paymentMethodId?: number;
	amount: number;
	chequeNumber?: string;
	donationTypeId?: number;
	appealId?: number;
	isWalkathon: boolean;
	organizationType?: string;
	organizationId?: number;
	statusId?: number;
	remark?: string;
}

interface CreatePayload {
	id?: number;
	societyId?: number;
	bankDepositDate?: string;
	contributions: ContributionPayload[];
}

interface DepositSlipContribution {
	id: number;
	amount: number;
	chequeNumber: string;
	isWalkathon: boolean;
	organizationType: string;
	organizationName: string;
	organizationId: number;
	remark: string;
	donor: Donor;
	paymentMethod: PaymentMethod;
	appeal: Appeal;
	status: DepositSlipsStatus;
	donationType: DonationTypes;
}

export interface DepositSlip extends TimeStamp, TimeStampInfo {
	id: number;
	status: string;
	bankDepositDate: string;
	society: Society;
	contributions: DepositSlipContribution[];
}

interface GetDepositSlipByIdProps {
	depositSlip: StateType<DepositSlip>;
	setDepositSlip: React.Dispatch<React.SetStateAction<StateType<DepositSlip>>>;
	id: number;
}

const useDepositSlipActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = useContext(GlobalContexts);

	const getDepositSlips = async ({
		depositSlips,
		setDepositSlips,
		page,
		pageSize,
		orderBy,
		filterPayload,
	}: GetDepositSlipProps) => {
		try {
			setDepositSlips({
				...depositSlips,
				loading: true,
			});

			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;

			if (page) {
				response = await api.get(
					`depositSlip/list?PageNumber=${page + 1}&PageSize=${pageSize}${filter}${
						orderBy || '&SortBy=bankDepositDate%20desc'
					}`,
				);
			} else {
				response = await api.get(
					`depositSlip/list?&PageSize=${pageSize}${filter}${orderBy || '&SortBy=bankDepositDate%20desc'}`,
				);
			}

			setDepositSlips({
				...depositSlips,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || depositSlips.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDepositSlips({
					...depositSlips,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			alert('An error occurred');
		}
	};

	const getDepositSlipById = async ({ depositSlip, setDepositSlip, id }: GetDepositSlipByIdProps) => {
		try {
			setDepositSlip({
				...depositSlip,
				loading: true,
			});
			const response = await api.get(`depositSlip/${id}`);

			setDepositSlip({
				...depositSlip,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDepositSlip({
					...depositSlip,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			alert('An error occurred');
		}
	};

	const getDepositSlipsStatus = async ({
		depositSlipsStatus,
		setDepositSlipsStatus,
	}: GetDepositSlipStatus) => {
		try {
			setDepositSlipsStatus({
				...depositSlipsStatus,
				loading: true,
			});
			const response = await api.get(`depositSlipStatus/list`);

			setDepositSlipsStatus({
				...depositSlipsStatus,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDepositSlipsStatus({
					...depositSlipsStatus,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			alert('An error occurred');
		}
	};

	const createDepositSlip = async (payload: CreatePayload): Promise<boolean | number> => {
		try {
			const createDeposit = await api.post(`/depositSlip/create`, { ...payload });
			if (createDeposit?.data?.result) {
				setSimpleErrorSnack({
					message: 'Deposit slip created successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const updateDepositSlip = async (payload: CreatePayload, id: number): Promise<boolean | number> => {
		try {
			const updateDeposit = await api.put(`/depositSlip/${id}/update`, { ...payload });
			if (updateDeposit?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Deposit slip updated successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const getOrganizationSearch = async ({ type, searchTerm, setOrganization }: GetOrganizationSearch) => {
		let url = '';
		if (type === 'Board') url = `schoolboard/search?query=${searchTerm}`;
		else if (type === 'School') url = `school/search?schoolQuery=${searchTerm}`;
		else if (type === 'Diocese') url = `diocese/search?dioceseQuery=${searchTerm}`;
		else if (type === 'Parish') url = `parish/search?parishQuery=${searchTerm}`;
		const response = await api.get(url);
		if (response?.data?.result) {
			const result = response?.data?.result.map((item: any) => {
				return {
					id: item.id,
					name:
						type === 'Board' || type === 'School'
							? item.name
							: type === 'Diocese'
							? item.dioceseName
							: item.parishName,
				};
			});
			setOrganization(result);
		}
	};

	const deleteDepositSlip = async (id: number): Promise<boolean> => {
		try {
			const deleteDepositSlip = await api.remove(`/depositSlip/${id}/archive`);
			if (deleteDepositSlip?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Deposit slip deleted succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const postDepositSlip = async (id: number): Promise<boolean> => {
		try {
			const postedDepositSlip = await api.post(`/depositSlip/${id}/post`);
			if (postedDepositSlip?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Deposit slip posted succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const printDepositSlip = async (id: number) => {
		try {
			const response = await api.get(`depositSlip/${id}/slip`, {
				responseType: 'blob',
			});
			const url = window.URL.createObjectURL(new Blob([response?.data], { type: 'application/pdf' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'deposit-slip.pdf');
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
		}
	};

	return {
		getDepositSlips,
		getDepositSlipsStatus,
		createDepositSlip,
		getOrganizationSearch,
		getDepositSlipById,
		updateDepositSlip,
		deleteDepositSlip,
		postDepositSlip,
		printDepositSlip,
	};
};

export default useDepositSlipActions;
