import React from 'react';
import {
	ResponseError,
	PostDatedCheques,
	PostDatedChequesData,
	StateType,
	FilterPayload,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { PostDatedChequesValues } from 'Components/Bookkeeping/PostDatedCheques/createEdit';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface GetPostDatedChequesTypes {
	setPostDatedCheques: React.Dispatch<React.SetStateAction<StateType<PostDatedChequesData>>>;
	postDatedCheques: StateType<PostDatedChequesData>;
	page: number;
	pageSize: number;
	orderBy?: string;
	donorQuery?: string;
	filterPayload?: FilterPayload[];
}

export interface GetPostDatedChequeByIdTypes {
	setPostDatedChequeById: React.Dispatch<React.SetStateAction<StateType<PostDatedCheques>>>;
	postDatedChequeById: StateType<PostDatedCheques>;
	id: number;
}
export interface DepositPostDatedChequeTypes {
	setDepositPostDatedCheque: React.Dispatch<React.SetStateAction<StateType<PostDatedCheques>>>;
	depositPostDatedCheque: StateType<PostDatedCheques>;
	id: number;
}
interface PostDatedChequeNotificationType {
	setNotify: React.Dispatch<React.SetStateAction<boolean>>;
	notify: boolean;
}

const useHcaOrdersActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const getPostDatedChequeByID = async ({
		setPostDatedChequeById,
		postDatedChequeById,
		id,
	}: GetPostDatedChequeByIdTypes) => {
		try {
			setPostDatedChequeById({
				...postDatedChequeById,
				loading: true,
				error: null,
			});
			const response = await api.get(`postDatedCheque/${id}`);
			setPostDatedChequeById({
				...postDatedChequeById,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setPostDatedChequeById({
					...postDatedChequeById,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const depositPostDatedChequeByID = async ({
		setDepositPostDatedCheque,
		depositPostDatedCheque,
		id,
	}: DepositPostDatedChequeTypes) => {
		try {
			setDepositPostDatedCheque({
				...depositPostDatedCheque,
				loading: true,
				error: null,
			});
			const response = await api.post(`postDatedCheque/${id}/deposit`, {});
			setDepositPostDatedCheque({
				...depositPostDatedCheque,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setDepositPostDatedCheque({
					...depositPostDatedCheque,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const getPostDatedCheques = async ({
		setPostDatedCheques,
		postDatedCheques,
		page,
		pageSize,
		orderBy,
		filterPayload,
		donorQuery,
	}: GetPostDatedChequesTypes) => {
		try {
			setPostDatedCheques({
				...postDatedCheques,
				loading: true,
				error: null,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`/postDatedCheque/list?PageNumber=${page + 1}&PageSize=${pageSize}${
						donorQuery ? '&donorQuery=' + donorQuery : ''
					}${filter}${orderBy || '&SortBy=dueDate%20desc'}`,
				);
			} else {
				response = await api.get(
					`/postDatedCheque/list?&PageSize=${pageSize}${
						donorQuery ? '&donorQuery=' + donorQuery : ''
					}${filter}${orderBy || '&SortBy=dueDate%20desc'}`,
				);
			}

			setPostDatedCheques({
				...postDatedCheques,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || postDatedCheques.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setPostDatedCheques({
					...postDatedCheques,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const createPostDatedCheque = async (
		payload: PostDatedChequesValues,
	): Promise<PostDatedCheques | boolean> => {
		try {
			const response = await api.post(`postDatedCheque/create`, {
				...payload,
			});
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Cheque created succesfully',
					severity: 'success',
					show: true,
				});
				return response?.data?.result;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const updatePostDatedCheque = async (payload: PostDatedChequesValues, id: number): Promise<boolean> => {
		try {
			const response = await api.put(`postDatedCheque/${id}/update`, {
				...payload,
				id,
			});
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Cheque updated succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deletePostDatedCheque = async (id: number): Promise<boolean> => {
		try {
			const response = await api.remove(`postDatedCheque/${id}/archive`);
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Cheque deleted successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};
	const postDatedChequeNotification = async ({ notify, setNotify }: PostDatedChequeNotificationType) => {
		try {
			const response = await api.get(`postDatedCheque/pendingRecordExists`);
			if (response.data?.result === notify) {
				return;
			}
			setNotify(response.data?.result);
		} catch (error) {
			return false;
		}
	};

	return {
		getPostDatedChequeByID,
		getPostDatedCheques,
		createPostDatedCheque,
		updatePostDatedCheque,
		deletePostDatedCheque,
		depositPostDatedChequeByID,
		postDatedChequeNotification,
	};
};

export default useHcaOrdersActions;
