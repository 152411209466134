import { useContext } from 'react';
import {
	ResponseError,
	StateType,
	TimeStamp,
	FilterPayload,
	TimeStampInfoType,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface LifeExpectancy {
	pageNumber: number;
	pageSize: number;
	totalPages: number;
	totalRecords: number;
	body: LifeExpectancyData[];
}

export interface GetLifeExpectancyProps {
	lifeExpectancy: StateType<LifeExpectancy>;
	setLifeExpectancy: React.Dispatch<React.SetStateAction<StateType<LifeExpectancy>>>;
	page: number;
	pageSize: number;
	orderBy?: string;
	filterPayload?: FilterPayload[];
}

export interface LifeExpectancyData extends TimeStamp, TimeStampInfoType {
	id: number;
	dateFrom: string;
	dateTo: string;
	adjRate: number;
	adjMale: number;
	adjFemale: number;
	isCurrent: boolean;
	unisex: boolean;
	source: string;
	archived: boolean;
}

interface CreatePayload {
	dateFrom?: string;
	dateTo?: string;
	adjRate?: number;
	adjMale?: number;
	adjFemale?: number;
	unisex?: boolean;
	isCurrent?: boolean;
}

const useLifeExpectancy = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = useContext(GlobalContexts);

	const getLifeExpectancyAdjustment = async ({
		lifeExpectancy,
		setLifeExpectancy,
		page,
		pageSize,
		orderBy,
		filterPayload,
	}: GetLifeExpectancyProps) => {
		try {
			setLifeExpectancy({
				...lifeExpectancy,
				loading: true,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`lifeexpectancyadjustment/list?PageNumber=${page + 1}&PageSize=${pageSize}${
						orderBy || '&SortBy=updated%20desc'
					}${filter}`,
				);
			} else {
				response = await api.get(
					`lifeexpectancyadjustment/list?&PageSize=${pageSize}${
						orderBy || '&SortBy=updated%20desc'
					}${filter}`,
				);
			}

			setLifeExpectancy({
				...lifeExpectancy,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || lifeExpectancy.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setLifeExpectancy({
					...lifeExpectancy,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
			}
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
		}
	};

	const createLifeExpectancyAdjustment = async (payload: CreatePayload): Promise<boolean> => {
		try {
			const createdData = await api.post(`lifeexpectancyadjustment/create`, {
				...payload,
			});
			if (createdData?.data?.result) {
				setSimpleErrorSnack({
					message: 'Life expectancy adjustment created successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const updateLifeExpectancyAdjustment = async (payload: CreatePayload, id: number): Promise<boolean> => {
		try {
			const updatedData = await api.put(`lifeexpectancyadjustment/${id}/update`, {
				...payload,
				id,
			});
			if (updatedData?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Life expectancy adjustment updated successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deleteLifeExpectancyAdjustment = async (id: number): Promise<boolean> => {
		try {
			const deletedData = await api.remove(`lifeexpectancyadjustment/${id}/archive`);
			if (deletedData?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Life expectancy adjustment deleted successfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	return {
		getLifeExpectancyAdjustment,
		createLifeExpectancyAdjustment,
		updateLifeExpectancyAdjustment,
		deleteLifeExpectancyAdjustment,
	};
};

export default useLifeExpectancy;
