import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { Grid, Stack, Typography, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormTextField } from 'Components/Common/TextField';
import { useGlobalStyles } from 'Components/Common/global/global';
import { ConfirmationNumber, Close, NotesOutlined, AddTask } from '@mui/icons-material';
import * as yup from 'yup';
import useGiftTypeActions from 'hooks/useGiftTypeActions';
import useUserActions from 'hooks/useUserActions';

export interface GiftTypeValues {
	id?: number;
	type: string;
	description: string;
}

interface P {
	selectedEditData?: GiftTypeValues;
	handleClose: () => void;
	reload?: boolean;
	setReload?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateEditGiftType = ({ selectedEditData, handleClose, reload, setReload }: P) => {
	const { handleRoles } = useUserActions();
	const global = useGlobalStyles();
	const { createGiftType, updateGiftType } = useGiftTypeActions();
	const [initial, setInitial] = useState<GiftTypeValues>({
		type: '',
		description: '',
	});
	const [saveLoading, setSaveLoading] = useState(false);

	useEffect(() => {
		if (!selectedEditData) {
			setInitial({
				type: '',
				description: '',
			});
		} else {
			const { type, description } = selectedEditData;
			setInitial({
				type,
				description,
			});
		}
	}, [selectedEditData]);

	const validationHCAMaterials = yup.object().shape({
		type: yup.string().required('Name is required'),
	});

	const handleSubmit = async (payload: GiftTypeValues) => {
		setSaveLoading(true);
		let isSucess;
		if (selectedEditData?.id) {
			isSucess = await updateGiftType(payload, selectedEditData.id);
		} else {
			isSucess = await createGiftType(payload);
		}
		setSaveLoading(false);
		handleClose();
		if (isSucess) {
			setReload?.(!reload);
		}
	};

	return (
		<Formik
			enableReinitialize
			onSubmit={handleSubmit}
			validationSchema={validationHCAMaterials}
			initialValues={initial}
		>
			{() => (
				<Form noValidate autoComplete="off">
					<Stack direction="column" py={2}>
						<Stack sx={{ mb: 2 }}>
							<Grid container>
								<Grid item>
									<ConfirmationNumber className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Name
									</Typography>
								</Grid>
							</Grid>
							<Field
								fullWidth
								name="type"
								size="small"
								variant="outlined"
								placeholder="Name"
								component={FormTextField}
							/>
						</Stack>
						<Stack sx={{ mb: 2 }}>
							<Grid container>
								<Grid item>
									<NotesOutlined className={global.formLabelIcon} />
								</Grid>
								<Grid item>
									<Typography variant="body2" className={global.formLabelText}>
										Description
									</Typography>
								</Grid>
							</Grid>
							<Field
								fullWidth
								name="description"
								size="small"
								variant="outlined"
								placeholder="Description"
								multiline
								rows={3}
								component={FormTextField}
							/>
						</Stack>
					</Stack>
					<Stack direction="row" justifyContent="center" py={3} spacing={2}>
						{handleRoles('admin-actions') && (
							<LoadingButton
								loadingPosition="start"
								loading={saveLoading}
								startIcon={<AddTask />}
								sx={{ textTransform: 'none' }}
								type="submit"
								variant="contained"
							>
								Save
							</LoadingButton>
						)}

						<Button
							sx={{ textTransform: 'none' }}
							startIcon={<Close />}
							onClick={handleClose}
							variant="outlined"
							className={global.whiteBtn}
						>
							Cancel
						</Button>
					</Stack>
				</Form>
			)}
		</Formik>
	);
};

export default CreateEditGiftType;
