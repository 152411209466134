import React, { useState, useEffect } from 'react';
import { Stack, Button } from '@mui/material';
import { Edit } from '@mui/icons-material';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import AnnuitiesTable from 'Components/Donors/DonorInfo/Annuities/DonorAnnuitiesTable';
import { StateType, Annuitant, FilterPayload } from 'contexts/intialstates/Types';
import useAnnuitantActions from 'hooks/useAnnuitantActions';
import UpdateAnnuitantDetail from './UpdateAnnuitantModal';
import useUserActions from 'hooks/useUserActions';
import DeathBenefit from 'Components/Reports/ExtraReports/DeathBenefit';
import { DeathBenefitReport } from 'hooks/useReportAction';
import { GlobalContexts } from 'contexts/GlobalContext';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';

export const annuitiesHead: ColumnType[] = [
	{
		id: 's/n',
		label: 'S/N',
		minWidth: 70,
		align: 'center',
	},
	{
		id: 'id',
		label: 'Agreement ID',
		minWidth: 220,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'role',
		label: 'Role',
		minWidth: 220,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'receivedAmount',
		label: 'Received Amount',
		minWidth: 220,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'aggrementType',
		label: 'Agreement Type',
		minWidth: 220,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'status',
		label: 'Status',
		minWidth: 220,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'createdBy',
		label: 'Created By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'updatedBy',
		label: 'Updated By',
		minWidth: 170,
		align: 'center',
		filterType: 'text',
	},
	{
		id: 'created',
		label: 'Date Created',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
	{
		id: 'updated',
		label: 'Date Updated',
		minWidth: 170,
		align: 'center',
		filterType: 'date',
	},
];

interface P {
	donorId: number;
	annuitantId?: number;
}

const Annuities = ({ donorId, annuitantId }: P) => {
	const { handleRoles } = useUserActions();
	const { getAnnuitantById } = useAnnuitantActions();
	const [filterPayload, setFilterPayload] = React.useState<Array<FilterPayload>>([]);

	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [annuitant, setAnnuitant] = useState<StateType<Annuitant>>({
		data: null,
		loading: false,
		error: null,
	});
	const [page, setPage] = useState(0);

	const [openUpdateAnnuitant, setOpenUpdateAnnuitant] = useState(false);
	const [reload, setReload] = useState(false);
	const [deathBenefit, setDeathBenefit] = useState<StateType<DeathBenefitReport>>({
		data: null,
		loading: false,
		error: null,
	});
	useEffect(() => {
		if (!annuitantId) return;
		getAnnuitantById({ annuitant, setAnnuitant, annuitantId });
	}, [reload]);

	const handleToggle = () => {
		setOpenUpdateAnnuitant(!openUpdateAnnuitant);
	};

	const { donorAnn } = React.useContext(GlobalContexts);

	return (
		<Stack>
			<Stack direction={'row'} justifyContent={'flex-end'} mb={3} alignItems="center" spacing={1}>
				<TrimTables
					name={'annuitiesDonorHead'}
					list={annuitiesHead}
					trimList={trimList}
					setTrimList={setTrimList}
				/>

				{handleRoles('donor-anuities') && (
					<>
						<Button
							variant="contained"
							size="small"
							startIcon={<Edit />}
							disabled={!annuitantId}
							onClick={handleToggle}
						>
							Update Annuitant Details
						</Button>
						<DeathBenefit
							disabled={!annuitantId || !annuitant?.data?.donor?.dateOfDeath || !donorAnn.data?.body}
							id={donorId as number}
							deathBenefit={deathBenefit}
							setDeathBenefit={setDeathBenefit}
						/>
					</>
				)}
				<FilterListButton
					page={page}
					setPage={setPage}
					reload={reload}
					setIsReload={setReload}
					setFilterPayload={setFilterPayload}
					filterPayload={filterPayload}
				/>
			</Stack>
			<AnnuitiesTable
				reload={reload}
				setReload={setReload}
				setFilterPayload={setFilterPayload}
				filterPayload={filterPayload}
				page={page}
				setPage={setPage}
				trimList={trimList}
				setTrimList={setTrimList}
				donorId={donorId}
			/>
			{openUpdateAnnuitant && annuitantId && (
				<UpdateAnnuitantDetail
					visible={openUpdateAnnuitant}
					handleClose={handleToggle}
					annuitantId={annuitantId}
					annuitant={annuitant}
					reload={reload}
					setReload={setReload}
				/>
			)}
		</Stack>
	);
};

export default Annuities;
