import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { Button, Stack, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

import OrderList from './OrdersList';
import { hcaOrdersHead, ColumnType } from 'Components/Common/Tables/TableHeaders';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';

const Orders = () => {
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const navigate = useNavigate();
	const [reload, setReload] = React.useState(false);
	const [filterPayload, setFilterPayload] = React.useState<Array<any>>([]);
	const [page, setPage] = React.useState(0);
	return (
		<>
			<Box>
				<Stack
					alignItems="center"
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent={'space-between'}
					spacing={1}
					mb={5}
				>
					<Stack direction="column">
						<Typography variant="h6" sx={{ color: 'var(--mainColor)' }}>
							HCA Orders
						</Typography>
						<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
							Manage and Add HCA Material Orders by School Boards/ Schools/ Parishes/ Diocese{' '}
						</Typography>
					</Stack>
					<Stack direction={'row'} justifyContent={'center'} spacing={{ xs: 1, md: 2 }} alignItems="center">
						<TrimTables
							name="hcaOrdersHead"
							list={hcaOrdersHead}
							trimList={trimList}
							setTrimList={setTrimList}
						/>
						<Button
							sx={{ textTransform: 'capitalize' }}
							variant="contained"
							disableElevation
							startIcon={<Add />}
							onClick={() => navigate('/hca/create-edit-hca-orders')}
						>
							Add Order
						</Button>
						<FilterListButton
							reload={reload}
							setIsReload={setReload}
							setPage={setPage}
							page={page}
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
						/>
					</Stack>
				</Stack>
				<Stack direction="row">
					<OrderList
						trimList={trimList}
						setTrimList={setTrimList}
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
						reload={reload}
						setReload={setReload}
						setPage={setPage}
						page={page}
					/>
				</Stack>
			</Box>
		</>
	);
};

export default Orders;
