import {
	TEMPLATE_DEPOSITS_LOADING,
	TEMPLATE_DEPOSITS_LOAD_ERROR,
	TEMPLATE_DEPOSITS_LOAD_SUCCESS,
	DELETE_TEMPLATE_DEPOSIT_LOADING,
	DELETE_TEMPLATE_DEPOSITS_LOAD_ERROR,
	DELETE_TEMPLATE_DEPOSITS_LOAD_SUCCESS,
	UPDATE_TEMPLATE_DEPOSITS_LOAD_ERROR,
	UPDATE_TEMPLATE_DEPOSITS_LOAD_SUCCESS,
	UPDATE_TEMPLATE_DEPOSIT_LOADING,
	TEMPLATE_DEPOSIT_LOADING,
	TEMPLATE_DEPOSIT_LOAD_SUCCESS,
	TEMPLATE_DEPOSIT_LOAD_ERROR,
} from 'contexts/actionTypes'
import { 
	TemplateDepositType,
	ResponseError,
	TemplateDepositsData,
	Action,
	UpdateDeletePayload,
	TemplateDepositDeleteType, 
	TemplateDepositUpdateType,
	TemplateDepositByIdType,
	TemplateDeposits,
} from 'contexts/intialstates/Types'

export const templateDeposits = (
	state: TemplateDepositType, 
	{ payload, type }: Action<TemplateDepositsData>
): TemplateDepositType => {
	switch (type) {
		case TEMPLATE_DEPOSITS_LOADING: {
			return {
				...state,
				loading: true,
				error: null,
			}
		}
		case TEMPLATE_DEPOSITS_LOAD_SUCCESS: {
			return {
				...state,
				loading: false,
				error: null,
				data: payload as TemplateDepositsData,	
			}
		}
		case TEMPLATE_DEPOSITS_LOAD_ERROR: {
			return {
				...state,
				loading: false,
				error: payload as ResponseError,
			}
		}
		default:
			return state;
	}
};

export const templateDeposit = (
	state: TemplateDepositByIdType, 
	{ payload, type }: Action<TemplateDeposits>
): TemplateDepositByIdType => {
	switch (type) {
		case TEMPLATE_DEPOSIT_LOADING: {
			return {
				...state,
				loading: true,
				error: null,
			}
		}
		case TEMPLATE_DEPOSIT_LOAD_SUCCESS: {
			return {
				...state,
				loading: false,
				error: null,
				data: payload as TemplateDeposits,	
			}
		}
		case TEMPLATE_DEPOSIT_LOAD_ERROR: {
			return {
				...state,
				loading: false,
				error: payload as ResponseError,
			}
		}
		default:
			return state;
	}
};

export const deleteTemplateDeposit = (
	state: TemplateDepositDeleteType, 
	{ payload, type }: Action<UpdateDeletePayload>
): TemplateDepositDeleteType => {
	switch (type) {
		case DELETE_TEMPLATE_DEPOSIT_LOADING: {
			return {
				...state,
				loading: true,
				error: null,
			}
		}
		case DELETE_TEMPLATE_DEPOSITS_LOAD_SUCCESS: {
			return {
				...state,
				loading: false,
				error: null,
				data: payload as UpdateDeletePayload,	
			}
		}
		case DELETE_TEMPLATE_DEPOSITS_LOAD_ERROR: {
			return {
				...state,
				loading: false,
				error: payload as ResponseError,
			}
		}
		default:
			return state;
	}
};

export const updateTemplateDeposit = (
	state: TemplateDepositUpdateType, 
	{ payload, type }: Action<UpdateDeletePayload>
): TemplateDepositUpdateType => {
	switch (type) {
		case UPDATE_TEMPLATE_DEPOSIT_LOADING: {
			return {
				...state,
				loading: true,
				error: null,
			}
		}
		case UPDATE_TEMPLATE_DEPOSITS_LOAD_SUCCESS: {
			return {
				...state,
				loading: false,
				error: null,
				data: payload as UpdateDeletePayload,	
			}
		}
		case UPDATE_TEMPLATE_DEPOSITS_LOAD_ERROR: {
			return {
				...state,
				loading: false,
				error: payload as ResponseError,
			}
		}
		default:
			return state;
	}
};