import React, { FC, createContext } from 'react';

interface DonorsContextType {
	donorsMenu: boolean;
	setDonorsMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DonorsContext = createContext<DonorsContextType>({} as any);

export const DonorsContextsProvider: FC = ({ children }) => {
	const [donorsMenu, setDonorsMenu] = React.useState<boolean>(true);

	const contextValue = {
		donorsMenu,
		setDonorsMenu,
	};

	return <DonorsContext.Provider value={contextValue}>{children}</DonorsContext.Provider>;
};
