import React, { useEffect, useState } from 'react';
import { Stack, Grid, Select, MenuItem, Typography, TextField, Button } from '@mui/material';
import { MiniCard } from '../Card';
import { useGlobalStyles } from 'Components/Common/global/global';
import { Donor, StateType, Province, PaymentMethod, Society } from 'contexts/intialstates/Types';
import { Search, AddTask, Clear } from '@mui/icons-material';
import DonorSearch from '../DonorReports/DonorSearch';
import LoadingButton from '@mui/lab/LoadingButton';
import useSocietyActions from 'hooks/useSocietyAction';
import usePaymentmethodsActions from 'hooks/usePaymentMethodAction';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import useAnnuitiesActions, {
	AgreementStatus,
	AgreementType,
	PaymentFrequency,
} from 'hooks/useAnnuitiesActions';
import useProvinceActions from 'hooks/useProvinceActions';

export interface FormValues {
	start: string;
	end: string;
	agreementType: string[];
	frequency: string[];
	paymentMethod: string[];
	agreementStatus: string[];
	receivedMinAmount?: string;
	receivedMaxAmount?: string;
	overpaymentMinAmount?: string;
	overpaymentMaxAmount?: string;
	provinceOfRegistration: string[];
	society: string[];
	donors: Donor[];
}

interface P {
	handleGetReport: (values: FormValues, isGenerate?: boolean) => void;
	loading: boolean;
	onCancel: () => void;
	isEdit: boolean;
	isShowFilter: boolean;
	setFormData: React.Dispatch<React.SetStateAction<FormValues>>;
	formData: FormValues;
}

const AnnuitiesForm = ({
	handleGetReport,
	loading,
	onCancel,
	isEdit,
	isShowFilter,
	formData,
	setFormData,
}: P) => {
	const [allSociety, setAllSoceity] = React.useState<StateType<Society[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [allPM, setPM] = React.useState<StateType<PaymentMethod[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const global = useGlobalStyles();
	const { getPaymentMethods } = usePaymentmethodsActions();
	const { getSocieties } = useSocietyActions();
	const { getAgreementTypes, getPaymentFrequency, getAgreementStatus } = useAnnuitiesActions();
	const { getProvince } = useProvinceActions();
	const [visibleSearch, setVisibleSearch] = useState(false);
	const [agreementType, setAgreementType] = useState<StateType<AgreementType[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [paymentFrequency, setPaymentFrequency] = useState<StateType<PaymentFrequency[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [agreementStatus, setAgreementStatus] = useState<StateType<AgreementStatus[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [province, setProvince] = useState<StateType<Province[]>>({
		data: null,
		loading: false,
		error: null,
	});
	const [formErrors, setFormErrors] = useState<string[]>([]);

	useEffect(() => {
		getPaymentMethods({ allPM, setPM });
		getSocieties({ allSociety, setAllSoceity });
		getAgreementTypes({ agreementType, setAgreementType });
		getPaymentFrequency({ paymentFrequency, setPaymentFrequency });
		getAgreementStatus({ agreementStatus, setAgreementStatus });
		getProvince({ province, setProvince });
	}, []);

	const handleVisibleSearch = () => {
		setVisibleSearch(!visibleSearch);
	};

	const handleAddDonor = (donors: Donor[]) => {
		setFormData({
			...formData,
			donors,
		});
		handleVisibleSearch();
	};

	const handleSelectChange = (field: string, value: string | string[]) => {
		setFormData({
			...formData,
			[field]: typeof value === 'string' ? value.split(',') : value,
		});
	};

	const handleChange = (field: string, value: string) => {
		setFormData({
			...formData,
			[field]: value,
		});
		if (formErrors.includes(field)) {
			setFormErrors(formErrors.filter((item) => item !== field));
		}
	};

	const handleGenerate = async (isGenerate?: boolean) => {
		const errors = [];
		if (!formData.start) {
			errors.push('start');
		}
		if (!formData.end) {
			errors.push('end');
		}
		setFormErrors(errors);
		if (errors.length) return;

		await handleGetReport(formData, isGenerate);
	};

	const handleCancel = () => {
		setFormData({
			start: '',
			end: '',
			receivedMinAmount: '',
			receivedMaxAmount: '',
			agreementStatus: [],
			agreementType: [],
			frequency: [],
			paymentMethod: [],
			provinceOfRegistration: [],
			society: [],
			overpaymentMaxAmount: '',
			overpaymentMinAmount: '',
			donors: [],
		});
		onCancel();
	};

	return (
		<Stack>
			{isShowFilter && (
				<MiniCard title="Filters">
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
						<Grid item xs={12} md={4} sm={6} mb={3}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Date Range
							</Typography>
							<Stack direction="row" pr={{ lg: 5, md: 0 }}>
								<Stack>
									<BasicDatePicker
										size="small"
										value={formData.start}
										setValue={(e) => handleChange('start', e.target.value)}
									/>
									{formErrors.includes('start') && (
										<span className={global.errorText}>Start date is required</span>
									)}
								</Stack>
								<Stack m={1}>To</Stack>
								<Stack>
									<BasicDatePicker
										size="small"
										value={formData.end}
										setValue={(e) => handleChange('end', e.target.value)}
									/>
									{formErrors.includes('end') && (
										<span className={global.errorText}>End date is required</span>
									)}
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Agreement Type
							</Typography>
							<Select
								size="small"
								fullWidth
								onChange={(e) => {
									handleSelectChange('agreementType', e.target.value);
								}}
								multiple
								value={formData.agreementType}
							>
								{agreementType?.data?.map((x) => (
									<MenuItem key={x.id} value={x.id}>
										{x.type}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Frequency
							</Typography>
							<Select
								size="small"
								fullWidth
								multiple
								onChange={(e) => {
									handleSelectChange('frequency', e.target.value);
								}}
								value={formData.frequency}
							>
								{paymentFrequency?.data?.map((x) => (
									<MenuItem key={x.id} value={x.id}>
										{x.frequency}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Payment Method
							</Typography>
							<Select
								size="small"
								fullWidth
								multiple
								onChange={(e) => {
									handleSelectChange('paymentMethod', e.target.value);
								}}
								value={formData.paymentMethod}
							>
								{allPM?.data
									?.filter((method) => method.name === 'Direct Debit' || method.name === 'Cheque')
									.map((x: { id: number; name: string }) => (
										<MenuItem key={x.id} value={x.id}>
											{x.name}
										</MenuItem>
									))}
							</Select>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Agreement Status
							</Typography>
							<Select
								size="small"
								fullWidth
								onChange={(e) => {
									handleSelectChange('agreementStatus', e.target.value);
								}}
								multiple
								value={formData.agreementStatus}
							>
								{agreementStatus?.data?.map((x) => (
									<MenuItem key={x.id} value={x.id}>
										{x.status}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Received Amount
							</Typography>
							<Stack direction="row" alignItems="center">
								<Stack>
									<TextField
										size={'small'}
										fullWidth
										placeholder={'Min'}
										type="number"
										value={formData.receivedMinAmount}
										onChange={(e) => handleChange('receivedMinAmount', e.target.value)}
									/>
									{formErrors.includes('receivedMinAmount') && (
										<span className={global.errorText}>Received min amount is required</span>
									)}
								</Stack>
								<Stack sx={{ margin: 1 }}>-</Stack>
								<Stack>
									<TextField
										size={'small'}
										fullWidth
										placeholder={'Max'}
										type="number"
										value={formData.receivedMaxAmount}
										onChange={(e) => handleChange('receivedMaxAmount', e.target.value)}
									/>
									{formErrors.includes('receivedMaxAmount') && (
										<span className={global.errorText}>Received max amount is required</span>
									)}
								</Stack>
							</Stack>
						</Grid>

						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Overpayment Amount
							</Typography>
							<Stack direction="row" alignItems="center">
								<Stack>
									<TextField
										size={'small'}
										fullWidth
										placeholder={'Min'}
										value={formData.overpaymentMinAmount}
										type="number"
										onChange={(e) => handleChange('overpaymentMinAmount', e.target.value)}
									/>
								</Stack>
								<Stack sx={{ margin: 1 }}>-</Stack>
								<Stack>
									<TextField
										size={'small'}
										fullWidth
										placeholder={'Max'}
										value={formData.overpaymentMaxAmount}
										type="number"
										onChange={(e) => handleChange('overpaymentMaxAmount', e.target.value)}
									/>
								</Stack>
							</Stack>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Province of Registration
							</Typography>
							<Select
								size="small"
								fullWidth
								onChange={(e) => {
									handleSelectChange('provinceOfRegistration', e.target.value);
								}}
								multiple
								value={formData.provinceOfRegistration}
							>
								{province?.data?.map((x) => (
									<MenuItem key={x.id} value={x.id}>
										{x.provinceName}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Beneficiary Society
							</Typography>
							<Select
								size="small"
								fullWidth
								onChange={(e) => {
									handleSelectChange('society', e.target.value);
								}}
								multiple
								value={formData.society}
							>
								{allSociety?.data
									?.filter((curr) => curr.code !== 'AN')
									?.map((x: { id: number; code: string }) => (
										<MenuItem key={x.id} value={x.id}>
											{x.code}
										</MenuItem>
									))}
							</Select>
						</Grid>
						<Grid item xs={12} md={4} sm={6} mb={3} pr={{ lg: 5, md: 0 }}>
							<Typography variant="body2" className={global.formLabelText} mb={1}>
								Donor
							</Typography>
							<Stack direction="row" alignItems={'center'}>
								<TextField
									size={'small'}
									value={formData.donors
										.map((item) => {
											return `${item.firstName} ${item.lastName}`;
										})
										.join(', ')}
									placeholder={'Search donors'}
									disabled
									fullWidth
								/>
								<Stack>
									<Button
										onClick={() => handleVisibleSearch()}
										startIcon={<Search />}
										variant="outlined"
										size={'small'}
										className={global.whiteBtn}
										sx={{ margin: '5px' }}
									>
										Search
									</Button>
								</Stack>
							</Stack>
						</Grid>
						{isEdit && (
							<Grid item xs={12} md={4} sm={6} mb={3}>
								<Stack direction="row" spacing={2} height="100%" alignItems="flex-end" p={1}>
									<LoadingButton
										startIcon={<AddTask />}
										variant="contained"
										onClick={() => handleGenerate()}
										type="submit"
										loading={loading}
										loadingPosition="start"
										size="small"
									>
										Apply
									</LoadingButton>

									<Button
										startIcon={<Clear />}
										variant="outlined"
										className={global.whiteBtn}
										onClick={handleCancel}
										size="small"
									>
										Cancel
									</Button>
								</Stack>
							</Grid>
						)}
					</Grid>
				</MiniCard>
			)}
			{!isEdit && (
				<Stack m={3} direction="row">
					<LoadingButton
						startIcon={<AddTask />}
						variant="contained"
						onClick={() => handleGenerate(true)}
						loading={loading}
						loadingPosition="start"
						size="small"
					>
						Generate Report
					</LoadingButton>
				</Stack>
			)}
			{visibleSearch && (
				<DonorSearch
					handleVisibleSearch={handleVisibleSearch}
					visibleSearch={visibleSearch}
					handleAdd={handleAddDonor}
					isMultiSelect
				/>
			)}
		</Stack>
	);
};

export default AnnuitiesForm;
