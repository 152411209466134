import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            color: 'var(--mainColor)',
            fontSize: '32px',
            fontWeight: 700,
        },
        subTitle: {
            border: '1px solid var(--mainColor)',
            borderRadius: '4px',
            padding: '5px',
            background: 'var(--white)',
            color: 'var(--mainColor)',
            fontSize: '12px',
        },
        backIconWrapper: {
            background: 'var(--white)',
            borderRadius: '4px',
            padding: '5px',
            color: 'var(--mainColor)',
            cursor: 'pointer'
        },
        paperWrap: {
            padding: '20px 0 20px 40px',
            [theme.breakpoints.down('md')]: {
                padding: '20px',
            },
        },
        headerWrap: {
            background: 'var(--mainBackground)',
            borderRadius: '10px',
            padding: '13px',
            overflow: 'auto',
        },
        headerContent: {
            borderRight: '2px solid var(--white)',
        },
        titleHeader: {
            color: 'var(--mainColor)',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            textAlign: 'center'
        },
        subTitleHeader: {
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '24px',
            color: 'var(--fontColorHeaderSubTitle)',
        },
        disabledIcon: {
            margin: '0 12px',
            border: '1px solid var(--disabled)',
            borderRadius: '6px',
            padding: '3px 10px',
            display: 'flex',
            background: 'var(--white)',
            '& svg': {
                color: 'var(--disabled)',
            },
        },
        disabledInput: {
            background: 'var(--invalidBackground)'
        },
        IconBtn: {
            cursor: 'pointer',
            margin: '0 12px',
            border: '1px solid var(--mainColor)',
            borderRadius: '6px',
            padding: '3px 10px',
            display: 'flex',
            background: 'var(--white)',
            '& svg': {
                color: 'var(--mainColor)',
            },
        },
        tableHead: {
            '& .MuiTableRow-head': {
                '& .MuiTableCell-head': {
                    textDecorationLine: 'underline',
                    color: '#1976D2',
                    fontWeight: 600,
                    fontSize: '18px',
                    lineHeight: '26px',
                    borderBottom: 'none',
                    backgroundColor: 'initial'
                },
            },
        },
        tableRowWrap: {
            '& .MuiTableCell-root': {
                borderBottom: 'none',
                fontSize: '16px',
                lineHeight: '26px',
                padding: '5px',
                backgroundColor: 'initial'  
            },
        },
        iconColor: {
            color: 'var(--mainColor)',
        }
    }),
);

