import React, { useState, useEffect } from 'react';
import { Stack, Button, Chip } from '@mui/material';
import { Add } from '@mui/icons-material';
import BurseChaliseTable from './BurseChaliceTable';
import { burseChaliceHead } from './BurseChaliceTable';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import CreateBurseChalice from './CreateBurseChalice';
import EditBurseChalice from './EditBurseChalice';
import useBurseChaliceActions, { BurseChalice as BurseChaliceType } from 'hooks/useBurseChaliceActions';
import { StateType } from 'contexts/intialstates/Types';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import useUserActions from 'hooks/useUserActions';

interface P {
	donorId: number;
}

const BurseChalice = ({ donorId }: P) => {
	const { handleRoles } = useUserActions();
	const { getBurseChalice } = useBurseChaliceActions();
	const [trimList, setTrimList] = useState<ColumnType[]>([]);
	const [visible, setVisible] = useState(false);
	const [visibleEdit, setVisibleEdit] = useState(false);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [orderBy, setOrderBy] = useState<string>('');
	const [reload, setReload] = useState(false);
	const [selectedId, setSelectedId] = useState();
	const [filterPayload, setFilterPayload] = React.useState<Array<any>>([]);

	const [burseChalice, setBurseChalice] = useState<StateType<BurseChaliceType>>({
		data: null,
		loading: false,
		error: null,
	});

	useEffect(() => {
		getBurseChalice({ burseChalice, setBurseChalice, donorId, page, pageSize, orderBy, filterPayload });
	}, [orderBy, page, pageSize, reload]);

	const handleToggle = () => {
		setVisible(!visible);
	};

	const handleEditToggle = () => {
		setVisibleEdit(!visibleEdit);
	};

	const handleRowClick = (row: any) => {
		setSelectedId(row?.id);
		handleEditToggle();
	};

	return (
		<Stack>
			<Stack direction="row" justifyContent={'space-between'} alignItems="center">
				{
					<>
						<Stack direction="row" spacing={1}>
							<Chip
								label={`${burseChalice?.data?.totalRecords || 0} Bruse/Chalice`}
								color="primary"
								variant="filled"
								size="small"
							/>
							<Chip
								label={`${burseChalice?.data?.statusInfo?.active || 0} Active`}
								color="primary"
								variant="outlined"
								size="small"
							/>
							<Chip
								label={`${burseChalice?.data?.statusInfo?.stopped || 0} Stopped`}
								color="error"
								variant="outlined"
								size="small"
							/>
							<Chip
								label={`${burseChalice?.data?.statusInfo?.completed || 0} Completed`}
								color="success"
								variant="outlined"
								size="small"
							/>
							<Chip
								label={`${burseChalice?.data?.statusInfo?.inactive || 0} Inactive`}
								variant="outlined"
								size="small"
							/>
						</Stack>
					</>
				}

				<Stack direction="row" alignItems="center">
					{handleRoles('donor-bookkeepings') && (
						<>
							<Button startIcon={<Add />} variant="contained" size="small" onClick={handleToggle}>
								Add Burse/Chalice
							</Button>
						</>
					)}
					<TrimTables
						name="burseChaliceHead"
						list={burseChaliceHead}
						trimList={trimList}
						setTrimList={setTrimList}
					/>
					<FilterListButton
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
						reload={reload}
						setIsReload={setReload}
						setPage={setPage}
						page={page}
					/>
				</Stack>
			</Stack>
			<BurseChaliseTable
				trimList={trimList}
				setTrimList={setTrimList}
				handleRowClick={handleRowClick}
				page={page}
				setPage={setPage}
				pageSize={pageSize}
				setPageSize={setPageSize}
				burseChalice={burseChalice?.data}
				loading={burseChalice?.loading}
				setOrderBy={setOrderBy}
				reload={reload}
				setReload={setReload}
				setFilterPayload={setFilterPayload}
				filterPayload={filterPayload}
			/>
			{visible && (
				<CreateBurseChalice
					visible={visible}
					handleClose={handleToggle}
					donorId={donorId}
					setReload={setReload}
					reload={reload}
				/>
			)}
			{visibleEdit && selectedId && (
				<EditBurseChalice
					visible={visibleEdit}
					handleClose={handleEditToggle}
					id={selectedId}
					setReload={setReload}
					reload={reload}
				/>
			)}
		</Stack>
	);
};

export default BurseChalice;
