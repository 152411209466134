import React from 'react';
import {
	FilterPayload,
	ResponseError,
	StateType,
	TimeStamp,
	TimeStampInfoType,
} from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';

export interface MissionMaterialData {
	pageNumber: number;
	pageSize: number;
	totalPages: number;
	totalRecords: number;
	body: MissionMaterial[];
}

export interface MissionMaterial extends TimeStamp, TimeStampInfoType {
	id: number;
	itemCode: string;
	description: string;
}

export interface GetMissionMaterialTypes {
	setMissionMaterial: React.Dispatch<React.SetStateAction<StateType<MissionMaterialData>>>;
	missionMaterial: StateType<MissionMaterialData>;
	page: number;
	pageSize: number;
	filterPayload?: FilterPayload[];
	orderBy?: string;
}

export interface MissionMaterialPayload {
	itemCode: string;
	description: string;
}

const useMissionSundayMaterialActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const getMissionMaterial = async ({
		setMissionMaterial,
		missionMaterial,
		page,
		pageSize,
		filterPayload,
		orderBy,
	}: GetMissionMaterialTypes) => {
		try {
			setMissionMaterial({
				...missionMaterial,
				loading: true,
				error: null,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`missionsunday-material/list?&PageNumber=${page + 1}&PageSize=${pageSize}${filter}${
						orderBy || '&SortBy=updated%20desc'
					}`,
				);
			} else {
				response = await api.get(
					`missionsunday-material/list?&PageSize=${pageSize}${filter}${orderBy || '&SortBy=updated%20desc'}`,
				);
			}

			setMissionMaterial({
				...missionMaterial,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || missionMaterial.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setMissionMaterial({
					...missionMaterial,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const createMissionMaterial = async (payload: MissionMaterialPayload): Promise<boolean> => {
		try {
			const response = await api.post(`missionsunday-material/create`, {
				...payload,
			});
			if (response?.data?.result) {
				setSimpleErrorSnack({
					message: 'Mission sunday material created succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const updateMissionMaterial = async (
		payload: MissionMaterialPayload,
		missionSundayMaterialId: number,
	): Promise<boolean> => {
		try {
			const response = await api.put(`missionsunday-material/${missionSundayMaterialId}/update`, {
				...payload,
			});
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Mission sunday material updated sucesssfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deleteMissionMaterial = async (missionSundayMaterialId: number): Promise<boolean> => {
		try {
			const response = await api.remove(`missionsunday-material/${missionSundayMaterialId}/archive`);
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Mission sunday material deleted succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	return {
		getMissionMaterial,
		createMissionMaterial,
		updateMissionMaterial,
		deleteMissionMaterial,
	};
};

export default useMissionSundayMaterialActions;
