import { OrderType } from 'contexts/intialstates/Types';
import React from 'react';

interface SortTableProps {
	column: string;
	setLabel: React.Dispatch<React.SetStateAction<string>>;
	setOrderType: React.Dispatch<React.SetStateAction<OrderType>>;
	setOderBy: React.Dispatch<React.SetStateAction<string>>;
	orderType: OrderType;
	label: string;
}

export const sortTable = ({
	column,
	setLabel,
	setOderBy,
	setOrderType,
	orderType,
	label,
}: SortTableProps) => {
	let sortBy;
	let orderParam: OrderType;

	if (column === 'actions' || column === 'remark') {
		return;
	}
	if (column === label) {
		if (orderType === 'asc') {
			orderParam = 'desc';
		} else {
			orderParam = 'asc';
		}
		sortBy = `&SortBy=${column} ${orderParam}`;
	} else {
		orderParam = 'asc';
		sortBy = `&SortBy=${column} ${orderParam}`;
		setLabel(column);
	}
	setOrderType(orderParam);
	setOderBy(sortBy);
};
