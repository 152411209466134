import * as React from 'react';
import {
	VolunteerActivism,
	DirectionsWalk,
	MonetizationOn,
	PostAdd,
	HistoryEdu,
	School,
	ChangeHistory,
	Campaign,
} from '@mui/icons-material';
import SubPanel from 'Components/Common/SubPanel/SubPanel';

const cardArray = [
	{
		name: 'HCA Orders',
		Icon: VolunteerActivism,
		link: 'hca-orders',
	},
	{
		name: 'Walkathon',
		Icon: DirectionsWalk,
		link: 'walkathon',
	},

	{
		name: 'Materials',
		Icon: PostAdd,
		link: 'hca-materials',
	},
	{
		name: 'HCA Acknowledgment',
		Icon: MonetizationOn,
		link: 'hca-donations',
	},

	{
		name: 'School Boards',
		Icon: HistoryEdu,
		link: 'school-boards',
	},
	{
		name: 'Schools',
		Icon: School,
		link: 'schools',
	},
	{
		name: 'Mission Sunday Material',
		Icon: ChangeHistory,
		link: 'missionsunday-material',
	},
	{
		name: 'Mission Sunday Order',
		Icon: ChangeHistory,
		link: 'missionsunday-order',
	},
	{
		name: 'Campaign',
		Icon: Campaign,
		link: 'campaign',
	},
];

const HCA = () => {
	return (
		<>
			<SubPanel cardArrays={cardArray} />
		</>
	);
};

export default HCA;
