import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RedirectionPayment = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const depositId = searchParams.get('depositId');
	const statusCode = searchParams.get('statusCode');
	const isRecurring = searchParams.get('isRecurring');

	useEffect(() => {
		setTimeout(
			() => {
				if (statusCode === '0') {
					if (isRecurring === 'true') {
						navigate(`/bookkeeping/template/deposits/${depositId}?tab=creditcard`);
					} else {
						navigate(`/bookkeeping/deposits/${depositId}`);
					}
				} else {
					isRecurring === 'true'
						? navigate('/bookkeeping/template/deposits?tab=creditcard')
						: navigate('/bookkeeping/deposits');
				}
			},
			statusCode !== '0' ? 2000 : 0,
		);
	}, []);
	return (
		<>
			{statusCode !== '0' && (
				<Snackbar open={statusCode !== '0'}>
					<Alert severity="error" sx={{ width: '100%' }}>
						{statusCode === '61'
							? 'An error occured, Payment card already been used'
							: 'An erorr occurred while payment'}
					</Alert>
				</Snackbar>
			)}
		</>
	);
};

export default RedirectionPayment;
