import { ResponseError, StateType, Society } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';
import { useContext } from 'react';

export interface GetAllAppealTypes {
	setAllSoceity: React.Dispatch<React.SetStateAction<StateType<Society[]>>>;
	allSociety: StateType<Society[]>;
}

const useSocietyActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = useContext(GlobalContexts);

	const getSocieties = async ({ allSociety, setAllSoceity }: GetAllAppealTypes) => {
		try {
			setAllSoceity({
				...allSociety,
				loading: true,
				error: null,
			});
			const response = await api.get(`/society/list`);

			setAllSoceity({
				...allSociety,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setAllSoceity({
					...allSociety,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	return {
		getSocieties,
	};
};

export default useSocietyActions;
