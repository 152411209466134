import { Add } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ColumnType, userHead } from 'Components/Common/Tables/TableHeaders';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { User, StateType } from 'contexts/intialstates/Types';
import useUserActions from 'hooks/useUserActions';
import React, { useContext, useEffect, useState } from 'react';
import CreateEditUser, { UserValues } from './CreateEdit';
import List from './List';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import { GlobalContexts } from 'contexts/GlobalContext';

const UserManageMent = () => {
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const { getUsers, deleteUser, updateUser, createUser, handleRoles } = useUserActions();
	const [reload, setReload] = React.useState(false);
	const [page, setPage] = React.useState(0);
	const [pageSize, setPageSize] = React.useState(10);
	const [visible, setVisible] = React.useState(false);
	const [isDelete, setIsDelete] = React.useState(false);
	const [selectedData, setSelectedData] = React.useState<User>();
	const [saveLoading, setSaveLoading] = useState(false);
	const [filterPayload, setFilterPayload] = React.useState<Array<any>>([]);
	const [userAllData, setUserAllData] = React.useState<User[]>([]);
	const [users, setUsers] = React.useState<StateType<User[]>>({
		data: null,
		error: null,
		loading: false,
	});
	const [isCalled, setIsCalled] = useState(false);
	const { getCurrentUser } = useUserActions();
	const { currentUser, setCurrentUser } = useContext(GlobalContexts);

	useEffect(() => {
		if (!users?.data) return;
		if (isCalled) return;
		setUserAllData(users?.data);
		setIsCalled(true);
	}, [users?.data]);

	useEffect(() => {
		getUsers({ setUsers, users });
	}, [reload]);

	useEffect(() => {
		getCurrentUser({ currentUser, setCurrentUser });
	}, [reload]);

	const handleOpenEdit = (data: User) => {
		setVisible(true);
		setSelectedData(data);
	};

	const handleOpenDelete = (data: User) => {
		setIsDelete(true);
		setSelectedData(data);
	};

	const handleCloseEdit = () => {
		setVisible(false);
		setSelectedData(undefined);
	};

	const handleCloseDelete = () => {
		setIsDelete(false);
		setSelectedData(undefined);
	};

	const handleDelete = async () => {
		if (selectedData) {
			setSaveLoading(true);
			const isSucess = await deleteUser(selectedData.id);
			setSaveLoading(false);
			handleCloseDelete();
			if (isSucess) {
				setReload(!reload);
				setVisible(false);
			}
		}
	};
	const handleSubmit = async (payload: UserValues) => {
		setSaveLoading(true);
		let isSucess;
		if (selectedData) {
			isSucess = await updateUser(payload, selectedData.id);
		} else {
			isSucess = await createUser(payload);
		}
		setSaveLoading(false);
		handleCloseEdit();
		if (isSucess) {
			setReload(!reload);
			setVisible(false);
		}
	};

	return (
		<>
			<Box>
				<Stack
					alignItems="center"
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent={'space-between'}
					spacing={1}
					mb={5}
				>
					<Stack direction="column">
						<Stack direction="row" spacing={4} alignItems="center">
							<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
								User Management
							</Typography>
						</Stack>
						<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
							Add and manage Users here.
						</Typography>
					</Stack>
					<Stack direction={'row'} justifyContent={'center'} spacing={{ xs: 1, md: 2 }} alignItems="center">
						<TrimTables name="userHead" list={userHead} trimList={trimList} setTrimList={setTrimList} />
						{handleRoles('user-actions') && (
							<Button
								sx={{ textTransform: 'capitalize' }}
								variant="contained"
								disableElevation
								startIcon={<Add />}
								onClick={() => setVisible(true)}
							>
								Add User
							</Button>
						)}
						<FilterListButton setFilterPayload={setFilterPayload} filterPayload={filterPayload} />
					</Stack>
				</Stack>
				<Stack direction="row">
					<List
						handleOpenEdit={handleOpenEdit}
						trimList={trimList}
						setTrimList={setTrimList}
						page={page}
						setPage={setPage}
						pageSize={pageSize}
						setPageSize={setPageSize}
						users={users}
						setUsers={setUsers}
						setFilterPayload={setFilterPayload}
						filterPayload={filterPayload}
						userAllData={userAllData}
					/>
				</Stack>
				<BasicModal
					visible={visible}
					title={selectedData ? 'Edit User' : 'Add a User'}
					data={selectedData || undefined}
					handleOpenDelete={handleOpenDelete}
				>
					<CreateEditUser
						saveLoading={saveLoading}
						handleSubmit={handleSubmit}
						selectedEditData={selectedData}
						setSelectedData={setSelectedData}
						setVisible={setVisible}
					/>
				</BasicModal>
				<ConfirmationModal
					handleClose={() => setIsDelete(false)}
					message={'Are you sure you want to delete this user ?'}
					open={isDelete}
					handleConfirmation={handleDelete}
					handleCancel={() => setIsDelete(false)}
					loading={saveLoading}
				/>
			</Box>
		</>
	);
};

export default UserManageMent;
