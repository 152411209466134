import React, { useState, useEffect } from 'react';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import { Formik, Form, Field } from 'formik';
import {
	Grid,
	Stack,
	Typography,
	Button,
	Select,
	MenuItem,
	FormHelperText,
	Autocomplete,
	Box,
	TextField,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormTextField } from 'Components/Common/TextField';
import { useGlobalStyles } from 'Components/Common/global/global';
import { ContentCopy, CorporateFare, TaskAlt, Close, MonetizationOn } from '@mui/icons-material';
import * as yup from 'yup';
import useDonationTypesActions from 'hooks/useDonationTypesActions';
import { StateType, DonationTypes } from 'contexts/intialstates/Types';
import useBurseChaliceActions from 'hooks/useBurseChaliceActions';
import useDebounce from 'Components/Reusable/hooks/debounce';

interface P {
	visible: boolean;
	handleClose: () => void;
	donorId: number;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	reload: boolean;
}

interface FormValues {
	contributionType: string;
	targetAmount: string;
	donationType?: {
		id?: number;
		label?: string;
	};
}

const CreateBurseChalice = ({ visible, handleClose, donorId, setReload, reload }: P) => {
	const global = useGlobalStyles();
	const { getDonationTypeBySearch } = useDonationTypesActions();
	const { createBurseChalice } = useBurseChaliceActions();

	const [loading, setLoading] = useState(false);
	const [donationTypeSearchTerm, setDonationTypeSearchTerm] = useState('');
	const debouncedDonationSearchTerm = useDebounce(donationTypeSearchTerm, 1000);
	const [donationType, setDonationType] = useState<StateType<DonationTypes[]>>({
		data: null,
		loading: false,
		error: null,
	});

	useEffect(() => {
		if (!debouncedDonationSearchTerm) return;
		getDonationTypeBySearch({ donationType, setDonationType, searchTerm: debouncedDonationSearchTerm });
	}, [debouncedDonationSearchTerm]);

	const handleSubmitValues = async (values: FormValues) => {
		if (!values.donationType?.id) return;

		const payload = {
			...values,
			targetAmount: parseInt(values.targetAmount),
			donationTypeId: values.donationType?.id,
			donorId,
		};
		setLoading(true);
		const isSuccess = await createBurseChalice(payload);
		if (isSuccess) {
			handleClose();
			setReload(!reload);
		}
		setLoading(false);
	};

	const validation = yup.object().shape({
		contributionType: yup.string().required('Contribution type is required'),
		donationType: yup.object().shape({
			id: yup.string().required('Donation Type is required'),
		}),
		targetAmount: yup.number().typeError('Invalid amount').required('Target amount is required'),
	});

	return (
		<BasicModal onClose={handleClose} visible={visible} title={'Add a Burse/Chalice'}>
			<Formik
				enableReinitialize
				onSubmit={handleSubmitValues}
				validationSchema={validation}
				initialValues={{
					contributionType: '',
					targetAmount: '',
					donationType: {
						id: undefined,
						label: undefined,
					},
				}}
			>
				{({ setFieldValue, values, touched, errors, handleSubmit }) => (
					<Form noValidate autoComplete="off">
						<Stack>
							<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
								<Grid item xs={12} md={6} mb={2}>
									<Grid container>
										<Grid item>
											<ContentCopy className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												Contribution Type
											</Typography>
										</Grid>
									</Grid>
									<Field
										component={() => (
											<Select
												size="small"
												fullWidth
												onChange={(e) => {
													setFieldValue('contributionType', e.target.value);
												}}
												value={values.contributionType}
												error={Boolean(touched?.contributionType && errors?.contributionType)}
											>
												<MenuItem value={'Burse'}>Burse</MenuItem>
												<MenuItem value={'Chalice'}>Chalice</MenuItem>
											</Select>
										)}
										name="contributionType"
										as={'select'}
										label="Contribution Type"
										variant="outlined"
									/>
									{touched?.contributionType && errors?.contributionType && (
										<FormHelperText error>{errors?.contributionType}</FormHelperText>
									)}
								</Grid>
								<Grid item xs={12} md={6} mb={2}>
									<Grid container>
										<Grid item>
											<MonetizationOn className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												Target Amount
											</Typography>
										</Grid>
									</Grid>
									<Field
										fullWidth
										name="targetAmount"
										size="small"
										variant="outlined"
										placeholder="Target Amount"
										component={FormTextField}
									/>
								</Grid>
								<Grid item xs={12} md={6} mb={2}>
									<Grid container>
										<Grid item>
											<CorporateFare className={global.formLabelIcon} />
										</Grid>
										<Grid item>
											<Typography variant="body2" className={global.formLabelText}>
												Donation Type
											</Typography>
										</Grid>
									</Grid>
									<Autocomplete
										freeSolo
										value={values.donationType?.label || ''}
										loading={donationType.loading}
										loadingText="loading..."
										onChange={(event: React.SyntheticEvent<unknown>, val: any): void => {
											setFieldValue('donationType', val);
										}}
										fullWidth
										options={(donationType?.data || []).map((donation: DonationTypes) => ({
											id: donation.id,
											label: `${donation.type} - ${donation.description}`,
										}))}
										renderOption={(props, option) => (
											<Box component="li" {...props} key={option.id}>
												{option.label}
											</Box>
										)}
										renderInput={(params) => (
											<TextField
												{...params}
												variant="outlined"
												label=""
												InputProps={{
													...params.InputProps,
												}}
												onChange={(event: any) => {
													setDonationTypeSearchTerm(event.target.value);
												}}
												size={'small'}
												placeholder={'Search donation type'}
												error={Boolean(errors?.donationType?.id)}
											/>
										)}
									/>
									{errors?.donationType && <FormHelperText error>{errors?.donationType?.id}</FormHelperText>}
								</Grid>
							</Grid>
							<Stack direction="row" my={3} justifyContent={'center'} spacing={2} alignItems="center">
								<LoadingButton
									color="primary"
									variant="contained"
									sx={{ width: 'fit-content', textTransform: 'capitalize' }}
									loading={loading}
									startIcon={<TaskAlt />}
									loadingPosition="start"
									onClick={() => handleSubmit()}
								>
									Save
								</LoadingButton>
								<Button
									variant="outlined"
									sx={{ width: 'fit-content', textTransform: 'capitalize' }}
									onClick={handleClose}
									startIcon={<Close />}
								>
									Cancel
								</Button>
							</Stack>
						</Stack>
					</Form>
				)}
			</Formik>
		</BasicModal>
	);
};

export default CreateBurseChalice;
