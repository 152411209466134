import React from 'react';
import {
	CreateReceiptTemplateProps,
	GetAllReceiptTemplateProps,
	GetReceiptTemplateProps,
	UpdateReceiptTemplateProps,
} from '../Components/AdminPanel/types';
import { ResponseError } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { GlobalContexts } from 'contexts/GlobalContext';
const useReceiptTemplateActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const getAllReceiptTemplate = async ({
		pageNumber,
		sizeOfPage,
		societyId,
		allReceipt,
		setAllReceipt,
		setErrorSnack,
		orderBy,
		filterPayload,
	}: GetAllReceiptTemplateProps) => {
		try {
			setAllReceipt({
				...allReceipt,
				loading: true,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (pageNumber) {
				response = await api.get(
					`receiptTemplate/list?PageNumber=${pageNumber + 1}&PageSize=${sizeOfPage}
					${societyId ? '&batchId=' + societyId : ''}
					${filter || ''}${orderBy || '&SortBy=updated%20desc'}`,
				);
			} else {
				response = await api.get(`receiptTemplate/list?${
					orderBy || 'SortBy=updated%20desc'
				}&PageSize=${sizeOfPage}${societyId ? '&batchId=' + societyId : ''}
				${filter || ''}`);
			}
			setAllReceipt({
				...allReceipt,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || allReceipt.data?.totalRecords,
				},
				error: null,
			});
			setErrorSnack(false);
		} catch (error) {
			if (Axios.isAxiosError(error)) {
				setAllReceipt({
					...allReceipt,
					loading: false,
					error: errorCodes(error?.response?.data) as ResponseError,
				});
			}
			setErrorSnack(true);
		}
	};

	const getSingleReceiptTemplate = async ({
		receiptTemplateId,
		receipt,
		setReceipt,
		setErrorSnack,
	}: GetReceiptTemplateProps) => {
		try {
			setReceipt({
				...receipt,
				loading: true,
			});
			const getReceipt = await api.get(`receiptTemplate/${receiptTemplateId}`);
			setReceipt({
				...receipt,
				loading: false,
				data: getReceipt?.data?.result,
				error: null,
			});
			setErrorSnack(false);
		} catch (error) {
			if (Axios.isAxiosError(error)) {
				setReceipt({
					...receipt,
					loading: false,
					error: errorCodes(error?.response?.data) as ResponseError,
				});
			}
			setErrorSnack(true);
		}
	};
	const createSingleReceiptTemplate = async ({
		payload,
		createReceipt,
		setCreateReceipt,
	}: CreateReceiptTemplateProps) => {
		try {
			setCreateReceipt({
				...createReceipt,
				loading: true,
			});
			const result = await api.post(`receiptTemplate/create`, { ...payload });
			setCreateReceipt({
				...createReceipt,
				loading: false,
				data: result?.data?.result,
				error: null,
			});

			setSimpleErrorSnack({
				message: 'Receipt letter created successfully ',
				severity: 'success',
				show: true,
			});
		} catch (error: any) {
			if (error?.response?.data?.StatusCode === 52) {
				setSimpleErrorSnack({
					message:
						'Failed - Can not create duplicate template with same, society,  month, year, donation type and appeal',
					severity: 'error',
					show: true,
				});
				return;
			}
			if (error.response?.data?.StatusCode === 70) {
				setSimpleErrorSnack({
					message: 'Failed - Donation type receipting is disabled',
					severity: 'error',
					show: true,
				});
				return false;
			}

			if (Axios.isAxiosError(error)) {
				setCreateReceipt({
					...createReceipt,
					loading: false,
					error: errorCodes(error?.response?.data) as ResponseError,
				});
			}
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
		}
	};

	const updateSingleReceiptTemplate = async ({
		payload,
		receiptTemplateId,
		updateReceipt,
		setUpdateReceipt,
	}: UpdateReceiptTemplateProps) => {
		try {
			setUpdateReceipt({
				...updateReceipt,
				loading: true,
			});
			const response = await api.put(`receiptTemplate/${receiptTemplateId}/update`, { ...payload });

			setUpdateReceipt({
				...updateReceipt,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
			setSimpleErrorSnack({
				message: 'Receipt letter updated successfully',
				severity: 'success',
				show: true,
			});
		} catch (err: any) {
			if (err.response?.data?.StatusCode === 70) {
				setSimpleErrorSnack({
					message: 'Donation type receipting is disabled',
					severity: 'error',
					show: true,
				});
				return false;
			}
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deleteSingleReceiptTemplate = async (id: number): Promise<boolean> => {
		try {
			const response = await api.remove(`receiptTemplate/${id}/archive`);
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'Receipt letter deleted succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	return {
		getAllReceiptTemplate,
		getSingleReceiptTemplate,
		createSingleReceiptTemplate,
		updateSingleReceiptTemplate,
		deleteSingleReceiptTemplate,
	};
};

export default useReceiptTemplateActions;
