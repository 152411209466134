import React from 'react';
import { FilterPayload, ResponseError, School, SchoolData, StateType } from 'contexts/intialstates/Types';
import useApi from './useApi';
import { errorCodes } from 'errorCodes';
import Axios from 'axios';
import { SchoolValues } from 'Components/AdminPanel/School/CreateEditSchool/CreateEditSchoolForm';
import { GlobalContexts } from 'contexts/GlobalContext';
import moment from 'moment';

export interface GetSchoolsTypes {
	setSchools: React.Dispatch<React.SetStateAction<StateType<SchoolData>>>;
	schools: StateType<SchoolData>;
	page: number;
	pageSize: number;
	filterPayload?: FilterPayload[];
	orderBy?: string;
	archive?: string;
	schoolBoardId?: number | string;
}
export interface GetExportSchoolsTypes {
	setSchoolExport: React.Dispatch<React.SetStateAction<StateType<SchoolData>>>;
	schoolExport: StateType<SchoolData>;
	page: number;
	pageSize: number;
	filterPayload?: FilterPayload[];
	orderBy?: string;
	archive?: string;
	schoolBoardId?: number | string;
}

export interface GetSchoolsSearchTypes {
	setSchoolSearch: React.Dispatch<React.SetStateAction<StateType<School[]>>>;
	schoolSearch: StateType<School[]>;
	searchTerm: string;
}

export interface GetSchoolTypes {
	setSchool: React.Dispatch<React.SetStateAction<StateType<School>>>;
	school: StateType<School>;
	id: number;
}

const useSchoolActions = () => {
	const { api } = useApi();
	const { setSimpleErrorSnack } = React.useContext(GlobalContexts);

	const getSchoolsByID = async ({ setSchool, school, id }: GetSchoolTypes) => {
		try {
			setSchool({
				...school,
				loading: true,
				error: null,
			});
			const response = await api.get(`school/${id}`);
			setSchool({
				...school,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSchool({
					...school,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const getSchools = async ({
		setSchools,
		schools,
		page,
		pageSize,
		filterPayload,
		orderBy,
		schoolBoardId,
		archive,
	}: GetSchoolsTypes) => {
		try {
			setSchools({
				...schools,
				loading: true,
				error: null,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`school/list?${schoolBoardId ? `schoolBoardId=${schoolBoardId}&` : ''}PageNumber=${
						page + 1
					}&PageSize=${pageSize}${archive ? archive : ''}${filter}${orderBy || '&SortBy=updated%20desc'}`,
				);
			} else {
				response = await api.get(
					`school/list?${schoolBoardId ? `schoolBoardId=${schoolBoardId}` : ''}&PageSize=${pageSize}${
						archive ? archive : ''
					}${filter}${orderBy || '&SortBy=updated%20desc'}`,
				);
			}

			setSchools({
				...schools,
				loading: false,
				data: {
					...response?.data?.result,
					totalRecords: response?.data?.result?.totalRecords || schools.data?.totalRecords,
				},
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSchools({
					...schools,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};
	const exportSchoolCSV = async ({
		setSchoolExport,
		schoolExport,
		page,
		pageSize,
		filterPayload,
		orderBy,
		schoolBoardId,
		archive,
	}: GetExportSchoolsTypes) => {
		try {
			setSchoolExport({
				...schoolExport,
				loading: true,
				error: null,
			});
			let filter = '';
			filterPayload?.map((item) => {
				filter = filter.concat(
					`&Filters=field=${item.field}${item?.value ? `;value=${item.value.join(',')}` : ''}${
						item?.query ? `;query=${item.query}` : ''
					}${item?.from ? `;from=${item.from}` : ''}${item?.to ? `;to=${item.to}` : ''}`,
				);
			});
			let response;
			if (page) {
				response = await api.get(
					`school/export-csv?${schoolBoardId ? `schoolBoardId=${schoolBoardId}&` : ''}PageNumber=${
						page + 1
					}&PageSize=${pageSize}${archive ? archive : ''}${filter}${orderBy || ''}`,
				);
			} else {
				response = await api.get(
					`school/export-csv?${schoolBoardId ? `schoolBoardId=${schoolBoardId}` : ''}&PageSize=${pageSize}${
						archive ? archive : ''
					}${filter}${orderBy || ''}`,
				);
			}

			const url = window.URL.createObjectURL(new Blob([response?.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `School_Export_${moment().format('yyMM')}.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
			setSchoolExport({
				...schoolExport,
				loading: false,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSchoolExport({
					...schoolExport,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const getSchoolsSearch = async ({ setSchoolSearch, schoolSearch, searchTerm }: GetSchoolsSearchTypes) => {
		try {
			setSchoolSearch({
				...schoolSearch,
				loading: true,
				error: null,
			});
			const response = await api.get(
				`school/search?schoolQuery=${searchTerm}&Filters=field=archived;value=false`,
			);
			setSchoolSearch({
				...schoolSearch,
				loading: false,
				data: response?.data?.result,
				error: null,
			});
		} catch (err) {
			if (Axios.isAxiosError(err)) {
				setSchoolSearch({
					...schoolSearch,
					loading: false,
					error: errorCodes(err?.response?.data) as ResponseError,
				});
				setSimpleErrorSnack({
					message: 'An error occured',
					severity: 'error',
					show: true,
				});
			}
		}
	};

	const createSchool = async (payload: SchoolValues): Promise<boolean> => {
		try {
			const response = await api.post(`school/create`, {
				...payload,
			});
			if (response?.data?.result) {
				setSimpleErrorSnack({
					message: 'School created succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occured',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const updateSchool = async (payload: SchoolValues, schoolID: number): Promise<boolean> => {
		try {
			const response = await api.put(`school/${schoolID}/update`, {
				...payload,
				id: schoolID,
			});
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'School updated sucesssfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	const deleteSchool = async (schoolID: number): Promise<boolean> => {
		try {
			const response = await api.remove(`school/${schoolID}/archive`);
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'School archived succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};
	const restoreSchool = async (schoolID: number): Promise<boolean> => {
		try {
			const response = await api.post(`school/${schoolID}/unarchive`);
			if (response?.data?.statusCode === 0) {
				setSimpleErrorSnack({
					message: 'School unarchived succesfully',
					severity: 'success',
					show: true,
				});
				return true;
			}
			return false;
		} catch (error) {
			setSimpleErrorSnack({
				message: 'An error occurred',
				severity: 'error',
				show: true,
			});
			return false;
		}
	};

	return {
		getSchoolsByID,
		getSchools,
		createSchool,
		updateSchool,
		deleteSchool,
		getSchoolsSearch,
		exportSchoolCSV,
		restoreSchool,
	};
};

export default useSchoolActions;
