import React, { useState, useEffect } from 'react';
import { useStyles } from './MissionSunday.style';
import { Stack, Button, Paper, Grid, Typography, Select, MenuItem } from '@mui/material';
import { Clear, AddTask } from '@mui/icons-material';
import { useGlobalStyles } from 'Components/Common/global/global';
import BasicDatePicker from 'Components/Reusable/DatePicker';
import { StateType } from 'contexts/intialstates/Types';
import LoadingButton from '@mui/lab/LoadingButton';
import ActionModal from 'Components/Reusable/Modals/ActionModal/ActionModal';
import useMissionSundayMaterialActions, { MissionMaterialData } from 'hooks/useMissionSundayMaterial';

export interface FormValues {
	start: string;
	end: string;
	materialids: number[];
}

interface P {
	handleGetReport?: (values: FormValues, isGenerate?: boolean) => void;
	loading: boolean;
	onCancel: () => void;
	isEdit: boolean;
	isShowFilter: boolean;
	formData: FormValues;
	setFormData: React.Dispatch<React.SetStateAction<FormValues>>;
}

const MissionSundayForm = ({
	handleGetReport,
	loading,
	onCancel,
	isEdit,
	isShowFilter,
	formData,
	setFormData,
}: P) => {
	const classes = useStyles();
	const global = useGlobalStyles();
	const { getMissionMaterial } = useMissionSundayMaterialActions();

	const [openRequiredModal, setOpenRequiredModal] = useState(false);
	const [missionMaterial, setMissionMaterial] = useState<StateType<MissionMaterialData>>({
		data: null,
		error: null,
		loading: false,
	});

	useEffect(() => {
		getMissionMaterial({ missionMaterial, setMissionMaterial, page: 0, pageSize: 1000 });
	}, []);

	const handleChange = (field: string, value: string) => {
		setFormData({
			...formData,
			[field]: value,
		});
	};

	const handleSelectChange = (field: string, value: string | string[] | number | number[]) => {
		setFormData({
			...formData,
			[field]: typeof value === 'string' ? value.split(',') : value,
		});
	};

	const handleGenerate = async (isGenerate?: boolean) => {
		if (!formData.start || !formData.end) {
			setOpenRequiredModal(true);
			return;
		}
		await handleGetReport?.(formData, isGenerate);
	};

	const handleCancel = () => {
		setFormData({
			start: '',
			end: '',
			materialids: [],
		});
		onCancel();
	};

	return (
		<Stack>
			{isShowFilter && (
				<>
					<Stack className={classes.headerFilter}>
						<span>Filters</span>
					</Stack>
					<Paper className={classes.paperWrap}>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
							<Grid item xs={12} md={4} sm={6} mb={3}>
								<Typography variant="body2" className={global.formLabelText} mb={1}>
									Date Range
								</Typography>
								<Stack direction="row" pr={{ lg: 5, md: 0 }}>
									<BasicDatePicker
										size="small"
										value={formData.start}
										setValue={(e) => handleChange('start', e.target.value)}
									/>
									<Stack m={1}>To</Stack>
									<BasicDatePicker
										size="small"
										value={formData.end}
										setValue={(e) => handleChange('end', e.target.value)}
									/>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4} sm={6} mb={3}>
								<Typography variant="body2" className={global.formLabelText} mb={1}>
									Mission Sunday Material
								</Typography>
								<Stack pr={{ lg: 5, md: 0 }}>
									<Select
										size="small"
										value={formData.materialids}
										name="donationType"
										multiple
										onChange={(event) => {
											handleSelectChange('materialids', event.target.value);
										}}
									>
										{(missionMaterial?.data?.body || []).map((x: { id: number; itemCode: string }) => (
											<MenuItem key={x.id} value={x.id}>
												{x.itemCode}
											</MenuItem>
										))}
									</Select>
								</Stack>
							</Grid>
							{isEdit && (
								<Grid item xs={12} md={4} sm={6} mb={3}>
									<Stack direction="row" spacing={2} height="100%" alignItems="flex-end" p={1}>
										<LoadingButton
											startIcon={<AddTask />}
											variant="contained"
											onClick={() => handleGenerate()}
											type="submit"
											loading={loading}
											loadingPosition="start"
											size="small"
										>
											Apply
										</LoadingButton>

										<Button
											startIcon={<Clear />}
											variant="outlined"
											className={global.whiteBtn}
											onClick={handleCancel}
											size="small"
										>
											Cancel
										</Button>
									</Stack>
								</Grid>
							)}
						</Grid>
					</Paper>
				</>
			)}
			{!isEdit && (
				<Stack m={3} direction="row">
					<LoadingButton
						startIcon={<AddTask />}
						variant="contained"
						onClick={() => handleGenerate(true)}
						loading={loading}
						loadingPosition="start"
						size="small"
					>
						Generate Report
					</LoadingButton>
				</Stack>
			)}
			{openRequiredModal && (
				<ActionModal
					open={openRequiredModal}
					title={'Required field'}
					subTitle={'Please fill donation recieved date range field'}
					closeText={'Close'}
					handleClose={() => setOpenRequiredModal(false)}
				/>
			)}
		</Stack>
	);
};

export default MissionSundayForm;
