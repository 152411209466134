import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import { GridView } from '@mui/icons-material';
import { Checkbox, FormControlLabel, FormGroup, IconButton, Stack, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { ColumnType } from 'Components/Common/Tables/TableHeaders';
import { useCookies } from 'react-cookie';

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 250,
		px: 2,
		color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
		boxShadow:
			'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			'& .MuiSvgIcon-root': {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			'&:active': {
				backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
			},
		},
	},
}));

interface TrimTablesProps {
	list: Array<ColumnType>;
	trimList: Array<ColumnType>;
	setTrimList: React.Dispatch<React.SetStateAction<ColumnType[]>>;
	name: string;
}

const TrimTables = ({ list, trimList, setTrimList, name }: TrimTablesProps) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [checkAll, setCheckAll] = React.useState<boolean>(false);
	const [refresh, setRefresh] = React.useState<boolean>(false);
	const [checker, setChecker] = React.useState<boolean>(false);
	const open = Boolean(anchorEl);
	const [cookies, setCookie] = useCookies([]);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClear = () => {
		setAnchorEl(null);
	};

	const trimData = (listItem: ColumnType) => {
		return { ...listItem, isChecked: true };
	};

	const handleCheck = (listItem: ColumnType) => {
		setTrimList(
			trimList.map((item: ColumnType) => {
				if (item.id === listItem.id) {
					item.isChecked = !item.isChecked;
				}
				return item;
			}),
		);
		setCheckAll(false);
		setRefresh(!refresh);
	};

	React.useEffect(() => {
		if (checkAll) {
			setTrimList(
				trimList.map((listItem: ColumnType) => {
					return { ...listItem, isChecked: true };
				}),
			);
		}
		if (!checkAll) {
			setTrimList(
				trimList.map((listItem: ColumnType) => {
					if (listItem.id === 'actions') return { ...listItem, isChecked: true };
					return { ...listItem, isChecked: false };
				}),
			);
		}
	}, [checker]);

	React.useEffect(() => {
		const index =
			trimList.length !== 0 && trimList.findIndex((indexItem: ColumnType) => indexItem.isChecked === false);
		if (index === -1) {
			setCheckAll(true);
		}
	}, [refresh]);

	React.useEffect(() => {
		if (Object.keys(cookies).length && Object.keys(cookies).includes(name)) {
			const columns = cookies[name as never] as ColumnType[];
			if (columns?.length) {
				setTrimList(cookies[name as never]);
				return;
			}
		}

		setTrimList(list.map(trimData));
		setRefresh(!refresh);
	}, []);

	React.useEffect(() => {
		setCookie(name as never, JSON.stringify(trimList));
	}, [trimList]);

	return (
		<>
			<Tooltip title="manage columns">
				<IconButton
					id="demo-customized-button"
					aria-controls={open ? 'demo-customized-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}
					color="primary"
				>
					<GridView sx={{ fontSize: 40 }} />
				</IconButton>
			</Tooltip>

			<StyledMenu
				id="demo-customized-menu"
				MenuListProps={{
					'aria-labelledby': 'demo-customized-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				<Stack alignItems="center" justifyContent="center" sx={{ my: 4 }}>
					<Box sx={{ width: 400 }}>
						<FormGroup sx={{ width: '50%', ml: 4 }}>
							<FormControlLabel
								sx={{ textTransform: 'capitalize' }}
								control={
									<Checkbox
										onChange={() => {
											setCheckAll(!checkAll);
											setChecker(!checker);
										}}
									/>
								}
								checked={checkAll}
								label={'Check All'}
							/>
						</FormGroup>
						<Stack pl={4} direction="row" justifyContent="space-between" flexWrap="wrap">
							{trimList.map((listItem: ColumnType) => (
								<FormGroup sx={{ width: '50%' }} key={listItem.label}>
									<FormControlLabel
										sx={{ textTransform: 'capitalize' }}
										control={
											<Checkbox
												checked={listItem.isChecked}
												disabled={listItem.id === 'actions'}
												onChange={() => handleCheck(listItem)}
											/>
										}
										label={listItem.label}
									/>
								</FormGroup>
							))}
						</Stack>
					</Box>
				</Stack>
				<Divider sx={{ mb: 0.5, mt: 3 }} />
				<Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
					<Button onClick={handleClear} sx={{ fontSize: 12, color: 'black', fontWeight: 600 }}>
						Cancel
					</Button>
				</Stack>
			</StyledMenu>
		</>
	);
};

export default TrimTables;
