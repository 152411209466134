import React from 'react';
import { ResponseError } from 'contexts/intialstates/Types';
import useApi from 'hooks/useApi';
import { Print } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

interface PackingSlipType {
	loading: boolean;
	data: string | null;
	error: ResponseError | null;
}

interface PackingSlipBtnType {
	id: number;
	reload: boolean;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
}

const PackingSlipBtn = ({ id, reload, setReload }: PackingSlipBtnType) => {
	const { api } = useApi();
	const [packingSlip, setPackingSlip] = React.useState<PackingSlipType>({
		loading: false,
		data: null,
		error: null,
	});

	const handlePrintPackingSlip = async () => {
		setPackingSlip({
			...packingSlip,
			loading: true,
			error: null,
		});
		try {
			const response = await api.get(
				`order/${id}/packingSlip`,

				{
					responseType: 'blob',
				},
			);
			setPackingSlip({
				...packingSlip,
				loading: false,
				error: null,
			});
			setReload(!reload);
			const url = window.URL.createObjectURL(new Blob([response?.data], { type: 'application/pdf' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'packing slip.pdf');
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			setPackingSlip({
				...packingSlip,
				loading: false,
				error: err as ResponseError,
			});
		}
	};
	return (
		<>
			<LoadingButton
				onClick={handlePrintPackingSlip}
				disableElevation
				sx={{ textTransform: 'capitalize', background: '#fff', color: '#1976D2' }}
				loading={packingSlip?.loading}
				loadingPosition="start"
				variant="outlined"
				startIcon={<Print />}
			>
				Print Packaging List
			</LoadingButton>
		</>
	);
};

export default PackingSlipBtn;
