import React, { useState, useContext } from 'react';
import useAppealActions from 'hooks/useAppealActions';
import BasicModal from 'Components/Reusable/Modals/BasicModal';
import { Stack, Button, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import AppealTable from './AppealTable';
import CreditEditAppeal from './CreateEditAppeal';
import { AppealValues } from 'Components/AdminPanel/Appeal/CreateEditAppeal';
import { Appeal } from 'contexts/intialstates/Types';
import { GlobalContexts } from 'contexts/GlobalContext';
import ErrorSnack from 'Components/Common/ErrorSnack/ErrorSnack';
import ConfirmationModal from 'Components/Reusable/Modals/ConfirmationModal';
import TrimTables from 'Components/Reusable/TrimTables/TrimTables';
import { appealHead, ColumnType } from 'Components/Common/Tables/TableHeaders';
import FilterListButton from 'Components/Reusable/FilterList/FilterList';
import useUserActions from 'hooks/useUserActions';

const Index = () => {
	const { handleRoles } = useUserActions();
	const [visible, setVisible] = useState(false);
	const { createAppeal, updateAppeal, deleteAppeal } = useAppealActions();
	const [saveLoading, setSaveLoading] = useState(false);
	const [reload, setReload] = useState(false);
	const [selectedData, setSelectedData] = useState<Appeal>();
	const { setErrorSnack } = useContext(GlobalContexts);
	const [isDelete, setIsDelete] = useState(false);
	const [trimList, setTrimList] = React.useState<ColumnType[]>([]);
	const [filterPayload, setFilterPayload] = React.useState<Array<any>>([]);
	const [page, setPage] = React.useState(0);

	const handleSubmit = async (payload: AppealValues) => {
		setSaveLoading(true);
		let isSucess;
		if (selectedData) {
			isSucess = await updateAppeal(payload, selectedData.id);
		} else {
			isSucess = await createAppeal(payload);
		}
		setSaveLoading(false);
		handleCloseEdit();
		if (isSucess) {
			setReload(!reload);
		} else {
			setErrorSnack(true);
		}
	};

	const handleOpenEdit = (data: Appeal) => {
		setVisible(true);
		setSelectedData(data);
	};

	const handleOpenDelete = (data: Appeal) => {
		setIsDelete(true);
		setSelectedData(data);
	};

	const handleCloseEdit = () => {
		setVisible(false);
		setSelectedData(undefined);
	};

	const handleCloseDelete = () => {
		setIsDelete(false);
		setSelectedData(undefined);
	};

	const handleDelete = async () => {
		if (selectedData) {
			setSaveLoading(true);
			const isSucess = await deleteAppeal(selectedData.id);
			setSaveLoading(false);
			handleCloseDelete();
			if (isSucess) {
				setReload(!reload);
				setVisible(false);
				setErrorSnack(false);
			} else {
				setErrorSnack(true);
			}
		}
	};

	return (
		<div>
			<Stack>
				<Stack
					alignItems="center"
					direction={{ xs: 'column', sm: 'row' }}
					justifyContent={'space-between'}
					spacing={1}
					mb={5}
				>
					<Stack direction="column">
						<Typography variant="h5" sx={{ color: 'var(--mainColor)' }}>
							Appeal
						</Typography>
						<Typography component={'span'} sx={{ color: '#7697ab', fontSize: 14, wordWrap: 'wrap' }}>
							Add and manage appeals here
						</Typography>
					</Stack>
					<Stack sx={{ mb: 3 }} direction="row" alignItems={'center'} justifyContent="flex-end">
						<TrimTables name="appealHead" list={appealHead} trimList={trimList} setTrimList={setTrimList} />

						{handleRoles('admin-actions') && (
							<Button
								onClick={() => setVisible(true)}
								sx={{ textTransform: 'none', mr: 2 }}
								startIcon={<Add />}
								variant="contained"
							>
								Add New Appeal
							</Button>
						)}
						<FilterListButton
							setFilterPayload={setFilterPayload}
							filterPayload={filterPayload}
							setIsReload={setReload}
							reload={reload}
							setPage={setPage}
							page={page}
						/>
					</Stack>
				</Stack>

				<AppealTable
					handleOpenEdit={handleOpenEdit}
					trimList={trimList}
					setTrimList={setTrimList}
					setFilterPayload={setFilterPayload}
					filterPayload={filterPayload}
					reload={reload}
					setReload={setReload}
					page={page}
					setPage={setPage}
				/>
				<BasicModal
					onClose={handleCloseEdit}
					visible={visible}
					title={selectedData ? 'Edit Appeal' : 'Create Appeal'}
					data={selectedData || undefined}
					handleOpenDelete={handleOpenDelete}
					noDelete={handleRoles('admin-actions')}
				>
					<CreditEditAppeal
						saveLoading={saveLoading}
						handleSubmit={handleSubmit}
						selectedEditData={selectedData}
					/>
				</BasicModal>
				<ConfirmationModal
					handleClose={() => setIsDelete(false)}
					message={'Are you sure you want to delete appeal ?'}
					open={isDelete}
					handleConfirmation={handleDelete}
					handleCancel={() => setIsDelete(false)}
					loading={saveLoading}
				/>
			</Stack>
			<ErrorSnack />
		</div>
	);
};

export default Index;
